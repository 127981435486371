import React from 'react';
import { TextField, Typography, Grid, MenuItem } from '@material-ui/core';
import { GenerateReportRequest, Site, TimePeriod } from '../../../models';
import RecipientListSelect from './RecipientListSelect';
import { PrimaryButton } from '../../ui/Buttons';
import {
  subYears,
  getYear,
  getMonth,
  eachYearOfInterval,
  eachMonthOfInterval,
  format,
  set,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from 'date-fns';

interface Props {
  site: Site;
  reportId: number;
  reportName: string;
  availableTimePeriods: TimePeriod[];
  onSubmit: (values: GenerateReportRequest) => void;
  users: string[];
}

interface State {
  timePeriod?: TimePeriod;
  yearSelected?: string;
  monthSelected?: string;
  startTS?: string;
  endTS?: string;
  recipients: string[];
  isTimePeriodTouched: boolean;
  isYearSelectedTouched: boolean;
  isMonthSelectedTouched: boolean;
}
const YEAR_LOOK_BACK_LIMIT = 2;
const getYears = (startDate?: string) => {
  const start = startDate ? new Date(startDate) : subYears(new Date(), YEAR_LOOK_BACK_LIMIT);
  const curr = new Date();
  return eachYearOfInterval({ start, end: curr }).map((year) => Number(format(year, 'yyyy')));
};

type Month = { index: number; label: string };
const getMonths = (year?: string, startDate?: string): Month[] => {
  const months: Month[] = [];
  const defaultYear = getYear(new Date());
  const monthList = eachMonthOfInterval({
    start: new Date(defaultYear, 0),
    end: new Date(defaultYear + 1, 0),
  });
  let startMonth = 0;
  let endMonth = 12;
  if (year && startDate) {
    const curr = new Date(new Date());
    const start = new Date(startDate);
    startMonth = Number(year) === getYear(start) ? getMonth(start) : startMonth;
    endMonth = Number(year) === getYear(curr) ? getMonth(curr) + 2 : endMonth;
  }
  for (let i = startMonth; i < endMonth; i++) {
    months.push({
      index: i,
      label: format(monthList[i], 'MMMM'),
    });
  }
  return months;
};

const calculateTimeRange = (timePeriod: TimePeriod, year?: number, month?: number) => {
  let startTS, endTS;

  if (month === undefined && TimePeriod.MONTHLY === timePeriod) {
    return {
      startTS: undefined,
      endTS: undefined,
    };
  }

  if (!year && [TimePeriod.MONTHLY, TimePeriod.YEARLY].includes(timePeriod)) {
    return {
      startTS: undefined,
      endTS: undefined,
    };
  }

  const yearSelectedDate = year ? set(new Date(), { year }) : undefined;

  const yearAndMonthSelectedDate =
    year && month !== undefined ? set(new Date(), { year, month }) : undefined;

  switch (timePeriod) {
    case TimePeriod.ALL:
      startTS = undefined;
      endTS = undefined;
      break;
    case TimePeriod.MONTHLY:
      startTS = yearAndMonthSelectedDate
        ? format(startOfMonth(yearAndMonthSelectedDate), 'yyyy-MM-dd')
        : undefined;
      endTS = yearAndMonthSelectedDate
        ? format(endOfMonth(yearAndMonthSelectedDate), 'yyyy-MM-dd')
        : undefined;
      break;
    case TimePeriod.YEARLY:
      startTS = yearSelectedDate ? format(startOfYear(yearSelectedDate), 'yyyy-MM-dd') : undefined;
      endTS = yearSelectedDate ? format(endOfYear(yearSelectedDate), 'yyyy-MM-dd') : undefined;
      break;
    case TimePeriod.MONTH_TO_DATE:
      startTS = format(startOfMonth(new Date()), 'yyyy-MM-dd');
      endTS = format(new Date(), 'yyyy-MM-dd');
      break;
    case TimePeriod.YEAR_TO_DATE:
      startTS = format(startOfYear(new Date()), 'yyyy-MM-dd');
      endTS = format(new Date(), 'yyyy-MM-dd');
      break;
    default:
      startTS = undefined;
      endTS = undefined;
  }

  return {
    startTS,
    endTS,
  };
};

const getDefaultState = (): State => {
  return {
    timePeriod: undefined,
    yearSelected: undefined,
    monthSelected: undefined,
    startTS: undefined,
    endTS: undefined,
    recipients: [],
    isTimePeriodTouched: false,
    isYearSelectedTouched: false,
    isMonthSelectedTouched: false,
  };
};

class ReportConfigurationForm extends React.Component<Props, State> {
  state: State = getDefaultState();

  componentDidMount() {
    if (this.props.availableTimePeriods.length === 1) {
      this.setState({ timePeriod: this.props.availableTimePeriods[0], isTimePeriodTouched: true });
    }
  }

  handleAddRecipient = (newRecipients: string[]) => {
    this.setState({ recipients: newRecipients });
  };

  handleRemoveRecipient = (emailAddress: string) => {
    const { recipients } = this.state;
    const newListOfRecipients = [...recipients.filter((email) => email !== emailAddress)];
    this.setState({ recipients: newListOfRecipients });
  };

  handleChangeTimePeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { yearSelected, isYearSelectedTouched, monthSelected, isMonthSelectedTouched } = this.state;
    const timePeriod = event.target.value as TimePeriod;
    const { startTS, endTS } = calculateTimeRange(
      timePeriod,
      yearSelected ? parseInt(yearSelected, 10) : undefined,
      monthSelected ? parseInt(monthSelected, 10) : undefined
    );
    if ([TimePeriod.ALL, TimePeriod.YEAR_TO_DATE, TimePeriod.MONTH_TO_DATE].includes(timePeriod)) {
      yearSelected = undefined;
      isYearSelectedTouched = false;
      monthSelected = undefined;
      isMonthSelectedTouched = false;
    }
    if (TimePeriod.YEARLY === timePeriod) {
      monthSelected = undefined;
      isMonthSelectedTouched = false;
    }
    this.setState({
      timePeriod,
      startTS,
      endTS,
      yearSelected,
      isYearSelectedTouched,
      monthSelected,
      isMonthSelectedTouched,
    });
  };

  handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { timePeriod, monthSelected } = this.state;
    const yearSelected = event.target.value;
    let startTS, endTS;
    if (timePeriod) {
      const timeRange = calculateTimeRange(
        timePeriod,
        parseInt(yearSelected, 10),
        monthSelected !== undefined ? parseInt(monthSelected, 10) : undefined
      );
      startTS = timeRange.startTS;
      endTS = timeRange.endTS;
    }
    this.setState({ yearSelected, startTS, endTS });
  };

  handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { timePeriod, yearSelected } = this.state;
    const monthSelected = event.target.value;
    let startTS, endTS;
    if (timePeriod) {
      const timeRange = calculateTimeRange(
        timePeriod,
        yearSelected ? parseInt(yearSelected, 10) : undefined,
        parseInt(monthSelected, 10)
      );
      startTS = timeRange.startTS;
      endTS = timeRange.endTS;
    }
    this.setState({ monthSelected, startTS, endTS });
  };

  handleGenerateReport = () => {
    const { startTS, endTS, recipients } = this.state;
    const { onSubmit, site, reportId, reportName } = this.props;
    const siteId = site.id;

    const generateReportRequest = {
      siteId,
      reportId,
      reportName,
      startTS,
      endTS,
      additionalRecipientEmails: recipients,
    };
    onSubmit(generateReportRequest);
  };

  render() {
    const {
      timePeriod,
      isTimePeriodTouched,
      yearSelected,
      isYearSelectedTouched,
      monthSelected,
      isMonthSelectedTouched,
      recipients,
    } = this.state;

    const { availableTimePeriods, users, site } = this.props;

    const isYearSelectedRequired =
      timePeriod && [TimePeriod.YEARLY, TimePeriod.MONTHLY].includes(timePeriod);

    const isMonthSelectedRequired = timePeriod && [TimePeriod.MONTHLY].includes(timePeriod);

    const isValid =
      timePeriod &&
      ((isYearSelectedRequired && yearSelected) || (!isYearSelectedRequired && !yearSelected)) &&
      ((isMonthSelectedRequired && monthSelected !== undefined) ||
        (!isMonthSelectedRequired && monthSelected === undefined));

    const showTimePeriodError = isTimePeriodTouched && !Boolean(timePeriod);

    const showYearSelectedError =
      isYearSelectedTouched && !Boolean(yearSelected) && isYearSelectedRequired;

    const showMonthSelectedError =
      isMonthSelectedTouched && monthSelected === undefined && isMonthSelectedRequired;

    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h5">Configure Report</Typography>
        </Grid>
        <Grid item container direction="column" style={{ maxWidth: 540 }} spacing={3}>
          <Grid item>
            <TextField
              InputLabelProps={{
                shrink: Boolean(timePeriod),
              }}
              disabled={availableTimePeriods.length === 1}
              variant="outlined"
              size="small"
              fullWidth
              select
              label="Choose Time Range"
              onChange={this.handleChangeTimePeriod}
              onBlur={() => this.setState({ isTimePeriodTouched: true })}
              value={timePeriod || ''}
              error={showTimePeriodError}
              helperText={showTimePeriodError ? 'Time Range is required' : null}
            >
              {availableTimePeriods.map((period) => (
                <MenuItem key={period} value={period}>
                  {period}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {isYearSelectedRequired && (
            <Grid item>
              <TextField
                disabled={!timePeriod}
                InputLabelProps={{
                  shrink: Boolean(yearSelected),
                }}
                variant="outlined"
                size="small"
                fullWidth
                select
                label="Year"
                onChange={this.handleYearChange}
                onBlur={() => this.setState({ isYearSelectedTouched: true })}
                value={yearSelected || ''}
                error={showYearSelectedError}
                helperText={showYearSelectedError ? 'Year is required' : null}
              >
                {getYears(site.commissionedOn).map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {isMonthSelectedRequired && (
            <Grid item>
              <TextField
                disabled={!timePeriod}
                InputLabelProps={{
                  shrink: monthSelected !== undefined,
                }}
                variant="outlined"
                size="small"
                fullWidth
                select
                label="Month"
                onChange={this.handleMonthChange}
                onBlur={() => this.setState({ isMonthSelectedTouched: true })}
                value={monthSelected === undefined ? '' : monthSelected}
                error={showMonthSelectedError}
                helperText={showMonthSelectedError ? 'Month is required' : null}
              >
                {getMonths(yearSelected, site.commissionedOn).map((month) => (
                  <MenuItem key={month.label} value={month.index}>
                    {month.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item>
            <RecipientListSelect
              label="CC results to:"
              options={users}
              recipients={recipients}
              onRemove={this.handleRemoveRecipient}
              onAdd={this.handleAddRecipient}
            />
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={8}>
            <Grid item>
              <PrimaryButton disabled={!isValid} onClick={this.handleGenerateReport}>
                Generate
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default ReportConfigurationForm;
