import { connect } from 'react-redux';
import { RootState } from '../../../state';
import UserPage, { Props } from './UserPage';
import {
  findUsersByCustomerId,
  updateUser,
  deleteUser,
  removeFromSite,
  createUser,
  resendInvite,
  getUsersWithSiteAccess,
} from '../../../state/entities';
import { getSiteContext } from '../../../state/contexts/site';
import { getProfileContext } from '../../../state/contexts/auth/selectors';
import { clearRequest } from '../../../state/request/actions';

const mapStateToProps = (rootState: RootState): Props => {
  const { id: siteId } = getSiteContext(rootState);
  const profile = getProfileContext(rootState);
  const { customerId } = profile;

  const allUsers = findUsersByCustomerId(rootState, customerId);

  return {
    profile,
    siteId,
    customerId,
    /**
     * Users that are linked to this customer
     */
    allUsers,
    /**
     * Users that are assigned to this site, and linked to this customer
     * Note: currently excludes users who are assigned to this site, but part
     *       of a different customer.
     */
    siteUsers: getUsersWithSiteAccess(rootState, siteId, customerId),
  };
};

const actions = {
  updateUser: updateUser.request,
  deleteUser: deleteUser.request,
  removeFromSite: removeFromSite.request,
  createNewUser: createUser.request,
  resendInvite: resendInvite.request,
  clearError: clearRequest,
};

export default connect(mapStateToProps, actions)(UserPage);
