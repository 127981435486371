import SiteDocument from './SiteDocument';
import { useRouteMatch } from 'react-router-dom';
import { isNaN } from 'lodash';

interface SiteDocumentMatchProps {
  siteId: string;
  documentName: string;
}

export const SiteDocumentContainer = () => {
  const { siteId: siteIdRaw, documentName } = useRouteMatch().params as SiteDocumentMatchProps;
  if (siteIdRaw === undefined || documentName === undefined || isNaN(+siteIdRaw)) {
    throw new Error('Page cannot be rendered without siteId or documentName .');
  }
  const siteId = parseInt(siteIdRaw, 10);

  return <SiteDocument siteId={siteId} documentName={documentName} />;
};
