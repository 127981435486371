import { getType, ActionType } from 'typesafe-actions';
import * as siteActions from '../site/actions';
import { keyBy } from 'lodash';
import { produce } from 'immer';
import { EquipmentNorm } from '../../../models';

export interface EquipmentEntityState {
  bySiteId: {
    [Key: string]: {
      [Key: string]: EquipmentNorm;
    };
  };
}

export interface EquipmentStateSlice {
  entities: {
    equipment: EquipmentEntityState;
  };
}

const initialState = {
  bySiteId: {},
};

type EquipmentActions = ActionType<typeof siteActions.fetchSiteData>;

export const equipmentEntityReducer = produce(
  (draft: EquipmentEntityState, action: EquipmentActions) => {
    switch (action.type) {
      case getType(siteActions.fetchSiteData.success): {
        const { equipment, site } = action.payload.result;
        const siteId = site.id;

        draft.bySiteId[siteId] = {
          ...draft.bySiteId[siteId],
          ...keyBy(equipment, (equip) => equip.id),
        };
        break;
      }

      default:
        break;
    }
  },
  initialState
);
