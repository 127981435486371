import React from 'react';
import {
  Theme,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { isFunction } from 'lodash';
import { TableSortLabel } from '../ui/Tables/Elements';
import identifiers from '../../tests/identifiers';

const useStyles = makeStyles<Theme>((theme) => ({
  tableWrapper: {
    position: 'relative' as 'relative',
    overflow: 'auto',
    boxSizing: 'border-box' as 'border-box',
    border: `1px solid ${theme.palette.divider}`,
  },
  table: {
    whiteSpace: 'nowrap' as 'nowrap',
    boxSizing: 'border-box' as 'border-box',
  },
  tableHead: {
    '& $tableCell': {
      position: 'sticky' as 'sticky',
      top: 0,
      zIndex: 3,
      backgroundClip: 'padding-box',
      boxShadow: `inset 0 -1px 0 ${theme.palette.divider}`,
      backgroundColor: '#fff',
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#f3f3f3',
      '& $tableCell': {
        backgroundColor: '#f3f3f3',
      },
    },
  },
  stickyColumnTable: {
    '& $tableHead $tableCell:nth-child(-n+1)': {
      position: 'sticky' as 'sticky',
      left: 0,
      zIndex: 4,
      boxShadow: `inset -1px 0 0 ${theme.palette.divider},  inset 0 -1px 0 ${theme.palette.divider}`,
      backgroundColor: '#fff',
    },
    '& $tableRow': {
      '& $tableCell:nth-child(-n+1)': {
        position: 'sticky' as 'sticky',
        left: 0,
        zIndex: 3,
        boxShadow: `inset -1px 0 0 ${theme.palette.divider}`,
        backgroundColor: '#fff',
      },
      '&:hover': {
        backgroundColor: '#f3f3f3',
        '& $tableCell:nth-child(-n+1)': {
          backgroundColor: '#f7f7f7',
        },
      },
    },
  },
  tableCell: {
    boxSizing: 'border-box' as 'border-box',
    backgroundClip: 'padding-box',
  },
}));

export type OrderDirectionType = 'asc' | 'desc' | undefined;

export type Field<E> = string | ((e: E) => string | number | undefined);

export interface Column<E> {
  id: string;
  label?: string;
  field: Field<E>;
  isSortable?: boolean;
  isSearchable?: boolean;
  isNumeric?: boolean;
  renderCell?: (e: E) => React.ReactNode;
  cellStyle?: {
    head?: React.CSSProperties;
    body?: React.CSSProperties;
  };
}

export function getColumnConfig<E>(label: string, field: Field<E>, isSortable: boolean = true) {
  return {
    id: label,
    label,
    field,
    isSortable,
  };
}

export interface DataTableProps<E> {
  stickyHeader?: boolean;
  stickyFirstCol?: boolean;
  elementKey: (e: E) => string;
  elements: E[];
  columnMapping: Column<E>[];
  testId?: string;
  handleSort: (columnId: string, direction: OrderDirectionType) => void;
  page: number;
  orderBy: string;
  orderDirection: OrderDirectionType;
  fullHeight?: boolean;
  rowClass?: (e: E) => string;
}

const PAGE_SIZE = 25;

export function DataTable<E>(props: DataTableProps<E>): React.ReactElement {
  const { orderDirection, orderBy, columnMapping, elements, page } = props;
  const orderColumnConfig = columnMapping.find((c) => c.id === orderBy);
  const orderField = orderColumnConfig?.field;

  let sortedElements = elements;
  if (orderField) {
    const sortBy = (a: E, b: E) => {
      const valueA = isFunction(orderField) ? orderField(a) : a[orderField];
      const valueB = isFunction(orderField) ? orderField(b) : b[orderField];
      if (valueA < valueB) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    };

    const sortElements = () => {
      if (!orderDirection) {
        return elements;
      }

      return [...elements].sort(sortBy);
    };
    sortedElements = sortElements();
  }

  const firstIndex = page ? (page - 1) * PAGE_SIZE : 0;
  const pageElements = sortedElements.slice(firstIndex, firstIndex + PAGE_SIZE);

  return <ControlledDataTable {...props} elements={pageElements} />;
}

export function ControlledDataTable<E>(props: DataTableProps<E>): React.ReactElement {
  const { orderDirection, orderBy, columnMapping, elements, stickyFirstCol, rowClass } = props;

  const sortedElements = elements;
  const pageElements = sortedElements;

  const handleColumnSort = (columnId: string) => {
    let newDirection: OrderDirectionType;
    if (columnId === orderBy) {
      newDirection =
        orderDirection === 'asc' ? 'desc' : orderDirection === 'desc' ? undefined : 'asc';
    } else {
      newDirection = 'asc';
    }
    props.handleSort(columnId, newDirection);
  };

  const classes = useStyles();

  const { elementKey } = props;
  return (
    <div
      className={classes.tableWrapper}
      data-testid={props.testId}
      style={props.fullHeight ? { height: '100%' } : undefined}
    >
      <Table
        classes={{ root: classes.table }}
        size="small"
        className={stickyFirstCol ? classes.stickyColumnTable : undefined}
      >
        <TableHead classes={{ root: classes.tableHead }}>
          <TableRow>
            {columnMapping.map((column, index) => {
              return (
                <TableCell
                  align={column.isNumeric ? 'right' : 'inherit'}
                  key={index}
                  classes={{ root: classes.tableCell }}
                  sortDirection={column.isSortable && orderDirection ? orderDirection : false}
                  style={column.cellStyle?.head}
                >
                  {column.isSortable && (
                    <Tooltip
                      title={`Order by ${column.label}`}
                      placement="bottom-end"
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={Boolean(orderBy === column.id && orderDirection)}
                        direction={orderDirection}
                        aria-sort={
                          orderDirection === 'asc'
                            ? 'ascending'
                            : orderDirection === 'desc'
                            ? 'descending'
                            : undefined
                        }
                        data-testid={identifiers.ui.dataTable.header}
                        onClick={() => handleColumnSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </Tooltip>
                  )}
                  {!column.isSortable && column.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {pageElements.map((element) => {
            return (
              <TableRow
                key={elementKey(element)}
                classes={{ root: classes.tableRow }}
                className={rowClass && rowClass(element)}
              >
                {columnMapping.map((column, index) => {
                  if (column.renderCell) {
                    return (
                      <TableCell
                        align={column.isNumeric ? 'right' : 'inherit'}
                        classes={{ root: classes.tableCell }}
                        key={index}
                        style={column.cellStyle?.body}
                      >
                        {column.renderCell(element)}
                      </TableCell>
                    );
                  } else if (isFunction(column.field)) {
                    return (
                      <TableCell
                        align={column.isNumeric ? 'right' : 'inherit'}
                        classes={{ root: classes.tableCell }}
                        key={index}
                        style={column.cellStyle?.body}
                      >
                        {column.field(element)}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        align={column.isNumeric ? 'right' : 'inherit'}
                        classes={{ root: classes.tableCell }}
                        key={index}
                        style={column.cellStyle?.body}
                      >
                        {element[column.field]}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {elements.length === 0 && (
        <div style={{ textAlign: 'center', marginTop: 36 }}>No results.</div>
      )}
    </div>
  );
}
