import URI from 'urijs';
import { AlarmDetails, AlarmSummary, AlarmStatus, getAlarmUid } from '../models';
import { PushClient } from './util/PushClient';
import { Normalized } from '../helpers/norm';
import { PagedPayloadBase } from './util/PushClient';

interface AlarmUpdate {
  updateType: 'note' | 'acknowledge' | 'resolve';
  contents: string;
  resolvedAtTs?: string;
}

export type AlarmSummaryPayload = Normalized<AlarmSummary, 'alarmUid', {}>;

export type AlarmDetailsPayload = Normalized<AlarmDetails, 'alarmUid', {}>;

export interface AlarmSummaryQuery {
  start: Date;
  end: Date;
  activeOnly?: boolean;
  tagIds?: string[];
  equipmentIds?: number[];
  priorities?: string[];
  statuses?: AlarmStatus[];
  orderBy?: string[];
}

export interface AlarmSummaryResponse extends PagedPayloadBase {
  alarmSummaries: AlarmSummaryPayload[];
}

export class AlarmApi extends PushClient {
  public async fetchAlarmDetails(
    siteId: number,
    alarmTagId: string,
    alarmId: string
  ): Promise<AlarmDetails> {
    const uri = new URI(`/alarm/${siteId}/${alarmTagId}/${alarmId}`).toString();
    return this.singleMessageGetRequest<AlarmDetails>(uri);
  }

  /**
   * We modify the responses returned from the server to add an `alarmUid`
   * field which is a combination of the `alarmId` and `alarmTag` field, as
   * neither has a uniqueness guarantee on their own.
   */
  public alarmSummaryPayloadReducer = (payload: AlarmSummaryResponse): AlarmSummary[] => {
    return payload.alarmSummaries.map((summaryPayload) => ({
      ...summaryPayload,
      alarmUid: getAlarmUid(summaryPayload),
    }));
  };

  public alarmDetailsPayloadReducer = (payload: AlarmDetailsPayload) => {
    return {
      ...payload,
      alarmUid: getAlarmUid(payload),
    };
  };

  public async fetchAlarmSummaries(
    siteId: number,
    start: Date,
    end: Date,
    isActiveOnly?: boolean
  ): Promise<AlarmSummary[]> {
    const uri = new URI(`/alarm/${siteId}`);
    uri.addSearch('includeAllActive', true);
    if (start) {
      uri.addSearch('startTS', start.toISOString());
    }
    if (end) {
      uri.addSearch('endTS', end.toISOString());
    }
    if (isActiveOnly) {
      uri.addSearch('activeOnly', true);
    }
    return this.multiMessageGetRequest(
      uri.toString(),
      // @ts-ignore todo: fix type
      this.getPagedMessageMarshaler({
        payloadReducer: this.alarmSummaryPayloadReducer,
      })
    );
  }

  public async queryAlarmSummaries(
    siteId: number,
    query: AlarmSummaryQuery
  ): Promise<AlarmSummary[]> {
    const uri = new URI(`/alarm/${siteId}`);

    uri.addSearch('startTS', query.start.toISOString());
    uri.addSearch('endTS', query.end.toISOString());
    if (query.activeOnly) {
      uri.addSearch('activeOnly', true);
    }
    if (query.tagIds) {
      uri.addSearch('tagIds', query.tagIds);
    }
    if (query.equipmentIds) {
      uri.addSearch('equipmentIds', query.equipmentIds);
    }
    if (query.priorities) {
      uri.addSearch('priorities', query.priorities);
    }
    if (query.statuses) {
      uri.addSearch('statuses', query.statuses);
    }

    return this.multiMessageGetRequest(
      uri.toString(),
      // @ts-ignore todo: fix type
      this.getPagedMessageMarshaler({
        payloadReducer: this.alarmSummaryPayloadReducer,
      })
    );
  }

  public async postAlarmNote(siteId: number, alarmTagId: string, alarmId: string, note: string) {
    return this.postAlarmUpdate(siteId, alarmTagId, alarmId, {
      updateType: 'note',
      contents: note,
    });
  }

  public async postAlarmAck(siteId: number, alarmTagId: string, alarmId: string, note?: string) {
    return this.postAlarmUpdate(siteId, alarmTagId, alarmId, {
      updateType: 'acknowledge',
      contents: note || '',
    });
  }

  public async postAlarmResolve(
    siteId: number,
    alarmTagId: string,
    alarmId: string,
    note?: string,
    resolvedAt?: Date
  ) {
    return this.postAlarmUpdate(siteId, alarmTagId, alarmId, {
      updateType: 'resolve',
      contents: note || '',
      resolvedAtTs: resolvedAt ? resolvedAt?.toISOString() : undefined,
    });
  }

  private async postAlarmUpdate(
    siteId: number,
    alarmTagId: string,
    alarmId: string,
    update: AlarmUpdate
  ): Promise<AlarmDetails> {
    const uri = new URI(`/alarm/${siteId}/${alarmTagId}/${alarmId}/${update.updateType}`);

    const requestBody = {
      contents: update.contents,
      resolvedAtTs: update.resolvedAtTs,
    };

    return this.singleMessagePostRequest(uri.toString(), requestBody, {
      payloadReducer: this.alarmDetailsPayloadReducer,
    });
  }
}
