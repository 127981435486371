import { EntitiesStateSlice } from '../';
import { Customer } from '../../../models';
import { CustomerNorm } from './reducer';
import { NormalizationError } from '../../../helpers/norm';
import { denormUser } from '../user/selectors';
import { memoizeSelector } from '../../../helpers/selectors';

export const getCustomerNorm = (
  rootState: EntitiesStateSlice,
  customerId: number
): CustomerNorm | undefined => {
  return rootState.entities.customer.byId[customerId];
};

export const denormCustomer = (rootState: EntitiesStateSlice, customerId: number) => {
  const customerNorm = getCustomerNorm(rootState, customerId);
  if (!customerNorm) {
    throw new NormalizationError(`Unable to denormalize customer: ${customerId}`, { customerId });
  }
  const { userIds, ...customer } = customerNorm;
  return {
    ...customer,
    users: userIds.map((userId) => denormUser(rootState, userId)),
  };
};

export const findCustomerById = memoizeSelector(
  (rootState: EntitiesStateSlice, customerId: number): Customer | undefined => {
    const customerNorm = getCustomerNorm(rootState, customerId);
    return customerNorm ? denormCustomer(rootState, customerId) : undefined;
  },
  [(state: EntitiesStateSlice) => state.entities.customer.byId]
);
