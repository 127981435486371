// import { createMuiTheme } from '@material-ui/core/styles';
import URI from 'urijs';
import { featureFlags } from './featureFlags';
import mpulseIconDark from '../assets/mpulse-icon-dark.png';
import mvoultLogoDark from '../assets/mvoult-logo-dark.png';
import mvoultIconDark from '../assets/mvoult-icon-dark.png';

export const ismVoult = featureFlags.product === 'mVoult';
export const ismPulse = featureFlags.product === 'mPulse';

export const authCallbackRelativePath = '/auth-callback';

export const auth = {
  domain: 'cleanspark-test.us.auth0.com',
  clientID: 'PKGxGae09xLp6gBzz8oiFXxcMTtjjOPJ',
  // eslint-disable-next-line no-restricted-globals
  redirectUri: `${location.origin}${authCallbackRelativePath}`,
  audience: 'https://cleanspark-test/platform',
  responseType: 'token id_token',
  scope: 'openid',
};

if (process.env.REACT_APP_DEV_API === 'prod') {
  Object.assign(auth, {
    domain: 'cleanspark.auth0.com',
    clientID: 'xi0AXh1OGJz7JfH9wEdBvpNy8DKE3KVc',
    audience: 'https://cleanspark/platform',
  });
}

export const applicationInsightsInstrumentationKey = '43531f06-2a95-4f67-b355-d39c971d51d5';

let apiRoot = new URI('https://api-dev.oncleanspark.com:8443/api');
if (process.env.REACT_APP_DEV_API === 'qa') {
  apiRoot = new URI('https://api-qa.oncleanspark.com:8443/api');
} else if (process.env.REACT_APP_DEV_API === 'prod') {
  apiRoot = new URI('https://api.oncleanspark.com:8443/api');
} else if (process.env.REACT_APP_DEV_API === 'local') {
  apiRoot = new URI('http://localhost:8081/api');
}

export const api = {
  root: apiRoot,
  version: '1.0',
  httpTimeoutDefault: 60 * 1000, // one minute
  pushTimeoutDefault: 60 * 3 * 1000, // three minutes
};

let signalRUrl = 'https://api-dev.oncleanspark.com:8885';
if (process.env.REACT_APP_DEV_API === 'qa') {
  signalRUrl = 'https://api-qa.oncleanspark.com:8885';
} else if (process.env.REACT_APP_DEV_API === 'prod') {
  signalRUrl = 'https://api.oncleanspark.com:8885';
} else if (process.env.REACT_APP_DEV_API === 'local') {
  signalRUrl = 'http://localhost:8085';
}

export const signalR = {
  url: signalRUrl,
  pushHub: 'PushHub',
};

// export const theme = createMuiTheme({
//   palette: {
//     primary: {
//       light: '#67daff',
//       main: '#03a9f4',
//       dark: '#007ac1',
//       contrastText: '#000',
//     },
//     secondary: {
//       light: '#718792',
//       main: '#455a64',
//       dark: '#1c313a',
//       contrastText: '#fff',
//     },
//   },
// });

export const palette = {
  // from v1 palette
  chelseaCucumber: '#8BA969', // green
  roseOfSharon: '#AF442A', // orange
  maiTai: '#A8652F', // orange
  pizza: '#BC8339', // yellow
  museli: '#A08F5B', // brown
  sirocco: '#6A786A', // green
  amazon: '#3B8361', // green
  allports: '#1E6571', // blue
  regalBlue: '#162B51', // blue

  // energy types
  solar: '#F8D564',
  stateOfCharge: '#59A28A',
  storage: '#59A28A',
  utility: '#D05848',
  load: '#89E1FF',
  generator: '#D9D9D9',
  wind: '#8A9D8C',
  intermediate: '#778CA3',
  baseGeneration: '#C5D244',
  meterRollback: '#F0C2E0',

  // branding
  cleansparkBlue: '#03a9f4',

  // grays
  gainsboro: '#dcdcdc',

  badNewsRed: '#cc0000',
  connectionFlowing: '#41C400',
};

export const mpulseIcon = ismVoult ? mvoultIconDark : mpulseIconDark;
export const mvoultLogo = mvoultLogoDark;

export const privacyPolicyUrl = 'https://cleanspark.com/privacy-policy/';

export const knowledgebaseUrl = 'https://cleanspark.zendesk.com/hc/en-us';

export const mVoultUrl = 'https://www.cleanspark.com/mvoult/';
