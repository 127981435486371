import { ActionType } from 'typesafe-actions';
import {
  Address,
  EnrollmentStatus,
  InstallerProvisionData,
  Site,
  SiteTemplate,
} from '../../../models';
import { WithStatusChildProps } from '../../utility/WithRequest';
import { installerActions } from '../../../state/entities/installer/reducer';

export interface SiteDetails {
  sku: string;
  siteName: string;
  address: Address;
  utilityId: number | null;
  rateScheduleId: number | null;
  customerEmail: string;
  image: string;
  imageFile: File | '';
  gridServicesInitialStatus: keyof typeof EnrollmentStatus | '';
}

export interface SolarEdgeDetails extends SiteDetails {
  notes?: string;
  serialNumbers: Record<string, string>;
  peakPower: number | '';
  pvManufacturer: string;
  pvModel: string;
  pvMaximumPower: number | '';
  pvTemperatureCoefficient?: number | '';
}

export interface SolArkDetails extends SiteDetails {
  partnerSiteId: string;
}

export type InstallerFormProps = SiteDetails | SolArkDetails | SolarEdgeDetails;

export interface InstallerPageProps {
  provisionSiteRequest: WithStatusChildProps<
    [InstallerProvisionData, SiteTemplate],
    ActionType<typeof installerActions.provisionSite.request>
  >;
  site: Site;
  siteTemplates: SiteTemplate[];
  sku: string;
}

export enum InstallerSteps {
  SKU = 0,
  LOCATION = 1,
  DETAILS = 2,
}
