import React from 'react';
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core';
import { ScreenSize } from './types';
import * as d3 from 'd3';

const styles = (theme: Theme) =>
  createStyles({
    tick: {},
    domain: {},
  });

interface Props {
  style?: React.CSSProperties;
  inset?: boolean;
  size: ScreenSize;
  offset?: number;
  yScale: d3.ScaleLinear<number, number>;
  tickCount?: number;
  tickInterval?: number;
  format: (d: number) => string;
  unit?: string;
}

type AllProps = Props & WithStyles<typeof styles>;

class YAxis extends React.Component<AllProps> {
  groupRef: React.RefObject<SVGGElement>;

  constructor(props: AllProps) {
    super(props);
    this.state = {};
    this.groupRef = React.createRef();
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  render() {
    return (
      <g ref={this.groupRef}>
        {this.props.unit && (
          <text style={{ fill: '#000' }} y={this.props.yScale.range()[1] - 3}>
            {this.props.unit}
          </text>
        )}
      </g>
    );
  }

  private makeAxis() {
    const formatter = (d: number) => {
      if ((this.props.yScale(d) ?? 0) - 10 < this.props.yScale.range()[1]) {
        return '';
      } else {
        return this.props.format(d);
      }
    };

    if (this.props.inset) {
      return (
        d3
          .axisRight(this.props.yScale)
          .ticks(this.props.tickCount || 5)
          // @ts-ignore todo: fix type
          .tickFormat(formatter)
      );
    } else {
      return (
        d3
          .axisLeft(this.props.yScale)
          .ticks(this.props.tickCount || 5)
          // @ts-ignore todo: fix type
          .tickFormat(formatter)
      );
    }
  }

  private update() {
    if (this.groupRef.current) {
      const g = d3.select(this.groupRef.current);
      g.call(this.makeAxis());
      g.attr('transform', `translate(${this.props.offset || 0}, 0)`);
      g.selectAll('.tick').attr('class', `tick ${this.props.classes.tick}`);
      if (this.props.inset) {
        g.selectAll('.tick').selectAll('text').attr('transform', `translate(-6, -7)`);
      } else {
        g.selectAll('.tick').selectAll('text').attr('transform', `translate(6, -7)`);
      }
      g.selectAll('.domain').attr('class', `domain ${this.props.classes.domain}`);
    }
  }
}

export default withStyles(styles)(YAxis);
