import React from 'react';
import {
  Equipment,
  findTagWithAttr,
  TagAttribute,
  AlarmSummary,
  AlarmStatus,
  getEquipmentIcon,
  DataPoint,
  TagDefinition,
  TagDataType,
  isChartableTag,
} from '../../../models';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  Divider,
} from '@material-ui/core';
import { Dictionary } from 'lodash';
import WarningIcon from '@material-ui/icons/Warning';
import { PathBuilder } from '../../../routing';
import { Link } from 'react-router-dom';
import { SDate } from '../../../helpers/SettableDate';
import { timeFormat } from 'd3';

const ONE_DAY = 1000 * 60 * 60 * 24;

export interface EquipmentTileProps {
  siteId: number;
  equipment: Equipment;
  latestSnapshots: Dictionary<DataPoint | undefined>;
  equipmentAlarms: AlarmSummary[];
}

const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  cardHeaderError: {
    backgroundColor: theme.palette.error.main,
  },
  cardTitle: {
    fontSize: 16,
    color: '#fff',
  },
  cardHeaderRoot: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    height: 32,
    alignItems: 'center',
  },
  cardHeaderAction: {
    marginTop: 0,
    alignSelf: 'unset',
  },
  warningIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  alarmLink: {
    color: theme.palette.error.dark,
    textTransform: 'none',
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
  },
  analysisLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      color: `#777`,
    },
  },
}));

const formatTagValue = (
  value: DataPoint['value'] | undefined,
  tag: TagDefinition,
  fractionDigits: number = 1
) => {
  if (value === undefined || value === null) {
    return;
  }
  if (tag.dataType === TagDataType.INTEGER && typeof value === 'number') {
    return value.toFixed(0);
  } else if (tag.dataType === TagDataType.DOUBLE && typeof value === 'number') {
    return value.toFixed(fractionDigits);
  } else if (tag.dataType === TagDataType.STRING && typeof value === 'string') {
    return value;
  } else {
    return value;
  }
};

const lookupAdjustedValue = (
  tag: TagDefinition | undefined,
  snapshots: Dictionary<DataPoint | undefined>
) => {
  let value;
  if (tag) {
    value = snapshots[tag.id]?.value;
    // adjust for perventage
    if (typeof value === 'number' && tag.unit.trim() === '%') {
      value = value * 100;
    }
  }
  return value;
};

const tooltipTitle = (
  tag: TagDefinition | undefined,
  snapshots: Dictionary<DataPoint | undefined>
) => {
  const time = tag && snapshots[tag.id]?.time;
  if (!time) {
    return undefined;
  }
  return timeFormat('at %-I:%M:%S %p')(time);
};

export const EquipmentTile: React.FC<EquipmentTileProps> = (props) => {
  const { equipment, latestSnapshots, equipmentAlarms, siteId } = props;

  const classes = useStyles();
  const primaryTag = findTagWithAttr(equipment, TagAttribute.Primary);
  const secondaryTag = findTagWithAttr(equipment, TagAttribute.Secondary);

  const primaryTagValue =
    primaryTag && formatTagValue(lookupAdjustedValue(primaryTag, latestSnapshots), primaryTag);
  const secondaryTagValue =
    secondaryTag &&
    formatTagValue(lookupAdjustedValue(secondaryTag, latestSnapshots), secondaryTag);

  const equipmentTagIds = equipment.tags.map((tag) => tag.id);
  const relevantAlarms = equipmentAlarms.filter((alarmSummary) => {
    return (
      equipmentTagIds.includes(alarmSummary.alarmTag.id) &&
      (alarmSummary.status === AlarmStatus.ACTIVE_UN_ACK ||
        alarmSummary.status === AlarmStatus.ACTIVE_ACK)
    );
  });

  const iconStyles = {
    width: 16,
    height: 16,
    backgroundImage: `url('${getEquipmentIcon(equipment)}')`,
    backgroundSize: 'contain',
  };

  return (
    <Card
      data-testid={`equipment-tile-${equipment.id}`}
      style={{ flex: 1, alignSelf: 'flex-start' }}
    >
      <CardHeader
        classes={{
          root: classes.cardHeaderRoot,
          title: classes.cardTitle,
          action: classes.cardHeaderAction,
        }}
        title={equipment.label}
        className={relevantAlarms.length > 0 ? classes.cardHeaderError : classes.cardHeader}
        action={<div style={iconStyles} />}
      />
      <CardContent>
        {relevantAlarms.length > 0 && (
          <div style={{ marginBottom: 8 }}>
            <Link to={PathBuilder.ALARMS_DEFAULT(siteId)} className={classes.alarmLink}>
              <WarningIcon className={classes.warningIcon} />
              <span>
                {relevantAlarms.length}{' '}
                {relevantAlarms.length > 1 ? 'active alarms' : 'active alarm'}
              </span>
            </Link>
          </div>
        )}

        {primaryTag && (
          <Grid container direction="column" alignItems="flex-end" style={{ flexWrap: 'nowrap' }}>
            <Grid item style={{ marginRight: 'auto' }}>
              <Typography>
                {primaryTag.label} {primaryTag.unit.trim() ? `(${primaryTag.unit})` : ''}
              </Typography>
            </Grid>
            <Grid item style={{ textAlign: 'right' }}>
              <Link
                className={classes.analysisLink}
                // @ts-ignore todo: refactor to not rely on to={false} as it's not supported
                to={
                  isChartableTag(primaryTag) &&
                  PathBuilder.ANALYSIS_QUERY(
                    siteId,
                    [primaryTag.id],
                    new Date(SDate.now() - ONE_DAY),
                    new Date(SDate.now())
                  )
                }
              >
                <Typography variant="h4" style={{ whiteSpace: 'nowrap' }}>
                  {primaryTagValue !== undefined ? (
                    <Tooltip title={tooltipTitle(primaryTag, latestSnapshots) as string}>
                      <span data-testid={`primary-tag-value-${primaryTag.id}`}>
                        {primaryTagValue} <span style={{ fontSize: 14 }}>{primaryTag.unit}</span>
                      </span>
                    </Tooltip>
                  ) : (
                    <em style={{ color: '#777' }}>no data</em>
                  )}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        )}

        {secondaryTag && (
          <Grid
            data-testid={`equipment-tile-${equipment.id}-secondary`}
            container
            direction="column"
            alignItems="flex-end"
            wrap="nowrap"
          >
            <Grid item style={{ marginRight: 'auto' }}>
              <Typography>
                {secondaryTag.label} {secondaryTag.unit.trim() ? `(${secondaryTag.unit})` : ''}
              </Typography>
            </Grid>
            <Grid item style={{ textAlign: 'right' }}>
              <Link
                className={classes.analysisLink}
                // @ts-ignore todo: refactor to not rely on to={false} as it's not supported
                to={
                  isChartableTag(secondaryTag) &&
                  PathBuilder.ANALYSIS_QUERY(
                    siteId,
                    [secondaryTag.id],
                    new Date(SDate.now() - ONE_DAY),
                    new Date(SDate.now())
                  )
                }
              >
                <Typography variant="h5" style={{ whiteSpace: 'nowrap' }}>
                  {secondaryTagValue !== undefined ? (
                    <Tooltip title={tooltipTitle(secondaryTag, latestSnapshots) as string}>
                      <span>
                        {secondaryTagValue}{' '}
                        <span style={{ fontSize: 12 }}>{secondaryTag.unit}</span>
                      </span>
                    </Tooltip>
                  ) : (
                    <em style={{ color: '#777' }}>no data</em>
                  )}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        )}
      </CardContent>
      <Divider />
      <CardActions style={{ padding: 16 }}>{props.children}</CardActions>
    </Card>
  );
};
