import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import { api as apiConfig } from '../../config';
import { ApiError } from './ApiError';

/**
 * Wrapper class for axios client instance which provides generic methods for
 * the api request types used in this application.
 */
export class ApiClient {
  protected client: AxiosInstance;

  public constructor(token: string) {
    const apiVersion = apiConfig.version || '1.0';
    this.client = axios.create({
      baseURL: `${apiConfig.root.toString()}/v${apiVersion}`,
      timeout: apiConfig.httpTimeoutDefault,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.unwrap(this.client.get(url, config));
  }

  public delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.unwrap(this.client.delete(url, config));
  }

  public post<T, B>(url: string, data?: B, config?: AxiosRequestConfig): Promise<T> {
    return this.unwrap(this.client.post(url, data, config));
  }

  public put<T, B>(url: string, data?: B, config?: AxiosRequestConfig): Promise<T> {
    return this.unwrap(this.client.put(url, data, config));
  }

  /**
   * Response comes back as { data: <result> } we want to return
   * Promise<result>
   * If the response is not parsable json, it could throw.
   */
  private async unwrap<T>(responsePromise: Promise<AxiosResponse<T>>): Promise<T> {
    try {
      const result = await responsePromise;
      return result.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.isAxiosError) {
        // we wrap axios erros in our own error class
        throw new ApiError(e);
      } else {
        // possibly an error parsing the response
        throw new ApiError(undefined, e.message);
      }
    }
  }
}
