import React from 'react';
import { Grid, TextField, makeStyles, createStyles } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../ui/controls/dateTimePicker.css';

export interface Props {
  start: Date | undefined;
  end: Date | undefined;
  maxRange?: number;
  filterDates?: (date: Date) => boolean;
  includeTime?: boolean;
  onStartChange: (date: Date) => void;
  onEndChange: (date: Date) => void;
  dateFormat?: string;
  disabled?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      width: 120,
    },
    inRange: {
      color: '#071013',
      backgroundColor: '#f0f0f0',
    },
    startDate: {
      color: '#fff',
      backgroundColor: '#52AD9C',
      borderRadius: '.3em',
    },
    endDate: {
      color: '#fff',
      backgroundColor: '#52AD9C',
      borderRadius: '.3em',
    },
  })
);

const daysToMS = (dayCount: number) => {
  return dayCount * 1000 * 60 * 60 * 24;
};

const DEFAULT_DATE_FORMAT = 'yyyy/MM/dd';
const DEFAULT_TIME_FORMAT = 'h:mm aa';
const DEFAULT_RANGE = 1000 * 60 * 60 * 24;

export const DateRange: React.FC<Props> = (props) => {
  const { start, end, onStartChange, onEndChange, maxRange } = props;
  const classes = useStyles();

  const handleStartDateChange = (newStart: Date) => {
    const currentRange = end && start ? end.getTime() - start.getTime() : undefined;
    if (maxRange && end && end.getTime() - newStart.getTime() > daysToMS(maxRange)) {
      onStartChange(newStart);
      onEndChange(new Date(newStart.getTime() + daysToMS(maxRange)));
    } else if (end && newStart > end) {
      onStartChange(newStart);
      onEndChange(new Date(newStart.getTime() + (currentRange || DEFAULT_RANGE)));
    } else {
      onStartChange(newStart);
    }
  };

  const handleEndDateChange = (newEnd: Date) => {
    const currentRange = end && start ? end.getTime() - start.getTime() : undefined;
    if (maxRange && start && newEnd.getTime() - start.getTime() > daysToMS(maxRange)) {
      onStartChange(new Date(newEnd.getTime() - daysToMS(maxRange)));
      onEndChange(newEnd);
    } else if (start && newEnd < start) {
      onStartChange(new Date(newEnd.getTime() - (currentRange || DEFAULT_RANGE)));
      onEndChange(newEnd);
    } else {
      onEndChange(newEnd);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <DatePicker
          disabled={props.disabled}
          selected={start}
          onChange={(e: Date) => e && handleStartDateChange(e)}
          dateFormat={DEFAULT_DATE_FORMAT}
          filterDate={props.filterDates}
          customInput={
            <TextField
              size="small"
              InputProps={{ classes: { input: classes.textField } }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              label="Start Date"
              variant="outlined"
              placeholder="Start Date"
            />
          }
        />
      </Grid>

      <Grid item>
        <DatePicker
          disabled={props.disabled}
          selected={start}
          onChange={(e: Date) => e && onStartChange(e)}
          dateFormat={DEFAULT_TIME_FORMAT}
          filterDate={props.filterDates}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          customInput={
            <TextField
              size="small"
              InputProps={{ classes: { input: classes.textField } }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              label="Start Time"
              variant="outlined"
              placeholder="Start Time"
            />
          }
        />
      </Grid>

      <Grid item>
        <DatePicker
          disabled={props.disabled}
          selected={end}
          onChange={(e: Date) => e && handleEndDateChange(e)}
          dateFormat={DEFAULT_DATE_FORMAT}
          filterDate={props.filterDates}
          customInput={
            <TextField
              size="small"
              InputProps={{ classes: { input: classes.textField } }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              label="End Date"
              variant="outlined"
              placeholder="End Date"
            />
          }
        />
      </Grid>

      <Grid item>
        <DatePicker
          disabled={props.disabled}
          selected={end}
          onChange={(e: Date) => e && onEndChange(e)}
          dateFormat={DEFAULT_TIME_FORMAT}
          filterDate={props.filterDates}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          customInput={
            <TextField
              size="small"
              InputProps={{ classes: { input: classes.textField } }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              label="End Time"
              variant="outlined"
              placeholder="End Time"
            />
          }
        />
      </Grid>
    </Grid>
  );
};
