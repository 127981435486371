import React from 'react';
import {
  makeStyles,
  IconButton,
  Divider,
  Drawer,
  SvgIcon,
  Menu,
  MenuItem,
  Typography,
  TextField,
  Button,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
  Zoom,
} from '@material-ui/core';
import { cls } from '../../../helpers/styles';
import { PathBuilder, PathPattern } from '../../../routing';
import { UserProfile, isAdminRole } from '../../../models';
import { Link, NavLink, NavLinkProps, RouteProps, useRouteMatch } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { sortBy } from 'lodash';

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import RepeatIcon from '@material-ui/icons/Repeat';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BarChartIcon from '@material-ui/icons/BarChart';
import TuneIcon from '@material-ui/icons/Tune';
import ClockIcon from '@material-ui/icons/Restore';
import CloseIcon from '@material-ui/icons/Close';
import AllowForRoles from '../auth/AllowForRoles';
import EquipmentIcon from '@material-ui/icons/DeveloperBoard';
import InstallerIcon from '@material-ui/icons/AddBox';
import HelpIcon from '@material-ui/icons/Help';
import { timeFormat } from 'd3';
import { isValid } from 'date-fns';
import { ComponentProps, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ismVoult } from '../../../config';
import { setTimeTravel, ttDate } from '../../../state/contexts/app';
import { SDate } from '../../../helpers/SettableDate';
import { getSiteContext } from '../../../state/contexts/site';
import { featureFlags } from '../../../config/featureFlags';
import identifiers from '../../../tests/identifiers';
import SiteLogo from '../../ui/SiteLogo';
import SiteIcon from '../../ui/SiteIcon';

export interface MainNavigationProps {
  profile: UserProfile;
  isNavOpen: boolean;
  closeNav: () => void;
  toggleNav: () => void;
}

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    ...theme.mixins.toolbar,
    minHeight: 'unset !important',
    boxShadow: theme.shadows[4],
  },
  drawerContent: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  toggleNav: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(1.5),
    ...theme.mixins.toolbar,
  },
  logoContainer: {
    alignItems: ismVoult ? 'flex-end' : 'center',
    display: 'flex',
    justifyContent: 'center',
    ...theme.mixins.toolbar,
  },
  sideBarTheme: {
    backgroundColor: theme.sidebar.main,
    color: '#fff',
  },
  sideBarDivider: {
    backgroundColor: '#13264f',
    marginTop: -1,
  },
  menuButton: {
    height: 48,
    width: 48,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  drawerRoot: {
    zIndex: 1250,
    height: '100vh',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  sideMenuItem: {
    '&:hover': {
      background: theme.sidebar.active,
      color: theme.sidebar.highlight,
    },
    fontSize: 16,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 8,
    marginBottom: 8,
  },
  sideMenuItemActive: {
    background: theme.sidebar.active,
    color: '#DCE6F9',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 8,
      top: 8,
      left: 0,
      width: 0,
      display: 'block',
      borderLeft: `5px solid ${theme.sidebar.active}`,
    },
  },
  sideLinkIcon: {
    fill: 'currentColor',
    marginRight: 24,
    marginLeft: 8,
    width: 24,
    height: 24,
  },
  timeTravelLink: {
    backgroundColor: '#5e35b1',
  },
  timeTravelForm: {
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: 8,
    },
  },
}));

type RoutePath = string | string[] | RouteProps;

/*
todo: label tuple elements after upgrading to TypeScript 4.0
type LinkDef = [
  label: string,
  to: string,
  Icon: typeof SvgIcon,
  requireAdmin: boolean,
  activePath?: RoutePath,
];
*/
type LinkDef = [string, string, typeof SvgIcon, boolean, RoutePath?];

const NavigationLink = ({
  closeNav,
  isSmallScreen,
  link,
}: {
  closeNav: MainNavigationProps['closeNav'];
  isSmallScreen: boolean;
  link: LinkDef;
}) => {
  const classes = useStyles();
  const [label, to, Icon, , activePath = false] = link;
  const isActivePath = useRouteMatch(activePath || '');
  return (
    <MenuItem
      key={label}
      className={classes.sideMenuItem}
      isActive={((match) => !!match || (activePath && isActivePath)) as NavLinkProps['isActive']}
      to={to}
      activeClassName={classes.sideMenuItemActive}
      exact={true}
      component={NavLink}
      onClick={() => {
        if (isSmallScreen) {
          closeNav();
        }
      }}
    >
      <Icon className={classes.sideLinkIcon} />
      {label}
    </MenuItem>
  );
};

export const MainNavigation = (props: MainNavigationProps) => {
  const siteContext = useSelector(getSiteContext);
  const { closeNav, isNavOpen, profile, toggleNav } = props;

  const ref = useRef<HTMLElement>(null);
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const linkProps = { closeNav, isSmallScreen };

  useEffect(() => {
    if (isSmallScreen) {
      // Close nav initially for small screens, and when resizing to small screen
      closeNav();
    }
  }, [closeNav, isSmallScreen]);

  const hideControlSummary = Boolean(
    siteContext.webPortalLayoutJson?.viewSettings?.hideControlSummary
  );
  const siteId = siteContext.id;

  const topLinks: LinkDef[] = useMemo(() => {
    const links: LinkDef[] = [
      ['Operations', PathBuilder.OPERATIONAL_SUMMARY(siteId), BarChartIcon, false],
      ['Alarms', PathBuilder.ALARMS_DEFAULT(siteId), NotificationsNoneIcon, false],
      ['Analysis', PathBuilder.ANALYSIS(siteId), MultilineChartIcon, false],
      ['Equipment', PathBuilder.EQUIPMENT(siteId), EquipmentIcon, false, PathPattern.EQUIPMENT],
    ];

    if (!hideControlSummary && featureFlags.enableControl) {
      links.push(['Control', PathBuilder.CONTROL_SUMMARY(siteId), TuneIcon, false]);
    }

    if (featureFlags.enableDashboard) {
      links.unshift(['Dashboard', PathBuilder.SITE(siteId), DashboardIcon, false]);
    }

    return links;
  }, [hideControlSummary, siteId]);

  const bottomLinks: LinkDef[] = useMemo(() => {
    const links: LinkDef[] = [
      ['Notifications', PathBuilder.ALARM_NOTIFICATIONS(siteId), AnnouncementIcon, true],
      ['Users', PathBuilder.USER_MANAGEMENT(siteId), PeopleAltIcon, true],
    ];

    if (featureFlags.enableReports) {
      links.unshift(['Report / Export', PathBuilder.REPORTS(siteId), AssignmentReturnedIcon, true]);
    }

    if (featureFlags.enableInstaller) {
      links.unshift([
        'Add Site',
        PathBuilder.INSTALLER(siteId),
        InstallerIcon,
        true,
        PathPattern.INSTALLER,
      ]);
    }

    return links;
  }, [siteId]);

  // Dispatch a navtoggle window event after width transition ends
  useEffect(() => {
    if (ref.current) {
      const handleTransitionEnd = (event: TransitionEvent) => {
        if (event.propertyName === 'width') {
          window.dispatchEvent(new Event('navtoggle'));
        }
      };
      const currentRef = ref.current;
      currentRef?.addEventListener('transitionend', handleTransitionEnd);
      return () => currentRef?.removeEventListener('transitionend', handleTransitionEnd);
    }
    return () => {};
  });

  // Use a swipeable drawer on mobile
  const DrawerComponent = isSmallScreen ? SwipeableDrawer : Drawer;
  const drawerProps: ComponentProps<typeof DrawerComponent> = isSmallScreen
    ? { onClose: closeNav, onOpen: () => {} }
    : { variant: 'permanent' };

  return (
    <DrawerComponent
      anchor="left"
      classes={{
        paper: cls(
          classes.drawerPaper,
          classes.sideBarTheme,
          !isNavOpen && classes.drawerPaperClose
        ),
        root: classes.drawerRoot,
      }}
      open={isNavOpen}
      PaperProps={{
        'data-testid': identifiers.mainNavigation[isNavOpen ? 'drawerOpen' : 'drawerSmall'],
      }}
      ref={ref}
      // todo: fix drawerProps type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(drawerProps as any)}
    >
      <div className={classes.drawerHeader}>
        <div className={classes.logoContainer}>
          {isNavOpen && (
            <Zoom
              in={isNavOpen}
              style={{ transitionDelay: `${theme.transitions.duration.shortest}ms` }}
            >
              <SiteLogo
                customerId={siteContext.customerId}
                link={PathBuilder.PORTFOLIO()}
                isTitleBar={true}
              />
            </Zoom>
          )}
          {!isNavOpen && (
            <Zoom in={!isNavOpen}>
              <SiteIcon customerId={siteContext.customerId} link={PathBuilder.PORTFOLIO()} />
            </Zoom>
          )}
        </div>

        <Divider className={classes.sideBarDivider} />

        {!isSmallScreen && (
          <div
            className={classes.toggleNav}
            data-testid={identifiers.mainNavigation.toggleNav}
            onClick={toggleNav}
          >
            <IconButton color="inherit">
              <DoubleArrowIcon
                style={{
                  transform: isNavOpen ? 'scaleX(-1)' : 'none',
                }}
              />
            </IconButton>
          </div>
        )}
        <Divider className={classes.sideBarDivider} />
      </div>

      <div className={classes.drawerContent}>
        <div>
          {isVerySmallScreen && (
            <MenuItem
              className={classes.sideMenuItem}
              onClick={() => {
                if (window.zE) {
                  window.zE('webWidget', 'show');
                  window.zE('webWidget', 'toggle');
                }
              }}
            >
              <HelpIcon className={classes.sideLinkIcon} />
              Help
            </MenuItem>
          )}
          {topLinks.map((link) => (
            <NavigationLink key={link[0]} link={link} {...linkProps} />
          ))}
        </div>

        <div style={{ marginTop: 'auto' }}>
          {bottomLinks.map((link, i) => {
            if (link[3]) {
              return (
                <AllowForRoles key={i} siteId={siteId} systemAdmin customerAdmin installer>
                  <NavigationLink key={link[0]} link={link} {...linkProps} />
                </AllowForRoles>
              );
            } else {
              return <NavigationLink key={link[0]} link={link} {...linkProps} />;
            }
          })}

          {/* Time Travel */}
          {profile.isSystemAdmin && featureFlags.enableTimeTravel && (
            <PopupState variant="popover">
              {(popupState) => (
                <>
                  <MenuItem
                    className={cls(
                      classes.sideMenuItem,
                      ttDate ? classes.timeTravelLink : undefined
                    )}
                    {...bindTrigger(popupState)}
                  >
                    <ClockIcon className={classes.sideLinkIcon} />
                    Time Travel
                  </MenuItem>

                  <Menu {...bindMenu(popupState)}>
                    <div>
                      <TimeTravelForm
                        className={classes.timeTravelForm}
                        onClose={() => popupState.close()}
                      />
                    </div>
                  </Menu>
                </>
              )}
            </PopupState>
          )}

          {/* Site Switcher */}
          {profile.sites.length > 1 && (
            <PopupState variant="popover">
              {(popupState) => (
                <>
                  <MenuItem className={classes.sideMenuItem} {...bindTrigger(popupState)}>
                    <RepeatIcon className={classes.sideLinkIcon} />
                    Switch Site
                  </MenuItem>

                  <Menu {...bindMenu(popupState)}>
                    {sortBy(profile.sites, 'customerId').map((site) => (
                      <MenuItem
                        key={site.siteId}
                        onClick={popupState.close}
                        to={PathBuilder.SITE(site.siteId)}
                        component={Link}
                        style={{ display: 'flex', paddingRight: 48 }}
                      >
                        <Typography style={{ flex: 1 }}>{site.siteName}</Typography>
                        {isAdminRole(site.role) ? (
                          <VpnKeyIcon
                            style={{ marginLeft: 8, marginRight: -28, width: 16, height: 16 }}
                          />
                        ) : null}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </PopupState>
          )}
        </div>
      </div>
    </DrawerComponent>
  );
};

const TimeTravelForm = (props: { className: string; onClose: () => void }) => {
  const [currentDate, setCurrentDate] = React.useState<Date>(new Date(SDate.now()));
  const dispatch = useDispatch();

  const formatter = timeFormat('%Y-%m-%dT%H:%M');
  return (
    <div className={props.className}>
      <TextField
        size="small"
        type="datetime-local"
        variant="outlined"
        value={formatter(currentDate)}
        onChange={(e) => {
          setCurrentDate(new Date(e.target.value));
        }}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={!isValid(currentDate)}
        onClick={() => {
          dispatch(setTimeTravel(currentDate));
        }}
      >
        Time Travel
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          dispatch(setTimeTravel(undefined));
          setCurrentDate(new Date());
        }}
      >
        Return to Present
      </Button>
      <IconButton onClick={props.onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};
