import React from 'react';
import { string, object, mixed } from 'yup';
import { TextField, Typography, Grid, MenuItem, FormControlLabel, Radio } from '@material-ui/core';
import { NewUser, AssignableRoles, getUserType, UserType } from '../../../models';
import { Formik, FormikHelpers } from 'formik';
import AllowForRoles from '../../modules/auth/AllowForRoles';
import { safeValue } from '../../../helpers/formatting';
import { internationalPhone } from '../../../helpers/validation';
import { PrimaryButton, CancelButton } from '../../ui/Buttons';
import { pick } from 'lodash';

export const InviteUserFormValidationSchema = object({
  name: string().min(0, 'Name is required.').required('Name is required.'),
  phoneNumber: internationalPhone({
    message: 'Enter country code followed by phone number: +1-999-999-9999',
    required: true,
  }),
  emailAddress: string().email().required('Email is required'),
  role: string().min(0, 'Role is required.').required('Role is required.'),
  userType: mixed().oneOf(Object.values(UserType)).required('User type is required'),
});

type FormValues = Pick<Partial<NewUser>, 'role'> &
  Pick<
    NewUser,
    | 'name'
    | 'emailAddress'
    | 'phoneNumber'
    | 'isSystemAdmin'
    | 'isCustomerAdmin'
    | 'isInstaller'
    | 'userType'
  >;

export interface Props {
  siteId: number;
  customerId: number;
  onCancel: () => void;
  onSave: (values: NewUser) => void;
}

const getUserTypeFromValues = (values: Partial<FormValues>) => {
  const userTypeBooleans = pick(values, [
    'isSystemAdmin',
    'isCustomerAdmin',
    'isInstaller',
  ]) as Pick<NewUser, 'isSystemAdmin' | 'isCustomerAdmin' | 'isInstaller'>;
  return getUserType(userTypeBooleans);
};

function InviteUserForm(props: Props) {
  const { onSave, onCancel, siteId, customerId } = props;

  const handleInviteUser = (values: Required<FormValues>, actions: FormikHelpers<FormValues>) => {
    const userTypeBooleans = {
      isCustomerAdmin: values.isCustomerAdmin,
      isInstaller: values.isInstaller,
      isSystemAdmin: values.isSystemAdmin,
    };
    const userType = getUserType(userTypeBooleans);

    const newSiteUser: NewUser = {
      ...values,
      ...{
        phoneNumber: values.phoneNumber
          ? values.phoneNumber.replace(/\D/g, '')
          : values.phoneNumber,
      },
      siteId,
      defaultSiteId: siteId,
      customerId,
      userType,
    };
    onSave(newSiteUser);
    actions.setSubmitting(false);
  };

  const handleCancel = () => {
    onCancel();
  };

  const initialValues: FormValues = {
    name: '',
    emailAddress: '',
    phoneNumber: '',
    role: undefined,
    isSystemAdmin: false,
    isCustomerAdmin: false,
    isInstaller: false,
    userType: UserType.USER,
  };

  return (
    <Formik
      validationSchema={InviteUserFormValidationSchema}
      // @ts-ignore todo: fix type
      onSubmit={handleInviteUser}
      // @ts-ignore todo: fix type
      initialValues={initialValues}
      children={(formikProps) => {
        const {
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          isValid,
          dirty,
          setFieldTouched,
          setFieldValue,
          setValues,
        } = formikProps;

        const handleUserTypeChange = (
          event: React.ChangeEvent<HTMLInputElement>,
          checked: boolean
        ) => {
          const optionValues = {
            isSystemAdmin: false,
            isInstaller: false,
            isCustomerAdmin: false,
          };
          optionValues[event.target.name] = checked;
          setValues({
            ...values,
            userType: getUserTypeFromValues(optionValues),
            ...optionValues,
          });
        };

        return (
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2} style={{ marginBottom: 0 }}>
              <Grid item>
                <Typography variant="h5">Invite User</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="name"
                  name="name"
                  label="Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.name}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name ? errors.name : null}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="emailAddress"
                  name="emailAddress"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.emailAddress}
                  error={touched.emailAddress && Boolean(errors.emailAddress)}
                  helperText={touched.emailAddress ? errors.emailAddress : null}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone"
                  onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key.match(/[^0-9+\-()/.]/)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const rawValue = event.target.value;
                    if (rawValue) {
                      const sanitizedValue = rawValue.replace(/[^0-9+\-()/.]/g, '');
                      setFieldValue('phoneNumber', sanitizedValue, true);
                    } else {
                      setFieldValue('phoneNumber', rawValue, true);
                    }
                  }}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.phoneNumber}
                  error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                  helperText={
                    touched.phoneNumber && Boolean(errors.phoneNumber)
                      ? errors.phoneNumber
                      : 'Enter country code followed by phone number: +1-999-999-9999'
                  }
                />
              </Grid>

              <Grid item>
                <TextField
                  data-testid={'invite-user-role-select'}
                  InputLabelProps={{
                    shrink: Boolean(values.role),
                  }}
                  fullWidth
                  variant="outlined"
                  size="small"
                  select
                  label="Role"
                  id="role"
                  name="role"
                  onChange={handleChange}
                  onBlur={() => {
                    setFieldTouched('role', true);
                  }}
                  disabled={isSubmitting}
                  value={safeValue(values.role)}
                  error={touched.role && Boolean(errors.role)}
                  helperText={touched.role ? errors.role : null}
                >
                  {AssignableRoles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item container direction="row">
                <AllowForRoles siteId={siteId} systemAdmin={true}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      htmlFor="isSystemAdmin"
                      control={
                        <Radio
                          inputProps={{
                            id: 'isSystemAdmin',
                          }}
                          name="isSystemAdmin"
                          checked={values.isSystemAdmin}
                          onChange={handleUserTypeChange}
                          color="primary"
                        />
                      }
                      label="System Admin"
                    />
                  </Grid>
                </AllowForRoles>

                <AllowForRoles siteId={siteId} systemAdmin={true} customerAdmin={true}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      htmlFor="isCustomerAdmin"
                      control={
                        <Radio
                          inputProps={{
                            id: 'isCustomerAdmin',
                          }}
                          name="isCustomerAdmin"
                          checked={values.isCustomerAdmin}
                          onChange={handleUserTypeChange}
                          color="primary"
                        />
                      }
                      label="Customer Admin"
                    />
                  </Grid>
                </AllowForRoles>

                <AllowForRoles siteId={siteId} systemAdmin={true} customerAdmin={true}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      htmlFor="isInstaller"
                      control={
                        <Radio
                          inputProps={{
                            id: 'isInstaller',
                          }}
                          name="isInstaller"
                          checked={values.isInstaller}
                          onChange={handleUserTypeChange}
                          color="primary"
                        />
                      }
                      label="Installer"
                    />
                  </Grid>
                </AllowForRoles>

                <AllowForRoles siteId={siteId} systemAdmin={true} customerAdmin={true}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      htmlFor="isUser"
                      control={
                        <Radio
                          inputProps={{
                            id: 'isUser',
                          }}
                          checked={values.userType === UserType.USER}
                          onChange={handleUserTypeChange}
                          color="primary"
                        />
                      }
                      label="User"
                    />
                  </Grid>
                </AllowForRoles>
              </Grid>

              <Grid item container justifyContent="flex-end" spacing={1}>
                <Grid item style={{ marginRight: 'auto' }}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </Grid>
                <Grid item>
                  <PrimaryButton
                    type="submit"
                    disabled={isSubmitting || isValidating || !isValid || !dirty}
                  >
                    Invite
                  </PrimaryButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
}

export default InviteUserForm;
