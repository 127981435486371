import { RootState } from '../../';
import { createAction } from 'typesafe-actions';
import { findTagLookupBySite, findSnapshotsInRange, findControlDataset } from '../../entities';
import { TagDefinition, TagAttribute, ProjectionDataset, TOUDataset } from '../../../models';
import { mapValues, values, compact, flatMap, Dictionary } from 'lodash';
import {
  powerAttributeMap,
  costAttributeMap,
  energyAttributeMap,
  projectionAttributeMap,
  touAttributeMap,
} from './config';
import { SeriesValue } from '../../../components/ui/chart/types';

export interface ControlSummaryPageState {
  date: Date | undefined;
}

export interface ControlTimeSeries {
  load: SeriesValue[];
  utilityMeter: SeriesValue[];
  utilityToLoad: SeriesValue[];
  utilityToBattery: SeriesValue[];
  pvToLoad: SeriesValue[];
  pvToBattery: SeriesValue[];
  pvToMeter: SeriesValue[];
  batteryToLoad: SeriesValue[];
  batteryChargePercentage: SeriesValue[];
  demandThreshold?: SeriesValue[];
}

const isSubset = (reference: TagAttribute[], toCheck: TagAttribute[]) => {
  const refSet = new Set(reference);
  return toCheck.every((v) => refSet.has(v));
};

/**
 * Given all tags for site and an attribute map, return list of tags with
 * attributes found in map
 */
const getTagMap = (allTags: TagDefinition[], attributeMap: Dictionary<TagAttribute[]>) => {
  const tagMap = mapValues(attributeMap, (attributes, key) => {
    const matches = allTags.filter((tag) => {
      return isSubset(tag.attributes, attributes);
    });

    return matches.length > 0 ? matches[0] : null;
  });
  return tagMap;
};

/**
 * Specifically returns tags needed for energy charts of control summary page
 */
export const getPowerTags = (allTags: TagDefinition[]) => {
  return compact(values(getTagMap(allTags, powerAttributeMap)));
};

/**
 * Specifically returns tags needed for energy charts of control summary page
 */
export const getEnergyTags = (allTags: TagDefinition[]) => {
  return compact(values(getTagMap(allTags, energyAttributeMap)));
};

/**
 * Specifically returns tags needed for cost charts of control summary page
 */
export const getCostTags = (allTags: TagDefinition[]) => {
  return compact(values(getTagMap(allTags, costAttributeMap)));
};

export const getAllTags = (allTags: TagDefinition[]) => {
  return flatMap([getPowerTags(allTags), getEnergyTags(allTags), getCostTags(allTags)]);
};

/* ----- actions ----- */

export const setActiveDate = createAction(
  'context/controlSummaryPage/setDate',
  (resolve) => (date: Date | undefined) => resolve({ date })
);

export const fetchControlSnapshots = createAction(
  'context/controlSummaryPage/fetchSnapshots',
  (resolve) => (siteId: number, date: Date) => resolve({ date, siteId })
);

/* ----- selectors ----- */

const selectTimeSeries = (
  state: RootState,
  attrMap: Dictionary<TagAttribute[]>,
  siteId: number,
  date: Date
) => {
  const tagLookup = findTagLookupBySite(state, siteId);
  const tagMap = getTagMap(Object.values(tagLookup), attrMap);

  const start = date;
  const end = new Date(date.getTime() + 1000 * 60 * 60 * 24);

  let timeSeriesMap = mapValues(tagMap, (tag) => {
    return tag
      ? findSnapshotsInRange(state, tag.id, start, end).map((point) => ({
          x: point.time,
          y: point.value,
        }))
      : [];
  });

  if (timeSeriesMap && timeSeriesMap.batteryChargePercentage) {
    timeSeriesMap = {
      ...timeSeriesMap,
      batteryChargePercentage: [
        ...timeSeriesMap.batteryChargePercentage.map((v) => ({ ...v, y: (v.y as number) * 100 })),
      ],
    };
  }
  if (timeSeriesMap && timeSeriesMap.pvToMeter) {
    timeSeriesMap = {
      ...timeSeriesMap,
      pvToMeter: [...timeSeriesMap.pvToMeter.map((v) => ({ ...v, y: (v.y as number) * -1 }))],
    };
  }
  return timeSeriesMap;
};

export const selectCostTimeSeries = (
  state: RootState,
  siteId: number,
  date: Date
): ControlTimeSeries => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return selectTimeSeries(state, costAttributeMap, siteId, date) as any as ControlTimeSeries;
};

export const selectPowerTimeSeries = (
  state: RootState,
  siteId: number,
  date: Date
): ControlTimeSeries => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return selectTimeSeries(state, powerAttributeMap, siteId, date) as any as ControlTimeSeries;
};

export const selectEnergyTimeSeries = (state: RootState, siteId: number, date: Date) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return selectTimeSeries(state, energyAttributeMap, siteId, date) as any as ControlTimeSeries;
};

export const selectProjections = (
  state: RootState,
  siteId: number,
  date: Date
): ProjectionDataset | undefined => {
  const controlDataset = findControlDataset(state, siteId, date);
  if (controlDataset === undefined) {
    return;
  }

  const dataset = mapValues(projectionAttributeMap, (attributes) => {
    const tag = controlDataset?.projections.find((tagData) =>
      isSubset(tagData.attributes, attributes)
    );
    return tag && tag.value;
  });

  return dataset;
};

export const selectTOUDataset = (state: RootState, siteId: number, date: Date): TOUDataset[] => {
  const controlDataset = findControlDataset(state, siteId, date);
  if (!controlDataset) {
    return [];
  }
  return controlDataset.tous.map((tou) => {
    if (!controlDataset?.controlTags[tou.name]) {
      return {
        ...tou,
      };
    }
    const controlTags = controlDataset?.controlTags[tou.name];
    const touControlData = mapValues(touAttributeMap, (attributes) => {
      const tag = controlTags.find((tagData) => isSubset(tagData.attributes, attributes));
      return tag && tag.value;
    });

    return {
      ...tou,
      ...touControlData,
    };
  });
};
