import React from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import { ReportConfiguration, ReportType } from '../../../models';
import { ReportMenuType, ReportMenu } from './ReportsPage';

interface Props {
  configurations: ReportConfiguration[];
  onReportTypeChange: (type: ReportMenuType) => void;
  active: ReportMenuType;
}

const styles = (theme: Theme) =>
  createStyles({
    activeMenuItem: {
      backgroundColor: theme.palette.grey[100],
      borderRight: `4px solid ${theme.palette.primary.main}`,
    },
    menuItem: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    drawerPaper: {
      position: 'relative',
    },
    primaryText: {
      fontWeight: 'bold',
    },
    secondaryText: {
      fontWeight: 'normal !important' as 'normal',
      paddingLeft: 0,
    },
  });

type AllProps = Props & WithStyles<typeof styles>;

class ReportTypeMenu extends React.Component<AllProps> {
  render() {
    const { configurations, active, onReportTypeChange, classes } = this.props;
    const isActive = (type: ReportMenuType) => type === active;

    return (
      <Drawer
        variant="permanent"
        anchor="left"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List
          disablePadding
          subheader={<ListSubheader component="div">Choose Template</ListSubheader>}
        >
          {configurations.map((type) => (
            <ListItem
              className={
                isActive(type.reportName as ReportType) ? classes.activeMenuItem : undefined
              }
              onClick={() => onReportTypeChange(type.reportName as ReportType)}
              button
              key={type.reportName}
            >
              <ListItemText
                primary={type.reportName}
                secondary={type.availableTimePeriods.join(', ')}
                classes={{
                  primary: classes.primaryText,
                  secondary: classes.secondaryText,
                }}
              />
            </ListItem>
          ))}
          <ListItem
            className={isActive(ReportMenu.EXPORT) ? classes.activeMenuItem : undefined}
            onClick={() => onReportTypeChange(ReportMenu.EXPORT)}
            button
          >
            <ListItemText
              primary={ReportMenu.EXPORT}
              secondary="Custom Range"
              classes={{
                primary: classes.primaryText,
                secondary: classes.secondaryText,
              }}
            />
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

export default withStyles(styles)(ReportTypeMenu);
