import { AdminApi } from '../../../api/AdminApi';
import { apiSagaV2 } from '../../../helpers/saga';
import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as alertActions from './actions';

const fetchSiteAlerts = apiSagaV2(alertActions.fetchSiteAlerts, (connection, action) => {
  const { siteId } = action.payload;
  return new AdminApi(connection).fetchSiteAlerts(siteId);
});

const updateAlert = apiSagaV2(alertActions.updateAlert, (connection, action) => {
  const { alertUpdate } = action.payload;
  return new AdminApi(connection).updateAlert(alertUpdate);
});

export function* alertSagas() {
  yield takeEvery(getType(alertActions.fetchSiteAlerts.request), fetchSiteAlerts);
  yield takeEvery(getType(alertActions.updateAlert.request), updateAlert);
}
