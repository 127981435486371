import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { AnalysisApi } from '../../../api/AnalysisApi';
import * as reportActions from '../../entities/report/actions';
import { apiSagaV2 } from '../../../helpers/saga';

const requestSiteReportConfiguration = apiSagaV2(
  reportActions.fetchReportConfigurations,
  (connection, action) => {
    const { siteId } = action.payload;
    return new AnalysisApi(connection).getReportConfigurations(siteId);
  }
);

const generateReport = apiSagaV2(reportActions.generateReport, (connection, action) => {
  const { request } = action.payload;
  return new AnalysisApi(connection).generateReport(request);
});

const exportReport = apiSagaV2(reportActions.exportReport, (connection, action) => {
  const { request } = action.payload;
  return new AnalysisApi(connection).exportReport(request);
});

export function* reportSagas() {
  yield takeEvery(
    getType(reportActions.fetchReportConfigurations.request),
    requestSiteReportConfiguration
  );
  yield takeEvery(getType(reportActions.generateReport.request), generateReport);
  yield takeEvery(getType(reportActions.exportReport.request), exportReport);
}
