import URI from 'urijs';
import { PushClient } from './util/PushClient';
import { Notification } from '../models';

interface NotificationPayload {
  notifications: Notification[];
}

export class NotificationApi extends PushClient {
  public async fetchNotifications(siteId: number): Promise<Notification[]> {
    const uri = new URI(`/notification/${siteId}`).toString();
    const result = await this.singleMessageGetRequest<NotificationPayload>(uri);
    return result ? result.notifications : [];
  }
}
