import { ActionsOf } from '../../../helpers/actions';
import { keyBy } from 'lodash';
import { getType } from 'typesafe-actions';
import { User } from '../../../models';
import * as userActions from './actions';
import * as customerActions from '../customer/actions';
import { produce } from 'immer';

export interface UserEntityState {
  byId: { [Key: number]: User };
}

export interface UserStateSlice {
  entities: {
    user: UserEntityState;
  };
}

const initialState = {
  byId: {},
};

type UserActions = ActionsOf<typeof customerActions & typeof userActions>;

export const userEntityReducer = produce((draft: UserEntityState, action: UserActions) => {
  switch (action.type) {
    /* fetch customer loads all users for customer*/

    case getType(customerActions.fetchCustomer.success): {
      const { result: customer } = action.payload;
      const byId = keyBy(customer.users, (user) => user.userId);
      draft.byId = {
        ...draft.byId,
        ...byId,
      };
      break;
    }

    case getType(userActions.createUser.success): {
      const { result: user } = action.payload;
      const { userId } = user;
      draft.byId[userId] = user;
      break;
    }

    case getType(userActions.updateUser.success): {
      const { result: user } = action.payload;
      draft.byId[user.userId] = user;
      break;
    }

    case getType(userActions.deleteUser.success): {
      const { triggerAction } = action.payload;
      const { userId } = triggerAction.payload.user;
      delete draft.byId[userId];
      break;
    }

    case getType(userActions.removeFromSite.success): {
      const { result: user } = action.payload;
      draft.byId[user.userId] = user;
      break;
    }

    default:
      break;
  }
}, initialState);
