import React from 'react';
import { compact, sum, every } from 'lodash';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { ComponentFlow } from './types';
import { ComponentId } from '../../../models';
import useSize from '@react-hook/size';
import * as d3 from 'd3';
import clsx from 'clsx';
import { FLOW_TOL } from './config';

interface EnergyMixDiagramProps {
  flows: ComponentFlow[];
}

const producerIds = [
  ComponentId.Utility,
  ComponentId.Generator,
  ComponentId.Storage,
  ComponentId.Solar,
];

interface TooltipProps {
  pos: 'top' | 'bottom';
  className?: 'string';
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const classes = useStyles();
  const posClass =
    props.pos === 'top' ? classes.tooltipTop : props.pos === 'bottom' ? classes.tooltipBottom : '';
  return (
    <div className={clsx(classes.tooltipOuter, props.className)} style={props.style}>
      <div className={posClass}>
        {props.children}
        <div className={classes.tooltipArrow}></div>
      </div>
    </div>
  );
};

export const EnergyMixDiagram = (props: EnergyMixDiagramProps) => {
  const { flows } = props;
  const producerFlows = compact(
    producerIds.map((compId) => flows.find((flow) => flow.id === compId))
  );

  const loadComponent = flows.find((flow) => flow.id === ComponentId.Load);

  if (!loadComponent) {
    throw new Error('Load component not found for powerflow diagram.');
  }

  if (producerFlows.length === 0) {
    throw new Error('No source components specified for powerflow diagram.');
  }

  // triggers re-render when topRef size changes
  const [barRef, setBarRef] = React.useState<HTMLDivElement | null>(null);
  const [width] = useSize(barRef);
  const classes = useStyles();

  const producers = producerFlows.filter((flow) => flow.flow > FLOW_TOL);

  const totalFlow = sum(producers.map((flow) => flow.flow));
  const maxValue = Math.max(totalFlow, Math.abs(loadComponent.flow));

  const scale = d3
    .scaleLinear()
    .domain([0, maxValue])
    .range([0, width - 8]);

  const barSources: ComponentFlow[] = [];

  // We want to calculate the start x position of each bar
  const barX = producers
    .reduce<number[]>(
      (acc, flow) => {
        const xlast = acc[acc.length - 1];

        if (flow.flow > 0) {
          acc.push(xlast + flow.flow);
          barSources.push(flow);
        }
        return acc;
      },
      [0]
    )
    .map(scale);

  // And the width of each bar
  const barWidth = barX.map((x, i) => (barX[i + 1] ? (barX[i + 1] || 0) - (barX[i] || 0) : 0));
  barWidth.pop();

  const loadTipContent = (
    <div>
      <p className={classes.toolTipLabel}>{loadComponent.name}</p>
      <p className={classes.toolTipValue}>
        {Math.abs(loadComponent.flow).toFixed(1)} {loadComponent.unit}
      </p>
    </div>
  );

  const noData = every([loadComponent, ...producers], (c) => c.flow === undefined);

  return (
    <div className={classes.container}>
      {noData && <Typography className={classes.noData}>No data available.</Typography>}
      <div ref={(newRef) => setBarRef(newRef)} className={classes.barBackground}>
        {barWidth.map((_, i) => {
          const flow = producers[i];
          const tipContent = (
            <div>
              <p className={classes.toolTipLabel}>{flow.name}</p>
              <p className={classes.toolTipValue}>
                {Math.abs(flow.flow).toFixed(1)} {flow.unit}
              </p>
            </div>
          );
          return (
            <React.Fragment key={producers[i].id}>
              <Tooltip
                key={producers[i].id}
                pos="bottom"
                style={{ top: -16, left: (barX[i] || 0) + barWidth[i] / 2 }}
              >
                {tipContent}
              </Tooltip>
              <div
                style={{
                  position: 'absolute',
                  left: (barX[i] || 0) + 7,
                  width: barWidth[i] - 7,
                  height: 21,
                  borderRadius: 5,
                  top: 7,
                  boxSizing: 'border-box',
                  backgroundColor: getColor(barSources[i]),
                }}
              />
            </React.Fragment>
          );
        })}

        {loadComponent.flow !== undefined && (
          <>
            <Tooltip
              pos="top"
              style={{ top: 108, left: (scale(Math.abs(loadComponent.flow)) || 0) + 3 }}
            >
              {loadTipContent}
            </Tooltip>
            <svg
              className={classes.svgEl}
              style={{
                position: 'absolute',
                top: -4,
                height: 36 + 8,
                left: (scale(Math.abs(loadComponent.flow)) || 0) - 0.5,
                width: 16,
              }}
            >
              <circle r="4" cy={4} cx={4} fill="#269AFA" />
              <line x1={4} x2={4} y1={4} y2={36 + 4} stroke="#269AFA" strokeWidth="2" />
              <circle r="4" cy={36 + 4} cx={4} fill="#269AFA" />
            </svg>
          </>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'hidden',
    position: 'relative',
    height: '220px',
    width: '100%',
    margin: '0px 0px',
    padding: '24px 48px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px 0px',
    },
  },
  noData: {
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '54px',
  },
  svgEl: {
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
  },
  barBackground: {
    position: 'relative',
    backgroundColor: '#A7A7A740',
    border: '1px solid #70707040',
    height: 36,
    width: '100%',
    borderRadius: 8,
    marginTop: 64,
  },

  tooltipArrow: {
    display: 'block',
    position: 'absolute',
    left: '50%',
    marginLeft: -12,
    width: 24,
    height: 12,
    overflow: 'hidden',

    '&:after': {
      content: '""',
      position: 'absolute',
      width: 12,
      height: 12,
      left: '50%',
      transform: 'translate(-50%,-50%) rotate(45deg)',
      backgroundColor: '#fff',
      boxShadow: '0 1px 8px #003d7b33',
    },
  },

  tooltipTop: {
    '& $tooltipArrow': {
      top: -11,
    },
    '& $tooltipArrow:after': {
      top: 11,
    },
  },

  tooltipBottom: {
    '& $tooltipArrow': {
      bottom: -11,
    },
    '& $tooltipArrow:after': {
      bottom: 0,
    },
  },

  tooltipOuter: {
    minWidth: 80,
    top: -20,
    left: '50%',
    transform: 'translate(-50%, -100%)',
    padding: '4px 8px',
    color: '#444444',
    backgroundColor: '#fff',
    borderRadius: 8,
    position: 'absolute',
    boxSizing: 'border-box',
    boxShadow: '0px 3px 8px 0px #003d7b33, 0 0 0 1px #CCD7E233',
  },

  toolTipLabel: {
    textAlign: 'center',
    margin: 0,
    color: '#221F1F',
    fontSize: 16,
    fontWeight: 300,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  toolTipValue: {
    textAlign: 'center',
    margin: 0,
    color: '#535353',
    fontSize: 16,
    fontWeight: 300,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
}));

const getColor = (flow: ComponentFlow): string => {
  switch (flow.id) {
    case ComponentId.Solar:
      return '#FFB045';
    case ComponentId.Storage:
      return '#3B9BA3';
    case ComponentId.Utility:
      return '#A33B3B';
    case ComponentId.Generator:
      return '#8B5CBA';
    default:
      return '#fff';
  }
};
