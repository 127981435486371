import URI from 'urijs';
import { PushClient, PagedPayloadBase } from './util/PushClient';
import { difference, isEqual, Dictionary } from 'lodash';

export type DataPointPayloadValue = number | string | Date | boolean | null;

export interface DataPointPayload {
  id: string;
  value: DataPointPayloadValue;
  timeStamp: string;
  quality: string;
}

export interface SnapshotData {
  siteId: number;
  points: {
    [Key: string]: DataPointPayload[];
  };
}

interface SnapshotPayload extends PagedPayloadBase {
  tagId: string;
  points: DataPointPayload[];
}

interface SnapshotRequest {
  siteId: number;
  tags: string[];
  topN?: number;
  start?: Date;
  end?: Date;
  includeTransient?: boolean;
}

export class SnapshotApi extends PushClient {
  public fetchTopNSnapshots(
    siteId: number,
    tags: string[],
    topN: number,
    includeTransient?: boolean
  ) {
    return this.fetchSnapshots({
      siteId,
      tags,
      topN,
      includeTransient,
    });
  }

  /**
   * Fetch snapshots matching specified siteId, and tagIds in specified date
   * range.
   *
   */
  public async fetchSnapshotsBetween(
    siteId: number,
    tags: string[],
    start: Date,
    end: Date,
    includeTransient?: boolean
  ): Promise<SnapshotData> {
    const request = {
      siteId,
      tags,
      start,
      end,
      includeTransient,
    };

    const snapshotData = this.fetchSnapshots(request);
    return snapshotData;
  }

  private getSnapshotUri(request: SnapshotRequest) {
    const uri = new URI(`/snapshot/${request.siteId}`);
    request.tags.forEach((tag) => uri.addSearch('tag', tag));
    if (request.topN) {
      uri.addSearch('topN', request.topN);
    } else if (request.start && request.end) {
      uri.addSearch('startTS', request.start.toISOString());
      uri.addSearch('endTS', request.end.toISOString());
    } else {
      throw new Error('Invalid snapshot request specified');
    }
    if (request.includeTransient) {
      uri.addSearch('includeTransient', true);
    }
    return uri;
  }

  /**
   * Base method for getting snapshots from server based on a request options
   * object. Makes one request to snapshot endpoint, collects push messages and
   * returns a promise that resolves to a SnapshotData object.
   */
  private async fetchSnapshots(request: SnapshotRequest): Promise<SnapshotData> {
    const { siteId, tags } = request;

    const snapshotData: SnapshotData = {
      siteId,
      points: {},
    };
    const receivedByTag: Dictionary<number> = {};
    const expectedByTag: Dictionary<number> = {};

    const uri = this.getSnapshotUri(request).toString();

    return this.multiMessageGetRequest<SnapshotData, SnapshotPayload>(uri, (finished, message) => {
      if (message.payloadType !== null && message.payload?.tagId) {
        const { tagId, points } = message.payload;
        snapshotData.points[tagId] = (snapshotData.points[tagId] || []).concat(points);
        receivedByTag[tagId] = receivedByTag[tagId] || 0;
        receivedByTag[tagId]++;

        if (message.payload.isFinal && message.payload.index) {
          expectedByTag[tagId] = message.payload.index;
        }
      }

      if (
        difference(tags, Object.keys(expectedByTag)).length === 0 &&
        isEqual(expectedByTag, receivedByTag)
      ) {
        finished.resolve(snapshotData);
      }
    });
  }
}
