import React from 'react';
import { Dispatch, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  Link,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { Equipment } from '../../../models';
import { FilterIcon } from '../../ui/Icons';
import identifiers from '../../../tests/identifiers';

const EquipmentListControls = ({
  allEquipment,
  listDispatch,
  listState,
}: EquipmentListControlsProps) => {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(false);

  // Equipment categories
  const categories = useMemo(() => {
    const set = new Set();
    allEquipment.forEach((item) => set.add(item.category));
    return Array.from(set).sort() as string[];
  }, [allEquipment]);

  // Equipment types
  const types = useMemo(() => {
    const set = new Set();
    allEquipment.forEach((item) => set.add(item.equipmentType));
    return Array.from(set).sort() as string[];
  }, [allEquipment]);

  return (
    <Accordion classes={{ root: classes.accordion }} elevation={0} expanded={showFilters} square>
      <AccordionSummary
        classes={{ root: classes.accordionSummary, content: classes.accordionSummaryContent }}
        expandIcon={<ExpandMoreIcon />}
        onClick={() => setShowFilters((prev) => !prev)}
      >
        <FilterIcon className={classes.filterIcon} />{' '}
        <Typography>
          Filters{' '}
          {isFiltered(listState) && (
            <>
              (
              <Link
                data-testid={identifiers.equipmentPage.clearFilters}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  event.stopPropagation();
                  listDispatch({ type: 'clear' });
                }}
              >
                clear
              </Link>
              )
            </>
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.filters }}>
        <Filter
          listDispatch={listDispatch}
          listState={listState}
          items={types}
          label="Types"
          type="types"
        />
        <Filter
          listDispatch={listDispatch}
          listState={listState}
          items={categories}
          label="Categories"
          type="categories"
        />
        <TextField
          id="search"
          onChange={({ target: { value } }) => listDispatch({ type: 'search', value })}
          placeholder="Search Equipment"
          value={listState.search}
          variant="outlined"
          size="small"
          className={classes.search}
          inputProps={{
            autoComplete: 'off',
            'data-testid': identifiers.equipmentPage.filterSearch,
          }}
          InputProps={{
            className: classes.searchInput,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export type EquipmentListAction =
  | { type: 'categories'; value: string[] }
  | { type: 'clear' }
  | { type: 'search'; value: string }
  | { type: 'types'; value: string[] };

export interface EquipmentListState {
  categories: string[];
  search: string;
  types: string[];
}

export interface EquipmentListControlsProps {
  allEquipment: Equipment[];
  listDispatch: Dispatch<EquipmentListAction>;
  listState: EquipmentListState;
}

export const INITIAL_LIST_STATE: EquipmentListState = {
  categories: [],
  search: '',
  types: [],
};

export function listReducer(state: EquipmentListState, action: EquipmentListAction) {
  switch (action.type) {
    case 'categories':
      return { ...state, categories: action.value };
    case 'clear':
      return { ...INITIAL_LIST_STATE };
    case 'search':
      return { ...state, search: action.value };
    case 'types':
      return { ...state, types: action.value };
    default:
      throw new Error();
  }
}

function isFiltered({ categories, search, types }: EquipmentListControlsProps['listState']) {
  return categories.length > 0 || types.length > 0 || search !== '';
}

const useStyles = makeStyles<Theme>((theme) => ({
  accordion: {
    background: theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginLeft: theme.spacing(-0.5),
    padding: '11px 0 4px',
    '&.Mui-expanded': {
      margin: theme.spacing(0, 0, 0, -0.5),
    },
  },
  accordionSummary: {
    padding: theme.spacing(0, 1.5, 0, 3),
    '&.Mui-expanded': {
      minHeight: 48,
    },
  },
  accordionSummaryContent: {
    '&.Mui-expanded': {
      margin: theme.spacing(1.5, 0),
    },
  },
  filterIcon: {
    marginRight: theme.spacing(0.5),
    marginTop: -2,
  },
  filters: {
    display: 'block',
    padding: theme.spacing(1, 1, 2, 2),
  },
  search: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  },
  searchInput: {
    background: '#fff',
  },
}));

interface FilterProps extends Pick<EquipmentListControlsProps, 'listDispatch' | 'listState'> {
  items: string[];
  label: string;
  type: 'categories' | 'types';
}
const Filter = ({ listDispatch, listState, items, label, type }: FilterProps) => {
  const classes = useFilterStyles();
  return (
    <FormControl className={classes.formControl} fullWidth size="small" variant="outlined">
      <InputLabel htmlFor={`equipment-filter-${type}`}>{label}</InputLabel>
      <Select
        multiple
        value={listState[type]}
        onChange={(event) => listDispatch({ type, value: event.target.value as string[] })}
        className={classes.select}
        label={label}
        inputProps={{
          name: `equipment-filter-${type}`,
          id: `equipment-filter-${type}`,
        }}
        MenuProps={{
          PaperProps: {
            style: {
              marginTop: 55,
            },
          },
          variant: 'menu',
        }}
        renderValue={(selected) => (selected as string[]).join(', ')}
        data-testid={
          identifiers.equipmentPage[type === 'categories' ? 'filterCategories' : 'filterTypes']
        }
      >
        {items.map((item: string) => (
          <MenuItem key={item} value={item}>
            <Checkbox color="default" checked={listState[type].indexOf(item) > -1} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useFilterStyles = makeStyles<Theme>((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1.5),
    textAlign: 'left',
  },
  select: {
    background: '#fff',
  },
}));

export default EquipmentListControls;
