import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
import { useCleanup, useCurrent, useEffectWhen } from '../../../helpers/hooks';
import identifiers from '../../../tests/identifiers';

interface SiteSearchProps {
  extValue?: string;
  handleSearch: (searchText: string) => void;
}

const SiteSearch = (props: SiteSearchProps) => {
  const { extValue, handleSearch } = props;
  const [value, setValue] = React.useState(extValue);

  useEffectWhen(
    () => {
      if (extValue !== value && extValue === undefined) {
        setValue(extValue);
      }
    },
    [extValue, value, setValue],
    [extValue]
  );

  const handleSearchRef = useCurrent(handleSearch);
  const debouncedHandleSearch = React.useMemo(
    () => debounce((searchText: string) => handleSearchRef.current(searchText), 300),
    [handleSearchRef]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setValue(searchText);
    debouncedHandleSearch(searchText);
  };

  useCleanup(() => {
    debouncedHandleSearch.cancel();
  });

  return (
    <TextField
      id="search-sites"
      style={{ backgroundColor: '#FFF' }}
      data-testid={identifiers.portfolioPage.searchInput}
      onChange={handleChange}
      placeholder="Find Site"
      value={value || ''}
      variant="outlined"
      size="small"
      fullWidth
      inputProps={{ autoComplete: 'off' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SiteSearch;
