import { getType, ActionType } from 'typesafe-actions';
import { fetchControlDataset } from './actions';
import { produce } from 'immer';
import { ControlDataset } from '../../../models';

export interface ControlDatasetEntityState {
  bySiteId: {
    [Key: string]: ControlDataset;
  };
}

export interface ControlDatasetStateSlice {
  entities: {
    controlDataset: ControlDatasetEntityState;
  };
}

const initialState = {
  bySiteId: {},
};

type ControlDatasetActions = ActionType<typeof fetchControlDataset>;

export const controlDatasetEntityReducer = produce(
  (draft: ControlDatasetEntityState, action: ControlDatasetActions) => {
    switch (action.type) {
      case getType(fetchControlDataset.success): {
        const { triggerAction, result: dataset } = action.payload;
        const { siteId, date } = triggerAction.payload;

        draft.bySiteId[siteId] = {
          ...draft.bySiteId[siteId],
          [date.toISOString()]: dataset,
        };
        break;
      }

      default:
        break;
    }
  },
  initialState
);
