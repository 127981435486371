import { string, object } from 'yup';
import { TextField, Typography, Grid, MenuItem } from '@material-ui/core';
import { SiteRole, User, AssignableRoles } from '../../../models';
import { Formik, FormikHelpers } from 'formik';
import { PrimaryButton, CancelButton } from '../../ui/Buttons';

export const AddExistingUserFormValidationSchema = object({
  user: object<User>().required('User is required'),
  role: string().min(0, 'Role is required.').required('Role is required.'),
});

interface FormValues {
  user?: User;
  role?: string;
}

export interface Props {
  siteId: number;
  users: User[];
  onCancel: () => void;
  onSave: (values: User) => void;
}

const AddExistingUserForm = (props: Props) => {
  const { onSave, onCancel, users } = props;

  const handleAddExistingUser = (
    values: Required<FormValues>,
    actions: FormikHelpers<FormValues>
  ) => {
    const role = values.role as SiteRole;
    const existingUser: User = {
      ...values.user,
      siteRoles: [...values.user.siteRoles, { siteId: props.siteId, role }],
    };
    onSave(existingUser);
    actions.setSubmitting(false);
  };

  const handleCancel = () => {
    onCancel();
  };

  const initialValues: FormValues = {
    user: undefined,
    role: undefined,
  };

  return (
    <Formik
      validationSchema={AddExistingUserFormValidationSchema}
      // @ts-ignore todo: fix type
      onSubmit={handleAddExistingUser}
      // @ts-ignore todo: fix type
      initialValues={initialValues}
      children={(formikProps) => {
        const {
          touched,
          errors,
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValidating,
          isValid,
          dirty,
          setFieldTouched,
          setFieldValue,
        } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2} style={{ marginBottom: 0 }}>
              <Grid item>
                <Typography variant="h5">Add Existing User</Typography>
              </Grid>
              <Grid item>
                <TextField
                  data-testid={'add-existing-user-select'}
                  InputLabelProps={{
                    shrink: Boolean(values.user),
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  select
                  label="Users"
                  id="user"
                  name="user"
                  onChange={(event) => {
                    const userId = event.target.value;
                    if (userId) {
                      const selectedUser = users.filter((user) => user.userId === Number(userId));
                      if (selectedUser.length > 0) {
                        setFieldValue('user', selectedUser[0], true);
                      }
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched('user', true);
                  }}
                  disabled={isSubmitting}
                  value={values.user ? values.user.userId : ''}
                  error={touched.user && Boolean(errors.user)}
                  helperText={touched.user ? errors.user : null}
                >
                  {users.map((user) => (
                    <MenuItem key={user.userId} value={user.userId}>
                      {`${user.name} - ${user.emailAddress}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  data-testid={'add-existing-role-select'}
                  InputLabelProps={{
                    shrink: Boolean(values.role),
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  select
                  label="Role"
                  name="role"
                  id="role"
                  onChange={handleChange}
                  onBlur={() => {
                    setFieldTouched('role', true);
                  }}
                  disabled={isSubmitting}
                  value={values.role || ''}
                  error={touched.role && Boolean(errors.role)}
                  helperText={touched.role ? errors.role : null}
                >
                  {AssignableRoles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item container justifyContent="flex-end" spacing={1}>
                <Grid item style={{ marginRight: 'auto' }}>
                  <CancelButton data-testid="cancel-add-existing-user" onClick={handleCancel}>
                    Cancel
                  </CancelButton>
                </Grid>
                <Grid item>
                  <PrimaryButton
                    type="submit"
                    disabled={isSubmitting || isValidating || !isValid || !dirty}
                  >
                    Add
                  </PrimaryButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default AddExistingUserForm;
