import React from 'react';
import * as d3 from 'd3';
import { ScreenSize } from './types';

interface Props {
  style?: React.CSSProperties;
  inset?: boolean;
  size: ScreenSize;
  offset?: number;
  xScale: d3.ScaleTime<number, number>;
  format?: (d: Date | d3.NumberValue) => string;
}

class XAxis extends React.Component<Props> {
  groupRef: React.RefObject<SVGGElement> = React.createRef();

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  render() {
    return <g ref={this.groupRef} />;
  }

  private makeAxis() {
    if (this.props.inset) {
      const axis = d3.axisTop(this.props.xScale);
      if (this.props.format) {
        axis.tickFormat(this.props.format);
      }
      return axis;
    } else {
      const axis = d3.axisBottom(this.props.xScale);
      if (this.props.format) {
        axis.tickFormat(this.props.format);
      }
      return axis;
    }
  }

  private update() {
    if (this.groupRef.current) {
      const g = d3.select(this.groupRef.current);
      g.attr('transform', `translate(0,${this.props.size.height + (this.props.offset || 0)})`)
        .call(this.makeAxis())
        .selectAll('.tick')
        .select('text')
        .attr('transform', (d) => {
          const xScale = this.props.xScale(d as Date) ?? 0;

          // move first tick label to the right a bit
          if (Math.abs(xScale - this.props.xScale.range()[0]) < 1) {
            return 'translate(24,0)';
          }
          // move last tick label to the left a bit
          if (Math.abs(xScale - this.props.xScale.range()[1]) < 1) {
            return 'translate(-26,0)';
          }
          return '';
        });
    }
  }
}

export default XAxis;
