export enum TimePeriod {
  ALL = 'All',
  MONTHLY = 'Monthly',
  MONTH_TO_DATE = 'Month to Date',
  YEARLY = 'Yearly',
  YEAR_TO_DATE = 'Year to Date',
}

export enum ReportType {
  DELIVERED_ENERGY = 'Delivered Energy',
  FINANCIAL = 'Financial Performance',
  MAINTENANCE_SCHEDULE = 'Site Maintenance Schedule',
}

export interface GenerateReportRequest {
  reportId: number;
  reportName: string;
  siteId: number;
  startTS?: string;
  endTS?: string;
  additionalRecipientEmails: string[];
}

export interface ExportReportRequest {
  siteId: number;
  tagNames: string[];
  historicalTS: string;
  modernTS: string;
  rawTimestampsOnly: boolean;
  timeAlignMinutes?: number;
  additionalRecipientEmails: string[];
}

export interface ReportConfiguration {
  reportId: number;
  reportName: string;
  reportDescription: string;
  availableTimePeriods: TimePeriod[];
  startTS: string;
  endTS: string;
}
