import URI from 'urijs';
import { withData } from '../../utility/withData';
import Loading from '../../ui/Loading/Loading';
import { activateAccount } from '../../../state/entities';
import { RouteComponentProps } from 'react-router';
import { Typography } from '@material-ui/core';
import { isComplete, isError, getError } from '../../../state/request';
import { withRouter } from 'react-router-dom';

interface MatchParams {
  email: string;
  key: string;
}

const getActivateAction = () => {
  // eslint-disable-next-line no-restricted-globals
  const queryParams = URI.parseQuery(location.search);
  const { email, key } = queryParams as URI.QueryDataMap;
  if (email && typeof email === 'string' && key && typeof key === 'string') {
    return activateAccount.request(email, key);
  } else {
    throw new Error('Invalid request parameters');
  }
};

/**
 * Implements activate account redirect.
 *
 * On mount triggers activate account request with parsed url parameters. If
 * parameters are invalid or request fails displays request error. Otherwise
 * redirects user to homepage.
 */
export const ActivateAccount = withRouter(
  withData<RouteComponentProps<MatchParams>>(
    (_rootState, _ownProps) => {
      return getActivateAction();
    },
    {
      isLoaded: (rootState, _ownProps) => isComplete(rootState, getActivateAction()),
      renderLoader: () => <Loading />,
      isError: (rootState, _ownProps) => isError(rootState, getActivateAction()),
      renderError: (rootState) => {
        const error = getError(rootState, getActivateAction());
        if (!error) {
          return null;
        }
        return (
          <div>
            <Typography>Error Activating account</Typography>
            <pre>{error.message}</pre>
          </div>
        );
      },
    }
  )(() => <div>Redirecting...</div>)
);
