import React from 'react';
import { getSiteContext } from '../../../state/contexts/site';
import { useSelector } from 'react-redux';
import { queryAlarmSummaries, getAlarmSummariesByQuery } from '../../../state/entities';
import { AlarmDetails } from '../../../models';
import { useRequest, useParamSelector } from '../../../helpers/hooks';
import { AlarmHistory } from './AlarmHistory';

const ONE_HOUR = 1000 * 60 * 60;
export const AlarmHistoryLoader: React.FC<{ alarmDetails: AlarmDetails }> = (props) => {
  const { alarmDetails } = props;
  const alarmTagId = alarmDetails.alarmTag.id;

  const site = useSelector(getSiteContext);
  const { id: siteId } = site;

  const now = new Date(Date.now());
  now.setHours(23, 59, 59, 999);
  // we are rounding the range for this query so the action will be consistent
  // across renders
  const [requestKey, requestStatus] = useRequest(
    queryAlarmSummaries.request(siteId, {
      tagIds: [alarmTagId],
      start: new Date(now.getTime() - ONE_HOUR * 24 * 90),
      end: now,
    })
  );

  const alarmSummaries = useParamSelector(getAlarmSummariesByQuery)(requestKey.meta.uid);

  return (
    <AlarmHistory
      activeAlarmTriggerTS={alarmDetails.activatedAt}
      range={90}
      alarmSummaries={alarmSummaries}
      requestStatus={requestStatus}
      siteId={siteId}
    />
  );
};
