import { withData } from '../../utility/withData';
import { RouteComponentProps } from 'react-router';
import AlertPageContainer from './AlertPageContainer';
import { fetchCustomer, fetchSiteAlerts } from '../../../state/entities';
import { getSiteContext } from '../../../state/contexts/site';
import { getProfileContext } from '../../../state/contexts/auth/selectors';
import { Site } from '../../../models';
import { every, some, compact } from 'lodash';
import { isComplete, isError, getError, Lifecycle } from '../../../state/request';
import { RequestStatus } from '../../utility/RequestStatus';
import { withRouter } from 'react-router-dom';

interface AlertRouteMatch {
  siteId: string;
}

const getLoadActions = (site: Site, isSystemAdmin: boolean) => {
  const { id: siteId } = site;
  const actions = [fetchCustomer.request(undefined), fetchSiteAlerts.request(siteId)];
  if (site.customerId) {
    actions.push(fetchCustomer.request(site.customerId, isSystemAdmin));
  }
  return actions;
};

export const AlertPageLoader = withRouter(
  withData<RouteComponentProps<AlertRouteMatch>>(
    (rootState, _ownProps) => {
      const site = getSiteContext(rootState);
      const { isSystemAdmin } = getProfileContext(rootState);
      return getLoadActions(site, isSystemAdmin);
    },
    {
      isLoaded: (rootState, _ownProps) => {
        const site = getSiteContext(rootState);
        const { isSystemAdmin } = getProfileContext(rootState);
        return every(getLoadActions(site, isSystemAdmin).map((a) => isComplete(rootState, a)));
      },
      renderLoader: () => <RequestStatus status={Lifecycle.PENDING} />,
      isError: (rootState, _ownProps) => {
        const site = getSiteContext(rootState);
        const { isSystemAdmin } = getProfileContext(rootState);
        return some(getLoadActions(site, isSystemAdmin).map((a) => isError(rootState, a)));
      },
      renderError: (rootState, _ownProps) => {
        const site = getSiteContext(rootState);
        const { isSystemAdmin } = getProfileContext(rootState);
        const errors = compact(
          getLoadActions(site, isSystemAdmin).map((a) => getError(rootState, a))
        );

        if (errors[0]) {
          return <div>{errors[0].message}</div>;
        }

        return <div>Error loading page.</div>;
      },
    }
  )(AlertPageContainer)
);
