import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { CancelButton, PrimaryButton, UIButtonProps } from './Buttons';

export interface ConfirmDialogProps extends Partial<DialogProps> {
  cancelButtonProps?: UIButtonProps;
  cancelLabel?: string;
  confirmButtonProps?: UIButtonProps;
  confirmLabel?: string;
  message?: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
}

const ConfirmDialog = ({
  cancelButtonProps = {},
  cancelLabel = 'Cancel',
  confirmButtonProps = {},
  confirmLabel = 'Yes',
  message = 'Are you sure you want to continue?',
  onCancel,
  onConfirm,
  open = true,
  title,
  ...dialogProps
}: ConfirmDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog open={open} {...dialogProps}>
      <DialogContent>
        {title && <Typography variant="h6">{title}</Typography>}
        <Typography component="div" className={classes.body} variant="body1">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <CancelButton onClick={onCancel} {...cancelButtonProps}>
          {cancelLabel}
        </CancelButton>
        <PrimaryButton onClick={onConfirm} {...confirmButtonProps}>
          {confirmLabel}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  actions: {
    justifyContent: 'space-between',
    padding: '0 16px 16px',
  },
  body: {
    margin: '1em 0',
  },
}));

export default ConfirmDialog;
