import logger from './logger';

/**
 * Specifies an interface from another interface by removing fields given by
 * <O> and adding fields given by <A>
 *
 * example:
 *
 * interface Test {
 *    user: User;
 *    another: string;
 * }
 *
 * export type TestNorm = Normalized<Test, 'user', { userId: number }>;
 *
 * equivalent to:
 * interface TestNorm {
 *    userId: number;
 *    another: string;
 * }
 *
 */
export type Normalized<T, O extends string | number, A> = Omit<T, O> & A;

/* eslint-disable @typescript-eslint/no-explicit-any */

export class NormalizationError extends Error {
  public message: string = '';

  public context: any;

  constructor(message: string, context?: any) {
    super(message);
    this.context = context;
    Object.setPrototypeOf(this, NormalizationError.prototype);
  }
}

/**
 * Consume normalization error and log warning.
 *
 * This will primarily be used in places where a miss-configuration, or missing
 * data should not cause a page to stop functioning, but which should be
 * addressed, and visible.
 *
 * For example if a tag cannot be found based on configuration for the control
 * summary page.
 */
export const soften = <T, P extends any[]>(normalizer: (...args: P) => T) => {
  return (...args: P) => {
    try {
      return normalizer(...args);
    } catch (e) {
      if (e instanceof NormalizationError) {
        logger.warn('Denormalization failed and ignored', args);
        return undefined;
      }
      throw e;
    }
  };
};

/**
 * Consume normalization error with no effect.
 *
 * This method should rarely be used, as a problem with denormalization
 * should indicate a logic error, or misconfiguration. This method will
 * primarily be useful in determining if a denormalization error is occuring.
 */
export const ignore = <T, P extends any[]>(normalizer: (...args: P) => T) => {
  return (...args: P) => {
    try {
      return normalizer(...args);
    } catch (e) {
      if (e instanceof NormalizationError) {
        return undefined;
      }
      throw e;
    }
  };
};
