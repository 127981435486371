import React from 'react';
import { AlarmsPageQuery } from '../../../state/contexts/alarmsPage';
import { AlarmSummary, Site } from '../../../models';
import { AlarmsTableContainer } from './AlarmsTableContainer';
import {
  Grid,
  FormControlLabel,
  Toolbar,
  makeStyles,
  createStyles,
  Button,
  Theme,
  Checkbox,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import { AlarmQueryModal } from './AlarmQueryModal';
import { AlarmDetailsLoader } from './AlarmDetailsLoader';
import { AlarmsQueryInfobar } from './AlarmsQueryInfobar';
import { Lifecycle, SimpleRequestStatus } from '../../../state/request';
import { PathPattern, AlarmDetailsRouteMatch } from '../../../routing';
import { Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { RequestStatus } from '../../utility/RequestStatus';

const PAGE_SIZE = 25;

export interface Props {
  site: Site;
  page: number;
  query?: AlarmsPageQuery;
  isActiveOnly: boolean;
  alarmSummaries: AlarmSummary[];
  requestStatus?: SimpleRequestStatus;
  setQuery: (query: AlarmsPageQuery | null) => void;
  setPage: (page: number) => void;
  setIsActiveOnly: (isActiveOnly: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      position: 'relative',
      flex: 1,
      overflow: 'auto',
      width: '100%',
    },
    statusWrapper: {
      top: '46px',
      left: '0',
      width: '100%',
      padding: 24,
      textAlign: 'center',
      position: 'absolute',
    },
    header: {
      padding: theme.spacing(2),
    },
    footer: {
      borderTop: '1px solid #E8E8E8',
    },
  })
);

export const AlarmsPage: React.FC<Props> = (props) => {
  const {
    query,
    page,
    alarmSummaries,
    setPage,
    site,
    setQuery,
    requestStatus,
    isActiveOnly,
    setIsActiveOnly,
  } = props;
  const startIdx = 25 * ((page || 1) - 1);
  const endIdx = Math.min(25 * ((page || 1) - 1) + 25, alarmSummaries.length);

  const classes = useStyles();

  const isLoaded = requestStatus === Lifecycle.COMPLETED;
  const isPending = !requestStatus || requestStatus === Lifecycle.PENDING;
  const error = typeof requestStatus === 'object' ? requestStatus : undefined;

  const [queryModalOpen, setQueryModalOpen] = useState<boolean>(false);

  const getResultCount = () => {
    return (
      <>
        {startIdx + 1} - {endIdx} of {alarmSummaries.length}
      </>
    );
  };

  return (
    <Grid data-testid="alarms-page" container className={classes.outer}>
      <Grid item container className={classes.header}>
        <Grid item container spacing={2} alignItems="baseline">
          <Grid item style={{ marginRight: 'auto' }}>
            <Button variant="outlined" onClick={() => setQueryModalOpen(true)}>
              {!query ? 'Create Query' : 'Edit Query'}
            </Button>
          </Grid>
          {!query && (
            <>
              <Grid item>
                <FormControlLabel
                  style={{ marginRight: 0 }}
                  control={
                    <Checkbox
                      onChange={(e) => setIsActiveOnly(e.target.checked)}
                      checked={isActiveOnly}
                      name="activeOnly"
                    />
                  }
                  label="Active Only"
                />
              </Grid>
            </>
          )}
          {query && (
            <Grid item>
              <Button variant="outlined" onClick={() => setQuery(null)}>
                Clear Query
              </Button>
            </Grid>
          )}
        </Grid>

        <Grid item container alignItems="flex-end" style={{ marginTop: 8 }}>
          <Grid item style={{ marginRight: 'auto' }}>
            {query ? (
              <div>
                <AlarmsQueryInfobar currentQuery={query} site={site} />
              </div>
            ) : isActiveOnly ? (
              <div>Showing all active alarms</div>
            ) : (
              <div>Showing active and recently resolved alarms</div>
            )}
          </Grid>
          {alarmSummaries.length > 0 && isLoaded && (
            <Grid item data-testid="alarms-page-result-count">
              {getResultCount()}
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item className={classes.body}>
        {/* Table */}
        <AlarmsTableContainer siteId={site.id} alarmSummaries={isLoaded ? alarmSummaries : []} />

        {(isPending || error) && (
          <div className={classes.statusWrapper}>
            <RequestStatus status={requestStatus} />
          </div>
        )}
      </Grid>

      {/* Pagination */}
      <Grid item className={classes.footer}>
        <Toolbar>
          <Grid justifyContent="center" container alignItems="center" spacing={2}>
            <Grid item>
              <Pagination
                count={Math.ceil(alarmSummaries.length / PAGE_SIZE)}
                page={page || 1}
                variant="outlined"
                shape="rounded"
                onChange={(_e, newPage) => setPage(newPage)}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Grid>

      {queryModalOpen && (
        <AlarmQueryModal
          site={site}
          currentQuery={query}
          onCancel={() => {
            setQueryModalOpen(false);
          }}
          onSubmitQuery={(newQuery) => {
            setQuery(newQuery);
            setQueryModalOpen(false);
          }}
        />
      )}

      <Route
        path={PathPattern.ALARM_DETAILS}
        // @ts-ignore todo: fix type
        render={(route: RouteComponentProps<AlarmDetailsRouteMatch>) => {
          const { alarmId, alarmTagId } = route.match.params;
          return <AlarmDetailsLoader alarmId={alarmId} alarmTagId={alarmTagId} />;
        }}
      />
    </Grid>
  );
};
