import * as d3 from 'd3';
import { LinkedSeriesValue, SeriesValue } from './types';
import { flatten, min, max } from 'lodash';

export const interpolate = (prev: LinkedSeriesValue, next: LinkedSeriesValue, ts: number) => {
  const pTs = prev.x.getTime();
  const nTs = next.x.getTime();
  return d3.interpolateNumber(prev.y, next.y)((ts - pTs) / (nTs - pTs));
};

export const invertSeries = (series: SeriesValue[] | undefined) => {
  if (series === undefined) {
    return [];
  }
  return series.map((value) => ({ ...value, y: value.y * -1 }));
};

export const timeFormat = (d: Date) => {
  const s = d3.timeFormat('%I:%M %p')(d);
  return s.length > 0 && s[0] === '0' ? s.slice(1, s.length) : s;
};

export const timeFormatOffset = (offset: number) => (d: Date) => {
  const minutes = d.getMinutes();
  let hours = d.getUTCHours() + offset / 60;
  // After applying offset wrap time to 24 hours
  hours = hours < 0 ? 24 + hours : hours;
  hours = hours > 24 ? hours - 24 : hours;
  const formatedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formatedMinutes = minutes < 10 ? '0' + minutes : minutes;
  return `${formatedHours}:${formatedMinutes} ${hours >= 12 ? 'PM' : 'AM'}`;
};

export const getDefaultMargins = () => {
  return {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };
};

export const getXLabel = (scale: d3.ScaleTime<number, number>, x: number) => {
  return timeFormat(scale.invert(x));
};

export const getClosestValue = (series: SeriesValue[], xValue: Date | undefined) => {
  if (!xValue) {
    return null;
  }
  const ts = xValue.getTime();
  for (let i = 0; i < series.length - 1; i++) {
    if (series[i].x.getTime() <= ts && series[i + 1].x.getTime()) {
      return series[i].y;
    }
  }
  return null;
};

export const comparePoints = (a: { x: number | Date }, b: { x: number | Date }) => {
  if (a.x < b.x) {
    return -1;
  }
  if (a.x > b.x) {
    return 1;
  }
  return 0;
};

export const getClosestPoint = (seriesList: SeriesValue[][], xValue: Date | undefined) => {
  if (!xValue) {
    return null;
  }
  const ts = xValue.getTime();
  const values = flatten(seriesList).sort(comparePoints);

  for (let i = 1; i < values.length - 1; i++) {
    if (values[i].x.getTime() <= ts && values[i + 1].x.getTime() > ts) {
      if (ts - values[i].x.getTime() > values[i + 1].x.getTime() - ts) {
        return values[i + 1];
      } else {
        return values[i];
      }
    }
  }
  return null;
};

export const sumSeriesOverRange = (
  series: SeriesValue[],
  range: { start: number; end: number }
) => {
  return series.reduce((acc, v) => {
    const ts = v.x.getTime();
    if (ts >= range.start && ts < range.end) {
      return acc + v.y;
    }
    return acc;
  }, 0);
};

export const getSeriesRange = (series: SeriesValue[]) => {
  if (series.length === 0) {
    return undefined;
  }
  const minX = series[0].x;
  const maxX = series[series.length - 1].x;
  const yValues = series.map((p) => p.y);
  const maxY = max(yValues);
  const minY = min(yValues);
  return {
    xMin: minX,
    xMax: maxX,
    yMin: minY || 0,
    yMax: maxY || 0,
  };
};
