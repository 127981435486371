import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../state';
import { UserProfile } from '../../../models';
import { getUserProfile } from '../../../state/entities';

interface Props {
  userProfile?: UserProfile;
  render: (profile: UserProfile) => React.ReactNode;
}

interface OwnProps {
  render: (profile: UserProfile) => React.ReactNode;
}

class WithProfile extends React.Component<Props & OwnProps> {
  render() {
    return this.props.userProfile ? this.props.render(this.props.userProfile) : null;
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): Props => {
  const profile = getUserProfile(state);
  return {
    render: ownProps.render,
    userProfile: profile,
  };
};

export default connect(mapStateToProps)(WithProfile);
