import HelpIcon from '@material-ui/icons/Help';
import { IconButton, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';

declare global {
  interface Window {
    zE?: (context: string, command: string) => void;
  }
}

export const HelpWidget = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return window.zE ? (
    <Tooltip title="Help">
      <IconButton
        color="inherit"
        aria-haspopup="true"
        data-testid="help-button"
        onClick={() => {
          if (window.zE) {
            window.zE('webWidget', 'show');
            window.zE('webWidget', 'toggle');
          }
        }}
        size={isSmallScreen ? 'small' : 'medium'}
      >
        <HelpIcon style={{ color: '#B2B2B2' }} />
      </IconButton>
    </Tooltip>
  ) : null;
};
