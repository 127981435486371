import { ActionType, createAction } from 'typesafe-actions';
import { PayloadMetaAction } from 'typesafe-actions/src/types';
import { ApiError } from '../api/util/ApiError';
import { v1 } from 'uuid';

export type ActionsOf<T> = ActionType<T[keyof T]>;

export interface ApiActionMeta {
  uid: string;
  triggeredTs: number;
}

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface ApiActionTriad<
  Type1 extends string,
  Type2 extends string,
  Type3 extends string,
  PArgs extends any[],
  Payload extends any,
  Result
> {
  request: (...args: PArgs) => PayloadMetaAction<Type1, Payload, ApiActionMeta>;
  success: (
    triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>,
    result: Result
  ) => PayloadMetaAction<
    Type2,
    { triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>; result: Result },
    undefined
  >;
  error: (
    triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>,
    error: ApiError
  ) => PayloadMetaAction<
    Type3,
    { triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>; error: ApiError },
    undefined
  >;
}

/**
 * Helper method to create three action creators corresponding to the request,
 * response, error events for an api request.
 *
 * Admittedly the type annotations make this difficult to follow. But they
 * allow the strings passed as the first three arguments to be used to infer
 * the action type.
 *
 * Usage:
 *                             note the extra call here ---+
 *             this will be the success result type --+    |
 *                                                    v    v
 *  export const fetchCustomer = createApiActions<Customer>()(
 *    'customer/fetch',
 *    'customer/fetchSuccess',
 *    'customer/fetchError',
 *    (customerId: number, includeSysAdmins?: boolean) => ({ customerId, includeSysAdmins})
 *  );
 *
 *  fetchCustomer.request(customerId, includeSysAdmins);
 *  fetchCustomer.success(customerResult);
 *  fetchCustomer.error(apiError);
 *
 * TODO: It may be possible to generate the action constants from a single
 * string, at the expense of even more complex type annotations.
 */
export const createApiActions = <Result extends any>() => {
  /**
   * We want to pass the `Result` type but have typescript infer the rest, to
   * do this we must use a nested function definition
   */
  const typeHelper = <
    Type1 extends string,
    Type2 extends string,
    Type3 extends string,
    Payload,
    PArgs extends any[]
  >(
    type1: Type1,
    type2: Type2,
    type3: Type3,
    payloader: (...args: PArgs) => Payload
  ): ApiActionTriad<Type1, Type2, Type3, PArgs, Payload, Result> => {
    const primaryCreator = createAction(
      type1,
      // @ts-ignore todo: fix type
      (resolve) =>
        (...args: PArgs) =>
          resolve(payloader(...args), { uid: v1(), triggeredTs: Date.now() })
    );
    const successCreator = createAction(
      type2,
      (resolve) =>
        (triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>, result: Result) =>
          resolve({ triggerAction, result })
    );
    const errorCreator = createAction(
      type3,
      (resolve) =>
        (triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>, error: ApiError) =>
          resolve({ triggerAction, error })
    );
    // @ts-ignore todo: fix type
    return { request: primaryCreator, success: successCreator, error: errorCreator };
  };
  return typeHelper;
};

export interface SubActionSet<
  Type1 extends string,
  Type2 extends string,
  Type3 extends string,
  Type4 extends string,
  PArgs extends any[],
  Payload,
  Result
> {
  start: (...args: PArgs) => PayloadMetaAction<Type1, Payload, ApiActionMeta>;
  update: (
    triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>,
    result: Result
  ) => PayloadMetaAction<
    Type2,
    { triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>; result: Result },
    undefined
  >;
  error: (
    triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>,
    error: ApiError
  ) => PayloadMetaAction<
    Type3,
    { triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>; error: ApiError },
    undefined
  >;
  stop: (
    triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>
  ) => PayloadMetaAction<
    Type4,
    { triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta> },
    undefined
  >;
}

/**
 * Helper method to create four action creators corresponding to the start,
 * update, stop, and error actions for a subscription.
 *
 * Usage:
 *                             note the extra call here ---+
 *             this will be the success result type --+    |
 *                                                    v    v
 *  export const subCustomer = createSubActions<Customer>()(
 *    'subCustomer/start',
 *    'subCustomer/update'
 *    'subCustomer/stop'
 *    'subCustomer/error'
 *    (customerId: number, includeSysAdmins?: boolean) => ({ customerId, includeSysAdmins})
 *  );
 *
 *  subCustomer.start(customerId, includeSysAdmins);
 *  subCustomer.update(customerResult);
 *  subCustomer.stop();
 *  subCustomer.error(apiError);
 */
export const createSubActions = <Result extends any>() => {
  /**
   * We want to pass the `Result` type but have typescript infer the rest, to
   * do this we must declare a separate function
   */
  const typeHelper = <
    Type1 extends string,
    Type2 extends string,
    Type3 extends string,
    Type4 extends string,
    Payload,
    PArgs extends any[]
  >(
    type1: Type1,
    type2: Type2,
    type3: Type3,
    type4: Type4,
    payloader: (...args: PArgs) => Payload
  ): SubActionSet<Type1, Type2, Type3, Type4, PArgs, Payload, Result> => {
    const startCreator = createAction(
      type1 as Type1,
      // @ts-ignore todo: fix type
      (resolve) =>
        (...args: PArgs) =>
          resolve(payloader(...args), { uid: v1(), triggeredTs: Date.now() })
    );
    const updateCreator = createAction(
      type2 as Type2,
      (resolve) =>
        (triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>, result: Result) =>
          resolve({ triggerAction, result })
    );
    const errorCreator = createAction(
      type3 as Type3,
      (resolve) =>
        (triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>, error: ApiError) =>
          resolve({ triggerAction, error })
    );
    const stopCreator = createAction(
      type4 as Type4,
      (resolve) => (triggerAction: PayloadMetaAction<Type1, Payload, ApiActionMeta>) =>
        resolve({ triggerAction })
    );
    // @ts-ignore todo: fix type
    return { start: startCreator, update: updateCreator, error: errorCreator, stop: stopCreator };
  };
  return typeHelper;
};
