import { HasTags } from './tag';
import { Equipment } from './equipment';
import { flatMap } from 'lodash';
import { AlarmPriority } from './alarm';

export interface Site extends HasTags {
  id: number;
  name: string;
  locationGeoJson: string;
  timeZone: string;
  ianaTimeZone: string;
  imageFileLink: string;
  postalCode: string;
  utilityId: number;
  isMVoult: boolean;
  // site schematic for op summary
  singleLineImageFileLink?: string;
  customerName: string;
  nodes: SiteNode[];
  webPortalLayoutJson: SiteLayoutConfig;
  customerId: number;
  commissionedOn?: string;
}

export interface SiteNode extends HasTags {
  id: number;
  name: string;
  imageFileLink: string;
  locationGeoJson: string;
  equipment: Equipment[];
}

export interface SiteKPI {
  netLoad: number;
  load: number;
  storageStatus: 'charging' | 'discharging' | 'idle' | 'alarm' | 'fault';
  storageDischarge: number;
  powerOutput: number;
  dailyEnergyGeneration: number;
}

export enum EnrollmentStatus {
  Ineligible = 'Not Eligible',
  Eligible = 'Eligible',
  Pending = 'Pending',
  Enrolled = 'Enrolled',
  Error = 'Error',
  Unenrolled = 'Unenrolled',
  OptOut = 'Opted Out',
  OptIn = 'Opted In',
  NonResponsive = 'Non-Responsive',
}

export interface SiteSummary {
  siteId: number;
  name: string;
  customerId: number;
  customerName: string;
  fullAddress: string;
  zipCode: string;
  utilityId?: number;
  utilityName?: string;
  totalPower: number;
  currentKPIs: SiteKPI;
  activeAlarmCount: number;
  highestActiveAlarmStatus?: AlarmPriority;
  highestActiveAlarmCount?: number;
  installationDate: string;
  gridServicesEnrollmentStatus: keyof typeof EnrollmentStatus;
}

export const getSiteEquipment = (site: Site) => {
  return flatMap(site.nodes.map((node) => node.equipment));
};

export const getSiteTags = (site: Site) => {
  let allTags = site.tags;
  site.nodes.forEach((node) => {
    allTags = allTags.concat(node.tags);
  });
  getSiteEquipment(site).forEach((equipment) => {
    allTags = allTags.concat(equipment.tags);
  });
  return allTags;
};

export const getSiteEquipmentById = (site: Site, equipmentId: number) => {
  const allEquipment = getSiteEquipment(site);
  return allEquipment.find((equipment) => equipment.id === equipmentId);
};

export const getSiteTagById = (site: Site, tagId: string) => {
  const allTags = getSiteTags(site);
  return allTags.find((tag) => tag.id === tagId);
};

export interface SiteLayoutConfig {
  viewSettings?: {
    hideControlSummary?: boolean;
  };
  operationalSummary?: {
    hideRateChart?: boolean;
  };
  lobby?: {
    powerFlow?: {
      elementAttributes: {
        [key: string]: string[];
      };
    };
    kPIs?: {
      elements: KPIElement[];
    };
  };
}

/*
 * Possible keys for lobby.powerFlow.elementAttributes
 */
export enum ComponentId {
  Generator = 'Generator',
  Solar = 'Solar',
  Utility = 'Utility',
  Storage = 'Storage',
  Load = 'Load',
}

export const SocComponentId = 'SOC';

interface KPIElement {
  title: string;
  subtitle: string;
  attributes: string[];
}
