import { createSvgIcon } from '@material-ui/core';

export const FilterIcon = createSvgIcon(
  <path d="M19.31 18.9L22.39 22L21 23.39L17.88 20.32C17.19 20.75 16.37 21 15.5 21C13 21 11 19 11 16.5C11 14 13 12 15.5 12C18 12 20 14 20 16.5C20 17.38 19.75 18.21 19.31 18.9M15.5 19C16.88 19 18 17.88 18 16.5C18 15.12 16.88 14 15.5 14C14.12 14 13 15.12 13 16.5C13 17.88 14.12 19 15.5 19M21 4V6H3V4H21M3 16V14H9V16H3M3 11V9H21V11H18.97C17.96 10.37 16.77 10 15.5 10C14.23 10 13.04 10.37 12.03 11H3Z" />,
  'Filter'
);

export const PinIcon = createSvgIcon(
  <path d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12M8.8,14L10,12.8V4H14V12.8L15.2,14H8.8Z" />,
  'Pin'
);

export const TagIcon = createSvgIcon(
  <path d="M6.5 10C7.3 10 8 9.3 8 8.5S7.3 7 6.5 7 5 7.7 5 8.5 5.7 10 6.5 10M9 6L16 13L11 18L4 11V6H9M9 4H4C2.9 4 2 4.9 2 6V11C2 11.6 2.2 12.1 2.6 12.4L9.6 19.4C9.9 19.8 10.4 20 11 20S12.1 19.8 12.4 19.4L17.4 14.4C17.8 14 18 13.5 18 13C18 12.4 17.8 11.9 17.4 11.6L10.4 4.6C10.1 4.2 9.6 4 9 4M13.5 5.7L14.5 4.7L21.4 11.6C21.8 12 22 12.5 22 13S21.8 14.1 21.4 14.4L16 19.8L15 18.8L20.7 13L13.5 5.7Z" />,
  'Tag'
);

export const UnpinIcon = createSvgIcon(
  <path d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />,
  'Unpin'
);

export const InfoOutline = createSvgIcon(
  <path d="M12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 C7.02943725,21 3,16.9705627 3,12 C3,7.02943725 7.02943725,3 12,3 Z M12,4.44 C7.82472729,4.44 4.44,7.82472729 4.44,12 C4.44,16.1752727 7.82472729,19.56 12,19.56 C16.1752727,19.56 19.56,16.1752727 19.56,12 C19.56,7.82472729 16.1752727,4.44 12,4.44 Z M12.6916992,9.59777344 L12.6916992,18.03 L11.2441406,18.03 L11.2441406,9.59777344 L12.6916992,9.59777344 Z M12.6916992,6.40998047 L12.6916992,8.02365234 L11.2441406,8.02365234 L11.2441406,6.40998047 L12.6916992,6.40998047 Z" />,
  'InfoOutline'
);

export const HighPriority = createSvgIcon(
  <path d="M12,3 L21,12 L12,21 L3,11.9326683 L12,3 Z M13.2402,14.6510723 L10.8498,14.6510723 L10.8498,17.0498753 L13.2402,17.0498753 L13.2402,14.6510723 Z M13.2402,6.27680798 L10.8498,6.27680798 L10.8498,13.4588529 L13.2402,13.4588529 L13.2402,6.27680798 Z" />,
  'HighPriority'
);
