import { PortfolioApi, PortfolioResponse, PortfolioQuery } from '../PortfolioApi';
import { sleep } from '../../helpers/async';
import { SubscriptionBase } from '../util/SubscriptionBase';
import { ApiConnection } from '../util/ApiConnection';

export class PortfolioSubscription extends SubscriptionBase<PortfolioResponse> {
  private apiAccess?: PortfolioApi;

  async *getGenerator(query: PortfolioQuery): AsyncGenerator<PortfolioResponse> {
    if (!this.apiAccess) return;

    this.interval = 1000 * 60 * 15;

    let portfolio = await this.apiAccess.querySiteSummaries(query);
    yield portfolio;
    while (!this.stopped) {
      await sleep(this.interval);
      portfolio = await this.apiAccess.querySiteSummaries(query);
      yield portfolio;
    }
  }

  start(query: PortfolioQuery) {
    this.apiAccess = new PortfolioApi(this.connection);
    this.generator = this.getGenerator(query);
  }

  updateConnection(connection: ApiConnection) {
    this.setConnection(connection);
    this.apiAccess = new PortfolioApi(this.connection);
  }
}
