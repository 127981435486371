import * as React from 'react';
import analytics from '../../helpers/analytics';
import { ErrorMessage } from '../ui/ErrorMessage';
import { Box } from '@material-ui/core';

interface State {
  error?: { message: string };
}

const DEFAULT_ERROR_MESSAGE = 'An unknwon error has occured, please refresh.';

interface InternalErrorBoundaryProps {
  className: string;
  overrideMessage?: string;
}

class InternalErrorBoundary extends React.Component<
  React.PropsWithChildren<InternalErrorBoundaryProps>,
  State
> {
  state: State = {
    error: undefined,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error: error });
    analytics.trackException(error, { errorInfo: JSON.stringify(errorInfo) });
  }

  renderError(props: InternalErrorBoundaryProps) {
    return (
      <Box className={props.className}>
        <ErrorMessage
          message={
            this.props.overrideMessage ||
            (this.state.error !== undefined ? this.state.error.message : DEFAULT_ERROR_MESSAGE)
          }
        />
      </Box>
    );
  }

  render() {
    if (this.state.error) {
      return this.renderError(this.props);
    }

    return this.props.children;
  }
}

export default InternalErrorBoundary;
