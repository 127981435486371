import { AxiosError } from 'axios';
import * as Yup from 'yup';
import { has } from 'lodash';

export interface PushError {
  statusCode: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  message: string;
}

export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError;
}

export function isPushError(error: unknown): error is PushError {
  return schemaPushError.isValidSync(error);
}
const schemaPushError = Yup.object({
  statusCode: Yup.number().required(),
  payload: Yup.mixed(),
  message: Yup.string().required(),
}).required();

export function isApiError(error: unknown): error is ApiError {
  // Validate object shape
  if (!schemaApiError.isValidSync(error)) {
    return false;
  }

  // Validate rawError type
  return !has(error, 'rawError') || isAxiosError(error.rawError) || isPushError(error.rawError);
}
const schemaApiError = Yup.object<Omit<ApiError, 'message'> & Pick<Partial<ApiError>, 'message'>>({
  rawError: Yup.mixed(),
  message: Yup.string().typeError().strict(true),
  actionId: Yup.string().required(),
  statusCode: Yup.number(),
}).required();

export class ApiError {
  public rawError?: AxiosError | PushError;

  public message: string;

  public actionId: string;

  public statusCode?: number;

  constructor(error?: AxiosError | PushError, message?: string) {
    this.rawError = error;
    this.actionId = 'null';
    if (message) {
      this.message = message;
    } else if (isAxiosError(error)) {
      this.statusCode = error.response?.status;
      const messageData = error.response?.data;
      if (typeof messageData === 'object') {
        if (messageData.Message) {
          this.message = messageData.Message;
        } else {
          this.message = JSON.stringify(messageData, null, 2);
        }
      } else {
        this.message = messageData;
      }
    } else if (error) {
      this.statusCode = error.statusCode;
      this.message = error.message;
    } else {
      this.message = '';
    }
  }
}
