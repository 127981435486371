import { ChangeEvent } from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Zoom,
  makeStyles,
} from '@material-ui/core';
import { number, string } from 'yup';
import { useFormikContext } from 'formik';
import identifiers from '../../../tests/identifiers';
import { getFormikInputProps } from '../../../helpers/form';
import { InstallerSteps, SolArkDetails } from './InstallerPage.types';
import { Utility, EnrollmentStatus } from '../../../models';
import { UtilityRate } from 'src/components/ui/form/UtilityRate';

export interface SolArkSiteDetailsFormProps {
  utilities: Utility[];
  setActiveStep: (step: number) => void;
  setImage: (imageUrl: string | false) => void;
  image: string | false;
}

const SolArkSiteDetailsForm = (props: SolArkSiteDetailsFormProps) => {
  const { utilities, setActiveStep, setImage, image } = props;
  const formik = useFormikContext<SolArkDetails>();
  const classes = useStyles();

  return (
    <Box marginTop={1.5}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <TextField
            fullWidth
            label="Grid Services Eligibility"
            {...getFormikInputProps(formik, 'gridServicesInitialStatus', 'select')}
          >
            {Object.keys(EnrollmentStatus)
              .filter((key) =>
                [
                  EnrollmentStatus.Ineligible,
                  EnrollmentStatus.Eligible,
                  EnrollmentStatus.OptIn,
                ].includes(EnrollmentStatus[key])
              )
              .map((key) => (
                <MenuItem key={key} value={key}>
                  {EnrollmentStatus[key]}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <UtilityRate utilities={utilities} />
        <Grid item>
          <TextField label="Customer E-mail" {...getFormikInputProps(formik, 'customerEmail')} />
        </Grid>

        <Grid item>
          <TextField
            label="Image"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            type="file"
            inputProps={{ accept: '.jpg,.jpeg,.png,.gif' }}
            {...getFormikInputProps(formik, 'image', 'text', {
              onChange: (event: ChangeEvent<unknown>) => {
                const imageFile = (event as ChangeEvent<HTMLInputElement>).currentTarget
                  ?.files?.[0];
                if (imageFile) {
                  setImage(URL.createObjectURL(imageFile));
                  formik.setFieldValue('imageFile', imageFile);
                } else {
                  setImage(false);
                  formik.setFieldValue('imageFile', '');
                }
              },
            })}
          />
        </Grid>
        {image && (
          <Grid item>
            <Zoom in>
              <img
                alt="Installer Icon"
                className={classes.img}
                data-testid={identifiers.installerPage.image}
                src={image}
              />
            </Zoom>
          </Grid>
        )}
      </Grid>

      <Box marginBottom={2} marginTop={3}>
        <Typography variant="h6">Plant Details</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField label="Plant Id" {...getFormikInputProps(formik, 'partnerSiteId')} />
        </Grid>
      </Grid>

      <Box marginTop={2} textAlign="right">
        <Button
          onClick={() => {
            // Reset image field because the value can't be set programmatically for security reasons
            setImage(false);
            formik.setFieldValue('image', '');
            formik.setFieldValue('imageFile', '');

            setActiveStep(InstallerSteps.LOCATION);
          }}
        >
          Back
        </Button>
        <Button
          className={classes.marginLeft}
          color="primary"
          onClick={() => formik.submitForm()}
          variant="contained"
        >
          Provision
        </Button>
      </Box>
    </Box>
  );
};

export const SolArkSiteDetailsFormValidationSchema = {
  gridServicesInitialStatus: string().required('Field Required'),
  utilityId: number().required('Field Required').nullable(),
  rateScheduleId: number().required('Field Required').nullable(),
  customerEmail: string().email('Invalid E-mail Address').required('Field Required'),
  imageFile: string(),
  partnerSiteId: string()
    .required('Field Required')
    .matches(/^[0-9]+$/, 'Must be a 5 digit number')
    .min(5, 'Must be a 5 digit number')
    .max(5, 'Must be a 5 digit number'),
};

export const solArkSiteDetailsInitialValues = {
  partnerSiteId: '',
};

const useStyles = makeStyles((theme) => ({
  img: {
    maxHeight: 225,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 150,
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

export default SolArkSiteDetailsForm;
