import OptOutPage from './OptOutPage';
import { isNaN } from 'lodash';
import { useLocation } from 'react-router';
import { useMemo } from 'react';

export const OPT_OUT_PAGE_ERROR_MESSAGE =
  'There was a problem loading the page. Please make sure the link is correct and contact support if you need further assistance.';

const OptOutPageContainer = () => {
  const { search } = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const partnerRefIdRaw = urlSearchParams.get('partnerRefId');

  if (!partnerRefIdRaw || isNaN(+partnerRefIdRaw)) {
    console.error('Page cannot be rendered without partnerRefId');
    throw new Error(OPT_OUT_PAGE_ERROR_MESSAGE);
  }
  return <OptOutPage partnerRefId={parseInt(partnerRefIdRaw, 10)} />;
};

export default OptOutPageContainer;
