import { createApiActions, createSubActions } from '../../../helpers/actions';
import { AlarmSummary, AlarmDetails } from '../../../models';
import { AlarmSummaryQuery } from '../../../api/AlarmApi';

export const fetchAlarmSummaries = createApiActions<AlarmSummary[]>()(
  'alarm/fetchAlarmSummaries',
  'alarm/fetchSummariesSuccess',
  'alarm/fetchSummariesError',
  (siteId: number, duration: number) => {
    return { siteId, duration };
  }
);

export const subAlarmSummaries = createSubActions<AlarmSummary[]>()(
  'alarm/subStart',
  'alarm/subUpdate',
  'alarm/subError',
  'alarm/subStop',
  (siteId: number, duration: number) => {
    return { siteId, duration };
  }
);

export const subActiveAlarms = createSubActions<AlarmSummary[]>()(
  'activeAlarm/subStart',
  'activeAlarm/subUpdate',
  'activeAlarm/subError',
  'activeAlarm/subStop',
  (siteId: number) => {
    return { siteId };
  }
);

export const fetchAlarmDetails = createApiActions<AlarmDetails>()(
  'alarm/fetchDetails',
  'alarm/fetchDetailsSuccess',
  'alarm/fetchDetailsError',
  (siteId: number, alarmTagId: string, alarmId: string) => ({ siteId, alarmTagId, alarmId })
);

export const queryAlarmSummaries = createApiActions<AlarmSummary[]>()(
  'alarm/query',
  'alarm/querySuccess',
  'alarm/queryError',
  (siteId: number, query: AlarmSummaryQuery) => ({ siteId, query })
);

export const addNote = createApiActions<AlarmDetails>()(
  'alarm/addNote',
  'alarm/addNoteSuccess',
  'alarm/addNoteError',
  (siteId: number, alarmTagId: string, alarmId: string, note: string) => ({
    siteId,
    alarmTagId,
    alarmId,
    note,
  })
);

export const acknowledgeAlarm = createApiActions<AlarmDetails>()(
  'alarm/acknowledge',
  'alarm/acknowledgeSuccess',
  'alarm/acknowledgeError',
  (siteId: number, alarmTagId: string, alarmId: string, note?: string) => ({
    siteId,
    alarmTagId,
    alarmId,
    note,
  })
);

export const resolveAlarm = createApiActions<AlarmDetails>()(
  'alarm/resolve',
  'alarm/resolveSuccess',
  'alarm/resolveError',
  (siteId: number, alarmTagId: string, alarmId: string, resolvedAt: Date, note?: string) => ({
    siteId,
    alarmTagId,
    alarmId,
    note,
    resolvedAt,
  })
);
