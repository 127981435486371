import React from 'react';
import { Button, Radio, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

type item = { value: string | number; label: string };

interface Props {
  style?: React.CSSProperties;
  activeValue?: string | number;
  items: item[];
  handleSelectValue: (value: string | number) => void;
}

const styles = (theme: Theme) => {
  const radius = theme.spacing() * 0.5; // TODO: get from theme
  return createStyles({
    radioButtonGroup: {
      boxShadow: theme.shadows[2],
      display: 'inline-flex',
      borderRadius: radius,
    },
    radioInput: {
      height: 'auto',
      width: 'auto',
      marginRight: theme.spacing(),
    },
    radioButton: {
      padding: `${theme.spacing() * 0.5}px ${theme.spacing() * 1}px`,
      boxShadow: 'none',
    },
    radioRoot: {
      padding: 2,
    },
    buttonRight: {
      borderRadius: `0 ${radius}px ${radius}px 0`,
    },
    buttonLeft: {
      borderRadius: `${radius}px 0 0 ${radius}px`,
    },
    buttonMiddle: {
      borderRadius: '0',
    },
    hover: {},
    buttonActive: {
      backgroundColor: '#ffffff',
    },
  });
};

type AllProps = Props & WithStyles<typeof styles>;

const RadioToggle: React.SFC<AllProps> = (props) => {
  return (
    <div style={props.style} className={props.classes.radioButtonGroup}>
      {props.items.map((i: item, index: number) => {
        const isRight = index === props.items.length - 1;
        const isLeft = index === 0;
        let buttonClass = '';
        if (isRight) {
          buttonClass = 'buttonRight';
        } else if (isLeft) {
          buttonClass = 'buttonLeft';
        } else {
          buttonClass = 'buttonMiddle';
        }
        const classes = [props.classes.radioButton, props.classes[buttonClass]];
        if (props.activeValue === i.value) {
          classes.push(props.classes.buttonActive);
        }

        return (
          <Button
            className={classes.join(' ')}
            variant="contained"
            size="small"
            color="default"
            key={i.value}
            onClick={(e) => {
              props.handleSelectValue(i.value);
              e.stopPropagation();
              return false;
            }}
          >
            <Radio
              color="primary"
              className={props.classes.radioInput}
              classes={{ root: props.classes.radioRoot }}
              checked={props.activeValue === i.value}
              aria-label={i.label}
            />
            {i.label}
          </Button>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(RadioToggle);
