import { ActionsOf } from '../../../helpers/actions';
import { getType } from 'typesafe-actions';
import * as profileActions from './actions';
import { UserProfile } from '../../../models';
import { produce } from 'immer';

export interface ProfileStateSlice {
  entities: {
    userProfile: UserProfile | null;
  };
}

const initialState = null;

type ProfileActions = ActionsOf<typeof profileActions.fetchUserProfile>;

export const profileEntityReducer = produce((_draft: UserProfile, action: ProfileActions) => {
  switch (action.type) {
    case getType(profileActions.fetchUserProfile.success): {
      const { result: userProfile } = action.payload;
      // note we return here instead of modifying the draft
      return userProfile;
    }

    default:
      return;
  }
}, initialState);
