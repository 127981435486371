import { TagStateSlice } from './reducer';
import { mapValues } from 'lodash';
import { TagDefinition } from '../../../models';
import { Dictionary } from 'lodash';
import { memoizeSelector } from '../../../helpers/selectors';
import { NormalizationError } from '../../../helpers/norm';

export const findTagById = (state: TagStateSlice, id: string): TagDefinition | undefined => {
  return state.entities.tag.byId[id];
};

/**
 * We need to define a denormTag method for usage in places where we should
 * be able to find a particular tag based on what has been loaded for the site.
 * Not finding a tag definition in that case should cause a normalization error
 */
export const denormTag = (state: TagStateSlice, id: string): TagDefinition => {
  const tag = findTagById(state, id);
  if (!tag) {
    throw new NormalizationError(`Unable to normalize tag: ${id}`);
  }
  return tag;
};

export const findTagLookupBySite = memoizeSelector(
  (state: TagStateSlice, siteId: number): Dictionary<TagDefinition> => {
    if (!state.entities.tag.bySite[siteId]) {
      return {};
    }
    return mapValues(
      state.entities.tag.bySite[siteId],
      (_, tagId) => state.entities.tag.byId[tagId]
    );
  },
  [(state: TagStateSlice) => state.entities.tag.byId]
);
