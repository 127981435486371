export enum SiteRole {
  SITE_ADMIN = 'SiteAdmin',
  CUSTOMER_ADMIN = 'Customer Administrator',
  SYSTEM_ADMIN = 'System Administrator',
  USER = 'User',
}

export const AssignableRoles = [SiteRole.USER, SiteRole.SITE_ADMIN];

export enum UserType {
  SYSTEM_ADMIN = 'SystemAdministrator',
  CUSTOMER_ADMIN = 'CustomerAdministrator',
  INSTALLER = 'Installer',
  USER = 'User',
}

/**
 * Used to map user type booleans (in the front-end)
 * to UserType enum needed for api request
 */
export const UserTypeMap = {
  isSystemAdmin: UserType.SYSTEM_ADMIN,
  isCustomerAdmin: UserType.CUSTOMER_ADMIN,
  isInstaller: UserType.INSTALLER,
};

export const getUserType = (
  userTypeBooleans: Pick<User, 'isCustomerAdmin' | 'isInstaller' | 'isSystemAdmin'>
): UserType => {
  const key = Object.keys(userTypeBooleans).find((key) => userTypeBooleans[key]);
  return key ? UserTypeMap[key] : UserType.USER;
};

export interface SiteRoleAssignment {
  siteId: number;
  role: SiteRole;
}

export interface NewUser {
  name: string;
  emailAddress: string;
  phoneNumber?: string;
  userType: UserType;
  isSystemAdmin: boolean;
  isCustomerAdmin: boolean;
  isInstaller: boolean;
  defaultSiteId: number;
  customerId: number;
  role: SiteRole;
  siteId: number;
}

export interface User {
  name: string;
  emailAddress: string;
  phoneNumber?: string;
  userType: UserType;
  isSystemAdmin: boolean;
  isCustomerAdmin: boolean;
  isInstaller: boolean;
  defaultSiteId: number;
  customerId: number;
  userId: number;
  isActive: boolean;
  siteRoles: SiteRoleAssignment[];
}

export interface SiteInfo {
  siteId: number;
  siteName: string;
}

export const isAssigned = (user: User, siteId: number) => {
  return user.siteRoles.map((siteRole) => siteRole.siteId).includes(siteId);
};

export const isAdminRole = (role: SiteRole) => {
  return (
    role === SiteRole.SITE_ADMIN ||
    role === SiteRole.CUSTOMER_ADMIN ||
    role === SiteRole.SYSTEM_ADMIN
  );
};
