type PushCallBack<T> = (message: T) => void;

export class PushHub<T> {
  private activeListeners: { [key: string]: PushCallBack<T> } = {};

  public getActiveListeners() {
    return this.activeListeners;
  }

  public registerListener(callId: string, callback: PushCallBack<T>) {
    this.activeListeners[callId] = callback;
  }

  public deleteListener(callId: string) {
    delete this.activeListeners[callId];
  }

  public isListenerActive(callId: string) {
    return this.activeListeners[callId] ? true : false;
  }

  public callListener(callId: string, data: T) {
    return this.activeListeners[callId] && this.activeListeners[callId](data);
  }
}

/** Application push hub can take any type of message */
export const pushHub = new PushHub<unknown>();
