import { TagDefinition } from './tag';
import { User } from './user';
import { enforceEnumValue } from '../helpers/enum';

export enum BackoffPolicy {
  CONSTANT_ONE_HOUR = 'Hourly',
  EXPONENTIAL = 'Exponential',
  CONSTANT_WEEKLY = 'Weekly',
}

export const isSettableBackoffPolicy = (backoffPolicy: BackoffPolicy): boolean => {
  return backoffPolicy !== BackoffPolicy.CONSTANT_WEEKLY;
};

export const getSettableBackoffPolicies = () => {
  return Object.values(BackoffPolicy).filter(isSettableBackoffPolicy);
};

export enum DeliveryMethod {
  SMS = 'sms',
  EMAIL = 'email',
}

export enum RecipientRole {
  TO = 'to', // for sms always to
  CC = 'cc',
  BCC = 'bcc',
}

export interface DeliveryStrategy {
  deliveryMethod: DeliveryMethod;
  recipientRole: RecipientRole;
}

export const DeliveryStrategies = [
  {
    label: 'SMS',
    deliveryMethod: DeliveryMethod.SMS,
    recipientRole: RecipientRole.TO,
  },
  {
    label: 'Email To',
    deliveryMethod: DeliveryMethod.EMAIL,
    recipientRole: RecipientRole.TO,
  },
  {
    label: 'Email CC',
    deliveryMethod: DeliveryMethod.EMAIL,
    recipientRole: RecipientRole.CC,
  },
  {
    label: 'Email BCC',
    deliveryMethod: DeliveryMethod.EMAIL,
    recipientRole: RecipientRole.BCC,
  },
];

export const getStrategyId = (dist: DeliveryStrategy): string => {
  return `${dist.recipientRole}-${dist.deliveryMethod}`;
};

export const strategyFromId = (id: string): DeliveryStrategy | undefined => {
  try {
    const [recipientRole, deliveryMethod] = id.split('-');

    return {
      recipientRole: enforceEnumValue(RecipientRole, recipientRole),
      deliveryMethod: enforceEnumValue(DeliveryMethod, deliveryMethod),
    };
  } catch {
    return;
  }
};

export const getDefaultDeliveryStrategy = () => {
  return DeliveryStrategies[1];
};

export interface AlertDistribution extends DeliveryStrategy {
  userId: number;
  user?: User;
}

export interface Alert {
  alertTagId: string;
  relatedAlarmTag: TagDefinition;
  label: string;
  description: string;
  isActive: boolean;
  backoffPolicy: BackoffPolicy | null;
  distributionList: AlertDistribution[];
}
