import React from 'react';
import { makeStyles, Toolbar } from '@material-ui/core';
import { HelpWidget } from './HelpWidget';
import { PathBuilder } from '../../../routing';
import { Link } from 'react-router-dom';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { mpulseIcon } from '../../../config';

const useStyles = makeStyles(() => ({
  toolbar: {
    paddingRight: 24,
    borderBottom: '1px solid #E8E8E8',
  },
}));

interface ErrorPageProps {
  message: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const classes = useStyles();

  return (
    <div style={{ width: '100%' }}>
      <Toolbar className={classes.toolbar}>
        <div style={{ flex: 1 }}>
          <Link to={PathBuilder.DEFAULT_SITE}>
            <img alt="mPulse" style={{ height: 36 }} src={mpulseIcon} />
          </Link>
        </div>

        <div>
          <HelpWidget />
        </div>
      </Toolbar>
      <div style={{ padding: 64, textAlign: 'center' }}>
        <ErrorMessage size="lg" message={props.message} />
      </div>
    </div>
  );
};
