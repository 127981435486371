import { createApiActions } from '../../../helpers/actions';
import { SiteData } from '../../../api/TagApi';

export const fetchSiteData = createApiActions<SiteData>()(
  'site/fetchData',
  'site/fetchDataSuccess',
  'site/fetchDataError',
  (siteId: number) => ({ siteId })
);

export const requestSiteDocument = createApiActions<string>()(
  'site/documentDownload',
  'site/documentDownloadSuccess',
  'site/documentDownloadError',
  (siteId: number, documentName: string) => ({ siteId, documentName })
);
