import React from 'react';
import * as d3 from 'd3';

import { ChartPane, chartContext } from '../../ui/chart/ChartPane';
import XAxis from '../../ui/chart/XAxis';
import YAxis from '../../ui/chart/YAxis';
import HorizontalGrid from '../../ui/chart/HorizontalGrid';
import Crosshairs from '../../ui/chart/Crosshairs';
import StackedAreaSeries, { stackAreaSeries } from '../../ui/chart/StackedAreaSeries';
import BackgroundSeries from '../../ui/chart/BackgroundSeries';
import StackedBarSeries, { stackBarSeries } from '../../ui/chart/StackedBarSeries';
import LineSeries from '../../ui/chart/LineSeries';
import { timeFormat } from '../../ui/chart/helpers';
import { ChartContextData, ChartSeries, BackgroundRange } from 'src/components/ui/chart/types';

interface Props {
  style?: React.CSSProperties;
  height?: number;
  activeXValue?: Date;
  activeSeries?: string;
  xRange: {
    start: Date;
    end: Date;
  };
  xAxisFormatter?: (d: Date | number) => string;
  yAxisFormatter?: (d: number) => string;
  yLabelFormatter?: (d: number) => string;
  yAltAxisFormatter?: (d: number) => string;
  yAltLabelFormatter?: (d: number) => string;
  handleHover?: (x: Date, y: number) => void;
  handleHoverEnd?: () => void;
  handleSelectSeries?: (series: string) => void;
  handleZoom?: (start: Date, end: Date) => void;
  backgroundRanges?: BackgroundRange[];
  lineSeries?: ChartSeries[];
  areaSeries?: ChartSeries[];
  barSeries: ChartSeries[];
}

const DispatchChart: React.SFC<Props> = (props) => {
  const [barData, barDataRange] = stackBarSeries(props.barSeries);
  const lineData = props.lineSeries;
  const [areaData] = stackAreaSeries(props.areaSeries || []);
  const backgroundData = props.backgroundRanges;

  const xRangeMin = props.xRange.start;
  const xRangeMax = props.xRange.end;
  let yRangeMin = barDataRange.minY;
  let yRangeMax = barDataRange.maxY;

  const padding = (yRangeMax - yRangeMin) * 0.05;
  yRangeMin = yRangeMin - padding;
  yRangeMax = yRangeMax + padding;

  const handleMouseMove = (pos: { x: Date; y: number }) =>
    props.handleHover && props.handleHover(pos.x, pos.y);
  const handleMouseLeave = () => props.handleHoverEnd && props.handleHoverEnd();

  return (
    <div style={{ width: '100%', boxSizing: 'border-box', marginTop: 20 }}>
      <ChartPane
        handleMouseMove={handleMouseMove}
        handleMouseLeave={handleMouseLeave}
        height={props.height || 240}
        range={{
          xMin: xRangeMin,
          xMax: xRangeMax,
          yMin: yRangeMin,
          yMax: yRangeMax,
        }}
        margins={{
          top: 0,
          bottom: 26,
          left: 0,
          right: 0,
        }}
      >
        <chartContext.Consumer>
          {(context: ChartContextData) => {
            const rightScale = d3
              .scaleLinear()
              .domain([0, 100])
              .range([context.yScale(0) ?? 0, 0 + (context.margins.top || 0) + 8]);

            return (
              <>
                <BackgroundSeries {...context} data={backgroundData || []} />
                <HorizontalGrid
                  {...context}
                  marginLeft={context.margins.left}
                  marginRight={context.margins.right}
                  tickCount={5}
                />
                <StackedAreaSeries {...context} yScale={rightScale} data={areaData || []} />
                <LineSeries {...context} yScale={rightScale} data={lineData || []} />
                <StackedBarSeries {...context} data={barData} />
                <XAxis
                  {...context}
                  inset={true}
                  offset={-1}
                  // @ts-ignore todo: fix type
                  format={props.xAxisFormatter || timeFormat}
                />
                <YAxis
                  {...context}
                  inset={true}
                  offset={context.margins.left}
                  format={props.yAxisFormatter || ((d) => `${d}`)}
                />

                {/* Right Axis */}
                {props.lineSeries && (
                  <YAxis
                    {...context}
                    inset={false}
                    yScale={rightScale}
                    offset={context.width - 1}
                    format={props.yAltAxisFormatter || ((d) => `${d}`)}
                    tickCount={3}
                  />
                )}

                {props.activeXValue && (
                  <Crosshairs
                    {...context}
                    targetPosition={{
                      x: context.xScale(props.activeXValue) ?? 0,
                      y: context.mouseY,
                    }}
                    margins={{ ...context.margins, bottom: 0 }}
                    bottomValue={props.xAxisFormatter && props.xAxisFormatter(props.activeXValue)}
                    leftValue={
                      props.yLabelFormatter &&
                      props.yLabelFormatter(context.yScale.invert(context.mouseY))
                    }
                    rightValue={
                      props.yAltLabelFormatter &&
                      props.yAltLabelFormatter(rightScale.invert(context.mouseY))
                    }
                  />
                )}
              </>
            );
          }}
        </chartContext.Consumer>
      </ChartPane>
    </div>
  );
};

export default DispatchChart;
