import { useDispatch, useSelector } from 'react-redux';
import { useLayoutEffect, useState, useMemo, useRef, useEffect } from 'react';
import {
  getStatus,
  isUnrequested,
  getLastDispatched,
  RequestAction,
  SimpleRequestStatus,
} from '../state/request';
import { getStatus as getSubscriptionStatus, SubscriptionStatus } from '../state/subscription';
import { SubActionSet } from '../helpers/actions';
import { isEqual } from 'lodash';
import { useLocation, useHistory } from 'react-router';
import URI from 'urijs';
import { loadState, saveState } from './localStorage';

/**
 * Wrapper for the useDispatch hook to bind the action, so it can be used like
 * it would be if it was passed to "connect".
 *
 * Note: action that was dispatched is also returned, in case the uid is needed
 * to track the request in the scope where action was dispatched.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAction<Args extends any[], AType>(
  actionCreator: (...args: Args) => AType
): (...args: Args) => AType {
  const dispatch = useDispatch();
  return useMemo(() => {
    return (...args: Args) => {
      const action = actionCreator(...args);
      dispatch(action);
      // we also return the action so the component can use it to keep track
      // of requests
      return action;
    };
  }, [actionCreator, dispatch]);
}

/**
 * Wrapper for the useSelector hook which allows passing additional parameters
 * without defining an anonymous function
 *
 * const someValue = useParamSelector(getSomeValueById)(valueId);
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useParamSelector<RootState, Args extends any[], Result>(
  selector: (rootState: RootState, ...args: Args) => Result
) {
  return (...args: Args) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSelector((state: RootState) => selector(state, ...args));
  };
}

/**
 * Enables calling side effects which have deps, only when a subset of the deps
 * change.
 */
export const useEffectWhen = (
  effect: () => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  whenDeps: any[],
  leading: boolean = true
) => {
  const whenRef = useRef(whenDeps || []);
  const initial = whenRef.current === whenDeps;
  const whenDepsChanged =
    (initial && leading) || !whenRef.current.every((w, i) => w === whenDeps[i]);
  whenRef.current = whenDeps;
  const nullDeps = deps.map(() => null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(whenDepsChanged ? effect : () => undefined, whenDepsChanged ? deps : nullDeps);
};

/*
 * Called with a request action, returns the status of that request, if the
 * request has not been made, will make that request
 */
export function useRequest<Action extends RequestAction>(
  action: RequestAction,
  enabled: boolean = true
): [Action, SimpleRequestStatus] {
  const dispatch = useDispatch();
  const notRequested = useParamSelector(isUnrequested)(action);
  // when action changes dispatch action once
  useEffectWhen(
    () => {
      if (notRequested && enabled) {
        dispatch(action);
      }
    },
    [action, enabled, notRequested],
    [action]
  );

  return [
    (useParamSelector(getLastDispatched)(action) as Action) || action,
    useParamSelector(getStatus)(action),
  ];
}

/*
 * As long as last action is not identical to this action dispatches request
 */
export function useUncachedRequest<Action extends RequestAction>(
  action: RequestAction
): [Action, SimpleRequestStatus] {
  const lastAction = useRef<RequestAction>();
  const dispatch = useDispatch();
  useEffect(() => {
    const notRequested =
      lastAction.current === undefined || !isEqual(lastAction.current.payload, action.payload);
    if (notRequested) {
      dispatch(action);
      lastAction.current = action;
    }
  }, [action, dispatch, lastAction]);

  return [
    (useParamSelector(getLastDispatched)(action) as Action) || action,
    useParamSelector(getStatus)(action),
  ];
}

/*
 * Called with a request action, returns the status of that request, if the
 * request has not been made, will make that request
 */
export function useSubscription<
  Type1 extends string,
  Type2 extends string,
  Type3 extends string,
  Type4 extends string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PArgs extends any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Payload extends any,
  Result
>(
  isEnabled: boolean,
  actionCreatorQuad: SubActionSet<Type1, Type2, Type3, Type4, PArgs, Payload, Result>,
  ...args: PArgs
): SubscriptionStatus {
  const dispatch = useDispatch();
  const action = actionCreatorQuad.start(...args);

  const prevAction = useRef<typeof action>();

  // when action changes dispatch action once
  useEffect(() => {
    const actionChanged =
      prevAction.current === undefined || !isEqual(prevAction.current.payload, action.payload);
    // stop any currently active subscriptions
    if (actionChanged && prevAction.current) {
      dispatch(actionCreatorQuad.stop(prevAction.current));
    }

    if (isEnabled && actionChanged) {
      dispatch(action);
      prevAction.current = action;
    }
  }, [action, actionCreatorQuad, dispatch, isEnabled]);

  useCleanup(() => {
    if (prevAction.current) {
      dispatch(actionCreatorQuad.stop(prevAction.current));
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const status = useParamSelector(getSubscriptionStatus)(action as any);
  return status;
}

/**
 * Called when component is unMounted
 */
export const useCleanup = (effect: () => void) => {
  return useEffect(() => {
    return effect;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useDimensions = <T extends HTMLElement>() => {
  const ref = useRef<T | null>(null);

  const [dimensions, setDimensions] = useState<DOMRect | undefined>(undefined);

  useLayoutEffect(() => {
    setDimensions(ref.current ? ref.current.getBoundingClientRect() : undefined);
  }, []);

  return [ref, dimensions] as const;
};

/**
 * Hook to execute a function on each animation frame
 * see: https://css-tricks.com/using-requestanimationframe-with-react-hooks/
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAnimationFrame = (callback: (ts: number) => void, deps: any[] = []) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestRef = useRef<any>();
  const previousTimeRef = useRef<number>();

  const animate = (time: number) => {
    if (previousTimeRef.current !== undefined) {
      callback(time);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps); // Make sure the effect runs only once
};

/**
 * Creates a ref and sets its 'current' property to
 * the current value of the argument. Can be helpful in memoizing
 * and optimizing components to reference a current prop value without
 * changing a callback reference.
 */
export const useCurrent = <T>(value: T) => {
  const ref = useRef<T>(value);
  ref.current = value;
  return ref;
};

/**
 * Allows the url to be used as a state store
 */
export const useUrlState = <StateType>(
  urlParser: (url: string) => StateType,
  initialState?: StateType
): [StateType, (nextState: Partial<StateType>) => void] => {
  const location = useLocation();

  let state = initialState;
  if (!initialState) {
    state = urlParser(location.pathname + location.search);
  }
  const history = useHistory();

  const setState = (nextState: Partial<StateType>) => {
    nextState = Object.assign({}, state, nextState);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newQuery = new URI(location.pathname).search(nextState as any).query();
    history.push(`${location.pathname}?${newQuery}`, { data: nextState });
  };
  return [state as StateType, setState];
};

/**
 * Allows local storage to be used as a state store
 */
export const useLocalStorageState = <StateType>(
  key: string,
  initialState?: StateType
): [StateType, (nextState: StateType) => void] => {
  const persistedState = loadState(key) as StateType;
  const [proxyState, setStateProxy] = useState<StateType | undefined>(
    persistedState || initialState
  );

  const setState = (nextState: StateType) => {
    setStateProxy(nextState);
    saveState(key, nextState);
  };
  return [proxyState as StateType, setState];
};
