import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core';
import { isUndefined, isNumber } from 'util';
import { formatNumber } from '../../../helpers/formatting';
import { Dictionary } from 'lodash';

interface CellData {
  column: string;
  row: string;
  value: string | number;
}

interface Props {
  style?: React.CSSProperties;
  rowTitleColors?: Dictionary<string>;
  columnNames: string[];
  rowNames: string[];
  data: CellData[];
  cellLabel: string;
  defaultCellValue: string | number;
}

const styles = (theme: Theme) =>
  createStyles({
    headCell: {
      width: `calc(100% / 8)`,
      paddingLeft: theme.spacing() * 2,
      paddingRight: theme.spacing() * 2,
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 'bold',
    },
    tableBody: {
      borderRight: '1px solid #ccc',
      borderLeft: '1px solid #ccc',
    },
    rowLabel: {
      borderRight: '1px solid #ccc',
      width: `calc(100% / 12)`,
      textAlign: 'right',
      padding: '4px 16px',
      fontSize: 12,
    },
    cellValue: {
      textAlign: 'right',
      paddingRight: 4,
      fontSize: 12,
    },
    nullCellValue: {
      textAlign: 'right',
      paddingRight: 4,
      color: '#ccc',
    },
    cellLabel: {
      textAlign: 'left',
      paddingLeft: 4,
      color: '#6d6d6d',
      width: '7%',
      fontSize: 12,
    },
    nullCellLabel: {
      textAlign: 'left',
      paddingLeft: 4,
      color: '#ccc',
      width: '7%',
    },
    totalValue: {
      textAlign: 'right',
      paddingRight: 4,
      fontSize: 12,
    },
    totalLabel: {
      textAlign: 'left',
      paddingLeft: 4,
      color: '#6d6d6d',
      fontSize: 12,
    },
    row: {
      height: 30,
    },
    totalRow: {
      height: 30,
      backgroundColor: '#F7F7F7',
      fontSize: 12,
    },
  });

type AllProps = Props & WithStyles<typeof styles>;

const PowerFlowTable: React.FC<AllProps> = (props) => {
  const structuredData = {};
  const totals = {};
  const rowColors = props.rowTitleColors || {};
  for (const d of props.data) {
    const { row, column, value } = d;
    structuredData[row] = isUndefined(structuredData[row]) ? {} : structuredData[row];
    structuredData[row][column] = isUndefined(structuredData[row][column])
      ? {}
      : structuredData[row][column];
    structuredData[row][column] = value;
    totals[column] = isUndefined(totals[column]) ? 0 : totals[column];
    if (isNumber(value)) {
      totals[column] = totals[column] + value;
    }
  }

  return (
    <div style={{ overflowX: 'auto', padding: 1, ...props.style }}>
      <Table size="small">
        <TableHead>
          <TableRow className={props.classes.row}>
            <TableCell />
            {props.columnNames.map((name) => (
              <TableCell key={name} colSpan={2} className={props.classes.headCell}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={props.classes.tableBody}>
          {props.rowNames.map((rowName) => (
            <TableRow className={props.classes.row} key={rowName}>
              <TableCell
                className={props.classes.rowLabel}
                style={rowColors[rowName] ? { backgroundColor: rowColors[rowName] } : {}}
              >
                {rowName}
              </TableCell>
              {props.columnNames.map((colName) => {
                let value = props.defaultCellValue;
                let label = isNumber(value) ? props.cellLabel : '';
                if (structuredData[rowName] && structuredData[rowName][colName]) {
                  value = structuredData[rowName][colName];
                  label = props.cellLabel;
                }
                return (
                  <React.Fragment key={colName}>
                    <TableCell
                      key={`${colName}-value`}
                      className={
                        value === 0 ? props.classes.nullCellValue : props.classes.cellValue
                      }
                    >
                      {isNumber(value) ? formatNumber(value, 0) : value}
                    </TableCell>
                    <TableCell
                      key={`${colName}-label`}
                      className={
                        value === 0 ? props.classes.nullCellLabel : props.classes.cellLabel
                      }
                    >
                      {label}
                    </TableCell>
                  </React.Fragment>
                );
              })}
            </TableRow>
          ))}
          <TableRow className={props.classes.totalRow}>
            <TableCell className={props.classes.rowLabel}>Totals</TableCell>
            {props.columnNames.map((colName) => (
              <React.Fragment key={colName}>
                <TableCell key={`${colName}-total-value`} className={props.classes.totalValue}>
                  {totals[colName] ? formatNumber(totals[colName], 0) : 0}
                </TableCell>
                <TableCell key={`${colName}-total-label`} className={props.classes.totalLabel}>
                  {props.cellLabel}
                </TableCell>
              </React.Fragment>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(PowerFlowTable);
