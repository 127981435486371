import React from 'react';
import * as d3 from 'd3';
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core';
import { ScreenSize } from './types';

const styles = (theme: Theme) =>
  createStyles({
    tick: {
      color: '#ccc',
      strokeDasharray: 5,
    },
  });

interface Props {
  style?: React.CSSProperties;
  size: ScreenSize;
  yScale: d3.ScaleLinear<number, number>;
  marginRight?: number;
  marginLeft?: number;
  tickCount?: number;
  tickInterval?: number;
}

type AllProps = Props & WithStyles<typeof styles>;

class HorizontalGrid extends React.Component<AllProps> {
  groupRef: React.RefObject<SVGGElement> = React.createRef();

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  render() {
    return <g ref={this.groupRef} />;
  }

  private makeGridlines() {
    return d3.axisLeft(this.props.yScale).ticks(this.props.tickCount);
  }

  private update() {
    if (this.groupRef.current) {
      const g = d3.select(this.groupRef.current);
      const gridLineLength =
        this.props.size.width - (this.props.marginLeft || 0) - (this.props.marginRight || 0);
      g.attr('class', 'grid').call(this.makeGridlines().tickSize(-gridLineLength));
      g.attr('transform', `translate(${this.props.marginLeft || 0}, 0)`);
      g.select('.domain').remove();
      g.selectAll('text').remove();
      g.selectAll('.tick').attr('class', `tick ${this.props.classes.tick}`);
    }
  }
}

export default withStyles(styles)(HorizontalGrid);
