import { createApiActions, createSubActions } from '../../../helpers/actions';
import { SnapshotData } from '../../../api/SnapshotApi';

export const fetchTopNSnapshots = createApiActions<SnapshotData>()(
  'snapshot/fetchTopN',
  'snapshot/fetchTopNSuccess',
  'snapshot/fetchTopNError',
  (siteId: number, tags: string[], topN: number) => ({ siteId, tags, topN })
);

export const fetchSnapshots = createApiActions<SnapshotData>()(
  'snapshot/fetch',
  'snapshot/fetchSuccess',
  'snapshot/fetchError',
  (siteId: number, tags: string[], start: Date, end: Date, includeTransient?: boolean) => ({
    siteId,
    tags,
    start,
    end,
    includeTransient,
  })
);

export const subSnapshots = createSubActions<SnapshotData>()(
  'snapshot/subStart',
  'snapshot/subUpdate',
  'snapshot/subError',
  'snapshot/subStop',
  (siteId: number, tags: string[], lookback: number, lookforward: number = 0) => ({
    siteId,
    tags,
    lookback,
    lookforward,
  })
);
