import { Typography } from '@material-ui/core/';

export interface ErrorMessageProps {
  message: string;
  title?: string;
  size?: 'sm' | 'lg';
}

export const ErrorMessage = ({ message, size, title = 'Error' }: ErrorMessageProps) => {
  return (
    <div>
      <Typography>{title}</Typography>
      <Typography variant={size === 'sm' ? 'h6' : 'h5'}>{message}</Typography>
    </div>
  );
};
