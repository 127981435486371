import { includes } from 'lodash';

export function includesAll<T>(array: T[], attributes: T[]): boolean {
  return attributes.every((required) => includes(array, required));
}

export function includesAny<T>(array: T[], attributes: T[]): boolean {
  return attributes.some((required) => includes(array, required));
}

/**
 * Adds "things" in between each element in an array.
 *
 * interleave([a, b, c], z) = [a, z, b, z, c]
 */
export function interleave<ElementType>(arr: ElementType[], thing: ElementType) {
  return ([] as ElementType[]).concat(...arr.map((n) => [n, thing])).slice(0, -1);
}

export function isValidDate(d: Date) {
  return !isNaN(d.getTime());
}
