import React from 'react';
import { createStyles, Theme, withStyles, WithStyles, Grid } from '@material-ui/core';

interface Props {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

/*
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: -24px;
    background-color: #F7F7F7;
    width: calc(100% + 48px);
    padding: 8px 24px;
    border-top: 1px solid #ccc;
    */

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: `#F7F7F7`,
      borderTop: `1px solid ${theme.palette.divider}`,
      width: `calc(100% + ${theme.spacing() * 6})`,
      padding: `${theme.spacing()}px ${theme.spacing() * 3}px`,
    },
  });

type AllProps = Props & WithStyles<typeof styles>;

const PageSectionFooter: React.SFC<AllProps> = (props) => {
  return (
    <Grid item xs={12} className={props.classes.root}>
      {props.children}
    </Grid>
  );
};

export default withStyles(styles)(PageSectionFooter);
