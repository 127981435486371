export interface Address {
  address1: string;
  address2?: string;
  city: string;
  stateOrProvince: string;
  postalCode: string | number;
  country: string;
  district?: string;
}

export enum Country {
  US = 'United States',
  CR = 'Costa Rica',
}

export const formatAddress = (address: Address) => {
  switch (address.country) {
    case Country.CR:
      return formatAddressCostaRica(address);
    case Country.US:
    default:
      return formatAddressUSA(address);
  }
};

export const formatAddressCostaRica = ({
  address1,
  city,
  district,
  stateOrProvince,
  postalCode,
  country,
}: Address) => `${address1}, ${stateOrProvince}, ${city}, ${district}, ${postalCode}, ${country}`;

export const formatAddressUSA = ({
  address1,
  address2,
  city,
  stateOrProvince,
  postalCode,
  country,
}: Address) => {
  let label = address1;
  if (address2) {
    label += `, ${address2}`;
  }
  label += `, ${city}, ${stateOrProvince} ${postalCode}, ${country}`;
  return label;
};

export const getDefaultAddress = (country: keyof typeof Country) => {
  switch (country) {
    case 'CR':
      return {
        address1: '',
        city: '',
        stateOrProvince: '',
        postalCode: '',
        country: 'CR',
        district: '',
      };
    case 'US':
    default:
      return {
        address1: '',
        address2: '',
        city: '',
        stateOrProvince: '',
        postalCode: '',
        country: 'US',
      };
  }
};
