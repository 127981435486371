import React from 'react';
import * as d3 from 'd3';
import { Grid, FormGroup, Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { sumBy, zipObject, some, values } from 'lodash';
import { SeriesValue, ChartSeries } from 'src/components/ui/chart/types';
import { getClosestPoint, invertSeries, timeFormatOffset } from '../../ui/chart/helpers';
import DateSelector from '../../ui/controls/DateSelector';
import RadioToggle from '../../ui/controls/RadioToggle';
import PageSection from '../../ui/layout/PageSection';
import CollapsingPageSection from '../../ui/layout/CollapsingPageSection';
import PageSectionFooter from '../../ui/layout/PageSectionFooter';
import SectionTitle from '../../ui/typography/SectionTitle';
import LegendItem, { LegendItemType } from '../../ui/chart/LegendItem';
import ControlOverviewChart from './ControlOverviewChart';
import DispatchChart from './DispatchChart';
import TotalsChart from './TotalsChart';
import PowerFlowTable from './PowerFlowTable';
import CostTable from './CostTable';
import LoadWithSpinner from '../../ui/Loading/LoadWithSpinner';
import { ProjectionDataset, TOUDataset } from '../../../models';
import { ControlTimeSeries } from '../../../state/contexts/controlSummaryPage';
import { formatNumber, formatNumberSignificant, toDateString } from '../../../helpers/formatting';
import { palette } from '../../../config';
import { zonedTimeToUtc, getTimezoneOffset } from 'date-fns-tz';
import { toDate, addMinutes } from 'date-fns';

const KWH = 'kWh';
const KW = 'kW';
const DOLLARS = '$';
const MINUTE_MILLISECONDS = 60000;

const bumpColor = (initial: string, amount?: number, darken?: number) => {
  amount = amount || 1;
  const bumpedColor = d3.hsl(initial);
  bumpedColor.h += 15 * amount;
  bumpedColor.s += 0.2 * amount;
  return bumpedColor.darker(darken || 1).toString();
};

const styles = (theme: Theme) =>
  createStyles({
    /* styles for page structure */
    controlOverviewLegend: {
      display: 'flex',
      justifySelf: 'flex-end',
      flexWrap: 'wrap',
      marginBottom: '8px',
      marginTop: '-36px',
      width: '60%',
      marginLeft: 'auto',
    },
    batteryDispatchLegend: {
      display: 'flex',
      justifySelf: 'flex-end',
      flexWrap: 'wrap',
      marginBottom: '-16px',
      marginTop: '8px',
      marginRight: '8px',
      marginLeft: 'auto',
    },
    /* styles for all shared chart elements */
    loadLine: {
      stroke: '#0C1419',
      strokeWidth: 2,
      strokeDasharray: '4 2',
    },
    utilityUsageLine: {
      stroke: '#304B5A',
      strokeWidth: 2,
    },
    demandThresholdLine: {
      stroke: '#039ad5',
      strokeWidth: 2,
    },
    meterRollbackArea: {
      fill: palette.meterRollback,
      stroke: bumpColor(palette.meterRollback, 1, 1),
      strokeOpacity: 0.6,
      fillOpacity: 0.7,
    },
    utilityServingLoadArea: {
      fill: palette.utility,
      stroke: bumpColor(palette.utility, 0.2, -0.5),
      strokeOpacity: 1,
      fillOpacity: 0.4,
    },
    pvServingLoadArea: {
      fill: palette.solar,
      stroke: bumpColor(palette.solar, 0.2, -0.5),
      strokeOpacity: 1,
      fillOpacity: 0.4,
    },
    utilityChargingBatteryArea: {
      fill: bumpColor(palette.utility),
      stroke: bumpColor(palette.utility, 1, 1.1),
      strokeOpacity: 0.6,
      fillOpacity: 0.7,
    },
    pvChargingBatteryArea: {
      stroke: bumpColor(palette.storage, 2, 1.1),
      fill: bumpColor(palette.storage, 2),
      strokeOpacity: 0.6,
      fillOpacity: 0.7,
    },
    batteryServingLoadArea: {
      stroke: bumpColor(palette.storage, 2, 1.2),
      fill: palette.storage,
      strokeOpacity: 0.6,
      fillOpacity: 0.4,
    },
    batteryChargeLine: {
      stroke: '#979797',
      strokeWidth: 2,
      strokeDasharray: '2 2',
    },
    batteryChargeArea: {
      fill: '#D8D8D844',
    },
    totalArea: {
      fill: bumpColor('#7ad6a0', 1),
      fillOpacity: 0.7,
      stroke: bumpColor('#7ad6a0', 1, 1.2),
      strokeOpacity: 0.7,
    },
    naArea: {
      fill: '#fff',
      stroke: 'none',
    },
  });

enum ChartUnits {
  'revenue',
  'energy',
}

interface SeriesTotals {
  load: number;
  utilityMeter: number;
  utilityToLoad: number;
  utilityToBattery: number;
  pvToLoad: number;
  pvToBattery: number;
  pvToMeter: number;
  batteryToLoad: number;
  batteryChargePercentage: number;
}

export interface Props {
  isLoading: boolean;
  targetDate: Date;
  siteTimezone: string;
  powerTimeSeries?: ControlTimeSeries;
  energyTimeSeries?: ControlTimeSeries;
  costTimeSeries?: ControlTimeSeries;
  tous?: TOUDataset[];
  projections?: ProjectionDataset;
}

interface ActionProps {
  handleSetTargetDate: (date: Date) => void;
}

interface State {
  chartDisplayUnits: ChartUnits;
  activeXValue: Date | undefined;
  xRangeMin: undefined;
  xRangeMax: undefined;
}

type AllProps = Props & ActionProps & WithStyles<typeof styles>;

class ControlSummaryPage extends React.Component<AllProps, State> {
  costTimeSeries: ControlTimeSeries | undefined;

  seriesTotals: SeriesTotals | undefined;

  costSeriesTotals: SeriesTotals | undefined;

  state = {
    activeXValue: undefined,
    xRangeMin: undefined,
    xRangeMax: undefined,
    chartDisplayUnits: ChartUnits.energy,
  };

  energyYAxisFormater = (y: number) => `${formatNumberSignificant(y, 3)} ${KWH}`;

  energyYLabelFormater = (y: number) => `${formatNumberSignificant(y, 3)} ${KWH}`;

  powerYAxisFormater = (y: number) => `${formatNumberSignificant(y, 3)} ${KW}`;

  powerYLabelFormater = (y: number) => `${formatNumberSignificant(y, 3)} ${KW}`;

  costYAxisFormater = (y: number) => `${DOLLARS} ${formatNumberSignificant(y, 3)}`;

  costYLabelFormater = (y: number) => `${DOLLARS} ${formatNumberSignificant(y, 3)}`;

  handleChangeDisplayUnits(newUnit: ChartUnits) {
    this.setState({
      chartDisplayUnits: newUnit,
    });
  }

  handleHover(x: Date | undefined) {
    const timeSeries = this.getActiveOverviewTimeSeries();
    // undefined x removes hover state
    if (!x || !timeSeries) {
      this.setState({ activeXValue: undefined });
      return;
    }

    // otherwise find closest data point to hover x and set as activeXValue
    const point = getClosestPoint([timeSeries.load, timeSeries.utilityMeter], x);
    if (point && point.x) {
      this.setState({
        activeXValue: point.x,
      });
    }
  }

  isEnergyUnits() {
    return this.state.chartDisplayUnits === ChartUnits.energy;
  }

  getSeriesTotals() {
    const seriesTotals = {} as SeriesTotals;
    const costSeriesTotals = {} as SeriesTotals;
    const timeSeries = this.props.energyTimeSeries || {};
    const costTimeSeries = this.props.costTimeSeries || {};

    Object.keys(timeSeries).forEach((key) => {
      seriesTotals[key] = sumBy(timeSeries[key], (value: SeriesValue) => value.y) || 0;
    });

    Object.keys(timeSeries).forEach((key) => {
      costSeriesTotals[key] = sumBy(costTimeSeries[key], (value: SeriesValue) => value.y) || 0;
    });

    return { seriesTotals, costSeriesTotals };
  }

  getActiveOverviewTimeSeries() {
    return this.isEnergyUnits() ? this.props.powerTimeSeries : this.props.costTimeSeries;
  }

  getActiveDispatchTimeSeries() {
    return this.isEnergyUnits() ? this.props.energyTimeSeries : this.props.costTimeSeries;
  }

  getTOUScale(minRate: number, maxRate: number) {
    return d3
      .scaleSequential(
        d3.interpolateRgb(
          d3.rgb(255, 255, 255, 0.34),
          // if no variation in rates, color scale should always return white
          minRate === maxRate ? d3.rgb(255, 255, 255, 0.34) : d3.rgb(254, 193, 193, 0.34)
        )
      )
      .domain([minRate, maxRate]);
  }

  getTOUBackgroundData() {
    const { tous } = this.props;
    if (!tous) {
      return;
    }
    const maxTouRate = tous.reduce(
      (maxSeen, range) => Math.max(maxSeen, range.consumptionRate || -Infinity),
      -Infinity
    );
    const minTouRate = tous.reduce(
      (minSeen, range) => Math.min(minSeen, range.consumptionRate || Infinity),
      Infinity
    );

    const touColorScale = this.getTOUScale(minTouRate, maxTouRate);

    const out = tous
      .map((tou) => {
        return tou.applicableTimes.map((timeRange) => ({
          label: tou.name,
          fillColor: touColorScale(tou.consumptionRate || 0),
          start: new Date(timeRange.startTS),
          end: new Date(timeRange.endTS),
          value: tou.consumptionRate || 0,
        }));
      })
      .reduce((list, touRanges) => list.concat(touRanges), []);
    return out;
  }

  getTOUStyles() {
    const { tous } = this.props;
    if (!tous) {
      return;
    }

    const maxTouRate = tous.reduce(
      (maxSeen, range) => Math.max(maxSeen, range.consumptionRate || -Infinity),
      -Infinity
    );
    const minTouRate = tous.reduce(
      (minSeen, range) => Math.min(minSeen, range.consumptionRate || Infinity),
      Infinity
    );

    const touColorScale = this.getTOUScale(minTouRate, maxTouRate);

    return tous.map((tou) => ({
      name: tou.name,
      fillColor: touColorScale(tou.consumptionRate || 0),
    }));
  }

  getBatteryTotals() {
    const { seriesTotals } = this.getSeriesTotals();
    const { classes } = this.props;

    const batteryServingLoadRange = [0, seriesTotals.batteryToLoad];
    const pvChargingBatteryRange = [0, seriesTotals.pvToBattery];
    const utilityChargingBatteryRange = [
      seriesTotals.pvToBattery,
      seriesTotals.pvToBattery + seriesTotals.utilityToBattery,
    ];

    const items = [
      {
        label: 'Battery to Load',
        value: `${formatNumber(seriesTotals.batteryToLoad, 0)} ${KWH}`,
        start: batteryServingLoadRange[0],
        end: batteryServingLoadRange[1],
        styleClasses: {
          bar: classes.batteryServingLoadArea,
        },
      },
      {
        label: 'PV to Battery',
        value: `${formatNumber(seriesTotals.pvToBattery, 0)} ${KWH}`,
        start: pvChargingBatteryRange[0],
        end: pvChargingBatteryRange[1],
        styleClasses: {
          bar: classes.pvChargingBatteryArea,
        },
      },
      {
        label: 'Utility to Battery',
        value: `${formatNumber(seriesTotals.utilityToBattery, 0)} ${KWH}`,
        start: utilityChargingBatteryRange[0],
        end: utilityChargingBatteryRange[1],
        styleClasses: {
          bar: classes.utilityChargingBatteryArea,
        },
      },
    ];

    return items;
  }

  getBatteryCostTotals() {
    const { costSeriesTotals } = this.getSeriesTotals();
    const { classes } = this.props;

    const batteryServingLoadRange = [0, costSeriesTotals.batteryToLoad];
    const pvChargingBatteryRange = [0, costSeriesTotals.pvToBattery];
    const utilityChargingBatteryRange = [
      costSeriesTotals.pvToBattery,
      costSeriesTotals.pvToBattery + costSeriesTotals.utilityToBattery,
    ];

    const totalLabel = 'Battery Energy Savings';
    const totalValue =
      costSeriesTotals.batteryToLoad -
      costSeriesTotals.pvToBattery -
      costSeriesTotals.utilityToBattery;

    let totalItem = {
      label: totalLabel,
      value: `${DOLLARS} ${formatNumberSignificant(totalValue, 3)}`,
      start: utilityChargingBatteryRange[1],
      end: batteryServingLoadRange[1],
      styleClasses: {
        bar: classes.totalArea,
      },
    };

    /* If there were no savings today show "NA" */
    if (totalValue <= 0) {
      totalItem = {
        label: totalLabel,
        value: `NA`,
        start: 0,
        end: 0,
        styleClasses: {
          bar: classes.naArea,
        },
      };
    }

    return [
      {
        label: 'Value of Battery to Load',
        value: `${DOLLARS} ${formatNumberSignificant(costSeriesTotals.batteryToLoad, 3)}`,
        start: batteryServingLoadRange[0],
        end: batteryServingLoadRange[1],
        styleClasses: {
          bar: classes.batteryServingLoadArea,
        },
      },
      {
        label: 'Cost of PV to Battery',
        value: `${DOLLARS} ${formatNumberSignificant(costSeriesTotals.pvToBattery, 3)}`,
        start: pvChargingBatteryRange[0],
        end: pvChargingBatteryRange[1],
        styleClasses: {
          bar: classes.pvChargingBatteryArea,
        },
      },
      {
        label: 'Cost of Utility to Battery',
        value: `${DOLLARS} ${formatNumberSignificant(costSeriesTotals.utilityToBattery, 3)}`,
        start: utilityChargingBatteryRange[0],
        end: utilityChargingBatteryRange[1],
        styleClasses: {
          bar: classes.utilityChargingBatteryArea,
        },
      },
      totalItem,
    ];
  }

  getPVTotals() {
    const { seriesTotals } = this.getSeriesTotals();
    const { classes } = this.props;

    const pvServingLoadRange = [0, seriesTotals.batteryToLoad];
    const meterRollbackRange = [
      pvServingLoadRange[1],
      pvServingLoadRange[1] + seriesTotals.pvToMeter,
    ];
    const pvChargingBatteryRange = [
      meterRollbackRange[1],
      meterRollbackRange[1] + seriesTotals.pvToBattery,
    ];

    return [
      {
        label: 'PV to Load',
        value: `${formatNumber(seriesTotals.pvToLoad, 0)} ${KWH}`,
        start: pvServingLoadRange[0],
        end: pvServingLoadRange[1],
        styleClasses: {
          bar: classes.pvServingLoadArea,
        },
      },
      {
        label: 'PV to Utility',
        value: `${formatNumber(seriesTotals.pvToMeter, 0)} ${KWH}`,
        start: meterRollbackRange[0],
        end: meterRollbackRange[1],
        styleClasses: {
          bar: classes.meterRollbackArea,
        },
      },
      {
        label: 'PV to Battery',
        value: `${formatNumber(seriesTotals.pvToBattery, 0)} ${KWH}`,
        start: pvChargingBatteryRange[0],
        end: pvChargingBatteryRange[1],
        styleClasses: {
          bar: classes.pvChargingBatteryArea,
        },
      },
    ];
  }

  getPVCostTotals() {
    const { costSeriesTotals } = this.getSeriesTotals();
    const { classes } = this.props;

    const pvServingLoadRange = [0, costSeriesTotals.batteryToLoad];
    const meterRollbackRange = [
      pvServingLoadRange[1],
      pvServingLoadRange[1] + costSeriesTotals.pvToMeter,
    ];
    const pvChargingBatteryRange = [
      meterRollbackRange[1],
      meterRollbackRange[1] + costSeriesTotals.pvToBattery,
    ];

    return [
      {
        label: 'Value of PV to Load',
        value: `${DOLLARS} ${formatNumberSignificant(costSeriesTotals.pvToLoad, 3)}`,
        start: pvServingLoadRange[0],
        end: pvServingLoadRange[1],
        styleClasses: {
          bar: classes.pvServingLoadArea,
        },
      },
      {
        label: 'Value of PV to Utility',
        value: `${DOLLARS} ${formatNumberSignificant(costSeriesTotals.pvToMeter, 3)}`,
        start: meterRollbackRange[0],
        end: meterRollbackRange[1],
        styleClasses: {
          bar: classes.meterRollbackArea,
        },
      },
      {
        label: 'Value of PV to Battery',
        value: `${DOLLARS} ${formatNumberSignificant(costSeriesTotals.pvToBattery, 3)}`,
        start: pvChargingBatteryRange[0],
        end: pvChargingBatteryRange[1],
        styleClasses: {
          bar: classes.pvChargingBatteryArea,
        },
      },
      {
        label: 'PV Energy Savings',
        value: `${DOLLARS} ${formatNumberSignificant(
          costSeriesTotals.pvToLoad + -1 * costSeriesTotals.pvToMeter,
          3
        )}`,
        start: pvServingLoadRange[0],
        end: meterRollbackRange[1],
        styleClasses: {
          bar: classes.totalArea,
        },
      },
    ];
  }

  renderPowerflowTable() {
    const { tous, powerTimeSeries: timeSeries } = this.props;
    const touStyles = this.getTOUStyles();
    if (!tous) {
      return null;
    }
    if (!timeSeries) {
      return null;
    }
    if (!touStyles) {
      return null;
    }

    const columns = [
      ['Utility to Load', 'utilityToLoad'],
      ['PV to Utility', 'pvToMeter'],
      ['PV to Load', 'pvToLoad'],
      ['PV to Battery', 'pvToBattery'],
      ['Utility to Battery', 'utilityToBattery'],
      ['Battery to Load', 'batteryToLoad'],
    ];

    const defaultCellValue = '--';

    // remove any tous that have no consumption rate to remove non-coincident
    const relevantTous = tous.filter((t) => (t.consumptionRate ? true : false));
    const relevantTouStyles = touStyles.filter((t) => relevantTous.find((s) => s.name === t.name));

    const rows = relevantTous.map((tou) => tou.name);
    const rowTitleColors = zipObject(
      rows,
      relevantTouStyles.map((touStyle) => touStyle.fillColor ?? '')
    );

    const data = [];
    for (const columnPair of columns) {
      for (const tou of relevantTous) {
        const value = tou[columnPair[1]];

        data.push({
          column: columnPair[0] as string,
          row: tou.name,
          value,
        });
      }
    }

    return (
      <PowerFlowTable
        rowTitleColors={rowTitleColors}
        columnNames={[...columns.map((c) => c[0] as string)]}
        rowNames={rows}
        data={data}
        cellLabel={KWH}
        defaultCellValue={defaultCellValue}
      />
    );
  }

  renderCostTable() {
    if (!this.props.tous) {
      return null;
    }
    if (!this.props.projections) {
      return null;
    }

    const touStyles = this.getTOUStyles();
    if (!touStyles) {
      return null;
    }

    const totals = {
      name: 'Totals',
      applicableTimes: [],
      consumptionChargeBaseline: sumBy(this.props.tous, (t) => t.consumptionChargeBaseline || 0),
      consumptionCharge: sumBy(this.props.tous, (t) => t.consumptionCharge || 0),
      nemCreditBaseline: 0,
      nemCredit: sumBy(this.props.tous, (t) => t.nemCredit || 0),
    };

    return (
      <CostTable
        touStyles={touStyles}
        touDatasets={[...this.props.tous, totals]}
        costProjections={this.props.projections}
      />
    );
  }

  renderOverviewLegend() {
    const { classes } = this.props;

    const legendItems = [
      {
        label: 'Load',
        styleClass: classes.loadLine,
        itemType: LegendItemType.line,
      },
      {
        label: 'PV to Load',
        styleClass: classes.pvServingLoadArea,
        itemType: LegendItemType.area,
      },
      {
        label: 'PV to Battery',
        styleClass: classes.pvChargingBatteryArea,
        itemType: LegendItemType.area,
      },
      {
        label: 'Battery to Load',
        styleClass: classes.batteryServingLoadArea,
        itemType: LegendItemType.area,
      },
      {
        label: 'Utility Meter',
        styleClass: classes.utilityUsageLine,
        itemType: LegendItemType.line,
      },
      {
        label: 'PV to Utility',
        styleClass: classes.meterRollbackArea,
        itemType: LegendItemType.area,
      },
      {
        label: 'Utility to Battery',
        styleClass: classes.utilityChargingBatteryArea,
        itemType: LegendItemType.area,
      },
      {
        label: 'Utility to Load',
        styleClass: classes.utilityServingLoadArea,
        itemType: LegendItemType.area,
      },
      {
        label: 'Target Demand Threshold',
        styleClass: classes.demandThresholdLine,
        itemType: LegendItemType.line,
      },
    ];

    return legendItems.map((item) => (
      <LegendItem
        {...item}
        key={item.label}
        size={16}
        style={{ width: '25%', padding: 2, boxSizing: 'border-box' }}
      />
    ));
  }

  render() {
    const { classes, siteTimezone } = this.props;
    const { activeXValue, xRangeMin, xRangeMax, chartDisplayUnits } = this.state;

    const timeSeries = this.getActiveOverviewTimeSeries();
    const dispatchTimeSeries = this.getActiveDispatchTimeSeries();

    const backgroundData = this.getTOUBackgroundData();
    const targetDateString = `${toDateString(this.props.targetDate)} 00:00:00`;
    // utc time of beginning of chart time range
    const targetDate = zonedTimeToUtc(targetDateString, siteTimezone);
    const tzOffset = getTimezoneOffset(siteTimezone, targetDate) / MINUTE_MILLISECONDS;

    // we want to render the page if any of the time series have values
    const hasData = some(values(timeSeries), (series) => series && series.length > 0);

    let lineDataSets: ChartSeries[] = [];
    if (hasData && timeSeries) {
      lineDataSets = [
        {
          values: timeSeries.load,
          styleClass: classes.loadLine,
        },
        {
          values: timeSeries.utilityMeter,
          styleClass: classes.utilityUsageLine,
        },
      ];

      if (chartDisplayUnits === ChartUnits.energy && timeSeries.demandThreshold) {
        lineDataSets.push({
          values: timeSeries.demandThreshold,
          styleClass: classes.demandThresholdLine,
        });
      }
    }

    return (
      <div>
        <Grid container spacing={2} style={{ width: 'auto', margin: 0 }}>
          {/* PAGE INTERFACE CONTROLS */}
          <Grid item xs={12} style={{ padding: 16 }}>
            <FormGroup row={true} style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <RadioToggle
                style={{ marginRight: 36 }}
                activeValue={chartDisplayUnits}
                items={[
                  {
                    value: ChartUnits.energy,
                    label: 'Energy View',
                  },
                  {
                    value: ChartUnits.revenue,
                    label: 'Revenue View',
                  },
                ]}
                handleSelectValue={(value) => {
                  this.handleChangeDisplayUnits(value as ChartUnits);
                }}
              />

              <DateSelector
                targetDate={toDate(this.props.targetDate)}
                handleSelectDate={(d) => this.props.handleSetTargetDate(d)}
              />
            </FormGroup>
          </Grid>

          {/* CONTROL OVERVIEW */}

          {this.props.isLoading && (
            <div
              style={{
                minHeight: '25vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <LoadWithSpinner />
            </div>
          )}

          {!this.props.isLoading && !hasData && (
            <div
              style={{
                minHeight: '25vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              There is no control data available for the selected date.
            </div>
          )}

          {!this.props.isLoading && hasData && (
            <>
              <PageSection>
                <div style={{ display: 'flex' }}>
                  <SectionTitle
                    style={{
                      paddingLeft: 24,
                      minHeight: '48px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    text="Control Overview"
                  />
                </div>

                <div className={classes.controlOverviewLegend}>{this.renderOverviewLegend()}</div>

                {timeSeries && (
                  <ControlOverviewChart
                    activeXValue={activeXValue}
                    xAxisFormatter={timeFormatOffset(tzOffset)}
                    yAxisFormatter={
                      this.isEnergyUnits() ? this.powerYAxisFormater : this.costYAxisFormater
                    }
                    yLabelFormatter={
                      this.isEnergyUnits() ? this.powerYLabelFormater : this.costYLabelFormater
                    }
                    xRange={{
                      start: xRangeMin || targetDate,
                      end: xRangeMax || addMinutes(targetDate, 60 * 24),
                    }}
                    backgroundRanges={backgroundData}
                    lineData={lineDataSets}
                    handleHover={(x) => this.handleHover(x)}
                    handleHoverEnd={() => this.handleHover(undefined)}
                    positiveAreaData={[
                      {
                        values: timeSeries.utilityToLoad,
                        styleClass: classes.utilityServingLoadArea,
                      },
                      {
                        values: timeSeries.pvToLoad,
                        styleClass: classes.pvServingLoadArea,
                      },
                      {
                        values: timeSeries.batteryToLoad,
                        styleClass: classes.batteryServingLoadArea,
                      },
                      {
                        values: timeSeries.utilityToBattery,
                        styleClass: classes.utilityChargingBatteryArea,
                      },
                      {
                        values: timeSeries.pvToBattery,
                        styleClass: classes.pvChargingBatteryArea,
                      },
                    ]}
                    negativeAreaData={[
                      {
                        values: invertSeries(timeSeries.pvToMeter),
                        styleClass: classes.meterRollbackArea,
                      },
                    ]}
                  />
                )}
              </PageSection>

              {/* BATTERY DISPATCH */}

              <CollapsingPageSection title="Battery Energy Dispatch" collapsed={true}>
                {timeSeries && dispatchTimeSeries && (
                  <>
                    <div className={classes.batteryDispatchLegend}>
                      <LegendItem
                        size={16}
                        label="Battery Charge Percentage"
                        styleClass={classes.batteryChargeLine}
                        itemType={LegendItemType.line}
                      />
                    </div>

                    <DispatchChart
                      activeXValue={activeXValue}
                      // @ts-ignore todo: fix type
                      xAxisFormatter={timeFormatOffset(tzOffset)}
                      yAxisFormatter={
                        this.isEnergyUnits() ? this.energyYAxisFormater : this.costYAxisFormater
                      }
                      yAltAxisFormatter={(y) => `${y}%`}
                      yLabelFormatter={
                        this.isEnergyUnits() ? this.energyYLabelFormater : this.costYLabelFormater
                      }
                      yAltLabelFormatter={(y) => (y >= 0 && y <= 100 ? `${y.toFixed(1)}%` : '')}
                      handleHover={(x) => this.handleHover(x)}
                      handleHoverEnd={() => this.handleHover(undefined)}
                      xRange={{
                        start: xRangeMin || targetDate,
                        end: xRangeMax || addMinutes(targetDate, 60 * 24),
                      }}
                      backgroundRanges={backgroundData}
                      barSeries={[
                        {
                          values: invertSeries(dispatchTimeSeries.utilityToBattery),
                          styleClass: classes.utilityChargingBatteryArea,
                        },
                        {
                          values: invertSeries(dispatchTimeSeries.pvToBattery),
                          styleClass: classes.pvChargingBatteryArea,
                        },
                        {
                          values: dispatchTimeSeries.batteryToLoad,
                          styleClass: classes.batteryServingLoadArea,
                        },
                      ]}
                      lineSeries={[
                        {
                          values: timeSeries.batteryChargePercentage,
                          styleClass: classes.batteryChargeLine,
                        },
                      ]}
                      areaSeries={[
                        {
                          values: timeSeries.batteryChargePercentage,
                          styleClass: classes.batteryChargeArea,
                        },
                      ]}
                    />

                    <PageSectionFooter style={{ display: 'flex' }}>
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <TotalsChart
                            rules={[0, 100]}
                            title={'Battery Dispatch Totals'}
                            items={this.getBatteryTotals()}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TotalsChart
                            rules={[0, 100]}
                            title={'Battery Dispatch Value'}
                            items={this.getBatteryCostTotals()}
                          />
                        </Grid>
                      </Grid>
                    </PageSectionFooter>
                  </>
                )}
              </CollapsingPageSection>

              {/* PV DISPATCH */}

              <CollapsingPageSection title="PV Energy Dispatch" collapsed={true}>
                {timeSeries && dispatchTimeSeries && (
                  <>
                    <DispatchChart
                      activeXValue={activeXValue}
                      // @ts-ignore todo: fix type
                      xAxisFormatter={timeFormatOffset(tzOffset)}
                      yAxisFormatter={
                        this.isEnergyUnits() ? this.energyYAxisFormater : this.costYAxisFormater
                      }
                      yLabelFormatter={
                        this.isEnergyUnits() ? this.energyYLabelFormater : this.costYLabelFormater
                      }
                      handleHover={(x) => this.handleHover(x)}
                      handleHoverEnd={() => this.handleHover(undefined)}
                      xRange={{
                        start: xRangeMin || targetDate,
                        end: xRangeMax || addMinutes(targetDate, 60 * 24),
                      }}
                      backgroundRanges={backgroundData}
                      barSeries={[
                        {
                          values: dispatchTimeSeries.pvToMeter,
                          styleClass: classes.meterRollbackArea,
                        },
                        {
                          values: dispatchTimeSeries.pvToBattery,
                          styleClass: classes.pvChargingBatteryArea,
                        },
                        {
                          values: dispatchTimeSeries.pvToLoad,
                          styleClass: classes.pvServingLoadArea,
                        },
                      ]}
                    />

                    <PageSectionFooter style={{ display: 'flex' }}>
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <TotalsChart
                            rules={[0, 100]}
                            title={'PV Dispatch Totals'}
                            items={this.getPVTotals()}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TotalsChart
                            rules={[0, 100]}
                            title={'PV Dispatch Value'}
                            items={this.getPVCostTotals()}
                          />
                        </Grid>
                      </Grid>
                    </PageSectionFooter>
                  </>
                )}
              </CollapsingPageSection>

              <PageSection>
                <div style={{ marginBottom: 16, padding: 12 }}>{this.renderPowerflowTable()}</div>
                <div style={{ padding: 12 }}>{this.renderCostTable()}</div>
              </PageSection>
            </>
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ControlSummaryPage);
