import React from 'react';
import { format, parse, startOfDay } from 'date-fns';
import { Button, TextField, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

interface Props {
  style?: React.CSSProperties;
  targetDate?: Date;
  handleSelectDate: (date: Date) => void;
}

interface State {
  currentValue: string;
}

const styles = (theme: Theme) =>
  createStyles({
    inputGroup: {
      display: 'inline-flex',
    },
    submitButton: {
      marginLeft: theme.spacing(),
    },
  });

type AllProps = Props & WithStyles<typeof styles>;

class DateSelector extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      currentValue: props.targetDate ? format(props.targetDate, 'yyyy-MM-dd') : '',
    };
  }

  onChange(e: React.ChangeEvent<{ value: string }>) {
    this.setState({
      currentValue: e.target.value,
    });
  }

  render() {
    const { targetDate, classes, style, handleSelectDate } = this.props;
    const { currentValue } = this.state;

    const isEmpty = !targetDate && !currentValue;
    const isUnChanged = targetDate && format(targetDate, 'yyyy-MM-dd') === currentValue;
    const isSubmitDisabled = isEmpty || isUnChanged;

    return (
      <div style={style} className={classes.inputGroup}>
        <TextField
          type="date"
          size="small"
          variant="outlined"
          value={currentValue}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => this.onChange(e)}
        />

        <Button
          disabled={isSubmitDisabled}
          className={classes.submitButton}
          variant="contained"
          size="small"
          color="primary"
          onClick={() =>
            handleSelectDate(startOfDay(parse(currentValue, 'yyyy-MM-dd', new Date())))
          }
        >
          Go
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(DateSelector);
