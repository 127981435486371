import { TagApi } from '../../../api/TagApi';
import { apiSagaV2 } from '../../../helpers/saga';
import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { fetchControlDataset } from './actions';

const handleFetchControlDataset = apiSagaV2(fetchControlDataset, (connection, action) => {
  const { siteId, date } = action.payload;
  return new TagApi(connection).fetchControlSummaryDataset(siteId, date);
});

export function* controlDatasetSagas() {
  yield takeEvery(getType(fetchControlDataset.request), handleFetchControlDataset);
}
