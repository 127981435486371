import React from 'react';
import { SimpleRequestStatus, Lifecycle } from '../../state/request';
import LoadWithSpinner from '../ui/Loading/LoadWithSpinner';
import { ErrorMessage } from '../ui/ErrorMessage';
import { SuccessMessage } from '../ui/SuccessMessage';
import { ApiError } from '../../api';
import { useLayoutEffect, useRef, useState } from 'react';

export interface RequestStatusProps {
  status: SimpleRequestStatus;
  successMessage?: string;
  loadMessage?: string;
  style?: React.CSSProperties;
}

export const RequestLifecycle: React.FC<RequestStatusProps> = (props) => {
  const { status, successMessage, loadMessage } = props;

  const wrapperRef = useRef<HTMLDivElement>();
  const [height, setHeight] = useState<number | undefined>();

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      setHeight(wrapperRef.current.offsetHeight);
    }
  }, []);

  if (status === Lifecycle.PENDING) {
    return (
      <div
        style={{
          display: 'flex',
          minHeight: height || 0,
          justifyContent: 'center',
          alignContent: 'center',
          padding: 16,
          ...props.style,
        }}
      >
        <LoadWithSpinner message={loadMessage || 'Loading...'} />
      </div>
    );
  } else if (status === Lifecycle.COMPLETED) {
    return (
      <div
        style={{
          display: 'flex',
          minHeight: height || 0,
          justifyContent: 'center',
          alignContent: 'center',
          padding: 16,
          ...props.style,
        }}
      >
        <SuccessMessage message={successMessage || 'Request Successful.'} />
      </div>
    );
  } else if (typeof status === 'object') {
    return (
      <div
        style={{
          display: 'flex',
          minHeight: height || 0,
          justifyContent: 'center',
          alignContent: 'center',
          padding: 16,
          ...props.style,
        }}
      >
        <ErrorMessage size="sm" message={(props.status as ApiError).message} />
      </div>
    );
  } else if (props.children) {
    return <div>{props.children}</div>;
  }
  return null;
};
