import React from 'react';
import analytics from '../../helpers/analytics';
import { ErrorPage } from '../modules/main/ErrorPage';

interface State {
  error?: { message: string };
}

const DEFAULT_ERROR_MESSAGE = 'An unknwon error has occured, please refresh.';

class ErrorBoundary extends React.Component<React.PropsWithChildren<{}>, State> {
  state: State = {
    error: undefined,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error: error });
    analytics.trackException(error, { errorInfo: JSON.stringify(errorInfo) });
  }

  renderErrorPage() {
    return (
      <ErrorPage
        message={this.state.error !== undefined ? this.state.error.message : DEFAULT_ERROR_MESSAGE}
      />
    );
  }

  render() {
    if (this.state.error) {
      return this.renderErrorPage();
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
