import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { UserApi } from '../../../api/UserApi';
import * as profileActions from './actions';
import { apiSagaV2 } from '../../../helpers/saga';

const fetchUserProfile = apiSagaV2(profileActions.fetchUserProfile, (connection, _action) => {
  return new UserApi(connection).fetchUserProfile();
});

export function* userProfileSagas() {
  yield takeEvery(getType(profileActions.fetchUserProfile.request), fetchUserProfile);
}
