import React from 'react';
import { DialogContent, DialogTitle, DialogActions, Grid } from '@material-ui/core';
import { User } from '../../../models';
import { PrimaryButton, CancelButton } from '../../ui/Buttons';

interface Props {
  onAccept: () => void;
  onCancel: () => void;
  user: User;
  actionLabel?: string;
  title?: string;
}

const DeleteUser: React.FC<Props> = (props) => {
  return (
    <>
      <DialogTitle>{props.title || 'Delete User'}</DialogTitle>
      <DialogContent>
        {props.children}
        <DialogActions>
          <Grid container>
            <Grid item style={{ marginRight: 'auto' }}>
              <CancelButton onClick={props.onCancel}>Cancel</CancelButton>
            </Grid>
            <Grid item>
              <PrimaryButton onClick={props.onAccept}>
                {props.actionLabel || 'Delete'}
              </PrimaryButton>
            </Grid>
          </Grid>
        </DialogActions>
      </DialogContent>
    </>
  );
};

export default DeleteUser;
