import { useHistory } from 'react-router-dom';
import {
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { EquipmentPageProps } from './EquipmentPage.d';
import { PathBuilder } from '../../../routing';
import { TagIcon } from '../../ui/Icons';
import identifiers from '../../../tests/identifiers';

const EquipmentListPinnedTags = ({
  activeEquipmentId,
  activeTab,
  pinnedTagIds,
  siteId,
}: EquipmentPageProps) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  return (
    <Collapse
      className={classes.root}
      data-testid={identifiers.equipmentPage.pinnedTagCount}
      in={pinnedTagIds.length > 0}
      timeout={theme.transitions.duration.shortest}
    >
      <ListItem
        classes={{
          root: classes.item,
          container: classes.container,
        }}
        ContainerComponent="div"
        onClick={() => history.push(PathBuilder.EQUIPMENT(siteId, pinnedTagIds))}
        button
        dense
        selected
      >
        <ListItemIcon className={classes.icon}>
          <TagIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            pinnedTagIds.length === 0
              ? '\u00A0'
              : `${pinnedTagIds.length} pinned tag${pinnedTagIds.length === 1 ? '' : 's'}`
          }
        />
        <ListItemSecondaryAction className={classes.secondaryAction}>
          <Tooltip title="Unpin All Tags">
            <IconButton
              data-testid={identifiers.equipmentPage.clearPinnedTags}
              edge="end"
              onClick={() =>
                history.push(PathBuilder.EQUIPMENT(siteId, [], activeEquipmentId, activeTab))
              }
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </Collapse>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    minHeight: 'auto !important',
  },
  container: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginLeft: theme.spacing(-0.5),
  },
  icon: {
    marginRight: theme.spacing(0.5),
    minWidth: 'auto',
  },
  item: {
    paddingLeft: theme.spacing(3),
  },
  secondaryAction: {
    right: theme.spacing(1.5),
  },
}));

export default EquipmentListPinnedTags;
