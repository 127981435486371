import React from 'react';
import LoadWithSpinner from '../../ui/Loading/LoadWithSpinner';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { getPushFeedStatus } from '../../../state/contexts/auth/selectors';
import { getUserProfile, fetchUserProfile } from '../../../state/entities';
import { pushFeedConnect, publishToken } from '../../../state/contexts/auth/actions';
import { useEffect } from 'react';
import { useRequest } from '../../../helpers/hooks';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { auth } from '../../../config';
import { getAccessToken } from '../../../helpers/saga';
import { featureFlags } from '../../../config/featureFlags';
import { ErrorMessage } from '../../ui/ErrorMessage';
import { LoadingPage } from '../../modules/main/LoadingPage';
import { Button } from '@material-ui/core';

/**
 * Publishes auth token to the store
 */
export const Auth0Adapter: React.FC = (props) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const persistedToken = useSelector(getAccessToken);

  /**
   * If in cypress environment (authenticating programmatically against Dev or QA)
   * We get the token from localStorage (token is initially retrieved programmatically through the oauth/token endpoint)
   */
  // @ts-ignore
  if (window.Cypress) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const auth0 = JSON.parse(localStorage.getItem('auth0Cypress')!);
      dispatch(publishToken(auth0.token));
    }, []);
  }

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently({
          audience: auth.audience,
          scope: auth.scope,
        });
        dispatch(publishToken(token));
      }
    })();
  }, [dispatch, isAuthenticated, getAccessTokenSilently]);

  /**
   * If in cypress environment, don't consider "isAuthenticated" since it isn't true
   * when we authenticate programmatically through the oauth/token endpoint
   */
  // @ts-ignore
  const shouldRenderContent = persistedToken && (window.Cypress || isAuthenticated);

  if (shouldRenderContent) {
    return <>{props.children ? props.children : null}</>;
  } else {
    return null;
  }
};

/**
 * Connects to push feed if status of push feed is disconnected
 */
const PushFeedWrapper: React.FC = (props) => {
  const pushStatus = useSelector(getPushFeedStatus);
  const dispatch = useDispatch();
  useEffect(() => {
    if (pushStatus === 'disconnected') {
      dispatch(pushFeedConnect());
    }
  }, [dispatch, pushStatus]);

  if (pushStatus === 'connecting' || pushStatus === 'disconnected') {
    return (
      <LoadingPage>
        <LoadWithSpinner message="Connecting to feed..." />
      </LoadingPage>
    );
  } else if (pushStatus === 'connected') {
    return <>{props.children ? props.children : null}</>;
  } else if (pushStatus === 'failed') {
    return (
      <LoadingPage>
        <div>
          <p>You have been disconnected due to inactivity.</p>
          <p>
            <Button variant="contained" onClick={() => dispatch(pushFeedConnect())}>
              Reconnect
            </Button>
          </p>
        </div>
      </LoadingPage>
    );
  } else {
    return null;
  }
};

/**
 * Loads user profile
 */
const UserProfileWrapper: React.FC = (props) => {
  const requestStatus = useRequest(fetchUserProfile.request())[1];
  const profile = useSelector(getUserProfile);

  if (typeof requestStatus === 'object') {
    return (
      <LoadingPage>
        <ErrorMessage message={requestStatus.message} />
      </LoadingPage>
    );
  } else if (profile) {
    return <>{props.children ? props.children : null}</>;
  } else {
    return (
      <LoadingPage>
        <LoadWithSpinner message="Loading Profile..." />
      </LoadingPage>
    );
  }
};

const PassThrough: React.FC = (props) => <>{props.children || null}</>;

/**
 * Checks that a user is authenticated and redirects if they are not
 */
const WithAuth = withAuthenticationRequired(PassThrough, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => (
    <LoadingPage>
      <LoadWithSpinner message="Authenticating..." />
    </LoadingPage>
  ),
});

/**
 * Acts as a guard component in the application route hierarchy, should be used to
 * render any authenticated routes.
 *
 * - Manages authentication checking,
 * - Connecting to push feed
 * - Loading user profile
 */
export const Protected: React.FC = (props) => {
  const dispatch = useDispatch();
  // If in cypress test environment bypass auth0 authentication
  if (featureFlags.mockHub) {
    // pass dummy token to the store
    dispatch(publishToken('testToken'));
    return (
      <PushFeedWrapper>
        <UserProfileWrapper>
          <>{props.children ? props.children : null}</>
        </UserProfileWrapper>
      </PushFeedWrapper>
    );
  }

  /**
   * If in cypress test environment (against Dev or QA)
   * We need to bypass withAuthenticationRequired since we programmatically
   * authenticate using the /oauth/token endpoint
   */
  // @ts-ignore
  if (window.Cypress) {
    return (
      <Auth0Adapter>
        <PushFeedWrapper>
          <UserProfileWrapper>
            <>{props.children ? props.children : null}</>
          </UserProfileWrapper>
        </PushFeedWrapper>
      </Auth0Adapter>
    );
  }

  return (
    <WithAuth>
      <Auth0Adapter>
        <PushFeedWrapper>
          <UserProfileWrapper>
            <>{props.children ? props.children : null}</>
          </UserProfileWrapper>
        </PushFeedWrapper>
      </Auth0Adapter>
    </WithAuth>
  );
};
