import { userSagas } from './entities/user/sagas';
import { alertSagas } from './entities/alert/sagas';
import { controlDatasetSagas } from './entities/controlDataset/sagas';
import { customerSagas } from './entities/customer/sagas';
import { reportSagas } from './entities/report/sagas';
import { alarmSagas } from './entities/alarm/sagas';
import { siteSagas } from './entities/site/sagas';
import { userProfileSagas } from './entities/profile/sagas';
import { notificationSagas } from './entities/notification/sagas';
import { snapshotSagas } from './entities/snapshot/sagas';
import { authContextSagas } from './contexts/auth/sagas';
import { appContextSagas } from './contexts/app';
import { portfolioPageSagas } from './contexts/portfolioPage';
import { analysisContextSagas } from './contexts/analysisPage';
import { installerSagas } from './entities/installer/sagas';
import { gridServicesSagas } from './contexts/optOutPage';
import { watchUtilityRateSagas } from './contexts/utilityRate';

export const sagas = {
  ...{ authContextSagas },
  ...{ appContextSagas },
  ...{ analysisContextSagas },
  ...{ portfolioPageSagas },
  ...{ gridServicesSagas },
  ...{ watchUtilityRateSagas },
  // entities
  ...{ alarmSagas },
  ...{ alertSagas },
  ...{ controlDatasetSagas },
  ...{ customerSagas },
  ...{ installerSagas },
  ...{ notificationSagas },
  ...{ reportSagas },
  ...{ siteSagas },
  ...{ snapshotSagas },
  ...{ userSagas },
  ...{ userProfileSagas },
};
