import { ApiClient } from './ApiClient';
import logger from '../../helpers/logger';
import { PushHub, pushHub as appPushHub } from '../../helpers/PushHub';
import { signalR } from '../../config';
import { Message, listen } from '../../helpers/signalr';

/**
 * Connection specifies both an api client which can be used to make http
 * requests, but also a push hub where the responses to those requests will
 * arrive.
 */
export class ApiConnection {
  public client: ApiClient;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public pushHub: PushHub<any>;

  public connectionId: string;

  static async listenToPushFeed(onDisconnect?: () => void) {
    return new Promise((resolve, reject) => {
      return listen({
        url: signalR.url,
        hub: signalR.pushHub,
        options: {
          logging: true,
        },
        listeners: {
          push: ApiConnection.onMessageReceived,
        },
        onConnected: resolve,
        onDisconnect,
        onError: reject,
      });
    });
  }

  static onMessageReceived(message: Message) {
    const payloadType = message.payloadType;
    const payload = message && message.payload && JSON.parse(message.payload);
    const statusCode = message.statusCode;
    const callId = message.callId;

    /*
    logger.groupCollapsed(`push message received: type ${message.payloadType}`);
    logger.log(JSON.stringify(payload, null, 2));
    logger.groupEnd();
    */

    if (callId && appPushHub.isListenerActive(callId)) {
      appPushHub.callListener(callId, {
        payloadType,
        callId,
        statusCode,
        payload,
      });
    } else if (payloadType !== null) {
      logger.warn(
        `No registered listener for callId: ${callId}, payloadType: ${payloadType}`,
        message
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(client: ApiClient, pushHub: PushHub<any>, connectionId: string) {
    this.client = client;
    this.pushHub = pushHub;
    this.connectionId = connectionId;
  }
}
