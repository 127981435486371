import React from 'react';
import { withStyles, WithStyles, createStyles, TextField, Chip, MenuItem } from '@material-ui/core';

interface Props {
  recipients: string[];
  options: string[];
  onAdd: (recipientEmails: string[]) => void;
  onRemove: (recipientEmail: string) => void;
}

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 1,
    },
  });

interface State {
  isTouched: boolean;
}

interface OwnProps {
  required?: boolean;
  label?: string;
}

type AllProps = Props & OwnProps & WithStyles<typeof styles>;

class RecipientList extends React.Component<AllProps, State> {
  state = {
    isTouched: false,
  };

  handleSelectUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValues = event.target.value as unknown as string[];
    this.props.onAdd(selectedValues);
  };

  render() {
    const { isTouched } = this.state;
    const { options, recipients, onRemove, required, label, classes } = this.props;
    return (
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        select
        SelectProps={{
          multiple: true,
          renderValue: (selected) => {
            if (!selected) {
              return undefined;
            }
            return (
              <div className={classes.root}>
                {(selected as string[]).map((value) => (
                  <Chip
                    className={classes.chip}
                    key={value}
                    label={value}
                    onDelete={() => onRemove(value)}
                  />
                ))}
              </div>
            );
          },
        }}
        label={label}
        value={recipients}
        onChange={this.handleSelectUser}
        onBlur={
          required
            ? () => {
                this.setState({ isTouched: true });
              }
            : undefined
        }
        InputLabelProps={{
          shrink: Boolean(recipients && recipients.length > 0),
        }}
        error={required && isTouched ? recipients.length === 0 : undefined}
        helperText={
          required && isTouched && recipients.length === 0 ? 'Recipients are required' : undefined
        }
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

export default withStyles(styles)(RecipientList);
