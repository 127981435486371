import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Chip,
} from '@material-ui/core';
import { ExportReportRequest } from '../../../models';
import { PrimaryButton, CancelButton } from '../../ui/Buttons';
import { WithRequest } from '../../utility/WithRequest';
import { exportReport } from '../../../state/entities';
import { RequestLifecycle } from '../../ui/RequestLifecycle';
import { parse, format } from 'date-fns';

const styles = (theme: Theme) =>
  createStyles({
    chipRoot: {
      marginRight: theme.spacing() * 0.5,
    },
    chipLabel: {
      paddingRight: theme.spacing(),
      paddingLeft: theme.spacing(),
    },
    tagContainer: {
      padding: theme.spacing(),
      backgroundColor: theme.palette.grey[200],
      maxHeight: 150,
      overflowY: 'auto',
    },
  });

interface Props {
  onCancel: (clearForm: boolean) => void;
  exportRequest: ExportReportRequest;
}

const SUCCESS_MESSAGE = 'Export processing. Results may take up to 15 minutes to arrive.';

const ExportConfirmationModal = (props: Props & WithStyles<typeof styles>) => {
  const { onCancel, exportRequest, classes } = props;
  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <WithRequest
        actionCreator={exportReport.request}
        render={({ boundActionCreator, status, isComplete, isError, isNew }) => (
          <>
            <RequestLifecycle status={status} successMessage={SUCCESS_MESSAGE}>
              <DialogTitle>Confirm Export</DialogTitle>
              <DialogContent>
                <Grid container direction="column" spacing={1}>
                  {exportRequest.additionalRecipientEmails.length > 0 && (
                    <Grid item container alignItems="center">
                      <Grid item style={{ paddingRight: '0.5rem' }}>
                        <Typography>CC to:</Typography>
                      </Grid>
                      <Grid item>
                        {exportRequest.additionalRecipientEmails.map((recipientEmail) => (
                          <Chip
                            key={recipientEmail}
                            classes={{
                              root: classes.chipRoot,
                              label: classes.chipLabel,
                            }}
                            label={recipientEmail}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <Typography>
                      Date Range:{' '}
                      <b>
                        {format(
                          parse(exportRequest.historicalTS, 'yyyy-MM-dd', new Date()),
                          'MM/dd/yyyy'
                        )}
                      </b>{' '}
                      &ndash;{' '}
                      <b>
                        {format(
                          parse(exportRequest.modernTS, 'yyyy-MM-dd', new Date()),
                          'MM/dd/yyyy'
                        )}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div className={classes.tagContainer}>
                      {exportRequest.tagNames.map((tag) => (
                        <Typography key={tag}>{tag}</Typography>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
            </RequestLifecycle>
            <DialogActions>
              {isNew && (
                <>
                  <CancelButton onClick={() => onCancel(false)}>Cancel</CancelButton>
                  <PrimaryButton onClick={() => boundActionCreator(exportRequest)}>
                    Send
                  </PrimaryButton>
                </>
              )}
              {(isComplete || isError) && (
                <PrimaryButton onClick={() => onCancel(isComplete)}>Ok</PrimaryButton>
              )}
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
};

export default withStyles(styles)(ExportConfirmationModal);
