import { ReportStateSlice } from './reducer';
import { ReportConfiguration } from '../../../models';

const getRoot = (state: ReportStateSlice) => {
  return state.entities.report;
};

export const getSiteReportConfigurations = (
  rootState: ReportStateSlice,
  siteId: number
): ReportConfiguration[] => {
  return getRoot(rootState).bySiteId[siteId];
};
