import React from 'react';
import * as d3 from 'd3';
import { ChartContextData, DataRange, Margins, ScreenSize } from './types';
import { isEqual, clamp } from 'lodash';
import useSize from '@react-hook/size';
import { useEffect } from 'react';

export interface ChartProps {
  style?: React.CSSProperties;
  height: number;
  width?: number;
  range: DataRange;
  altRanges?: DataRange[];
  margins: Margins;
  handleMouseLeave?: () => void;
  handleMouseMove?: (pos: { mouseX: number; mouseY: number; x: Date; y: number }) => void;
}

export interface ChartState {
  size:
    | {
        width: number;
        height: number;
      }
    | undefined;
}

interface SizerProps {
  elRef: React.RefObject<HTMLDivElement>;
  onResize: (size: ScreenSize) => void;
}

const Sizer: React.FC<SizerProps> = ({ children, elRef, onResize }) => {
  const [width, height] = useSize(elRef.current);

  useEffect(() => {
    onResize({ width, height });
  }, [onResize, height, width]);

  return <>{children}</>;
};

export const chartContext = React.createContext<ChartContextData>({} as never);

/**
 * Note: width, height, mouseX, and mouseY are duplicated between instance attributes
 * and state attributes. Instance attributes are used as a cache and can be updated possibly
 * multiple times between animation frames without causing the component to re-render.
 *
 * See handleFrame for the logic that controls how instance changes are saved to
 * component state.
 */
export class ChartPane extends React.Component<ChartProps, ChartState> {
  width: number = 500;

  height: number = 300;

  mouseX: number | undefined;

  mouseY: number | undefined;

  xScale?: d3.ScaleTime<number, number>;

  yScale?: d3.ScaleLinear<number, number>;

  altYScales?: d3.ScaleLinear<number, number>[];

  svgRef: React.RefObject<SVGSVGElement> = React.createRef();

  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  dirty: boolean = true;

  mouseDirty: boolean = false;

  frameRequest?: number | null;

  state: ChartState = {} as ChartState;

  boundInRange: (mouseX: number, mouseY: number) => boolean;

  constructor(props: ChartProps) {
    super(props);
    // bind listeners here to reduce overhead
    this.handleFrame = this.handleFrame.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.boundInRange = this.inRange.bind(this);
  }

  componentDidMount() {
    this.handleFrame();
  }

  componentWillUnmount() {
    // Clean up requestAnimationFrame loop
    if (this.frameRequest) {
      window.cancelAnimationFrame(this.frameRequest);
    }
  }

  /**
   * Helper function that is passed to context consumers for testing if
   * a screen x, y position is within the chart area
   */
  inRange(mouseX: number, mouseY: number) {
    const size = this.state.size;
    const clampedX = clamp(
      mouseX,
      this.props.margins.left || 0,
      (size?.width || 0) - (this.props.margins.right || 0)
    );
    if (clampedX !== mouseX) {
      return false;
    }
    const clampedY = clamp(
      mouseY,
      this.props.margins.top || 0,
      (size?.height || 0) - (this.props.margins.bottom || 0)
    );
    if (clampedY !== mouseY) {
      return false;
    }

    return true;
  }

  shouldComponentUpdate(nextProps: ChartProps) {
    if (this.props.range !== nextProps.range) {
      // if the range changes we want to make sure to call set state in
      // handleFrame so children update, use the dirty flag to accomplish this
      this.dirty = true;
    }
    if (!this.frameRequest) {
      this.frameRequest = window.requestAnimationFrame(this.handleFrame);
    }
    return true;
  }

  render() {
    const chartStyle = {
      position: 'relative' as 'relative',
      width: this.props.width || '100%',
      height: this.props.height || 400,
    };

    const contextValue = {
      ...this.state,
      size: this.state.size,
      inRange: this.boundInRange,
      margins: this.props.margins,
      xScale: this.xScale,
      yScale: this.yScale,
      altYScales: this.altYScales,
      mouseY: this.mouseY,
      mouseX: this.mouseX,
    } as ChartContextData;

    return (
      <chartContext.Provider value={contextValue}>
        <Sizer elRef={this.wrapperRef} onResize={this.handleResize} />
        <div ref={this.wrapperRef} style={{ ...this.props.style, ...chartStyle }}>
          <svg
            ref={this.svgRef}
            width={this.state.size?.width}
            height={this.state.size?.height}
            onMouseMove={this.handleMouseMove}
            onMouseLeave={this.handleMouseLeave}
          >
            {
              /* svg must render once to set width and height */
              this.state.size?.width && this.state.size?.height ? this.props.children : null
            }
          </svg>
        </div>
      </chartContext.Provider>
    );
  }

  private updateXScale() {
    const r = this.props.range;

    const screenRange = [
      0 + (this.props.margins.left || 0),
      this.width - (this.props.margins.right || 0),
    ];

    const xScale = d3.scaleTime().domain([r.xMin, r.xMax]).range(screenRange);

    this.xScale = xScale;
  }

  private updateYScale() {
    const r = this.props.range;

    const screenRange = [
      this.height - (this.props.margins.bottom || 0),
      0 + (this.props.margins.top || 0),
    ];

    const yScale = d3.scaleLinear().domain([r.yMin, r.yMax]).range(screenRange);

    this.yScale = yScale;
  }

  private updateAltYScales() {
    const ranges = this.props.altRanges;
    if (!ranges) {
      return;
    }

    const altScales = [];

    for (const range of ranges) {
      const screenRange = [
        this.height - (this.props.margins.bottom || 0),
        0 + (this.props.margins.top || 0),
      ];

      const yScale = d3.scaleLinear().domain([range.yMin, range.yMax]).range(screenRange);
      altScales.push(yScale);
    }
    this.altYScales = altScales;
  }

  /**
   * Update any global chart properties that should be passed to
   * chart sub components to facilitate a re-render.
   */
  private handleFrame() {
    this.frameRequest = null;
    // Trigger callback if mouse position has changed
    if (this.mouseDirty) {
      if (!this.mouseX || !this.mouseY) {
        if (this.props.handleMouseLeave) {
          this.props.handleMouseLeave();
        }
      } else if (this.xScale && this.yScale && this.props.handleMouseMove) {
        this.props.handleMouseMove({
          mouseX: this.mouseX,
          mouseY: this.mouseY,
          x: this.xScale.invert(this.mouseX),
          y: this.yScale.invert(this.mouseY),
        });
      }
      this.mouseDirty = false;
    }

    if (this.dirty) {
      this.setState((prevState, currentProps) => {
        // Queue next update on next animation frame
        let newState = {
          ...prevState,
        };

        // Handle resize
        const wrapperEl = this.wrapperRef.current;
        if (wrapperEl) {
          const wrapperSize = wrapperEl.getBoundingClientRect();
          if (wrapperSize.height !== this.height || this.dirty) {
            this.height = wrapperEl.clientHeight;
            this.updateYScale();
            this.updateAltYScales();
          }

          if (wrapperSize.width !== this.width || this.dirty) {
            this.width = wrapperEl.clientWidth;
            this.updateXScale();
          }
          if (this.dirty) {
            this.dirty = false;
          }
        }

        // Trigger state update if width or height has changed
        if (this.width !== prevState.size?.width || this.height !== prevState.size?.height) {
          newState = {
            ...newState,
            size: {
              width: this.width,
              height: this.height,
            },
          };
        }

        if (!isEqual(prevState, newState)) {
          return newState;
        } else {
          return prevState;
        }
      });
    }
  }

  private handleMouseMove(e: React.MouseEvent) {
    if (this.wrapperRef.current) {
      var rect = this.wrapperRef.current.getBoundingClientRect();
      this.mouseX = e.clientX - rect.left;
      this.mouseY = e.clientY - rect.top;
      this.mouseDirty = true;
      if (!this.frameRequest) {
        this.frameRequest = window.requestAnimationFrame(this.handleFrame);
      }
    }
  }

  private handleResize(size: ScreenSize) {
    if (size.height && size.width) {
      this.height = size.height;
      this.width = size.width;
      this.dirty = true;
      if (!this.frameRequest) {
        this.frameRequest = window.requestAnimationFrame(this.handleFrame);
      }
    }
  }

  private handleMouseLeave() {
    this.mouseX = undefined;
    this.mouseY = undefined;
    this.mouseDirty = true;
    if (!this.frameRequest) {
      this.frameRequest = window.requestAnimationFrame(this.handleFrame);
    }
  }
}
