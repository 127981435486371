import { createApiActions } from '../../../helpers/actions';
import { AlertUpdate, AlertPayload } from '../../../api';

export const fetchSiteAlerts = createApiActions<AlertPayload[]>()(
  'alert/fetchSiteAlerts',
  'alert/fetchSiteAlertsSuccess',
  'alert/fetchSiteAlertsError',
  (siteId: number) => ({ siteId })
);

export const updateAlert = createApiActions<AlertPayload>()(
  'alert/updateAlert',
  'alert/updateAlertSuccess',
  'alert/updateAlertError',
  (alertUpdate: AlertUpdate) => ({ alertUpdate })
);
