import React from 'react';
import { Grid } from '@material-ui/core';
import {
  Site,
  User,
  ReportConfiguration,
  GenerateReportRequest,
  ExportReportRequest,
  ReportType,
} from '../../../models';
import ReportTypeMenu from './ReportTypeMenu';
import ReportConfigurationForm from './ReportConfigurationForm';
import ReportConfirmationModal from './ReportConfirmationModal';
import ExportConfigurationForm from './ExportConfigurationForm';
import ExportConfirmationModal from './ExportConfirmationModal';

enum ReportsPageAction {
  NONE,
  GENERATE,
  EXPORT,
}

export interface Props {
  users: User[];
  siteId: number;
  site: Site;
  reportConfigurations: ReportConfiguration[];
}

export enum ReportMenu {
  EXPORT = 'Custom Export',
}

export type ReportMenuType = ReportType | ReportMenu;

type AllProps = Props;

interface State {
  activeMenuItem: ReportMenuType;
  action: ReportsPageAction;
  actionRequest?: GenerateReportRequest | ExportReportRequest;
  // used to remount form components on submition
  submitCounter: number;
}

class ReportsPage extends React.Component<AllProps, State> {
  state: State = {
    activeMenuItem:
      this.props.reportConfigurations.length > 0
        ? (this.props.reportConfigurations[0].reportName as ReportType)
        : ReportMenu.EXPORT,
    action: ReportsPageAction.NONE,
    actionRequest: undefined,
    submitCounter: 0,
  };

  handleReportMenuChange = (type: ReportMenuType) => this.setState({ activeMenuItem: type });

  handleDismissActionModal = (clearForm: boolean) => {
    this.setState((prevState) => ({
      action: ReportsPageAction.NONE,
      submitCounter: clearForm ? prevState.submitCounter + 1 : prevState.submitCounter,
    }));
  };

  handleShowGenerateConfirmationModal = (request: GenerateReportRequest) => {
    this.setState({ action: ReportsPageAction.GENERATE, actionRequest: request });
  };

  handleShowExportConfirmationModal = (request: ExportReportRequest) => {
    this.setState({ action: ReportsPageAction.EXPORT, actionRequest: request });
  };

  render() {
    const { siteId, site, reportConfigurations, users } = this.props;
    const { activeMenuItem, action, actionRequest, submitCounter } = this.state;
    const activeConfiguration = reportConfigurations.reduce((acc, config) => {
      if (config.reportName === activeMenuItem) {
        acc = config;
      }
      return acc;
    });
    return (
      <div
        data-testid="reports-page"
        style={{ position: 'relative', display: 'flex', height: '100%' }}
      >
        <Grid container direction="row" style={{ justifyContent: 'stretch', flexWrap: 'nowrap' }}>
          <Grid item style={{ flexShrink: 0, display: 'flex' }}>
            <ReportTypeMenu
              active={activeMenuItem}
              configurations={reportConfigurations}
              onReportTypeChange={this.handleReportMenuChange}
            />
          </Grid>
          <Grid item style={{ position: 'relative', flex: 1, padding: 16, paddingBottom: 0 }}>
            {Object.values(ReportType).includes(activeMenuItem as ReportType) && (
              <ReportConfigurationForm
                key={`${activeMenuItem}-${submitCounter}`}
                site={site}
                availableTimePeriods={activeConfiguration.availableTimePeriods}
                reportName={activeConfiguration.reportName}
                reportId={activeConfiguration.reportId}
                onSubmit={this.handleShowGenerateConfirmationModal}
                users={users.map((user) => user.emailAddress)}
              />
            )}
            {activeMenuItem === ReportMenu.EXPORT && (
              <ExportConfigurationForm
                submitCounter={submitCounter}
                siteId={siteId}
                onSubmit={this.handleShowExportConfirmationModal}
                site={site}
                users={users.map((user) => user.emailAddress)}
              />
            )}
          </Grid>
        </Grid>
        {action === ReportsPageAction.GENERATE && actionRequest && (
          <ReportConfirmationModal
            reportRequest={actionRequest as GenerateReportRequest}
            onCancel={this.handleDismissActionModal}
          />
        )}
        {action === ReportsPageAction.EXPORT && actionRequest && (
          <ExportConfirmationModal
            exportRequest={actionRequest as ExportReportRequest}
            onCancel={this.handleDismissActionModal}
          />
        )}
      </div>
    );
  }
}

export default ReportsPage;
