class ImageCache {
  private cachedImages: Map<string, HTMLImageElement> = new Map();

  public fetchAndStoreImage(url: string) {
    const imageEl = new Image();
    imageEl.src = url;

    this.cachedImages.set(url, imageEl);
  }

  public getImage(url: string) {
    return this.cachedImages.get(url);
  }

  public deleteImage(url: string) {
    return this.cachedImages.delete(url);
  }
}

export const imageCache = new ImageCache();
