import { getType, ActionType } from 'typesafe-actions';
import { produce } from 'immer';
import { Action } from 'redux';
import * as snapshotActions from '../entities/snapshot/actions';
import * as alarmActions from '../entities/alarm/actions';
import * as notificationActions from '../entities/notification/actions';
import { ApiError } from '../../api';
import { clearSubscription } from './actions';
import { subPortfolio } from '../contexts/portfolioPage';

export enum SubscriptionLifecycle {
  STARTING = 'starting',
  ACTIVE = 'active',
  STOPPED = 'stopped',
}

export type SubscriptionStatus = undefined | SubscriptionLifecycle | ApiError;

/**
 * When adding new requests add each corresponding action triad to this array.
 */
const allSubscriptionActions = [
  snapshotActions.subSnapshots,
  notificationActions.subNotifications,
  alarmActions.subActiveAlarms,
  alarmActions.subAlarmSummaries,
  subPortfolio,
] as const; // This allows typescript to infer the types at each index

export type StartActionCreator = typeof allSubscriptionActions[number]['start'];
export type StopActionCreator = typeof allSubscriptionActions[number]['stop'];

export type StartAction = ActionType<typeof allSubscriptionActions[number]['start']>;
export type UpdateAction = ActionType<typeof allSubscriptionActions[number]['update']>;
export type ErrorAction = ActionType<typeof allSubscriptionActions[number]['error']>;
export type StopAction = ActionType<typeof allSubscriptionActions[number]['stop']>;

export type StartActionType = StartAction['type'];
export type UpdateActionType = UpdateAction['type'];
export type ErrorActionType = ErrorAction['type'];
export type StopActionType = StopAction['type'];

const startLookup = new Set<StartActionType>();
const updateLookup = new Set<UpdateActionType>();
const errorLookup = new Set<ErrorActionType>();
const stopLookup = new Set<StopActionType>();

allSubscriptionActions.forEach((actionQuad) => {
  startLookup.add(getType(actionQuad.start));
  updateLookup.add(getType(actionQuad.update));
  errorLookup.add(getType(actionQuad.error));
  stopLookup.add(getType(actionQuad.stop));
});

function isStartAction(action: Action<unknown>): action is StartAction {
  return startLookup.has(action.type as StartActionType);
}

function isUpdateAction(action: Action<unknown>): action is UpdateAction {
  return updateLookup.has(action.type as UpdateActionType);
}

function isErrorAction(action: Action<unknown>): action is ErrorAction {
  return errorLookup.has(action.type as ErrorActionType);
}

function isStopAction(action: Action<unknown>): action is StopAction {
  return stopLookup.has(action.type as StopActionType);
}

export interface SubscriptionStatusState<T extends StartAction> {
  trigger: T;
  status: SubscriptionStatus;
  cleared: boolean;
}

export interface SubscriptionState {
  byPayload: {
    // action type
    [Key: string]: {
      // serilaizedPayload
      [Key: string]: string; // action: uuid
    };
  };
  byStarting: {
    // action type
    [Key: string]: {
      // uid
      [Key: string]: boolean;
    };
  };
  byActive: {
    // action type
    [Key: string]: {
      // uid
      [Key: string]: boolean;
    };
  };
  byError: {
    // action type
    [Key: string]: {
      // uid
      [Key: string]: boolean;
    };
  };
  byUid: {
    [Key: string]: SubscriptionStatusState<StartAction>;
  };
}

export const toRootState = (subscriptionState: SubscriptionState) => ({
  subscriptions: subscriptionState,
});

export interface SubscriptionStateSlice {
  subscriptions: SubscriptionState;
}

const initialState = {
  byPayload: {},
  byStarting: {},
  byActive: {},
  byError: {},
  byUid: {},
};

// TODO: this is an unappealing way to serialize payloads, but will work for now
export const serializeAction = (action: StartAction) => {
  return JSON.stringify(action.payload);
};

const setStarting = (draft: SubscriptionState, action: StartAction) => {
  const { uid } = action.meta;
  const payloadId = serializeAction(action);

  draft.byPayload[action.type] = draft.byPayload[action.type] || {};
  draft.byPayload[action.type][payloadId] = uid;

  draft.byStarting[action.type] = draft.byStarting[action.type] || {};
  draft.byStarting[action.type][uid] = true;

  draft.byUid[uid] = {
    trigger: action,
    status: SubscriptionLifecycle.STARTING,
    cleared: false,
  };
};

const setActive = (draft: SubscriptionState, action: UpdateAction) => {
  const { triggerAction } = action.payload;
  const { uid } = triggerAction.meta;
  if (draft.byStarting[triggerAction.type]) {
    delete draft.byStarting[triggerAction.type][uid];
  }

  draft.byActive[action.type] = draft.byActive[action.type] || {};
  draft.byActive[action.type][uid] = true;

  draft.byUid[uid].status = SubscriptionLifecycle.ACTIVE;
};

const setError = (draft: SubscriptionState, action: ErrorAction) => {
  const { triggerAction, error } = action.payload;
  const { uid } = triggerAction.meta;
  if (draft.byStarting[triggerAction.type]) {
    delete draft.byStarting[triggerAction.type][uid];
  }
  if (draft.byActive[triggerAction.type]) {
    delete draft.byActive[triggerAction.type][uid];
  }
  draft.byUid[uid].status = {
    ...error,
    actionId: uid,
  };

  draft.byError[triggerAction.type] = draft.byError[triggerAction.type] || {};
  draft.byError[triggerAction.type][uid] = true;
};

const setStopped = (draft: SubscriptionState, action: StopAction) => {
  const { triggerAction } = action.payload;
  const { uid } = triggerAction.meta;
  if (draft.byActive[triggerAction.type]) {
    delete draft.byActive[triggerAction.type][uid];
  }
  if (draft.byUid[uid]) {
    draft.byUid[uid].status = SubscriptionLifecycle.STOPPED;
  }
};

export const subscriptionReducer = produce(
  (
    draft: SubscriptionState,
    action:
      | StartAction
      | UpdateAction
      | ErrorAction
      | StopAction
      | ActionType<typeof clearSubscription>
  ) => {
    if (action.type === getType(clearSubscription)) {
      if (draft.byUid[action.payload.actionUid]) {
        draft.byUid[action.payload.actionUid].cleared = true;
        delete draft.byError?.[action.type]?.[action.payload.actionUid];
      }
    } else if (isStartAction(action)) {
      setStarting(draft, action);
    } else if (isUpdateAction(action)) {
      setActive(draft, action);
    } else if (isErrorAction(action)) {
      setError(draft, action);
    } else if (isStopAction(action)) {
      setStopped(draft, action);
    }
  },
  initialState
);
