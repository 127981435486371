import { ControlledDataTable, Column, OrderDirectionType } from '../../ui/DataTable';
import { Link } from 'react-router-dom';
import { Typography, Tooltip, makeStyles } from '@material-ui/core';
import { red, amber } from '@material-ui/core/colors';
import { darken } from '@material-ui/core/styles';
import { SiteSummary, AlarmPriority, EnrollmentStatus } from '../../../models';
import { PathBuilder } from '../../../routing';
import { formatValueAndUnit } from '../../../helpers/formatting';
import NotificationsIcon from '@material-ui/icons/Notifications';
import WarningIcon from '@material-ui/icons/Warning';
import DangerousIcon from '@material-ui/icons/Report';
import { InfoOutline, HighPriority } from '../../ui/Icons';

const ACTIVE_ALARMS_COLOR = amber[50];
const CRITICAL_ALARMS_COLOR = red[100];
const ACTIVE_ALARMS_COLOR_DARK = darken(ACTIVE_ALARMS_COLOR, 0.1);
const CRITICAL_ALARMS_COLOR_DARK = darken(CRITICAL_ALARMS_COLOR, 0.1);

export const filterableSiteSummaryTableColumns = {
  zipCode: 'Zip',
  address: 'Address',
  installationDate: 'Installation Date',
  storageStatus: 'Storage Status',
  dailyEnergyGeneration: 'Daily Gen.',
  netLoad: 'Net Load',
  load: 'Load',
  powerOutput: 'Power Output',
  storageDischarge: 'Storage Discharge',
  totalPower: 'Total Power',
  utility: 'Utility',
  customerName: 'Customer',
  gridServicesEnrollmentStatus: 'Enrollment Status',
};

interface SiteSummaryTableProps {
  siteSummaries: SiteSummary[];
  columnsShowing: string[];
  page: number;
  orderBy: string;
  orderDirection: OrderDirectionType;
  handleSort: (columnId: string, direction: OrderDirectionType) => void;
  isAdmin?: boolean;
}

const getAlarmIcon = (priority?: AlarmPriority) => {
  switch (priority) {
    case AlarmPriority.INFO:
      return InfoOutline;
    case AlarmPriority.WARNING:
      return WarningIcon;
    case AlarmPriority.HIGH:
      return HighPriority;
    case AlarmPriority.CRITICAL:
      return DangerousIcon;
    default:
      return null;
  }
};

const Pill: React.FC = (props) => {
  const classes = useStyles();
  return <span className={classes.pill}>{props.children}</span>;
};

const hasCriticalAlarms = (summary: SiteSummary) =>
  summary.highestActiveAlarmStatus === AlarmPriority.CRITICAL;

const getSiteSummaryTableConfig = (isAdmin?: boolean): Column<SiteSummary>[] => {
  const baseStickyCellStyles = {
    zIndex: 3,
    position: 'sticky' as 'sticky',
  };

  const baseHeadCellStyles = {
    backgroundColor: '#fff',
    zIndex: 4,
  };

  return [
    {
      id: 'name',
      label: 'Name',
      field: (siteSummary) => siteSummary.name,
      isSortable: true,
      isSearchable: true,
      renderCell: (siteSummary) => (
        <Tooltip title={siteSummary.name}>
          <Link
            to={PathBuilder.SITE(siteSummary.siteId)}
            style={{ width: '100%', textDecoration: 'none' }}
          >
            <Typography noWrap style={{ fontSize: 14 }}>
              {siteSummary.name}
            </Typography>
          </Link>
        </Tooltip>
      ),
      cellStyle: {
        head: {
          ...baseStickyCellStyles,
          ...baseHeadCellStyles,
          maxWidth: 150,
          width: 150,
          minWidth: 150,
          left: 0,
        },
        body: {
          ...baseStickyCellStyles,
          maxWidth: 150,
          width: 150,
          minWidth: 150,
          left: 0,
        },
      },
    },
    {
      id: 'alarmStatus',
      label: 'Active Alarms',
      field: (siteSummary) => siteSummary.activeAlarmCount,
      isSortable: true,
      renderCell: (siteSummary) => {
        const activeAlarms = siteSummary.activeAlarmCount;
        if (activeAlarms === 0 || !siteSummary.highestActiveAlarmCount) {
          return '-';
        }
        let tooltipText = '';
        if (activeAlarms === siteSummary.highestActiveAlarmCount && activeAlarms === 1) {
          tooltipText = `1 ${siteSummary.highestActiveAlarmStatus} Alarm`;
        } else if (activeAlarms === siteSummary.highestActiveAlarmCount && activeAlarms > 1) {
          tooltipText = `${activeAlarms} ${siteSummary.highestActiveAlarmStatus} Alarms`;
        } else if (
          activeAlarms !== siteSummary.highestActiveAlarmCount &&
          activeAlarms - siteSummary.highestActiveAlarmCount > 1
        ) {
          tooltipText = `${siteSummary.highestActiveAlarmCount} ${
            siteSummary.highestActiveAlarmStatus
          } / ${activeAlarms - siteSummary.highestActiveAlarmCount} Other Alarms`;
        } else {
          tooltipText = `${siteSummary.highestActiveAlarmCount} ${
            siteSummary.highestActiveAlarmStatus
          } / ${activeAlarms - siteSummary.highestActiveAlarmCount} Other Alarm`;
        }

        const Icon = getAlarmIcon(siteSummary.highestActiveAlarmStatus);
        return (
          <Tooltip title={tooltipText}>
            <Link
              style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
              to={PathBuilder.ALARMS_DEFAULT(siteSummary.siteId)}
            >
              {Icon && (
                <Pill>
                  {siteSummary.highestActiveAlarmCount} <Icon />
                </Pill>
              )}
              {activeAlarms !== siteSummary.highestActiveAlarmCount && (
                <>
                  <span style={{ fontSize: 14 }}> / </span>
                  <Pill>
                    {siteSummary.activeAlarmCount - siteSummary.highestActiveAlarmCount}{' '}
                    <NotificationsIcon />
                  </Pill>
                </>
              )}
            </Link>
          </Tooltip>
        );
      },
      cellStyle: {
        head: {
          ...baseStickyCellStyles,
          ...baseHeadCellStyles,
          textAlign: 'center',
          maxWidth: 140,
          width: 140,
          minWidth: 140,
          left: 150,
          boxShadow: `inset -1px 0 0 rgba(0, 0, 0, 0.12),  inset 0 -1px 0 rgba(0, 0, 0, 0.12)`,
        },
        body: {
          ...baseStickyCellStyles,
          textAlign: 'center',
          maxWidth: 140,
          width: 140,
          minWidth: 140,
          left: 150,
          boxShadow: `inset -1px 0 0 rgba(0, 0, 0, 0.12)`,
        },
      },
    },
    {
      id: 'address',
      label: 'Address',
      field: (siteSummary) => siteSummary.fullAddress,
      isSortable: false,
      isSearchable: true,
      renderCell: (siteSummary) => (
        <Tooltip title={siteSummary.fullAddress}>
          <div
            style={{
              maxWidth: 180,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {(siteSummary.fullAddress || '').trim() || '-'}
          </div>
        </Tooltip>
      ),
      cellStyle: {
        head: {
          textAlign: 'center',
          color: '#8b8b8b',
        },
      },
    },
    {
      id: 'zipCode',
      label: 'Zip',
      field: (siteSummary) => siteSummary.zipCode,
      isSortable: true,
      renderCell: (siteSummary) => (siteSummary.zipCode || '').trim() || '-',
      cellStyle: {
        head: {
          textAlign: 'center',
          padding: '4px 2px',
          whiteSpace: 'normal',
        },
        body: {
          textAlign: 'center',
          paddingRight: 16,
        },
      },
    },
    {
      id: 'installationDate',
      label: 'Installation Date',
      field: (siteSummary) => siteSummary.installationDate,
      isSortable: true,
      renderCell: (siteSummary) => new Date(siteSummary.installationDate).toLocaleDateString(),
      cellStyle: {
        head: {
          textAlign: 'center',
          padding: '4px 2px',
          whiteSpace: 'normal',
        },
        body: {
          textAlign: 'center',
          paddingRight: 16,
        },
      },
    },
    {
      id: 'totalPower',
      label: `${filterableSiteSummaryTableColumns.totalPower} (kW)`,
      field: (siteSummary) => siteSummary.totalPower,
      isSortable: true,
      renderCell: (siteSummary) =>
        formatValueAndUnit(siteSummary.totalPower, undefined, {
          fractionDigits: 1,
          zeroPlaceholder: '-',
        }),
      cellStyle: {
        head: {
          textAlign: 'center',
          padding: '4px 2px',
          whiteSpace: 'normal',
        },
        body: {
          textAlign: 'right',
          paddingRight: 36,
        },
      },
    },
    {
      id: 'load',
      label: `${filterableSiteSummaryTableColumns.load} (kW)`,
      field: (siteSummary) => siteSummary.currentKPIs.load,
      isSortable: true,
      renderCell: (siteSummary) =>
        formatValueAndUnit(siteSummary.currentKPIs.load, undefined, {
          fractionDigits: 1,
          zeroPlaceholder: '-',
        }),
      cellStyle: {
        head: {
          textAlign: 'center',
          padding: '4px 2px',
          whiteSpace: 'normal',
        },
        body: {
          textAlign: 'right',
          paddingRight: 36,
        },
      },
    },
    {
      id: 'netLoad',
      label: `${filterableSiteSummaryTableColumns.netLoad} (kW)`,
      field: (siteSummary) => siteSummary.currentKPIs.netLoad,
      isSortable: true,
      renderCell: (siteSummary) =>
        formatValueAndUnit(siteSummary.currentKPIs.netLoad, undefined, {
          fractionDigits: 1,
          zeroPlaceholder: '-',
        }),
      cellStyle: {
        head: {
          textAlign: 'center',
          padding: '4px 2px',
          whiteSpace: 'normal',
        },
        body: {
          textAlign: 'right',
          paddingRight: 36,
        },
      },
    },
    {
      id: 'powerOutput',
      label: `${filterableSiteSummaryTableColumns.powerOutput} (kW)`,
      field: (siteSummary) => siteSummary.currentKPIs.powerOutput,
      isSortable: true,
      renderCell: (siteSummary) =>
        formatValueAndUnit(siteSummary.currentKPIs.powerOutput, undefined, {
          fractionDigits: 1,
          zeroPlaceholder: '-',
        }),
      cellStyle: {
        head: {
          textAlign: 'center',
          padding: '4px 2px',
          whiteSpace: 'normal',
        },
        body: {
          textAlign: 'right',
          paddingRight: 36,
        },
      },
    },
    {
      id: 'storageDischarge',
      label: `${filterableSiteSummaryTableColumns.storageDischarge} (kW)`,
      field: (siteSummary) => siteSummary.currentKPIs.storageDischarge,
      isSortable: true,
      renderCell: (siteSummary) =>
        formatValueAndUnit(siteSummary.currentKPIs.storageDischarge, undefined, {
          fractionDigits: 1,
          zeroPlaceholder: '-',
        }),
      cellStyle: {
        head: {
          textAlign: 'center',
          padding: '4px 2px',
          whiteSpace: 'normal',
        },
        body: {
          textAlign: 'right',
          paddingRight: 36,
        },
      },
    },
    {
      id: 'dailyEnergyGeneration',
      label: `${filterableSiteSummaryTableColumns.dailyEnergyGeneration} (kWh)`,
      field: (siteSummary) => siteSummary.currentKPIs.dailyEnergyGeneration,
      isSortable: true,
      renderCell: (siteSummary) =>
        formatValueAndUnit(siteSummary.currentKPIs.dailyEnergyGeneration, undefined, {
          fractionDigits: 1,
          zeroPlaceholder: '-',
        }),
      cellStyle: {
        head: {
          textAlign: 'center',
          padding: '4px 2px',
          whiteSpace: 'normal',
        },
        body: {
          textAlign: 'right',
          paddingRight: 36,
        },
      },
    },
    {
      id: 'storageStatus',
      label: filterableSiteSummaryTableColumns.storageStatus,
      field: (siteSummary) => (siteSummary.currentKPIs.storageStatus || '').trim() || '-',
      isSortable: true,
      cellStyle: {
        head: {
          textAlign: 'center',
          padding: '4px 2px',
          whiteSpace: 'normal',
        },
        body: {
          padding: '0 16px',
          textAlign: 'center',
        },
      },
    },
    {
      id: 'utility',
      label: filterableSiteSummaryTableColumns.utility,
      field: (siteSummary) => (siteSummary.utilityName || '').trim() || '-',
      isSortable: true,
      cellStyle: {
        head: {
          textAlign: 'center',
          padding: '4px 2px',
          whiteSpace: 'normal',
        },
      },
    },
    {
      id: 'gridServicesEnrollmentStatus',
      label: filterableSiteSummaryTableColumns.gridServicesEnrollmentStatus,
      field: (siteSummary) =>
        siteSummary.gridServicesEnrollmentStatus
          ? EnrollmentStatus[(siteSummary.gridServicesEnrollmentStatus || '').trim()]
          : '-',
      isSortable: true,
      cellStyle: {
        head: {
          textAlign: 'center',
          padding: '4px 2px',
          whiteSpace: 'normal',
        },
        body: {
          padding: '0 16px',
          textAlign: 'center',
        },
      },
    },
    ...(isAdmin
      ? [
          {
            id: 'customerName',
            label: filterableSiteSummaryTableColumns.customerName,
            field: (siteSummary: SiteSummary) => siteSummary.customerName,
            isSortable: true,
            cellStyle: {
              head: {
                textAlign: 'center' as 'center',
                padding: '4px 2px',
                whiteSpace: 'normal' as 'normal',
              },
            },
          },
        ]
      : []),
  ];
};

const SiteSummaryTable = (props: SiteSummaryTableProps) => {
  const { isAdmin, siteSummaries, columnsShowing, page, orderBy, orderDirection, handleSort } =
    props;

  const classes = useStyles();

  const getRowClass = (siteSummary: SiteSummary) => {
    if (hasCriticalAlarms(siteSummary)) {
      return classes.criticalAlarmRow;
    }
    if (siteSummary.activeAlarmCount > 0) {
      return classes.activeAlarmRow;
    }
    return classes.siteSummaryTableRow;
  };

  let tableConfig = getSiteSummaryTableConfig(isAdmin);

  tableConfig = tableConfig.filter(
    (c) =>
      columnsShowing.includes(c.id) ||
      !Object.keys(filterableSiteSummaryTableColumns).includes(c.id)
  );

  return (
    <ControlledDataTable<SiteSummary>
      fullHeight
      elementKey={(siteSummary) => siteSummary.siteId.toString()}
      elements={siteSummaries}
      columnMapping={tableConfig}
      handleSort={handleSort}
      page={page}
      orderBy={orderBy}
      orderDirection={orderDirection}
      rowClass={getRowClass}
    />
  );
};

const useStyles = makeStyles(() => {
  return {
    siteSummaryTableRow: {
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#f3f3f3',
        '& td': {
          backgroundColor: '#f3f3f3',
        },
      },
      '& td': {
        backgroundColor: '#fff',
        padding: '8px 8px',
      },
    },
    activeAlarmRow: {
      backgroundColor: ACTIVE_ALARMS_COLOR,
      '&:hover': {
        backgroundColor: `${ACTIVE_ALARMS_COLOR_DARK} !important`,
        '& td': {
          backgroundColor: `${ACTIVE_ALARMS_COLOR_DARK} !important`,
        },
      },
      '& td': {
        backgroundColor: ACTIVE_ALARMS_COLOR,
        padding: '8px 8px',
      },
    },
    criticalAlarmRow: {
      backgroundColor: CRITICAL_ALARMS_COLOR,
      '&:hover': {
        backgroundColor: `${CRITICAL_ALARMS_COLOR_DARK} !important`,
        '& td': {
          backgroundColor: `${CRITICAL_ALARMS_COLOR_DARK} !important`,
        },
      },
      '& td': {
        backgroundColor: CRITICAL_ALARMS_COLOR,
        padding: '8px 8px',
      },
    },
    pill: {
      display: 'inline-flex',
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: '0 3px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});

export default SiteSummaryTable;
