import React from 'react';

import { ChartPane, chartContext } from '../../ui/chart/ChartPane';
import XAxis from '../../ui/chart/XAxis';
import YAxis from '../../ui/chart/YAxis';
import HorizontalGrid from '../../ui/chart/HorizontalGrid';
import Crosshairs from '../../ui/chart/Crosshairs';
import StackedAreaSeries, { stackAreaSeries } from '../../ui/chart/StackedAreaSeries';
import LineSeries from '../../ui/chart/LineSeries';
import BackgroundSeries from '../../ui/chart/BackgroundSeries';
import { timeFormat } from '../../ui/chart/helpers';
import { ChartSeries, BackgroundRange, ChartContextData } from '../../ui/chart/types';

interface Props {
  style?: React.CSSProperties;
  height?: number;
  activeXValue?: Date;
  activeSeries?: string;
  xRange: {
    start: Date;
    end: Date;
  };
  xAxisFormatter?: (d: Date) => string;
  yAxisFormatter?: (d: number) => string;
  yLabelFormatter?: (d: number) => string;
  handleHover?: (x: Date, y: number) => void;
  handleHoverEnd?: () => void;
  handleSelectSeries?: (series: string) => void;
  handleZoom?: (start: Date, end: Date) => void;
  backgroundRanges?: BackgroundRange[];
  lineData?: ChartSeries[];
  positiveAreaData?: ChartSeries[];
  negativeAreaData?: ChartSeries[];
}

const ControlOverviewChart: React.SFC<Props> = (props) => {
  const { lineData, positiveAreaData, negativeAreaData } = props;

  const backgroundData = props.backgroundRanges;
  const [negativeAreaStackedData, negativeAreaRange] = stackAreaSeries(negativeAreaData || []);
  const [positiveAreaStackedData, positiveAreaRange] = stackAreaSeries(positiveAreaData || []);

  const xRangeMin = props.xRange.start;
  const xRangeMax = props.xRange.end;

  let yRangeMin = negativeAreaRange.minY;
  let yRangeMax = positiveAreaRange.maxY;

  const padding = (yRangeMax - yRangeMin) * 0.05;
  yRangeMin = yRangeMin - padding;
  yRangeMax = yRangeMax + padding;

  const handleMouseMove = (pos: { x: Date; y: number }) =>
    props.handleHover && props.handleHover(pos.x, pos.y);
  const handleMouseLeave = () => props.handleHoverEnd && props.handleHoverEnd();

  return (
    <div style={{ width: '100%', boxSizing: 'border-box' }}>
      <ChartPane
        handleMouseMove={handleMouseMove}
        handleMouseLeave={handleMouseLeave}
        height={props.height || 240}
        range={{
          xMin: xRangeMin,
          xMax: xRangeMax,
          yMin: yRangeMin,
          yMax: yRangeMax,
        }}
        margins={{
          top: 0,
          bottom: 26,
          left: 0,
          right: 0,
        }}
      >
        <chartContext.Consumer>
          {(context: ChartContextData) => (
            <>
              <BackgroundSeries {...context} data={backgroundData || []} />
              <HorizontalGrid
                {...context}
                marginLeft={context.margins.left}
                marginRight={context.margins.right}
                tickCount={5}
              />
              <StackedAreaSeries {...context} data={negativeAreaStackedData} />
              <StackedAreaSeries {...context} data={positiveAreaStackedData} />
              <LineSeries {...context} data={lineData || []} />
              <XAxis
                {...context}
                inset={true}
                offset={-1}
                // @ts-ignore todo: fix type
                format={props.xAxisFormatter || timeFormat}
              />
              <YAxis
                {...context}
                inset={true}
                offset={context.margins.left}
                format={props.yAxisFormatter || ((d) => `${d}`)}
              />

              {props.activeXValue && context.inRange(context.mouseX, context.mouseY) ? (
                <Crosshairs
                  {...context}
                  targetPosition={{ x: context.xScale(props.activeXValue) ?? 0, y: context.mouseY }}
                  margins={{ ...context.margins, bottom: 0 }}
                  bottomValue={props.xAxisFormatter && props.xAxisFormatter(props.activeXValue)}
                  leftValue={
                    props.yLabelFormatter &&
                    props.yLabelFormatter(context.yScale.invert(context.mouseY))
                  }
                />
              ) : null}
            </>
          )}
        </chartContext.Consumer>
      </ChartPane>
    </div>
  );
};

export default ControlOverviewChart;
