import {
  StartAction,
  serializeAction,
  SubscriptionStateSlice,
  SubscriptionLifecycle,
  SubscriptionStatus,
} from './reducer';
import { some, every } from 'lodash';

export const isUnrequested = (
  state: SubscriptionStateSlice,
  triggerAction: StartAction
): boolean => {
  const key = serializeAction(triggerAction);
  const uid = (state.subscriptions.byPayload[triggerAction.type] || {})[key];
  return uid === undefined;
};

const lookupActionRecord = (state: SubscriptionStateSlice, triggerAction: StartAction) => {
  const key = serializeAction(triggerAction);
  const uid = (state.subscriptions.byPayload[triggerAction.type] || {})[key];
  const record = state.subscriptions.byUid[uid];
  return record;
};

export const isStarting = (state: SubscriptionStateSlice, triggerAction: StartAction): boolean => {
  const record = lookupActionRecord(state, triggerAction);
  return Boolean(record && record.status === SubscriptionLifecycle.STARTING);
};

export const isActive = (state: SubscriptionStateSlice, triggerAction: StartAction): boolean => {
  const record = lookupActionRecord(state, triggerAction);
  return Boolean(record && record.status === SubscriptionLifecycle.ACTIVE);
};

export const isError = (state: SubscriptionStateSlice, triggerAction: StartAction): boolean => {
  const record = lookupActionRecord(state, triggerAction);
  return Boolean(record && typeof record.status === 'object');
};

export const getStatus = (
  state: SubscriptionStateSlice,
  triggerAction: StartAction
): SubscriptionStatus => {
  const record = lookupActionRecord(state, triggerAction);
  return record ? record.status : undefined;
};

export const getLastDispatched = <T extends StartAction>(
  state: SubscriptionStateSlice,
  triggerAction: T
): T | undefined => {
  const record = lookupActionRecord(state, triggerAction);
  return record ? (record.trigger as T) : undefined;
};

/**
 * Given multiple subscription statuses, returns the "lowest" status,
 * If any are STARTING returns STARTING,
 * If any are ERROR returns ERROR,
 * If any are STOPPED returns STOPPED
 * If all are ACTIVE returns ACTIVE
 */
export const mergeStatus = (...args: SubscriptionStatus[]) => {
  if (some(args, (arg) => arg === SubscriptionLifecycle.STARTING || arg === undefined)) {
    return SubscriptionLifecycle.STARTING;
  }
  if (some(args, (arg) => arg === SubscriptionLifecycle.STOPPED)) {
    return SubscriptionLifecycle.STOPPED;
  }
  if (some(args, (arg) => typeof arg === 'object')) {
    return args.find((arg) => typeof arg === 'object');
  }
  if (every(args, (arg) => arg === SubscriptionLifecycle.ACTIVE)) {
    return SubscriptionLifecycle.ACTIVE;
  }
  return;
};
