import React from 'react';
import { AlarmsPage } from './AlarmsPage';
import { getSiteContext } from '../../../state/contexts/site';
import {
  getIsActive,
  setQuery,
  getQuery,
  getPage,
  setPage,
  setActiveOnly,
} from '../../../state/contexts/alarmsPage';
import { useSelector } from 'react-redux';
import {
  subAlarmSummaries,
  queryAlarmSummaries,
  fetchAlarmSummaries,
  findSummariesByRange,
} from '../../../state/entities';
import { getAlarmSummariesByQuery } from '../../../state/entities';
import { ActionType } from 'typesafe-actions';
import { RootState } from '../../../state';
import { useAction, useRequest, useSubscription } from '../../../helpers/hooks';
import { SubscriptionLifecycle } from '../../../state/subscription';
import { Lifecycle } from '../../../state/request';

type RequestAction =
  | ActionType<typeof queryAlarmSummaries.request>
  | ActionType<typeof fetchAlarmSummaries.request>;
const LOOKBACK = 1000 * 60 * 60 * 24 * 7;

export const AlarmsPageLoader: React.FC = () => {
  const site = useSelector(getSiteContext);
  const { id: siteId } = site;
  const isActiveOnly = useSelector(getIsActive);
  const query = useSelector(getQuery);
  const doSetQuery = useAction(setQuery);
  const doSetActiveOnly = useAction(setActiveOnly);
  const doSetPage = useAction(setPage);

  let action: RequestAction;
  if (query != null) {
    action = queryAlarmSummaries.request(siteId, query);
  } else {
    action = fetchAlarmSummaries.request(siteId, LOOKBACK);
  }

  let [requestKey, requestStatus] = useRequest(action, query !== null);

  const alarmSummaries = useSelector((state: RootState) =>
    query == null
      ? findSummariesByRange(state, siteId, LOOKBACK, isActiveOnly)
      : getAlarmSummariesByQuery(state, requestKey.meta.uid)
  );

  const subStatus = useSubscription(query === null, subAlarmSummaries, site.id, LOOKBACK);

  if (query === null) {
    if (subStatus === SubscriptionLifecycle.ACTIVE) {
      requestStatus = Lifecycle.COMPLETED;
    } else if (subStatus === SubscriptionLifecycle.STARTING) {
      requestStatus = Lifecycle.PENDING;
    } else if (subStatus instanceof Object) {
      requestStatus = subStatus;
    } else {
      requestStatus = undefined;
    }
  }

  const page = useSelector(getPage);

  return (
    <AlarmsPage
      site={site}
      page={page}
      query={query || undefined}
      isActiveOnly={isActiveOnly}
      requestStatus={requestStatus}
      alarmSummaries={alarmSummaries || []}
      setQuery={doSetQuery}
      setIsActiveOnly={doSetActiveOnly}
      setPage={doSetPage}
    />
  );
};
