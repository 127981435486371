import React from 'react';
import { useSelector } from 'react-redux';
import { withStyles, WithStyles, Theme, Button, Typography, Box } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { requestSiteDocument } from '../../../state/entities';
import { getSiteContext } from '../../../state/contexts/site';
import { WithRequest } from '../../utility/WithRequest';
import { PathBuilder } from '../../../routing';
import bgImage from '../../../assets/bg.jpg';
import SiteLogo from '../../../components/ui/SiteLogo';

const styles = (theme: Theme) => ({
  logoContainer: {
    width: '300px',
  },
  icon: {
    marginRight: theme.spacing(),
  },
  root: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
  },
});

export interface Props {
  siteId: number;
  documentName: string;
}

type AllProps = Props & WithStyles<typeof styles> & Props;

const SiteDocument: React.FC<AllProps> = (props) => {
  const { classes, siteId, documentName } = props;

  const site = useSelector(getSiteContext);

  return (
    <Box
      data-testid="site-document-page"
      className={classes.root}
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <WithRequest
        actionCreator={requestSiteDocument.request}
        render={({ status, boundActionCreator, isError, isPending }) => (
          <>
            <Box my={1} className={classes.logoContainer}>
              <SiteLogo
                customerId={site.customerId}
                isDark={true}
                link={PathBuilder.SITE(siteId)}
              />
            </Box>
            <Box my={1}>
              <Typography variant="h6">{documentName}</Typography>
            </Box>
            {typeof status === 'object' && status.message && (
              <Box my={1}>
                <Typography variant="subtitle1">Error Processing Request</Typography>
                <Typography color="error" style={{ padding: 0 }}>
                  {status.message}
                </Typography>
              </Box>
            )}
            <Box my={1}>
              <Button
                disabled={isPending || isError}
                onClick={() => boundActionCreator(siteId, documentName)}
                color="primary"
                variant="contained"
                size="large"
              >
                <DownloadIcon className={classes.icon} />
                {isPending ? 'Downloading' : `Download`}
              </Button>
            </Box>
          </>
        )}
      />
    </Box>
  );
};

export default withStyles(styles)(SiteDocument);
