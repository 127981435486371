import { ActionsOf } from '../../../helpers/actions';
import { keyBy } from 'lodash';
import { getType } from 'typesafe-actions';
import * as alertActions from './actions';
import { produce } from 'immer';
import { AlertDistribution, Alert } from '../../../models';
import { Normalized } from '../../../helpers/norm';

export interface AlertDistributionNorm
  extends Normalized<AlertDistribution, 'user', { userId: number }> {}

export interface AlertNorm
  extends Normalized<
    Alert,
    'relatedAlarmTag' | 'distributionList' | 'isActive',
    { relatedAlarmTagId: string; distributionList: AlertDistributionNorm[] }
  > {}

export interface AlertEntityState {
  byId: { [Key: string]: AlertNorm };
  // provides ordering of alerts based on server response
  lookupBySiteId: { [Key: number]: string[] };
}

export interface AlertStateSlice {
  entities: {
    alert: AlertEntityState;
  };
}

const initialState = {
  byId: {},
  lookupBySiteId: {},
};

type AlertActions = ActionsOf<typeof alertActions>;

export const alertEntityReducer = produce((draft: AlertEntityState, action: AlertActions) => {
  switch (action.type) {
    case getType(alertActions.fetchSiteAlerts.success): {
      const { triggerAction, result: alerts } = action.payload;
      const { siteId } = triggerAction.payload;

      const byId = keyBy(alerts, (alertObj) => alertObj.alertTagId);
      const lookup = alerts.map((alertObj) => alertObj.alertTagId);
      draft.byId = {
        ...draft.byId,
        ...byId,
      };
      draft.lookupBySiteId[siteId] = lookup;
      break;
    }

    case getType(alertActions.updateAlert.success): {
      const { result: alertObj } = action.payload;
      const { alertTagId, ...alertRest } = alertObj;
      draft.byId[alertTagId] = {
        alertTagId,
        ...alertRest,
      };
      break;
    }

    default:
      break;
  }
}, initialState);
