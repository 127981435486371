import React from 'react';
import * as d3 from 'd3';
import { ChartPane, chartContext } from '../../ui/chart/ChartPane';
import XAxis from '../../ui/chart/XAxis';
import YAxis from '../../ui/chart/YAxis';
import LineSeries from '../../ui/chart/LineSeries';
import { ZoomBar } from '../../ui/chart/ZoomBar';
import HorizontalGrid from '../../ui/chart/HorizontalGrid';
import Crosshairs from '../../ui/chart/Crosshairs';
import { getSeriesRange } from '../../ui/chart/helpers';
import { ChartContextData, TimeRange, SeriesValue } from '../../ui/chart/types';
import { Dictionary, min, max, mapValues, compact, sortedIndexBy, some } from 'lodash';
import { TimeSeries, NumericDataPoint, TagDefinition } from '../../../models';
import { makeStyles, Link, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { OpSummaryChartSourceKey, ForecastSources } from './config';
import StackedAreaSeries, { stackAreaSeries } from '../../ui/chart/StackedAreaSeries';
import { palette } from '../../../config';
import { useMemo } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { formatNumberSignificant } from '../../../helpers/formatting';
import DataSources from './DataSources';

export interface OpSummaryChartProps {
  className?: string;
  height?: number;
  focusDate: Date;
  tagsMap: Record<OpSummaryChartSourceKey, string | undefined>;
  snapshots: Dictionary<TimeSeries>;
  hideRates?: boolean;
  toggleRateSwitcherDisplay(): void;
  ismVoult: boolean;
}

const yLabelFormatter = (v: number) => formatNumberSignificant(v, 4);
const yAxisFormatter = d3.format('.3g');
const xAxisFormatter = d3.timeFormat('%b %d, %Y %-H:%M:%S');

const defaultFillAlpha = 0.4;
const defaultLineThickness = 0;

const DEFAULT_UNIT = 'kW';
const DEFAULT_RATE_UNIT = '$/kW';

const useStyles = makeStyles(() => ({
  utilityRollbackArea: {
    fill: '#332288',
    strokeOpacity: 0.6,
    fillOpacity: defaultFillAlpha,
    strokeWidth: defaultLineThickness,
  },
  utilityConsumptionArea: {
    fill: palette.utility,
    strokeOpacity: 0.6,
    fillOpacity: defaultFillAlpha,
    strokeWidth: defaultLineThickness,
  },
  baseGenerationArea: {
    fill: '#86A4BF',
    strokeOpacity: 0.6,
    fillOpacity: defaultFillAlpha,
    strokeWidth: defaultLineThickness,
  },
  storageDischargingArea: {
    fill: palette.storage,
    strokeOpacity: 0.6,
    fillOpacity: defaultFillAlpha,
    strokeWidth: defaultLineThickness,
  },
  storageChargingArea: {
    color: palette.storage,
    fillOpacity: defaultFillAlpha,
    lineThickness: defaultLineThickness,
  },
  pvArea: {
    fill: palette.solar,
    strokeOpacity: 0.6,
    fillOpacity: defaultFillAlpha,
    strokeWidth: defaultLineThickness,
  },
  loadLine: {
    stroke: '#000000',
    strokeOpacity: 1,
    fillOpacity: 0,
  },
  hidden: {
    strokeOpacity: 0,
    fillOpacity: 0,
    visibility: 'hidden',
  },
  demandLine: {
    stroke: palette.load,
    strokeOpacity: 1,
    strokeWidth: 2,
  },
  rateLine: {
    stroke: '#3300ff',
    strokeOpacity: 1,
  },
  stateOfChargeLine: {
    stroke: palette.stateOfCharge,
    strokeOpacity: 1,
    strokeDasharray: 7,
  },
  ellipLeft: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '200px',
    direction: 'rtl',
    textAlign: 'left',
  },
  legend: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginRight: 16,
    maxWidth: '22em',
    minWidth: '16em',
    marginBottom: 16,
    border: '1px solid #e0e0e0',
    borderRadius: 4,
  },
  forecastBreak: {
    stroke: '#888',
    strokeOpacity: 1,
    strokeDasharray: 7,
  },
}));

const margins = {
  top: 16,
  bottom: 26,
  left: 0,
  right: 0,
};

const toSeriesValue = (point: NumericDataPoint) => ({ x: point.time, y: point.value });

interface ValueRange {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

const maxRange = (ranges: ValueRange[]): ValueRange => {
  if (ranges.length === 0) {
    return {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
    };
  }
  return {
    minX: min(ranges.map((range) => range.minX)) as number,
    maxX: max(ranges.map((range) => range.maxX)) as number,
    minY: min(ranges.map((range) => range.minY)) as number,
    maxY: max(ranges.map((range) => range.maxY)) as number,
  };
};

interface LegendItemProps {
  sourceKey: OpSummaryChartSourceKey;
  isForecast?: boolean;
  className?: string;
  setHighlighted?: (key: OpSummaryChartSourceKey | undefined) => void;
  toggleActive?: (key: OpSummaryChartSourceKey | undefined) => void;
  isActive: boolean;
  value?: string;
  label: string;
  seriesType: 'line' | 'area';
}

const LegendItem = (props: LegendItemProps) => {
  const {
    setHighlighted,
    toggleActive,
    className,
    isForecast,
    value,
    sourceKey,
    isActive,
    label,
    seriesType,
  } = props;
  return (
    <div
      key={sourceKey}
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        padding: 2,
        marginRight: 8,
        alignItems: 'center',
        opacity: isActive ? 1 : 0.7,
        fontSize: 12,
        width: '200px',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHighlighted && setHighlighted(sourceKey)}
      onMouseLeave={() => setHighlighted && setHighlighted(undefined)}
      onClick={() => toggleActive && toggleActive(sourceKey)}
    >
      <div style={{ width: 16, height: 16, marginRight: 4, flexShrink: 0, position: 'relative' }}>
        <svg style={{ width: 16, height: 16 }}>
          {seriesType === 'area' ? (
            <rect className={className} width={16} height={16} />
          ) : (
            <line className={className} x1={0} x2={16} y1={7.5} y2={7.5} />
          )}
        </svg>
        {!isActive && (
          <ClearIcon
            style={{ height: 16, width: 16, color: '#fff', inset: 0, position: 'absolute' }}
          />
        )}
      </div>
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: isForecast ? '#777' : '#000',
        }}
      >
        {label}{' '}
      </span>
      <span
        data-testid={`legend-item-value-${sourceKey}`}
        style={{
          marginLeft: 'auto',
          paddingLeft: '1em',
          whiteSpace: 'nowrap',
          color: isForecast ? '#777' : '#000',
        }}
      >
        {value}
      </span>
    </div>
  );
};

interface LegendProps {
  focusDate: Date;
  activeX?: Date;
  nearest?: Dictionary<number | undefined>;
  tagsMap: Dictionary<TagDefinition | undefined>;
  setHighlighted?: (key: OpSummaryChartSourceKey | undefined) => void;
  toggleActive?: (key: OpSummaryChartSourceKey | undefined) => void;
  active: OpSummaryChartSourceKey[];
}

export const Legend = (props: LegendProps) => {
  const { focusDate, activeX, active, tagsMap, nearest, setHighlighted, toggleActive } = props;
  const classes = useStyles();

  const actions = { setHighlighted, toggleActive };
  const getItemProps = (key: OpSummaryChartSourceKey, tag: TagDefinition) => {
    const value =
      nearest && nearest[key] !== undefined ? (nearest[key] as number).toFixed(1) : undefined;
    return {
      sourceKey: key,
      isActive: active.includes(key),
      value: nearest && nearest[key] ? `${value} ${tagsMap[key]?.unit}` : '',
      label: `${tag.label || tag.id} ${tag.unit}`,
    };
  };
  const renderLegendFor = (
    key: OpSummaryChartSourceKey,
    className: string,
    seriesType: 'line' | 'area'
  ) => {
    let adjustedKey = key;
    if (activeX && activeX > focusDate) {
      adjustedKey = (adjustedKey + 'Forecast') as OpSummaryChartSourceKey;
    }
    const tag = tagsMap[key];
    return tag ? (
      <LegendItem
        {...actions}
        {...getItemProps(adjustedKey, tag)}
        isForecast={key !== adjustedKey}
        className={className}
        seriesType={seriesType}
      />
    ) : null;
  };
  return (
    <div className={classes.legend}>
      <Grid container>
        <Grid item>
          {renderLegendFor('UtilityRollback', classes.utilityRollbackArea, 'area')}
          {renderLegendFor('UtilityConsumption', classes.utilityConsumptionArea, 'area')}
          {renderLegendFor('BaseGeneration', classes.baseGenerationArea, 'area')}
        </Grid>
        <Grid item>
          {renderLegendFor('StorageCharging', classes.storageChargingArea, 'area')}
          {renderLegendFor('StorageDischarging', classes.storageDischargingArea, 'area')}
          {renderLegendFor('StateOfCharge', classes.stateOfChargeLine, 'line')}
        </Grid>
        <Grid item>
          {renderLegendFor('PV', classes.pvArea, 'area')}
          {renderLegendFor('Load', classes.loadLine, 'line')}
        </Grid>
        <Grid item>{renderLegendFor('DemandThreshold', classes.demandLine, 'line')}</Grid>
      </Grid>
    </div>
  );
};

const RateLegend = (props: LegendProps) => {
  const { active, tagsMap, nearest, setHighlighted, toggleActive } = props;
  const classes = useStyles();

  const actions = { setHighlighted, toggleActive };
  const getItemProps = (key: OpSummaryChartSourceKey, tag: TagDefinition) => {
    return {
      sourceKey: key,
      isActive: active.includes(key),
      value:
        nearest && nearest[key]
          ? `${formatNumberSignificant(nearest[key] as number, 4)} ${tagsMap[key]?.unit}`
          : '',
      label: `${tag.label || tag.id} ${tag.unit}`,
    };
  };
  const renderLegendFor = (
    key: OpSummaryChartSourceKey,
    className: string,
    seriesType: 'line' | 'area'
  ) => {
    const tag = tagsMap[key];
    return tag ? (
      <LegendItem
        {...actions}
        {...getItemProps(key, tag)}
        className={className}
        seriesType={seriesType}
      />
    ) : null;
  };
  return (
    <div className={classes.legend}>
      <Grid container>
        <Grid item>{renderLegendFor('ConsumptionRate', classes.rateLine, 'line')}</Grid>
      </Grid>
    </div>
  );
};

const lookupNearest = (timeSeries: Dictionary<TimeSeries>, at: Date) => {
  const nearest: Dictionary<number | undefined> = {};

  const comp = (v: NumericDataPoint) => v.time;

  Object.keys(timeSeries).forEach((tagId) => {
    const series = timeSeries[tagId];
    const idx = sortedIndexBy(series.points, { time: at, value: 0, quality: '', id: '' }, comp);
    nearest[tagId] =
      series.points.length > 0 && series.points[idx] && series.points[idx].value !== null
        ? (series.points[idx].value as number)
        : undefined;
  });
  return nearest;
};

/**
 * OpSummaryChart
 * -------------------
 *
 * Displays an overview of site operations for the last 24 hour period, and
 * expected operations over the next 8 hour period.
 */
export const OpSummaryChart: React.FC<OpSummaryChartProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallPortrait = useMediaQuery(
    `${theme.breakpoints.down('xs')} and (orientation: portrait)`
  );
  const isSmallLandscape = useMediaQuery(
    `${theme.breakpoints.down('sm')} and (orientation: landscape)`
  );
  const isSmallScreen = isSmallPortrait || isSmallLandscape;
  const { snapshots, focusDate, tagsMap, hideRates, toggleRateSwitcherDisplay, ismVoult } = props;
  const hasForecastTags = some(ForecastSources.map((key) => tagsMap[key]));
  const xRange = {
    start: new Date(focusDate.getTime() - 1000 * 60 * 60 * 24),
    end: hasForecastTags ? new Date(focusDate.getTime() + 1000 * 60 * 60 * 8) : focusDate,
  };

  const quickZoomRanges = [
    {
      label: '1 hour',
      range: {
        start: new Date(focusDate.getTime() - 1000 * 60 * 60),
        end: focusDate,
      },
    },
    {
      label: '3 hours',
      range: {
        start: new Date(focusDate.getTime() - 1000 * 60 * 60 * 3),
        end: focusDate,
      },
    },
    {
      label: '6 hours',
      range: {
        start: new Date(focusDate.getTime() - 1000 * 60 * 60 * 6),
        end: focusDate,
      },
    },
    {
      label: '12 hours',
      range: {
        start: new Date(focusDate.getTime() - 1000 * 60 * 60 * 12),
        end: focusDate,
      },
    },
    {
      label: 'Max',
      range: undefined,
    },
  ];

  if (hasForecastTags) {
    quickZoomRanges.push({
      label: 'Next 8 hours',
      range: {
        start: focusDate,
        end: new Date(focusDate.getTime() + 1000 * 60 * 60 * 8),
      },
    });
  }

  const [activeXValue, setActiveXValue] = useState<Date | undefined>(undefined);
  const [zoomRange, setZoomRange] = useState<TimeRange | undefined>(undefined);

  const getTagIdFromKey = (key: OpSummaryChartSourceKey) => tagsMap[key];

  const getDataFor = (key: OpSummaryChartSourceKey): SeriesValue[] => {
    const tagId = tagsMap[key];
    const tagData = tagId && snapshots[tagId];
    if (!tagData) {
      return [];
    }
    const validPoints = tagData.points.filter((p) => p.value !== null);
    return validPoints.map((p) => toSeriesValue(p)) as SeriesValue[];
  };

  // if any tags are available for any of the defined forecast keys
  // show the forecast area.

  const activeSeries = Object.keys(tagsMap) as OpSummaryChartSourceKey[];

  const [active, setActive] = useState<OpSummaryChartSourceKey[]>(activeSeries);

  const toggleActive = (key: OpSummaryChartSourceKey | undefined) => {
    if (key !== undefined) {
      if (active.includes(key)) {
        setActive((prev) => prev.filter((el) => el !== key && el !== `${key}Forecast`));
      } else {
        if (activeSeries.includes(`${key}Forecast` as OpSummaryChartSourceKey)) {
          setActive((prev) => [...prev, key, `${key}Forecast` as OpSummaryChartSourceKey]);
        } else {
          setActive((prev) => [...prev, key]);
        }
      }
    }
  };

  interface ChartData {
    key: string;
    values: SeriesValue[];
    styleClass: string;
  }

  const filterActive = (chartData: ChartData[]) =>
    chartData.filter((item) => active.includes(item.key as OpSummaryChartSourceKey));

  const negativeAreaData = [
    {
      key: 'UtilityRollback',
      values: getDataFor('UtilityRollback'),
      styleClass: classes.utilityRollbackArea,
    },
    {
      key: 'UtilityRollbackForecast',
      values: getDataFor('UtilityRollbackForecast'),
      styleClass: classes.utilityRollbackArea,
    },
  ];

  const positiveAreaData = [
    {
      key: 'UtilityConsumption',
      values: getDataFor('UtilityConsumption'),
      styleClass: classes.utilityConsumptionArea,
    },
    {
      key: 'UtilityConsumptionForecast',
      values: getDataFor('UtilityConsumptionForecast'),
      styleClass: classes.utilityConsumptionArea,
    },
    {
      key: 'BaseGeneration',
      values: getDataFor('BaseGeneration'),
      styleClass: classes.baseGenerationArea,
    },
    {
      key: 'BaseGenerationForecast',
      values: getDataFor('BaseGenerationForecast'),
      styleClass: classes.baseGenerationArea,
    },
    {
      key: 'StorageDischarging',
      values: getDataFor('StorageDischarging'),
      styleClass: classes.storageDischargingArea,
    },
    {
      key: 'StorageDischargingForecast',
      values: getDataFor('StorageDischargingForecast'),
      styleClass: classes.storageDischargingArea,
    },

    {
      key: 'PV',
      values: getDataFor('PV'),
      styleClass: classes.pvArea,
    },
    {
      key: 'PVForecast',
      values: getDataFor('PVForecast'),
      styleClass: classes.pvArea,
    },
  ];

  const loadAreaData = [
    {
      key: 'Load',
      values: getDataFor('Load'),
      styleClass: classes.hidden,
    },
    {
      key: 'LoadForecast',
      values: getDataFor('LoadForecast'),
      styleClass: classes.hidden,
    },
    {
      key: 'StorageCharging',
      values: getDataFor('StorageCharging'),
      styleClass: classes.storageChargingArea,
    },
    {
      key: 'StorageChargingForecast',
      values: getDataFor('StorageChargingForecast'),
      styleClass: classes.storageChargingArea,
    },
  ];

  const lineData = [
    {
      key: 'Load',
      values: getDataFor('Load'),
      styleClass: classes.loadLine,
    },
    {
      key: 'LoadForecast',
      values: getDataFor('LoadForecast'),
      styleClass: classes.loadLine,
    },
    {
      key: 'DemandThreshold',
      values: getDataFor('DemandThreshold'),
      styleClass: classes.demandLine,
    },
    {
      key: 'DemandThresholdForecast',
      values: getDataFor('DemandThresholdForecast'),
      styleClass: classes.demandLine,
    },
  ];

  const altLineData = [
    {
      key: 'StateOfCharge',
      values: getDataFor('StateOfCharge'),
      styleClass: classes.stateOfChargeLine,
    },
    {
      key: 'StateOfChargeForecast',
      values: getDataFor('StateOfChargeForecast'),
      styleClass: classes.stateOfChargeLine,
    },
  ];

  const rateData = [
    {
      key: 'ConsumptionRate',
      values: getDataFor('ConsumptionRate'),
      styleClass: classes.rateLine,
    },
  ];

  let rateRange = {
    yMin: 0,
    yMax: 0,
    ...getSeriesRange(rateData[0].values),
    xMin: xRange.start,
    xMax: xRange.end,
  };

  const demandRange = getSeriesRange(lineData[2].values);
  const demandRangeAlt = {
    minY: 0,
    maxY: 0,
    ...(demandRange && {
      minY: demandRange.yMin,
      maxY: demandRange.yMax,
    }),
    minX: 0,
    maxX: 0,
  };

  const ratePadding = (rateRange.yMax - rateRange.yMin || 1) * 0.05;
  rateRange = {
    ...rateRange,
    yMin: rateRange.yMin - ratePadding,
    yMax: rateRange.yMax + ratePadding,
  };

  const [negativeAreaStackedData, negativeAreaRange] = useMemo(
    () => stackAreaSeries(filterActive(negativeAreaData) || []),
    // todo: fix dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.snapshots, active]
  );
  const [positiveAreaStackedData, positiveAreaRange] = useMemo(
    () => stackAreaSeries(filterActive(positiveAreaData) || []),
    // todo: fix dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.snapshots, active]
  );
  const [loadAreaStackedData, loadAreaRange] = useMemo(
    () => stackAreaSeries(filterActive(loadAreaData) || []),
    // todo: fix dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.snapshots, active]
  );

  const handleMouseMove = useCallback((pos: { x: Date; y: number }) => setActiveXValue(pos.x), []);
  const handleMouseLeave = useCallback(() => setActiveXValue(undefined), []);

  const getRange = () => {
    const xRangeMin = xRange.start;
    const xRangeMax = xRange.end;

    let { minY, maxY } = maxRange([
      negativeAreaRange,
      positiveAreaRange,
      loadAreaRange,
      demandRangeAlt,
    ]);

    const padding = (maxY - minY || 1) * 0.05;
    minY = minY - padding;
    maxY = maxY + padding;
    return {
      xMin: xRangeMin,
      xMax: xRangeMax,
      yMin: minY,
      yMax: maxY,
    };
  };

  const tagLookup = mapValues(tagsMap, (tagId) => {
    const tagData = tagId ? snapshots[tagId] : undefined;
    return tagData && tagData.tag;
  });

  const dataUnit =
    compact(positiveAreaData.map((d) => d.key).map((key) => tagLookup[key]?.unit))?.[0] ||
    DEFAULT_UNIT;
  const rateUnit =
    compact(rateData.map((d) => d.key).map((key) => tagLookup[key]?.unit))?.[0] ||
    DEFAULT_RATE_UNIT;

  const range = getRange();

  const nearestByTag = activeXValue ? lookupNearest(snapshots, activeXValue) : undefined;
  const nearest = activeXValue
    ? mapValues(tagsMap, (tagId: string) => {
        return tagId && nearestByTag ? nearestByTag[tagId] : undefined;
      })
    : undefined;

  const [showDataSources, setShowDataSources] = useState<boolean>(false);
  const toggleDataSourcesDisplay = (): boolean => {
    setShowDataSources(!showDataSources);
    return showDataSources;
  };

  const showSwitchRateLink = ismVoult && !hideRates;

  return (
    <div style={{ width: '100%', boxSizing: 'border-box' }}>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Legend
            // @ts-ignore todo: fix type
            nearest={nearest}
            tagsMap={tagLookup}
            activeX={activeXValue}
            active={active}
            focusDate={focusDate}
            toggleActive={toggleActive}
          />
        </Grid>
        <Grid item container spacing={2} xs={12} sm={4} justifyContent="flex-end">
          {quickZoomRanges.map((zoom) => (
            <Grid item key={zoom.label}>
              <Link style={{ cursor: 'pointer' }} onClick={() => setZoomRange(zoom.range)}>
                {zoom.label}
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <div style={{ margin: '16px 0' }}>
        <ChartPane
          handleMouseMove={handleMouseMove}
          handleMouseLeave={handleMouseLeave}
          height={props.height || 240}
          range={{
            ...range,
            xMin: zoomRange?.start || range.xMin,
            xMax: zoomRange?.end || range.xMax,
          }}
          altRanges={[
            {
              xMin: zoomRange?.start || range.xMin,
              xMax: zoomRange?.end || range.xMax,
              yMin: -5,
              yMax: 105,
            },
          ]}
          margins={margins}
        >
          <chartContext.Consumer>
            {(context: ChartContextData) => (
              <>
                {hasForecastTags && (
                  <>
                    <line
                      x1={context.xScale(focusDate)}
                      x2={context.xScale(focusDate)}
                      y1={context.margins.top || 0}
                      y2={context.size.height}
                      className={classes.forecastBreak}
                    />

                    <rect
                      x={context.xScale(focusDate)}
                      y={context.margins.top}
                      width={
                        context.size.width -
                        (context.margins.right || 0) -
                        (context.xScale(focusDate) ?? 0)
                      }
                      height={context.size.height}
                      fill={'#F2FAFF'}
                    />
                    <text
                      fill={'#2A7AD7'}
                      x={Math.max(4, (context.xScale(focusDate) ?? 0) + 4)}
                      y={(context.margins.top || 0) + 16}
                    >
                      Forecast
                    </text>
                  </>
                )}
                <HorizontalGrid
                  size={context.size}
                  yScale={context.yScale}
                  marginLeft={context.margins.left}
                  marginRight={context.margins.right}
                  tickCount={5}
                />

                <StackedAreaSeries {...context} data={negativeAreaStackedData} />
                <StackedAreaSeries {...context} data={positiveAreaStackedData} />
                <StackedAreaSeries {...context} data={loadAreaStackedData} />
                <LineSeries {...context} data={filterActive(lineData)} />
                <LineSeries
                  {...context}
                  yScale={context.altYScales[0]}
                  data={filterActive(altLineData)}
                />

                <XAxis {...context} inset={true} offset={-1} />

                <YAxis
                  {...context}
                  unit={dataUnit}
                  inset={true}
                  offset={context.margins.left}
                  format={yAxisFormatter}
                />

                <YAxis
                  {...context}
                  unit="%"
                  inset={false}
                  yScale={context.altYScales[0]}
                  offset={context.size.width - 1}
                  format={yAxisFormatter}
                  tickCount={3}
                />

                {activeXValue ? (
                  <Crosshairs
                    size={context.size}
                    targetPosition={{ x: context.xScale(activeXValue) ?? 0, y: context.mouseY }}
                    margins={{ ...context.margins, bottom: 0 }}
                    bottomValue={hideRates ? xAxisFormatter(activeXValue) : undefined}
                    leftValue={
                      yLabelFormatter && yLabelFormatter(context.yScale.invert(context.mouseY))
                    }
                  />
                ) : null}
                {!isSmallScreen ? (
                  <ZoomBar
                    size={context.size}
                    xScale={(() => {
                      const scale = zoomRange
                        ? d3
                            .scaleTime()
                            .domain([zoomRange.start, zoomRange.end])
                            .range([
                              0 + (context.margins.left || 0),
                              context.size.width - (context.margins.right || 0),
                            ])
                        : context.xScale;
                      return scale;
                    })()}
                    onZoomEnd={(newZoomRange) => setZoomRange(newZoomRange)}
                  />
                ) : null}
              </>
            )}
          </chartContext.Consumer>
        </ChartPane>

        {!hideRates && (
          <>
            <div style={{ marginTop: 8 }}>
              <RateLegend
                // @ts-ignore todo: fix type
                nearest={nearest}
                tagsMap={tagLookup}
                active={active}
                activeX={activeXValue}
                focusDate={focusDate}
                toggleActive={toggleActive}
              />
            </div>

            <ChartPane
              handleMouseMove={handleMouseMove}
              handleMouseLeave={handleMouseLeave}
              height={80}
              range={{
                ...rateRange,
                xMin: zoomRange?.start || rateRange.xMin,
                xMax: zoomRange?.end || rateRange.xMax,
              }}
              margins={{
                top: 16,
                bottom: 8,
                left: 0,
                right: 0,
              }}
            >
              <chartContext.Consumer>
                {(context: ChartContextData) => (
                  <>
                    <LineSeries {...context} data={filterActive(rateData)} />
                    <YAxis
                      {...context}
                      unit={rateUnit}
                      inset={true}
                      offset={context.margins.left}
                      format={yAxisFormatter || ((d) => `${d}`)}
                    />
                    <XAxis {...context} inset={true} offset={-1} />
                    {activeXValue ? (
                      <Crosshairs
                        size={context.size}
                        targetPosition={{ x: context.xScale(activeXValue) ?? 0, y: context.mouseY }}
                        margins={{ ...context.margins, bottom: 0 }}
                        bottomValue={xAxisFormatter && xAxisFormatter(activeXValue)}
                        leftValue={
                          yLabelFormatter && yLabelFormatter(context.yScale.invert(context.mouseY))
                        }
                      />
                    ) : null}
                  </>
                )}
              </chartContext.Consumer>
            </ChartPane>
          </>
        )}

        <ChartPane
          height={30}
          range={range}
          style={{ backgroundColor: '#f0f0f0' }}
          margins={{
            top: 8,
            bottom: 8,
            left: 0,
            right: 0,
          }}
        >
          <chartContext.Consumer>
            {(context: ChartContextData) => (
              <>
                <XAxis {...context} inset={true} offset={-1} />
                <ZoomBar
                  {...context}
                  zoomRange={zoomRange}
                  onZoom={(newZoomRange) => setZoomRange(newZoomRange)}
                />
              </>
            )}
          </chartContext.Consumer>
        </ChartPane>

        <div style={{ height: 16, display: 'flex', fontSize: 12, marginTop: 4 }}>
          <Link
            style={{ cursor: 'pointer', paddingRight: 8 }}
            onClick={() => toggleDataSourcesDisplay()}
          >
            Data Sources
          </Link>
          {showSwitchRateLink && (
            <Link
              style={{ cursor: 'pointer', padding: '0 8px' }}
              onClick={() => toggleRateSwitcherDisplay()}
            >
              Switch Rate
            </Link>
          )}

          {zoomRange && (
            <Link
              onClick={() => setZoomRange(undefined)}
              style={{ cursor: 'pointer', marginLeft: 'auto' }}
            >
              Clear Zoom
            </Link>
          )}
        </div>
      </div>

      <DataSources
        isDisplayedInitialValue={showDataSources}
        toggleDisplay={toggleDataSourcesDisplay}
        tagsMap={props.tagsMap}
        snapshots={props.snapshots}
        getTagIdFromKey={getTagIdFromKey}
      />
    </div>
  );
};
