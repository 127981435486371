import React from 'react';
import { Typography, Grid } from '@material-ui/core/';

export interface SuccessMessageProps {
  message: string;
  title?: string;
  size?: 'sm' | 'lg';
}

export const SuccessMessage: React.FC<SuccessMessageProps> = (props) => {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1}>
      <Grid item>
        <Typography variant="h6">Success</Typography>
      </Grid>
      <Grid item>
        <Typography>{props.message}</Typography>
      </Grid>
    </Grid>
  );
};
