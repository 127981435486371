import { createTheme } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSSProperties } from '@material-ui/styles';
import { featureFlags, Product } from '../config/featureFlags';
import pageBackground from '../assets/bg.jpg';

declare module '@material-ui/core/styles/createMixins' {
  interface Mixins {
    pageBackground: CSSProperties;
  }
  interface MixinsOptions {
    pageBackground: CSSProperties;
  }
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    sidebar: {
      main: CSSProperties['color'];
      highlight: CSSProperties['color'];
      active: CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    sidebar: {
      main: CSSProperties['color'];
      highlight: CSSProperties['color'];
      active: CSSProperties['color'];
    };
  }
}

export interface ProductTheme {
  primaryMain: Required<CSSProperties>['color'];
  sidebarMain: Required<CSSProperties>['color'];
  sidebarHighlight: Required<CSSProperties>['color'];
  sidebarActive: Required<CSSProperties>['color'];
}

const PRODUCT_THEMES: Record<Product, ProductTheme> = {
  mPulse: {
    primaryMain: '#479AFA',
    sidebarMain: '#04072d',
    sidebarHighlight: '#52C6F3',
    sidebarActive: '#0D224B',
  },
  mVoult: {
    primaryMain: '#0CAFED',
    sidebarMain: '#003473',
    sidebarHighlight: '#4AD2FF',
    sidebarActive: '#19559e',
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      main: PRODUCT_THEMES[featureFlags.product].primaryMain,
      contrastText: '#FFFFFF',
    },
  },
  sidebar: {
    main: PRODUCT_THEMES[featureFlags.product].sidebarMain,
    highlight: PRODUCT_THEMES[featureFlags.product].sidebarHighlight,
    active: PRODUCT_THEMES[featureFlags.product].sidebarActive,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 300,
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  mixins: {
    pageBackground: {
      backgroundImage: `url(${pageBackground})`,
      backgroundSize: 'cover',
      boxShadow: 'inset 0 30px 24px 24px #fff',
      position: 'absolute',
      overflow: 'auto',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
});
