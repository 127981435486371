import React from 'react';
import { useMemo, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Theme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { isDisplayTag } from '../../../models';
import { EquipmentTabProps } from './EquipmentPage.d';
import EquipmentTagListControls, {
  INITIAL_TAG_LIST_STATE,
  tagListReducer,
} from './EquipmentTagListControls';
import EquipmentTagRow from './EquipmentTagRow';
import identifiers from '../../../tests/identifiers';

const EquipmentTagList = (props: EquipmentTabProps) => {
  const { equipment, pinnedTagIds = [] } = props;
  const classes = useStyles();

  const [tagListState, tagListDispatch] = useReducer(tagListReducer, INITIAL_TAG_LIST_STATE);
  const { search, units } = tagListState;

  const tags = useMemo(() => equipment.tags.filter(isDisplayTag), [equipment]);

  const pinnedTags = useMemo(
    () => tags.filter((tag) => pinnedTagIds.includes(tag.id)),
    [pinnedTagIds, tags]
  );

  return (
    <>
      {pinnedTags.length > 0 && (
        <AppBar className={classes.pinnedTags} position="sticky" elevation={1}>
          <TableContainer
            className={classes.pinnedTagsTableContainer}
            component={Paper}
            elevation={0}
            square
          >
            <Table className={classes.table} size="small">
              <TableHead>
                {pinnedTags.map((tag, index) => (
                  <EquipmentTagRow key={index} tag={tag} pinned {...props} />
                ))}
              </TableHead>
            </Table>
          </TableContainer>
        </AppBar>
      )}

      {tags.length > 0 && (
        <EquipmentTagListControls
          equipment={equipment}
          tagListDispatch={tagListDispatch}
          tagListState={tagListState}
        />
      )}

      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.table} size="small">
          <TableBody data-testid={identifiers.equipmentPage.tagList}>
            {tags.map((tag, index) => (
              <EquipmentTagRow
                key={index}
                tag={tag}
                visible={(() => {
                  // Exclude pinned tags
                  if (pinnedTagIds.includes(tag.id)) {
                    return false;
                  }

                  // Exclude by filtered units
                  if (units.length > 0 && !units.includes(tag.unit)) {
                    return false;
                  }

                  // Exclude by search term
                  if (search === '') {
                    return true;
                  }
                  return tag.label.toLowerCase().indexOf(search.toLowerCase()) !== -1;
                })()}
                {...props}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {tags.length === 0 && <Alert severity="info">No tags available for this equipment</Alert>}
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  pinnedTags: {
    marginBottom: theme.spacing(3),
  },
  pinnedTagsTableContainer: {
    background: theme.palette.grey[100],
  },
  table: {
    overflow: 'hidden',
    width: '100%',
  },
}));

export default React.memo(EquipmentTagList);
