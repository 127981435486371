import React from 'react';
import {
  Grid,
  Button,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
  Link,
} from '@material-ui/core';
import { Dictionary, uniq, compact } from 'lodash';
import { TimeSeries, Site, isChartableTag } from '../../../models';
import { AnalysisChart } from './AnalysisChart';
import { TagTree } from '../site/TagTree';
import { useState } from 'react';
import { DateRange } from '../../ui/controls/DateRange';
import { PrimaryButton } from '../../ui/Buttons';
import { useEffect } from 'react';
import { ErrorMessage } from '../../ui/ErrorMessage';
import testIdentifiers from '../../../tests/identifiers';

export interface Props {
  site: Site;
  start?: Date;
  end?: Date;
  tagIds: string[];
  toggleTagId: (tagId: string) => void;
  clearAllTags: () => void;
  setStartDate: (start: Date) => void;
  setEndDate: (end: Date) => void;
  reset: () => void;
  snapshots: Dictionary<TimeSeries>;
  loading: boolean;
  error?: string;
}

const DRAWER_WIDTH = 400;

const useStyles = makeStyles((theme: Theme) => ({
  tagToggle: {
    position: 'absolute',
    top: '19px',
    left: '-52px',
    transform: 'rotate(-90deg)',
    border: '1px solid #e0e0e0',
    backgroundColor: '#fff',
  },
  drawer: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: 'absolute',
    flexDirection: 'column',
    borderLeft: '1px solid #e0e0e0',
    backgroundColor: '#f8f8f8',
    top: 0,
    bottom: 0,
    right: 0,
  },
  pageContent: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflowY: 'auto',
    flexWrap: 'nowrap',
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const MAX_TAGS = 8;

export const AnalysisPage: React.FC<Props> = (props) => {
  const {
    site,
    start,
    end,
    snapshots,
    toggleTagId,
    clearAllTags,
    tagIds,
    setStartDate,
    setEndDate,
    reset,
    error,
  } = props;

  const [tagDrawerOpen, setTagDrawerOpen] = useState<boolean>(true);

  const classes = useStyles();

  const [newStart, setNewStart] = useState<Date | undefined>(start);
  const [newEnd, setNewEnd] = useState<Date | undefined>(end);

  useEffect(() => {
    setNewStart(start);
  }, [start]);

  useEffect(() => {
    setNewEnd(end);
  }, [end]);

  const handleUpdateDates = () => {
    if (newStart) {
      setStartDate(newStart);
    }
    if (newEnd) {
      setEndDate(newEnd);
    }
  };

  const updateDisabled = !newStart || !newEnd || (newStart === start && newEnd === end);

  const dateInputsDisabled = props.loading;

  const units = uniq(compact(tagIds.map((tagId) => snapshots[tagId]?.unit)));
  const maxUnits = 6;

  return (
    <>
      <Grid
        data-testid={testIdentifiers.analysisPage.page}
        container
        className={classes.pageContent}
        style={{ paddingRight: tagDrawerOpen && !error ? DRAWER_WIDTH : 0 }}
      >
        <Grid item container style={{ flex: '1', flexDirection: 'column', flexWrap: 'nowrap' }}>
          {error && (
            <Grid item container>
              <Grid item style={{ padding: 36 }}>
                <ErrorMessage message={error} />
                <Button
                  data-testid={testIdentifiers.analysisPage.startOverButton}
                  style={{ marginTop: 16 }}
                  variant="contained"
                  onClick={() => reset()}
                >
                  Start Over
                </Button>
              </Grid>
            </Grid>
          )}

          {!error && (
            <>
              <Grid
                item
                container
                style={{ padding: 16, paddingRight: 48, margin: 0, width: 'auto' }}
                spacing={1}
              >
                <Grid item>
                  <DateRange
                    disabled={dateInputsDisabled}
                    maxRange={7}
                    filterDates={(date) => date.getTime() < Date.now()}
                    start={newStart}
                    end={newEnd}
                    onStartChange={setNewStart}
                    onEndChange={setNewEnd}
                  />
                </Grid>
                <Grid item container alignItems="center" style={{ width: 'auto' }}>
                  <Grid item>
                    <PrimaryButton
                      style={{ paddingTop: 7, paddingBottom: 7 }}
                      disabled={updateDisabled || tagIds.length === 0 || props.loading}
                      onClick={() => handleUpdateDates()}
                    >
                      Update
                    </PrimaryButton>
                  </Grid>
                  {props.loading && (
                    <Grid item>
                      <CircularProgress size={16} style={{ margin: 8 }} />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item container style={{ height: 480 }}>
                <Grid item style={{ flex: 1, position: 'relative' }}>
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: 16,
                    }}
                  >
                    {start && end && tagIds.length > 0 ? (
                      <AnalysisChart
                        height={400}
                        tagIds={tagIds}
                        xRange={{ start, end }}
                        snapshots={snapshots}
                      />
                    ) : (
                      <Typography>
                        Select one or more{' '}
                        {tagDrawerOpen ? (
                          'tags'
                        ) : (
                          <Link onClick={() => setTagDrawerOpen(true)}>tags</Link>
                        )}{' '}
                        to display.
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {!error && (
        <div
          className={classes.drawer}
          style={{
            width: tagDrawerOpen ? DRAWER_WIDTH : 0,
          }}
        >
          <div className={classes.tagToggle}>
            <Button onClick={() => setTagDrawerOpen(!tagDrawerOpen)}>Tags</Button>
          </div>
          <div
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              overflow: 'hidden',
            }}
          >
            <Grid item container style={{ width: DRAWER_WIDTH, height: '100%' }}>
              <Grid item style={{ flex: 1, position: 'relative', width: DRAWER_WIDTH - 380 }}>
                <TagTree
                  height={'100%'}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 36,
                    borderRadius: 4,
                  }}
                  tagFilter={isChartableTag}
                  clearAll={() => clearAllTags()}
                  site={site}
                  selectedTags={tagIds}
                  onTagSelect={toggleTagId}
                  disabled={(tag) =>
                    (!units.includes(tag.unit) && units.length >= maxUnits) ||
                    tagIds.length >= MAX_TAGS
                  }
                />
                <Typography
                  variant="caption"
                  style={{ padding: '0 4px', color: '#606060', position: 'absolute', bottom: 2 }}
                >
                  * A maximum of {maxUnits} different units of measure may be visualized, or{' '}
                  {MAX_TAGS} tags total.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};
