// NOTE(Diego): https://stackoverflow.com/questions/24007073/open-links-made-by-createobjecturl-in-ie11
// HACK(Diego): Apparently, there is no way for the browser to prompt a file download with content disposition
// source: https://medium.com/@drevets/you-cant-prompt-a-file-download-with-the-content-disposition-header-using-axios-xhr-sorry-56577aa706d6

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blob: unknown, defaultName?: string | undefined) => boolean;
  }
}

export function downloadFromBlob(filename: string, content: Blob) {
  const blob = new Blob([content]);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // for IE
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // for Non-IE (chrome, firefox etc.)
    const a = document.createElement('a');
    document.body.appendChild(a as unknown as Node);
    a.style.display = 'none';
    const blobUrl = URL.createObjectURL(blob);
    a.href = blobUrl;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }
}

export function downloadFromUrl(filename: string, url: string) {
  const a = document.createElement('a');
  document.body.appendChild(a as unknown as Node);
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  a.target = '_blank';
  a.click();
  a.remove();
}
