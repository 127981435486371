export class SDate extends Date {
  constructor(...args: ConstructorParameters<typeof Date>) {
    if (!args) {
      super(SDate.currentDate);
    } else {
      super(...args);
    }
  }

  private static currentDate: Date = new Date();

  private static realDate: Date = new Date();

  static now() {
    const elapsed = Date.now() - SDate.realDate.getTime();
    return SDate.currentDate.getTime() + elapsed;
  }

  static setCurrentDate(date: Date) {
    SDate.currentDate = date;
    SDate.realDate = new Date();
  }
}
