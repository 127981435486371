import { AppInsights } from 'applicationinsights-js';
import { History } from 'history';
import { v1 } from 'uuid';
import { applicationInsightsInstrumentationKey } from '../config';
import logger from './logger';
import { featureFlags } from '../config/featureFlags';
import { Dictionary } from 'lodash';

const instanceId = v1();

export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}

type AnalyticsHelper = {
  setup(): void;
  trackPageViews(history: History): void;
  trackException(exception: Error, properties?: Dictionary<string>): void;
  trackTrace(message: string, properties?: Dictionary<string>, severityLevel?: SeverityLevel): void;
  setAnalyticsUserId(userId: number): void;
  clearAnalyticsUserId(): void;
};

class AppInsightsAnalyticsHelper {
  setup(): void {
    if (AppInsights.downloadAndSetup) {
      const config: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: applicationInsightsInstrumentationKey,
      };

      // Call downloadAndSetup to download full ApplicationInsights script from CDN and initialize it with instrumentation key
      AppInsights.downloadAndSetup(config);

      // add document referrer to all calls
      appInsights.queue.push(() => {
        appInsights.context.addTelemetryInitializer(
          (envelope: Microsoft.ApplicationInsights.IEnvelope) => {
            var telemetryItem = envelope.data.baseData;
            telemetryItem.properties = telemetryItem.properties || {};
            telemetryItem.properties.urlReferrer = document.referrer;
            telemetryItem.properties.instanceId = instanceId;
          }
        );
      });
    } else {
      logger.error('AppInsights download failed');
    }
  }

  trackPageViews(history: History): void {
    history.listen(() => {
      AppInsights.trackPageView();
    });
  }

  trackException(exception: Error, properties?: Dictionary<string>): void {
    AppInsights.trackException(exception, undefined, properties);
  }

  trackTrace(
    message: string,
    properties?: Dictionary<string>,
    severityLevel: SeverityLevel = SeverityLevel.Information
  ): void {
    AppInsights.trackTrace(message, properties, severityLevel);
  }

  setAnalyticsUserId(userId: number): void {
    AppInsights.setAuthenticatedUserContext(userId.toString());
  }

  clearAnalyticsUserId(): void {
    AppInsights.clearAuthenticatedUserContext();
  }
}

class NullAnalyticsHelper {
  setup(): void {}

  trackPageViews(): void {}

  trackException(): void {}

  trackTrace(): void {}

  setAnalyticsUserId(): void {}

  clearAnalyticsUserId(): void {}
}

function getHelper(): AnalyticsHelper {
  const helper = featureFlags.enableAnalytics
    ? new AppInsightsAnalyticsHelper()
    : new NullAnalyticsHelper();
  helper.setup();
  return helper;
}

export default getHelper();
