import { createAction } from 'typesafe-actions';

export const enterAuthContext = createAction('auth/enter', (resolve) => () => resolve());

/* login */

export const publishToken = createAction(
  'auth/publishToken',
  (resolve) => (token: string) =>
    resolve({
      token,
    })
);

export const pushFeedConnect = createAction('pushFeed/connect', (resolve) => {
  return () => resolve();
});

export const pushFeedConnectSuccess = createAction('pushFeed/connectSuccess', (resolve) => {
  return (connectionId: string) => resolve({ connectionId });
});

export const pushFeedConnectError = createAction('pushFeed/connectError', (resolve) => {
  return (error?: string) => resolve({ error });
});

export const pushFeedDisconnect = createAction('pushFeed/disconnect', (resolve) => {
  return () => resolve();
});
