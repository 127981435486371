import { EntitiesStateSlice } from '../';
import { denormTag } from '../tag/selectors';
import { Equipment } from '../../../models';
import { NormalizationError } from '../../../helpers/norm';
import { memoizeSelector } from '../../../helpers/selectors';

export const denormEquipment = (
  state: EntitiesStateSlice,
  siteId: number,
  equipmentId: number
): Equipment => {
  const equipmentNorm = state.entities.equipment.bySiteId?.[siteId]?.[equipmentId];
  if (!equipmentNorm) {
    throw new NormalizationError(`Unable to denormalize equipment: ${equipmentId}`, {
      siteId,
      equipmentId,
    });
  }
  const { tagIds, ...equipment } = equipmentNorm;
  return {
    ...equipment,
    tags: tagIds.map((tagId) => denormTag(state, tagId)),
  };
};

export const findEquipmentById = memoizeSelector(
  (state: EntitiesStateSlice, siteId: number, equipmentId: number): Equipment | undefined => {
    const equipmentNorm = state.entities.equipment.bySiteId?.[siteId]?.[equipmentId];
    return equipmentNorm ? denormEquipment(state, siteId, equipmentId) : undefined;
  },
  [(state: EntitiesStateSlice) => state.entities.equipment.bySiteId]
);

export const findEquipmentBySite = memoizeSelector(
  (state: EntitiesStateSlice, siteId: number): Equipment[] => {
    const normEquipmentIds = Object.keys(state.entities.equipment.bySiteId[siteId] || {});
    return normEquipmentIds.map((equipmentId) => denormEquipment(state, siteId, +equipmentId));
  },
  [(state: EntitiesStateSlice) => state.entities.equipment.bySiteId]
);
