import { getType, ActionType } from 'typesafe-actions';
import { produce } from 'immer';
import { Action } from 'redux';
import * as alarmActions from '../entities/alarm/actions';
import * as alertActions from '../entities/alert/actions';
import * as controlDatasetActions from '../entities/controlDataset/actions';
import * as customerActions from '../entities/customer/actions';
import * as installerActions from '../entities/installer/actions';
import * as notificationActions from '../entities/notification/actions';
import * as profileActions from '../entities/profile/actions';
import * as reportActions from '../entities/report/actions';
import * as siteActions from '../entities/site/actions';
import * as snapshotActions from '../entities/snapshot/actions';
import * as userActions from '../entities/user/actions';
import { queryPortfolio, fetchPortfolioOptions } from '../contexts/portfolioPage';
import { gridServicesOptOut } from '../contexts/optOutPage';
import { utilityRateSwitchSchedule } from '../contexts/utilityRate';
import { ApiError } from '../../api';
import { clearRequest } from './actions';

export enum Lifecycle {
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export const statusToError = (status?: SimpleRequestStatus): ApiError | undefined =>
  status && status instanceof ApiError ? status : undefined;

export type SimpleRequestStatus = undefined | Lifecycle | ApiError;

/**
 * When adding new requests add each corresponding action triad to this array.
 */
const allRequestActions = [
  // alarm
  alarmActions.fetchAlarmSummaries,
  alarmActions.queryAlarmSummaries,
  alarmActions.fetchAlarmDetails,
  alarmActions.addNote,
  alarmActions.acknowledgeAlarm,
  alarmActions.resolveAlarm,
  // alert
  alertActions.fetchSiteAlerts,
  alertActions.updateAlert,
  // controlDataset
  controlDatasetActions.fetchControlDataset,
  // customer
  customerActions.fetchCustomer,
  // installer
  installerActions.fetchSiteTemplates,
  installerActions.fetchUtilities,
  installerActions.provisionSite,
  // notification
  notificationActions.fetchNotifications,
  // profile
  profileActions.fetchUserProfile,
  // portfolio
  queryPortfolio,
  fetchPortfolioOptions,
  // report
  reportActions.fetchReportConfigurations,
  reportActions.exportReport,
  reportActions.generateReport,
  // site
  siteActions.fetchSiteData,
  siteActions.requestSiteDocument,
  // snapshot
  snapshotActions.fetchSnapshots,
  snapshotActions.fetchTopNSnapshots,
  // user
  userActions.createUser,
  userActions.deleteUser,
  userActions.updateUser,
  userActions.removeFromSite,
  userActions.resendInvite,
  userActions.activateAccount,
  // opt out page
  gridServicesOptOut,
  // utility rate change
  utilityRateSwitchSchedule,
] as const; // This allows typescript to infer the types at each index

export type RequestActionCreator = typeof allRequestActions[number]['request'];
export type RequestAction = ActionType<typeof allRequestActions[number]['request']>;
export type SuccessAction = ActionType<typeof allRequestActions[number]['success']>;
export type ErrorAction = ActionType<typeof allRequestActions[number]['error']>;

export type RequestActionType = RequestAction['type'];
export type SuccessActionType = SuccessAction['type'];
export type ErrorActionType = ErrorAction['type'];

const requestLookup = new Set<RequestActionType>();
const successLookup = new Set<SuccessActionType>();
const errorLookup = new Set<ErrorActionType>();

allRequestActions.forEach((actionTriad) => {
  requestLookup.add(getType(actionTriad.request));
  successLookup.add(getType(actionTriad.success));
  errorLookup.add(getType(actionTriad.error));
});

function isRequestAction(action: Action<unknown>): action is RequestAction {
  return requestLookup.has(action.type as RequestActionType);
}

function isSuccessAction(action: Action<unknown>): action is SuccessAction {
  return successLookup.has(action.type as SuccessActionType);
}

function isErrorAction(action: Action<unknown>): action is ErrorAction {
  return errorLookup.has(action.type as ErrorActionType);
}

export interface RequestStatus<T extends RequestAction> {
  trigger: T;
  status: SimpleRequestStatus;
  cleared: boolean;
}
export interface RequestStatusState {
  byPayload: {
    // action type
    [Key: string]: {
      // serilaizedPayload
      [Key: string]: string; // action: uuid
    };
  };
  byPending: {
    // action type
    [Key: string]: {
      // uid
      [Key: string]: boolean;
    };
  };
  byError: {
    // action type
    [Key: string]: {
      // uid
      [Key: string]: boolean;
    };
  };
  byUid: {
    [Key: string]: RequestStatus<RequestAction>;
  };
}

export const toRootState = (requestState: RequestStatusState) => ({
  requests: requestState,
});

export interface RequestStatusStateSlice {
  requests: RequestStatusState;
}

const initialState = {
  byPayload: {},
  byPending: {},
  byError: {},
  byUid: {},
};

// TODO: this is an unappealing way to serialize payloads, but will work for now
export const serializeAction = (action: RequestAction) => {
  return JSON.stringify(action.payload);
};

const setPending = (draft: RequestStatusState, action: RequestAction) => {
  const { uid } = action.meta;
  const payloadId = serializeAction(action);

  draft.byPayload[action.type] = draft.byPayload[action.type] || {};
  draft.byPayload[action.type][payloadId] = uid;

  draft.byPending[action.type] = draft.byPending[action.type] || {};
  draft.byPending[action.type][uid] = true;

  draft.byUid[uid] = {
    trigger: action,
    status: Lifecycle.PENDING,
    cleared: false,
  };
};

const setComplete = (draft: RequestStatusState, action: SuccessAction) => {
  const { triggerAction } = action.payload;
  const { uid } = triggerAction.meta;
  if (draft.byPending[triggerAction.type]) {
    delete draft.byPending[triggerAction.type][uid];
  }
  draft.byUid[uid].status = Lifecycle.COMPLETED;
};

const setError = (draft: RequestStatusState, action: ErrorAction) => {
  const { triggerAction, error } = action.payload;
  const { uid } = triggerAction.meta;
  if (draft.byPending[triggerAction.type]) {
    delete draft.byPending[triggerAction.type][uid];
  }
  draft.byUid[uid].status = {
    ...error,
    actionId: uid,
  };

  draft.byError[triggerAction.type] = draft.byError[triggerAction.type] || {};
  draft.byError[triggerAction.type][uid] = true;
};

export const requestReducer = produce(
  (
    draft: RequestStatusState,
    action: RequestAction | SuccessAction | ErrorAction | ActionType<typeof clearRequest>
  ) => {
    if (action.type === getType(clearRequest)) {
      if (draft.byUid[action.payload.actionUid]) {
        draft.byUid[action.payload.actionUid].cleared = true;
        delete draft.byError?.[action.type]?.[action.payload.actionUid];
      }
    } else if (isRequestAction(action)) {
      setPending(draft, action);
    } else if (isSuccessAction(action)) {
      setComplete(draft, action);
    } else if (isErrorAction(action)) {
      setError(draft, action);
    }
  },
  initialState
);
