import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Collapse,
  Fade,
  IconButton,
  Link,
  TableCell,
  TableCellProps,
  TableRow,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PathBuilder } from '../../../routing';
import { PinIcon, UnpinIcon } from '../../ui/Icons';
import { TagDefinition, isChartableTag } from '../../../models';
import { EquipmentTabProps } from './EquipmentPage.d';
import EquipmentTagValue from './EquipmentTagValue';

const EquipmentTagRow = ({
  activeEquipmentId,
  activeTab,
  pinned = false,
  pinnedTagIds,
  siteId,
  tag,
  tagDataSnapshots,
  tagDataStatus,
  visible = true,
}: EquipmentTagRowProps) => {
  const classes = useStyles({ pinned });
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { id, label } = tag;

  const value = (
    <EquipmentTagValue
      tagId={tag.id}
      tagDataStatus={tagDataStatus}
      tagDataSnapshots={tagDataSnapshots}
    />
  );

  const cellProps = {
    Transition: pinned ? Fade : Collapse,
    visible,
  };

  return (
    <TableRow aria-hidden={!visible} className={classes.row}>
      <Cell className={classes.tag} component="th" scope="row" {...cellProps}>
        {isChartableTag(tag) ? (
          <Link
            component={RouterLink}
            variant="body2"
            to={PathBuilder.ANALYSIS_QUERY(siteId, [id])}
          >
            {label}
          </Link>
        ) : (
          <Typography variant="body2">{label}</Typography>
        )}
        {isSmallScreen && (
          <>
            <br />
            {value}
          </>
        )}
      </Cell>
      {!isSmallScreen && (
        <Cell align="right" className={classes.value} {...cellProps}>
          {value}
        </Cell>
      )}
      <Cell className={classes.actions} {...cellProps}>
        <Tooltip title={pinned ? 'Unpin Tag' : 'Pin Tag'}>
          <IconButton
            classes={{ label: classes.buttonLabel, root: classes.button }}
            color={pinned ? 'secondary' : 'default'}
            onClick={() => {
              history.push(
                PathBuilder.EQUIPMENT(
                  siteId,
                  getNewPinnedTagIds(tag, pinnedTagIds, pinned),
                  activeEquipmentId,
                  activeTab
                )
              );
            }}
            size="small"
          >
            {pinned ? <UnpinIcon /> : <PinIcon />}
          </IconButton>
        </Tooltip>
      </Cell>
    </TableRow>
  );
};

export interface EquipmentTagRowProps
  extends Omit<EquipmentTabProps, 'equipment' | 'equipmentAlarms'> {
  pinned?: boolean;
  tag: TagDefinition;
  visible?: boolean;
}

/**
 * Get pinned tag id state update
 *
 * @param tag
 * @param pinnedTagIds
 * @param pinned
 */
function getNewPinnedTagIds(tag: TagDefinition, pinnedTagIds: string[], pinned: boolean): string[] {
  if (pinned) {
    // Remove tag from array
    return pinnedTagIds.filter((tagId) => tagId !== tag.id);
  }
  // Add tag if it isn't in the array already
  return pinnedTagIds.includes(tag.id) ? pinnedTagIds : [...pinnedTagIds, tag.id];
}

const useStyles = makeStyles<Theme, { pinned: boolean }>((theme) => ({
  actions: {
    '&:last-child': {
      paddingRight: theme.spacing(1.5),
    },
  },
  button: {
    margin: theme.spacing(-0.75, 0),
  },
  buttonLabel: {
    '& > svg': {
      transform: 'rotate(35deg)',
    },
  },
  row: {
    '&:hover': {
      background: ({ pinned }) => (pinned ? theme.palette.grey[200] : theme.palette.grey[50]),
    },
  },
  tag: {
    width: '100%',
  },
  value: {
    whiteSpace: 'nowrap',
  },
}));

const Cell: React.FC<
  TableCellProps & {
    Transition: React.ComponentType<TransitionProps & { children: React.ReactElement }>;
    visible: boolean;
  }
> = ({ children, className = '', Transition, visible, ...cellProps }) => {
  const classes = useCellStyles({ visible });
  return (
    <TableCell className={clsx(classes.root, className)} {...cellProps}>
      <Transition in={visible}>
        <div>{children}</div>
      </Transition>
    </TableCell>
  );
};

const useCellStyles = makeStyles<Theme, { visible: boolean }>((theme) => ({
  root: {
    borderBottomWidth: ({ visible }) => (visible ? 1 : 0),
    padding: ({ visible }) => (visible ? theme.spacing(0.5, 3, 0.5, 2) : theme.spacing(0, 3, 0, 2)),
  },
}));

export default EquipmentTagRow;
