import { AlarmApi, AlarmSubscription } from '../../../api';
import { apiSagaV2, subSaga } from '../../../helpers/saga';
import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as alarmActions from './actions';

const fetchAlarmSummaries = apiSagaV2(alarmActions.fetchAlarmSummaries, (connection, action) => {
  const { siteId, duration } = action.payload;
  const start = new Date(Date.now() - duration);
  const end = new Date(Date.now());
  return new AlarmApi(connection).fetchAlarmSummaries(siteId, start, end);
});

const queryAlarmSummaries = apiSagaV2(alarmActions.queryAlarmSummaries, (connection, action) => {
  const { siteId, query } = action.payload;
  return new AlarmApi(connection).queryAlarmSummaries(siteId, query);
});

const handleSubAlarmSummaries = subSaga(alarmActions.subAlarmSummaries, (conection, action) => {
  const subscription = new AlarmSubscription(conection);
  const { siteId, duration } = action.payload;
  subscription.start(siteId, duration, false);
  return subscription;
});

const handleSubActiveAlarms = subSaga(alarmActions.subActiveAlarms, (conection, action) => {
  const subscription = new AlarmSubscription(conection);
  const { siteId } = action.payload;
  subscription.start(siteId, 0, true);
  return subscription;
});

const fetchAlarmDetails = apiSagaV2(alarmActions.fetchAlarmDetails, (connection, action) => {
  const { siteId, alarmTagId, alarmId } = action.payload;
  return new AlarmApi(connection).fetchAlarmDetails(siteId, alarmTagId, alarmId);
});

const addAlarmNote = apiSagaV2(alarmActions.addNote, (connection, action) => {
  const { siteId, alarmTagId, alarmId, note } = action.payload;
  return new AlarmApi(connection).postAlarmNote(siteId, alarmTagId, alarmId, note);
});

const acknowledgeAlarm = apiSagaV2(alarmActions.acknowledgeAlarm, (connection, action) => {
  const { siteId, alarmTagId, alarmId, note } = action.payload;
  return new AlarmApi(connection).postAlarmAck(siteId, alarmTagId, alarmId, note);
});

const resolveAlarm = apiSagaV2(alarmActions.resolveAlarm, async (connection, action) => {
  const { siteId, alarmTagId, alarmId, note, resolvedAt } = action.payload;
  return new AlarmApi(connection).postAlarmResolve(siteId, alarmTagId, alarmId, note, resolvedAt);
});

export function* alarmSagas() {
  yield takeEvery(getType(alarmActions.queryAlarmSummaries.request), queryAlarmSummaries);
  yield takeEvery(getType(alarmActions.fetchAlarmSummaries.request), fetchAlarmSummaries);
  yield takeEvery(getType(alarmActions.subAlarmSummaries.start), handleSubAlarmSummaries);
  yield takeEvery(getType(alarmActions.subActiveAlarms.start), handleSubActiveAlarms);
  yield takeEvery(getType(alarmActions.fetchAlarmDetails.request), fetchAlarmDetails);
  yield takeEvery(getType(alarmActions.addNote.request), addAlarmNote);
  yield takeEvery(getType(alarmActions.acknowledgeAlarm.request), acknowledgeAlarm);
  yield takeEvery(getType(alarmActions.resolveAlarm.request), resolveAlarm);
}
