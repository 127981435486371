import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';
import { ScreenSize, ScreenPosition, Margins } from './types';
import { getDefaultMargins } from './helpers';
import { Dictionary, clamp } from 'lodash';

const LABEL_PADDING = 2;

const styles = () =>
  createStyles({
    line: {
      stroke: '#333333',
    },
    label: {
      fontSize: '12px',
      fill: '#ffffff',
      dominantBaseline: 'middle',
    },
    labelBg: {
      fill: '#333333',
    },
  });

interface LabelPosition {
  bgWidth: number;
  bgHeight: number;
  bgX: number;
  bgY: number;
  labelX: number;
  labelY: number;
}

interface Props {
  style?: React.CSSProperties;
  size: ScreenSize;
  targetPosition: ScreenPosition;
  leftValue?: string;
  rightValue?: string;
  topValue?: string;
  bottomValue?: string;
  margins?: Margins;
}

type AllProps = Props & WithStyles<typeof styles>;

class Crosshairs extends React.Component<AllProps> {
  labelRefs: Dictionary<SVGTextElement> = {};

  rectRefs: Dictionary<SVGRectElement> = {};

  labelPositions = ['top', 'bottom', 'left', 'right'];

  labelSizes: Dictionary<number> = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    if (this.props.targetPosition.x !== undefined) {
      this.update();
    }
  }

  render() {
    const { classes, topValue, bottomValue, leftValue, rightValue } = this.props;
    const { width, height } = this.props.size;
    let { x, y } = this.props.targetPosition;
    y = y || 0;
    const margins = {
      ...getDefaultMargins(),
      ...this.props.margins,
    };
    const values = {
      top: x && topValue,
      bottom: x && bottomValue,
      left: y && leftValue,
      right: y && rightValue,
    };

    return (
      <g>
        {/* horizontal line */}
        {y && (
          <line
            className={classes.line}
            x1={margins.left}
            x2={width - margins.right}
            y1={y + 0.5}
            y2={y + 0.5}
          />
        )}

        {/* vertical line */}
        {x && (
          <line
            className={classes.line}
            x1={x + 0.5}
            x2={x + 0.5}
            y1={margins.top}
            y2={height - margins.bottom}
          />
        )}

        {/* render labels */}
        {this.labelPositions.map((posKey) => {
          return values[posKey] ? (
            <g key={posKey}>
              <rect className={classes.labelBg} ref={(el) => el && this.setRectRef(posKey, el)} />
              <text className={classes.label} ref={(el) => el && this.setLabelRef(posKey, el)}>
                {values[posKey]}
              </text>
            </g>
          ) : null;
        })}
      </g>
    );
  }

  private setRectRef(posKey: string, el: SVGRectElement) {
    this.rectRefs[posKey] = el;
  }

  private setLabelRef(posKey: string, el: SVGTextElement) {
    this.labelRefs[posKey] = el as SVGTextElement;
  }

  /**
   * The position of the labels is modified near the corners to prevent overlap,
   * for each label we need the x,y positions of the text and the background, and
   * the width and height of the background, which depend on the width and
   * height of the label text.
   */
  private calculateLabelPositions(
    targetPosition: ScreenPosition,
    chartSize: ScreenSize,
    labelSizes: Dictionary<ScreenSize>
  ) {
    const p = LABEL_PADDING;
    const out = {};
    const m = this.props.margins || {};
    const [mLeft, mRight, mTop, mBottom] = [m.left || 0, m.right || 0, m.top || 0, m.bottom || 0];

    for (const posKey of Object.keys(labelSizes)) {
      const labelSize = labelSizes[posKey];

      const chartWidth = chartSize.width;
      const chartHeight = chartSize.height;
      const labelWidth = labelSize.width;
      const labelHeight = labelSize.height;
      const targetX = targetPosition.x;
      const targetY = targetPosition.y;

      // calculate label positions
      let x = NaN,
        y = NaN,
        x_min,
        y_min,
        x_max,
        y_max,
        x_mid,
        y_mid;

      if (posKey === 'top') {
        x_min = mLeft + p;
        x_max = chartWidth - p - labelWidth - mRight;
        x_mid = targetX - labelWidth / 2;
        x = clamp(x_mid, x_min, x_max);
        y = p + labelHeight / 2 + mTop;
      } else if (posKey === 'bottom') {
        x_min = mLeft + p;
        x_max = chartWidth - p - labelWidth - mRight;
        x_mid = targetX - labelWidth / 2;
        x = clamp(x_mid, x_min, x_max);
        y = chartHeight - p - labelHeight / 2 - mBottom;
      } else if (posKey === 'left') {
        x = p + mLeft;
        // logic to prevent overlap
        y_min =
          labelSizes.top && targetX < labelSizes.top.width / 2 + p + 2 * p + labelWidth + mLeft
            ? labelSizes.top.height + 4 * p + labelHeight / 2 + mTop
            : p + labelHeight / 2 + mTop;
        y_max =
          labelSizes.bottom &&
          targetX < labelSizes.bottom.width / 2 + p + 2 * p + labelWidth + mLeft
            ? chartHeight - labelSizes.bottom.height - 4 * p - labelHeight / 2 - mBottom
            : chartHeight - p - labelHeight / 2 - mBottom;
        y_mid = targetY;

        y = clamp(y_mid, y_min, y_max);
      } else if (posKey === 'right') {
        x = chartWidth - p - labelWidth - mRight;
        // logic to prevent overlap
        y_min =
          labelSizes.top &&
          targetX > chartWidth - labelSizes.top.width / 2 - p - 2 * p - labelWidth - mRight
            ? labelSizes.top.height + 4 * p + labelHeight / 2 + mTop
            : p + labelHeight / 2 + mTop;
        y_max =
          labelSizes.bottom &&
          targetX > chartWidth - labelSizes.bottom.width / 2 - p - 2 * p - labelWidth - mRight
            ? chartHeight - labelSizes.bottom.height - 4 * p - labelHeight / 2 - mBottom
            : chartHeight - p - labelHeight / 2 - mBottom;
        y_mid = targetY;

        y = clamp(y_mid, y_min, y_max);
      }

      // get rect values based on label values
      out[posKey] = {
        bgWidth: labelWidth + 2 * p,
        bgHeight: labelHeight + 2 * p,
        bgX: x - p,
        bgY: y - labelHeight / 2 - p,
        labelX: x,
        labelY: y,
      };
    }
    return out;
  }

  private updateLabel(refKey: string, pos: LabelPosition) {
    const labelRef = this.labelRefs[refKey];
    const rectRef = this.rectRefs[refKey];

    rectRef.setAttribute('width', `${pos.bgWidth}px`);
    rectRef.setAttribute('height', `${pos.bgHeight}px`);

    if (pos.labelX !== undefined) {
      rectRef.setAttribute('x', `${pos.bgX}px`);
      labelRef.setAttribute('x', `${pos.labelX}px`);
    }

    if (pos.labelY !== undefined && !isNaN(pos.labelY)) {
      rectRef.setAttribute('y', `${pos.bgY}px`);
      labelRef.setAttribute('y', `${pos.labelY}px`);
    }
  }

  private update() {
    const labelSizes = {};
    for (const posKey of Object.keys(this.labelRefs)) {
      // if needed could compare label values to determine when to get label size
      labelSizes[posKey] = this.labelRefs[posKey].getBoundingClientRect();
    }
    const positions = this.calculateLabelPositions(
      this.props.targetPosition,
      this.props.size,
      labelSizes
    );
    for (const posKey of Object.keys(positions)) {
      this.updateLabel(posKey, positions[posKey]);
    }
  }
}

export default withStyles(styles)(Crosshairs);
