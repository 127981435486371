import { AlarmStateSlice } from './reducer';
import { getAlarmUid, AlarmSummary, AlarmId, AlarmDetails, AlarmStatus } from '../../../models';
import { RootState } from '../../';
import { NormalizationError } from '../../../helpers/norm';
import { denormTag } from '../tag/selectors';
import { ignore } from '../../../helpers/norm';
import { compact } from 'lodash';
import { memoizeSelector } from '../../../helpers/selectors';

export const getSummaryRoot = (rootState: AlarmStateSlice) => {
  return rootState.entities.alarmSummary;
};

export const getDetailsRoot = (rootState: AlarmStateSlice) => {
  return rootState.entities.alarmDetails;
};

export const getSummaryNormByUid = (rootState: AlarmStateSlice, alarmUid: string) => {
  return rootState.entities.alarmSummary.byId[alarmUid];
};

export const denormSummary = (rootState: RootState, alarmUid: string): AlarmSummary => {
  const summaryNorm = getSummaryNormByUid(rootState, alarmUid);
  if (!summaryNorm) {
    throw new NormalizationError(`Unable to denormalize alarmSummary: ${alarmUid}`, { alarmUid });
  }
  return {
    ...summaryNorm,
    alarmTag: denormTag(rootState, summaryNorm.alarmTag),
  };
};

export const denormDetails = (rootState: RootState, alarmUid: string): AlarmDetails => {
  const detailsNorm = rootState.entities.alarmDetails.byId[alarmUid];
  if (!detailsNorm) {
    throw new NormalizationError(`Unable to denormalize alarmDetails: ${alarmUid}`, { alarmUid });
  }
  return {
    ...detailsNorm,
    alarmTag: denormTag(rootState, detailsNorm.alarmTag),
  };
};

export const getByAlarmIds = (rootState: RootState, alarmIds: AlarmId[]): AlarmSummary[] => {
  return compact(
    alarmIds.map((alarmId) => {
      return ignore(denormSummary)(rootState, getAlarmUid(alarmId));
    })
  );
};

export const getAlarmSummariesByQuery = memoizeSelector(
  (rootState: RootState, queryId: string): AlarmSummary[] => {
    const root = getSummaryRoot(rootState);
    if (!root.byQuery[queryId]) {
      return [];
    }
    return root.byQuery[queryId].map((alarmUid) => denormSummary(rootState, alarmUid));
  },
  [(state: RootState) => state.entities.alarmSummary.byId]
);

export const findAlarmDetails = memoizeSelector(
  (rootState: RootState, alarmUid: string): AlarmDetails | undefined => {
    const detailsNorm = rootState.entities.alarmDetails.byId[alarmUid];
    return detailsNorm ? denormDetails(rootState, alarmUid) : undefined;
  },
  [(state: RootState) => state.entities.alarmDetails.byId]
);

export const findSummariesByRange = memoizeSelector(
  (rootState: RootState, siteId: number, lookback: number, isActiveOnly: boolean) => {
    const begin = new Date(Date.now() - lookback).toISOString();

    if (!rootState.entities.alarmSummary.bySite[siteId]) {
      return [];
    }

    return Object.keys(rootState.entities.alarmSummary.bySite[siteId])
      .map((alarmUid) => denormSummary(rootState, alarmUid))
      .filter(
        (summary) =>
          (summary.activatedAt > begin && !isActiveOnly) ||
          summary.status === AlarmStatus.ACTIVE_UN_ACK ||
          summary.status === AlarmStatus.ACTIVE_ACK
      );
  },
  [(state: RootState) => state.entities.alarmSummary.byId]
);

export const findSummariesBySite = memoizeSelector(
  (rootState: RootState, siteId: number, isActiveOnly: boolean) => {
    if (!rootState.entities.alarmSummary.bySite[siteId]) {
      return [];
    }

    return Object.keys(rootState.entities.alarmSummary.bySite[siteId])
      .map((alarmUid) => denormSummary(rootState, alarmUid))
      .filter(
        (summary) =>
          !isActiveOnly ||
          summary.status === AlarmStatus.ACTIVE_UN_ACK ||
          summary.status === AlarmStatus.ACTIVE_ACK
      );
  },
  [(state: RootState) => state.entities.alarmSummary.byId]
);
