import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import EquipmentList from './EquipmentList';
import EquipmentPageLanding from './EquipmentPageLanding';
import EquipmentTabPanel from './EquipmentTabPanel';
import { getSummariesForEntity } from '../../../models';
import { EquipmentPageProps } from './EquipmentPage.d';

const EquipmentPage = (props: EquipmentPageProps) => {
  const { activeAlarms, activeEquipmentId, allEquipment } = props;

  const classes = useStyles();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const showList = !isSmallScreen || activeEquipmentId === false;

  return (
    <div className={classes.tabsContainer}>
      {showList && <EquipmentList {...props} />}
      {allEquipment.map((equipment) => {
        if (activeEquipmentId !== equipment.id) {
          return null;
        }
        return (
          <EquipmentTabPanel
            key={equipment.id}
            equipment={equipment}
            equipmentAlarms={getSummariesForEntity(activeAlarms, equipment)}
            {...props}
          />
        );
      })}
      {!isSmallScreen && activeEquipmentId === false && <EquipmentPageLanding {...props} />}
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  tabsContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
  },
}));

export default EquipmentPage;
