import { InstallerStateSlice } from './reducer';

/**
 * Get installer site templates
 *
 * @param state
 */
export const getSiteTemplates = (state: InstallerStateSlice) => {
  return state.entities.installer.siteTemplates;
};

/**
 * Get installer utilities
 *
 * @param state
 * @param postalCode
 */
export const getUtilities = (state: InstallerStateSlice, postalCode: string | number) => {
  return state.entities.installer.utilities[postalCode] ?? [];
};
