import { keyBy, keys, forEach, includes } from 'lodash';
import { EquipmentNorm, TagDefinition } from '../../models';
import { Dictionary, flatMap } from 'lodash';
import { SitePayload, SiteNodePayload, EquipmentPayload, SiteData } from '../TagApi';
import { SiteNorm, SiteNodeNorm } from '../TagApi';
import logger from '../../helpers/logger';

const normalizeNode = (node: SiteNodePayload): SiteNodeNorm => {
  const { tags, equipment, ...restOfNode } = node;
  return {
    ...restOfNode,
    equipmentIds: equipment.map((e) => e.id),
    tagIds: tags.map((tag) => tag.id),
  };
};

const normalizeSite = (site: SitePayload): SiteNorm => {
  const { tags, nodes, webPortalLayoutJson, locationGeoJson, ...restOfSite } = site;
  let parsedLayout, parsedLocationGeo;
  try {
    parsedLayout = JSON.parse(webPortalLayoutJson);
  } catch (e) {
    logger.warn('Error parsing site web portal layout');
  }
  try {
    parsedLocationGeo = JSON.parse(locationGeoJson);
  } catch (e) {
    logger.warn('Error parsing site location geo json');
  }
  return {
    ...restOfSite,
    nodeIds: nodes.map((n) => n.id),
    webPortalLayoutJson: parsedLayout,
    locationGeoJson: parsedLocationGeo,
    tagIds: tags.map((tag) => tag.id),
  };
};

const normalizeEquipment = (equipment: EquipmentPayload): EquipmentNorm => {
  const { tags, ...restOfEquipment } = equipment;
  return {
    ...restOfEquipment,
    tagIds: tags.map((tag) => tag.id),
  };
};

export class SiteDataBuffer {
  private siteData?: SitePayload;

  private sites: Dictionary<SitePayload> = {};

  private nodes: Dictionary<SiteNodePayload> = {};

  private nodeList: SiteNodePayload[] = [];

  private equipment: Dictionary<EquipmentPayload> = {};

  private equipmentList: EquipmentPayload[] = [];

  private tagList: TagDefinition[] = [];

  private tagContextIds: string[] = [];

  private unprocessedTagContexts: Dictionary<TagDefinition[]> = {};

  getStatus() {
    return {
      waiting: [
        ...keys(this.sites).map((id) => `site-${id}`),
        ...keys(this.nodes).map((id) => `node-${id}`),
        ...keys(this.equipment).map((id) => `equipment-${id}`),
      ],
      received: [...this.tagContextIds],
      siteReceived: this.siteData ? true : false,
    };
  }

  pushSiteData(siteData: SitePayload) {
    if (this.siteData) {
      throw new Error('Site data has already been added to SiteDataBuffer');
    }
    this.siteData = siteData;
    this.sites[siteData.id] = siteData;
    this.nodeList = this.siteData.nodes;
    this.equipmentList = flatMap(this.siteData.nodes, (node) => node.equipment);

    this.nodes = keyBy(this.siteData.nodes, 'id');
    this.equipment = keyBy(this.equipmentList, 'id');

    // It is possible the site data is returned last so we need to
    // process any queued tag data here
    forEach(this.unprocessedTagContexts, this.processTagContext.bind(this));
  }

  pushTagData(tagContextId: string, tags: TagDefinition[]) {
    if (includes(this.tagContextIds, tagContextId)) {
      throw new Error(
        `Tag data has already been added to buffer for 'tagContextId'=${tagContextId}`
      );
    }
    this.tagContextIds.push(tagContextId);
    this.unprocessedTagContexts[tagContextId] = tags;
    if (!this.siteData) {
      // if no site data has been received we need to wait to process tags
      return;
    } else {
      forEach(this.unprocessedTagContexts, this.processTagContext.bind(this));
    }
  }

  isFinished() {
    const out =
      this.siteData &&
      keys(this.nodes).length === 0 &&
      keys(this.equipment).length === 0 &&
      keys(this.sites).length === 0;
    return out;
  }

  getData(): SiteData {
    if (!this.siteData) throw new Error('siteData not found');
    return {
      site: normalizeSite(this.siteData),
      nodes: this.nodeList.map(normalizeNode),
      equipment: this.equipmentList.map(normalizeEquipment),
      tags: this.tagList,
    };
  }

  flush() {
    if (!this.isFinished()) {
      throw new Error('Attempted to flush site buffer before all data received');
    }
    return this.getData();
  }

  private processTagContext(tags: TagDefinition[], tagContextId: string) {
    const [contextType, id] = tagContextId.split('-');
    const typeMap = {
      site: this.sites,
      node: this.nodes,
      equipment: this.equipment,
    };

    if (!includes(keys(typeMap), contextType)) {
      throw Error(`Invalid contextType '${contextType}' in SiteDataBuffer`);
    }

    if (!typeMap[contextType][id]) {
      throw Error(`No context matching id '${id}' for context '${contextType}' in SiteDataBuffer`);
    }

    this.tagList = this.tagList.concat(tags);
    const contextReference = typeMap[contextType];
    contextReference[id].tags = tags;

    delete contextReference[id];

    delete this.unprocessedTagContexts[tagContextId];
  }
}
