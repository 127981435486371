import React from 'react';
import { AlarmDetails } from '../../../models';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  TextField,
} from '@material-ui/core';
import { Formik, Field, FieldProps, FormikProps } from 'formik';
import { object, date, string } from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SimpleRequestStatus } from '../../../state/request';
import { RequestStatus } from '../../utility/RequestStatus';
import { useRef, useState } from 'react';
import { CancelButton, PrimaryButton } from '../../../components/ui/Buttons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subheading: {
      paddingTop: theme.spacing(),
    },
  })
);

interface AlarmUpdateModalProps {
  requestStatus?: SimpleRequestStatus;
  siteId: number;
  alarmDetails: AlarmDetails;
  handleCancel: () => void;
}

export interface AlarmAckModalProps extends AlarmUpdateModalProps {
  handleSubmit: (siteId: number, alarmTagId: string, alarmId: string, note?: string) => void;
}

export interface AlarmNoteModalProps extends AlarmUpdateModalProps {
  handleSubmit: (siteId: number, alarmTagId: string, alarmId: string, note: string) => void;
}

export interface AlarmResolveModalProps extends AlarmUpdateModalProps {
  handleSubmit: (
    siteId: number,
    alarmTagId: string,
    alarmId: string,
    resolvedAt: Date,
    note?: string
  ) => void;
}

export const getAckValidationSchema = (alarmDetails: AlarmDetails) => {
  return object({
    note: alarmDetails.alarmTag.acknowledgementNoteRequired
      ? string().required('Note is required to acknowledge.')
      : string().optional(),
  });
};

export const getNoteValidationSchema = () => {
  return object({
    note: string().required('Note is required to acknowledge.'),
  });
};

export const getResolveValidationSchema = (alarmDetails: AlarmDetails) => {
  return object({
    resolvedAt: date()
      .required()
      .max(new Date(Date.now() + 1000 * 60 * 5), 'Resolved date / time must be in the past.'),
    note: alarmDetails.alarmTag.acknowledgementNoteRequired
      ? string().required('Note is required to resolve.')
      : string().optional(),
  });
};

const dateFormat = 'yyyy-MM-dd h:mm aa';

export const AlarmAckModal: React.FC<AlarmAckModalProps> = (props) => {
  const { siteId, alarmDetails, handleSubmit, handleCancel, requestStatus } = props;
  const classes = useStyles();
  const { alarmId, alarmTag } = alarmDetails;
  const alarmTagId = alarmTag.id;
  const noteRequired = alarmTag.acknowledgementNoteRequired;

  const [height, setHeight] = useState<undefined | number>();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const handleSubmitSideeffects = () => {
    setHeight(contentRef?.current?.offsetHeight);
  };

  let dialogContent: React.ReactNode;

  if (requestStatus) {
    dialogContent = (
      <>
        <RequestStatus status={requestStatus} minHeight={height ? `${height}px` : undefined}>
          <div>Alarm updated succesfully.</div>
        </RequestStatus>
        {typeof requestStatus === 'object' && (
          <div style={{ padding: 8 }}>
            <Button color="secondary" onClick={handleCancel}>
              Ok
            </Button>
          </div>
        )}
      </>
    );
  } else {
    dialogContent = (
      <Formik<{ note?: string }>
        validationSchema={getAckValidationSchema(alarmDetails)}
        onSubmit={(values) => {
          handleSubmitSideeffects();
          handleSubmit(siteId, alarmTagId, alarmId, values.note);
        }}
        initialValues={{
          note: undefined,
        }}
        validateOnMount
      >
        {(formikProps) => (
          <form data-testid="alarm-ack-form" onSubmit={formikProps.handleSubmit}>
            <DialogTitle disableTypography={true}>
              <Typography variant="h6">Acknowledge Alarm: {alarmDetails.alarmTag.label}</Typography>
              <Typography variant="subtitle1" className={classes.subheading}>
                Triggered: {new Date(alarmDetails.activatedAt).toLocaleString()}
              </Typography>
              <Typography variant="subtitle1" className={classes.subheading}>
                Status: {alarmDetails.status}
              </Typography>
            </DialogTitle>

            <DialogContent>
              <Field
                name="note"
                children={({ field }: FieldProps<string | undefined, { note?: string }>) => (
                  <TextField
                    data-testid="ack-note-textbox"
                    fullWidth
                    multiline={true}
                    variant="outlined"
                    rows={4}
                    rowsMax={8}
                    label={noteRequired ? 'Note (Required)' : 'Note (Optional)'}
                    margin="normal"
                    {...field}
                  />
                )}
              />
            </DialogContent>

            <DialogActions>
              <CancelButton onClick={handleCancel}>Cancel</CancelButton>
              <PrimaryButton
                data-testid="alarm-action-submit"
                type="submit"
                disabled={formikProps.isValidating || !formikProps.isValid}
              >
                Acknowledge
              </PrimaryButton>
            </DialogActions>
          </form>
        )}
      </Formik>
    );
  }

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      {dialogContent}
    </Dialog>
  );
};

export const AlarmNoteModal: React.FC<AlarmNoteModalProps> = (props) => {
  const { siteId, alarmDetails, handleSubmit, handleCancel, requestStatus } = props;
  const classes = useStyles();
  const { alarmId, alarmTag } = alarmDetails;
  const alarmTagId = alarmTag.id;

  const [height, setHeight] = useState<undefined | number>();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const handleSubmitSideeffects = () => {
    setHeight(contentRef?.current?.offsetHeight);
  };

  let dialogContent: React.ReactNode;

  if (requestStatus) {
    dialogContent = (
      <>
        <RequestStatus status={requestStatus} minHeight={height ? `${height}px` : undefined}>
          <div>Alarm updated succesfully.</div>
        </RequestStatus>
        {typeof requestStatus === 'object' && (
          <div style={{ padding: 8 }}>
            <Button color="secondary" onClick={handleCancel}>
              Ok
            </Button>
          </div>
        )}
      </>
    );
  } else {
    dialogContent = (
      <Formik<{ note: string }>
        validationSchema={getNoteValidationSchema()}
        onSubmit={(values) => {
          handleSubmitSideeffects();
          handleSubmit(siteId, alarmTagId, alarmId, values.note);
        }}
        initialValues={{
          note: '',
        }}
        validateOnMount
      >
        {(formikProps) => (
          <div ref={contentRef}>
            <form onSubmit={formikProps.handleSubmit}>
              <DialogTitle disableTypography={true}>
                <Typography variant="h6">Add Note: {alarmDetails.alarmTag.label}</Typography>
                <Typography variant="subtitle1" className={classes.subheading}>
                  Triggered: {new Date(alarmDetails.activatedAt).toLocaleString()}
                </Typography>
                <Typography variant="subtitle1" className={classes.subheading}>
                  Status: {alarmDetails.status}
                </Typography>
              </DialogTitle>

              <DialogContent>
                <Field
                  name="note"
                  children={({ field }: FieldProps<string, { note: string }>) => (
                    <TextField
                      fullWidth
                      multiline={true}
                      variant="outlined"
                      rows={4}
                      rowsMax={8}
                      label={'Note'}
                      margin="normal"
                      {...field}
                    />
                  )}
                />
              </DialogContent>

              <DialogActions>
                <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                <PrimaryButton
                  data-testid="alarm-action-submit"
                  type="submit"
                  disabled={formikProps.isValidating || !formikProps.isValid}
                >
                  Add Note
                </PrimaryButton>
              </DialogActions>
            </form>
          </div>
        )}
      </Formik>
    );
  }

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      {dialogContent}
    </Dialog>
  );
};

interface ResolveFormState {
  note?: string;
  resolvedAt: Date;
}

const errorHelper = <FormState,>(form: FormikProps<FormState>, key: string) => ({
  helperText: form.errors[key] && form.touched[key] && form.errors[key],
  error: Boolean(form.errors[key] && form.touched[key]),
});

export const AlarmResolveModal: React.FC<AlarmResolveModalProps> = (props) => {
  const { siteId, alarmDetails, handleSubmit, handleCancel, requestStatus } = props;
  const classes = useStyles();
  const { alarmId, alarmTag } = alarmDetails;
  const alarmTagId = alarmTag.id;

  const [height, setHeight] = useState<undefined | number>();
  const contentRef = useRef<HTMLDivElement | null>(null);

  const filterDates = (dateOption: Date) => {
    return (
      dateOption.getTime() < Date.now() + 1000 * 60 * 5 &&
      dateOption.getTime() > new Date(alarmDetails.activatedAt).getTime()
    );
  };

  const noteRequired = alarmTag.acknowledgementNoteRequired;

  const handleSubmitSideeffects = () => {
    setHeight(contentRef?.current?.offsetHeight);
  };

  let dialogContent: React.ReactNode;

  if (requestStatus) {
    let errorMessage;
    if (typeof requestStatus === 'object' && requestStatus.statusCode === 409) {
      errorMessage =
        'Requested change conflicts with more recent server state. Please reload and try again.';
    }
    dialogContent = (
      <>
        <RequestStatus
          minHeight={height ? `${height}px` : undefined}
          status={requestStatus}
          errorMessage={errorMessage}
        >
          <div>Alarm updated succesfully.</div>
        </RequestStatus>
        {typeof requestStatus === 'object' && (
          <div style={{ padding: 8 }}>
            <Button color="secondary" onClick={handleCancel}>
              Ok
            </Button>
          </div>
        )}
      </>
    );
  } else {
    dialogContent = (
      <Formik<ResolveFormState>
        validationSchema={getResolveValidationSchema(alarmDetails)}
        onSubmit={(values) => {
          handleSubmitSideeffects();
          handleSubmit(siteId, alarmTagId, alarmId, values.resolvedAt, values.note);
        }}
        initialValues={{
          note: undefined,
          resolvedAt: new Date(Date.now()),
        }}
        validateOnMount
      >
        {(formikProps) => (
          <div ref={contentRef}>
            <form onSubmit={formikProps.handleSubmit}>
              <DialogTitle disableTypography={true}>
                <Typography variant="h6">Resolve: {alarmDetails.alarmTag.label}</Typography>
                <Typography variant="subtitle1" className={classes.subheading}>
                  Triggered: {new Date(alarmDetails.activatedAt).toLocaleString()}
                </Typography>
                <Typography variant="subtitle1" className={classes.subheading}>
                  Status: {alarmDetails.status}
                </Typography>
              </DialogTitle>

              <DialogContent>
                <Field name="resolvedAt">
                  {({ form, field }: FieldProps<Date, ResolveFormState>) => (
                    <DatePicker
                      selected={form.values.resolvedAt}
                      onChange={(e) => field.onChange({ target: { value: e, name: field.name } })}
                      onBlur={field.onBlur}
                      onCalendarClose={() => field.onBlur({ target: { name: field.name } })}
                      dateFormat={dateFormat}
                      showTimeSelect
                      popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                        },
                      }}
                      filterDate={filterDates}
                      customInput={
                        <TextField
                          {...field}
                          {...errorHelper(form, 'resolvedAt')}
                          fullWidth
                          label="Resolved Date / Time"
                          variant="outlined"
                        />
                      }
                    />
                  )}
                </Field>

                <Field
                  name="note"
                  children={({ field }: FieldProps<string | undefined, { note?: string }>) => (
                    <TextField
                      fullWidth
                      multiline={true}
                      variant="outlined"
                      rows={8}
                      rowsMax={12}
                      label={noteRequired ? 'Note (Required)' : 'Note (Optional)'}
                      margin="normal"
                      {...field}
                    />
                  )}
                />
              </DialogContent>

              <DialogActions>
                <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                <PrimaryButton
                  data-testid="alarm-action-submit"
                  type="submit"
                  disabled={formikProps.isValidating || !formikProps.isValid}
                >
                  Resolve
                </PrimaryButton>
              </DialogActions>
            </form>
          </div>
        )}
      </Formik>
    );
  }

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      {dialogContent}
    </Dialog>
  );
};
