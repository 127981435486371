import { NotificationApi, NotificationSubscription } from '../../../api';
import { apiSagaV2, subSaga } from '../../../helpers/saga';
import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { fetchNotifications, subNotifications } from './actions';

const handleFetchNotifications = apiSagaV2(fetchNotifications, (connection, action) => {
  const { siteId } = action.payload;
  return new NotificationApi(connection).fetchNotifications(siteId);
});

const handleSubNotifications = subSaga(subNotifications, (conection, action) => {
  const subscription = new NotificationSubscription(conection);
  subscription.start(action.payload.siteId);
  return subscription;
});

export function* notificationSagas() {
  yield takeEvery(getType(fetchNotifications.request), handleFetchNotifications);
  yield takeEvery(getType(subNotifications.start), handleSubNotifications);
}
