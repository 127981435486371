import { ReportConfiguration, GenerateReportRequest, ExportReportRequest } from '../../../models';
import { createApiActions } from '../../../helpers/actions';

export const fetchReportConfigurations = createApiActions<ReportConfiguration[]>()(
  'report/fetchReportConfiguration',
  'report/fetchReportConfigurationSuccess',
  'report/fetchReportConfigurationError',
  (siteId: number) => ({ siteId })
);

export const generateReport = createApiActions<boolean>()(
  'report/generateReport',
  'report/generateReportSuccess',
  'report/generateReportError',
  (request: GenerateReportRequest) => ({ request })
);

export const exportReport = createApiActions<boolean>()(
  'report/exportReport',
  'report/exportReportSuccess',
  'report/exportReportError',
  (request: ExportReportRequest) => ({ request })
);
