import { mapValues, Dictionary } from 'lodash';
import { TagAttribute } from '../../../models';

const withCommon = (common: string[], map: Dictionary<string[]>): Dictionary<TagAttribute[]> => {
  return mapValues(map, (attributes) => [...common, ...attributes]) as Dictionary<TagAttribute[]>;
};

/**
 * Each of these attribute maps should uniquely specify a tag
 */

/* Tags in kWh */
export const energyAttributeMap = withCommon(['controlsummary', 'timeseries'], {
  pvToLoad: ['pv to load', 'tou'],
  pvToMeter: ['utility rollback', 'tou'],
  pvToBattery: ['pv to battery', 'tou'],
  utilityToBattery: ['utility to battery', 'tou'],
  batteryToLoad: ['storage discharging', 'tou'],
  utilityToLoad: ['utility to load', 'tou'],
});

/** Tags in kW */
export const powerAttributeMap = withCommon(['controlsummary', 'timeseries'], {
  load: ['baseline', 'demand'],
  utilityMeter: ['utility', 'demand'],
  pvToLoad: ['pv to load', 'demand'],
  pvToMeter: ['utility rollback', 'demand'],
  pvToBattery: ['pv to battery', 'demand'],
  utilityToBattery: ['utility to battery', 'demand'],
  batteryToLoad: ['storage discharging', 'demand'],
  utilityToLoad: ['utility to load', 'demand'],
  batteryChargePercentage: ['state of charge'],
  demandThreshold: ['max-value', 'demand'],
});

export const costAttributeMap = withCommon(['controlsummary', 'timeseries'], {
  load: ['load', 'cost'],
  utilityMeter: ['utility consumption', 'cost'],
  pvToLoad: ['pv to load', 'cost'],
  pvToMeter: ['utility rollback', 'cost'],
  pvToBattery: ['pv to battery', 'cost'],
  utilityToBattery: ['utility to battery', 'cost'],
  batteryToLoad: ['storage discharging', 'cost'],
  utilityToLoad: ['utility to load', 'cost'],
  batteryChargePercentage: ['state of charge'],
});

export const touAttributeMap = withCommon(['controlsummary', 'tou'], {
  consumptionCharge: ['utility consumption', 'actual', 'cost'],
  consumptionChargeBaseline: ['utility consumption', 'baseline', 'cost'],
  nemCredit: ['utility rollback', 'actual', 'cost'],
  nemCreditBaseline: ['utility rollback', 'baseline', 'cost'],
  peakDemand: ['demand', 'actual'],
  peakDemandBaseline: ['demand', 'baseline'],
  pvToLoad: ['pv to load'],
  pvToMeter: ['utility rollback'],
  pvToBattery: ['pv to battery'],
  utilityToBattery: ['utility to battery'],
  batteryToLoad: ['storage discharging'],
  utilityToLoad: ['utility to load'],
});

export const projectionAttributeMap = withCommon(['controlsummary'], {
  energyCharges: ['utility consumption', 'actual', 'projection'],
  energyChargesBaseline: ['utility consumption', 'baseline', 'projection'],
  nemCredits: ['utility rollback', 'projection'],
  demandCost: ['demand', 'actual', 'projection'],
  demandCostBaseline: ['demand', 'baseline', 'projection'],
});
