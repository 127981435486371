import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Theme,
  createStyles,
  makeStyles,
  Checkbox,
  ListItemIcon,
  Collapse,
  TextField,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  Paper,
  Grid,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Cancel from '@material-ui/icons/Cancel';
import { Site, getSiteTags, SiteNode, Equipment, TagDefinition, TagType } from '../../../models';
import { useMemo, useState } from 'react';
import { sortBy } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemDense: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    checkbox: {
      height: 'unset',
      width: 'unset',
      padding: 0,
      marginRight: theme.spacing(2),
    },
    nestedPrimaryListItemText: {
      fontWeight: 'bold',
    },
    listItemIcon: {
      minWidth: 0,
      marginRight: theme.spacing(2),
    },
    nestedListItem: {
      marginLeft: 0,
    },
    tagTreeRoot: {
      overflowY: 'auto',
      maxHeight: 450,
      padding: 6,
      minHeight: 300,
    },
    clearSearchButton: {
      height: 'unset',
      width: 'unset',
    },
    searchInput: {
      paddingBottom: theme.spacing(),
    },
  })
);

export interface ExpandGroupProps<T> {
  item: T;
  getId: (item: T) => string;
  getLabel: (item: T) => string;
}

const MemoList = React.memo(List);

const ExpandGroup = <T,>(props: React.PropsWithChildren<ExpandGroupProps<T>>) => {
  const { item, getId, getLabel } = props;
  const classes = useStyles();
  const listItemClasses = useMemo<{ dense: string }>(
    () => ({
      dense: classes.listItemDense,
    }),
    [classes]
  );
  const listItemIconClasses = useMemo<{ root: string }>(
    () => ({
      root: classes.listItemIcon,
    }),
    [classes]
  );
  const nestedPrimaryListItemTextClasses = useMemo<{ primary: string }>(
    () => ({
      primary: classes.nestedPrimaryListItemText,
    }),
    [classes]
  );

  const [expandedListItems, setExpandedListItems] = useState<string[]>([]);
  const handleExpand = (id: string) => () => {
    let newExpandedListItems = [];
    if (expandedListItems.includes(id)) {
      newExpandedListItems = expandedListItems.filter((i) => i !== id);
    } else {
      newExpandedListItems = [...expandedListItems, id];
    }
    setExpandedListItems(newExpandedListItems);
  };

  return (
    <>
      <ListItem button dense classes={listItemClasses} onClick={handleExpand(getId(item))}>
        <ListItemIcon classes={listItemIconClasses}>
          {expandedListItems.includes(getId(item)) ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
        <ListItemText classes={nestedPrimaryListItemTextClasses}>{getLabel(item)}</ListItemText>
      </ListItem>
      <Collapse
        in={expandedListItems.includes(getId(item))}
        timeout="auto"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <MemoList disablePadding dense style={{ paddingLeft: 24 }}>
          {props.children}
        </MemoList>
      </Collapse>
    </>
  );
};

export interface TagItemProps {
  tag: TagDefinition;
  onToggle: (tagId: string) => void;
  isSelected: boolean;
  disabled?: (tag: TagDefinition) => boolean;
}

export const TagItem: React.FC<TagItemProps> = React.memo((props) => {
  const { tag, onToggle, isSelected, disabled } = props;

  const handleToggle = () => onToggle(tag.id);
  const classes = useStyles();
  const checkboxClasses = { root: classes.checkbox };
  const listItemDenseClasses = { root: classes.nestedListItem, dense: classes.listItemDense };

  return (
    <ListItem
      key={tag.id}
      dense
      classes={listItemDenseClasses}
      disabled={disabled && disabled(tag)}
    >
      <Checkbox
        classes={checkboxClasses}
        checked={isSelected}
        onClick={handleToggle}
        disabled={disabled && disabled(tag)}
      />
      <ListItemText onClick={handleToggle}>
        {tag.label === tag.id ? <em>{tag.label}</em> : <>{tag.label}</>}
        {tag.tagType !== TagType.Alarm && (
          <span data-testid={`units-container-${tag.id}`}>&nbsp;({tag.unit})</span>
        )}
      </ListItemText>
    </ListItem>
  );
});

interface TagListProps {
  tags: TagDefinition[];
  selectedTags: string[];
  onToggle: (tagId: string) => void;
  disabled?: (tag: TagDefinition) => boolean;
}

const TagList: React.FC<TagListProps> = React.memo((props) => {
  const { tags, selectedTags, onToggle, disabled } = props;

  const sortedTags = useMemo(() => sortBy(tags, (tag) => tag.label || tag.id), [tags]);

  return (
    <MemoList disablePadding dense>
      {sortedTags.map((tag) => {
        return (
          <TagItem
            key={tag.id}
            tag={tag}
            onToggle={onToggle}
            isSelected={selectedTags.includes(tag.id)}
            disabled={disabled}
          />
        );
      })}
    </MemoList>
  );
});

const getNodeId = (node: SiteNode) => {
  return node.id.toString();
};
const getNodeLabel = (node: SiteNode) => {
  return node.name;
};

const getEquipmentId = (equipment: Equipment) => {
  return equipment.id.toString();
};
const getEquipmentLabel = (equipment: Equipment) => {
  return equipment.label;
};

export interface TagTreeProps {
  site: Site;
  onTagSelect: (tagId: string) => void;
  clearAll?: () => void;
  selectedTags: string[];
  tagFilter?: (tag: TagDefinition) => boolean;
  height?: number | string;
  style?: React.CSSProperties;
  disabled?: (tag: TagDefinition) => boolean;
}

export const TagTree: React.FC<TagTreeProps> = (props) => {
  const { site, selectedTags, disabled, tagFilter } = props;

  const allTags = useMemo(() => {
    if (tagFilter) {
      return getSiteTags(site).filter(tagFilter);
    } else {
      return getSiteTags(site);
    }
  }, [site, tagFilter]);

  const [filterText, setFilterText] = useState<string | undefined>(undefined);

  const filterTags = (tag: TagDefinition) => {
    const textFilter = filterText
      ? tag.label.toLowerCase().includes(filterText.toLowerCase())
      : true;
    const topFilter = props.tagFilter ? props.tagFilter(tag) : true;
    return textFilter && topFilter;
  };
  const nodes = site.nodes.filter((node) => node.equipment.length > 0);
  return (
    <div style={{ ...props.style }}>
      <div
        style={{
          minWidth: 120,
          minHeight: 200,
          height: props.height || 'auto',
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {selectedTags && selectedTags.length > 0 && (
          <>
            <Grid container>
              <Grid item>
                <Typography variant="caption">Selected: {selectedTags.length}</Typography>
              </Grid>
              <Grid item style={{ marginLeft: 'auto' }}>
                {props.clearAll && selectedTags.length > 0 && (
                  <Link style={{ cursor: 'pointer' }} onClick={props.clearAll} variant="caption">
                    Clear All
                  </Link>
                )}
              </Grid>
            </Grid>
            <Paper style={{ minHeight: 160, maxHeight: '40%', overflowY: 'auto', marginBottom: 8 }}>
              <TagList
                tags={allTags.filter((tag) => selectedTags.includes(tag.id))}
                onToggle={props.onTagSelect}
                selectedTags={selectedTags}
              />
            </Paper>
          </>
        )}

        <Typography variant="caption">Available: {allTags.length}</Typography>

        <Paper style={{ flex: 1, height: '100%', paddingTop: 48, position: 'relative' }}>
          <div
            style={{
              marginBottom: 0,
              top: 0,
              left: 0,
              right: 0,
              padding: 4,
              position: 'absolute',
              zIndex: 1000,
              backgroundColor: '#fff',
              borderBottom: '1px solid #f0f0f0',
            }}
          >
            <TextField
              fullWidth
              placeholder="Filter"
              value={filterText || ''}
              onChange={(e) => setFilterText(e.target.value)}
              variant="outlined"
              size="small"
              InputProps={
                filterText
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setFilterText(undefined)}>
                            <Cancel />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
            />
          </div>

          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              top: 48,
              overflow: 'auto',
            }}
          >
            <MemoList disablePadding dense>
              {nodes.length === 1 &&
                sortBy(nodes[0].equipment, (e) => e.label).map((equipment) => {
                  const tags = equipment.tags.filter(filterTags);
                  return tags.length > 0 ? (
                    <ExpandGroup<Equipment>
                      key={equipment.id.toString()}
                      getId={getEquipmentId}
                      getLabel={getEquipmentLabel}
                      item={equipment}
                    >
                      <TagList
                        tags={tags}
                        onToggle={props.onTagSelect}
                        selectedTags={selectedTags}
                        disabled={disabled}
                      />
                    </ExpandGroup>
                  ) : null;
                })}

              {nodes.length > 1 ? (
                nodes.map((node) => (
                  <ExpandGroup<SiteNode>
                    key={node.id.toString()}
                    getId={getNodeId}
                    getLabel={getNodeLabel}
                    item={node}
                  >
                    {sortBy(node.equipment, (e) => e.label).map((equipment) => {
                      const tags = equipment.tags.filter(filterTags);
                      return tags.length > 0 ? (
                        <ExpandGroup<Equipment>
                          key={equipment.id.toString()}
                          getId={getEquipmentId}
                          getLabel={getEquipmentLabel}
                          item={equipment}
                        >
                          <TagList
                            tags={tags.filter(filterTags)}
                            onToggle={props.onTagSelect}
                            selectedTags={selectedTags}
                            disabled={disabled}
                          />
                        </ExpandGroup>
                      ) : null;
                    })}
                    <TagList
                      tags={node.tags.filter(filterTags)}
                      onToggle={props.onTagSelect}
                      selectedTags={selectedTags}
                      disabled={disabled}
                    />
                  </ExpandGroup>
                ))
              ) : (
                <>
                  <TagList
                    tags={site.nodes[0].tags.filter(filterTags)}
                    onToggle={props.onTagSelect}
                    selectedTags={selectedTags}
                    disabled={disabled}
                  />
                </>
              )}
              <TagList
                tags={site.tags.filter(filterTags)}
                onToggle={props.onTagSelect}
                selectedTags={selectedTags}
                disabled={disabled}
              />
            </MemoList>
          </div>
        </Paper>
      </div>
    </div>
  );
};
