import { getType, ActionType } from 'typesafe-actions';
import * as siteActions from '../site/actions';
import { keyBy, mapValues, pickBy } from 'lodash';
import { produce } from 'immer';
import { TagDefinition } from '../../../models';
import * as alarmActions from '../alarm/actions';

export interface TagEntityState {
  byId: { [Key: string]: TagDefinition };
  bySite: {
    [Key: number]: {
      [Key: string]: boolean;
    };
  };
}

export interface TagStateSlice {
  entities: {
    tag: TagEntityState;
  };
}

const initialState = {
  byId: {},
  bySite: {},
};

type TagActions =
  | ActionType<typeof siteActions.fetchSiteData>
  | ActionType<typeof alarmActions.fetchAlarmSummaries>
  | ActionType<typeof alarmActions.queryAlarmSummaries>;

const tagReducerHelper = (
  draft: TagEntityState,
  tags: TagDefinition[],
  siteId: number,
  filter?: (tagId: string) => boolean
) => {
  const tagLookup = keyBy(tags, (tag) => tag.id);
  const currentTags = filter ? pickBy(draft.byId, (_, tagId: string) => filter(tagId)) : draft.byId;
  draft.byId = {
    ...currentTags,
    ...tagLookup,
  };
  draft.bySite[siteId] = draft.bySite[siteId] || {};
  const currentSiteTags = filter
    ? pickBy(draft.bySite[siteId], (_, tagId: string) => filter(tagId))
    : draft.bySite[siteId];
  draft.bySite[siteId] = {
    ...currentSiteTags,
    ...mapValues(tagLookup, () => true),
  };
  return draft;
};

// Some tags change ID, but logically represent the same value.
// RateSchedule tags are an example. As a result, we want to filter these
// tags out from current sets as they can't be replaced like other
// tags can.
const filterIrregularTags = (tagId: string): boolean => {
  return !tagId.includes('.RateSchedule.');
};

export const tagEntityReducer = produce((draft: TagEntityState, action: TagActions) => {
  switch (action.type) {
    case getType(siteActions.fetchSiteData.success): {
      const { tags, site } = action.payload.result;
      tagReducerHelper(draft, tags, site.id, filterIrregularTags);
      break;
    }

    case getType(alarmActions.fetchAlarmSummaries.success):
    case getType(alarmActions.queryAlarmSummaries.success): {
      const { result: alarmSummaries } = action.payload;
      const { siteId } = action.payload.triggerAction.payload;
      const alarmTags = [...alarmSummaries.map((alarmSummary) => alarmSummary.alarmTag)];

      tagReducerHelper(draft, alarmTags, siteId);
      break;
    }

    default:
      break;
  }
}, initialState);
