import React from 'react';
import { Typography, Theme, withStyles, WithStyles, createStyles } from '@material-ui/core';

interface Props {
  label: string;
  nomargin?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    fieldGroup: {
      marginBottom: theme.spacing(2),
    },
    label: {
      marginBottom: 4,
      color: '#2a2a2a',
    },
  });

const FieldGroup: React.SFC<Props & WithStyles<typeof styles>> = (props) => {
  const { classes } = props;
  return (
    <div className={props.nomargin ? undefined : classes.fieldGroup}>
      <Typography variant="caption" className={classes.label}>
        {props.label}
      </Typography>
      <div>{props.children}</div>
    </div>
  );
};

export default withStyles(styles)(FieldGroup);
