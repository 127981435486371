import { useState } from 'react';
import { PathBuilder, PathPattern, SiteRouteMatch } from '../routing';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { ActivateAccount } from './modules/account/ActivateAccount';
import { Protected } from './modules/auth/Protected';
import { Redirect } from 'react-router-dom';
import WithProfile from './modules/auth/WithProfile';
import { UserProfile } from '../models';
import { SiteContext } from './contexts/SiteContext';
import {
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { ErrorMessage } from './ui/ErrorMessage';
import AllowForRoles from './modules/auth/AllowForRoles';
import { SiteRole } from '../models';
import { closeNav, getIsNavOpen, toggleNav, ttDate } from '../state/contexts/app';
import { useSelector } from 'react-redux';
import { useAction } from '../helpers/hooks';
import { MainNavigation } from './modules/main/MainNavigation';
import { MainToolbar } from './modules/main/MainToolbar';
import { ErrorPage } from './modules/main/ErrorPage';
import { DashboardPageLoader } from './modules/dashboard/DashboardPageLoader';
import { AlarmsPageLoader } from './modules/alarm-page/AlarmsPageLoader';
import { ReportsPageLoader } from './modules/reports/ReportsPageLoader';
import { UserPageLoader } from './modules/user-page/UserPageLoader';
import { PortfolioToolbar } from './modules/portfolio/PortfolioToolbar';
import PortfolioPageLoader from './modules/portfolio/PortfolioPageLoader';
import { AlertPageLoader } from './modules/alarm-notifications/AlertPageLoader';
import { ControlSummaryPageLoader } from './modules/control-summary/ControlSummaryPageLoader';
import { AnalysisPageLoader } from './modules/analysis-page/AnalysisPageLoader';
import { OpSummaryPageLoader } from './modules/op-summary/OpSummaryPageLoader';
import { SiteDocumentContainer } from './modules/site-document/SiteDocumentContainer';
import EquipmentPageLoader from './modules/equipment/EquipmentPageLoader';
import InstallerPageLoader from './modules/installer/InstallerPageLoader';
import { ismVoult, mvoultLogo } from '../config';
import { featureFlags } from '../config/featureFlags';
import { PrimaryButton, SecondaryButton } from './ui/Buttons';
import { format } from 'date-fns';
import OptOutPageContainer from './modules/opt-out/OptOutPageContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  contentOuter: {
    margin: 0,
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
  },
  contentInner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
  },
  timeTravel: {
    position: 'fixed',
    backgroundColor: '#5e35b1',
    color: theme.palette.common.white,
    padding: '0 6px',
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    zIndex: 1500,
    top: 0,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 24,
    textAlign: 'center',
  },
}));

const TimeTravelBanner = (props: { className: string }) =>
  ttDate?.date ? (
    <div {...props}>
      <Typography variant="body2">
        Currently in time travel mode at {format(new Date(ttDate.date), 'M/d/yyyy, h:mm a')}
      </Typography>
    </div>
  ) : null;

const NotAuthorized = () => (
  <div style={{ padding: 36 }}>
    <ErrorMessage message="Not authorized to view this page." />
  </div>
);

const SameCustomerMessage = () => (
  <div style={{ padding: 36 }}>
    <ErrorMessage message="Notification management only allowed for same-customer users." />
  </div>
);

export const Root = () => {
  const isNavOpen = useSelector(getIsNavOpen);
  const doCloseNav = useAction(closeNav);
  const doToggleNav = useAction(toggleNav);
  const classes = useStyles();
  const theme = useTheme();

  const isMobilePortrait = useMediaQuery(
    `${theme.breakpoints.down('xs')} and (orientation: portrait) and (hover: none)`
  );
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down('sm')} and (orientation: landscape) and (hover: none)`
  );
  const isMobileScreen = isMobilePortrait || isMobileLandscape;
  const [showMobileDialog, setShowMobileDialog] = useState(
    sessionStorage.getItem('mobile-dialog') !== 'closed'
  );

  return (
    <div>
      <Switch>
        <Route path={PathPattern.ACTIVATE_ACCOUNT} exact>
          <ActivateAccount />
        </Route>

        <Route path={PathPattern.AUTH_CALLBACK} exact>
          <Protected>
            <Redirect to={PathBuilder.PORTFOLIO()} />
          </Protected>
        </Route>

        <Route path={PathPattern.MAIN} exact>
          <Redirect to={PathBuilder.PORTFOLIO()} />
        </Route>

        <Route path={PathPattern.PROTECTED}>
          <Protected>
            <WithProfile
              render={(profile: UserProfile) => (
                <Switch>
                  <Route path={PathPattern.DEFAULT_SITE} exact>
                    {profile.sites.length > 1 ? (
                      <Redirect to={PathBuilder.PORTFOLIO()} />
                    ) : (
                      <Redirect to={PathBuilder.SITE(profile.defaultSiteId)} />
                    )}
                  </Route>

                  <Route path={PathPattern.SITE_DOCUMENT_PATH} exact>
                    <SiteContext>
                      <SiteDocumentContainer />
                    </SiteContext>
                  </Route>

                  {ismVoult && (
                    <Route path={PathPattern.OPT_OUT}>
                      <OptOutPageContainer />
                    </Route>
                  )}

                  <Route path={PathPattern.PORTFOLO}>
                    {profile.sites.length === 1 ? (
                      <Redirect to={PathBuilder.SITE(profile.sites[0].siteId)} />
                    ) : (
                      <div className={classes.root}>
                        <main className={classes.content}>
                          <PortfolioToolbar profile={profile} />
                          <div className={classes.contentOuter}>
                            <PortfolioPageLoader />
                          </div>
                        </main>
                      </div>
                    )}
                  </Route>

                  <Route
                    path={PathPattern.SITE}
                    // @ts-ignore todo: fix type
                    render={(route: RouteComponentProps<SiteRouteMatch>) => {
                      // TODO: It may make sense to break this into another
                      // component in the future for now I think having the
                      // route definitions laid  out here is the most clear
                      const siteId = +route.match.params.siteId;
                      return (
                        <SiteContext>
                          <div className={classes.root}>
                            <MainNavigation
                              profile={profile}
                              isNavOpen={isNavOpen}
                              closeNav={doCloseNav}
                              toggleNav={doToggleNav}
                            />

                            <main className={classes.content}>
                              <TimeTravelBanner className={classes.timeTravel} />
                              <MainToolbar
                                profile={profile}
                                siteId={siteId}
                                toggleNav={doToggleNav}
                              />

                              <div className={classes.contentOuter}>
                                <div className={classes.contentInner}>
                                  <Switch>
                                    <Route path={PathPattern.SITE} exact>
                                      {ismVoult ? (
                                        <Redirect
                                          to={{
                                            pathname: PathBuilder.OPERATIONAL_SUMMARY(siteId),
                                          }}
                                        />
                                      ) : (
                                        <DashboardPageLoader />
                                      )}
                                    </Route>

                                    <Route path={PathPattern.ANALYSIS} exact>
                                      <AnalysisPageLoader />
                                    </Route>

                                    <Route path={PathPattern.ALARMS_DEFAULT}>
                                      <AlarmsPageLoader />
                                    </Route>

                                    <Route path={PathPattern.OPERATIONAL_SUMMARY} exact>
                                      <OpSummaryPageLoader />
                                    </Route>

                                    {featureFlags.enableControl && (
                                      <Route path={PathPattern.CONTROL_SUMMARY} exact>
                                        <ControlSummaryPageLoader />
                                      </Route>
                                    )}

                                    {featureFlags.enableReports && (
                                      <Route path={PathPattern.REPORT} exact>
                                        <AllowForRoles
                                          siteId={siteId}
                                          systemAdmin
                                          customerAdmin
                                          roles={[SiteRole.SITE_ADMIN]}
                                          renderMessage={NotAuthorized}
                                        >
                                          <ReportsPageLoader />
                                        </AllowForRoles>
                                      </Route>
                                    )}

                                    <Route path={PathPattern.EQUIPMENT} exact>
                                      <EquipmentPageLoader />
                                    </Route>

                                    <Route path={PathPattern.ALARM_NOTIFICATIONS} exact>
                                      <AllowForRoles
                                        siteId={siteId}
                                        systemAdmin
                                        customerAdmin
                                        roles={[SiteRole.SITE_ADMIN]}
                                        renderMessage={
                                          profile.isSystemAdmin
                                            ? SameCustomerMessage
                                            : NotAuthorized
                                        }
                                      >
                                        <AlertPageLoader />
                                      </AllowForRoles>
                                    </Route>

                                    <Route path={PathPattern.USER_MANAGEMENT} exact>
                                      <AllowForRoles
                                        siteId={siteId}
                                        systemAdmin
                                        customerAdmin
                                        installer
                                        roles={[SiteRole.SITE_ADMIN]}
                                        renderMessage={NotAuthorized}
                                      >
                                        <UserPageLoader />
                                      </AllowForRoles>
                                    </Route>

                                    {featureFlags.enableInstaller && (
                                      <Route path={PathPattern.INSTALLER} exact>
                                        <AllowForRoles
                                          siteId={siteId}
                                          systemAdmin
                                          customerAdmin
                                          installer
                                          renderMessage={NotAuthorized}
                                        >
                                          <InstallerPageLoader />
                                        </AllowForRoles>
                                      </Route>
                                    )}

                                    <Route>
                                      <Typography>Page Not Found</Typography>
                                    </Route>
                                  </Switch>
                                </div>
                              </div>
                            </main>
                          </div>
                          {isMobileScreen && ismVoult && (
                            <Dialog
                              classes={{ paper: classes.dialog }}
                              open={showMobileDialog}
                              transitionDuration={0}
                              fullScreen
                            >
                              <img src={mvoultLogo} style={{ width: 300 }} alt="Logo" />
                              <DialogTitle disableTypography>
                                <Typography variant="h5">
                                  Download the mVoult mobile app!
                                </Typography>
                              </DialogTitle>
                              <Typography gutterBottom>
                                The best mobile experience for mVoult users is through the mVoult
                                mobile app. Please download it using one of the links below.
                              </Typography>
                              {/* will be replaced by app store buttons */}
                              <DialogActions>
                                <div>
                                  <PrimaryButton>iPhone</PrimaryButton>
                                </div>
                                <div>
                                  <PrimaryButton>Android</PrimaryButton>
                                </div>
                              </DialogActions>
                              <DialogActions>
                                <Box marginTop={2}>
                                  <SecondaryButton
                                    onClick={() => {
                                      setShowMobileDialog(false);
                                      sessionStorage.setItem('mobile-dialog', 'closed');
                                    }}
                                  >
                                    Continue With Browser
                                  </SecondaryButton>
                                </Box>
                              </DialogActions>
                            </Dialog>
                          )}
                        </SiteContext>
                      );
                    }}
                  />

                  <Route>
                    <ErrorPage message={'Page not found'} />
                  </Route>
                </Switch>
              )}
            />
          </Protected>
        </Route>

        <Route>
          <ErrorPage message={'Page not found'} />
        </Route>
      </Switch>
    </div>
  );
};
