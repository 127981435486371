import PortfolioPage from './PortfolioPage';
import { useRequest, useUrlState, useParamSelector, useSubscription } from '../../../helpers/hooks';
import {
  getSiteSummaries,
  fetchPortfolioOptions,
  getFilterOptions,
  PortfolioState,
  stateToQuery,
  getTotalResults,
  urlParser,
  subPortfolio,
} from '../../../state/contexts/portfolioPage';
import LoadWithSpinner from '../../ui/Loading/LoadWithSpinner';
import { Lifecycle } from '../../../state/request';
import { getUserProfile } from '../../../state/entities';
import { SubscriptionLifecycle } from '../../../state/subscription';

const DEFAULT_SORT = 'Name';

const PortfolioPageLoader = () => {
  const profile = useParamSelector(getUserProfile)();

  // we want the current filter, sort, and page state to be persisted in the
  // url and not the store
  const [portfolioState, setPortfolioQueryState] = useUrlState<PortfolioState>(urlParser);

  // request data for page
  const query = stateToQuery(portfolioState);

  const siteSummaries = useParamSelector(getSiteSummaries)();
  const totalSites = useParamSelector(getTotalResults)();
  const filterOptions = useParamSelector(getFilterOptions)();

  const [, optionStatus] = useRequest(fetchPortfolioOptions.request());

  const queryStatus = useSubscription(true, subPortfolio, query);

  /* end hooks */

  const filters = {
    customers: portfolioState.customers || [],
    zipCodes: portfolioState.zipCodes || [],
    statuses: portfolioState.statuses || [],
    utilities: portfolioState.utilities || [],
    gridServicesEnrollmentStatuses: portfolioState.gridServicesEnrollmentStatuses || [],
  };

  const { page, sortBy, sortDir, textMatch } = portfolioState;
  const isAdmin = profile?.isSystemAdmin;

  if (!optionStatus || optionStatus === Lifecycle.PENDING) {
    return <LoadWithSpinner />;
  }

  return (
    <PortfolioPage
      filterOptions={filterOptions}
      siteSummaries={siteSummaries || []}
      activeFilters={filters}
      handleFilter={(newFilter) => {
        setPortfolioQueryState({
          ...(newFilter as Partial<PortfolioState>),
          page: 1,
        });
      }}
      handleSort={(key, direction) =>
        setPortfolioQueryState({
          sortBy: key,
          sortDir: direction,
          page: 1,
        })
      }
      handleSearch={(newSearch) =>
        setPortfolioQueryState({
          textMatch: newSearch !== '' ? newSearch : undefined,
          page: 1,
        })
      }
      handleChangePage={(newPage) => setPortfolioQueryState({ page: newPage })}
      activeSort={sortDir}
      activeSearch={textMatch}
      sortBy={sortBy || DEFAULT_SORT}
      activePage={page}
      totalSites={totalSites}
      loading={!queryStatus || queryStatus === SubscriptionLifecycle.STARTING}
      error={typeof queryStatus === 'object' ? queryStatus : undefined}
      isAdmin={isAdmin}
    />
  );
};

export default PortfolioPageLoader;
