import { Address } from './address';
import { InstallerFormProps } from '../components/modules/installer/InstallerPage.types';

export interface EquipmentTemplate {
  participantId: string;
  equipmentType: string;
  name: string;
  description?: string;
  manufacturer?: string;
  make?: string;
  model?: string;
  requiresAdditionalConfiguration?: boolean;
}

export enum PartnerSite {
  SolarEdge = 'SolarEdge',
  SolArk = 'Sol-Ark',
}

export interface SiteTemplate {
  sku: string;
  name: string;
  partnerId: PartnerSite;
  equipment: EquipmentTemplate[];
}

export interface InstallerProvisionData
  extends Omit<
      InstallerFormProps,
      'address' | 'image' | 'serialNumbers' | 'sku' | 'gridServicesInitialStatus'
    >,
    Omit<Address, 'address1' | 'address2'> {
  templateSKU: string;
  installationDate: string;
  address: string;
  customerId?: number;
  utilityName: string;
  inverterSerialNumbers?: string[];
}
