import React from 'react';
import * as d3 from 'd3';
import { ChartPane, chartContext } from '../../ui/chart/ChartPane';
import XAxis from '../../ui/chart/XAxis';
import YAxis from '../../ui/chart/YAxis';
import HorizontalGrid from '../../ui/chart/HorizontalGrid';
import Crosshairs from '../../ui/chart/Crosshairs';
import LineSeries from '../../ui/chart/LineSeries';
import { TimeRange, DataRange, ChartSeriesV2 } from '../../ui/chart/types';
import { difference, without, groupBy, Dictionary, sortedIndexBy } from 'lodash';
import { TimeSeries, NumericDataPoint } from '../../../models';
import { makeStyles, Theme, Link, Tooltip, useTheme, useMediaQuery } from '@material-ui/core';
import { ZoomBar } from '../../ui/chart/ZoomBar';
import { useState, useCallback, useMemo } from 'react';
import ClearIcon from '@material-ui/icons/Clear';

interface Props {
  height?: number;
  xRange: {
    start: Date;
    end: Date;
  };
  tagIds: string[];
  snapshots: Dictionary<TimeSeries>;
}

const getMin = (points: NumericDataPoint[]) => {
  return points.reduce((minSoFar, point) => {
    return Math.min(minSoFar, point.value === null ? Infinity : point.value);
  }, Infinity);
};

const getMax = (points: NumericDataPoint[]) => {
  return points.reduce((maxSoFar, point) => {
    return Math.max(maxSoFar, point.value === null ? -Infinity : point.value);
  }, -Infinity);
};

interface ChartTimeSeries extends TimeSeries {
  lineClass: string;
  legendClass: string;
}

const yLabelFormatter = d3.format('.6g');
const yAxisFormatter = d3.format('.3g');
const xAxisFormatter = d3.timeFormat('%b %d, %Y %H:%M:%S');

const colors: string[] = [
  '#332288',
  '#117733',
  '#44AA99',
  '#88CCEE',
  '#DDCC77',
  '#CC6677',
  '#AA4499',
  '#882255',
];

const useStyles = makeStyles((theme: Theme) => ({
  line1: {
    stroke: colors[0],
  },
  line2: {
    stroke: colors[1],
  },
  line3: {
    stroke: colors[2],
  },
  line4: {
    stroke: colors[3],
  },
  line5: {
    stroke: colors[4],
  },
  line6: {
    stroke: colors[5],
  },
  line7: {
    stroke: colors[6],
  },
  line8: {
    stroke: colors[7],
  },
  line9: {
    stroke: colors[8],
  },
  line10: {
    stroke: colors[9],
  },
  legend1: { backgroundColor: colors[0] },
  legend2: { backgroundColor: colors[1] },
  legend3: { backgroundColor: colors[2] },
  legend4: { backgroundColor: colors[3] },
  legend5: { backgroundColor: colors[4] },
  legend6: { backgroundColor: colors[5] },
  legend7: { backgroundColor: colors[6] },
  legend8: { backgroundColor: colors[7] },
  legend9: { backgroundColor: colors[8] },
  legend10: { backgroundColor: colors[9] },
  ellipLeft: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '200px',
    direction: 'rtl',
    textAlign: 'left',
  },
  legend: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginRight: 16,
    maxWidth: '22em',
    minWidth: '16em',
    marginBottom: 16,
    border: '1px solid #e0e0e0',
    borderRadius: 4,
  },
}));

const getDataGroups = (
  tagIds: string[],
  lineClasses: string[],
  timeSeries: Dictionary<TimeSeries>
): Dictionary<ChartSeriesV2[]> => {
  const groups = {};

  tagIds.forEach((tagId, i) => {
    const series = timeSeries[tagId];
    const unit = series.unit;

    groups[unit] = groups[unit] || [];

    groups[unit].push({
      key: series.tagId,
      values: series.points.filter((p) => p.value !== null).map((p) => ({ x: p.time, y: p.value })),
      styleClass: lineClasses[i],
    });
  });

  return groups;
};

/**
 * We want to calculate a range object for each separate unit on the chart
 */
const getRanges = (
  tagIds: string[],
  timeSeries: Dictionary<TimeSeries>,
  timeRange: TimeRange,
  paddingPer?: number
): DataRange[] => {
  const unitMins: Dictionary<number> = {};
  const unitMaxes: Dictionary<number> = {};

  tagIds.forEach((tagId) => {
    const series = timeSeries[tagId];
    const unit = series.unit;
    const seriesMin = getMin(series.points);
    const seriesMax = getMax(series.points);
    unitMins[unit] = Math.min(seriesMin, unitMins[unit] || Infinity);
    unitMaxes[unit] = Math.max(seriesMax, unitMaxes[unit] || -Infinity);
  });

  const ranges = Object.keys(unitMins).map((unit) => {
    return {
      xMin: timeRange.start,
      xMax: timeRange.end,
      yMin: unitMins[unit] === Infinity ? 0 : unitMins[unit],
      yMax: unitMaxes[unit] === -Infinity ? 0 : unitMaxes[unit],
    };
  });

  if (paddingPer) {
    ranges.forEach((range, i) => {
      const padding = (range.yMax - range.yMin || 1) * paddingPer;
      ranges[i] = {
        ...ranges[i],
        yMin: ranges[i].yMin - padding,
        yMax: ranges[i].yMax + padding,
      };
    });
  }
  return ranges;
};

const lookupNearest = (timeSeries: Dictionary<TimeSeries>, at: Date) => {
  const nearest: Dictionary<number | undefined> = {};

  const comp = (v: NumericDataPoint) => v.time;

  Object.keys(timeSeries).forEach((tagId) => {
    const series = timeSeries[tagId];
    const idx = sortedIndexBy(series.points, { time: at, value: 0, quality: '', id: '' }, comp);
    nearest[tagId] =
      series.points.length > 0 && series.points[idx] && series.points[idx].value !== null
        ? (series.points[idx].value as number)
        : undefined;
  });
  return nearest;
};

const margins = {
  top: 16,
  bottom: 26,
  left: 0,
  right: 0,
};

const Legend: React.FC<{
  snapshots: Dictionary<ChartTimeSeries>;
  nearest?: Dictionary<number | undefined>;
  setHighlighted: (tag: string | undefined) => void;
  toggleActive: (tags: string) => void;
  active: string[];
}> = (props) => {
  const { snapshots, nearest, setHighlighted, toggleActive, active } = props;
  const classes = useStyles();
  const unitGroups = groupBy(Object.values(snapshots), (s) => s.tag.unit);
  return (
    <div className={classes.legend}>
      {Object.keys(unitGroups).map((unit, i) => {
        return (
          <div key={i} className={classes.legendItem}>
            <div style={{ borderBottom: '1px solid #e0e0e0', padding: 4, textAlign: 'right' }}>
              {unit}
            </div>
            {unitGroups[unit].map((s, j) => {
              const isActive = active.includes(s.tagId);
              const value =
                nearest && nearest[s.tag.id] !== undefined
                  ? (nearest[s.tag.id] as number).toPrecision(5)
                  : '';
              const points = snapshots[s.tagId].points.length;

              return (
                <div
                  key={j}
                  style={{
                    display: 'inline-flex',
                    padding: 6,
                    alignItems: 'center',
                    opacity: isActive ? 1 : 0.7,
                  }}
                  onMouseEnter={() => setHighlighted(s.tagId)}
                  onMouseLeave={() => setHighlighted(undefined)}
                  onClick={() => toggleActive(s.tagId)}
                >
                  <div
                    className={s.legendClass}
                    style={{
                      width: 16,
                      height: 16,
                      marginRight: 4,
                      flexShrink: 0,
                      position: 'relative',
                    }}
                  >
                    {!isActive && (
                      <ClearIcon
                        style={{
                          height: 16,
                          width: 16,
                          color: '#fff',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          position: 'absolute',
                        }}
                      />
                    )}
                  </div>
                  <Tooltip title={`${points} data points.`}>
                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {s.tag.label || s.tag.id}{' '}
                    </span>
                  </Tooltip>

                  <span style={{ marginLeft: 'auto', paddingLeft: '1em' }}>
                    {value !== '' ? value : points === 0 ? 'no data' : ''}
                  </span>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

function toggleValue<E>(list: E[], value: E) {
  return list.includes(value) ? without(list, value) : [...list, value];
}

export const AnalysisChart: React.SFC<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallPortrait = useMediaQuery(
    `${theme.breakpoints.down('xs')} and (orientation: portrait)`
  );
  const isSmallLandscape = useMediaQuery(
    `${theme.breakpoints.down('sm')} and (orientation: landscape)`
  );
  const isSmallScreen = isSmallPortrait || isSmallLandscape;
  const { snapshots, xRange, tagIds } = props;

  const [activeXValue, setActiveXValue] = useState<Date | undefined>(undefined);
  const [zoomRange, setZoomRange] = useState<TimeRange | undefined>(undefined);
  const lineClasses = useMemo(
    () => [
      classes.line1,
      classes.line2,
      classes.line3,
      classes.line4,
      classes.line5,
      classes.line6,
      classes.line7,
      classes.line8,
      classes.line9,
      classes.line10,
    ],
    [classes]
  );
  const legendClasses = [
    classes.legend1,
    classes.legend2,
    classes.legend3,
    classes.legend4,
    classes.legend5,
    classes.legend6,
    classes.legend7,
    classes.legend8,
    classes.legend9,
    classes.legend10,
  ];

  const reversedTagIds = tagIds.slice().reverse();

  const lineGroups = useMemo(
    () => getDataGroups(reversedTagIds, lineClasses, snapshots),
    [lineClasses, reversedTagIds, snapshots]
  );
  const ranges = useMemo(
    () => getRanges(reversedTagIds, snapshots, zoomRange || xRange, 0.05),
    [reversedTagIds, snapshots, xRange, zoomRange]
  );
  const altRanges = useMemo(() => (ranges.length > 1 ? ranges.slice(1) : undefined), [ranges]);

  const fullRanges = useMemo(
    () => getRanges(reversedTagIds, snapshots, xRange, 0.05),
    [reversedTagIds, snapshots, xRange]
  );

  const handleMouseMove = useCallback((pos: { x: Date; y: number }) => setActiveXValue(pos.x), []);
  const handleMouseLeave = useCallback(() => setActiveXValue(undefined), []);

  const nearest = activeXValue ? lookupNearest(snapshots, activeXValue) : undefined;

  const chartSnapshots: Dictionary<ChartTimeSeries> = {};
  reversedTagIds.forEach((tagId, i) => {
    chartSnapshots[tagId] = {
      ...snapshots[tagId],
      lineClass: lineClasses[i],
      legendClass: legendClasses[i],
    };
  });

  const [inactiveLines, setInactiveLines] = useState<string[]>([]);
  const activeLines = difference(tagIds, inactiveLines);
  const setHighlighted = useState<string | undefined>()[1];

  return (
    <div style={{ width: '100%', boxSizing: 'border-box' }}>
      <div style={{ margin: '16px 0' }}>
        <ChartPane
          handleMouseMove={handleMouseMove}
          handleMouseLeave={handleMouseLeave}
          height={props.height || 240}
          range={ranges[0]}
          altRanges={altRanges}
          margins={margins}
        >
          <chartContext.Consumer>
            {(context) => (
              <>
                <HorizontalGrid
                  size={context.size}
                  yScale={context.yScale}
                  marginLeft={context.margins.left}
                  marginRight={context.margins.right}
                  tickCount={5}
                />
                {Object.values(lineGroups).map((group, i) => {
                  const yScale =
                    i === 0 ? context.yScale : context.altYScales && context.altYScales[i - 1];
                  const activeGroups = group.filter((g) => activeLines.includes(g.key));

                  return activeGroups.length > 0 ? (
                    <LineSeries
                      key={i}
                      xScale={context.xScale}
                      size={context.size}
                      margins={context.margins}
                      yScale={yScale}
                      data={activeGroups || []}
                    />
                  ) : null;
                })}

                <XAxis {...context} inset={true} offset={-1} />

                {/* Allow for multiple y axis */}
                {Object.keys(lineGroups).map((unit, i) => {
                  const offset =
                    i === 0 ? context.margins.left : context.size.width - (i - 1) * 42 - 1;
                  const yScale =
                    i === 0 ? context.yScale : context.altYScales && context.altYScales[i - 1];
                  if (!yScale) {
                    return null;
                  }
                  return (
                    <YAxis
                      key={unit}
                      unit={unit}
                      yScale={yScale}
                      size={context.size}
                      inset={i === 0}
                      offset={offset}
                      format={yAxisFormatter || ((d) => `${d}`)}
                    />
                  );
                })}

                {activeXValue && context.inRange(context.mouseX, context.mouseY) ? (
                  <Crosshairs
                    size={context.size}
                    targetPosition={{ x: context.xScale(activeXValue) ?? 0, y: context.mouseY }}
                    margins={{ ...context.margins, bottom: 0 }}
                    bottomValue={xAxisFormatter && xAxisFormatter(activeXValue)}
                    leftValue={
                      yLabelFormatter && yLabelFormatter(context.yScale.invert(context.mouseY))
                    }
                  />
                ) : null}
                {!isSmallScreen ? (
                  <ZoomBar
                    size={context.size}
                    xScale={(() => {
                      const scale = zoomRange
                        ? d3
                            .scaleTime()
                            .domain([zoomRange.start, zoomRange.end])
                            .range([
                              0 + (context.margins.left || 0),
                              context.size.width - (context.margins.right || 0),
                            ])
                        : context.xScale;
                      return scale;
                    })()}
                    onZoomEnd={(newZoomRange) => setZoomRange(newZoomRange)}
                  />
                ) : null}
              </>
            )}
          </chartContext.Consumer>
        </ChartPane>
        <ChartPane
          height={60}
          range={fullRanges[0]}
          altRanges={fullRanges.length > 1 ? fullRanges.slice(1) : undefined}
          style={{ backgroundColor: '#f0f0f0' }}
          margins={{
            top: 8,
            bottom: 8,
            left: 0,
            right: 0,
          }}
        >
          <chartContext.Consumer>
            {(context) => (
              <>
                {Object.values(lineGroups).map((group, i) => {
                  const yScale =
                    i === 0 ? context.yScale : context.altYScales && context.altYScales[i - 1];
                  return yScale ? (
                    <LineSeries key={i} {...context} yScale={yScale} data={group || []} />
                  ) : null;
                })}

                <XAxis {...context} inset={true} offset={-1} />

                <ZoomBar
                  {...context}
                  zoomRange={zoomRange}
                  onZoom={(newZoomRange) => setZoomRange(newZoomRange)}
                />
              </>
            )}
          </chartContext.Consumer>
        </ChartPane>

        <div style={{ height: 16, textAlign: 'right' }}>
          {zoomRange && (
            <Link onClick={() => setZoomRange(undefined)} style={{ cursor: 'pointer' }}>
              Clear Zoom
            </Link>
          )}
        </div>
      </div>
      <Legend
        snapshots={chartSnapshots}
        nearest={nearest}
        setHighlighted={(tagId) => setHighlighted(tagId)}
        toggleActive={(tagId) => setInactiveLines(toggleValue(inactiveLines, tagId))}
        active={activeLines}
      />
    </div>
  );
};
