import React from 'react';
import {
  Site,
  DataPoint,
  TimeSeries,
  findTagWithAttr,
  TagAttribute,
  getSummariesForEntity,
  AlarmSummary,
  getSiteTags,
} from '../../../models';
import { Grid, CircularProgress, Link as StyledLink, Typography } from '@material-ui/core';
import { flatMap, sortBy, Dictionary, keyBy } from 'lodash';
import { EquipmentTile } from './EquipmentTile';
import { OpSummaryChart } from './OpSummaryChart';
import {
  getHistoricalTagIds,
  getForecastTagIds,
  getRateTagIds,
  OpSummaryChartSourceKey,
} from './config';
import RadioToggle from '../../ui/controls/RadioToggle';
import { useState, useRef, useLayoutEffect } from 'react';
import { imageCache } from '../../../helpers/ImageCache';
import { SubscriptionStatus, SubscriptionLifecycle } from '../../../state/subscription';
import { PathBuilder } from '../../../routing';
import { Link } from 'react-router-dom';
import RateSwitcher from './RateSwitcher';

export interface OpSummaryPageProps {
  site: Site;
  latestSnapshots: Dictionary<DataPoint | undefined>;
  chartData: Dictionary<TimeSeries>;
  activeAlarms: AlarmSummary[];
  start: Date;
  end: Date;
  equipmentDataStatus: SubscriptionStatus;
  chartDataStatus: SubscriptionStatus;
}

enum Tabs {
  CHART,
  IMAGE,
}

const SingleLineImage = (props: { url: string }) => {
  const { url } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const imageEl = imageCache.getImage(url);
    if (imageEl && wrapperRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const imageToUse: HTMLDivElement = imageEl.cloneNode() as any;
      imageToUse.style.width = '100%';
      imageToUse.style.maxWidth = '960px';
      while (wrapperRef.current.firstChild) {
        wrapperRef.current.firstChild.remove();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      wrapperRef.current.appendChild(imageToUse as any);
    }
  }, [url]);

  return <div ref={wrapperRef} />;
};

export const OpSummaryPage: React.FC<OpSummaryPageProps> = (props) => {
  const { site, end, chartData, activeAlarms, equipmentDataStatus, chartDataStatus } = props;
  const tags = keyBy(getSiteTags(site), (tag) => tag.id);

  const tagsMap = {
    ...getHistoricalTagIds(tags),
    ...getForecastTagIds(tags),
    ...getRateTagIds(tags),
  } as Record<OpSummaryChartSourceKey, string | undefined>;

  const equipment = flatMap(site.nodes, (node) => node.equipment).filter((e) =>
    Boolean(findTagWithAttr(e, TagAttribute.Primary))
  );
  const sortedEquipment = sortBy(equipment, [(e) => e.tileOrder, (e) => e.label]);

  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.CHART);

  const [showRateSwitcher, setShowRateSwitcher] = useState<boolean>(false);
  const toggleRateSwitcherDisplay = (): boolean => {
    setShowRateSwitcher(!showRateSwitcher);
    return showRateSwitcher;
  };

  const getTagIdFromKey = (key: OpSummaryChartSourceKey) => tagsMap[key];
  const consumptionRateTag = getTagIdFromKey('ConsumptionRate');
  const hasRateTag = Boolean(consumptionRateTag);
  const hideRates = site.webPortalLayoutJson.operationalSummary?.hideRateChart || !hasRateTag;

  const isEquipmentLoading =
    equipmentDataStatus === undefined || equipmentDataStatus === SubscriptionLifecycle.STARTING;
  const isLoading =
    isEquipmentLoading ||
    chartDataStatus === undefined ||
    chartDataStatus === SubscriptionLifecycle.STARTING;

  const isError = typeof chartDataStatus === 'object' || typeof equipmentDataStatus === 'object';

  return (
    <div data-testid="op-summary-page" style={{ padding: 16 }}>
      <Grid container alignItems="center" style={{ marginBottom: 16 }}>
        <Grid item>
          {site.singleLineImageFileLink && (
            <RadioToggle
              items={[
                {
                  value: Tabs.CHART,
                  label: 'Chart',
                },
                {
                  value: Tabs.IMAGE,
                  label: 'Operator View',
                },
              ]}
              activeValue={activeTab}
              handleSelectValue={() => {
                setActiveTab((prevState) => (prevState === Tabs.CHART ? Tabs.IMAGE : Tabs.CHART));
              }}
            />
          )}
        </Grid>
        {isLoading && (
          <Grid item style={{ padding: '0 24px', height: '24px' }}>
            <CircularProgress style={{ height: 24, width: 24 }} />
          </Grid>
        )}
        {isError && (
          <Grid item style={{ padding: '0 24px' }}>
            <Typography color="error">Error encountered loading data.</Typography>
          </Grid>
        )}
      </Grid>
      <div style={{ marginBottom: 24 }}>
        {activeTab === Tabs.CHART ? (
          <>
            <OpSummaryChart
              height={400}
              tagsMap={tagsMap}
              focusDate={end}
              snapshots={chartData}
              hideRates={hideRates}
              toggleRateSwitcherDisplay={toggleRateSwitcherDisplay}
              ismVoult={site.isMVoult}
            />
            <RateSwitcher
              isDisplayed={showRateSwitcher}
              toggleDisplay={toggleRateSwitcherDisplay}
              postalCode={site.postalCode}
              initialFormValues={{
                siteId: site.id,
                utilityId: site.utilityId,
                rateScheduleId: Number(consumptionRateTag?.split('.').slice(-1)[0]) ?? null,
              }}
            />
          </>
        ) : (
          site.singleLineImageFileLink && <SingleLineImage url={site.singleLineImageFileLink} />
        )}
      </div>
      <Grid data-testid="equipment-tiles" container item spacing={3}>
        {sortedEquipment.map((e) => {
          return (
            <Grid
              key={e.id}
              item
              container
              lg={3}
              md={4}
              sm={6}
              xs={12}
              style={{ opacity: isEquipmentLoading ? 0.75 : 1 }}
            >
              <EquipmentTile
                siteId={site.id}
                equipment={e}
                latestSnapshots={props.latestSnapshots}
                equipmentAlarms={getSummariesForEntity(activeAlarms, e)}
              >
                <div style={{ marginLeft: 'auto' }}>
                  <StyledLink component={Link} to={PathBuilder.EQUIPMENT(site.id, [], e.id)}>
                    More
                  </StyledLink>
                </div>
              </EquipmentTile>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
