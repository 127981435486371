import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { TimeSeries } from '../../../models';
import { OpSummaryChartSourceKey } from './config';
import { Dictionary } from 'lodash';

interface DataSourcesProps {
  isDisplayedInitialValue: boolean;
  toggleDisplay(): boolean;
  tagsMap: Record<OpSummaryChartSourceKey, string | undefined>;
  snapshots: Dictionary<TimeSeries>;
  getTagIdFromKey(key: OpSummaryChartSourceKey): string | undefined;
}

const DataSources = (props: DataSourcesProps): JSX.Element => {
  let isDisplayed = props.isDisplayedInitialValue;

  const handleClose = () => {
    isDisplayed = props.toggleDisplay();
  };

  return (
    <Dialog open={isDisplayed} maxWidth={'md'} fullWidth>
      <DialogTitle>Data Sources</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Metric</b>
              </TableCell>
              <TableCell>Tag Id</TableCell>
              <TableCell>Points</TableCell>
            </TableRow>
            {Object.keys(props.tagsMap).map((key) => {
              const tagId = props.getTagIdFromKey(key as OpSummaryChartSourceKey);
              return (
                <TableRow key={key}>
                  <TableCell>
                    <b>{key}</b>
                  </TableCell>
                  <TableCell>{tagId}</TableCell>
                  <TableCell>{tagId && props.snapshots[tagId]?.points.length}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DataSources;
