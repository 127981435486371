import React from 'react';
import { TextField, MenuItem, Checkbox, ListItemText, makeStyles } from '@material-ui/core';

export interface TableColumnsFilterProps {
  isAdmin?: boolean;
  activeColumns: string[];
  handleChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: string[];
    }>
  ) => void;
  options: { value: string; label: string }[];
}

const TableColumnsFilter = (props: TableColumnsFilterProps) => {
  const classes = useStyles();
  const { isAdmin, activeColumns, handleChange, options } = props;

  const filteredOptions = !isAdmin ? options.filter((o) => o.label !== 'Customer') : options;

  return (
    <TextField
      size="small"
      select
      variant="outlined"
      SelectProps={{
        displayEmpty: true,
        multiple: true,
        value: activeColumns,
        // @ts-ignore todo: fix type
        onChange: handleChange,
        renderValue: () => 'Columns',
      }}
      InputProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      {filteredOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox checked={activeColumns.indexOf(option.value) > -1} />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </TextField>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF',
    fontSize: '0.875rem',
  },
}));

export default TableColumnsFilter;
