import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableHead,
  Hidden,
  Grid,
} from '@material-ui/core';
import { range, sum, isUndefined, isNull } from 'lodash';
import { ProjectionDataset, TOUDataset } from '../../../models';
import { formatNumber } from '../../../helpers/formatting';

const styles = (theme: Theme) =>
  createStyles({
    legendWrap: {
      marginBottom: 24,
    },
    rateWrap: {
      marginBottom: 24,
      marginLeft: 4,
      minWidth: 100,
    },
    comparisonWrap: {
      marginBottom: 24,
      marginLeft: 4,
    },
    comparisonWrapSingle: {
      marginBottom: 24,
      marginLeft: 4,
    },
    projectionWrap: {
      marginBottom: 24,
    },
    table: {
      borderCollapse: 'collapse',
      width: 'auto',
    },
    tableBody: {
      borderRight: '1px solid #ccc',
      borderLeft: '1px solid #ccc',
    },
    tableRow: {
      height: 30,
      '&:last-child': {
        backgroundColor: '#F7F7F7',
      },
    },
    totalsRow: {
      height: 30,
      backgroundColor: '#F7F7F7',
    },
    rateTableRow: {
      height: 30,
    },
    emptyRow: {
      height: 30,
    },
    tableHeadRow: {
      height: 50,
    },
    tableCell: {
      whiteSpace: 'nowrap',
      padding: '4px 12px',
      textAlign: 'right',
      minWidth: 104,
      fontSize: 12,
      borderTop: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
      borderSpacing: 0,
      '&:first-child': {
        paddingLeft: 16,
      },
      '&:last-child': {
        paddingRight: 16,
      },
    },
    titleCell: {
      whiteSpace: 'normal',
      padding: '0 8px',
      fontSize: 12,
      fontWeight: 'bold',
      textAlign: 'center',
      '&:last-child': {
        paddingRight: 8,
      },
    },
    emptyCell: {
      borderTop: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
      padding: '4px 8px',
    },
    cellValue: {
      color: '#000',
    },
    cellUnitLeft: {
      marginRight: 'auto',
    },
    cellUnitRight: {
      marginLeft: 8,
    },
    mutedCellValue: {
      opacity: 0.5,
    },
    mutedCellUnit: {
      opacity: 0.5,
    },
  });

interface CellValueProps {
  style?: React.CSSProperties;
  muted?: boolean;
  value: number | string | null | undefined;
  unit: string;
  fractionDigits?: number;
  unitAlign: 'left' | 'right';
}

const CellValue = withStyles(styles)((props: CellValueProps & WithStyles<typeof styles>) => {
  let { classes, value, unit, unitAlign, style, fractionDigits } = props;
  fractionDigits = fractionDigits === undefined ? 2 : fractionDigits;
  if (value === undefined) {
    return null;
  }
  if (value === null) {
    return (
      <span style={{ ...style, display: 'flex', justifyContent: 'flex-end' }}>
        {unitAlign === 'left' && (
          <span
            className={[classes.cellUnitLeft, props.muted && classes.mutedCellUnit].join(' ')}
          />
        )}

        <span className={[classes.cellValue, props.muted && classes.mutedCellValue].join(' ')}>
          -
        </span>

        {unitAlign === 'right' && (
          <span
            className={[classes.cellUnitRight, props.muted && classes.mutedCellUnit].join(' ')}
          />
        )}
      </span>
    );
  }
  return (
    <span style={{ ...style, display: 'flex', justifyContent: 'flex-end' }}>
      {unitAlign === 'left' && (
        <span className={[classes.cellUnitLeft, props.muted && classes.mutedCellUnit].join(' ')}>
          {unit}
        </span>
      )}

      <span className={[classes.cellValue, props.muted && classes.mutedCellValue].join(' ')}>
        {typeof value === 'string' ? value : formatNumber(value, fractionDigits)}
      </span>

      {unitAlign === 'right' && (
        <span className={[classes.cellUnitRight, props.muted && classes.mutedCellUnit].join(' ')}>
          {unit}
        </span>
      )}
    </span>
  );
});

interface LegendColumnProps {
  rowLabels: string[];
  rowBg: string[];
}

const LegendColumn = withStyles(styles)((props: LegendColumnProps & WithStyles<typeof styles>) => {
  let { classes, rowLabels, rowBg } = props;
  classes = classes || {};
  const rowCount = Math.max(rowLabels.length, rowBg.length);
  return (
    <div className={classes.legendWrap}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell className={classes.titleCell} />
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {range(rowCount).map((idx) => (
            <TableRow key={rowLabels[idx]} className={classes.tableRow}>
              <TableCell
                style={{ backgroundColor: rowBg[idx] }}
                className={[classes.tableCell].join(' ')}
              >
                {rowLabels[idx] && rowLabels[idx]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
});

interface RateTableProps {
  title: string;
  unit: string;
  rowData: (number | null | undefined)[];
}

const RateTable = withStyles(styles)((props: RateTableProps & WithStyles<typeof styles>) => {
  let { classes, unit, rowData, title } = props;
  classes = classes || {};
  return (
    <div className={classes.rateWrap}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell className={classes.titleCell}>{title}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {rowData.map((rowValue, i) => (
            <TableRow key={`${i}`} className={classes.rateTableRow}>
              <TableCell className={classes.tableCell}>
                <CellValue
                  fractionDigits={5}
                  classes={classes}
                  value={rowValue}
                  unit={unit}
                  unitAlign={'right'}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
});

const nullOrValue = (n: number | undefined) => {
  if (isUndefined(n)) {
    return null;
  } else {
    return n ? n : 0;
  }
};

const nullZeroValues = (n: number | undefined) => {
  if (isUndefined(n) || n === 0) {
    return null;
  } else {
    return n ? n : 0;
  }
};

interface CompTableProps {
  title: string;
  unit: string;
  unitAlign: 'left' | 'right';
  primaryRowData: (string | number | null)[];
  secondaryRowData?: (string | number | null)[];
}

const CompTable = withStyles(styles)((props: CompTableProps & WithStyles<typeof styles>) => {
  let { classes, unit, unitAlign, title, primaryRowData, secondaryRowData } = props;
  classes = classes || {};
  const rowCount = secondaryRowData
    ? Math.max(primaryRowData.length, secondaryRowData.length)
    : primaryRowData.length;
  const colSpan = secondaryRowData ? 2 : 1;
  const wrapClass = secondaryRowData ? classes.comparisonWrap : classes.comparisonWrapSingle;
  return (
    <div className={wrapClass}>
      <Table className={classes.table} style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell colSpan={colSpan} className={classes.titleCell}>
              {title}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {range(rowCount).map((rowIdx) => {
            const isEmpty = isNull(primaryRowData[rowIdx]) && isNull(primaryRowData[rowIdx]);
            const isLast = rowIdx === rowCount - 1;
            if (isEmpty && isLast) {
              return null;
            }
            return (
              <TableRow
                key={rowIdx}
                className={isLast && !isEmpty ? classes.totalsRow : classes.emptyRow}
              >
                {secondaryRowData && (
                  <TableCell className={classes.tableCell} style={{ width: '50%' }}>
                    <CellValue
                      muted={true}
                      unit={unit}
                      unitAlign={unitAlign}
                      value={secondaryRowData[rowIdx]}
                    />
                  </TableCell>
                )}
                <TableCell className={classes.tableCell} style={{ width: '50%' }}>
                  <CellValue unit={unit} unitAlign={unitAlign} value={primaryRowData[rowIdx]} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
});

interface ProjectionRowProps {
  style?: React.CSSProperties;
  title: string;
  baseline: number | undefined;
  actual: number | undefined;
  bgColor?: string;
}

const ProjectionRow = withStyles(styles)(
  (props: ProjectionRowProps & WithStyles<typeof styles>) => {
    const { classes, baseline, actual, title, bgColor } = props;
    return (
      <TableRow className={classes.tableRow} style={{ ...props.style, backgroundColor: bgColor }}>
        <th className={classes.tableCell}>{title}</th>
        <TableCell className={classes.tableCell}>
          <CellValue
            muted
            unit={'$'}
            fractionDigits={0}
            unitAlign={'left'}
            value={nullOrValue(baseline)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CellValue unit={'$'} fractionDigits={0} unitAlign={'left'} value={nullOrValue(actual)} />
        </TableCell>
      </TableRow>
    );
  }
);

export interface TOUStyle {
  name: string;
  fillColor?: string;
}

interface Props {
  style?: React.CSSProperties;
  touStyles: TOUStyle[];
  touDatasets: TOUDataset[];
  costProjections: ProjectionDataset;
}

type CostTableProps = Props & WithStyles<typeof styles>;

const CostTable: React.FC<CostTableProps> = (props) => {
  const { touStyles, touDatasets, costProjections, classes } = props;

  const rateDatasets = touDatasets.slice(0, touDatasets.length - 1);

  const baselineProjectedCostTotal = sum([
    costProjections.energyChargesBaseline,
    costProjections.nemCreditsBaseline,
    costProjections.demandCostBaseline,
  ]);
  const actualProjectedCostTotal = sum([
    costProjections.energyCharges,
    costProjections.nemCredits,
    costProjections.demandCost,
  ]);

  return (
    <div style={props.style}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Grid container>
          <Grid item container style={{ width: 'auto', marginRight: 24 }}>
            <Grid item style={{ display: 'flex', marginRight: 4 }}>
              <LegendColumn
                rowLabels={touDatasets.map((d) => d.name)}
                rowBg={touStyles.map((d) => d.fillColor || '')}
              />

              <RateTable
                title={'Cons. Rate'}
                rowData={rateDatasets.map((d) => nullZeroValues(d.consumptionRate))}
                unit={'$/kWh'}
              />

              <CompTable
                title={'Daily Energy Charges'}
                secondaryRowData={touDatasets.map((d) => nullOrValue(d.consumptionChargeBaseline))}
                primaryRowData={touDatasets.map((d) => nullOrValue(d.consumptionCharge))}
                unit={'$'}
                unitAlign={'left'}
              />
            </Grid>
            <Grid item style={{ display: 'flex', marginRight: 4 }}>
              <Hidden lgUp>
                <LegendColumn
                  rowLabels={touDatasets.map((d) => d.name)}
                  rowBg={touStyles.map((d) => d.fillColor || '')}
                />
              </Hidden>
              <RateTable
                title={'NEM Rate'}
                rowData={rateDatasets.map((d) => nullZeroValues(d.nemRate))}
                unit="$/kWh"
              />

              <CompTable
                title={'Daily NEM Credits'}
                primaryRowData={touDatasets.map((d) => nullOrValue(d.nemCredit))}
                unit={'$'}
                unitAlign={'left'}
              />
            </Grid>
            <Grid item style={{ display: 'flex', marginRight: 4 }}>
              <Hidden lgUp>
                <LegendColumn
                  rowLabels={touDatasets.map((d) => d.name)}
                  rowBg={touStyles.map((d) => d.fillColor || '')}
                />
              </Hidden>

              <RateTable
                title={'Dem. Rate'}
                rowData={rateDatasets.map((d) => nullZeroValues(d.demandRate))}
                unit="$/kW"
              />

              <CompTable
                title={'Peak Demands'}
                secondaryRowData={touDatasets.map((d) => nullOrValue(d.peakDemandBaseline))}
                primaryRowData={touDatasets.map((d) => nullOrValue(d.peakDemand))}
                unit={'kW'}
                unitAlign={'right'}
              />
            </Grid>
          </Grid>

          {/* Monthly projections */}
          <Grid style={{ flex: 0 }}>
            <div className={classes.projectionWrap}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell colSpan={3} className={classes.titleCell}>
                      {'Monthly Cost Projections'}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ProjectionRow
                    style={{ borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc' }}
                    title="Energy Charges:"
                    baseline={props.costProjections.energyChargesBaseline}
                    actual={props.costProjections.energyCharges}
                  />

                  <ProjectionRow
                    style={{ borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc' }}
                    title="NEM Credits:"
                    baseline={props.costProjections.nemCreditsBaseline}
                    actual={props.costProjections.nemCredits}
                  />

                  <ProjectionRow
                    style={{ borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc' }}
                    title="Demand ($):"
                    baseline={props.costProjections.demandCostBaseline}
                    actual={props.costProjections.demandCost}
                  />

                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.emptyCell} />
                    <TableCell className={classes.emptyCell} />
                  </TableRow>

                  <ProjectionRow
                    style={{ borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc' }}
                    bgColor={'#F7F7F7'}
                    title="Totals:"
                    baseline={baselineProjectedCostTotal}
                    actual={actualProjectedCostTotal}
                  />

                  <TableRow>
                    <TableCell className={classes.emptyCell} style={{ borderBottom: 'none' }} />
                    <TableCell className={classes.tableCell} style={{ fontWeight: 'bold' }}>
                      {'Savings:'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <CellValue
                        style={{ fontWeight: 'bold' }}
                        classes={classes}
                        unit={'$'}
                        unitAlign={'left'}
                        fractionDigits={0}
                        value={baselineProjectedCostTotal - actualProjectedCostTotal}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Grid>
        </Grid>
      </div>

      <div>
        <Typography variant="caption" className={classes.cellValue}>
          <span className={classes.mutedCellValue}>* Without mPulse</span> * With mPulse
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(CostTable);
