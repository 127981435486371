import { Route } from 'react-router';
import {
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  TypographyProps,
  TypographyVariant,
} from '@material-ui/core';
import { PathPattern } from '../../../routing';
import { featureFlags } from '../../../config/featureFlags';
import { TooltipIfEllipsis } from '../../ui/TooltipIfEllipsis';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
    paddingRight: theme.spacing(1),
    width: '100%',
  },
  title: {
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 300,
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.15,
    },
  },
}));

export interface PageTitleProps {
  siteName?: string;
}

export const PageTitle = ({ siteName }: PageTitleProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!siteName) {
    return <div className={classes.root} />;
  }

  const titleProps: TypographyProps & { component: string } = {
    className: classes.title,
    color: 'inherit',
    component: 'h1',
    noWrap: true,
  };

  const secondary = <SecondaryTitle />;

  return (
    <div className={classes.root}>
      {!isSmallScreen && (
        <TooltipIfEllipsis
          title={
            <>
              {siteName}&nbsp;{secondary}
            </>
          }
        >
          <Typography variant={getTitleVariant(siteName)} {...titleProps}>
            {siteName}&nbsp;{secondary}
          </Typography>
        </TooltipIfEllipsis>
      )}
      {isSmallScreen && (
        <>
          <TooltipIfEllipsis title={siteName}>
            <Typography variant="h6" {...titleProps}>
              {siteName}&nbsp;
            </Typography>
          </TooltipIfEllipsis>
          <TooltipIfEllipsis title={secondary}>
            <Typography variant="subtitle1" {...titleProps}>
              {secondary}
            </Typography>
          </TooltipIfEllipsis>
        </>
      )}
    </div>
  );
};

function getTitleVariant(siteName: string): TypographyVariant {
  // todo: find a more accurate way to size title
  if (siteName.length > 35) {
    return 'h6';
  } else if (siteName.length > 15) {
    return 'h5';
  }
  return 'h4';
}

const SecondaryTitle = () => (
  <>
    <Route path={PathPattern.ANALYSIS} exact>
      / Analysis
    </Route>
    <Route path={PathPattern.ALARMS_DEFAULT} exact>
      / Alarms
    </Route>
    <Route path={PathPattern.OPERATIONAL_SUMMARY} exact>
      / Operational Summary
    </Route>
    {featureFlags.enableControl && (
      <Route path={PathPattern.CONTROL_SUMMARY} exact>
        / Control Summary
      </Route>
    )}
    {featureFlags.enableReports && (
      <Route path={PathPattern.REPORT} exact>
        / Report
      </Route>
    )}
    <Route path={PathPattern.USER_MANAGEMENT} exact>
      / User Management
    </Route>
    <Route path={PathPattern.ALARM_NOTIFICATIONS} exact>
      / Notifications
    </Route>
    <Route path={PathPattern.EQUIPMENT} exact>
      / Equipment
    </Route>
    {featureFlags.enableInstaller && (
      <Route path={PathPattern.INSTALLER} exact>
        / Installer Portal
      </Route>
    )}
  </>
);
