import React from 'react';
import { createStyles, Theme, withStyles, WithStyles, Typography } from '@material-ui/core';

interface Props {
  style?: React.CSSProperties;
  text: string;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 16,
      fontWeight: 'bold',
    },
  });

type AllProps = Props & WithStyles<typeof styles>;

const PageTitle: React.SFC<AllProps> = (props) => {
  return (
    <Typography style={props.style} variant="h5" className={props.classes.root}>
      {props.text}
    </Typography>
  );
};

export default withStyles(styles)(PageTitle);
