import { createApiActions } from '../../../helpers/actions';
import { InstallerProvisionData, SiteTemplate, Utility } from '../../../models';

export const fetchSiteTemplates = createApiActions<SiteTemplate[]>()(
  'installer/fetchSiteTemplates',
  'installer/fetchSiteTemplatesSuccess',
  'installer/fetchSiteTemplatesError',
  (search: string) => ({ search })
);

export const fetchUtilities = createApiActions<Utility[]>()(
  'installer/fetchUtilities',
  'installer/fetchUtilitiesSuccess',
  'installer/fetchUtilitiesError',
  (postalCode: string | number) => ({ postalCode })
);

export const provisionSite = createApiActions<boolean>()(
  'installer/provisionSite',
  'installer/provisionSiteSuccess',
  'installer/provisionSiteError',
  (formData: InstallerProvisionData, siteTemplate: SiteTemplate) => ({ formData, siteTemplate })
);
