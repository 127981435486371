import React from 'react';
import {
  withStyles,
  WithStyles,
  Theme,
  Typography,
  Grid,
  TextField,
  MenuItem,
  createStyles,
} from '@material-ui/core';
import {
  Site,
  ExportReportRequest,
  isDisplayTag,
  getSiteEquipment,
  TagType,
} from '../../../models';
import RecipientListSelect from './RecipientListSelect';
import { TagTree } from '../site/TagTree';
import { PrimaryButton } from '../../ui/Buttons';
import { addDays, format } from 'date-fns';

const styles = (theme: Theme) =>
  createStyles({
    selectedTagsContainer: {
      minHeight: 160,
      maxHeight: 160,
      overflowY: 'auto',
      backgroundColor: theme.palette.grey[100],
      padding: 6,
    },
  });

const TIME_ALIGNMENT_OPTIONS = [1, 5, 15, 30, 60];
export interface Props {
  siteId: number;
  onSubmit: (values: ExportReportRequest) => void;
  site: Site;
  users: string[];
  submitCounter: number;
}

interface State {
  historicalTS?: string;
  modernTS?: string;
  recipients: string[];
  tags: string[];
  timeAlignMinutes?: number;
  tagSearchValue: string;
  needsTimeAlignment: boolean;
}

type AllProps = Props & WithStyles<typeof styles>;

const getDefaultState = (): State => {
  return {
    historicalTS: format(addDays(new Date(), -2), 'yyyy-MM-dd'),
    modernTS: format(new Date(), 'yyyy-MM-dd'),
    recipients: [],
    tags: [],
    timeAlignMinutes: undefined,
    tagSearchValue: '',
    needsTimeAlignment: false,
  };
};

class ExportConfigurationForm extends React.Component<AllProps, State> {
  state: State = getDefaultState();

  componentDidUpdate(prevProps: Props) {
    if (prevProps.submitCounter !== this.props.submitCounter) {
      const { historicalTS, modernTS, ...newState } = getDefaultState();
      this.setState(newState);
    }
  }

  handleAddRecipient = (newRecipients: string[]) => {
    this.setState({ recipients: newRecipients });
  };

  handleRemoveRecipient = (emailAddress: string) => {
    const { recipients } = this.state;
    const newListOfRecipients = [...recipients.filter((email) => email !== emailAddress)];
    this.setState({ recipients: newListOfRecipients });
  };

  handleChangeHistoricalTS = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ historicalTS: event.currentTarget.value });
  };

  handleChangeModernTS = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ modernTS: event.currentTarget.value });
  };

  handleTimeAlignChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ timeAlignMinutes: value === '' ? undefined : parseInt(value, 10) });
  };

  handleSelectTag = (tagId: string) => {
    const { tags, timeAlignMinutes } = this.state;
    let newTags: string[] = [];
    if (tags.includes(tagId)) {
      newTags = tags.filter((i) => i !== tagId);
    } else {
      newTags = [...tags, tagId];
    }
    const needsTimeAlignment =
      getSiteEquipment(this.props.site).filter((equipment) => {
        return newTags.some((tag) => equipment.tags.map((t) => t.id).includes(tag));
      }).length > 1;
    this.setState({
      tags: newTags,
      needsTimeAlignment,
      timeAlignMinutes: needsTimeAlignment && !timeAlignMinutes ? 1 : timeAlignMinutes,
    });
  };

  handleTagSearch = (value: string) => {
    this.setState({ tagSearchValue: value });
  };

  handleExportReport = () => {
    const { historicalTS, modernTS, recipients, timeAlignMinutes, tags } = this.state;
    const { siteId, onSubmit } = this.props;

    if (!historicalTS || !modernTS) {
      return;
    }

    const exportReportRequest = {
      siteId,
      additionalRecipientEmails: recipients,
      tagNames: tags,
      historicalTS,
      modernTS,
      timeAlignMinutes,
      rawTimestampsOnly: timeAlignMinutes ? false : true,
    };
    onSubmit(exportReportRequest);
  };

  render() {
    const { recipients, historicalTS, modernTS, timeAlignMinutes, tags, needsTimeAlignment } =
      this.state;
    const { users, site } = this.props;

    const isValid = tags.length > 0 && historicalTS && modernTS;

    return (
      <Grid container style={{ height: '100%' }} spacing={2}>
        <Grid sm={6} item container direction="column">
          <Grid xs item style={{ flexGrow: 0, marginBottom: 4 }}>
            <Typography variant="subtitle1">Choose Tags</Typography>
          </Grid>
          <Grid xs item style={{ flex: 1, position: 'relative' }}>
            <TagTree
              height={'100%'}
              site={site}
              onTagSelect={this.handleSelectTag}
              selectedTags={tags}
              style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }}
              tagFilter={(tag) => {
                return isDisplayTag(tag) || tag.tagType === TagType.Control;
              }}
            />
          </Grid>
        </Grid>
        <Grid sm={6} item container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle1">Configure Export</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                type="date"
                label="From"
                value={historicalTS || ''}
                error={!historicalTS}
                inputProps={{
                  min: site.commissionedOn
                    ? format(new Date(site.commissionedOn), 'yyyy-MM-dd')
                    : '',
                }}
                helperText={!historicalTS && 'From date is required'}
                onChange={this.handleChangeHistoricalTS}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                type="date"
                label="To"
                value={modernTS || ''}
                error={!modernTS}
                inputProps={{
                  max: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
                }}
                helperText={!modernTS && 'To date is required'}
                onChange={this.handleChangeModernTS}
              />
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              SelectProps={{
                displayEmpty: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
              fullWidth
              label="Time Alignment (minutes)"
              onChange={this.handleTimeAlignChange}
              value={timeAlignMinutes || ''}
              select
            >
              <MenuItem disabled={needsTimeAlignment} value="">
                None
              </MenuItem>
              {TIME_ALIGNMENT_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <RecipientListSelect
              label="CC results to:"
              options={users}
              recipients={recipients}
              onRemove={this.handleRemoveRecipient}
              onAdd={this.handleAddRecipient}
            />
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <PrimaryButton onClick={this.handleExportReport} disabled={!isValid}>
                Export
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ExportConfigurationForm);
