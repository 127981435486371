import {
  Grid,
  makeStyles,
  Theme,
  Divider,
  useMediaQuery,
  useTheme,
  Typography,
} from '@material-ui/core';
import { PowerflowDiagram } from './PowerflowDiagram';
import { ComponentFlow, Metric } from './types';
import MetricList from './MetricList';
import { EnergyMixDiagram } from './EnergyMixDiagram';
import { SubscriptionStatus, SubscriptionLifecycle } from '../../../state/subscription';
import identifiers from '../../../tests/identifiers';
import InternalErrorBoundary from '../../utility/InternalErrorBoundary';
import LoadWithSpinner from '../../ui/Loading/LoadWithSpinner';

interface DashboardPageProps {
  flows: ComponentFlow[];
  metrics: Metric[];
  dataStatus: SubscriptionStatus;
}

const DEFAULT_ERROR_MESSAGE =
  'Problem with dashboard configuration, please contact site administrator.';

const DashboardPage = (props: DashboardPageProps) => {
  const { flows, metrics, dataStatus } = props;
  const theme = useTheme();
  const classes = useStyles();
  const isMedScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <InternalErrorBoundary
      className={classes.contentWrapper}
      overrideMessage={DEFAULT_ERROR_MESSAGE}
    >
      {dataStatus !== SubscriptionLifecycle.ACTIVE ? (
        <div className={classes.contentWrapper}>
          <LoadWithSpinner message={'Loading dashboard...'} />
        </div>
      ) : (
        <div
          data-testid={identifiers.dashboardPage.page}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div className={classes.pageBackground} style={{ zIndex: -1, position: 'fixed' }} />
          <div className={classes.powerflowSection}>
            <Typography variant="h3" className={classes.subtitle}>
              Power Flow
            </Typography>
            <Grid className={classes.root} container direction="column" spacing={1}>
              <Grid container item spacing={1}>
                <Grid className={classes.powerflowWrap} item xs={12} md={8} lg={9}>
                  <PowerflowDiagram flows={flows} />
                </Grid>

                {isMedScreen && (
                  <div style={{ width: 0 }}>
                    <Divider orientation="vertical" />
                  </div>
                )}

                <Grid item xs={12} md={4} lg={3}>
                  <MetricList metrics={metrics} />
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div className={classes.energySection}>
            <Grid container item>
              <Typography variant="h3" className={classes.subtitle}>
                Energy Mix
              </Typography>
              <EnergyMixDiagram flows={flows} />
            </Grid>
          </div>
        </div>
      )}
    </InternalErrorBoundary>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: '100%',
    width: 'calc(100% - 2px)',
    margin: 0,
    overflow: 'hidden',
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 24,
    height: '60vh',
  },
  pageBackground: theme.mixins.pageBackground,
  powerflowSection: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #ccc',
    paddingBottom: '48px',
    zIndex: 0,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
    },
  },
  powerflowWrap: {
    padding: '0 48px !important',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px !important',
    },
  },
  energySection: {
    paddingTop: '16px',
  },
  subtitle: {
    fontSize: 20,
    margin: '24px 48px 0',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      margin: '16px 24px',
    },
  },
}));

export default DashboardPage;
