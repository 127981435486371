import { Dispatch, useMemo } from 'react';
import {
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Equipment, isDisplayTag } from '../../../models';
import identifiers from '../../../tests/identifiers';

const EMPTY_UNIT_LABEL = '(None)';
const unitLabel = (unit: string) => (unit.trim() === '' ? EMPTY_UNIT_LABEL : unit);

const EquipmentTagListControls = ({
  equipment,
  tagListDispatch,
  tagListState: { search, units },
}: EquipmentTagListControlsProps) => {
  const classes = useStyles();

  const tagUnits = useMemo(() => {
    const uniqueUnits = new Set();
    equipment.tags.filter(isDisplayTag).forEach((tag) => uniqueUnits.add(tag.unit));
    return Array.from(uniqueUnits).sort() as string[];
  }, [equipment]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={9}>
        <TextField
          id="search-equipment-tags"
          data-testid={identifiers.equipmentPage.tagFilterSearch}
          onChange={({ target: { value } }) => tagListDispatch({ type: 'search', value })}
          placeholder="Search Tag Points"
          disabled={tagUnits.length === 0}
          value={search}
          variant="outlined"
          size="small"
          className={classes.filter}
          inputProps={{ autoComplete: 'off' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl variant="outlined" className={classes.formControl} size="small">
          <InputLabel htmlFor="tag-units">Units</InputLabel>
          <Select
            multiple
            value={units}
            onChange={(event) =>
              tagListDispatch({ type: 'units', value: event.target.value as [] })
            }
            label="Units"
            inputProps={{
              name: 'tag-units',
              id: 'tag-units',
            }}
            disabled={tagUnits.length === 0}
            MenuProps={{
              variant: 'menu',
              PaperProps: {
                style: {
                  maxHeight: 400,
                },
              },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            renderValue={(selected) => (selected as string[]).map(unitLabel).join(', ')}
            data-testid={identifiers.equipmentPage.tagFilterTypes}
          >
            {tagUnits.map((unit: string) => (
              <MenuItem key={unit} value={unit}>
                <Checkbox color="default" checked={units.indexOf(unit) > -1} />
                <ListItemText primary={unitLabel(unit)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export type EquipmentTagListAction =
  | { type: 'clear' }
  | { type: 'search'; value: string }
  | { type: 'units'; value: string[] };

export interface EquipmentTagListState {
  search: string;
  units: string[];
}

export interface EquipmentTagListControlsProps {
  equipment: Equipment;
  tagListDispatch: Dispatch<EquipmentTagListAction>;
  tagListState: EquipmentTagListState;
}

export const INITIAL_TAG_LIST_STATE: EquipmentTagListState = {
  search: '',
  units: [],
};

export function tagListReducer(state: EquipmentTagListState, action: EquipmentTagListAction) {
  switch (action.type) {
    case 'clear':
      return { ...INITIAL_TAG_LIST_STATE };
    case 'search':
      return { ...state, search: action.value };
    case 'units':
      return { ...state, units: action.value };
    default:
      throw new Error();
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  filter: {
    marginBottom: theme.spacing(0.5),
  },
  formControl: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

export default EquipmentTagListControls;
