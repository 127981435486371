import { TagApi } from '../../../api';
import { takeEvery } from 'redux-saga/effects';
import { getType, ActionType } from 'typesafe-actions';
import * as siteActions from './actions';
import { apiSagaV2 } from '../../../helpers/saga';
import { downloadFromUrl } from '../../../helpers/download';
import { imageCache } from '../../../helpers/ImageCache';

const requestSiteDocument = apiSagaV2(siteActions.requestSiteDocument, (connection, action) => {
  const { siteId, documentName } = action.payload;
  return new TagApi(connection).getSiteDocument(siteId, documentName);
});

function handleDocumentDownload(
  action: ActionType<typeof siteActions.requestSiteDocument.success>
) {
  const { triggerAction, result: downloadUrl } = action.payload;
  const { documentName } = triggerAction.payload;
  downloadFromUrl(documentName, downloadUrl);
}

const fetchSiteData = apiSagaV2(siteActions.fetchSiteData, async (connection, action) => {
  const { siteId } = action.payload;
  const siteData = await new TagApi(connection).fetchTagTree(siteId);
  if (siteData.site.singleLineImageFileLink) {
    imageCache.fetchAndStoreImage(siteData.site.singleLineImageFileLink);
  }
  return siteData;
});

export function* siteSagas() {
  yield takeEvery(getType(siteActions.fetchSiteData.request), fetchSiteData);
  yield takeEvery(getType(siteActions.requestSiteDocument.request), requestSiteDocument);
  yield takeEvery(getType(siteActions.requestSiteDocument.success), handleDocumentDownload);
}
