import React from 'react';
import { createStyles, Theme, withStyles, WithStyles, Paper, Grid } from '@material-ui/core';

interface Props {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    section: {
      padding: 0,
    },
  });

type AllProps = Props & WithStyles<typeof styles>;

const PageSection: React.SFC<AllProps> = (props) => {
  return (
    <Grid item xs={12} className={props.classes.root}>
      <Paper className={props.classes.section}>{props.children}</Paper>
    </Grid>
  );
};

export default withStyles(styles)(PageSection);
