import { RootState } from '../';
import { getType } from 'typesafe-actions';
import { createApiActions, ActionsOf, createSubActions } from '../../helpers/actions';
import { PortfolioApi, PortfolioQuery, PortfolioResponse } from '../../api/PortfolioApi';
import { PortfolioSubscription } from '../../api/subscriptions/PortfolioSubscription';
import { apiSagaV2, subSaga } from '../../helpers/saga';
import { takeEvery } from 'redux-saga/effects';
import { AlarmPriority, EnrollmentStatus } from '../../models';
import { OrderDirectionType } from '../../components/ui/DataTable';
import URI from 'urijs';

const DEFAULT_PAGE_COUNT = 25;

export interface FilterOption<ValueType> {
  name: string;
  value: ValueType | undefined;
}

export interface PortfolioFilter {
  customers: FilterOption<number>[];
  zipCodes: FilterOption<string>[];
  utilities: FilterOption<number>[];
  statuses: FilterOption<AlarmPriority>[];
  gridServicesEnrollmentStatuses: FilterOption<keyof typeof EnrollmentStatus>[];
}

export interface PortfolioFilterValues {
  customers: number[];
  zipCodes: string[];
  utilities: number[];
  statuses: AlarmPriority[];
  gridServicesEnrollmentStatuses: EnrollmentStatus[];
}

export const FilterLabels = {
  customers: 'Customer',
  statuses: 'Alarm Level',
  utilities: 'Utility',
  zipCodes: 'Zip Code',
  gridServicesEnrollmentStatuses: 'Enrollment Status',
} as const;

export interface PortfolioState {
  customers: number[];
  zipCodes: string[];
  utilities: number[];
  statuses: AlarmPriority[];
  gridServicesEnrollmentStatuses: EnrollmentStatus[];
  page: number;
  pageCount: number;
  sortBy: string;
  sortDir: OrderDirectionType;
  textMatch?: string;
}

export const emptyState = {
  page: undefined,
  sortBy: undefined,
  sortDir: undefined,
  customers: undefined,
  zipCodes: undefined,
  utilities: undefined,
  textMatch: undefined,
  statuses: undefined,
  gridServicesEnrollmentStatuses: undefined,
};

export interface PortfolioPersistantState {
  lastUrl?: string;
  columns: string[];
}

export const stateToQuery = (state: PortfolioState): PortfolioQuery => ({
  customerIds: state.customers,
  zipCodes: state.zipCodes,
  utilityIds: state.utilities,
  alarmStatuses: state.statuses,
  gridServicesEnrollmentStatuses: state.gridServicesEnrollmentStatuses,
  sortBy: state.sortBy || 'alarmStatus',
  sortAscending: state.sortDir === 'asc',
  pageNumber: state.page,
  pageSize: state.pageCount || DEFAULT_PAGE_COUNT,
  nameAddressFilter: state.textMatch,
});

export const urlParser = (url: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rawState = new URI(url).search(true) as any;

  // ensure there is a property for each filter
  ['customers', 'statuses', 'utilities', 'zipCodes', 'gridServicesEnrollmentStatuses'].forEach(
    (key) => {
      if (rawState[key] && !(rawState[key] instanceof Array)) {
        rawState[key] = [rawState[key]];
      } else if (!rawState[key]) {
        rawState[key] = [];
      }
    }
  );

  // parse ids as ints
  ['customers', 'utilities'].forEach((key) => {
    rawState[key] = rawState[key].map((id: string) => parseInt(id, 10));
  });

  rawState.page = rawState.page ? parseInt(rawState.page, 10) : 1;
  return rawState as PortfolioState;
};

/* ----- actions ----- */

export const queryPortfolio = createApiActions<PortfolioResponse>()(
  'portfolioPage/queryPortfolio',
  'portfolioPage/queryPortfolioSuccess',
  'portfolioPage/queryPortfolioError',
  (query: PortfolioQuery) => query
);

export const subPortfolio = createSubActions<PortfolioResponse>()(
  'portfolioPage/subStart',
  'portfolioPage/subUpdate',
  'portfolioPage/subError',
  'portfolioPage/subStop',
  (query: PortfolioQuery) => query
);

export const fetchPortfolioOptions = createApiActions<PortfolioFilter>()(
  'portfolioPage/fetchOptions',
  'portfolioPage/fetchOptionsSuccess',
  'portfolioPage/fetchOptionsError',
  () => true
);

/* ----- sagas ----- */

const queryPortfolioSaga = apiSagaV2(queryPortfolio, (connection, action) => {
  const query = action.payload;
  return new PortfolioApi(connection).querySiteSummaries(query);
});

const subPortfolioSaga = subSaga(subPortfolio, (connection, action) => {
  const subscription = new PortfolioSubscription(connection);
  subscription.start(action.payload);
  return subscription;
});

const fetchPortfolioOptionsSaga = apiSagaV2(fetchPortfolioOptions, async (connection) => {
  const apiOptions = await new PortfolioApi(connection).fetchPortfolioOptions();
  // we need to map to the frontend naming convention here
  return {
    customers: apiOptions.customers.map((c) => ({ name: c.name, value: c.id })),
    zipCodes: apiOptions.zipCodes.map((z) => ({ name: z, value: z })),
    utilities: apiOptions.utilities.map((u) => ({ name: u.name, value: u.id })),
    statuses: Object.values(AlarmPriority).map((s) => ({ name: s, value: s })),
    gridServicesEnrollmentStatuses: Object.keys(EnrollmentStatus).map((k) => ({
      name: EnrollmentStatus[k],
      value: k,
    })),
  } as PortfolioFilter;
});

export function* portfolioPageSagas() {
  yield takeEvery(getType(queryPortfolio.request), queryPortfolioSaga);
  yield takeEvery(getType(fetchPortfolioOptions.request), fetchPortfolioOptionsSaga);
  yield takeEvery(getType(subPortfolio.start), subPortfolioSaga);
}

/* ----- selectors ----- */

export const getSiteSummaries = (rootState: RootState) => {
  return rootState.contexts.portfolioPage.results?.sites;
};

export const getTotalResults = (rootState: RootState) => {
  return rootState.contexts.portfolioPage.results?.totalMatchingSites;
};

export const getFilterOptions = (rootState: RootState) => {
  return rootState.contexts.portfolioPage.options;
};

/* ----- reducer ----- */

// Note: query state is persisted in the url not this store
export interface PortfolioPageState {
  results?: PortfolioResponse;
  options?: PortfolioFilter;
}

const initialState = {
  results: undefined,
  options: undefined,
};

type PortfolioPageActions =
  | ActionsOf<typeof queryPortfolio | typeof fetchPortfolioOptions>
  | ActionsOf<typeof subPortfolio>;

export const portfolioPageReducer = (
  state: PortfolioPageState = initialState,
  action: PortfolioPageActions
): PortfolioPageState => {
  switch (action.type) {
    case getType(subPortfolio.update):
    case getType(queryPortfolio.success):
      return {
        ...state,
        results: action.payload.result,
      };
    case getType(fetchPortfolioOptions.success):
      return {
        ...state,
        options: action.payload.result,
      };
    default:
      return state;
  }
};
