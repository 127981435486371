import { toPercentage } from './numbers';
import { format, timeFormat } from 'd3';

export function formatValueAndUnit(
  value: number | string,
  unit?: string,
  options?: { fractionDigits?: number; zeroPlaceholder?: string }
) {
  if (typeof value === 'string') {
    return unit ? `${value} ${unit}` : value;
  }

  if (unit === '%') {
    return `${toPercentage(value, options?.fractionDigits)}`;
  }

  if (unit === '$') {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: options?.fractionDigits,
      maximumFractionDigits: options?.fractionDigits,
    });
    return formatter.format(value);
  }

  const result = unit
    ? `${formatNumber(value, options?.fractionDigits)} ${unit}`
    : formatNumber(value, options?.fractionDigits);

  if (value === 0 || formatNumber(value, options?.fractionDigits) === '0') {
    return options?.zeroPlaceholder || '';
  } else {
    return result;
  }
}

export function formatNumber(
  value: number,
  maximumFractionDigits: number = 20,
  minimumFractionDigits: number = 0
) {
  return `${(value || 0).toLocaleString(undefined, {
    minimumFractionDigits,
    maximumFractionDigits,
  })}`;
}

/**
 * Formats a number taking localization into account, and rounding to given
 * number of significant figures.
 *
 * Examples:
 *    1234.5678, 3    -> 1,230
 *    .00012345678, 3 -> 0.000123
 */
export function formatNumberSignificant(value: number, significantDigits: number) {
  return `${value.toLocaleString(undefined, {
    minimumSignificantDigits: 1,
    maximumSignificantDigits: significantDigits,
  })}`;
}

export const safeValue = (value: string | undefined | null): string => {
  if (value === undefined || value === null) {
    return '';
  }
  return value;
};

const ONE_SECOND = 1000;
const ONE_MINUTE = 1000 * 60;
const ONE_HOUR = 1000 * 60 * 60;
const ONE_DAY = 1000 * 60 * 60 * 24;
const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;

/**
 * Creates a human readable time duration string of form,
 *
 * 1.2 s, 34.2 m, 8.7 w etc.
 */
export const formatDuration = (duration: number) => {
  const formatter = format('.1f');
  if (duration < ONE_SECOND / 10) {
    return `${formatter(duration)} ms`;
  } else if (duration < ONE_MINUTE) {
    return `${formatter(duration / ONE_SECOND)} s`;
  } else if (duration < ONE_HOUR) {
    return `${formatter(duration / ONE_MINUTE)} m`;
  } else if (duration < ONE_DAY) {
    return `${formatter(duration / ONE_HOUR)} h`;
  } else if (duration < ONE_WEEK) {
    return `${formatter(duration / ONE_DAY)} d`;
  } else {
    return `${formatter(duration / ONE_WEEK)} w`;
  }
};

/**
 * Returns a date string with format YYYY-MM-DD.
 */
export const toDateString = timeFormat('%Y-%m-%d');
