import { HasTags } from './tag';
import { palette } from '../config';
import { Normalized } from '../helpers/norm';
import batteryIcon from '../assets/icons/electric-icon.png';
import electricityIcon from '../assets/icons/electric-icon.png';
import hybridInverterIcon from '../assets/icons/inverter-white.png';
import loadIcon from '../assets/icons/load-white.png';
import solarPanelIcon from '../assets/icons/solar-panel.png';
import utilityIcon from '../assets/icons/utility-icon.png';
import generatorIcon from '../assets/icons/generator-white.png';
import weatherStationIcon from '../assets/icons/weather-station-white.png';

export enum EquipmentType {
  BaseGeneration = 'Base Generation',
  BatteryManagementSystem = 'Battery Management System',
  FlowBattery = 'Flow Battery',
  HybridInverter = 'Hybrid Inverter',
  Load = 'Load',
  Meter = 'Meter',
  NetMeter = 'Net Meter',
  PV = 'PV',
  Panel = 'Panel',
  ATS = 'ATS',
  WeatherStation = 'Weather Station',
  Storage = 'Storage',
  Other = 'Other',
}

export interface Equipment extends HasTags {
  id: number;
  label: string;
  description: string;
  equipmentType: EquipmentType;
  category: string;
  tileOrder: number;
  commissionedDate?: string | Date;
  imageLink?: string;
  installedPower?: number;
  installedCapacity?: number;
  make?: string;
  manualLink?: string;
  manufacturer?: string;
  model?: string;
  serialNumber?: string;
}

export type EquipmentNorm = Normalized<Equipment, 'tags', { tagIds: string[] }>;

const colorMappings = {
  [EquipmentType.PV]: palette.solar,

  [EquipmentType.BatteryManagementSystem]: palette.storage,
  [EquipmentType.FlowBattery]: palette.storage,
  [EquipmentType.Storage]: palette.storage,
  [EquipmentType.HybridInverter]: palette.intermediate,

  [EquipmentType.Load]: palette.load,
  [EquipmentType.Meter]: palette.utility,
  [EquipmentType.NetMeter]: palette.utility,
};

export const getEquipmentColor = (equipment: Equipment) => {
  return colorMappings[equipment.equipmentType];
};

export const getEquipmentIcon = (equipment: Equipment) => {
  const { equipmentType } = equipment;

  switch (equipmentType) {
    case EquipmentType.FlowBattery:
    case EquipmentType.Storage:
    case EquipmentType.BatteryManagementSystem:
      return batteryIcon;

    case EquipmentType.HybridInverter:
      return hybridInverterIcon;

    case EquipmentType.BaseGeneration:
      return generatorIcon;

    case EquipmentType.PV:
      return solarPanelIcon;

    case EquipmentType.Load:
      return loadIcon;

    case EquipmentType.Meter:
    case EquipmentType.NetMeter:
      return utilityIcon;

    case EquipmentType.WeatherStation:
      return weatherStationIcon;

    default:
      return electricityIcon;
  }
};
