import { createStore, Store, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { forIn } from 'lodash';
import { rootReducer, RootState } from './rootReducer';
import { getHistory } from './history';
import { sagas } from './sagas';

export const REDUX_INIT = 'REDUX_INIT';
export { getHistory } from './history';

export type { RootState } from './rootReducer';

export function configureStore(): Store<RootState> {
  const history = getHistory();
  const routerMiddlewareInst = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();
  const persistedState = {};
  const result = createStore(
    rootReducer,
    persistedState,
    composeWithDevTools(applyMiddleware(routerMiddlewareInst, sagaMiddleware))
  );

  // must be run after the store has been created
  forIn(sagas, sagaMiddleware.run);

  // dispatch this action that can be used to trigger startup sagas
  result.dispatch({ type: REDUX_INIT });

  return result;
}
