import React from 'react';
import { Lifecycle, SimpleRequestStatus } from '../../../state/request';
import { AlarmSummary } from '../../../models';
import { DataTable, Column, OrderDirectionType } from '../../ui/DataTable';
import { useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { Box, Typography } from '@material-ui/core';
import { formatDuration } from '../../../helpers/formatting';
import { Link } from 'react-router-dom';
import { PathBuilder } from '../../../routing';
import { RequestStatus } from '../../utility/RequestStatus';

export interface Sort {
  orderBy: string;
  orderDirection: OrderDirectionType;
}

const getAlarmHistoryConfig = (
  siteId: number,
  isResolvable: boolean,
  activeTS?: string
): Column<AlarmSummary>[] => {
  const tableConfig: Column<AlarmSummary>[] = [
    {
      id: 'activatedAt',
      label: 'Triggered',
      field: 'activatedAt',
      isSortable: true,
      renderCell: (alarmSummary) => {
        if (activeTS && alarmSummary.activatedAt === activeTS) {
          return <b>{new Date(alarmSummary.activatedAt).toLocaleString()}</b>;
        } else {
          return (
            <Link
              to={PathBuilder.ALARM_DETAILS(siteId, alarmSummary.alarmId, alarmSummary.alarmTag.id)}
            >
              {new Date(alarmSummary.activatedAt).toLocaleString()}
            </Link>
          );
        }
      },
    },
    {
      id: 'resolved',
      label: 'Resolved',
      field: (alarmSummary) =>
        alarmSummary.resolvedAt && new Date(alarmSummary.resolvedAt).toLocaleString(),
      isSortable: true,
    },
    {
      id: 'duration',
      label: 'Active For',
      field: (alarmSummary) => {
        const { activatedAt, resolvedAt } = alarmSummary;
        if (!resolvedAt) {
          return Infinity;
        } else {
          return new Date(resolvedAt).getTime() - new Date(activatedAt).getTime();
        }
      },
      isSortable: true,
      renderCell: (alarmSummary) => {
        const { activatedAt, resolvedAt } = alarmSummary;
        if (!resolvedAt) {
          return '—';
        } else {
          return formatDuration(new Date(resolvedAt).getTime() - new Date(activatedAt).getTime());
        }
      },
    },
  ];

  if (!isResolvable) {
    tableConfig.push({
      id: 'ackBy',
      label: 'Acknowledged By',
      field: (alarmSummary) =>
        alarmSummary.acknowledgement && alarmSummary.acknowledgement.userEmail,
    });
  }

  return tableConfig;
};

export interface AlarmHistoryProps {
  activeAlarmTriggerTS?: string;
  requestStatus: SimpleRequestStatus;
  range: number;
  siteId: number;
  alarmSummaries: AlarmSummary[];
}

const PAGE_SIZE = 20;

export const AlarmHistory: React.FC<AlarmHistoryProps> = (props) => {
  const { alarmSummaries, range, siteId, requestStatus } = props;
  const [page, setPage] = useState<number>(1);
  const [{ orderBy, orderDirection }, setSort] = useState<Sort>({
    orderBy: 'activatedAt',
    orderDirection: 'desc',
  });

  const doSetSort = (newOrderBy: string, newOrderDirection: OrderDirectionType) =>
    setSort({ orderBy: newOrderBy, orderDirection: newOrderDirection });
  const isAlarmResolvable = alarmSummaries.some((s) => s.alarmTag.alarmCanBeResolved);
  const tableConfig = getAlarmHistoryConfig(siteId, isAlarmResolvable, props.activeAlarmTriggerTS);
  if (requestStatus !== Lifecycle.COMPLETED) {
    return <RequestStatus status={requestStatus} />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box p={2}>
        {alarmSummaries.length} events in previous {range} days.
      </Box>
      {alarmSummaries && alarmSummaries.length > 0 && (
        <DataTable
          fullHeight
          columnMapping={tableConfig}
          page={page}
          orderBy={orderBy}
          orderDirection={orderDirection}
          elements={alarmSummaries}
          elementKey={(alarmSummary) => alarmSummary.alarmUid}
          handleSort={doSetSort}
        />
      )}
      {(!alarmSummaries || alarmSummaries.length === 0) && (
        <Box flex="1" display="flex" justifyContent="center">
          <Typography>No alarms for this tag have been triggered in the last 90 days.</Typography>
        </Box>
      )}
      <Box
        p={2}
        style={{ display: 'flex', justifyItems: 'center', borderTop: '1px solid #e0e0e0' }}
      >
        <Pagination
          count={Math.ceil(alarmSummaries.length / PAGE_SIZE)}
          page={page || 1}
          variant="outlined"
          shape="rounded"
          onChange={(_e, newPage) => setPage(newPage)}
        />
      </Box>
    </div>
  );
};
