import { SnapshotSubscription, SnapshotApi } from '../../../api';
import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as snapshotActions from './actions';
import { apiSagaV2, subSaga } from '../../../helpers/saga';

const fetchSnapshotsBetween = apiSagaV2(snapshotActions.fetchSnapshots, (connection, action) => {
  const { siteId, tags, start, end, includeTransient } = action.payload;
  return new SnapshotApi(connection).fetchSnapshotsBetween(
    siteId,
    tags,
    start,
    end,
    includeTransient
  );
});

const handleSubSnapshots = subSaga(snapshotActions.subSnapshots, (conection, action) => {
  const subscription = new SnapshotSubscription(conection);
  const { siteId, tags, lookback, lookforward } = action.payload;
  subscription.start(siteId, tags, lookback, lookforward);
  return subscription;
});

export function* snapshotSagas() {
  yield takeEvery(getType(snapshotActions.fetchSnapshots.request), fetchSnapshotsBetween);
  yield takeEvery(getType(snapshotActions.subSnapshots.start), handleSubSnapshots);
}
