import React from 'react';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import UserIcon from '@material-ui/icons/AccountCircle';
import { privacyPolicyUrl, knowledgebaseUrl } from '../../../config/index';
import {
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core';

interface UserMenuProps {
  logout: () => void;
}

const useIconStyles = makeStyles(() => ({
  root: {
    color: '#666666',
  },
}));

export const UserMenu: React.FC<UserMenuProps> = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const iconClasses = useIconStyles();
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <>
          <Tooltip title="User">
            <IconButton
              {...bindTrigger(popupState)}
              aria-haspopup="true"
              data-testid="account-button"
              color="inherit"
              size={isSmallScreen ? 'small' : 'medium'}
            >
              <UserIcon color="primary" classes={iconClasses} />
            </IconButton>
          </Tooltip>
          <Menu {...bindMenu(popupState)}>
            <MenuItem href={privacyPolicyUrl} target="_blank" component="a">
              Privacy Policy
            </MenuItem>
            <MenuItem href={knowledgebaseUrl} target="_blank" component="a">
              Knowledgebase
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.logout();
                popupState.close();
              }}
            >
              Log Out
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
};
