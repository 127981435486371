import { withStyles, Button, createStyles, ButtonProps } from '@material-ui/core';

export type UIButtonProps = Omit<ButtonProps, 'variant' | 'color'>;

export const PrimaryButton = withStyles(() => createStyles({}))((props: UIButtonProps) => (
  <Button {...props} variant="contained" color="primary" />
));

export const SecondaryButton = withStyles(() =>
  createStyles({
    root: {
      fontWeight: 400,
    },
  })
)((props: UIButtonProps) => <Button {...props} variant="outlined" color="primary" />);

export const CancelButton = withStyles(() =>
  createStyles({
    root: {
      fontWeight: 400,
      backgroundColor: '#f0f0f0',
    },
  })
)((props: UIButtonProps) => <Button {...props} variant="contained" color="default" />);

export const PrimaryActionButton = PrimaryButton;

export const SecondaryActionButton = SecondaryButton;
