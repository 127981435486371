import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SectionTitle from '../typography/SectionTitle';

interface Props {
  style?: React.CSSProperties;
  collapsed?: boolean;
  title?: string;
  children?: React.ReactNode;
}

const styles = (theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.h6.fontSize,
    },
    panel: {
      boxShadow: theme.shadows[2],
    },
    content: {
      padding: 0,
      borderTop: `1px solid ${theme.palette.divider}`,
      flexDirection: 'column',
    },
    expanded: {},
    headerRoot: {
      minHeight: 6 * theme.spacing(),
      '&$expanded': {
        minHeight: 6 * theme.spacing(),
      },
    },
    headerBar: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      '&$expanded': {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
      },
    },
  });

interface State {
  collapsed: boolean;
}

type AllProps = Props & WithStyles<typeof styles>;

class CollapsingPageSection extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      collapsed: props.collapsed || false,
    };
  }

  onToggle() {
    this.setState((prevState: State) => {
      return {
        collapsed: !prevState.collapsed,
      };
    });
  }

  render() {
    return (
      <Grid item xs={12}>
        <Accordion className={this.props.classes.panel} expanded={!this.state.collapsed}>
          <AccordionSummary
            classes={{
              root: this.props.classes.headerRoot,
              content: this.props.classes.headerBar,
              expanded: this.props.classes.expanded,
            }}
            expandIcon={<ExpandMoreIcon />}
            onClick={() => this.onToggle()}
          >
            {this.props.title && <SectionTitle text={this.props.title} />}
          </AccordionSummary>

          <AccordionDetails className={this.props.classes.content}>
            {this.props.children}
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  }
}

export default withStyles(styles)(CollapsingPageSection);
