import React from 'react';
import {
  TextField,
  Grid,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  User,
  BackoffPolicy,
  getSettableBackoffPolicies,
  isSettableBackoffPolicy,
  getDefaultDeliveryStrategy,
  DeliveryStrategies,
  DeliveryStrategy,
  getStrategyId,
  strategyFromId,
  AlertDistribution,
} from '../../../models';
import { safeValue } from '../../../helpers/formatting';
import { PrimaryActionButton, SecondaryActionButton } from '../../ui/Buttons';

interface UserDropdownProps {
  users: User[];
  onSelect: (user: User) => void;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  radioLabel: {
    fontSize: 14,
  },
}));

/**
 * Implementes a user selector button + dropdown
 */
class UserDropdown extends React.Component<UserDropdownProps> {
  state = {
    anchorEl: undefined,
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: undefined });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div>
        <PrimaryActionButton
          aria-owns={anchorEl ? 'user-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          disabled={this.props.users.length === 0 || this.props.disabled}
        >
          <AddIcon />
          Add User
        </PrimaryActionButton>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.props.users.map((user) => (
            <MenuItem
              key={user.userId}
              onClick={() => {
                this.handleClose();
                this.props.onSelect(user);
              }}
            >
              {user.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export interface AlertFields {
  alertTagId: string;
  backoffPolicy: BackoffPolicy | null;
  distributionList: AlertDistribution[];
}

interface Props {
  alertFields: AlertFields;
  isSubmitting?: boolean;
  users: User[];
  onChange: (update: AlertFields) => void;
  additionalControls?: () => React.ReactNode;
}

/**
 * Acts as a group of controlled inputs to modify the backoff policy and
 * delivery strategy of an alert object
 */
export const AlertSettingsFieldGroup: React.FC<Props> = (props) => {
  const { isSubmitting, onChange, alertFields } = props;
  const { backoffPolicy, distributionList } = alertFields;
  const classes = useStyles();

  /**
   * Get list of users that are not already in the distribution list
   */
  const getAvailableUsers = () => {
    const existingUsers = distributionList.map((dist) => dist.userId);
    return props.users.filter((user) => !existingUsers.includes(user.userId));
  };

  /**
   * Immutably updates alert fields, adding a distribution for a new user
   */
  const handleAddUser = (user: User) => {
    const updatedDistributionList = [
      ...distributionList,
      {
        userId: user.userId,
        user,
        ...getDefaultDeliveryStrategy(),
      },
    ];
    onChange({
      alertTagId: alertFields.alertTagId,
      backoffPolicy,
      distributionList: updatedDistributionList,
    });
  };

  /**
   * Immutably updates alert fields, modifying the delivery strategy for the
   * specified alert distribution.
   */
  const handleChangeStrat = (distToChange: AlertDistribution, strat: DeliveryStrategy) => {
    const foundDistIdx = distributionList.findIndex((dist) => dist.userId === distToChange.userId);
    if (foundDistIdx >= 0) {
      const updatedDistributionList = [
        ...distributionList.slice(0, foundDistIdx),
        {
          ...distributionList[foundDistIdx],
          ...strat,
        },
        ...distributionList.slice(foundDistIdx + 1),
      ];
      onChange({
        alertTagId: alertFields.alertTagId,
        backoffPolicy,
        distributionList: updatedDistributionList,
      });
    }
  };

  /**
   * Calls onChange with user removed (immutably) from distributionList
   */
  const handleRemoveUser = (user: User) => {
    const foundDistIdx = distributionList.findIndex((dist) => dist.userId === user.userId);
    if (foundDistIdx >= 0) {
      const updatedDistributionList = [
        ...distributionList.slice(0, foundDistIdx),
        ...distributionList.slice(foundDistIdx + 1),
      ];
      onChange({
        alertTagId: alertFields.alertTagId,
        backoffPolicy,
        distributionList: updatedDistributionList,
      });
    }
  };

  const availableUsers = getAvailableUsers();

  const availablePolicies =
    backoffPolicy && isSettableBackoffPolicy(backoffPolicy)
      ? getSettableBackoffPolicies()
      : Object.values(BackoffPolicy);

  return (
    <Grid container direction="column" spacing={1} data-testid="alert-settings-form">
      {backoffPolicy !== null && (
        <Grid item style={{ marginBottom: 16 }}>
          <TextField
            style={{ minWidth: 220 }}
            data-testid={'alert-fields-backoff-select'}
            InputLabelProps={{
              shrink: Boolean(backoffPolicy),
            }}
            select
            variant="outlined"
            size="small"
            disabled={isSubmitting || !isSettableBackoffPolicy(backoffPolicy)}
            label="Backoff Policy"
            name="backoffPolicy"
            onChange={(event) => {
              const newPolicy = event.target.value as BackoffPolicy;
              onChange({
                alertTagId: alertFields.alertTagId,
                backoffPolicy: newPolicy,
                distributionList,
              });
            }}
            value={safeValue(backoffPolicy)}
          >
            {availablePolicies.map((backoffPolicyOption) => (
              <MenuItem key={backoffPolicyOption} value={backoffPolicyOption}>
                {backoffPolicyOption}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}

      <Grid item>
        <Typography variant="caption" style={{ marginBottom: 8 }}>
          Recipients
        </Typography>
        {distributionList.length === 0 && (
          <Typography variant="body2" style={{ padding: '8px 0px' }}>
            Click add user to add recipients.
          </Typography>
        )}
        <Table style={{ borderTop: '1px solid #e0e0e0' }} size="small">
          <TableBody>
            {distributionList.map((dist) => {
              if (!dist.user) {
                return null;
              } else {
                return (
                  <TableRow key={`${dist.userId}-${getStrategyId(dist)}`}>
                    <TableCell style={{ paddingLeft: 8 }}>{dist.user.name}</TableCell>
                    <TableCell>{dist.user.emailAddress}</TableCell>
                    <TableCell>
                      <RadioGroup
                        style={{ display: 'flex', flexDirection: 'row' }}
                        aria-label="Delivery Strategy"
                        name="deliveryStrategy"
                        value={getStrategyId(dist)}
                        onChange={(_e, value) => {
                          const strat = strategyFromId(value);
                          if (strat) {
                            handleChangeStrat(dist, strat);
                          }
                        }}
                      >
                        {DeliveryStrategies.map((strat) => (
                          <FormControlLabel
                            classes={{ label: classes.radioLabel }}
                            key={getStrategyId(strat)}
                            disabled={isSubmitting}
                            value={getStrategyId(strat)}
                            control={<Radio />}
                            label={strat.label}
                          />
                        ))}
                      </RadioGroup>
                    </TableCell>

                    <TableCell style={{ paddingRight: 0, textAlign: 'right' }}>
                      <SecondaryActionButton
                        disabled={isSubmitting}
                        onClick={() => dist.user && handleRemoveUser(dist.user)}
                      >
                        Remove
                      </SecondaryActionButton>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </Grid>

      <Grid item container>
        <Grid item>
          <UserDropdown
            disabled={isSubmitting}
            users={availableUsers}
            onSelect={(user) => handleAddUser(user)}
          />
        </Grid>
        {props.additionalControls && (
          <Grid item style={{ marginLeft: 'auto' }}>
            {props.additionalControls()}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
