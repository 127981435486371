import { getType } from 'typesafe-actions';
import * as alarmActions from './actions';
import { produce } from 'immer';
import { ActionsOf } from '../../../helpers/actions';
import { AlarmDetails, AlarmSummary, alarmDetailsToSummary, getAlarmUid } from '../../../models';
import { keyBy } from 'lodash';

export interface AlarmSummaryNorm extends Omit<AlarmSummary, 'alarmTag'> {
  alarmTag: string;
}

export interface AlarmDetailsNorm extends Omit<AlarmDetails, 'alarmTag'> {
  alarmTag: string;
}

export interface AlarmSummaryEntityState {
  byId: {
    [Key: string]: AlarmSummaryNorm;
  };
  byQuery: { [key: string]: string[] };
  bySite: {
    [key: number]: {
      [Key: string]: boolean;
    };
  };
}

export interface AlarmDetailEntityState {
  byId: { [Key: string]: AlarmDetailsNorm };
}

export interface AlarmStateSlice {
  entities: {
    alarmSummary: AlarmSummaryEntityState;
    alarmDetails: AlarmDetailEntityState;
  };
}

const initialAlarmSummaryState = {
  byId: {},
  bySite: {},
  byQuery: {},
};

const initialAlarmDetailState = {
  byId: {},
};

type AlarmActions = ActionsOf<typeof alarmActions>;

export const summaryReducerHelper = (
  draft: AlarmSummaryEntityState,
  siteId: number,
  queryId: string,
  alarmSummaries: AlarmSummary[],
  start?: Date,
  end?: Date
) => {
  // alarm summaries from query as normalized summaries
  const normalized: AlarmSummaryNorm[] = alarmSummaries.map((summary) => ({
    ...summary,
    alarmTag: summary.alarmTag.id,
  }));
  draft.bySite[siteId] = draft.bySite[siteId] || {};

  // We need to merge new alarm summaries by activatedAt instead of alarmId
  // This is because when changes are made to an alarm the alarmId changes.
  // When we request updated alarms we don't want duplication, and we want to
  // keep the old alarmId on our end.
  // For a given alarmTag the activatedAt:alarmTagId *should* represent a
  // static unique id.

  const existingSummaries = Object.keys(draft.bySite[siteId]).map(
    (alarmUid) => draft.byId[alarmUid]
  );
  const existingLookup = keyBy(
    existingSummaries,
    (summary) => `${summary.activatedAt}:${summary.alarmTag}`
  );

  const newLookup = keyBy(normalized, (summary) => `${summary.activatedAt}:${summary.alarmTag}`);
  const keys = new Set([...Object.keys(existingLookup), ...Object.keys(newLookup)]);

  const newSummaries: AlarmSummaryNorm[] = [];
  const querySummaries: AlarmSummaryNorm[] = [];
  keys.forEach((uid) => {
    if (newLookup[uid]) {
      const { alarmId, ...rest } = newLookup[uid];
      const updatedSummary = {
        // if there is no existing summary for this activatedAt:tagId then
        // fallback to the new summary id and new summary details
        alarmId: alarmId,
        // otherwise override the new alarmId
        ...(existingLookup[uid] as AlarmSummaryNorm | undefined),
        // but use all of the rest of the new alarm information
        ...rest,
      };
      updatedSummary.alarmUid = getAlarmUid({
        alarmId: updatedSummary.alarmId,
        alarmTagId: updatedSummary.alarmTag,
      });
      newSummaries.push(updatedSummary);
      querySummaries.push(updatedSummary);
    } else if (existingLookup[uid]) {
      newSummaries.push(existingLookup[uid]);
    }
  });
  // newSummaries now contains all summaries existing in the store updated with
  // new state, as well as any brand new summaries from this query

  // querySummaries now contains only summaries from the query, but possibly
  // with id's from a previous state if the frontend has already seen those
  // alarms

  newSummaries.forEach((normAlarmSummary) => {
    const uid = normAlarmSummary.alarmUid;
    draft.byId[uid] = normAlarmSummary;
    draft.bySite[siteId][uid] = true;
  });

  if (start && end) {
    // TODO: if api doesn't always return all active we could remove this
    draft.byQuery[queryId] = querySummaries
      .filter(
        (normAlarm) =>
          normAlarm.activatedAt >= start?.toISOString() &&
          normAlarm.activatedAt <= end?.toISOString()
      )
      .map((normAlarm) => normAlarm.alarmUid);
  } else {
    draft.byQuery[queryId] = querySummaries.map((normAlarm) => normAlarm.alarmUid);
  }
};

export const alarmSummaryEntityReducer = produce(
  (draft: AlarmSummaryEntityState, action: AlarmActions) => {
    switch (action.type) {
      case getType(alarmActions.queryAlarmSummaries.success): {
        const { triggerAction, result: alarmSummaries } = action.payload;
        const { uid: queryId } = triggerAction.meta;
        const { siteId, query } = triggerAction.payload;
        summaryReducerHelper(draft, siteId, queryId, alarmSummaries, query.start, query.end);
        break;
      }

      case getType(alarmActions.subActiveAlarms.update):
      case getType(alarmActions.subAlarmSummaries.update):
      case getType(alarmActions.fetchAlarmSummaries.success): {
        const { triggerAction, result: alarmSummaries } = action.payload;
        const { uid: queryId } = triggerAction.meta;
        const { siteId } = triggerAction.payload;
        summaryReducerHelper(draft, siteId, queryId, alarmSummaries);
        break;
      }

      /* alarm updates */
      case getType(alarmActions.addNote.success):
      case getType(alarmActions.acknowledgeAlarm.success):
      case getType(alarmActions.resolveAlarm.success): {
        const { result: alarmDetails } = action.payload;
        const existingSummaries = Object.values(draft.byId);
        const existingLookup = keyBy(
          existingSummaries,
          (summary) => `${summary.activatedAt}:${summary.alarmTag}`
        );
        const updatedKey = `${alarmDetails.activatedAt}:${alarmDetails.alarmTag.id}`;
        if (existingLookup[updatedKey]) {
          const alarmSummary = alarmDetailsToSummary(alarmDetails);
          const existingAlarm = existingLookup[updatedKey];
          const existingUid = getAlarmUid({
            alarmTagId: existingAlarm.alarmTag,
            alarmId: existingAlarm.alarmId,
          });
          draft.byId[existingUid] = {
            ...alarmSummary,
            alarmId: existingAlarm.alarmId,
            alarmTag: existingAlarm.alarmTag,
          };
        }
        break;
      }

      default:
        break;
    }
  },
  initialAlarmSummaryState
);

export const alarmDetailsEntityReducer = produce(
  (draft: AlarmDetailEntityState, action: AlarmActions) => {
    switch (action.type) {
      case getType(alarmActions.fetchAlarmDetails.success): {
        const { result: alarmDetails } = action.payload;
        const expectedAlarmId = action.payload.triggerAction.payload.alarmId;
        const uid = getAlarmUid({ alarmId: expectedAlarmId, alarmTag: alarmDetails.alarmTag });
        const { alarmTag, ...alarmRest } = alarmDetails;
        draft.byId[uid] = {
          ...alarmRest,
          alarmTag: alarmTag.id,
          alarmId: expectedAlarmId,
        };
        break;
      }

      /* alarm updates */
      case getType(alarmActions.addNote.success):
      case getType(alarmActions.acknowledgeAlarm.success):
      case getType(alarmActions.resolveAlarm.success): {
        const { result: alarmDetails } = action.payload;
        const uid = getAlarmUid(alarmDetails);
        draft.byId[uid] = {
          ...alarmDetails,
          alarmTag: alarmDetails.alarmTag.id,
        };
        break;
      }

      default:
        break;
    }
  },
  initialAlarmDetailState
);
