import React from 'react';
import * as d3 from 'd3';
import { ScreenSize, ChartSeries, SeriesPoint, Margins } from './types';
import { sumBy } from 'lodash';

const INTERVAL_MS = 15 * 60 * 1000;

interface Props {
  style?: React.CSSProperties;
  size: ScreenSize;
  margins?: Margins;
  xScale: d3.ScaleTime<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  data: BarStackedData;
}

type BarStackedData = {
  timeId: number;
  data: SeriesPoint[];
}[];

type BarLayout = [BarStackedData, { minX: number; maxX: number; minY: number; maxY: number }];

/**
 * The purpose of this function is to take multiple ChartSeries objects as
 * inputs and stack them and return multiple SeriesPoint arrays that are ready
 * to render with d3.
 *
 * Additionally this function returns the horizontal and vertical range of the data
 * after the stacking operation has been carried out.
 *
 * This function is exported because it is possible we will want to process this
 * data only once in a parent, use the range data, and then pass it as a prop to
 * this component.
 */
export const stackBarSeries = (chartSeries: ChartSeries[]): BarLayout => {
  const lookup = {};
  const seriesIds: number[] = [];

  chartSeries.forEach((series, seriesId) => {
    seriesIds.push(seriesId);
    for (const value of series.values) {
      const ts = value.x.getTime();
      const intervalTs = ts - (ts % INTERVAL_MS);

      lookup[intervalTs] = lookup[intervalTs] || {};
      lookup[intervalTs][seriesId] = lookup[intervalTs][seriesId] || [];
      lookup[intervalTs][seriesId].push(value);
    }
  });

  // at each time for each tag
  const stackedData = [];
  const timestamps = Object.keys(lookup)
    .map((t) => parseInt(t, 10))
    .sort();

  let minY = Infinity;
  let maxY = -Infinity;

  for (const intervalTs of timestamps) {
    const interval = {
      timeId: intervalTs,
      data: [] as SeriesPoint[],
    };

    let posSum = 0;
    let negSum = 0;

    for (const seriesId of seriesIds) {
      // if multiple values exist in series for a time interval use sum of
      // values for interval value
      let y = 0;
      if (lookup[intervalTs] && lookup[intervalTs][seriesId]) {
        y = sumBy(lookup[intervalTs][seriesId], (sumValue: SeriesPoint) => sumValue.y);
      }

      interval.data.push({
        styleClass: chartSeries[seriesId].styleClass,
        y0: y > 0 ? posSum : negSum,
        y: y,
        x: new Date(intervalTs),
        seriesId: seriesId,
      });

      if (y > 0) {
        posSum = posSum + y;
      } else {
        negSum = negSum + y;
      }
    }

    minY = minY > negSum ? negSum : minY;
    maxY = posSum > maxY ? posSum : maxY;
    stackedData.push(interval);
  }

  const minX = timestamps.length > 0 ? timestamps[0] : 0;
  const maxX = timestamps.length > 0 ? timestamps[timestamps.length - 1] : 0;

  return [
    stackedData,
    { minX, minY: minY === Infinity ? 0 : minY, maxX, maxY: maxY === -Infinity ? 0 : maxY },
  ];
};

class StackedBarSeries extends React.Component<Props> {
  groupRef: React.RefObject<SVGGElement> = React.createRef();

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  render() {
    return <g ref={this.groupRef}>{/* rendered with d3 */}</g>;
  }

  private update() {
    if (this.groupRef.current && this.props.xScale) {
      const { xScale, yScale } = this.props;
      const stackedData = this.props.data;
      const bandSize =
        (xScale(new Date('2019-07-12 00:15:00')) ?? 0) -
        (xScale(new Date('2019-07-12 00:00:00')) ?? 0);

      const getHeight = (d: SeriesPoint) => {
        return Math.abs((yScale(d.y0) ?? 0) - (yScale(d.y0 + d.y) ?? 0));
      };

      const g = d3.select(this.groupRef.current);

      let barUpdate = g.selectAll('.bar').data(stackedData);

      barUpdate.exit().remove();

      const barEnter = barUpdate.enter().append('g').attr('class', 'bar');
      // @ts-ignore todo: fix type
      barUpdate = barUpdate.merge(barEnter);

      let boxUpdate = barUpdate.selectAll('.box').data((d) => d.data);

      boxUpdate.exit().remove();

      const boxEnter = boxUpdate.enter().append('rect').attr('class', 'box');
      // @ts-ignore todo: fix type
      boxUpdate = boxUpdate.merge(boxEnter);

      boxUpdate
        .attr('width', bandSize - 4)
        .attr('x', (d) => xScale(d.x) ?? 0)
        .attr('y', (d) => (yScale(d.y0) ?? 0) - (d.y < 0 ? 0 : 1) * getHeight(d))
        .attr('class', (d, i) => 'box ' + (d.styleClass || ''))
        .attr('height', getHeight);
    }
  }
}

export default StackedBarSeries;
