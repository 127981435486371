import React from 'react';
import { getSiteContext } from '../../../state/contexts/site';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAlarmDetails } from '../../../state/entities';
import { findAlarmDetails } from '../../../state/entities';
import { AlarmDetailsModal } from './AlarmDetailsModal';
import { getAlarmUid } from '../../../models';
import { useParamSelector, useCurrent } from '../../../helpers/hooks';
import { useEffect } from 'react';
import { getStatus } from '../../../state/request';

export const AlarmDetailsLoader: React.FC<{ alarmId: string; alarmTagId: string }> = (props) => {
  const { alarmId, alarmTagId } = props;
  const site = useSelector(getSiteContext);
  const dispatch = useDispatch();
  const { id: siteId } = site;

  const action = useCurrent(fetchAlarmDetails.request(siteId, alarmTagId, alarmId));

  useEffect(() => {
    if (action.current) {
      dispatch(action.current);
    }
  }, [action, alarmId, alarmTagId, dispatch, siteId]);

  const requestStatus = useParamSelector(getStatus)(action.current);

  const alarmDetails = useParamSelector(findAlarmDetails)(getAlarmUid({ alarmId, alarmTagId }));

  return (
    <AlarmDetailsModal siteId={siteId} requestStatus={requestStatus} alarmDetails={alarmDetails} />
  );
};
