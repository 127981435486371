import React from 'react';
import { Typography, Grid, Dialog, DialogContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import UserTable from './UserTable';
import { NewUser, User, UserProfile } from '../../../models';
import DeleteUser from './DeleteUserDialog';
import InviteUserForm from './InviteUserForm';
import EditUserForm from './EditUserForm';
import AddExistingUserForm from './AddExistingUserForm';
import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email';
import {
  updateUser,
  createUser,
  removeFromSite,
  deleteUser,
  resendInvite,
} from '../../../state/entities';
import { WithRequest } from '../../utility/WithRequest';
import { RequestLifecycle } from '../../ui/RequestLifecycle';
import { PrimaryButton } from '../../ui/Buttons';
import { useEffect } from 'react';

const Modal: React.FC = (props) => {
  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
};

enum UserPageAction {
  NEW,
  EDIT,
  DELETE,
  REMOVE,
  ADD_EXISTING,
  RESEND_INVITE,
  NONE,
}

export interface Props {
  profile: UserProfile;
  siteId: number;
  customerId: number;
  siteUsers: User[];
  allUsers: User[];
}

export interface DispatchProps {
  updateUser: (user: User) => void;
  createNewUser: (user: NewUser) => void;
  deleteUser: (user: User) => void;
  removeFromSite: (userId: number, siteId: number) => void;
  resendInvite: (user: User) => void;
  clearError: (actionId: string) => void;
}

type AllProps = Props & DispatchProps;

interface UserPageState {
  action: UserPageAction;
  userId: number | undefined;
  sortByItem?: string;
}

const ResendInviteStatusModal = (props: { user: User; resetState: () => void }) => {
  const { user, resetState } = props;
  return (
    <Modal>
      <WithRequest
        actionCreator={resendInvite.request}
        render={({ boundActionCreator, status, isComplete, isError }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            boundActionCreator(user);
            // todo: add useEffectOnce hook?
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, []);

          return (
            <>
              <RequestLifecycle
                status={status}
                successMessage={`Invite sent to ${user.emailAddress}.`}
              />
              {(isComplete || isError) && (
                <Grid item container justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <PrimaryButton onClick={resetState}>Ok</PrimaryButton>
                  </Grid>
                </Grid>
              )}
            </>
          );
        }}
      />
    </Modal>
  );
};

class UserPage extends React.Component<AllProps, UserPageState> {
  state = {
    action: UserPageAction.NONE,
    userId: undefined,
  };

  setActionAndUserId(action: UserPageAction, userId: number | undefined) {
    this.setState({
      action,
      userId,
    });
  }

  get showUserPage() {
    return (
      <>
        <Grid container direction="column" style={{ padding: 8 }}>
          <Grid container item xs={12} justifyContent="space-between" alignItems="center">
            <Grid />
            <Grid
              xs={6}
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              style={{ marginBottom: 8 }}
            >
              <Grid item>
                <PrimaryButton size="small" onClick={this.setAddExistingUserState}>
                  <AddIcon style={{ paddingRight: 8 }} />
                  Add Existing User
                </PrimaryButton>
              </Grid>
              <Grid item>
                <PrimaryButton size="small" onClick={this.setNewUserState}>
                  <EmailIcon style={{ paddingRight: 8 }} />
                  Invite New User
                </PrimaryButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <UserTable
              siteId={this.props.siteId}
              rows={this.props.siteUsers}
              handleDeleteUser={(userId) => this.setDeleteModal(userId)}
              handleRemoveUserFromSite={(userId) => this.setRemoveFromSiteModal(userId)}
              handleEditUser={(user) => this.setEditUserModal(user.userId)}
              handleInviteUser={(user) => {
                this.setResendInvite(user.userId);
              }}
            />
            {this.state.action !== UserPageAction.NONE && this.modalToRender}
          </Grid>
        </Grid>
      </>
    );
  }

  get modalToRender() {
    switch (this.state.action as UserPageAction) {
      case UserPageAction.DELETE:
        return this.showDeleteModal;
      case UserPageAction.EDIT:
        return this.showEditModal;
      case UserPageAction.NEW:
        return this.showNewUserModal;
      case UserPageAction.REMOVE:
        return this.showRemoveFromSiteModal;
      case UserPageAction.ADD_EXISTING:
        return this.showAddExistingUserModal;
      case UserPageAction.RESEND_INVITE:
        return this.showResendInviteModal;
      default:
        return null;
    }
  }

  get showDeleteModal() {
    return (
      <Modal>
        <WithRequest
          actionCreator={deleteUser.request}
          render={({ boundActionCreator, status, isComplete, isError }) => {
            const possibleUserToDelete = this.props.siteUsers.find(
              (user) => user.userId === this.state.userId
            );

            return (
              <>
                <RequestLifecycle status={status} successMessage={`User account deleted.`}>
                  {possibleUserToDelete && (
                    <DeleteUser
                      user={possibleUserToDelete}
                      onAccept={() => boundActionCreator(possibleUserToDelete)}
                      onCancel={() => {
                        this.resetState();
                      }}
                    >
                      {this.props.profile.userId === this.state.userId && (
                        <Alert severity="warning">
                          Warning: you are about to delete your account. If you do this, you will
                          lose access.
                        </Alert>
                      )}
                      <Typography variant="body1" style={{ margin: '1em' }}>
                        Are you sure you want to delete {possibleUserToDelete.name}?
                      </Typography>
                    </DeleteUser>
                  )}
                </RequestLifecycle>
                {(isComplete || isError) && (
                  <Grid item container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                      <PrimaryButton onClick={this.resetState}>Ok</PrimaryButton>
                    </Grid>
                  </Grid>
                )}
              </>
            );
          }}
        />
      </Modal>
    );
  }

  get showRemoveFromSiteModal() {
    const userToRemove = this.props.siteUsers.find((user) => user.userId === this.state.userId);
    if (!userToRemove) {
      return null;
    }

    const isDangerousSelfRemove =
      this.props.profile.userId === userToRemove.userId &&
      !(this.props.profile.isSystemAdmin || this.props.profile.isCustomerAdmin);
    return (
      <Modal>
        <WithRequest
          actionCreator={removeFromSite.request}
          render={({ boundActionCreator, status, isComplete, isError }) => (
            <>
              <RequestLifecycle status={status} successMessage={`User removed from site.`}>
                <DeleteUser
                  actionLabel="Remove"
                  title="Remove User"
                  user={userToRemove}
                  onAccept={() => boundActionCreator(userToRemove.userId, this.props.siteId)}
                  onCancel={() => {
                    this.resetState();
                  }}
                >
                  {isDangerousSelfRemove ? (
                    <>
                      <Typography variant="body1" color="error" style={{ margin: '1em' }}>
                        Warning: Removing yourself from this site will cause you to lose access.
                      </Typography>
                      <Typography variant="body1" style={{ margin: '1em' }}>
                        Are you sure you want to remove yourself from this site?
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body1" style={{ margin: '1em' }}>
                      Are you sure you want to remove {userToRemove.name} from this site?
                    </Typography>
                  )}
                </DeleteUser>
              </RequestLifecycle>
              {(isComplete || isError) && (
                <Grid item container justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <PrimaryButton onClick={this.resetState}>Ok</PrimaryButton>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        />
      </Modal>
    );
  }

  get showNewUserModal() {
    return (
      <Modal>
        <WithRequest
          actionCreator={createUser.request}
          render={({ boundActionCreator, status, isComplete, isError }) => (
            <>
              <RequestLifecycle status={status} successMessage={`User account created.`}>
                <InviteUserForm
                  siteId={this.props.siteId}
                  customerId={this.props.customerId}
                  onSave={(user) => {
                    boundActionCreator(user);
                  }}
                  onCancel={this.resetState}
                />
              </RequestLifecycle>
              {(isComplete || isError) && (
                <Grid item container justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <PrimaryButton onClick={this.resetState}>Ok</PrimaryButton>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        />
      </Modal>
    );
  }

  get showAddExistingUserModal() {
    return (
      <Modal>
        <WithRequest
          actionCreator={updateUser.request}
          render={({ boundActionCreator, status, isComplete, isError }) => (
            <>
              <RequestLifecycle status={status} successMessage={`User added to site.`}>
                <AddExistingUserForm
                  siteId={this.props.siteId}
                  users={this.unAddedUsers}
                  onSave={(user) => {
                    boundActionCreator(user);
                  }}
                  onCancel={this.resetState}
                />
              </RequestLifecycle>
              {(isComplete || isError) && (
                <Grid item container justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <PrimaryButton onClick={this.resetState}>Ok</PrimaryButton>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        />
      </Modal>
    );
  }

  get showResendInviteModal() {
    const userToResend = this.props.siteUsers.find((user) => user.userId === this.state.userId);
    if (!userToResend) {
      return null;
    }

    return <ResendInviteStatusModal user={userToResend} resetState={() => this.resetState()} />;
  }

  get unAddedUsers() {
    const addedUserIds = this.props.siteUsers.map((siteUser) => siteUser.userId);
    return this.props.allUsers.filter((user) => !addedUserIds.includes(user.userId));
  }

  get showEditModal() {
    const userToEdit = this.props.siteUsers.find((user) => user.userId === this.state.userId);
    if (!userToEdit) {
      return null;
    }

    return (
      <Modal>
        <WithRequest
          actionCreator={updateUser.request}
          render={({ boundActionCreator, status, isComplete, isError }) => (
            <>
              <RequestLifecycle status={status}>
                <EditUserForm
                  siteId={this.props.siteId}
                  user={userToEdit}
                  onSave={(user) => {
                    boundActionCreator(user);
                  }}
                  onCancel={this.resetState}
                />
              </RequestLifecycle>
              {(isComplete || isError) && (
                <Grid item container justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <PrimaryButton onClick={this.resetState}>Ok</PrimaryButton>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        />
      </Modal>
    );
  }

  render() {
    /*
     * It is possible that a user might be assigned to many sites but none of
     * them related to the customer they are assigned to. In this case show a
     * warning and not the page.
     
      if (this.props.sites.length === 0) {
        return <div>No sites configured for current customer</div>;
      }
     */

    return this.showUserPage;
  }

  resetState = () => {
    this.setActionAndUserId(UserPageAction.NONE, undefined);
  };

  setDeleteModal = (userId: number) => {
    if (userId) {
      this.setActionAndUserId(UserPageAction.DELETE, userId);
    }
  };

  setRemoveFromSiteModal = (userId: number) => {
    if (userId) {
      this.setActionAndUserId(UserPageAction.REMOVE, userId);
    }
  };

  setResendInvite = (userId: number) => {
    if (userId) {
      this.setActionAndUserId(UserPageAction.RESEND_INVITE, userId);
    }
  };

  setNewUserState = () => {
    this.setActionAndUserId(UserPageAction.NEW, undefined);
  };

  setAddExistingUserState = () => this.setActionAndUserId(UserPageAction.ADD_EXISTING, undefined);

  setEditUserModal = (userId: number) => {
    if (userId) {
      this.setActionAndUserId(UserPageAction.EDIT, userId);
    }
  };
}

export default UserPage;
