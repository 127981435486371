import { AlarmApi } from '../AlarmApi';
import { sleep } from '../../helpers/async';
import { AlarmSummary } from '../../models';
import { SubscriptionBase } from '../util/SubscriptionBase';
import { SDate } from '../../helpers/SettableDate';
import { ApiConnection } from '../util/ApiConnection';

export class AlarmSubscription extends SubscriptionBase<AlarmSummary[]> {
  private apiAccess?: AlarmApi;

  async *getGenerator(
    siteId: number,
    lookback: number,
    activeOnly: boolean
  ): AsyncGenerator<AlarmSummary[]> {
    if (!this.apiAccess) return;

    this.interval = 1000 * 60 * 0.25;
    // We first request snapshots from start up to now
    const latest = new Date(SDate.now());
    const start = new Date(SDate.now() - (lookback || 0));
    let alarms = await this.apiAccess.fetchAlarmSummaries(siteId, start, latest, activeOnly);
    yield alarms;
    while (!this.stopped) {
      await sleep(this.interval);
      // we keep extending this interval as alarms can be updated as well as
      // created and we want the interface to stay up to date.
      const nextLatest = new Date(SDate.now());
      alarms = await this.apiAccess.fetchAlarmSummaries(siteId, start, nextLatest, activeOnly);
      yield alarms;
    }
  }

  start(siteId: number, lookback: number, activeOnly: boolean) {
    this.apiAccess = new AlarmApi(this.connection);
    this.generator = this.getGenerator(siteId, lookback, activeOnly);
  }

  updateConnection(connection: ApiConnection) {
    this.setConnection(connection);
    this.apiAccess = new AlarmApi(this.connection);
  }
}
