import { Deferred } from '../../helpers/Deferred';
import { ApiError } from '../../api/util/ApiError';
import { v1 } from 'uuid';
import { ApiConnection } from '../util/ApiConnection';
import { api as apiConfig } from '../../config';
import { flatMap, Dictionary } from 'lodash';

export interface ResponseBase {
  callId: string;
  statusCode: number;
}

export interface PagedPayloadBase {
  isFinal?: boolean;
  index?: number;
}

export interface PushResponse<TPayload> extends ResponseBase {
  payloadType: string;
  payload: TPayload;
}

export interface StatusResponse extends ResponseBase {
  payloadType: null;
  payload: null | string;
}

interface SingleMessageRequestConfig<P, T> {
  timeout?: number;
  payloadReducer?: (payload: P) => T;
}

interface MultiMessageArgs<T, P, B> {
  uri: string;
  method: 'GET' | 'POST';
  payloadMashaler: MultiPayloadMarshaler<P, T>;
  body?: B;
  timeout?: number;
}

type MultiPayloadMarshaler<M, R> = (
  deffered: Deferred<R, ApiError>,
  message: PushResponse<M> | StatusResponse
) => void;

/**
 * Some error responses have string payloads and some have the format:
 *  {
 *    errorMessage: "Message"
 *  }
 *  We want to handle both.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const readPushErrorMessage = (pushMessage: PushResponse<any>) => {
  if (typeof pushMessage.payload === 'string') {
    return pushMessage.payload as string;
  } else if (
    pushMessage.payload &&
    pushMessage.payload.errorMessage &&
    typeof pushMessage.payload.errorMessage === 'string'
  ) {
    return pushMessage.payload.errorMessage;
  } else {
    return 'Error processing request';
  }
};

export class PushClient {
  protected connection: ApiConnection;

  constructor(connection: ApiConnection) {
    this.connection = connection;
  }

  /**
   * Make get request, and expect one push message as response
   */
  public singleMessageGetRequest<T, P = T>(
    uri: string,
    config?: SingleMessageRequestConfig<P, T>
  ): Promise<T> {
    return this.singleMessageRequest(uri, 'GET', undefined, config);
  }

  /**
   * Make post request, and expect one push message as response
   */
  public singleMessagePostRequest<T, P = T, B = undefined>(
    uri: string,
    body: B,
    config?: SingleMessageRequestConfig<P, T>
  ): Promise<T> {
    return this.singleMessageRequest(uri, 'POST', body, config);
  }

  /**
   * Make get request and expect multiple messages, must provide function to
   * accumulate messages.
   */
  public async multiMessageGetRequest<T, P = T>(
    uri: string,
    payloadMashaler: MultiPayloadMarshaler<P, T>
  ) {
    return this.multiMessageRequest<T, P>({ payloadMashaler, uri, method: 'GET' });
  }

  /**
   * Catchall method to encapsulate the complexity of making a request to an
   * endpoint which will result in one or more push message responses.
   *
   * Generic Params:
   *  T = Type of result which will ultimately be returned
   *  P = Type of message payload, defaults to return type
   *  B = Type of http request body, defaults to undefined
   *
   * Method Argument Object Properties:
   *  uri, method, body: together specify the details of the http request
   *  timeout: (optional) defaults to appConfig.pushTimeoutDefault
   *  payloadMashaler:
   *    This should be a function with signature MultiPayloadMarshaler
   *    Whose purpose is to collect messages as they arrive and call
   *    finished.resolve with the response result when all messages have
   *    arrived. Or finished.reject if an error is detected.
   *
   * TODO: Add retry / timeout logic for the http request
   */
  public async multiMessageRequest<T, P = T, B = undefined>(
    args: MultiMessageArgs<T, P, B>
  ): Promise<T> {
    const { uri, payloadMashaler, body, method } = args;
    const { client, pushHub, connectionId } = this.connection;
    const callId = v1();
    const finished = new Deferred<T, ApiError>();

    // register a listener to handle responses for this request, based on
    // callId
    pushHub.registerListener(callId, (message: PushResponse<P> | StatusResponse) => {
      payloadMashaler(finished, message);
    });

    const timeout = args.timeout || apiConfig.pushTimeoutDefault;
    const timeoutHandle = setTimeout(() => {
      pushHub.deleteListener(callId);
      finished.reject(
        new ApiError(
          {
            statusCode: 408,
            payload: { uri, body, method },
            message: '',
          },
          `Push request timeout, messages not recieved in ${timeout} ms.`
        )
      );
    }, timeout);

    const requestConfig = {
      headers: {
        HubConnectionId: connectionId,
        CallId: callId,
      },
    };

    // Make http request to api, and wait for success
    if (method === 'GET') {
      await client.get<''>(uri, requestConfig);
    } else if (method === 'POST') {
      await client.post<'', B>(uri, body, requestConfig);
    }

    // wait for push messages to be received
    const result = await finished.promise;

    // cleanup resources
    pushHub.deleteListener(callId);
    clearTimeout(timeoutHandle);

    return result;
  }

  public get client() {
    return this.connection.client;
  }

  public getSingleMessageMarshaler<T, P>(config?: SingleMessageRequestConfig<P, T>) {
    return (finished: Deferred<T, ApiError>, message: PushResponse<P>) => {
      if (message.statusCode > 299) {
        const errorMessage = readPushErrorMessage(message);

        finished.reject(
          new ApiError(
            {
              statusCode: message.statusCode,
              payload: message.payload,
              message: errorMessage,
            },
            errorMessage
          )
        );
      } else if (message.payloadType !== null) {
        if (config?.payloadReducer) {
          finished.resolve(config.payloadReducer(message.payload));
        } else {
          // TODO: figure out how to get typescript to infer this type
          finished.resolve(message.payload as unknown as T);
        }
      }
    };
  }

  /**
   * Paged message marshaller which can be used when the result is an array type.
   * Simply concatenates all results in paged messages into one result and
   * returns it.
   */
  public getPagedMessageMarshaler<R, P extends PagedPayloadBase>(
    config?: SingleMessageRequestConfig<P, R[]>
  ) {
    const payloadBuffer: Dictionary<R[]> = {};
    let totalExpected: number;
    let receivedCount = 0;
    return (finished: Deferred<R[], ApiError>, message: PushResponse<P>) => {
      if (message.statusCode > 299) {
        const errorMessage = readPushErrorMessage(message);
        finished.reject(
          new ApiError(
            {
              statusCode: message.statusCode,
              payload: message.payload,
              message: errorMessage,
            },
            errorMessage
          )
        );
      } else if (message.payloadType !== null && message.payload && message.payload.index) {
        const { index } = message.payload;
        receivedCount++;
        if (config?.payloadReducer) {
          payloadBuffer[index] = config.payloadReducer(message.payload);
        } else {
          // TODO: figure out how to get typescript to infer this type
          payloadBuffer[index] = message.payload as unknown as R[];
        }
        if (message.payload && message.payload.isFinal && message.payload.index !== undefined) {
          totalExpected = message.payload.index;
        }
        if (totalExpected && receivedCount === totalExpected) {
          const indicies = Object.keys(payloadBuffer).sort();
          const result = flatMap(indicies.map((i) => payloadBuffer[i]));
          finished.resolve(result);
        }
      }
    };
  }

  /**
   * Make get or post request, and expect one push message as response
   */
  private async singleMessageRequest<T, P = T, B = undefined>(
    uri: string,
    method: 'GET' | 'POST',
    body?: B,
    config?: SingleMessageRequestConfig<P, T>
  ): Promise<T> {
    return this.multiMessageRequest({
      uri,
      method,
      // @ts-ignore todo: fix type
      payloadMashaler: this.getSingleMessageMarshaler(config),
      body,
      timeout: config?.timeout,
    });
  }
}
