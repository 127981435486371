/* eslint-disable @typescript-eslint/no-explicit-any */

type PureSelector<RootState> = (r: RootState) => object;
type Cache<Result> = Map<object, Result | Cache<Result>>;
type Selector<RootState, Args extends any[], Result> = (state: RootState, ...rest: Args) => Result;
type Wrapped<Result> = { result: Result };

/**
 * Provides a means to cache computed results of selector calls based on
 * parts of the store, and the arguments passed to the selector.
 *
 * Constructor Arguments:
 *  selector: The selector which takes some number of arguments, with the first
 *    being the application root state
 *  keyFuncs: An array of "pure" functions that return unmodified references
 *    into portions of the application state.
 *
 *  Generic Arguments:
 *    RootState - full application state tree
 *    Args - Type of selector arguments after root state
 *    Result - Result type of selector we are memoizing
 *
 *  TODO: Limit maximum number of cache entries
 *
 *  Implementation:
 *
 *  We create a tree where each node is a Map instance.
 *
 *        Map -> [PureResult1]:Map -> [PureResult2]:Map
 *                                                  / \
 *                                                 V   V
 *                                        [arg1]:Map   [arg1]:Map
 *                                               /            /
 *                                              V            V
 *                                    [arg2]:Result1      [arg2]:Result2
 *
 *  Results are all at the same level of the tree determined by the number of
 *  cache keys specified.
 *
 */
class MemoCache<RootState, Result, Args extends any[]> {
  private root: Cache<Wrapped<Result>> = new Map();

  private keyFuncs: PureSelector<RootState>[];

  private selector: Selector<RootState, Args, Result>;

  constructor(selector: Selector<RootState, Args, Result>, keysFuncs: PureSelector<RootState>[]) {
    this.keyFuncs = keysFuncs;
    this.selector = selector;
  }

  /**
   * Either returns cached value from the cache tree, or if no cache entry
   * exists for the set of cache keys, computes a new value, inserts it into
   * the cache, and returns that value.
   */
  public lookup(state: RootState, ...rest: Args) {
    let [prevRef, cacheRef, matchDepthIdx] = this.lookupHelper(state, ...rest);

    if (cacheRef !== undefined) {
      return cacheRef.result;
    } else {
      const result = this.selector(state, ...rest);
      // if we got a cache miss from one of the pure selectors we can discard
      // the whole cache, we do so because if the state has changed it is
      // possible any results could have been invalidated.
      if (matchDepthIdx < this.keyFuncs.length) {
        this.root = new Map();
        prevRef = this.root;
        matchDepthIdx = 0;
      }
      this.insert(result, matchDepthIdx, prevRef, state, ...rest);
      return result;
    }
  }

  private wrapResult(result: Result) {
    return {
      result,
    };
  }

  /**
   * Find node in cache tree where new value should be inserted, or where the
   * result is contained.
   */
  private lookupHelper(state: RootState, ...rest: Args) {
    const cacheKeys = [...this.keyFuncs.map((fn) => fn(state)), ...rest];

    let prevRef: Cache<Wrapped<Result>> = this.root;
    let cacheRef: Cache<Wrapped<Result>> | Wrapped<Result> | undefined = undefined;

    // We start at the root of the cache tree, and traverse downward. At each
    // level of the tree we check a different cache key until we reach a node
    // where we don't have possible match, or a leaf.
    let matchDepth;
    for (matchDepth = 0; matchDepth < cacheKeys.length; matchDepth++) {
      cacheRef = prevRef.get(cacheKeys[matchDepth]);
      if (cacheRef instanceof Map) {
        prevRef = cacheRef;
      } else {
        break;
      }
    }

    // We return both the depth and the reference where the search terminates,
    // and the parent reference where we would be able to insert a new child
    return [prevRef, cacheRef, matchDepth] as [
      Cache<Wrapped<Result>>,
      Wrapped<Result> | undefined,
      number
    ];
  }

  private insert(
    result: Result,
    startIdx: number,
    startRef: Cache<Wrapped<Result>>,
    state: RootState,
    ...rest: Args
  ) {
    /*
     * We first cache by the references to substates of the rootstate, then by
     * arguments to the selector.
     */
    const cacheKeys = [...this.keyFuncs.map((fn) => fn(state)), ...rest];
    let prevRef = startRef;
    let cacheRef: Cache<Wrapped<Result>> | Wrapped<Result> | undefined = undefined;

    // we start traversing downward in the cache tree from the node referenced by
    // startRef, which has depth startIdx. This saves us from having to start
    // at the root.
    for (let depth = startIdx; depth < cacheKeys.length - 1; depth++) {
      cacheRef = prevRef.get(cacheKeys[depth]);
      if (cacheRef instanceof Map) {
        prevRef = cacheRef;
      } else if (cacheRef === undefined) {
        const temp = new Map();
        prevRef.set(cacheKeys[depth], temp);
        prevRef = temp;
      }
    }
    prevRef.set(cacheKeys[cacheKeys.length - 1], this.wrapResult(result));
  }
}

/**
 * Memoizes a selector of the form:
 *
 * (state: RootState, arg1: T1, arg2: T2...)
 *
 * First parameter should be the parameterized selector.
 *
 * The second argument should be an array of "pure" selectors. A pure selector
 * MUST directly return some portion of the state tree WITHOUT modification.
 *
 * The memoization works by directly comparing the objects returned by these
 * pure selectors. If a state update occurs and some portion of the state tree
 * is modified then a subsequent call to the memoized selector will detect the
 * change and break the cache.
 *
 * If different arguments are passed the cache is extended so subsequent
 * calls with the same arguments will return the cached value. Any change to
 * the return values provided by the "pure selectors" will cause all cached
 * values to be discarded.
 *
 * Note: In most cases type arguments will be inferred from context, and
 * shouldn't need to be explicitly stated.
 */
export const memoizeSelector = <RootState extends any, Args extends any[], Result extends any>(
  selector: Selector<RootState, Args, Result>,
  keysFuncs: PureSelector<RootState>[]
) => {
  const cache = new MemoCache(selector, keysFuncs);

  return (rootState: RootState, ...rest: Args) => {
    return cache.lookup(rootState, ...rest);
  };
};
