import React from 'react';
import { mpulseIcon } from '../../../config';
import bgImage from '../../../assets/bg.jpg';

export const LoadingPage: React.FC = (props) => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
      }}
    >
      <img alt="mPulse" style={{ width: 48 }} src={mpulseIcon} />
      {props.children ? props.children : null}
    </div>
  );
};
