import { useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, ListItemText, makeStyles, Tab, Tabs, Theme, Tooltip } from '@material-ui/core';
import { PathBuilder } from '../../../routing';
import { EquipmentPageProps } from './EquipmentPage.d';
import { TagIcon } from '../../ui/Icons';
import EquipmentListControls, { INITIAL_LIST_STATE, listReducer } from './EquipmentListControls';
import EquipmentListPinnedTags from './EquipmentListPinnedTags';
import identifiers from '../../../tests/identifiers';
import { EquipmentType } from '../../../models';

const LIST_WIDTH = 260;
const LIST_WIDTH_LARGE = 320;

const EquipmentList = (props: EquipmentPageProps) => {
  const { activeEquipmentId, activeTab, allEquipment, pinnedTagIds, siteId } = props;
  const classes = useStyles();
  const history = useHistory();

  const [listState, listDispatch] = useReducer(listReducer, INITIAL_LIST_STATE);
  const { categories, search, types } = listState;

  const filteredEquipment = useMemo(
    () =>
      allEquipment
        .filter((item) => {
          // Filter by search term
          if (search.length > 0) {
            // Check equipment name
            if (item.label.toLowerCase().indexOf(search.toLowerCase()) === -1) {
              return false;
            }
          }

          // Filter by category
          if (categories.length > 0 && !categories.includes(item.category)) {
            return false;
          }

          // Filter by type
          return !(types.length > 0 && !types.includes(item.equipmentType));
        })
        .sort((a, b) => {
          // Sort ascending by tile order
          const sort = a.tileOrder - b.tileOrder;
          // Sort by label for equal tile orders
          return sort === 0 ? a.label.localeCompare(b.label) : sort;
        }),
    [allEquipment, categories, search, types]
  );

  // Get number of pinned tags per piece of equipment
  const pinnedTagCount = useMemo(() => {
    const obj = {};
    allEquipment.forEach(({ id, tags }) => {
      obj[id] = tags.reduce((count, tag) => (pinnedTagIds.includes(tag.id) ? count + 1 : count), 0);
    });
    return obj;
  }, [allEquipment, pinnedTagIds]);

  // Get current tab index
  const tabIndex = useMemo(() => {
    const index = filteredEquipment.findIndex(({ id }) => id === activeEquipmentId);
    return index === -1 ? false : index;
  }, [activeEquipmentId, filteredEquipment]);

  return (
    <div className={classes.list} data-testid={identifiers.equipmentPage.list}>
      <EquipmentListControls
        allEquipment={allEquipment}
        listState={listState}
        listDispatch={listDispatch}
      />
      <EquipmentListPinnedTags {...props} />
      <Tabs
        key={filteredEquipment.length} // Rerender on list change to fix scroll arrows
        aria-label="Equipment List"
        classes={{
          root: classes.tabs,
          indicator: classes.indicator,
          scrollButtons: classes.scrollButtons,
        }}
        orientation="vertical"
        scrollButtons="on"
        value={tabIndex}
        variant="scrollable"
      >
        {filteredEquipment.map(({ equipmentType, id, label, serialNumber, installedCapacity }) => {
          return (
            <Tab
              key={id}
              label={
                <ListItemText
                  className={classes.listItem}
                  primary={
                    <>
                      {label}
                      <PinnedTagCount count={pinnedTagCount[id]} />
                    </>
                  }
                  secondary={
                    <>
                      {/* We want to hide type if "Other" */}
                      {equipmentType !== EquipmentType.Other
                        ? equipmentType + (installedCapacity ? ` (${installedCapacity} kWh)` : '')
                        : ''}
                      {serialNumber && (
                        <>
                          {equipmentType !== EquipmentType.Other && <br />}
                          {'SN: ' + serialNumber}
                        </>
                      )}
                    </>
                  }
                />
              }
              id={`equipment-tabpanel-${id}`}
              aria-controls={`equipment-tab-${id}`}
              classes={{
                root: classes.tab,
                selected: classes.selected,
              }}
              onClick={() => {
                history.push(PathBuilder.EQUIPMENT(siteId, pinnedTagIds, id, activeTab));
              }}
            />
          );
        })}
      </Tabs>
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  indicator: {
    backgroundColor: theme.sidebar.highlight,
    width: 4,
  },
  list: {
    background: theme.palette.grey[50],
    borderRight: `1px solid ${theme.palette.divider}`,
    boxShadow: '2px 2px 2px rgba(0,0,0,0.04)',
    display: 'flex',
    flexBasis: LIST_WIDTH,
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    maxWidth: LIST_WIDTH,
    paddingLeft: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      background: 'transparent',
      borderRight: 0,
      flexBasis: 'auto',
      maxWidth: 'none',
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      flexBasis: LIST_WIDTH_LARGE,
      maxWidth: LIST_WIDTH_LARGE,
    },
  },
  listItem: {
    width: '100%',
  },
  selected: {
    background: theme.sidebar.main,
    color: '#fff',
    '& .MuiTypography-colorTextSecondary': {
      color: '#ccc',
    },
  },
  tab: {
    maxWidth: 'none',
    paddingLeft: theme.spacing(3),
    textAlign: 'left',
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'none',
    },
  },
  tabs: {
    width: '100%',
  },
  scrollButtons: {
    height: theme.spacing(3),
  },
}));

const PinnedTagCount = ({ count }: { count: number }) => {
  const classes = usePinnedTagCountStyles();
  if (count < 1) {
    return null;
  }
  return (
    <Tooltip title={`${count} Pinned Tag${count === 1 ? '' : 's'}`}>
      <Badge
        badgeContent={count}
        classes={{
          root: classes.root,
          badge: classes.badge,
        }}
        color="primary"
      >
        <TagIcon className={classes.icon} />
      </Badge>
    </Tooltip>
  );
};

const usePinnedTagCountStyles = makeStyles<Theme>((theme) => ({
  root: {
    margin: theme.spacing(-0.25, 0.5, -0.5),
    transform: 'scale(0.75)',
  },
  badge: {
    boxShadow: `0px 0px 1px 2px  ${theme.palette.grey[50]}`,
    right: -5,
  },
  icon: {
    height: '1.15em',
    width: '1.15em',
  },
}));

export default EquipmentList;
