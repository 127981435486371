import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';

export type NumberFieldProps = TextFieldProps & {
  max?: number;
  min?: number;
  unit?: string;
};

export const NumberField = ({ max, min, unit, ...otherProps }: NumberFieldProps) => {
  const classes = useStyles();
  return (
    <TextField
      InputProps={{
        endAdornment: unit ? <span className={classes.unit}>{unit}</span> : null,
        inputProps: {
          max,
          min,
          ...otherProps.InputProps?.inputProps,
        },
        ...otherProps.InputProps,
      }}
      type="number"
      {...otherProps}
      onBlur={(event) => {
        // Clear input when losing focus with an invalid number
        if (!event.target.checkValidity()) {
          event.target.value = '';
        }

        if (otherProps.onBlur) {
          otherProps.onBlur(event);
        }
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  unit: {
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
}));
