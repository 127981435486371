import { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SecondaryButton } from '../Buttons';
import { Utility } from '../../../models';
import { ChangeRateFormValues, ChangeRateFormProps } from './ChangeRateForm.types';
import { UtilityRate, UtilityRateValidation } from './UtilityRate';
import Alert from '@material-ui/lab/Alert';

const validationSchema = Yup.object().shape(UtilityRateValidation());

const ChangeRateForm = (props: ChangeRateFormProps) => {
  const utilities = props.utilities ?? Array<Utility>();
  const initialValues: ChangeRateFormValues = props;
  const { cancelAction, dismissError, handleSubmit, handleSuccess, submission } = props;

  const [errored, setError] = useState(false);

  useEffect(() => {
    if (submission.error) {
      setError(true);
    }

    if (submission.success) {
      handleSuccess();
    }
  }, [submission.error, submission.success, handleSuccess]);

  return errored ? (
    <Alert
      action={
        <Button
          color="inherit"
          onClick={(e) => {
            dismissError();
            setError(false);
          }}
        >
          Close
        </Button>
      }
      severity="error"
    >
      Failed to update rate.
    </Alert>
  ) : (
    <Box marginTop={1.5}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <Grid container direction="column" spacing={3}>
              <UtilityRate isUtilityDisabled={true} utilities={utilities}></UtilityRate>
              <Grid item container justifyContent="flex-end" direction="row" spacing={1}>
                <Grid item>
                  <SecondaryButton onClick={cancelAction}>Cancel</SecondaryButton>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || !dirty}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ChangeRateForm;
