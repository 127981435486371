import * as React from 'react';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { PathBuilder } from '../../../routing';
import { AddressForm } from '../../ui/form/AddressForm';
import { getFormikInputProps } from '../../../helpers/form';
import { InstallerFormProps, InstallerSteps } from './InstallerPage.types';
import { formatAddress } from '../../../models';

const FIELD_SITE_NAME_MAX_LENGTH = 48;
const FIELD_SITE_NAME_MIN_LENGTH = 3;

interface SiteLocationProps {
  setActiveStep: (step: number) => void;
  siteId: number;
  onCountryChange: (country: string) => void;
  updatePostalCode: (postalCode: string) => void;
}

const SiteLocation = (props: SiteLocationProps) => {
  const { setActiveStep, siteId, onCountryChange, updatePostalCode } = props;
  const history = useHistory();
  const formik = useFormikContext<InstallerFormProps>();

  // Keep track of country selection to update validation schema
  // todo: find a cleaner way to do this (country value needs to be available before formik is declared)
  React.useEffect(() => {
    onCountryChange(formik.values.address.country);
  }, [formik.values.address.country]);

  return (
    <>
      <Box marginTop={3} marginBottom={3}>
        <TextField
          label="Site Name"
          inputProps={{
            maxLength: FIELD_SITE_NAME_MAX_LENGTH,
            minLength: FIELD_SITE_NAME_MIN_LENGTH,
          }}
          {...getFormikInputProps(formik, 'siteName')}
        />
      </Box>
      <AddressForm formik={formik} objectKey="address" />

      <Box marginTop={2} textAlign="right">
        <Button
          onClick={() => {
            history.push(PathBuilder.INSTALLER(siteId));
            setActiveStep(InstallerSteps.SKU);
          }}
        >
          Back
        </Button>
        <Button
          color="primary"
          onClick={() => {
            updatePostalCode(formik.values.address.postalCode.toString());
            formik.submitForm();
          }}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export const SiteLocationStepLabel = (props: { activeStep: InstallerSteps }) => {
  const formik = useFormikContext<InstallerFormProps>();
  return props.activeStep > InstallerSteps.LOCATION ? (
    <>
      <Typography variant="h6">{formik.values.siteName}</Typography>
      <Typography variant="subtitle1">{formatAddress(formik.values.address)}</Typography>
    </>
  ) : (
    <Typography variant="h6">Site Location</Typography>
  );
};

export default SiteLocation;
