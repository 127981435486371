import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from './components/theme';
import { configureStore, getHistory } from './state';
import { auth } from './config';
import './index.css';
import ErrorBoundary from './components/utility/ErrorBoundary';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Root } from './components/Root';

const store = configureStore();
const history = getHistory();

const handleRedirectCallback = (appState: AppState) => {
  const { returnTo } = appState;
  if (returnTo) {
    history.push(returnTo);
  }
};

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConnectedRouter history={history}>
        <ErrorBoundary>
          <Auth0Provider
            domain={auth.domain}
            clientId={auth.clientID}
            redirectUri={auth.redirectUri}
            audience={auth.audience}
            scope={auth.scope}
            onRedirectCallback={handleRedirectCallback}
          >
            <Root />
          </Auth0Provider>
        </ErrorBoundary>
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement
);
