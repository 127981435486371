import React from 'react';
import { Typography } from '@material-ui/core';

interface Item {
  label: string;
  value: string;
  start: number;
  end: number;
  styleClasses?: {
    [key: string]: string;
  };
}

interface Props {
  style?: React.CSSProperties;
  title: string;
  items: Item[];
  rules?: number[];
}

const getRange = (items: Item[]) => {
  const min = Math.min(...items.map((i) => i.start));
  const max = Math.max(...items.map((i) => i.end));
  return [min, max];
};

const TotalsChart: React.SFC<Props> = (props) => {
  const range = getRange(props.items);
  const style = props.style || {};
  return (
    <div style={{ ...style, padding: '8px 0', position: 'relative' }}>
      <Typography variant="h6" style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 8 }}>
        {props.title}
      </Typography>

      <div
        style={{
          position: 'absolute',
          width: 'calc(60% - 4px)',
          height: '100%',
          right: 0,
          padding: '4px',
          paddingTop: '24px',
          top: 0,
        }}
      >
        {props.rules &&
          props.rules.map((r) => (
            <span
              key={r}
              style={{
                position: 'absolute',
                display: 'block',
                width: 0,
                borderRight: '1px dashed #ccc',
                top: 28,
                bottom: 4,
                left: `${r}%`,
              }}
            />
          ))}
      </div>

      {props.items.map((item) => {
        const rangeSize = range[1] - range[0];
        const leftPos = item.start / rangeSize;
        const width = (item.end - item.start) / rangeSize;
        return (
          <div key={item.label} style={{ display: 'flex', padding: '2px 0', position: 'relative' }}>
            <div
              style={{ display: 'flex', width: '40%', marginRight: '.5em', alignItems: 'baseline' }}
            >
              <Typography variant="subtitle1" style={{ fontSize: '12px' }}>
                {item.label}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 'normal', marginLeft: 'auto' }}>
                {item.value}
              </Typography>
            </div>

            <div style={{ width: '60%', position: 'relative' }}>
              <svg
                style={{
                  position: 'absolute',
                  height: '100%',
                  padding: '2px 0',
                  left: `${leftPos * 100}%`,
                  width: `${width * 100}%`,
                  minWidth: '1px',
                }}
              >
                <rect
                  className={item.styleClasses && item.styleClasses.bar}
                  width="100%"
                  height="100%"
                />
              </svg>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TotalsChart;
