import React from 'react';
import { SimpleRequestStatus } from '../../state/request';
import { useState } from 'react';
import { Lifecycle, getStatus, RequestAction } from '../../state/request';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../state';
import { useEffectWhen } from '../../helpers/hooks';
import { ApiError } from '../../api';

/* eslint-disable @typescript-eslint/no-explicit-any */
type ActionCreator<Args extends any[], Action extends RequestAction> = (...args: Args) => Action;

export interface WithStatusChildProps<Args extends any[], Action extends RequestAction> {
  status: SimpleRequestStatus;
  isComplete: boolean;
  isError: boolean;
  isPending: boolean;
  isNew: boolean;
  boundActionCreator: ActionCreator<Args, Action>;
}

export interface RequestStatusProps<Args extends any[], Action extends RequestAction> {
  actionCreator: ActionCreator<Args, Action>;
  onSuccess?: () => void;
  onSuccessDelay?: number;
  render: (props: WithStatusChildProps<Args, Action>) => React.ReactElement | null;
  renderLoading?: (props: WithStatusChildProps<Args, Action>) => React.ReactElement;
  renderSuccess?: (props: WithStatusChildProps<Args, Action>) => React.ReactElement;
  renderError?: (
    props: WithStatusChildProps<Args, Action> & { error: ApiError }
  ) => React.ReactElement;
}

export function WithRequest<Args extends any[], Action extends RequestAction>(
  props: RequestStatusProps<Args, Action>
): React.ReactElement | null {
  const [lastRequestAction, setLastRequestAction] = useState<Action>();
  const dispatch = useDispatch();

  const doRequest = (...args: Args) => {
    const action = props.actionCreator(...args);
    setLastRequestAction(action);
    dispatch(action);
    return action;
  };

  const lastRequestStatus = useSelector((state: RootState) => {
    return lastRequestAction && getStatus(state, lastRequestAction);
  });

  useEffectWhen(
    () => {
      if (lastRequestStatus === Lifecycle.COMPLETED && props.onSuccess !== undefined) {
        setTimeout(() => {
          setLastRequestAction(undefined);
          if (props.onSuccess !== undefined) {
            props.onSuccess();
          }
        }, props.onSuccessDelay || 0);
      }
    },
    [props.onSuccess, props.onSuccessDelay, lastRequestStatus, lastRequestAction],
    [lastRequestStatus]
  );

  const renderArgs = {
    status: lastRequestStatus,
    boundActionCreator: doRequest,
    isPending: lastRequestStatus === Lifecycle.PENDING,
    isError: typeof lastRequestStatus === 'object',
    isComplete: lastRequestStatus === Lifecycle.COMPLETED,
    isNew: lastRequestStatus === undefined,
  };

  if (lastRequestStatus === Lifecycle.PENDING && props.renderLoading) {
    return props.renderLoading(renderArgs);
  } else if (lastRequestStatus === Lifecycle.COMPLETED && props.renderSuccess) {
    return props.renderSuccess(renderArgs);
  } else if (typeof lastRequestStatus === 'object' && props.renderError) {
    return props.renderError({
      ...renderArgs,
      error: lastRequestStatus as ApiError,
    });
  } else {
    return props.render(renderArgs);
  }
}
