import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { EquipmentTabProps } from './EquipmentPage.d';

const EquipmentDetails = ({
  equipment: {
    commissionedDate,
    imageLink,
    installedCapacity,
    installedPower,
    label,
    make,
    manualLink,
    manufacturer,
    model,
    serialNumber,
  },
}: EquipmentTabProps) => {
  const classes = useStyles();
  return (
    <div>
      {imageLink && (
        <div>
          <img alt={label} className={classes.image} src={imageLink} />
        </div>
      )}

      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.table} size="small">
          <TableBody>
            <Row title="Manufacturer">{manufacturer}</Row>
            <Row title="Make">{make}</Row>
            <Row title="Model">{model}</Row>
            <Row title="Installed Power" unit=" kW">
              {installedPower}
            </Row>
            <Row title="Installed Capacity" unit=" kWh">
              {installedCapacity}
            </Row>
            <Row title="Serial Number">{serialNumber}</Row>
            {commissionedDate && (
              <Row title="Commission Date">
                {(typeof commissionedDate === 'string'
                  ? new Date(commissionedDate)
                  : commissionedDate
                ).toLocaleString()}
              </Row>
            )}
            {manualLink && (
              <Row>
                <Typography
                  component="a"
                  href={manualLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  O&amp;M Manual
                </Typography>
              </Row>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  backButton: {
    float: 'right',
  },
  image: {
    maxHeight: 200,
    maxWidth: '100%',
  },
  table: {
    maxWidth: 650,
    width: '100%',
  },
  tabPanel: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
}));

const Row: React.FC<{ title?: string; unit?: string }> = ({ children, title, unit = '' }) => {
  if (!children) {
    return null;
  }
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <strong>{title}</strong>
      </TableCell>
      <TableCell align="right">
        {children}
        {unit}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(EquipmentDetails);
