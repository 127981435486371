import React from 'react';
import { AlarmsPageQuery } from '../../../state/contexts/alarmsPage';
import { Site, getSiteEquipmentById, getSiteTagById } from '../../../models';
import { compact } from 'lodash';
import { interleave } from '../../../helpers/common';

export interface Props {
  currentQuery: AlarmsPageQuery;
  site: Site;
}

export const AlarmsQueryInfobar: React.FC<Props> = (props) => {
  const { site } = props;
  const { start, end, tagIds, equipmentIds, statuses, priorities } = props.currentQuery;

  const tagsString = compact(tagIds.map((tagId) => getSiteTagById(site, tagId)))
    .map((tag) => tag.label)
    .join(', ');

  const equipmentString = compact(
    equipmentIds.map((equipmentId) => getSiteEquipmentById(site, equipmentId))
  )
    .map((equipment) => equipment.label)
    .join(', ');

  const groups = compact([
    tagIds.length > 0 && (
      <>
        Tags:&nbsp;<b>{tagsString}</b>
      </>
    ),
    equipmentIds.length > 0 && (
      <>
        Equipment:&nbsp;
        <b>{equipmentString}</b>
      </>
    ),
    statuses.length > 0 && (
      <>
        Statuses:&nbsp;<b>{statuses.join(', ')}</b>
      </>
    ),
    priorities.length > 0 && (
      <>
        Priorities:&nbsp;<b>{priorities.join(', ')}</b>
      </>
    ),
  ]);

  const groupElements = interleave(groups, <>,&nbsp;&nbsp;</>).map((el, i) => (
    <span key={i}>{el}</span>
  ));

  return (
    <div>
      <div>
        Showing alarms triggered between <b>{start.toLocaleString()}</b> and{' '}
        <b>{end.toLocaleString()}</b>
      </div>
      <div>{groups.length > 0 && <>With&nbsp;{groupElements}</>}</div>
    </div>
  );
};
