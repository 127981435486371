import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, IconButton, makeStyles, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { PageTitle } from './PageTitle';
import { NotificationWidgetContainer } from './NotificationWidgetContainer';
import { HelpWidget } from './HelpWidget';
import { UserMenu } from './UserMenu';
import { UserProfile } from '../../../models';
import identifiers from '../../../tests/identifiers';
import SiteIcon from '../../ui/SiteIcon';
import { useSelector } from 'react-redux';
import { getSiteContext } from 'src/state/contexts/site';

export interface MainToolbarProps {
  profile: UserProfile;
  siteId: number;
  toggleNav: () => void;
}

export const MainToolbar = ({ profile, siteId, toggleNav }: MainToolbarProps) => {
  const classes = useStyles();
  const theme = useTheme();

  // TODO: Figure out how to retrieve customerId that doesn't require pulling the full site.
  const site = useSelector(getSiteContext);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { logout } = useAuth0();

  return (
    <Toolbar className={classes.root}>
      {isSmallScreen && (
        <IconButton
          className={classes.toggleNavButton}
          data-testid={identifiers.mainNavigation.toggleNavMobile}
          onClick={toggleNav}
          size="small"
        >
          <SiteIcon customerId={site.customerId} isDark={true} />
          <MenuIcon className={classes.toggleNavIcon} />
        </IconButton>
      )}

      <PageTitle siteName={profile.sites.find((site) => site.siteId === siteId)?.siteName} />

      <IconBorder>
        <NotificationWidgetContainer siteId={siteId} />
      </IconBorder>

      {!isVerySmallScreen && (
        <IconBorder>
          <HelpWidget />
        </IconBorder>
      )}

      <IconBorder>
        {/* eslint-disable-next-line no-restricted-globals */}
        <UserMenu logout={() => logout({ returnTo: `${location.origin}` })} />
      </IconBorder>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  toggleNavButton: {
    marginBottom: -3,
    marginLeft: -theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginTop: -3,
  },
  toggleNavIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: theme.palette.background.paper,
    fontSize: '0.75em',
  },
}));

const IconBorder: React.FC = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      borderLeft={`1px solid ${theme.palette.divider}`}
      display="flex"
      paddingLeft={1}
      paddingRight={1}
    >
      <div>{children}</div>
    </Box>
  );
};
