import { hubConnection, Options, Connection } from 'signalr-no-jquery';
import logger from './logger';
import { featureFlags } from '../config/featureFlags';
import { SignalRMock } from '../tests/helpers/SignalRMock';

export interface Message {
  callId?: string;
  payloadType?: string;
  payload?: string;
  statusCode?: number;
}

export interface Listener {
  (message: Message): void;
}

export interface OnConnected {
  (connection: Connection): void;
}

export interface OnError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (error: any): void;
}

export interface SignalRProperties {
  url: string;
  hub: string;
  options?: Options;
  listeners: { [key: string]: Listener };
  onConnected?: (connection: Connection) => void;
  onDisconnect?: () => void;
  onError?: OnError;
}

interface StateChangedInfo {
  oldState: number;
  newState: number;
}

const states = {
  0: 'connecting',
  1: 'connected',
  2: 'reconnecting',
  4: 'disconnected',
};

declare global {
  interface Window {
    signalrMock?: SignalRMock;
  }
}

export function listen(props: SignalRProperties): Connection {
  /*
   * If mockHub feature flag is set use mock hub instance injected to page
   */
  if (featureFlags.mockHub) {
    if (!window.signalrMock) {
      throw new Error('Cannot find mockHub instance for push source');
    }

    logger.log('Using mocked signalr pushHub.');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const connection: any = window.signalrMock;

    for (const key in props.listeners) {
      if (props.listeners.hasOwnProperty(key)) {
        // set up event listeners i.e. for incoming events
        connection.on(key, props.listeners[key]);
      }
    }

    connection.start().then(props.onConnected).catch(props.onError);

    return connection;
  } else {
    const connection = hubConnection(props.url.toString(), props.options);
    const hubProxy = connection.createHubProxy(props.hub);

    for (const key in props.listeners) {
      if (props.listeners.hasOwnProperty(key)) {
        // set up event listeners i.e. for incoming events
        hubProxy.on(key, props.listeners[key]);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const anyConnection: any = connection;

    anyConnection.logging = false;

    // TODO: add listeners for more lifecycle events
    anyConnection.starting(() => {
      logger.log('SignalR starting');
    });
    anyConnection.received((message: {}) => {
      return;
    });
    anyConnection.connectionSlow(() => {
      logger.log('SignalR connectionSlow');
    });
    anyConnection.reconnecting(() => {
      logger.log('SignalR reconnecting');
    });
    anyConnection.reconnected(() => {
      logger.log('SignalR reconnected');
    });
    anyConnection.stateChanged((data: StateChangedInfo) => {
      const newState = states[data.newState] || data.newState;
      const oldState = states[data.oldState] || data.oldState;
      logger.log(`SignalR stateChanged from ${oldState} to ${newState}.`, data);
    });
    anyConnection.disconnected(() => {
      logger.log('SignalR disconnected');
      if (props.onDisconnect) {
        props.onDisconnect();
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    anyConnection.error((error: any) => {
      logger.log('SignalR error: ' + error);
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).testConn = anyConnection;

    // connect
    connection.start().done(props.onConnected).fail(props.onError);

    return connection;
  }
}
