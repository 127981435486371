import { Location } from 'history';

export interface SiteRouteMatch {
  siteId: string;
}

export interface AlarmDetailsRouteMatch {
  siteId: string;
  alarmId: string;
  alarmTagId: string;
}

export interface ControlSummaryRouteMatch {
  date?: Date;
}

export const parseControlSummaryRoute = (location: Location): ControlSummaryRouteMatch => {
  const query = new URLSearchParams(location.search);
  const dateStr = query.get('date');

  return {
    date: dateStr && Boolean(Date.parse(dateStr)) ? new Date(dateStr) : undefined,
  };
};
