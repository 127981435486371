import { ApiConnection } from '../util/ApiConnection';

export abstract class SubscriptionBase<Result> {
  protected connection: ApiConnection;

  protected interval: number = 1000 * 30;

  protected stopped: boolean = false;

  protected generator?: AsyncGenerator<Result>;

  constructor(connection: ApiConnection) {
    this.connection = connection;
  }

  public setConnection(connection: ApiConnection) {
    this.connection = connection;
  }

  public stop() {
    this.stopped = true;
  }

  public isActive() {
    return !this.stopped;
  }

  public async next(): Promise<Result> {
    if (!this.generator) throw new Error('generator not found');

    const resultPromise = this.generator.next();
    const result = await resultPromise;
    return result.value;
  }
}
