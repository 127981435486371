import { entitiesReducer, EntitiesRootState } from './entities';
import { requestReducer, RequestStatusState } from './request/reducer';
import { subscriptionReducer, SubscriptionState } from './subscription/reducer';
import { contextReducer, ContextRootState } from './contexts';
import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers } from 'redux';
import { getHistory } from './history';

export interface RootState {
  contexts: ContextRootState;
  entities: EntitiesRootState;
  requests: RequestStatusState;
  subscriptions: SubscriptionState;
  router: RouterState;
}

export const rootReducer = combineReducers<RootState>({
  contexts: contextReducer,
  router: connectRouter(getHistory()),
  entities: entitiesReducer,
  requests: requestReducer,
  subscriptions: subscriptionReducer,
});
