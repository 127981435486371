import React from 'react';
import { RootState } from '../../../state';
import { getSite } from '../../../state/entities';
import { SiteRole } from '../../../models';
import { getProfileContext } from '../../../state/contexts/auth/selectors';
import { getSiteContext } from '../../../state/contexts/site';
import { useSelector } from 'react-redux';

interface AllowForRolesProps {
  siteId?: number;
  systemAdmin?: boolean;
  customerAdmin?: boolean;
  installer?: boolean;
  sameCustomer?: boolean;
  roles?: SiteRole[];
  renderMessage?: () => React.ReactElement;
}

const AllowForRoles: React.FC<AllowForRolesProps> = (props) => {
  const { systemAdmin, customerAdmin, siteId, roles, installer } = props;
  let isAuthorized = false;
  const activeSite = useSelector((state: RootState) => siteId && getSite(state, siteId));
  const userProfile = useSelector(getProfileContext);
  const site = useSelector(getSiteContext);

  if (!activeSite || !userProfile) {
    return null;
  }

  const siteRole = userProfile.sites.find((assg) => assg.siteId === siteId);
  const hasUserRole = userProfile && siteRole?.role === SiteRole.USER;
  const hasSiteAdminRole = userProfile && siteRole?.role === SiteRole.SITE_ADMIN;
  const siteCustomerId = site.customerId !== undefined ? site.customerId : siteRole?.customerId;

  /**
   * In some cases we want to restrict non-customer users
   */
  if (siteCustomerId !== userProfile.customerId && props.sameCustomer) {
    if (props.renderMessage !== undefined) {
      return props.renderMessage() as React.ReactElement;
    } else {
      return null;
    }
  }

  if (systemAdmin && userProfile && userProfile.isSystemAdmin) {
    isAuthorized = true;
  } else if (
    installer &&
    userProfile &&
    userProfile.isInstaller &&
    siteCustomerId !== undefined &&
    siteCustomerId === userProfile.customerId
  ) {
    isAuthorized = true;
  } else if (
    customerAdmin &&
    siteId &&
    activeSite &&
    userProfile &&
    userProfile.isCustomerAdmin &&
    siteCustomerId !== undefined &&
    siteCustomerId === userProfile.customerId
  ) {
    isAuthorized = true;
  } else if (roles && roles.includes(SiteRole.SITE_ADMIN) && userProfile && hasSiteAdminRole) {
    isAuthorized = true;
  } else if (roles && roles.includes(SiteRole.USER) && userProfile && hasUserRole) {
    isAuthorized = true;
  } else {
    isAuthorized = false;
  }

  if (isAuthorized) {
    return props.children as React.ReactElement;
  } else if (props.renderMessage !== undefined) {
    return props.renderMessage() as React.ReactElement;
  }

  return null;
};

export default AllowForRoles;
