import { MenuItem, TextField, makeStyles, Theme } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import identifiers from '../../../tests/identifiers';
import { PathBuilder } from '../../../routing';
import { SiteTemplate } from '../../../models';
import { InstallerFormProps, InstallerSteps } from './InstallerPage.types';
import { getFormikInputProps } from '../../../helpers/form';
import { useFormikContext } from 'formik';

interface SkuSelectProps {
  setActiveStep: (step: number) => void;
  siteTemplates: SiteTemplate[];
  siteId: number;
  sku: string;
}

const SkuSelect = (props: SkuSelectProps) => {
  const { setActiveStep, siteTemplates, siteId, sku } = props;
  const classes = useStyles();
  const formik = useFormikContext<InstallerFormProps>();
  const history = useHistory();

  return (
    <>
      <Alert
        data-testid={identifiers.installerPage.noSelectedSKU}
        severity="info"
        className={classes.alert}
      >
        <AlertTitle>New installation</AlertTitle>
        Select the SKU that matches your site configuration to continue with installation.
      </Alert>

      <TextField
        label="SKU"
        {...getFormikInputProps(formik, 'sku', 'select')}
        onChange={(event) => {
          history.push(PathBuilder.INSTALLER(siteId, event.target.value as string));
          setActiveStep(InstallerSteps.LOCATION);
        }}
        size="medium"
        value={sku}
      >
        {siteTemplates.map((template) => (
          <MenuItem key={template.sku} value={template.sku}>
            {template.name}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

export default SkuSelect;
