import { UserApi } from '../../../api/UserApi';
import { apiSagaV2 } from '../../../helpers/saga';
import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as customerActions from './actions';

const fetchCustomer = apiSagaV2(customerActions.fetchCustomer, (connection, action) => {
  const { customerId, includeSysAdmins } = action.payload;
  return new UserApi(connection).fetchCustomer(customerId, includeSysAdmins);
});

export function* customerSagas() {
  yield takeEvery(getType(customerActions.fetchCustomer.request), fetchCustomer);
}
