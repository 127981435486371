import React from 'react';
import { ScreenSize, BackgroundRange, Margins } from './types';
import * as d3 from 'd3';
import { getDefaultMargins } from './helpers';

interface Props {
  style?: React.CSSProperties;
  margins?: Margins;
  size: ScreenSize;
  xScale: d3.ScaleTime<number, number>;
  data: BackgroundRange[];
}

class BackgroundSeries extends React.Component<Props> {
  groupRef: React.RefObject<SVGGElement> = React.createRef();

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  render() {
    return <g ref={this.groupRef}>{/* rendered with d3 */}</g>;
  }

  private update() {
    if (this.groupRef.current && this.props.xScale) {
      const { xScale, size, data } = this.props;
      const margins = { ...getDefaultMargins(), ...this.props.margins };
      const g = d3.select(this.groupRef.current);
      const chain = g.selectAll('rect').data(data);

      chain.enter().append('rect');

      // @ts-ignore todo: fix type
      g.selectAll('rect')
        .data(data)
        .attr('width', (d) => (xScale(d.end) ?? 0) - (xScale(d.start) ?? 0))
        .attr('height', (d) => size.height - margins.top - margins.bottom)
        .attr('x', (d) => xScale(d.start))
        .attr('y', (d) => margins.top)
        .attr('fill', (d) => d.fillColor || '#FFFFFF')
        .attr('class', (d) => d.styleClass || '');
    }
  }
}

export default BackgroundSeries;
