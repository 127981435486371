import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import URI from 'urijs';
import { getSiteContext } from '../../../state/contexts/site';
import {
  findEquipmentBySite,
  findSummariesBySite,
  getDataSet,
  subActiveAlarms,
  subSnapshots,
} from '../../../state/entities';
import { RootState } from '../../../state';
import EquipmentPage from './EquipmentPage';
import { SDate } from '../../../helpers/SettableDate';
import { useParamSelector, useSubscription } from '../../../helpers/hooks';
import { isDisplayTag } from '../../../models';

const TAG_DATA_LOOKBACK = 45 * 60 * 1000; // 45 minutes

const EquipmentPageLoader = () => {
  const site = useSelector(getSiteContext);

  // Get values from URL path
  const { activeEquipmentId = false, activeTab = 'tags' } = useParams<EquipmentPageParams>();

  // Get values from URL query
  const { search } = useLocation();
  const { tagId = [] } = URI.parseQuery(search);
  const pinnedTagIds = useMemo(() => (Array.isArray(tagId) ? tagId : [tagId]) as string[], [tagId]);

  // Get equipment from state
  const allEquipment = useSelector((rootState: RootState) =>
    findEquipmentBySite(rootState, site.id)
  );
  const activeEquipment = useMemo(
    () =>
      allEquipment.find(
        (equipment) => activeEquipmentId && Number(activeEquipmentId) === equipment.id
      ),
    [allEquipment, activeEquipmentId]
  );

  // Get active equipment tag data snapshots
  const end = new Date(SDate.now());
  const start = new Date(end.getTime() - TAG_DATA_LOOKBACK);
  const tagIds = useMemo(() => {
    if (!activeEquipment) {
      return pinnedTagIds ? pinnedTagIds : [];
    }
    return activeEquipment.tags.filter(isDisplayTag).map((tag) => tag.id);
  }, [activeEquipment, pinnedTagIds]);
  const tagDataStatus = useSubscription(true, subSnapshots, site.id, tagIds, TAG_DATA_LOOKBACK);
  const tagDataSnapshots = useParamSelector(getDataSet)(tagIds, start, end);

  // Get active alarms
  useSubscription(true, subActiveAlarms, site.id);
  const activeAlarms = useParamSelector(findSummariesBySite)(site.id, true);

  return (
    <EquipmentPage
      activeAlarms={activeAlarms}
      activeEquipmentId={activeEquipmentId ? Number(activeEquipmentId) : false}
      activeTab={activeTab}
      allEquipment={allEquipment}
      pinnedTagIds={pinnedTagIds}
      siteId={site.id}
      tagDataSnapshots={tagDataSnapshots}
      tagDataStatus={tagDataStatus}
    />
  );
};

export interface EquipmentPageParams {
  activeEquipmentId?: string;
  activeTab?: string;
}

export default EquipmentPageLoader;
