import { ProfileStateSlice } from './reducer';
import { SiteRole } from '../../../models';

export const getUserProfile = (state: ProfileStateSlice) => {
  return state.entities.userProfile ? state.entities.userProfile : undefined;
};

export const isSystemAdmin = (state: ProfileStateSlice): boolean => {
  const profile = getUserProfile(state);
  return Boolean(profile && profile.isSystemAdmin);
};

export const isCustomerAdmin = (state: ProfileStateSlice, customerId: number): boolean => {
  const profile = getUserProfile(state);
  return Boolean(profile && profile.isCustomerAdmin && profile.customerId === customerId);
};

export const isSiteAdmin = (state: ProfileStateSlice, siteId: number): boolean => {
  const profile = getUserProfile(state);
  return Boolean(profile && profile.sitePermissions[siteId] === SiteRole.SITE_ADMIN);
};
