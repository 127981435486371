import { RootState } from '../';
import { getType, createAction, ActionType } from 'typesafe-actions';
import URI from 'urijs';
import { produce } from 'immer';
import {
  AlarmStatus,
  AlarmPriority,
  AlarmSummary,
  AlarmDetails,
  TagDefinition,
} from '../../models';
import { combineReducers } from 'redux';
import { PathBuilder } from '../../routing';
import { SDate } from '../../helpers/SettableDate';
import { OrderDirectionType } from '../../components/ui/DataTable';

export interface AlarmsPageQuery {
  start: Date;
  end: Date;
  tagIds: string[];
  equipmentIds: number[];
  priorities: AlarmPriority[];
  statuses: AlarmStatus[];
}

export interface AlarmsPageSort {
  orderBy: string;
  orderDirection: OrderDirectionType;
}

export interface AlarmsPageState {
  query: AlarmsPageQuery | null;
  sort: AlarmsPageSort;
  page: number;
  isActiveOnly: boolean;
}

/* ----- actions ----- */
export const resetContext = createAction('context/alarmsPage/reset', (resolve) => () => resolve());

export const setQuery = createAction(
  'context/alarmsPage/setQuery',
  (resolve) => (query: AlarmsPageQuery | null) => resolve({ query })
);

export const setSort = createAction(
  'context/alarmsPage/setSort',
  (resolve) => (orderBy: string, orderDirection: OrderDirectionType) =>
    resolve({ orderBy, orderDirection })
);

export const setPage = createAction(
  'context/alarmsPage/setPage',
  (resolve) => (page: number) => resolve({ page })
);

export const setActiveOnly = createAction(
  'context/alarmsPage/setIsActiveOnly',
  (resolve) => (isActiveOnly: boolean) => resolve({ isActiveOnly })
);

export const parseAlarmsRouteContext = (
  _path: string,
  search: string
): { query: AlarmsPageQuery | null; page: number } => {
  const params = URI.parseQuery(search);
  let start: Date | undefined, end: Date | undefined;

  if (params.start && typeof params.start === 'string') {
    start = new Date(params.start);
  }
  if (params.end && typeof params.end === 'string') {
    end = new Date(params.end);
  }

  if (!start || !end) {
    return { query: null, page: 1 };
  }

  const context: AlarmsPageQuery = {
    start,
    end,
    tagIds: [],
    equipmentIds: [],
    priorities: [],
    statuses: [],
  };
  if (params.tagIds && params.tagIds instanceof Array) {
    context.tagIds = params.tagIds.filter((t) => t !== null) as string[];
  } else if (params.tagIds && typeof params.tagIds === 'string') {
    context.tagIds = [params.tagIds];
  }

  if (params.equipmentIds && params.equipmentIds instanceof Array) {
    context.equipmentIds = (params.equipmentIds.filter((t) => t !== null) as string[]).map(
      (t) => +t
    );
  } else if (params.equipmentIds && typeof params.equipmentIds === 'string') {
    context.equipmentIds = [+params.equipmentIds];
  }

  if (params.statuses && params.statuses instanceof Array) {
    context.statuses = params.statuses.filter((t) => t !== null) as AlarmStatus[];
  } else if (params.statuses && typeof params.statuses === 'string') {
    context.statuses = [params.statuses as AlarmStatus];
  }

  if (params.priorities && params.priorities instanceof Array) {
    context.priorities = params.priorities.filter((t) => t !== null) as AlarmPriority[];
  } else if (params.priorities && typeof params.priorities === 'string') {
    context.priorities = [params.priorities as AlarmPriority];
  }

  return {
    query: { ...context },
    page: params.page ? +params.page : 1,
  };
};

const isAlarmSummary = (alarmObject: AlarmSummary | AlarmDetails): alarmObject is AlarmSummary => {
  return Boolean((alarmObject as AlarmSummary).relatedTagIds);
};

const isAlarm = (alarmObject: AlarmSummary | AlarmDetails): alarmObject is AlarmDetails => {
  return Boolean((alarmObject as AlarmDetails).relatedTags);
};

export const getAnalysisUrl = (siteId: number, alarm: AlarmSummary | AlarmDetails) => {
  let activatedDate: Date;
  let resolvedDate: Date;
  if (alarm.resolvedAt) {
    activatedDate = new Date(alarm.activatedAt);
    resolvedDate = new Date(alarm.resolvedAt);
  } else {
    activatedDate = new Date(alarm.activatedAt);
    resolvedDate = new Date(Math.min(Date.now(), activatedDate.getTime() + 1000 * 60 * 60 * 12));
  }
  const range = resolvedDate.getTime() - activatedDate.getTime();
  // we want some padding before and after the alarm times
  // padding must not go bast current time
  const padding = Math.max(0.2 * range, 1000 * 60 * 60 * 3);
  const start: Date = new Date(activatedDate.getTime() - padding);
  const end: Date = new Date(Math.min(SDate.now(), resolvedDate.getTime() + padding));
  let relatedTags: string[] = [];

  if (isAlarm(alarm)) {
    relatedTags = (alarm.relatedTags as TagDefinition[]).map((t) => t.id) || [];
  } else if (isAlarmSummary(alarm)) {
    relatedTags = alarm.relatedTagIds;
  }

  return PathBuilder.ANALYSIS_QUERY(siteId, [...relatedTags], start, end, [alarm.alarmTag.id]);
};

/* ----- selectors ----- */

export const getQuery = (rootState: RootState) => {
  return rootState.contexts.alarmsPage.query;
};

export const getIsActive = (rootState: RootState) => {
  return rootState.contexts.alarmsPage.isActiveOnly;
};

export const getPage = (rootState: RootState) => {
  return rootState.contexts.alarmsPage.page;
};

export const getSort = (rootState: RootState) => {
  return rootState.contexts.alarmsPage.sort;
};

// we need encode / decode Route state
// we need set / get redux state

type AlarmsPageContextActions =
  | ActionType<typeof setActiveOnly>
  | ActionType<typeof resetContext>
  | ActionType<typeof setQuery>
  | ActionType<typeof setPage>;

export const alarmsPageQueryReducer = produce(
  (_draft: AlarmsPageQuery | null, action: AlarmsPageContextActions) => {
    switch (action.type) {
      case getType(resetContext):
        return null;
      case getType(setQuery):
        return action.payload.query;
      default:
        break;
    }
    return;
  },
  null
);

export const alarmsPageSortReducer = produce(
  (_draft: AlarmsPageSort, action: ActionType<typeof setSort>) => {
    switch (action.type) {
      case getType(setSort):
        return action.payload;
      default:
        break;
    }
    return;
  },
  { orderBy: 'activatedAt', orderDirection: 'desc' }
);

export const alarmsPagePageReducer = (state: number = 1, action: ActionType<typeof setPage>) => {
  if (action.type === getType(setPage)) {
    return action.payload.page;
  }
  return state;
};

export const alarmsPageActiveOnlyReducer = (
  state: boolean = false,
  action: ActionType<typeof setActiveOnly>
) => {
  if (action.type === getType(setActiveOnly)) {
    return action.payload.isActiveOnly;
  }
  return state;
};

export const alarmsPageReducer = combineReducers<AlarmsPageState>({
  query: alarmsPageQueryReducer,
  page: alarmsPagePageReducer,
  sort: alarmsPageSortReducer,
  isActiveOnly: alarmsPageActiveOnlyReducer,
});
