import { ActionsOf } from '../../../helpers/actions';
import { SnapshotData, DataPointPayloadValue } from '../../../api/';
import { getType } from 'typesafe-actions';
import * as snapshotActions from './actions';
import { keyBy } from 'lodash';
import { produce } from 'immer';

export interface DataPointNorm {
  id: string;
  value: DataPointPayloadValue;
  timeStamp: string;
  quality: string;
}

export interface SnapshotEntityState {
  byTagId: {
    [Key: string]: {
      [Key: string]: DataPointNorm;
    };
  };
}

export interface SnapshotStateSlice {
  entities: {
    snapshot: SnapshotEntityState;
  };
}

const initialState = {
  byTagId: {},
};

type SnapshotActions = ActionsOf<typeof snapshotActions>;

const snapshotReducerHelper = (draft: SnapshotEntityState, snapshotData: SnapshotData) => {
  for (const tagId of Object.keys(snapshotData.points)) {
    const newPoints = keyBy(snapshotData.points[tagId], (dataPoint) => dataPoint.timeStamp);
    draft.byTagId[tagId] = {
      ...draft.byTagId[tagId],
      ...newPoints,
    };
  }
};

export const snapshotEntityReducer = produce(
  (draft: SnapshotEntityState, action: SnapshotActions) => {
    switch (action.type) {
      case getType(snapshotActions.fetchSnapshots.success):
      case getType(snapshotActions.fetchTopNSnapshots.success):
      case getType(snapshotActions.subSnapshots.update): {
        const { result: snapshotData } = action.payload;
        snapshotReducerHelper(draft, snapshotData);
        break;
      }

      default:
        break;
    }
  },
  initialState
);
