import { SnapshotStateSlice } from './reducer';
import { max, zipObject, Dictionary } from 'lodash';
import { memoizeSelector } from '../../../helpers/selectors';
import { RootState } from '../../';
import { denormTag } from '../tag/selectors';
import {
  TimeSeries,
  DataSeries,
  DataPoint,
  NumericDataPoint,
  TagDataType,
  TagDefinition,
} from '../../../models';
import { DataPointNorm } from './reducer';
import { sortBy } from 'lodash';
import logger from '../../../helpers/logger';

const getRoot = (state: SnapshotStateSlice) => {
  return state.entities.snapshot;
};

export const convertToDataPoint = (dataPointNorm: DataPointNorm) => {
  const { timeStamp, ...dataPointRest } = dataPointNorm;
  return {
    ...dataPointRest,
    time: new Date(timeStamp),
  };
};

export const findMostRecentSnapshot = (
  state: SnapshotStateSlice,
  tagId: string
): DataPoint | undefined => {
  const snapshots = state.entities.snapshot.byTagId[tagId];
  if (!snapshots) {
    return;
  }

  const maxId = max(Object.keys(snapshots));
  if (!maxId) {
    return undefined;
  }
  return convertToDataPoint(state.entities.snapshot.byTagId[tagId][maxId]);
};

export const findSnapshotsInRange = memoizeSelector(
  (state: SnapshotStateSlice, tagId: string, start: Date, end: Date): DataPoint[] => {
    const snpashots = getRoot(state);
    if (!snpashots.byTagId[tagId]) {
      return [];
    }

    const startTs = start.toISOString();
    const endTs = end.toISOString();

    return sortBy(
      Object.keys(snpashots.byTagId[tagId])
        .filter((timeStamp) => timeStamp >= startTs && timeStamp <= endTs)
        .map((timeStamp) => {
          return convertToDataPoint(snpashots.byTagId[tagId][timeStamp]);
        }),
      (dp) => dp.time
    );
  },
  [getRoot]
);

export const findByTagsInRange = memoizeSelector(
  (state: SnapshotStateSlice, tagIds: string[], start: Date, end: Date) => {
    return zipObject(
      tagIds,
      tagIds.map((tagId) => findSnapshotsInRange(state, tagId, start, end))
    );
  },
  [getRoot]
);

/**
 * Attempts to convert string value data to numeric data, nulling any values
 * which cannot be parsed as number
 */
function coerceToNumber(value: string | number | null) {
  const numericValue: number | null = value !== null ? +value : null;
  return numericValue !== null && isNaN(numericValue) ? null : numericValue;
}

/**
 * Percentage tags need to have all values multipled by 100 for display.
 */
function transformPercentageTags(tag: TagDefinition, points: DataPoint[]) {
  // We need to multiply percentage tags by 100 for display
  if (
    [TagDataType.INTEGER, TagDataType.LONG, TagDataType.DOUBLE, TagDataType.STRING].includes(
      tag.dataType
    ) &&
    tag.unit === '%'
  ) {
    const numericPoints: NumericDataPoint[] = points.map((point) => {
      return {
        ...point,
        value: coerceToNumber(point.value as number | string | null),
      } as NumericDataPoint;
    }) as NumericDataPoint[];
    points = numericPoints.map((point) => ({
      ...point,
      value: point.value !== null ? point.value * 100 : null,
    }));
  }
  return points;
}

/**
 * Returns a range of DataPoints, along with tag definition based on an array
 * of tagIds, and a start and end time. Returns results only for tags with a
 * numeric tag data type.
 *
 * Useful for getting all information needed to build a chart.
 */
export const getNumericDataSet = memoizeSelector(
  (state: RootState, tagIds: string[], start: Date, end: Date): Dictionary<TimeSeries> => {
    return zipObject(
      tagIds,
      tagIds.map((tagId) => {
        const tag = denormTag(state, tagId);
        // TODO: remove "STRING" once tags data is fixed
        if (
          tag.dataType === TagDataType.DOUBLE ||
          tag.dataType === TagDataType.LONG ||
          tag.dataType === TagDataType.INTEGER ||
          tag.dataType === TagDataType.STRING ||
          tag.dataType === null // rate schedule tags come back with null
        ) {
          let points = findSnapshotsInRange(state, tagId, start, end) as NumericDataPoint[];
          // TODO: This block is needed as INTEGER tags have zeros encoded
          // as string values remove this coercion if response data is fixed.
          // TODO: Long tags also can be string encoded, we will just assume
          // any numeric tag type could come back as a string
          if ([TagDataType.INTEGER, TagDataType.LONG, TagDataType.DOUBLE].includes(tag.dataType)) {
            points = points.map((point) => {
              return {
                ...point,
                value: coerceToNumber(point.value),
              };
            });
          }

          points = transformPercentageTags(tag, points) as NumericDataPoint[];

          return {
            tagId,
            tag,
            unit: tag.unit,
            points,
          };
        } else {
          logger.warn(`Trying to load a non numeric tag into a numeric data set ${tag.id}`, tag);
          return {
            tagId,
            tag,
            unit: tag.unit,
            points: findSnapshotsInRange(state, tagId, start, end) as NumericDataPoint[],
          };
        }
      })
    );
  },
  [getRoot]
);

/**
 * Returns a range of DataPoints, along with tag definition based on an array
 * of tagIds, and a start and end time.
 */
export const getDataSet = memoizeSelector(
  (state: RootState, tagIds: string[], start: Date, end: Date): Dictionary<DataSeries> => {
    return zipObject(
      tagIds,
      tagIds.map((tagId) => {
        const tag = denormTag(state, tagId);

        let points = findSnapshotsInRange(state, tagId, start, end) as DataPoint[];
        points = transformPercentageTags(tag, points);

        return {
          tagId,
          tag,
          unit: tag ? tag.unit : 'unknown',
          points,
        };
      })
    );
  },
  [getRoot]
);
