import React from 'react';
import { useSelector } from 'react-redux';
import { getSiteContext } from '../../../state/contexts/site';
import ControlSummaryPage from './ControlSummaryPage';
import { useRequest, useParamSelector } from '../../../helpers/hooks';
import { fetchControlDataset, findTagLookupBySite, fetchSnapshots } from '../../../state/entities';
import { Lifecycle } from '../../../state/request';
import {
  getAllTags,
  selectPowerTimeSeries,
  selectEnergyTimeSeries,
  selectCostTimeSeries,
  selectTOUDataset,
  selectProjections,
} from '../../../state/contexts/controlSummaryPage';
import { parseControlSummaryRoute, PathBuilder } from '../../../routing';
import { useLocation, useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { toDateString } from '../../../helpers/formatting';
import { zonedTimeToUtc } from 'date-fns-tz';
import { addHours } from 'date-fns';

const beginningOf = (date: Date) => {
  const resultDate = new Date(date);
  resultDate.setHours(0, 0, 0, 0);
  return resultDate;
};

export const ControlSummaryPageLoader: React.FC = () => {
  const site = useSelector(getSiteContext);

  const location = useLocation();
  const history = useHistory();

  const date = beginningOf(
    parseControlSummaryRoute(location).date || new Date(Date.now() - 1000 * 60 * 60 * 24)
  );

  const tagLookup = useParamSelector(findTagLookupBySite)(site.id);
  const controlSummaryTagIds = getAllTags(Object.values(tagLookup)).map((tag) => tag.id);

  const siteTz = site.ianaTimeZone;
  /*
  Create date object for midnight / beginning of day in site timezone 
  zonedTimeToUtc(...) returns date with time set to site timezone in GMT
  */
  const startDate = zonedTimeToUtc(`${toDateString(date)} 00:00:00`, siteTz);
  const endDate = addHours(startDate, 24);

  const action = fetchControlDataset.request(site.id, date);
  const requestStatus = useRequest(action)[1];

  const snapshotAction = fetchSnapshots.request(site.id, controlSummaryTagIds, startDate, endDate);
  const snapshotRequestStatus = useRequest(snapshotAction)[1];

  const powerTimeSeries = useParamSelector(selectPowerTimeSeries)(site.id, startDate);
  const energyTimeSeries = useParamSelector(selectEnergyTimeSeries)(site.id, startDate);
  const costTimeSeries = useParamSelector(selectCostTimeSeries)(site.id, startDate);
  const touDatasets = useParamSelector(selectTOUDataset)(site.id, date);
  const projections = useParamSelector(selectProjections)(site.id, date);

  const doSetDate = useCallback(
    (newDate: Date | undefined) => {
      history.push(PathBuilder.CONTROL_SUMMARY(site.id, newDate));
    },
    [site, history]
  );

  return (
    <ControlSummaryPage
      isLoading={requestStatus === Lifecycle.PENDING || snapshotRequestStatus === Lifecycle.PENDING}
      targetDate={date}
      siteTimezone={site.ianaTimeZone}
      powerTimeSeries={powerTimeSeries}
      energyTimeSeries={energyTimeSeries}
      costTimeSeries={costTimeSeries}
      tous={touDatasets}
      projections={projections}
      handleSetTargetDate={doSetDate}
    />
  );
};
