import URI from 'urijs';
import { GenerateReportRequest, ExportReportRequest, ReportConfiguration } from '../models';
import { PushClient } from './util/PushClient';

export class AnalysisApi extends PushClient {
  static baseUrl = '/Analysis';

  public async getReportConfigurations(siteId: number): Promise<ReportConfiguration[]> {
    const uri = new URI(`${AnalysisApi.baseUrl}/site/${siteId}/report`).toString();
    return this.singleMessageGetRequest<ReportConfiguration[]>(uri);
  }

  public async generateReport(data: GenerateReportRequest): Promise<boolean> {
    await this.client.post<'', GenerateReportRequest>(`${AnalysisApi.baseUrl}/site/report`, data);
    return true;
  }

  public async exportReport(data: ExportReportRequest): Promise<boolean> {
    await this.client.post<'', ExportReportRequest>(`${AnalysisApi.baseUrl}/site/export`, data);
    return true;
  }
}
