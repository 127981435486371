import React from 'react';
import { getSiteContext } from '../../../state/contexts/site';
import { OpSummaryPage } from './OpSummaryPage';
import { useSelector } from 'react-redux';
import { findTagWithAttr, TagAttribute, getSiteEquipment } from '../../../models';
import { compact } from 'lodash';
import {
  subSnapshots,
  findMostRecentSnapshot,
  findTagLookupBySite,
  getNumericDataSet,
  findSummariesBySite,
  subActiveAlarms,
} from '../../../state/entities';
import { zipObject } from 'lodash';
import { RootState } from '../../../state';
import { getHistoricalTagIds, getForecastTagIds, getRateTagIds } from './config';
import { useParamSelector, useSubscription } from '../../../helpers/hooks';
import { SDate } from '../../../helpers/SettableDate';
import { mergeStatus } from '../../../state/subscription';

const ONE_DAY = 1000 * 60 * 60 * 24;
const EIGHT_HOURS = 1000 * 60 * 60 * 8;
const ONE_HOUR = 1000 * 60 * 60;

export const OpSummaryPageLoader: React.FC<{}> = () => {
  const site = useSelector(getSiteContext);

  const tags = useParamSelector(findTagLookupBySite)(site.id);

  const equipment = getSiteEquipment(site);

  const primaryTags = compact(equipment.map((e) => findTagWithAttr(e, TagAttribute.Primary)));
  const secondaryTags = compact(equipment.map((e) => findTagWithAttr(e, TagAttribute.Secondary)));
  const tagIds = [...primaryTags, ...secondaryTags].map((t) => t.id);

  const historicalTagMap = getHistoricalTagIds(tags);
  const historicalTagIds = compact(Object.values(historicalTagMap));
  const forecastTagIds = compact(Object.values(getForecastTagIds(tags)));
  const rateTagIds = compact(Object.values(getRateTagIds(tags)));

  const minus24Hours = new Date(SDate.now() - ONE_DAY);
  const now = new Date(SDate.now());
  const plus8Hours = new Date(SDate.now() + EIGHT_HOURS);

  // subscribe to equipment card data
  const pointDataStatus = useSubscription(true, subSnapshots, site.id, tagIds, ONE_HOUR);
  const alarmDataStatus = useSubscription(true, subActiveAlarms, site.id);

  // subscribe to chart data
  const historicalDataStatus = useSubscription(
    true,
    subSnapshots,
    site.id,
    historicalTagIds,
    ONE_DAY
  );
  const rateDataStatus = useSubscription(
    true,
    subSnapshots,
    site.id,
    rateTagIds,
    ONE_DAY,
    EIGHT_HOURS
  );
  const forecastDataStatus = useSubscription(true, subSnapshots, site.id, forecastTagIds, 1);

  const equipmentDataStatus = mergeStatus(pointDataStatus, alarmDataStatus);
  const chartDataStatus = mergeStatus(historicalDataStatus, rateDataStatus, forecastDataStatus);

  const activeAlarms = useParamSelector(findSummariesBySite)(site.id, true);

  const latestSnapshots = useSelector((state: RootState) => {
    return zipObject(
      tagIds,
      tagIds.map((tagId) => findMostRecentSnapshot(state, tagId))
    );
  });

  const historicalData = useParamSelector(getNumericDataSet)(historicalTagIds, minus24Hours, now);
  const forecastData = useParamSelector(getNumericDataSet)(forecastTagIds, now, plus8Hours);
  const rateData = useParamSelector(getNumericDataSet)(rateTagIds, minus24Hours, plus8Hours);
  const chartData = {
    ...historicalData,
    ...forecastData,
    ...rateData,
  };

  return (
    <OpSummaryPage
      site={site}
      start={minus24Hours}
      end={now}
      chartData={chartData}
      latestSnapshots={latestSnapshots}
      activeAlarms={activeAlarms}
      equipmentDataStatus={equipmentDataStatus}
      chartDataStatus={chartDataStatus}
    />
  );
};
