/**
 * Creates function to check if value is valid for given enum, ignoring case
 */
function createEnumChecker<T extends string, TEnumValue extends string>(enumVariable: {
  [key in T]: TEnumValue;
}) {
  const enumValues = (Object.values(enumVariable) as TEnumValue[]).map((v) => v.toLowerCase());
  return (value: string): value is TEnumValue => enumValues.includes(value.toLowerCase());
}

/**
 * Checks that value is a valid member of enum, throws error if value cannot
 * be coerced to a valid value.
 *
 * Note this helper only works with enums which have string values.
 */
export function enforceEnumValue<T extends string, TEnumValue extends string>(
  enumVariable: { [key in T]: TEnumValue },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): TEnumValue {
  const isEnumValue = createEnumChecker(enumVariable);

  if (!isEnumValue(value)) {
    throw new Error(`Invalid value "${value}", for cast to enum ${enumVariable.toString()}`);
  }
  const result = (Object.values(enumVariable) as TEnumValue[]).find(
    (v) => v.toLowerCase() === value.toLowerCase()
  );
  return result as TEnumValue;
}

export function getEnumKeyByValue<T extends string, TEnumValue extends string>(
  enumVariable: { [key in T]: TEnumValue },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) {
  const result = Object.keys(enumVariable)[Object.values(enumVariable).indexOf(value)];
  return result as keyof typeof enumVariable;
}
