import React from 'react';
import { Typography } from '@material-ui/core';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  message?: React.ReactNode;
}

function Loading(props: Props) {
  const { message, ...rest } = props;

  return (
    <div {...rest}>
      <Typography variant="subtitle1">{props.message || 'Loading...'}</Typography>
    </div>
  );
}

export default Loading;
