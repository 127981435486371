import {
  Box,
  Grid,
  Typography,
  makeStyles,
  ButtonBase,
  Divider,
  useMediaQuery,
  useTheme,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { formatValueAndUnit } from '../../../helpers/formatting';
import { Metric } from './types';

interface MetricListProps {
  metrics: Metric[];
}

const MetricList = (props: MetricListProps) => {
  const { metrics } = props;
  const theme = useTheme();
  const isMedScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isExSmallScreen = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  return (
    <Grid className={classes.root} container direction={isMedScreen ? 'column' : 'row'}>
      {metrics.map((metric, i) => (
        <React.Fragment key={`${metric.subLabel}-${metric.label}`}>
          <Grid
            component={
              metric.link
                ? (componentProps) => (
                    <ButtonBase
                      href={metric.link}
                      classes={{
                        root: classes.link,
                      }}
                      {...componentProps}
                    />
                  )
                : 'div'
            }
            item
            style={{
              flex: 1,
            }}
          >
            <Box className={classes.outerWrap}>
              <Box className={classes.innerWrap}>
                <Box pt={1} pb={2}>
                  <Typography className={classes.label}>{metric.label}</Typography>
                  {metric.label && (
                    <Typography className={classes.subLabel}>{metric.subLabel}</Typography>
                  )}
                </Box>
                {metric.value !== undefined && (
                  <Typography
                    variant="h5"
                    component="p"
                    className={classes.value}
                    style={
                      metric.valueColor
                        ? {
                            color: metric.valueColor,
                          }
                        : undefined
                    }
                  >
                    {formatValueAndUnit(metric.value, metric.unit, {
                      fractionDigits: metric.precision || 1,
                      zeroPlaceholder: `0 ${metric.unit}`,
                    })}
                  </Typography>
                )}
                {metric.value === undefined && (
                  <Typography color="textSecondary" className={classes.noData}>
                    No data available
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
          {i !== metrics.length - 1 && (
            <Box display="flex">
              <Divider
                className={classes.divider}
                orientation={isMedScreen || isExSmallScreen ? 'horizontal' : 'vertical'}
              />
            </Box>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    padding: '8px 48px',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
      padding: '8px 16px',
    },
    '@media (max-width:1600px)': {
      padding: '8px 24px',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      display: 'block',
    },
  },
  outerWrap: {
    display: 'flex',
    height: '100%',
  },
  innerWrap: {
    display: 'flex',
    margin: 'auto',
    width: '80%',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      margin: '8px',
      width: 'auto',
    },
  },
  label: {
    lineHeight: '1.25rem',
    fontWeight: 100,
    fontSize: 19,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  value: {
    fontSize: 31,
    lineHeight: '35px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  subLabel: {
    lineHeight: '1.25rem',
    fontWeight: 'bold',
    fontSize: 19,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  noData: {
    lineHeight: '35px',
  },
  link: {
    display: 'block',
    textAlign: 'left',
  },
  divider: {
    flex: 1,
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: 1,
      height: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 1,
    },
  },
}));

export default MetricList;
