import * as yup from 'yup';

/**
 * Inspired by https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s03.html
 * This regex validates international numbers between 10 and 14 digits long.
 * It allows ( ) . / - and space as valid separators between numbers
 *
 * Valid numbers:
 * +17601234567
 * 17601234567
 * +1-760-123-4567
 * 1-760-123-4567
 * +1(760)123-4567
 * 1 760 123 4567
 * +1 760 123 4567
 * +1(760) 123 4567
 */
const PHONE_REGEX = /^\+?(?:[0-9][()\-./]{0,2}\x20?){9,14}[0-9]$/;
type ValidationOptions = {
  message?: string;
  required?: boolean;
};
export const internationalPhone = (options: ValidationOptions) => {
  const schema = yup
    .string()
    .test(
      'validCharacters',
      'Phone number must contain only digits and -, +, (, ), ., or /',
      (value) => {
        if (!value) {
          return true;
        }
        const other = (value as string).replace(/[0-9+\-()/.]/g, '');
        return other.length === 0;
      }
    )
    .test('validLength', 'Phone number must be between 10 and 14 digits', (value) => {
      if (!value) {
        return true;
      }
      const sanitizedValue = (value as string).replace(/\D/g, '');
      return sanitizedValue.length >= 10 && sanitizedValue.length <= 14;
    })
    .matches(PHONE_REGEX, 'Phone number is invalid');
  if (options.required) {
    return schema.required('Phone number is required');
  }
  return schema;
};

export const isValidEmail = (email: string) => yup.string().required().email().isValid(email);
