import React from 'react';
import Loading from './Loading';
import { Box, BoxProps, CircularProgress } from '@material-ui/core';

export interface LoadWithSpinnerProps extends BoxProps {
  message?: React.ReactNode;
  noMessage?: boolean;
  testId?: string;
}

const LoadWithSpinner = ({
  message = 'Loading',
  noMessage = false,
  testId,
  ...boxProps
}: LoadWithSpinnerProps) => {
  return (
    <Box
      alignItems="center"
      data-testid={testId}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      {...boxProps}
    >
      {!noMessage && <Loading message={message} />}
      <CircularProgress size={50} />
    </Box>
  );
};

export default LoadWithSpinner;
