import { RouteComponentProps } from 'react-router';
import { withData } from '../../utility/withData';
import ReportsPageContainer from './ReportsPageContainer';
import { fetchReportConfigurations } from '../../../state/entities/report/actions';
import { fetchCustomer } from '../../../state/entities/customer/actions';
import { getSiteContext } from '../../../state/contexts/site';
import { isComplete, isError, getError, Lifecycle } from '../../../state/request';
import { every, some, compact } from 'lodash';
import { Site } from '../../../models';
import { RequestStatus } from '../../utility/RequestStatus';
import { withRouter } from 'react-router-dom';
import { getProfileContext } from '../../../state/contexts/auth/selectors';

interface ReportsPageRouteMatch {
  siteId: string;
}

const getLoadActions = (site: Site, isSystemAdmin: boolean) => {
  const { customerId, id: siteId } = site;
  return [
    fetchCustomer.request(customerId, isSystemAdmin),
    fetchReportConfigurations.request(siteId),
  ];
};

export const ReportsPageLoader = withRouter(
  withData<RouteComponentProps<ReportsPageRouteMatch>>(
    (rootState, _ownProps) => {
      const { isSystemAdmin } = getProfileContext(rootState);
      const site = getSiteContext(rootState);
      return getLoadActions(site, isSystemAdmin);
    },
    {
      isLoaded: (rootState, _ownProps) => {
        const { isSystemAdmin } = getProfileContext(rootState);
        const site = getSiteContext(rootState);
        return every(getLoadActions(site, isSystemAdmin).map((a) => isComplete(rootState, a)));
      },
      renderLoader: () => <RequestStatus status={Lifecycle.PENDING} />,
      isError: (rootState, _ownProps) => {
        const { isSystemAdmin } = getProfileContext(rootState);
        const site = getSiteContext(rootState);
        return some(getLoadActions(site, isSystemAdmin).map((a) => isError(rootState, a)));
      },
      renderError: (rootState, _ownProps) => {
        const { isSystemAdmin } = getProfileContext(rootState);
        const site = getSiteContext(rootState);
        const errors = compact(
          getLoadActions(site, isSystemAdmin).map((a) => getError(rootState, a))
        );

        if (errors[0]) {
          return <RequestStatus status={errors[0]} />;
        }

        return <div>Error loading page.</div>;
      },
    }
  )(ReportsPageContainer)
);
