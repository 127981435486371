interface DataPollingInfo {
  intervalMs: number;
}

export type Product = 'mPulse' | 'mVoult';

export interface FeatureFlags {
  alarmNotifications: boolean;
  enableAnalytics: boolean;
  enableConsoleLogging: boolean;
  enableControl: boolean;
  enableDashboard: boolean;
  enableInstaller: boolean;
  enableReports: boolean;
  enableTimeTravel: boolean;
  mockHub: boolean;
  pollDataForData: DataPollingInfo;
  product: Product;
}

declare global {
  interface Window {
    cs: {
      featureFlags: Partial<FeatureFlags>;
    };
  }
}

export const featureFlags: FeatureFlags = {
  alarmNotifications: true,
  enableAnalytics: process.env.DEBUG === 'false',
  enableConsoleLogging: process.env.DEBUG === 'true',
  enableControl: true,
  enableDashboard: true,
  enableInstaller: false,
  enableReports: true,
  enableTimeTravel: true,
  mockHub: false,
  pollDataForData: {
    intervalMs: 1000 * 30, // 30 seconds
  },
  product: 'mPulse',
};

// mVoult feature flag overrides
if (process.env.REACT_APP_PRODUCT === 'mvoult') {
  Object.assign(featureFlags, {
    enableControl: false,
    enableDashboard: false,
    enableInstaller: true,
    enableReports: false,
    enableTimeTravel: false,
    product: 'mVoult',
  });
}

// Allow feature flag override in browser
window.cs = window.cs || {};
Object.assign(featureFlags, window.cs.featureFlags);
window.cs.featureFlags = featureFlags;
