import { SiteDataBuffer } from './util/SiteDataBuffer';
import { ApiError } from './util/ApiError';
import URI from 'urijs';
import { PushClient, ResponseBase } from './util/PushClient';
import {
  Site,
  SiteNode,
  Equipment,
  EquipmentNorm,
  TagDefinition,
  TagAttribute,
  ControlDataset,
} from '../models';
import { Normalized } from '../helpers/norm';
import { timeFormat } from 'd3';
import { siteDefinitionType, tagDictionaryType } from './constants';

export type EquipmentPayload = Equipment;

export type SiteNodePayload = Normalized<SiteNode, 'equipment', { equipment: EquipmentPayload[] }>;

export type SitePayload = Normalized<
  Site,
  'nodes' | 'webPortalLayoutJson' | 'locationGeoJson',
  {
    nodes: SiteNodePayload[];
    webPortalLayoutJson: string;
    locationGeoJson: string;
  }
>;

export type SiteNorm = Normalized<Site, 'nodes' | 'tags', { nodeIds: number[]; tagIds: string[] }>;

export type SiteNodeNorm = Normalized<
  SiteNode,
  'equipment' | 'tags',
  { equipmentIds: number[]; tagIds: string[] }
>;

export interface SiteData {
  site: SiteNorm;
  nodes: SiteNodeNorm[];
  equipment: EquipmentNorm[];
  tags: TagDefinition[];
}

export interface TagDataPayload {
  tagContextId: string;
  tags: TagDefinition[];
}

interface TagResponse extends ResponseBase {
  payloadType: typeof tagDictionaryType;
  payload: TagDataPayload;
}

interface SiteResponse extends ResponseBase {
  payloadType: typeof siteDefinitionType;
  payload: SitePayload;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSiteMessage(message: any): message is SiteResponse {
  return message?.payloadType === siteDefinitionType;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isTagMessage(message: any): message is TagResponse {
  return message?.payloadType === tagDictionaryType;
}

export class TagApi extends PushClient {
  static baseUrl = '/Tag';

  /**
   * fetchTagTree
   *
   * Fetch site data, plus all tags in the site tag tree. Resovles to
   * a SiteData object containing the full tag tree, and the nodes, equipment,
   * and tags broken out.
   */
  public async fetchTagTree(siteId: number, attribute?: TagAttribute): Promise<SiteData> {
    // site data buffer keeps track of messages and builds the site tag tree
    // provides means of determining when all messages have arrived
    const siteDataBuffer = new SiteDataBuffer();

    const uri = `${TagApi.baseUrl}/${siteId}/tree/site/${siteId}/${attribute || ''}`;

    return this.multiMessageGetRequest<SiteData, TagDataPayload | SitePayload>(
      uri,
      (finished, message) => {
        if (message.statusCode && message.statusCode > 299) {
          finished.reject(new ApiError(undefined, 'Request error'));
          return;
        }
        if (isSiteMessage(message)) {
          siteDataBuffer.pushSiteData(message.payload);
        }
        if (isTagMessage(message)) {
          siteDataBuffer.pushTagData(message.payload.tagContextId, message.payload.tags);
        }
        if (siteDataBuffer.isFinished()) {
          const siteData = siteDataBuffer.getData();

          finished.resolve({
            ...siteData,
            site: {
              ...siteData.site,
            },
          });
        }
      }
    );
  }

  /**
   * fetchControlSummaryDataset
   *
   * fetch data needed to render control summary tables
   */
  public async fetchControlSummaryDataset(siteId: number, date: Date) {
    const dateParam = `${timeFormat('%Y-%m-%d')(date)}T00:00:00`;
    const uri = new URI(
      `${TagApi.baseUrl}/${siteId}/controlsummary?filterDate=${dateParam}`
    ).toString();

    return this.singleMessageGetRequest<ControlDataset>(uri);
  }

  /**
   * getSiteDocument
   *
   * get url for site document
   */
  public getSiteDocument(siteId: number, documentName: string): Promise<string> {
    return this.client.get<string>(`${TagApi.baseUrl}/${siteId}/document/${documentName}`);
  }
}
