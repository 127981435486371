import {
  Theme,
  WithStyles,
  withStyles,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Chip,
} from '@material-ui/core';
import { parse, format } from 'date-fns';
import { GenerateReportRequest } from '../../../models';
import { PrimaryButton, CancelButton } from '../../ui/Buttons';
import { WithRequest } from '../../utility/WithRequest';
import { generateReport } from '../../../state/entities';
import { RequestLifecycle } from '../../ui/RequestLifecycle';

const styles = (theme: Theme) => ({
  chipRoot: {
    marginRight: theme.spacing() * 0.5,
  },
  chipLabel: {
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
  },
});

interface Props {
  onCancel: (clearForm: boolean) => void;
  reportRequest: GenerateReportRequest;
}

const getDateRangeDisplay = (startTS?: string, endTS?: string) => {
  if (!startTS && !endTS) {
    return (
      <>
        Date Range: <b>All Dates</b>
      </>
    );
  }
  return (
    <>
      Date Range: <b>{format(parse(startTS!, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')}</b> &ndash;{' '}
      <b>{format(parse(endTS!, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')}</b>
    </>
  );
};

const SUCCESS_MESSAGE = 'Report processing. Results may take up to 15 minutes to arrive.';

const ReportConfirmationModal = (props: Props & WithStyles<typeof styles>) => {
  const { onCancel, reportRequest, classes } = props;
  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <WithRequest
        actionCreator={generateReport.request}
        render={({ boundActionCreator, status, isComplete, isError, isNew }) => (
          <>
            <RequestLifecycle status={status} successMessage={SUCCESS_MESSAGE}>
              <DialogTitle>Confirm Send Report</DialogTitle>
              <DialogContent>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography>
                      Send <b>{reportRequest.reportName}</b>
                    </Typography>
                  </Grid>
                  {reportRequest.additionalRecipientEmails.length > 0 && (
                    <Grid item container alignItems="center">
                      <Grid item style={{ paddingRight: '0.5rem' }}>
                        <Typography>CC to:</Typography>
                      </Grid>
                      <Grid item>
                        {reportRequest.additionalRecipientEmails.map((recipientEmail) => (
                          <Chip
                            key={recipientEmail}
                            classes={{
                              root: classes.chipRoot,
                              label: classes.chipLabel,
                            }}
                            label={recipientEmail}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <Typography>
                      {getDateRangeDisplay(reportRequest.startTS, reportRequest.endTS)}
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
            </RequestLifecycle>
            <DialogActions>
              {isNew && (
                <>
                  <CancelButton onClick={() => onCancel(false)}>Cancel</CancelButton>
                  <PrimaryButton onClick={() => boundActionCreator(reportRequest)}>
                    Send
                  </PrimaryButton>
                </>
              )}
              {(isComplete || isError) && (
                <PrimaryButton onClick={() => onCancel(isComplete)}>Ok</PrimaryButton>
              )}
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
};

export default withStyles(styles)(ReportConfirmationModal);
