import { createApiActions } from '../../../helpers/actions';
import { NewUser, User } from '../../../models';

export const createUser = createApiActions<User>()(
  'user/create',
  'user/createSuccess',
  'user/createError',
  (newUser: NewUser) => ({ newUser })
);

export const removeFromSite = createApiActions<User>()(
  'user/removeFromSite',
  'user/removeFromSiteSuccess',
  'user/removeFromSiteError',
  (userId: number, siteId: number) => ({ userId, siteId })
);

export const updateUser = createApiActions<User>()(
  'user/update',
  'user/updateSuccess',
  'user/updateError',
  (user: User) => ({ user })
);

export const deleteUser = createApiActions<boolean>()(
  'user/delete',
  'user/deleteSuccess',
  'user/deleteError',
  (user: User) => ({ user })
);

export const resendInvite = createApiActions<User>()(
  'user/resendInvite',
  'user/resendInviteSuccess',
  'user/resendInviteError',
  (user: User) => ({ user })
);

export const activateAccount = createApiActions<string>()(
  'user/activateAccount',
  'user/activateAccountSuccess',
  'user/activateAccountError',
  (email: string, registrationCode: string) => ({ email, registrationCode })
);
