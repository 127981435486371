import React from 'react';
import { Typography } from '@material-ui/core';

interface Props {
  style?: React.CSSProperties;
  styleClass?: string;
  itemType: LegendItemType;
  size: number;
  value?: string | number | null;
  label: string;
  handleSelect?: () => void;
}

export enum LegendItemType {
  area,
  line,
}

class LegendItem extends React.Component<Props> {
  renderLine() {
    return (
      <line
        x1="0"
        y1={this.props.size / 2}
        x2={this.props.size}
        y2={this.props.size / 2}
        className={this.props.styleClass || ''}
      />
    );
  }

  renderArea() {
    return (
      <rect
        x="0"
        y="0"
        width={this.props.size}
        height={this.props.size}
        className={this.props.styleClass || ''}
      />
    );
  }

  render() {
    const text: string | number | null = this.props.label;
    const style = this.props.style || {};
    const opacity = this.props.value !== 0 ? 1 : 0.5;
    return (
      <div
        style={{ ...style, display: 'flex', alignItems: 'center' }}
        onClick={(e) => this.props.handleSelect && this.props.handleSelect()}
      >
        <svg
          width={this.props.size}
          height={this.props.size}
          style={{ marginRight: '.5em', flexShrink: 0 }}
        >
          {this.props.itemType === LegendItemType.line && this.renderLine()}
          {this.props.itemType === LegendItemType.area && this.renderArea()}
        </svg>

        <Typography
          variant={'caption'}
          style={{
            opacity,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            flex: 'auto',
            overflow: 'hidden',
          }}
        >
          {text}
        </Typography>
        {this.props.value !== null && typeof this.props.value !== 'undefined' && (
          <Typography
            variant={'caption'}
            style={{
              opacity,
              marginLeft: 'auto',
              marginRight: '12%',
              flex: 1,
              minWidth: '40%',
              textAlign: 'right',
            }}
          >
            {this.props.value}
          </Typography>
        )}
      </div>
    );
  }
}

export default LegendItem;
