import { createApiActions, createSubActions } from '../../../helpers/actions';
import { Notification } from '../../../models';

export const fetchNotifications = createApiActions<Notification[]>()(
  'notification/fetch',
  'notification/fetchSuccess',
  'notification/fetchError',
  (siteId: number) => ({ siteId })
);

export const subNotifications = createSubActions<Notification[]>()(
  'notification/subStart',
  'notification/subUpdate',
  'notification/subError',
  'notification/subStop',
  (siteId: number) => ({ siteId })
);
