import React from 'react';
import { object, date, mixed } from 'yup';
import { AlarmPriority, AlarmStatus, Site, getSiteEquipment, isAlarmTag } from '../../../models';
import {
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  TextField,
  FormLabel,
  makeStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Formik, Field, FieldProps } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import { MultipleSelect } from '../../ui/controls/MultipleSelect';
import ClearIcon from '@material-ui/icons/Clear';
import { difference } from 'lodash';
import { AlarmsPageQuery } from '../../../state/contexts/alarmsPage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../ui/controls/dateTimePicker.css';
import { TagTree } from '../site/TagTree';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectMenu: {
      whiteSpace: 'normal',
    },
  })
);

export const QueryValidationSchema = object({
  start: date().required('Start date is required.'),
  end: date(),
  tagIds: mixed().test({
    name: 'contextIds',
    message: 'Must choose either tags or equipment to search by.',
    test: function (value) {
      const hasEquipmentIds = this.parent.equipmentIds && this.parent.equipmentIds.length > 0;
      const hasTagIds = value && value.length > 0;
      return hasTagIds || hasEquipmentIds;
    },
  }),
});

export interface Props {
  site: Site;
  currentQuery?: AlarmsPageQuery;
}

export interface DispatchProps {
  onCancel: () => void;
  onSubmitQuery: (query: AlarmsPageQuery) => void;
}

type AllProps = Props & DispatchProps;

const statusOptions = Object.values(AlarmStatus).map((s) => ({ name: s, value: s }));

const priorityOptions = Object.values(AlarmPriority).map((s) => ({
  name: s,
  value: s,
}));

const filterDates = (dateOption: Date) => {
  return dateOption.getTime() < Date.now();
};

const dateFormat = 'yyyy-MM-dd h:mm aa';
const MAX_RANGE = 1000 * 60 * 60 * 24 * 90;

export const AlarmQueryModal: React.FC<AllProps> = (props) => {
  const classes = useStyles();
  const equipmentOptions = getSiteEquipment(props.site).map((equipment) => ({
    name: equipment.label,
    value: equipment.id,
  }));
  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <Formik
        validationSchema={QueryValidationSchema}
        onSubmit={props.onSubmitQuery}
        initialValues={
          props.currentQuery || {
            start: null as never,
            end: null as never,
            tagIds: [],
            equipmentIds: [],
            statuses: [],
            priorities: [],
          }
        }
        validateOnMount
        children={(formikProps) => {
          const { start, end } = formikProps.values;

          const handleSetStart = (newStart: Date) => {
            if (end && end.getTime() - newStart.getTime() > MAX_RANGE) {
              const newEnd = new Date(newStart.getTime() + MAX_RANGE);
              formikProps.setFieldValue('start', newStart);
              formikProps.setFieldValue('end', newEnd);
            } else if (end && end < newStart) {
              formikProps.setFieldValue('start', newStart);
              formikProps.setFieldValue('end', new Date(newStart.getTime() + 1000 * 60 * 60 * 24));
            } else {
              formikProps.setFieldValue('start', newStart);
            }
          };

          const handleSetEnd = (newEnd: Date) => {
            if (start && newEnd.getTime() - start.getTime() > MAX_RANGE) {
              const newStart = new Date(newEnd.getTime() - MAX_RANGE);
              formikProps.setFieldValue('start', newStart);
              formikProps.setFieldValue('end', newEnd);
            } else if (start && start > newEnd) {
              formikProps.setFieldValue('start', new Date(newEnd.getTime() - 1000 * 60 * 60 * 24));
              formikProps.setFieldValue('end', newEnd);
            } else {
              formikProps.setFieldValue('end', newEnd);
            }
          };

          return (
            <>
              <form onSubmit={formikProps.handleSubmit}>
                <DialogTitle>Query Alarms</DialogTitle>
                <DialogContent>
                  <Grid container direction="row">
                    <Grid container item direction="column" spacing={2} xs={6}>
                      <Grid item>
                        <Field
                          name="start"
                          children={({ field }: FieldProps<AlarmsPageQuery['start']>) => (
                            <DatePicker
                              selected={field.value}
                              onChange={(e: Date) => handleSetStart(e)}
                              onBlur={field.onBlur}
                              dateFormat={dateFormat}
                              showTimeSelect
                              filterDate={filterDates}
                              customInput={
                                <TextField
                                  size="small"
                                  fullWidth
                                  label="Start Time"
                                  variant="outlined"
                                  placeholder="Start Time"
                                />
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Field name="end">
                          {({ field }: FieldProps<AlarmsPageQuery['end']>) => (
                            <DatePicker
                              selected={field.value}
                              onChange={(e: Date) => handleSetEnd(e)}
                              onBlur={field.onBlur}
                              dateFormat={dateFormat}
                              showTimeSelect
                              filterDate={filterDates}
                              customInput={
                                <TextField
                                  size="small"
                                  fullWidth
                                  label="End Time"
                                  variant="outlined"
                                  placeholder="End Time"
                                />
                              }
                            />
                          )}
                        </Field>
                      </Grid>

                      <Grid item container alignItems="center">
                        <Grid item style={{ flex: 1 }}>
                          <Field
                            name="statuses"
                            children={({ field }: FieldProps<AlarmsPageQuery['statuses']>) => (
                              <MultipleSelect
                                fullWidth
                                variant="outlined"
                                options={statusOptions}
                                classes={{ selectMenu: classes.selectMenu }}
                                label="Status"
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            style={
                              formikProps.values.statuses.length > 0 ? {} : { visibility: 'hidden' }
                            }
                            onClick={() => formikProps.setFieldValue('statuses', [])}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid item container alignItems="center">
                        <Grid item style={{ flex: 1 }}>
                          <Field
                            name="priorities"
                            children={({ field }: FieldProps<AlarmsPageQuery['priorities']>) => (
                              <MultipleSelect
                                fullWidth
                                variant="outlined"
                                classes={{ selectMenu: classes.selectMenu }}
                                options={priorityOptions}
                                label="Priority"
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            style={
                              formikProps.values.priorities.length > 0
                                ? {}
                                : { visibility: 'hidden' }
                            }
                            onClick={() => formikProps.setFieldValue('priorities', [])}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid item container alignItems="center">
                        <Grid item style={{ flex: 1 }}>
                          <Field
                            name="equipmentIds"
                            children={({ field }: FieldProps<AlarmsPageQuery['equipmentIds']>) => (
                              <MultipleSelect
                                fullWidth
                                variant="outlined"
                                classes={{ selectMenu: classes.selectMenu }}
                                options={equipmentOptions}
                                label="Equipment"
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            style={
                              formikProps.values.equipmentIds.length > 0
                                ? {}
                                : { visibility: 'hidden' }
                            }
                            onClick={() => formikProps.setFieldValue('equipmentIds', [])}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} container>
                      <Grid item xs={12} style={{ padding: '0 16px' }}>
                        <FormLabel style={{ marginTop: 6, marginBottom: 6, display: 'block' }}>
                          Tags
                        </FormLabel>
                        <Field
                          name="tagIds"
                          children={({ form }: FieldProps<AlarmsPageQuery>) => {
                            return (
                              <TagTree
                                height={460}
                                site={props.site}
                                tagFilter={isAlarmTag}
                                onTagSelect={(tagId) => {
                                  const next = form.values.tagIds.includes(tagId)
                                    ? difference(form.values.tagIds, [tagId])
                                    : [...form.values.tagIds, tagId];
                                  form.setFieldValue('tagIds', next);
                                }}
                                selectedTags={form.values.tagIds}
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {formikProps.errors.tagIds && (
                    <Typography style={{ marginRight: 'auto', marginLeft: 16 }}>
                      {formikProps.errors.tagIds}
                    </Typography>
                  )}
                  <Button onClick={props.onCancel}>Cancel</Button>
                  <Button
                    type="submit"
                    disabled={
                      formikProps.isValidating || !formikProps.isValid || !formikProps.dirty
                    }
                  >
                    Query
                  </Button>
                </DialogActions>
              </form>
            </>
          );
        }}
      />
    </Dialog>
  );
};
