import { Fade, Tooltip } from '@material-ui/core';
import { last } from 'lodash';
import { SubscriptionLifecycle } from '../../../state/subscription';
import { DataPoint } from '../../../models';
import { EquipmentTabProps } from './EquipmentPage.d';

const EquipmentTagValue = ({ tagId, tagDataStatus, tagDataSnapshots }: EquipmentTagValueProps) => {
  if (tagDataStatus !== SubscriptionLifecycle.ACTIVE) {
    return null;
  }

  const { points = [], unit } = tagDataSnapshots[tagId] || [];

  if (points.length < 1) {
    return NO_DATA;
  }

  const { time, value } = last(points) as DataPoint;
  if (value === null) {
    return NO_DATA;
  }

  return (
    <Fade in>
      <Tooltip title={time.toISOString()}>
        <span>
          {value}
          {unit !== 'None' && ` ${unit}`}
        </span>
      </Tooltip>
    </Fade>
  );
};

export interface EquipmentTagValueProps
  extends Pick<EquipmentTabProps, 'tagDataStatus' | 'tagDataSnapshots'> {
  tagId: string;
}

const NO_DATA = (
  <Fade in>
    <span>no data</span>
  </Fade>
);

export default EquipmentTagValue;
