import React from 'react';
import {
  Theme,
  WithStyles,
  withStyles,
  Backdrop,
  Typography,
  IconButton,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Button,
  createStyles,
  CircularProgress,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import UserAvatarList from '../../ui/UserAvatarList';
import { Alert, User } from '../../../models';

interface Props {
  isSubmitting: boolean;
  alert: Alert;
  handleEnable: (alertItem: Alert) => void;
  children: React.ReactNode;
}

type AllProps = Props & WithStyles<typeof styles>;

const styles = (theme: Theme) =>
  createStyles({
    panel: {
      margin: '0 !important',
      borderTop: '1px solid #e0e0e0',
      boxShadow: 'none',
      '&:last-child': {
        borderBottom: '1px solid #e0e0e0',
      },
      '&:before': {
        content: 'none',
      },
    },
    panelSummary: {
      fontSize: 14,
      minHeight: '0 !important',
      cursor: 'default !important',
      '&$panelSummaryDisabled': {
        opacity: 1,
        pointerEvents: 'unset',
        backgroundColor: theme.palette.grey[100],
      },
      '&:hover': {
        backgroundColor: '#F8FAFC',
      },
    },
    panelContent: {
      backgroundColor: '#fafafa',
      border: '1px solid #e0e0e0',
      borderBottom: 'none',
      paddingTop: 16,
    },
    panelDisabled: {
      backgroundColor: 'unset',
      opacity: 1,
    },
    panelSummaryDisabled: {},
    panelSummaryContent: {
      width: '100%',
      margin: '8px 0 !important',
    },
    disabled: {
      opacity: 0.38,
      pointerEvents: 'none',
    },
    rowText: {
      fontSize: 14,
    },
    loader: {
      color: '#e0e0e0',
    },
  });

interface State {
  isExpanded: boolean;
}

class AlertListItem extends React.Component<AllProps, State> {
  state = {
    isExpanded: false,
  };

  handleExpand = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  render() {
    const { classes, alert, handleEnable } = this.props;
    const { isExpanded } = this.state;

    return (
      <Accordion
        classes={{
          root: classes.panel,
          disabled: classes.panelDisabled,
        }}
        expanded={alert.isActive && isExpanded}
        disabled={!alert.isActive}
        TransitionProps={{ unmountOnExit: true }}
      >
        {this.props.isSubmitting && (
          <Backdrop open={this.props.isSubmitting} style={{ position: 'absolute', zIndex: 100 }}>
            <CircularProgress classes={{ circle: classes.loader }} />
          </Backdrop>
        )}
        <AccordionSummary
          classes={{
            root: classes.panelSummary,
            disabled: classes.panelSummaryDisabled,
            content: classes.panelSummaryContent,
          }}
          onClick={this.handleExpand}
        >
          <Grid container style={{ padding: 0 }} alignItems="center">
            <Grid
              xs={11}
              container
              item
              alignItems="center"
              className={!alert.isActive ? classes.disabled : undefined}
            >
              <Grid xs={12} sm={3} item>
                <Typography className={classes.rowText} style={{ fontWeight: 500 }}>
                  {alert.label}
                </Typography>
              </Grid>
              <Grid xs={12} sm={3} item zeroMinWidth>
                {alert.description !== alert.label && (
                  <Tooltip title={alert.description}>
                    <Typography noWrap className={classes.rowText}>
                      {alert.description}
                    </Typography>
                  </Tooltip>
                )}
              </Grid>
              <Grid xs={12} sm={3} item>
                <Typography className={classes.rowText}>
                  {alert.relatedAlarmTag ? alert.relatedAlarmTag.alarmPriority : ''}
                </Typography>
              </Grid>
              <Grid xs={12} sm={3} item>
                {alert.isActive && (
                  <UserAvatarList
                    color="primary"
                    names={alert.distributionList
                      .filter((r) => r.user)
                      .map((r) => (r.user as User).name)}
                  />
                )}
                {!alert.isActive && <Typography className={classes.rowText}>Inactive</Typography>}
              </Grid>
            </Grid>
            <Grid xs={1} item container justifyContent="flex-end" alignItems="center">
              <Grid item>
                {alert.isActive && (
                  <IconButton
                    data-testid="alert-settings-toggle"
                    style={{
                      height: 'auto',
                      width: 32,
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                )}
                {!alert.isActive && (
                  <Button size="small" onClick={() => handleEnable(alert)} color="primary">
                    Enable
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.panelContent,
          }}
        >
          {this.props.children}
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withStyles(styles)(AlertListItem);
