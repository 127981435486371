const testIdentifiers = {
  analysisPage: {
    page: '',
    startOverButton: '',
  },
  dashboardPage: {
    page: '',
    flowEdge: '',
    flowEdgeReversed: '',
    flowComponent: '',
    flowComponentValue: '',
  },
  equipmentPage: {
    clearFilters: '',
    clearPinnedTags: '',
    closeEquipment: '',
    filterCategories: '',
    filterSearch: '',
    filterTypes: '',
    landingPage: '',
    list: '',
    pinnedTagCount: '',
    tabPanel: '',
    tagDataError: '',
    tagDataLoading: '',
    tagFilterSearch: '',
    tagFilterTypes: '',
    tagList: '',
  },
  installerPage: {
    page: '',
    image: '',
    invalidPartner: '',
    noSelectedSKU: '',
    provisionError: '',
    provisionPending: '',
    provisionSuccess: '',
    siteTemplatesError: '',
    siteTemplatesLoading: '',
    stepSiteDetails: '',
    stepSiteLocation: '',
    stepSKUSelection: '',
  },
  portfolioPage: {
    searchInput: '',
  },
  optOutPage: {
    page: '',
  },
  mainNavigation: {
    drawerOpen: '',
    drawerSmall: '',
    toggleNav: '',
    toggleNavMobile: '',
  },
  ui: {
    dataTable: {
      header: '',
    },
  },
  utilityRate: {
    noRateSchedules: '',
    utilitiesLoading: '',
    utilityError: '',
  },
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const processTree = (path: string, tree: any): typeof testIdentifiers => {
  for (const key of Object.keys(tree)) {
    const subTree = tree[key];
    if (subTree === '') {
      tree[key] = `${path}${key}`;
    } else {
      processTree(`${path}${key}.`, subTree);
    }
  }
  return tree;
};

export default processTree('', testIdentifiers);
