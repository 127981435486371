import * as authActions from './actions';
import { getType } from 'typesafe-actions';
import logger from '../../../helpers/logger';
import { put, takeEvery, select, call } from 'redux-saga/effects';
import { getIsConnected } from './selectors';
import { ApiConnection } from '../../../api';
import { Connection } from 'signalr-no-jquery';
import { Deferred } from '../../../helpers/Deferred';

export const AUTH_REDIRECT_PATH = 'AUTH_REDIRECT_URI';

function* handlePushFeedConnect() {
  try {
    const connected: boolean = yield select(getIsConnected);
    // if already connected don't need to reconnect
    if (connected) {
      return;
    }

    const deferredDisconnect = new Deferred();

    const connection: Connection = yield call(ApiConnection.listenToPushFeed, () => {
      deferredDisconnect.resolve(true);
    });

    yield put(authActions.pushFeedConnectSuccess(connection.id));

    // wait for disconnect
    yield deferredDisconnect.promise;
    yield put(authActions.pushFeedDisconnect());
  } catch (error) {
    logger.log(error);
    // @ts-ignore todo: fix type
    yield put(authActions.pushFeedConnectError(error.toString()));
  }
}

export function* authContextSagas() {
  yield takeEvery(getType(authActions.pushFeedConnect), handlePushFeedConnect);
}
