import {
  Box,
  Fade,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import EquipmentIcon from '@material-ui/icons/DeveloperBoard';
import identifiers from '../../../tests/identifiers';
import { SubscriptionLifecycle } from '../../../state/subscription';
import LoadWithSpinner from '../../ui/Loading/LoadWithSpinner';
import EquipmentTagRow from './EquipmentTagRow';
import { EquipmentPageProps } from './EquipmentPage.d';

const EquipmentPageLanding = (props: EquipmentPageProps) => {
  const { pinnedTagIds } = props;
  const classes = useStyles();
  return (
    <Fade in>
      <Box
        alignItems="center"
        data-testid={identifiers.equipmentPage.landingPage}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        padding={3}
      >
        <EquipmentIcon className={classes.icon} />

        {pinnedTagIds.length === 0 && (
          <Alert className={classes.alert} severity="info">
            <AlertTitle>No equipment selected</AlertTitle>
            Select a piece of equipment from the list to view more information.
          </Alert>
        )}

        {pinnedTagIds.length > 0 && <LandingTags {...props} />}
      </Box>
    </Fade>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  alert: {
    marginBottom: theme.spacing(3),
  },
  icon: {
    color: theme.palette.grey[100],
    height: theme.spacing(16),
    margin: theme.spacing(8, 0, 4),
    width: theme.spacing(16),
  },
}));

const LandingTags = (props: EquipmentPageProps) => {
  const { pinnedTagIds, tagDataSnapshots, tagDataStatus } = props;
  const classes = useLandingTagsStyles();
  switch (tagDataStatus) {
    case SubscriptionLifecycle.ACTIVE:
      return (
        <TableContainer className={classes.root} component={Paper} elevation={1} square>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Pinned Tag Point</TableCell>
                <TableCell align="right" className={classes.noWrap}>
                  Latest Value
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {pinnedTagIds.map((tagId) => {
                const { tag } = tagDataSnapshots[tagId];
                return <EquipmentTagRow key={tagId} tag={tag} pinned {...props} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    case SubscriptionLifecycle.STARTING:
    case undefined:
      return <LoadWithSpinner testId={identifiers.equipmentPage.tagDataLoading} noMessage />;
    default:
      return (
        <Alert data-testid={identifiers.equipmentPage.tagDataError} severity="error">
          Error loading pinned tag point data
        </Alert>
      );
  }
};

const useLandingTagsStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: theme.palette.grey[100],
    width: 'auto',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));

export default EquipmentPageLanding;
