import URI from 'urijs';
import { SDate } from '../helpers/SettableDate';

export class PathPattern {
  static MAIN = '/';

  static PROTECTED = '/protected';

  static PORTFOLO = `${PathPattern.PROTECTED}/portfolio`;

  static ACTIVATE_ACCOUNT = '/activate-account';

  static SITE = `${PathPattern.PROTECTED}/site/:siteId`;

  static DEFAULT_SITE = `${PathPattern.PROTECTED}/site`;

  static LOBBY = `${PathPattern.SITE}/lobby`;

  static OPERATIONAL_SUMMARY = `${PathPattern.SITE}/operational-summary`;

  static CONTROL_SUMMARY = `${PathPattern.SITE}/control-summary`;

  static ANALYSIS = `${PathPattern.SITE}/analysis`;

  static ALARMS_DEFAULT = `${PathPattern.SITE}/alarm`;

  static ALARM_DETAILS = `${PathPattern.ALARMS_DEFAULT}/:alarmTagId/:alarmId`;

  static ALARM_ACK = `${PathPattern.ALARM_DETAILS}/acknowledge`;

  static ALARM_NOTE = `${PathPattern.ALARM_DETAILS}/note`;

  static ALARM_RESOLVE = `${PathPattern.ALARM_DETAILS}/resolve`;

  static USER_MANAGEMENT = `${PathPattern.SITE}/users`;

  static ALARM_NOTIFICATIONS = `${PathPattern.SITE}/alarm-notifications`;

  static SITE_DOCUMENT_PATH = `${PathPattern.PROTECTED}/:siteId/document/:documentName`;

  static AUTH_CALLBACK = `/auth-callback`;

  static REPORT = `${PathPattern.SITE}/reports`;

  static EQUIPMENT = `${PathPattern.SITE}/equipment/:activeEquipmentId?/:activeTab?`;

  static INSTALLER = `${PathPattern.SITE}/installer/:sku?`;

  static OPT_OUT = `${PathPattern.PROTECTED}/grid-services/opt-out`;
}

export class PathBuilder {
  static MAIN = () => PathPattern.MAIN;

  static PROTECTED = () => PathPattern.PROTECTED;

  static ACTIVATE_ACCOUNT = () => PathPattern.ACTIVATE_ACCOUNT;

  static SITE = (siteId: number) => `/protected/site/${siteId}`;

  static DEFAULT_SITE = () => `/protected/site`;

  static REPORT = (siteId: number) => `/protected/site/${siteId}/reports`;

  static ANALYSIS = (siteId: number) => `${PathBuilder.SITE(siteId)}/analysis`;

  static ANALYSIS_QUERY = (
    siteId: number,
    tagIds: string[],
    start: Date = new Date(SDate.now() - 1000 * 60 * 60 * 24 * 2),
    end: Date = new Date(SDate.now()),
    alarmTagIds?: string[]
  ) => {
    const uri = URI(`${PathBuilder.SITE(siteId)}/analysis`);
    uri.addQuery('startTS', start.toISOString());
    uri.addQuery('endTS', end.toISOString());
    uri.addQuery('tagIds', tagIds);
    uri.addQuery('alarmIds', alarmTagIds);
    return uri.toString();
  };

  static ALARMS_DEFAULT = (siteId: number) => `${PathBuilder.SITE(siteId)}/alarm`;

  static ALARM_DETAILS = (siteId: number, alarmId: string, alarmTagId: string) =>
    `${PathBuilder.SITE(siteId)}/alarm/${alarmTagId}/${alarmId}`;

  static ALARM_ACK = (siteId: number, alarmId: string, alarmTagId: string) =>
    `${PathBuilder.ALARM_DETAILS(siteId, alarmId, alarmTagId)}/acknowledge`;

  static ALARM_NOTE = (siteId: number, alarmId: string, alarmTagId: string) =>
    `${PathBuilder.ALARM_DETAILS(siteId, alarmId, alarmTagId)}/note`;

  static ALARM_RESOLVE = (siteId: number, alarmId: string, alarmTagId: string) =>
    `${PathBuilder.ALARM_DETAILS(siteId, alarmId, alarmTagId)}/resolve`;

  static REPORTS = (siteId: number) => `${PathBuilder.SITE(siteId)}/reports`;

  static ALARM_NOTIFICATIONS = (siteId: number) =>
    `${PathBuilder.SITE(siteId)}/alarm-notifications`;

  static USER_MANAGEMENT = (siteId: number) => `${PathBuilder.SITE(siteId)}/users`;

  static OPERATIONAL_SUMMARY = (siteId: number) =>
    `${PathBuilder.SITE(siteId)}/operational-summary`;

  static CONTROL_SUMMARY = (siteId: number, date?: Date) => {
    const uri = URI(`${PathBuilder.SITE(siteId)}/control-summary`);
    if (date) {
      uri.addQuery('date', date.toISOString());
    }
    return uri.toString();
  };

  static EQUIPMENT = (
    siteId: number,
    pinnedTagIds: string[] = [],
    activeEquipmentId: number | false = false,
    activeTab: string = 'tags'
  ) => {
    const uri = URI(
      `${PathBuilder.SITE(siteId)}/equipment${
        activeEquipmentId ? `/${activeEquipmentId}/${activeTab}` : ''
      }`
    );
    if (pinnedTagIds.length > 0) {
      uri.addQuery('tagId', pinnedTagIds);
    }
    return uri.toString();
  };

  static INSTALLER = (siteId: number, sku: string = '') =>
    `${PathBuilder.SITE(siteId)}/installer/${sku}`;

  static PORTFOLIO = () => `/protected/portfolio`;

  static OPT_OUT = (partnerRefId: number) => `${PathPattern.OPT_OUT}?partnerRefId=${partnerRefId}`;
}
