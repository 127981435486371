/* eslint-disable @typescript-eslint/no-explicit-any */
import { featureFlags } from '../config/featureFlags';

type Logger = {
  assert(test?: boolean, message?: string, ...optionalParams: any[]): void;
  clear(): void;
  count(countTitle?: string): void;
  debug(message?: any, ...optionalParams: any[]): void;
  dir(value?: any, ...optionalParams: any[]): void;
  dirxml(value: any): void;
  error(message?: any, ...optionalParams: any[]): void;
  exception?(message?: string, ...optionalParams: any[]): void;
  group(groupTitle?: string, ...optionalParams: any[]): void;
  groupCollapsed(groupTitle?: string, ...optionalParams: any[]): void;
  groupEnd(): void;
  info(message?: any, ...optionalParams: any[]): void;
  log(message?: any, ...optionalParams: any[]): void;
  table(...data: any[]): void;
  time(timerName?: string): void;
  timeEnd(timerName?: string): void;
  trace(message?: any, ...optionalParams: any[]): void;
  warn(message?: any, ...optionalParams: any[]): void;
};

class NullConsole {
  assert(test?: boolean, message?: string, ...optionalParams: any[]): void {}

  clear(): void {}

  count(countTitle?: string): void {}

  debug(message?: any, ...optionalParams: any[]): void {}

  dir(value?: any, ...optionalParams: any[]): void {}

  dirxml(value: any): void {}

  error(message?: any, ...optionalParams: any[]): void {}

  exception(message?: string, ...optionalParams: any[]): void {}

  group(groupTitle?: string, ...optionalParams: any[]): void {}

  groupCollapsed(groupTitle?: string, ...optionalParams: any[]): void {}

  groupEnd(): void {}

  info(message?: any, ...optionalParams: any[]): void {}

  log(message?: any, ...optionalParams: any[]): void {}

  profile(reportName?: string): void {}

  profileEnd(): void {}

  select(element: Element): void {}

  table(...data: any[]): void {}

  time(timerName?: string): void {}

  timeEnd(timerName?: string): void {}

  trace(message?: any, ...optionalParams: any[]): void {}

  warn(message?: any, ...optionalParams: any[]): void {}
}

function createConsole(): Logger {
  return featureFlags.enableConsoleLogging ? console : new NullConsole();
}

export default createConsole();
