import { Alert, AlertDistribution, BackoffPolicy, RecipientRole, DeliveryMethod } from '../models';
import { Normalized } from '../helpers/norm';
import { enforceEnumValue } from '../helpers/enum';
import { PushClient } from './util/PushClient';

export type AlertDistributionPayload = Normalized<AlertDistribution, 'user', { userId: number }>;

export type AlertPayload = Normalized<
  Alert,
  'relatedAlarmTag' | 'distributionList' | 'isActive',
  { relatedAlarmTagId: string; distributionList: AlertDistributionPayload[] }
>;

export interface AlertUpdate {
  tagId: string;
  backoffPolicy: BackoffPolicy | null;
  distributionList: AlertDistributionPayload[];
}

export const convertDistributionToPayload = ({
  user,
  ...distribution
}: AlertDistribution): AlertDistributionPayload => {
  return {
    ...distribution,
  };
};

export class AdminApi extends PushClient {
  static baseUrl = '/admin';

  public async fetchSiteAlerts(siteId: number): Promise<AlertPayload[]> {
    const alerts = await this.client.get<AlertPayload[]>(
      `${AdminApi.baseUrl}/site/${siteId}/alerts`
    );
    return alerts.map((alertItem) => this.sanitizeAlert(alertItem));
  }

  public async updateAlert(alertUpdate: AlertUpdate): Promise<AlertPayload> {
    const alertItem = await this.client.post<AlertPayload, AlertUpdate>(
      `${AdminApi.baseUrl}/alert`,
      alertUpdate
    );
    return this.sanitizeAlert(alertItem);
  }

  public async terminateGridServices(partnerRefId: number, status: string): Promise<boolean> {
    const body = { partnerRefId, status };
    await this.client.post<boolean, typeof body>(
      AdminApiPathBuilder.TERMINATE_GRID_SERVICES(),
      body
    );
    return true;
  }

  public async switchRateSchedule(siteId: number, rateScheduleId: number): Promise<boolean> {
    const body = { siteId, rateScheduleId };
    const response = await this.client.post<boolean, typeof body>(
      AdminApiPathBuilder.SWITCH_RATESCHEDULE(),
      body
    );
    return response;
  }

  private sanitizeAlert(apiAlert: AlertPayload): AlertPayload {
    return {
      ...apiAlert,
      backoffPolicy:
        apiAlert.backoffPolicy && enforceEnumValue(BackoffPolicy, apiAlert.backoffPolicy),
      distributionList: apiAlert.distributionList.map((dist) => ({
        ...dist,
        recipientRole: enforceEnumValue(RecipientRole, dist.recipientRole),
        deliveryMethod: enforceEnumValue(DeliveryMethod, dist.deliveryMethod),
      })),
    };
  }
}

export class AdminApiPathBuilder {
  static TERMINATE_GRID_SERVICES = () => `${AdminApi.baseUrl}/terminategridservices`;

  static SWITCH_RATESCHEDULE = () => `${AdminApi.baseUrl}/switchrateschedule`;
}
