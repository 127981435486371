import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../state';
import ReportsPage, { Props } from './ReportsPage';
import { SiteRole } from '../../../models';
import { getSiteReportConfigurations, getAssignedToSite } from '../../../state/entities';
import { getSiteContext } from '../../../state/contexts/site';

interface ReportsPageMatchProps {
  siteId: string;
}

const mapStateToProps = (
  rootState: RootState,
  _ownProps: RouteComponentProps<ReportsPageMatchProps>
): Props => {
  const site = getSiteContext(rootState);
  const { id: siteId } = site;

  // get users authorized for this site that are not role "User"
  const adminUsers = getAssignedToSite(rootState, siteId).filter((user) => {
    if (user.isSystemAdmin || user.isCustomerAdmin) {
      return true;
    }
    return (
      user.siteRoles.findIndex((role) => role.siteId === siteId && role.role !== SiteRole.USER) >= 0
    );
  });

  return {
    siteId,
    reportConfigurations: getSiteReportConfigurations(rootState, siteId),
    site,
    users: adminUsers,
  };
};

export default connect(mapStateToProps)(ReportsPage);
