import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { getSiteContext } from '../../../state/contexts/site';
import InstallerPage from './InstallerPage';
import { Lifecycle } from '../../../state/request';
import { useParamSelector, useRequest } from '../../../helpers/hooks';
import { fetchSiteTemplates, provisionSite } from '../../../state/entities/installer/actions';
import identifiers from '../../../tests/identifiers';
import LoadWithSpinner from '../../ui/Loading/LoadWithSpinner';
import { getSiteTemplates } from '../../../state/entities/installer/selectors';
import { WithRequest } from '../../utility/WithRequest';
import { isApiError } from '../../../api/util/ApiError';

export interface InstallerPageParams {
  sku?: string;
}

const InstallerPageLoader = () => {
  const site = useSelector(getSiteContext);
  const siteTemplates = useParamSelector(getSiteTemplates)();

  // Get values from URL path
  const { sku = '' } = useParams<InstallerPageParams>();

  // Load site templates from server
  const [, siteTemplatesStatus = Lifecycle.PENDING] = useRequest(fetchSiteTemplates.request(''));
  if (siteTemplatesStatus === Lifecycle.PENDING) {
    return (
      <PageBackground>
        <Box data-testid={identifiers.installerPage.siteTemplatesLoading} paddingTop={10}>
          <LoadWithSpinner message="Loading site template data" />
        </Box>
      </PageBackground>
    );
  }

  if (isApiError(siteTemplatesStatus)) {
    return (
      <PageBackground>
        <Alert data-testid={identifiers.installerPage.siteTemplatesError} severity="error">
          <AlertTitle>Error</AlertTitle>
          There was a problem loading site template data. {siteTemplatesStatus.message}
        </Alert>
      </PageBackground>
    );
  }

  return (
    <WithRequest
      actionCreator={provisionSite.request}
      render={(provisionSiteRequest) => {
        if (provisionSiteRequest.isComplete) {
          return (
            <PageBackground>
              <Alert data-testid={identifiers.installerPage.provisionSuccess} severity="success">
                <AlertTitle>Submission successful</AlertTitle>
                Your new site has been submitted for provisioning.
              </Alert>
            </PageBackground>
          );
        }

        return (
          <PageBackground>
            <InstallerPage
              provisionSiteRequest={provisionSiteRequest}
              site={site}
              siteTemplates={siteTemplates}
              sku={sku}
            />
          </PageBackground>
        );
      }}
    />
  );
};

const PageBackground: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};
const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.pageBackground,
    padding: theme.spacing(3.5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5),
    },
  },
}));

export default InstallerPageLoader;
