import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { createApiActions } from '../../helpers/actions';
import { apiSagaV2 } from '../../helpers/saga';
import { AdminApi } from '../../api';

export const gridServicesOptOut = createApiActions<boolean>()(
  'gridServices/optOut',
  'gridServices/optOutSuccess',
  'gridServices/optOutError',
  (partnerRefId: number, status: string) => ({ partnerRefId, status })
);

const gridServicesOptOutSaga = apiSagaV2(gridServicesOptOut, async (connection, action) => {
  const { partnerRefId, status } = action.payload;
  return new AdminApi(connection).terminateGridServices(partnerRefId, status);
});

export function* gridServicesSagas() {
  yield takeEvery(getType(gridServicesOptOut.request), gridServicesOptOutSaga);
}
