/**
 * Promise construct which can be resolved or rejected from "outside". Useful
 * when awaiting an async result comprised of multiple async events.
 */
export class Deferred<T, E> {
  // @ts-ignore todo: fix type
  public resolve: (value: T) => void;

  // @ts-ignore todo: fix type
  public reject: (error: E) => void;

  public promise: Promise<T>;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
