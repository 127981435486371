import { SnapshotApi, SnapshotData } from '../SnapshotApi';
import { sleep } from '../../helpers/async';
import { SubscriptionBase } from '../util/SubscriptionBase';
import { SDate } from '../../helpers/SettableDate';
import { ApiConnection } from '../util/ApiConnection';

export class SnapshotSubscription extends SubscriptionBase<SnapshotData> {
  private apiAccess?: SnapshotApi;

  async *getGenerator(
    siteId: number,
    tags: string[],
    lookback: number,
    lookforward: number
  ): AsyncGenerator<SnapshotData> {
    if (!this.apiAccess) return;

    // We first request snapshots from start up to now
    let latest = new Date(SDate.now() + lookforward);
    let start = new Date(SDate.now() - lookback);
    let snapshotData = await this.apiAccess.fetchSnapshotsBetween(siteId, tags, start, latest);
    yield snapshotData;
    while (!this.stopped) {
      await sleep(this.interval);
      latest = new Date(SDate.now() + lookforward);
      start = new Date(latest.getTime() - lookback);
      snapshotData = await this.apiAccess.fetchSnapshotsBetween(siteId, tags, start, latest);
      yield snapshotData;
    }
  }

  start(siteId: number, tags: string[], lookback: number, lookforward: number = 0) {
    this.apiAccess = new SnapshotApi(this.connection);
    this.generator = this.getGenerator(siteId, tags, lookback, lookforward);
  }

  updateConnection(connection: ApiConnection) {
    this.setConnection(connection);
    this.apiAccess = new SnapshotApi(this.connection);
  }
}
