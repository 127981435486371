import { getType } from 'typesafe-actions';
import { ReportConfiguration } from '../../../models';
import * as reportActions from './actions';
import { ActionsOf } from '../../../helpers/actions';
import { produce } from 'immer';

export interface ReportEntityState {
  bySiteId: {
    [Key: number]: ReportConfiguration[];
  };
}

export interface ReportStateSlice {
  entities: {
    report: ReportEntityState;
  };
}

const initialState = {
  bySiteId: {},
};

type ReportActions = ActionsOf<typeof reportActions>;

export const reportEntityReducer = produce((draft: ReportEntityState, action: ReportActions) => {
  switch (action.type) {
    case getType(reportActions.fetchReportConfigurations.success): {
      const { triggerAction, result: configurations } = action.payload;
      const { siteId } = triggerAction.payload;
      draft.bySiteId[siteId] = configurations;
      break;
    }

    default:
      break;
  }
}, initialState);
