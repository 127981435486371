import { useEffect } from 'react';
import { Grid, GridSize, MenuItem, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { number } from 'yup';
import { getFormikInputProps } from '../../../helpers/form';
import { RateSchedule, Utility } from '../../../models';

export interface UtilityRateProps {
  utilities: Utility[];
  rateColumnSize?: GridSize;
  isUtilityDisabled?: boolean;
}

interface UtilityRateValues {
  utilityId: number | null;
  rateScheduleId: number | null;
}

export const UtilityRate = (props: UtilityRateProps) => {
  const { utilities, rateColumnSize, isUtilityDisabled } = props;
  const formik = useFormikContext<UtilityRateValues>();
  const { setFieldValue, values } = formik;

  useEffect(() => {
    if (values.utilityId === null && utilities.length === 1) {
      setFieldValue('utilityId', utilities[0].utilityId);
    }
    // Only apply on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortRates = (rates: RateSchedule[] | undefined): RateSchedule[] => {
    return rates === undefined
      ? []
      : [...rates].sort((a, b) => a.scheduleName.localeCompare(b.scheduleName));
  };

  const handleUtilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (values.rateScheduleId !== null) {
      // Reset rate schedule on utility change
      setFieldValue('rateScheduleId', null, false);
    }
    setFieldValue('utilityId', value);
  };

  const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFieldValue('rateScheduleId', value);
  };

  return (
    <>
      <Grid item xs={12}>
        <TextField
          {...getFormikInputProps(formik, 'utilityId', 'select')}
          name="utility"
          label="Utility"
          onChange={handleUtilityChange}
          disabled={isUtilityDisabled}
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="" disabled>
            <em>Select a utility</em>
          </MenuItem>
          {utilities.map(({ utilityId, utilityName }) => (
            <MenuItem key={utilityId} value={utilityId}>
              {utilityName}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={rateColumnSize}>
        <TextField
          {...getFormikInputProps(formik, 'rateScheduleId', 'select')}
          name="rateSchedule"
          label="Rate Schedule"
          onChange={handleRateChange}
          disabled={values.utilityId === null}
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="" disabled>
            <em>Select a rate </em>
          </MenuItem>
          {sortRates(utilities.find((u) => u.utilityId === values.utilityId)?.rateSchedules).map(
            ({ scheduleId, scheduleName }) => (
              <MenuItem key={scheduleId} value={scheduleId}>
                {scheduleName}
              </MenuItem>
            )
          )}
        </TextField>
      </Grid>
    </>
  );
};

UtilityRate.defaultProps = { rateColumnSize: 12, isUtilityDisabled: false };

export const UtilityRateValidation = () => ({
  utilityId: number().nullable(),
  rateScheduleId: number().required('Field Required').nullable(),
});
