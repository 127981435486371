import { Button, Typography, Box, makeStyles } from '@material-ui/core';
import { WithRequest } from '../../utility/WithRequest';
import { mVoultUrl } from '../../../config';

import bgImage from '../../../assets/bg.jpg';
import SiteLogo from '../../ui/SiteLogo';
import { gridServicesOptOut } from '../../../state/contexts/optOutPage';
import { EnrollmentStatus } from '../../../models';
import identifiers from '../../../tests/identifiers';
import LoadWithSpinner from 'src/components/ui/Loading/LoadWithSpinner';
import { getEnumKeyByValue } from '../../../helpers/enum';

export interface OptOutPageProps {
  partnerRefId: number;
}

const OPT_OUT_DEFAULT_MESSAGE = 'Confirm that you want to opt out from grid services';
export const OPT_OUT_SUCCESS_MESSAGE =
  'You have successfully opted out from grid services. You will be redirected shortly';
export const OPT_OUT_REQUEST_ERROR_MESSAGE =
  'There was a problem processing your request. Please try again and contact support if you need further assistance.';

export const OPT_OUT_REDIRECT_DELAY = 5000;

const PageMessage = ({ message }: { message: string }) => {
  const classes = useStyles();
  return (
    <>
      <Box my={1} className={classes.image}>
        <SiteLogo customerId={0} isDark={true} />
      </Box>
      <Box my={1}>
        <Typography variant="h6">{message}</Typography>
      </Box>
    </>
  );
};

const OptOutPage = (props: OptOutPageProps) => {
  const classes = useStyles();
  const { partnerRefId } = props;

  const redirectToHome = () => {
    window.location.href = mVoultUrl;
  };

  return (
    <Box
      data-testid={identifiers.optOutPage.page}
      className={classes.root}
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <WithRequest
        actionCreator={gridServicesOptOut.request}
        onSuccess={redirectToHome}
        onSuccessDelay={OPT_OUT_REDIRECT_DELAY}
        render={({ status, boundActionCreator, isError, isPending, isComplete }) => {
          if (isComplete) {
            return <PageMessage message={OPT_OUT_SUCCESS_MESSAGE} />;
          }

          return (
            <>
              <PageMessage message={OPT_OUT_DEFAULT_MESSAGE} />
              {isError && (
                <Box my={1}>
                  <Typography variant="subtitle1" align="center">
                    Error Processing Request
                  </Typography>
                  <Typography color="error" align="center">
                    {OPT_OUT_REQUEST_ERROR_MESSAGE}
                  </Typography>
                </Box>
              )}
              {isPending && <LoadWithSpinner message="Processing..." />}
              <Box my={1}>
                <Button
                  disabled={isPending}
                  onClick={() =>
                    boundActionCreator(
                      partnerRefId,
                      getEnumKeyByValue(EnrollmentStatus, EnrollmentStatus.OptOut)
                    )
                  }
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Opt Out
                </Button>
              </Box>
            </>
          );
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  image: {
    width: 300,
  },
  root: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
  },
}));

export default OptOutPage;
