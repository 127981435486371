import * as authActions from './actions';
import { produce } from 'immer';
import { getType } from 'typesafe-actions';
import { ActionsOf } from '../../../helpers/actions';
import { loadState } from '../../../helpers/localStorage';

export interface AuthContextState {
  accessToken?: string;
}

const initialState = loadState<AuthContextState>('auth') || {
  isAuthenticated: false,
  isPending: false,
};

type AuthContextActions = ActionsOf<typeof authActions>;

export const authContextReducer = produce((draft: AuthContextState, action: AuthContextActions) => {
  switch (action.type) {
    case getType(authActions.publishToken):
      draft.accessToken = action.payload.token;
      break;
    default:
      break;
  }
}, initialState);

type PushFeedState = 'disconnected' | 'connecting' | 'connected' | 'failed';

export interface PushContextState {
  pushFeedState: PushFeedState;
  error?: string;
  connectionId?: string;
}

const initialPushContextState = {
  pushFeedState: 'disconnected',
};

export const pushContextReducer = produce((draft: PushContextState, action: AuthContextActions) => {
  switch (action.type) {
    case getType(authActions.pushFeedConnect):
      draft.pushFeedState = 'connecting';
      draft.error = undefined;
      break;

    case getType(authActions.pushFeedConnectSuccess):
      draft.pushFeedState = 'connected';
      draft.connectionId = action.payload.connectionId;
      draft.error = undefined;
      break;

    case getType(authActions.pushFeedConnectError):
      draft.pushFeedState = 'failed';
      draft.error = action.payload.error;
      draft.connectionId = undefined;
      break;

    case getType(authActions.pushFeedDisconnect):
      draft.pushFeedState = 'disconnected';
      draft.connectionId = undefined;
      break;

    default:
      break;
  }
}, initialPushContextState);
