import { User, NewUser, SiteRole, Customer, UserType } from '../models';
import URI from 'urijs';
import { PushClient } from './util/PushClient';
import { UserProfile } from '../models';

import { Normalized } from '../helpers/norm';
import { omit } from 'lodash';

export interface NewUserRequest {
  user: Omit<NewUser, 'isCustomerAdmin' | 'isSystemAdmin' | 'isInstaller'>;
  siteId: number;
  siteRole: SiteRole;
}

type CustomerResponse = Normalized<
  Customer,
  'users',
  {
    users: Omit<User, 'isSystemAdmin' | 'isCustomerAdmin' | 'isInstaller'>[];
  }
>;

type UserProfileResponse = Omit<UserProfile, 'isCustomerAdmin' | 'isSystemAdmin' | 'isInstaller'>;

export type UserRequest = Omit<User, 'isCustomerAdmin' | 'isSystemAdmin' | 'isInstaller'>;

export class UserApi extends PushClient {
  static baseUrl = '/User';

  public async fetchCustomer(customerId?: number, includeAdmins?: boolean): Promise<Customer> {
    const uri = new URI(`${UserApi.baseUrl}/customer`);
    if (customerId !== undefined) {
      uri.addSearch('customerId', customerId);
    }
    if (includeAdmins) {
      uri.addSearch('includeSysAdminUsers', 'true');
    }
    const result = await this.client.get<CustomerResponse>(uri.toString());
    const modifiedResult: Customer = {
      ...result,
      users: result.users.map((user) => ({
        ...user,
        isSystemAdmin: user.userType === UserType.SYSTEM_ADMIN,
        isCustomerAdmin: user.userType === UserType.CUSTOMER_ADMIN,
        isInstaller: user.userType === UserType.INSTALLER,
      })),
    };
    return modifiedResult;
  }

  public async createUser(siteId: number, siteRole: SiteRole, user: NewUser): Promise<User> {
    const result = await this.client.post<User, NewUserRequest>(UserApi.baseUrl, {
      user: omit(user, ['isSystemAdmin', 'isCustomerAdmin', 'isInstaller']),
      siteId,
      siteRole,
    });

    return {
      ...result,
      isSystemAdmin: result.userType === UserType.SYSTEM_ADMIN,
      isCustomerAdmin: result.userType === UserType.CUSTOMER_ADMIN,
      isInstaller: result.userType === UserType.INSTALLER,
    };
  }

  public async updateUser(user: User): Promise<User> {
    const result = await this.client.put<UserRequest, {}>(
      UserApi.baseUrl,
      omit(user, ['isSystemAdmin', 'isCustomerAdmin', 'isInstaller'])
    );

    return {
      ...result,
      isSystemAdmin: result.userType === UserType.SYSTEM_ADMIN,
      isCustomerAdmin: result.userType === UserType.CUSTOMER_ADMIN,
      isInstaller: result.userType === UserType.INSTALLER,
    };
  }

  public async deleteUser(userId: number): Promise<true> {
    await this.client.delete<{}>(`${UserApi.baseUrl}/${userId}`);
    return true;
  }

  public async resendInvite(user: User): Promise<User> {
    await this.client.post<{}, User>(`${UserApi.baseUrl}/invite/${user.userId}`);
    return user;
  }

  public async fetchUserProfile(): Promise<UserProfile> {
    const uri = `${UserApi.baseUrl}/profile`;
    const result = await this.singleMessageGetRequest<UserProfileResponse>(uri);

    const modifiedResult: UserProfile = {
      ...result,
      isSystemAdmin: result.userType === UserType.SYSTEM_ADMIN,
      isCustomerAdmin: result.userType === UserType.CUSTOMER_ADMIN,
      isInstaller: result.userType === UserType.INSTALLER,
    };
    return modifiedResult;
  }
}
