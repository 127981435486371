import { EntitiesStateSlice } from '../';
import { denormTag } from '../tag/selectors';
import { denormEquipment } from '../equipment/selectors';
import { SiteNode } from '../../../models';
import { NormalizationError } from '../../../helpers/norm';

export const denormNode = (state: EntitiesStateSlice, siteId: number, nodeId: number): SiteNode => {
  const nodeNorm = (state.entities.node.bySiteId[siteId] || {})[nodeId];
  if (!nodeNorm) {
    throw new NormalizationError(`Unable to denormalize node: ${nodeId}`, { siteId, nodeId });
  }
  const { tagIds, equipmentIds, ...siteNode } = nodeNorm;
  return {
    ...siteNode,
    tags: tagIds.map((tagId) => denormTag(state, tagId)),
    equipment: equipmentIds.map((equipmentId) => denormEquipment(state, siteId, equipmentId)),
  };
};
