import { getType, ActionType } from 'typesafe-actions';
import * as siteActions from '../site/actions';
import { keyBy } from 'lodash';
import { produce } from 'immer';
import { SiteNode } from '../../../models';
import { Normalized } from '../../../helpers/norm';

export interface SiteNodeNorm
  extends Normalized<
    SiteNode,
    'equipment' | 'tags',
    { equipmentIds: number[]; tagIds: string[] }
  > {}

export interface NodeEntityState {
  bySiteId: {
    [Key: string]: {
      [Key: string]: SiteNodeNorm;
    };
  };
}

export interface NodeStateSlice {
  entities: {
    node: NodeEntityState;
  };
}

const initialState = {
  bySiteId: {},
};

type NodeActions = ActionType<typeof siteActions.fetchSiteData>;

export const nodeEntityReducer = produce((draft: NodeEntityState, action: NodeActions) => {
  switch (action.type) {
    case getType(siteActions.fetchSiteData.success): {
      const { site, nodes } = action.payload.result;
      const { id: siteId } = site;
      draft.bySiteId[siteId] = draft.bySiteId[siteId] || {};
      draft.bySiteId[siteId] = {
        ...draft.bySiteId[siteId],
        ...keyBy(nodes, (node) => node.id),
      };
      break;
    }

    default:
      break;
  }
}, initialState);
