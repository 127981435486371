import URI from 'urijs';
import { PushClient } from './util/PushClient';
import { InstallerProvisionData, SiteTemplate, Utility } from '../models';

export class InstallerApi extends PushClient {
  public async fetchSiteTemplates(dataPartnerId: string): Promise<SiteTemplate[]> {
    const uri = new URI(InstallerApiPathBuilder.SITE_TEMPLATES(dataPartnerId)).toString();
    return this.client.get<SiteTemplate[]>(uri);
  }

  public async fetchUtilities(postalCode: string | number = ''): Promise<Utility[]> {
    const uri = new URI(InstallerApiPathBuilder.UTILITIES(postalCode)).toString();
    return this.client.get<Utility[]>(uri);
  }

  public async provisionSite(data: InstallerProvisionData): Promise<boolean> {
    const uri = new URI(InstallerApiPathBuilder.PROVISION_SITE()).toString();
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return this.singleMessagePostRequest(uri, formData);
  }
}

export class InstallerApiPathBuilder {
  static PROVISION_SITE = () => '/admin/site/provision';

  static SITE_TEMPLATES = (dataPartnerId: string = '') => `/admin/skutemplates/${dataPartnerId}`;

  static UTILITIES = (postalCode: string | number = '') => `/admin/utilities/${postalCode}`;
}
