import { AlertStateSlice } from './reducer';
import { Alert, AlertDistribution } from '../../../models';
import { denormTag } from '../tag/selectors';
import { EntitiesStateSlice } from '../';
import { NormalizationError, soften } from '../../../helpers/norm';
import { denormUser } from '../../entities/user/selectors';
import { AlertNorm, AlertDistributionNorm } from './reducer';
import { memoizeSelector } from '../../../helpers/selectors';

export const getRoot = (state: AlertStateSlice) => {
  return state.entities.alert;
};

export const getAlertNorm = (state: AlertStateSlice, alertTagId: string): AlertNorm | undefined => {
  return state.entities.alert.byId[alertTagId];
};

export const denormDistribution = (
  state: EntitiesStateSlice,
  alertDistributionNorm: AlertDistributionNorm
): AlertDistribution => {
  const { userId, ...remainingDist } = alertDistributionNorm;
  return {
    ...remainingDist,
    userId,
    user: denormUser(state, userId),
  };
};

export const denormAlert = (state: EntitiesStateSlice, alertTagId: string): Alert => {
  const alertNorm = getAlertNorm(state, alertTagId);
  if (!alertNorm) {
    throw new NormalizationError(`Unable to denormalize alert: ${alertTagId}`, { alertTagId });
  }
  const { relatedAlarmTagId, distributionList, ...alertObj } = alertNorm;
  const distributionListDenorm: AlertDistribution[] = distributionList.map(
    (dist) => soften(denormDistribution)(state, dist) || dist
  );
  /*
   * Note, because it may be possible that alert distributions specify users
   * that the current user has not loaded we soften the denormalization here
   * and return only distributions which have a user available.
   */
  return {
    ...alertObj,
    // Only show as active if the current user can see that there are users in
    // this list
    isActive: distributionListDenorm.filter((dist) => dist.user).length > 0,
    relatedAlarmTag: denormTag(state, relatedAlarmTagId),
    distributionList: distributionListDenorm,
  };
};

export const findAlertById = memoizeSelector(
  (state: EntitiesStateSlice, alertTagId: string): Alert | undefined => {
    const alertNorm = getAlertNorm(state, alertTagId);
    return alertNorm ? denormAlert(state, alertTagId) : undefined;
  },
  [
    (state: EntitiesStateSlice) => state.entities.site.byId,
    (state: EntitiesStateSlice) => state.entities.user.byId,
    (state: EntitiesStateSlice) => state.entities.alert.byId,
  ]
);

export const findAlertsBySite = memoizeSelector(
  (state: EntitiesStateSlice, siteId: number): Alert[] => {
    const siteAlertIds = state.entities.alert.lookupBySiteId[siteId] || [];
    return siteAlertIds.map((alertId) => denormAlert(state, alertId));
  },
  [
    (state: EntitiesStateSlice) => state.entities.site.byId,
    (state: EntitiesStateSlice) => state.entities.user.byId,
    (state: EntitiesStateSlice) => state.entities.alert.byId,
  ]
);
