import React from 'react';
import { Theme, WithStyles, withStyles, Avatar } from '@material-ui/core';

const getInitials = (name: string) => {
  const reducer = (acc: string, part: string, i: number, arr: string[]) => {
    if (i === 0 || i === arr.length - 1) {
      acc = acc + part[0];
    }
    return acc;
  };
  return name.split(' ').reduce(reducer, '');
};

interface Props {
  names: string[];
  max?: number;
  color?: 'primary' | 'secondary';
}

type AllProps = Props & WithStyles<typeof styles>;

const styles = (theme: Theme) => ({
  root: {
    border: '2px solid #fff',
    marginLeft: -6,
    fontSize: 14,
    height: 32,
    width: 32,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
});

const UserAvatarList: React.SFC<AllProps> = (props) => {
  const { classes, names, max, color } = props;
  const MAX = max || 3;
  const namesToDisplay = names.length <= MAX ? names : names.slice(0, -1 * (names.length - MAX));

  return (
    <div style={{ display: 'inline-flex' }}>
      {namesToDisplay.map((name, index) => {
        return (
          <Avatar
            key={index}
            classes={{
              root: classes.root,
              colorDefault: color ? classes[color] : undefined,
            }}
          >
            {getInitials(name)}
          </Avatar>
        );
      })}
      {names.length - MAX > 0 && (
        <Avatar
          classes={{
            root: classes.root,
            colorDefault: color ? classes[color] : undefined,
          }}
        >
          {`+ ${names.length - MAX}`}
        </Avatar>
      )}
    </div>
  );
};

export default withStyles(styles)(UserAvatarList);
