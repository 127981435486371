const v1Prefix = 'CleanSpark.Cloud.API.DataContracts.V1.';
export const siteDefinitionType = `${v1Prefix}Tag.Site`;
export const tagDictionaryType = `${v1Prefix}Tag.TagDictionary`;
export const snapshotType = `${v1Prefix}Snapshot.TimeSeries`;
export const userProfileType = `${v1Prefix}User.UserProfile`;
export const sitePowerFlowType = `${v1Prefix}Tag.SitePowerFlow`;
export const alarmDetailsType = `${v1Prefix}Alarm.AlarmDetails`;
export const errorResponseType = `${v1Prefix}ErrorResponse`;
export const notificationListType = `${v1Prefix}Notification.NotificationList`;
export const alarmSummaryListType = `${v1Prefix}Alarm.AlarmSummaryList`;
export const reportConfigurationType = `${v1Prefix}Site.ReportConfiguration`;
export const siteProvisionType = `${v1Prefix}Admin.NewSiteRequest`;
export const portfolioViewType = `${v1Prefix}Snapshot.PortfolioViewResponse`;
export const portfolioFilterOptionsType = `${v1Prefix}Snapshot.PortfolioFilterOptions`;
