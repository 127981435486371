import React from 'react';
import * as d3 from 'd3';
import { ScreenSize, SeriesValue, ChartSeries, Margins } from './types';
import { useMemo } from 'react';

interface Props {
  style?: React.CSSProperties;
  margins?: Margins;
  size: ScreenSize;
  xScale: d3.ScaleTime<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  data: ChartSeries[];
}

const LineSeries = React.memo((props: Props) => {
  const { xScale, yScale, data } = props;

  const line = useMemo(() => {
    return d3
      .line<SeriesValue>()
      .x((d) => {
        return xScale(d.x) ?? 0;
      })
      .y((d) => {
        return yScale(d.y) ?? 0;
      });
  }, [xScale, yScale]);

  const dataValues = useMemo(() => {
    return data.map((s) => s.values || []);
  }, [data]);

  if (!xScale || !yScale) {
    // tslint:disable-next-line: no-console
    console.warn('xScale or yScale is undefined', xScale, yScale);
    return null;
  }

  return (
    <g>
      {dataValues.map((lineData, i) => {
        const linePath = line(lineData);
        return linePath ? (
          <path key={i} d={linePath} className={props.data[i].styleClass} fill="none" />
        ) : null;
      })}
      ;
    </g>
  );
});

export default LineSeries;
