import React from 'react';
import {
  Theme,
  WithStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableBody,
  withStyles,
  IconButton,
  createStyles,
} from '@material-ui/core';
import { SiteRole, User, UserProfile } from '../../../models';
import Dropdown from '../../ui/controls/Dropdown';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import WithProfile from '../../modules/auth/WithProfile';
import { compact, orderBy } from 'lodash';
import { TableSortLabel, TableCellTight } from '../../ui/Tables/Elements';

export type OrderOptions = 'asc' | 'desc' | undefined;

/*
 * Table with all the users listed
 */

const styles = (theme: Theme) =>
  createStyles({
    row: {},
  });

interface Props {
  siteId: number;
  rows: User[];
  handleDeleteUser: (userId: number) => void;
  handleRemoveUserFromSite: (userId: number, siteId: number) => void;
  handleEditUser: (siteUser: User) => void;
  handleInviteUser: (siteUser: User) => void;
}

interface UserTableState {
  orderColumn: string;
  order: OrderOptions;
}

class UserTable extends React.Component<Props & WithStyles<typeof styles>, UserTableState> {
  state = {
    orderColumn: 'name',
    order: 'asc' as 'asc',
  };

  handleTableSort(key: string) {
    if (this.state.orderColumn === key) {
      if (this.state.order === 'asc') {
        this.setState({
          order: 'desc',
        });
      } else if (this.state.order === 'desc') {
        this.setState({
          order: undefined,
        });
      } else {
        this.setState({
          order: 'asc',
        });
      }
    } else {
      this.setState({
        order: 'asc',
        orderColumn: key,
      });
    }
  }

  get tableHeaders() {
    return [
      {
        title: 'Name',
        key: 'name',
        allowSort: true,
      },
      {
        title: 'Email',
        key: 'emailAddress',
        allowSort: true,
      },
      {
        title: 'Phone',
        key: 'phoneNumber',
        allowSort: true,
      },
      {
        title: 'Role',
        key: 'role',
        allowSort: true,
      },
      {
        title: '',
        key: 'manage-user',
      },
    ].map((item, index) => ({
      ...item,
      id: index,
    }));
  }

  get orderedRows() {
    const { rows } = this.props;
    if (!this.state.order) {
      return rows;
    }
    return orderBy(rows, [this.state.orderColumn], [this.state.order]);
  }

  dropDownOptions(row: User, profile?: UserProfile) {
    const { siteId, handleEditUser, handleDeleteUser, handleRemoveUserFromSite, handleInviteUser } =
      this.props;

    const siteRole = profile ? profile.sites.find((assg) => assg.siteId === siteId) : undefined;
    const hasSiteAdminRole = profile && siteRole?.role === SiteRole.SITE_ADMIN;

    const items = [
      profile &&
        (profile.isSystemAdmin || profile.isCustomerAdmin || hasSiteAdminRole) && {
          label: 'Edit',
          action: () => handleEditUser(row),
        },
      profile &&
        (profile.isSystemAdmin || profile.isCustomerAdmin) && {
          label: 'Delete',
          action: () => handleDeleteUser(row.userId),
        },
      {
        label: 'Remove from Site',
        action: () => handleRemoveUserFromSite(row.userId, siteId),
      },
      !row.isActive && {
        label: 'Resend Invite',
        action: () => handleInviteUser(row),
      },
    ];

    // remove any undefined items
    return compact(items);
  }

  getRole(row: User) {
    const { siteId } = this.props;
    return row.siteRoles.find((role) => role.siteId === siteId)?.role;
  }

  render() {
    const { classes } = this.props;
    const { orderColumn, order } = this.state;
    const tableHeaders = this.tableHeaders;

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => {
              if (header.allowSort) {
                return (
                  <TableCellTight
                    key={header.id}
                    align="left"
                    sortDirection={orderColumn === header.key ? order : false}
                  >
                    <Tooltip title="Sort" placement="bottom-end" enterDelay={300}>
                      <TableSortLabel
                        active={order && orderColumn === header.key}
                        direction={order}
                        onClick={() => this.handleTableSort(header.key)}
                      >
                        {header.title}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCellTight>
                );
              }
              return (
                <TableCell key={header.id} align="left">
                  {header.title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {this.orderedRows.map((row, index) => (
            <TableRow className={classes.row} key={index} data-testid={'user-table-row'}>
              <TableCellTight align="left" style={{ fontWeight: 500 }}>
                {row.name}
              </TableCellTight>
              <TableCellTight align="left">{row.emailAddress}</TableCellTight>
              <TableCellTight align="left">{row.phoneNumber}</TableCellTight>
              <TableCellTight align="left">{this.getRole(row) || '--'}</TableCellTight>
              <TableCellTight align="left" style={{ width: '1%' }}>
                <WithProfile
                  render={(profile: UserProfile) => (
                    <Dropdown
                      buttonComponent={(p) => (
                        <IconButton
                          {...p}
                          style={{ padding: 4 }}
                          data-testid={'user-table-row-actions'}
                        >
                          <MoreHoriz />
                        </IconButton>
                      )}
                      menuItems={this.dropDownOptions(row, profile)}
                    />
                  )}
                />
              </TableCellTight>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(styles)(UserTable);
