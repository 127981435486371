import { EntitiesStateSlice } from '../';
import { Site } from '../../../models';
import { NormalizationError } from '../../../helpers/norm';
import { denormNode } from '../node/selectors';
import { denormTag } from '../tag/selectors';
import { memoizeSelector } from '../../../helpers/selectors';
import { SiteNorm } from './reducer';

export const getSiteNorm = (
  rootState: EntitiesStateSlice,
  siteId: number
): SiteNorm | undefined => {
  return rootState.entities.site.byId[siteId];
};

export const denormSite = (rootState: EntitiesStateSlice, siteId: number) => {
  const siteNorm = getSiteNorm(rootState, siteId);
  if (!siteNorm) {
    throw new NormalizationError(`Unable to denormalize site: ${siteId}`, { siteId });
  }
  const { tagIds, nodeIds, ...site } = siteNorm;
  return {
    ...site,
    tags: tagIds.map((tagId) => denormTag(rootState, tagId)),
    nodes: nodeIds.map((nodeId) => denormNode(rootState, siteId, nodeId)),
  };
};

export const getSite = (rootState: EntitiesStateSlice, siteId: number): Site | undefined => {
  const siteNorm = getSiteNorm(rootState, siteId);
  return siteNorm ? denormSite(rootState, siteId) : undefined;
};

export const getSiteById = memoizeSelector(denormSite, [
  (state: EntitiesStateSlice) => state.entities.site.byId,
]);
