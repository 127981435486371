import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { ChangeRateFormValues } from '../../ui/form/ChangeRateForm.types';
import ChangeRateForm from '../../ui/form/ChangeRateForm';
import { UtilityRateLoader } from 'src/components/ui/form/UtilityRateLoader';
import {
  getUtilityRateContext,
  utilityRateReset,
  utilityRateSwitchSchedule,
} from 'src/state/contexts/utilityRate';
import { fetchSiteData } from 'src/state/entities/site/actions';

interface RateSwitcherProps {
  isDisplayed: boolean;
  toggleDisplay(): boolean;
  postalCode: string;
  initialFormValues: ChangeRateFormValues;
}

const useStyles = makeStyles(() => ({
  dialog: {
    height: 300,
  },
}));

function RateSwitcher(props: RateSwitcherProps): JSX.Element {
  const classes = useStyles();
  let { isDisplayed } = props;
  const {
    initialFormValues: { siteId },
  } = props;
  const dispatch = useDispatch();
  const { submission } = useSelector(getUtilityRateContext);

  const handleClose = () => {
    isDisplayed = props.toggleDisplay();
  };

  const handleSubmit = (values: ChangeRateFormValues) => {
    console.log('Save button clicked.');
    dispatch(utilityRateSwitchSchedule.request(values.siteId, Number(values.rateScheduleId)));
  };

  const dismissError = (): void => {
    dispatch(utilityRateReset());
    handleClose();
  };

  const handleSuccess = (): void => {
    console.log('Utility Rate update succeeded.');
    handleClose();
    dispatch(fetchSiteData.request(siteId));
    dispatch(utilityRateReset());
  };

  const { initialFormValues } = props;

  return (
    <Dialog open={isDisplayed} maxWidth={'md'} fullWidth classes={{ paper: classes.dialog }}>
      <DialogTitle>Change Utility Rate</DialogTitle>
      <DialogContent>
        <Container maxWidth={'sm'}>
          <UtilityRateLoader
            postalCode={props.postalCode}
            errorDismissalAction={
              <Button color="inherit" onClick={handleClose}>
                Close
              </Button>
            }
          >
            {({ utilities }) => (
              <ChangeRateForm
                {...initialFormValues}
                utilities={utilities}
                cancelAction={handleClose}
                dismissError={dismissError}
                submission={submission}
                handleSubmit={handleSubmit}
                handleSuccess={handleSuccess}
              ></ChangeRateForm>
            )}
          </UtilityRateLoader>
        </Container>
      </DialogContent>
    </Dialog>
  );
}

export default RateSwitcher;
