import { ActionsOf } from '../../../helpers/actions';
import { getType } from 'typesafe-actions';
import * as siteActions from './actions';
import produce from 'immer';
import { Site } from '../../../models';
import { Normalized } from '../../../helpers/norm';

export interface SiteNorm
  extends Normalized<Site, 'nodes' | 'tags', { nodeIds: number[]; tagIds: string[] }> {}

export interface SiteEntityState {
  byId: { [Key: number]: SiteNorm };
}

export interface SiteStateSlice {
  entities: {
    site: SiteEntityState;
  };
}

export const initialState = {
  byId: {},
  statusById: {},
};

type SiteActions = ActionsOf<typeof siteActions>;

export const siteEntityReducer = produce((draft: SiteEntityState, action: SiteActions) => {
  switch (action.type) {
    case getType(siteActions.fetchSiteData.success): {
      const { site } = action.payload.result;
      draft.byId[site.id] = site;
      break;
    }

    default:
      break;
  }
}, initialState);
