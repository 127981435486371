import { AccountApi, ApiConnection, ApiError, UserApi } from '../../../api';
import { apiSagaV2, getApiConnection } from '../../../helpers/saga';
import { takeEvery, select, put, take } from 'redux-saga/effects';
import { getType, ActionType } from 'typesafe-actions';
import { User, UserProfile } from '../../../models';
import * as userActions from './actions';
import { UserStateSlice } from './reducer';
import { findUserById } from './selectors';
import { hardRedirect } from '../../../helpers/navigation';
import { getUserProfile } from '../profile/selectors';
import { fetchUserProfile } from '../profile/actions';
import { PushError } from '../../../api/util/ApiError';

const createUser = apiSagaV2(userActions.createUser, (connection, action) => {
  const newUser = action.payload.newUser;

  return new UserApi(connection).createUser(newUser.siteId, newUser.role, newUser);
});

/**
 * We cannot use the apiSaga helper for this saga because we need to
 * get the user matching the id from the store
 *
 */
function* updateUser(action: ActionType<typeof userActions.updateUser.request>) {
  const connection: ApiConnection = yield select(getApiConnection);
  const updatedUser = action.payload.user;
  const profile: UserProfile = yield select(getUserProfile);
  try {
    const modifiedUser: User = yield new UserApi(connection).updateUser(updatedUser);
    /*
     * TODO: Replace this put with a direct api call to remove multiple
     * dispatch.
     */
    if (profile.userId === modifiedUser.userId) {
      yield put(fetchUserProfile.request());
      yield take(getType(fetchUserProfile.success));
    }
    yield put(userActions.updateUser.success(action, modifiedUser));
  } catch (e) {
    yield put(userActions.updateUser.error(action, e as ApiError));
  }
}

/**
 * We cannot use the apiSaga helper for this saga because we need to
 * get the user matching the id from the store
 *
 * TODO: We may want to refactor to remove this requirement
 */
function* removeFromSite(action: ActionType<typeof userActions.removeFromSite.request>) {
  const { userId, siteId } = action.payload;
  const currentUser: User = yield select((rootState: UserStateSlice) =>
    findUserById(rootState, userId)
  );
  const profile: UserProfile = yield select(getUserProfile);
  const connection: ApiConnection = yield select(getApiConnection);
  try {
    if (!currentUser) {
      throw new Error('No matching user found.');
    }
    let modifiedUser = {
      ...currentUser,
      siteRoles: currentUser.siteRoles.filter((role) => role.siteId !== siteId),
    };
    modifiedUser = yield new UserApi(connection).updateUser(modifiedUser);

    /*
     * TODO: Replace this put with a direct api call to remove multiple
     * dispatch.
     */
    if (profile.userId === modifiedUser.userId) {
      yield put(fetchUserProfile.request());
      yield take(getType(fetchUserProfile.success));
    }
    yield put(userActions.removeFromSite.success(action, modifiedUser));
  } catch (e) {
    yield put(userActions.removeFromSite.error(action, e as ApiError));
  }
}

const deleteUser = apiSagaV2(userActions.deleteUser, (connection, action) => {
  const { user } = action.payload;
  return new UserApi(connection).deleteUser(user.userId);
});

const resendInvite = apiSagaV2(userActions.resendInvite, (connection, action) => {
  return new UserApi(connection).resendInvite(action.payload.user);
});

function* activateAccount(action: ActionType<typeof userActions.activateAccount.request>) {
  const { email, registrationCode } = action.payload;
  try {
    const result: { data: string } = yield new AccountApi().activateAccount(
      email,
      registrationCode
    );

    yield put(userActions.activateAccount.success(action, result.data));
  } catch (e) {
    yield put(userActions.activateAccount.error(action, new ApiError(e as PushError)));
  }
}

/**
 * Need to redirect user to location specified by response
 */
function activateAccountSuccess(action: ActionType<typeof userActions.activateAccount.success>) {
  const { result: redirectUrl } = action.payload;
  hardRedirect(redirectUrl);
}

export function* userSagas() {
  yield takeEvery(getType(userActions.createUser.request), createUser);
  yield takeEvery(getType(userActions.updateUser.request), updateUser);
  yield takeEvery(getType(userActions.removeFromSite.request), removeFromSite);
  yield takeEvery(getType(userActions.deleteUser.request), deleteUser);
  yield takeEvery(getType(userActions.resendInvite.request), resendInvite);
  yield takeEvery(getType(userActions.activateAccount.request), activateAccount);
  yield takeEvery(getType(userActions.activateAccount.success), activateAccountSuccess);
}
