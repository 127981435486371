import { Dictionary, compact, last, some } from 'lodash';
import { TagDefinition, Site, TimeSeries, ComponentId, SocComponentId } from '../../../models';
import { Metric, ComponentFlow } from './types';
import { blue, green } from '@material-ui/core/colors';
import { includesAll } from '../../../helpers/common';

const ACTIVE_LIMIT = 0.1;

const getTags = (attributes: string[][], tagLookup: Dictionary<TagDefinition>) => {
  const tags = Object.values(tagLookup);
  const foundTags = compact(
    attributes.map((attributeList) => {
      return tags.find((tag) => includesAll(tag.attributes, attributeList));
    })
  );
  return foundTags;
};

export const getPowerflowTags = (
  site: Site,
  tagLookup: Dictionary<TagDefinition>
): TagDefinition[] => {
  const attributeMap = site.webPortalLayoutJson.lobby?.powerFlow?.elementAttributes || {};
  const attributes = Object.values(attributeMap);
  return getTags(attributes, tagLookup);
};

export const getMetricTags = (
  site: Site,
  tagLookup: Dictionary<TagDefinition>
): TagDefinition[] => {
  const elements = site.webPortalLayoutJson.lobby?.kPIs?.elements || [];
  const attributes = elements.map((e) => e.attributes);
  return getTags(attributes, tagLookup);
};

/**
 * buildFlows
 *
 * Returns a list of flows configured for this site, if there is a tag
 * in the dictionary which matches the attributes defined for a particular
 * flow then the site is configured to have that flow.
 *
 */
export const buildFlows = (
  site: Site,
  tags: TagDefinition[],
  snapshots: Dictionary<TimeSeries>
): ComponentFlow[] => {
  const attributeMap = site.webPortalLayoutJson.lobby?.powerFlow?.elementAttributes || {};
  let possibleComponents = Object.keys(attributeMap);

  // we want to filter out any components for which we do not have a valid tag
  possibleComponents = possibleComponents.filter((componentId) => {
    const testAttributes = attributeMap[componentId];
    // is there a tag that has attributes matching those specifed for this
    // component?
    return some(tags, (tag) => includesAll(tag.attributes, testAttributes));
  });

  // componentIds of all configured components for this site based on site config
  const componentIds = compact(
    possibleComponents.map((key) =>
      Object.values(ComponentId).find((id) => id.toLowerCase() === key.toLowerCase())
    )
  );

  // We now want build a flow for each component
  return componentIds.map((componentId) => {
    const attributes = attributeMap[componentId.toLowerCase()];
    let latestValue = undefined;

    const matchedTimeSeries = Object.values(snapshots).find((snapshot: TimeSeries) => {
      return includesAll(snapshot.tag.attributes, attributes);
    });
    if (matchedTimeSeries) {
      latestValue = last(matchedTimeSeries.points)?.value;
    }

    const out = {
      id: componentId,
      name: componentId as string,
      flow: latestValue,
      active: latestValue && Math.abs(latestValue) >= ACTIVE_LIMIT,
      unit: matchedTimeSeries?.tag.unit,
    } as ComponentFlow;

    // If we are dealing with the storage component we need to lookup the
    // charge additionalMetric
    if (componentId.toLowerCase() === ComponentId.Storage.toLowerCase()) {
      const socAttributes = attributeMap[SocComponentId.toLowerCase()];
      let socLatestValue = undefined;
      const matchedSocTimeSeries = Object.values(snapshots).find((snapshot: TimeSeries) => {
        return includesAll(snapshot.tag.attributes, socAttributes);
      });
      if (matchedSocTimeSeries) {
        socLatestValue = last(matchedSocTimeSeries.points)?.value;
      }
      out.additionalMetrics = [
        {
          label: 'Charge',
          value: socLatestValue === null ? undefined : socLatestValue,
          unit: '%',
        },
      ];
    }

    return out;
  });
};

/**
 * buildMetrics
 *
 * Takes site including layout config, and a dictionary of tag data and returns
 * the metrics to display on the dashboard
 */
export const buildMetrics = (site: Site, snapshots: Dictionary<TimeSeries>): Metric[] => {
  const elements = site.webPortalLayoutJson.lobby?.kPIs?.elements || [];

  return elements.map((element) => {
    const attributes = element.attributes;

    let latestValue = undefined;

    const matchedTimeSeries = Object.values(snapshots).find((snapshot: TimeSeries) => {
      return includesAll(snapshot.tag.attributes, attributes);
    });

    if (matchedTimeSeries) {
      latestValue = last(matchedTimeSeries.points)?.value;
    }

    if (['Solar Generation', 'Battery Generation'].includes(element.subtitle)) {
      latestValue = latestValue && latestValue < 0 ? 0 : latestValue;
    }

    return {
      label: element.title,
      subLabel: element.subtitle,
      valueColor: matchedTimeSeries?.tag.unit === '$' ? green[400] : blue[400],
      value: latestValue === null ? undefined : latestValue,
      unit: matchedTimeSeries?.tag.unit,
      precision: matchedTimeSeries?.tag.unit === '$' ? 2 : 1,
    };
  });
};

/**
 * How close should flow be to 0 before we consider it 0?
 */
export const FLOW_TOL = 0.5;
