import React from 'react';
import { SelectProps, MenuItem, Checkbox, ListItemText, TextField } from '@material-ui/core';
import { compact } from 'lodash';

interface MultipleSelectProps<T extends string | number> {
  label: string;
  options: {
    name: string;
    value: T;
  }[];
  value: T[];
  helperText?: string;
}

export const MultipleSelect = <T extends string | number>(
  props: React.PropsWithChildren<MultipleSelectProps<T> & SelectProps>
) => {
  const { helperText, onChange, variant, ...rest } = props;
  return (
    <TextField
      select
      variant="outlined"
      size="small"
      fullWidth={rest.fullWidth}
      SelectProps={{
        multiple: true,
        value: rest.value,
        onChange,
        classes: rest.classes,
        renderValue: (value) =>
          compact(
            (value as T[]).map((v) => (props.options.find((o) => o.value === v) || {}).name)
          ).join(', '),
      }}
      id={rest.id}
      name={rest.name}
      label={rest.label}
      helperText={helperText}
      error={rest.error}
    >
      {props.options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox checked={props.value && props.value.indexOf(option.value) > -1} />
          <ListItemText primary={option.name} />
        </MenuItem>
      ))}
    </TextField>
  );
};
