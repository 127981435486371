import React from 'react';
import { SimpleRequestStatus, Lifecycle } from '../../state/request';
import LoadWithSpinner from '../ui/Loading/LoadWithSpinner';
import { ErrorMessage } from '../ui/ErrorMessage';

export interface RequestStatusProps {
  status: SimpleRequestStatus;
  loadMessage?: string;
  minHeight?: string;
  errorMessage?: string;
  style?: React.CSSProperties;
}

const DEFAULT_ERROR = 'Error loading requested resource.';

export const RequestStatus: React.FC<RequestStatusProps> = (props) => {
  const { status, loadMessage } = props;

  if (status === Lifecycle.PENDING) {
    return (
      <div
        style={{
          display: 'flex',
          minHeight: props.minHeight || 0,
          justifyContent: 'center',
          alignContent: 'center',
          padding: 16,
          ...props.style,
        }}
      >
        <LoadWithSpinner message={loadMessage} />
      </div>
    );
  } else if (typeof status === 'object') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: props.minHeight || 0,
          justifyContent: 'center',
          alignContent: 'center',
          padding: 36,
          ...props.style,
        }}
      >
        <ErrorMessage size="sm" message={props.errorMessage || status.message || DEFAULT_ERROR} />
      </div>
    );
  } else if (props.children) {
    return (
      <div
        style={{
          display: 'flex',
          minHeight: props.minHeight || 0,
          justifyContent: 'center',
          alignContent: 'center',
          padding: 16,
          ...props.style,
        }}
      >
        {props.children}
      </div>
    );
  }
  return null;
};
