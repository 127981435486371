import React from 'react';
import { MenuItem, Button, Menu } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { MenuProps } from '@material-ui/core/Menu';

interface Props {
  buttonText?: string;
  buttonColor?: string;
  buttonSize?: string;
  buttonVariant?: string;
  buttonComponent?: (p: { onClick: ButtonProps['onClick'] }) => React.ReactNode;
  disabled?: boolean;
  pos?: MenuProps['anchorOrigin'];
  menuItems: {
    action: () => void;
    label: string;
    disabled?: boolean;
  }[];
}

class Dropdown extends React.Component<Props> {
  state = {
    anchorEl: undefined,
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: undefined });
  };

  render() {
    const { menuItems, buttonText } = this.props;
    const { anchorEl } = this.state;

    return (
      <>
        <div>
          {this.props.buttonComponent ? (
            this.props.buttonComponent({ onClick: this.handleClick })
          ) : (
            <Button
              variant={(this.props.buttonVariant as ButtonProps['variant']) || 'contained'}
              color={this.props.buttonColor as ButtonProps['color'] | undefined}
              size={this.props.buttonSize as ButtonProps['size'] | undefined}
              disabled={this.props.disabled ? true : false}
              onClick={this.handleClick}
            >
              {buttonText}
            </Button>
          )}
        </div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          getContentAnchorEl={undefined}
          anchorOrigin={this.props.pos || { vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={this.handleClose}
        >
          {menuItems.map((item: Props['menuItems'][0]) => {
            return (
              <MenuItem
                disabled={item.disabled}
                onClick={() => {
                  item.action();
                  this.handleClose();
                }}
                key={item.label}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }
}

export default Dropdown;
