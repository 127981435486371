import React from 'react';
import { Box, Grid, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { SiteSummary } from '../../../models';
import SiteSearch from './SiteSearch';
import SiteFilter from './SiteFilter';
import SiteSummaryTable, { filterableSiteSummaryTableColumns } from './SiteSummaryTable';
import TableColumnsFilter from './TableColumnsFilter';
import { PortfolioFilter, PortfolioFilterValues } from '../../../state/contexts/portfolioPage';
import { ApiError } from '../../../api';
import { RequestStatus } from '../../../components/utility/RequestStatus';
import { Lifecycle } from '../../../state/request';
import { OrderDirectionType } from '../../../components/ui/DataTable';
import { useLocalStorageState } from '../../../helpers/hooks';

const PAGE_SIZE = 25;

interface ProfilePageProps {
  handleSearch: (searchText: string) => void;
  handleSort: (key: string, direction: OrderDirectionType) => void;
  handleChangePage: (page: number) => void;
  handleFilter: (filter: PortfolioFilterValues) => void;
  siteSummaries: SiteSummary[];
  activeFilters: PortfolioFilterValues;
  activePage: number;
  activeSort: OrderDirectionType;
  activeSearch?: string;
  sortBy: string;
  filterOptions?: PortfolioFilter;
  totalSites?: number;
  loading: boolean;
  error?: ApiError;
  isAdmin?: boolean;
}

const ProfilePage = (props: ProfilePageProps) => {
  const {
    handleFilter,
    handleSearch,
    handleSort,
    handleChangePage,
    siteSummaries,
    activeFilters,
    activePage,
    activeSort,
    activeSearch,
    sortBy,
    filterOptions,
    totalSites,
    loading,
    error,
    isAdmin,
  } = props;

  const [activeColumns, setActiveColumns] = useLocalStorageState<string[]>(
    'portfolioPage.columns',
    Object.keys(filterableSiteSummaryTableColumns)
  );

  const classes = useStyles();

  const handleColumnsFilterChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: string[];
    }>
  ) => setActiveColumns(event.target.value);

  const showTable = totalSites !== undefined && siteSummaries.length > 0 && !loading && !error;

  return (
    <Grid className={classes.root} container direction="column" spacing={1}>
      <Grid
        container
        item
        direction="row"
        style={{ flex: 0, justifyContent: 'center', padding: '24px 16px 0px' }}
      >
        <Grid item sm={6} xs={12} style={{ marginBottom: 8 }}>
          <Grid container alignContent="center" alignItems="center">
            <Grid item style={{ paddingRight: 12, marginBottom: 4 }}>
              <SiteSearch extValue={activeSearch} handleSearch={handleSearch} />
            </Grid>
            <Grid item style={{ marginBottom: 4 }}>
              {filterOptions && (
                <SiteFilter
                  hideChips={true}
                  isAdmin={isAdmin}
                  handleFilterChange={handleFilter}
                  currentFilters={activeFilters}
                  availableFilters={filterOptions}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12} style={{ marginBottom: 8 }}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography align="right">
                {showTable && totalSites && (
                  <ResultCount
                    activePage={activePage}
                    resultCount={siteSummaries.length}
                    totalCount={totalSites}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item style={{ paddingLeft: 16 }}>
              <TableColumnsFilter
                isAdmin={isAdmin}
                activeColumns={activeColumns}
                handleChange={handleColumnsFilterChange}
                options={Object.keys(filterableSiteSummaryTableColumns).map((key) => ({
                  value: key,
                  label: filterableSiteSummaryTableColumns[key],
                }))}
              />
            </Grid>
          </Grid>
        </Grid>
        {filterOptions && (
          <Grid item xs={12} style={{ marginBottom: 8 }}>
            <SiteFilter
              hideToggle={true}
              isAdmin={isAdmin}
              handleFilterChange={handleFilter}
              currentFilters={activeFilters}
              availableFilters={filterOptions}
            />
          </Grid>
        )}
      </Grid>

      <Grid className={classes.body} item>
        <SiteSummaryTable
          handleSort={handleSort}
          siteSummaries={siteSummaries}
          page={activePage}
          orderDirection={activeSort}
          columnsShowing={activeColumns}
          orderBy={sortBy}
          isAdmin={isAdmin}
        />

        {(loading || error) && (
          <Box className={classes.statusWrapper}>
            <div className={classes.statusInner}>
              <RequestStatus status={error || Lifecycle.PENDING} />
            </div>
          </Box>
        )}
      </Grid>

      {showTable && totalSites !== undefined && (
        <Grid item className={classes.footer}>
          <Toolbar>
            <Grid justifyContent="center" container alignItems="center" spacing={2}>
              <Grid item>
                <Pagination
                  count={Math.ceil(totalSites / PAGE_SIZE)}
                  page={activePage || 1}
                  variant="outlined"
                  shape="rounded"
                  onChange={(_e, newPage) => {
                    if (newPage === activePage) {
                      return;
                    }
                    handleChangePage(newPage);
                  }}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
      )}
    </Grid>
  );
};

const ResultCount = (props: { activePage: number; resultCount: number; totalCount: number }) => {
  const { activePage, resultCount, totalCount } = props;
  const startIdx = PAGE_SIZE * ((activePage || 1) - 1);
  const endIdx = PAGE_SIZE * ((activePage || 1) - 1) + resultCount;
  return (
    <>
      {startIdx + 1} - {endIdx} of {totalCount}
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    ...theme.mixins.pageBackground,
    boxShadow: 'none',
    height: '100%',
  },
  footer: {
    borderTop: '1px solid #E8E8E8',
    backgroundColor: '#FFFFFF',
    padding: 0,
  },
  body: {
    position: 'relative',
    flex: 1,
    overflow: 'auto',
    width: '100%',
    padding: '0 4px !important',
  },
  chip: {
    margin: theme.spacing(0, 0.2),
  },
  statusWrapper: {
    zIndex: 1000,
    top: '0',
    left: 0,
    bottom: 0,
    width: '100%',
    padding: 24,
    textAlign: 'center',
    position: 'absolute',
    backgroundColor: '#33333399',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statusInner: {
    maxWidth: 320,
    minWidth: 180,
    backgroundColor: '#FFF',
    border: '1px solid #CCC',
    borderRadius: 4,
  },
}));

export default ProfilePage;
