import { TagDefinition, HasTags } from './tag';
import { TimeSeries } from './snapshot';

export enum AlarmStatus {
  INACTIVE = 'Inactive',
  ACTIVE_UN_ACK = 'Active Unacknowledged',
  ACTIVE_ACK = 'Active Acknowledged',
  RESOLVED = 'Resolved',
}

export enum AlarmPriority {
  INFO = 'Informational',
  WARNING = 'Warning',
  HIGH = 'High',
  CRITICAL = 'Critical',
}

export interface AlarmSummaryList {
  alarmSummaries: AlarmSummary[];
}

export interface AlarmSummary {
  alarmUid: string;
  alarmId: string;
  activatedAt: string;
  resolvedAt: string;
  status: AlarmStatus;
  alarmTag: TagDefinition;
  acknowledgement: Note;
  relatedTagIds: string[];
}

export interface AlarmId {
  alarmId: string;
  alarmTagId: string;
}

export interface AlarmDetails {
  alarmUid: string;
  alarmId: string;
  activatedAt: string;
  resolvedAt: string;
  status: AlarmStatus;
  alarmTag: TagDefinition;
  rawSourceTag: TagDefinition;
  acknowledgement: Note;
  notes: Note[];
  relatedTags: TagDefinition[];
  relatedData: TimeSeries[];
}

export interface Note {
  timeStamp: string;
  userId: string;
  userEmail: string;
  contents: string;
}

export interface AlarmNote {
  contents: string;
}

export const getAlarmUid = (alarm: {
  alarmTag?: TagDefinition;
  alarmTagId?: string;
  alarmId: string;
}) => {
  if (typeof alarm.alarmTag === 'object') {
    return `${alarm.alarmId}::${alarm.alarmTag.id}`;
  } else if (typeof alarm.alarmTagId === 'string') {
    return `${alarm.alarmId}::${alarm.alarmTagId}`;
  } else {
    throw new Error('Invalid object type');
  }
};

export const alarmCanBeResolved = (alarm: AlarmDetails | AlarmSummary) => {
  const isActive =
    alarm.status === AlarmStatus.ACTIVE_ACK || alarm.status === AlarmStatus.ACTIVE_UN_ACK;
  return isActive && alarm.alarmTag.alarmCanBeResolved;
};

export const alarmDetailsToSummary = (alarmDetails: AlarmDetails): AlarmSummary => {
  const { notes, relatedData, relatedTags, rawSourceTag, ...rest } = alarmDetails;
  const alarmSummary = {
    ...rest,
    relatedTagIds: alarmDetails.relatedTags.map((t) => t.id),
  };

  return alarmSummary;
};

export const getSummariesForEntity = (
  summaries: AlarmSummary[],
  entity: HasTags
): AlarmSummary[] => {
  const tagIds = entity.tags.map((tag) => tag.id);
  return summaries.filter((summary) => tagIds.includes(summary.alarmTag.id));
};
