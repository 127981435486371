import React from 'react';
import { compact } from 'lodash';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import useSize from '@react-hook/size';
import * as d3 from 'd3';
import { range } from 'lodash';
import { useAnimationFrame } from '../../../helpers/hooks';
import { Typography } from '@material-ui/core';
import { useRef } from 'react';
import { ComponentId } from '../../../models';
import { ComponentFlow } from './types';
import identifiers from '../../../tests/identifiers';
import { ismPulse } from '../../../config';
import { FLOW_TOL } from './config';

const storageIcon = require('../../../assets/icons/storage-3d.png').default;
const solarIcon = require('../../../assets/icons/solar-3d.png').default;
const utilityIcon = require('../../../assets/icons/utility-3d.png').default;
const generatorIcon = require('../../../assets/icons/generator-3d.png').default;
const loadIcon = require('../../../assets/icons/load-3d.png').default;
const controlIcon = ismPulse
  ? require('../../../assets/mpulse-icon-dark.png').default
  : require('../../../assets/mpulse-icon-dark.png').default;

const ANIMATION_RATE = 1 / 10;
//const DOT_SPACING = 100;

const imageLookup = {
  [ComponentId.Generator]: generatorIcon,
  [ComponentId.Load]: loadIcon,
  [ComponentId.Solar]: solarIcon,
  [ComponentId.Storage]: storageIcon,
  [ComponentId.Utility]: utilityIcon,
};

export const leftComponents = [
  ComponentId.Utility,
  ComponentId.Solar,
  ComponentId.Storage,
  ComponentId.Generator,
];

export interface PowerflowDiagramProps {
  flows: ComponentFlow[];
}

export const PowerflowDiagram: React.FC<PowerflowDiagramProps> = (props) => {
  const { flows } = props;
  const classes = useStyles();

  // defines the slot priority for component placement on the left
  const posOrder = [2, 1, 3, 0, 4];
  const leftFlows = compact(
    leftComponents.map((compId) => flows.find((flow) => flow.id === compId))
  );

  const loadComponent = flows.find((flow) => flow.id === ComponentId.Load);

  const [_, setRerender] = React.useState<number>(0);

  // define ref for resize listener
  const topRef = React.useRef<HTMLDivElement>(null);

  // define refs for positioning the edges
  const leftRefs = React.useRef(leftFlows.map(() => React.createRef<HTMLDivElement>()));
  const loadRef = React.useRef<HTMLDivElement>(null);
  const [controlRef, setControlRef] = React.useState<HTMLDivElement | null>(null);

  // triggers re-render when topRef size changes
  const [width, height] = useSize(topRef);

  if (!loadComponent) {
    throw new Error('Load component not found for powerflow diagram.');
  }

  if (leftFlows.length === 0) {
    throw new Error('No source components specified for powerflow diagram.');
  }

  // We will use the size of this component for responsive checks instead of
  // the viewport since it will be rendered in columns
  const isSmall = width < 960;
  const isXSmall = width < 600;
  const tileSize = isSmall ? 68 : 88;
  const loadTileSize = isSmall ? 115 : 120;
  const controlTileSize = isSmall ? 80 : 100;
  const gridSize = height / 5;
  const gridTopPos = (i: number, size: number) => gridSize * i + gridSize / 2 - size / 2;
  const rate = (ANIMATION_RATE * Math.pow(width - 200, 0.5)) / 10000;
  return (
    <div style={{ paddingTop: 24 }}>
      <div
        className={clsx(
          classes.container,
          isSmall && classes.smallSize,
          isXSmall && classes.xSmallSize
        )}
        ref={topRef}
      >
        <div className={classes.centerBackground} />

        <svg className={classes.svgEl}>
          {leftFlows.map((flow, i) => {
            let ref = leftRefs.current[i];
            if (!ref) {
              ref = React.createRef<HTMLDivElement>();
              leftRefs.current[i] = ref;
              // TODO: This is a hack to get around the dynamic refs
              setTimeout(() => setRerender(_ + 1), 10);
            }
            if (ref.current != null && Math.abs(flow.flow) > FLOW_TOL) {
              return (
                <PowerflowEdge
                  key={flow.id}
                  rate={rate}
                  aRef={ref}
                  bRef={{ current: controlRef }}
                  invertFlow={flow.flow < 0}
                  bOffset={{ x: i * 5 - 10, y: 0 }}
                  componentId={flow.id}
                />
              );
            }
            return null;
          })}

          {loadRef.current && Math.abs(loadComponent.flow) > FLOW_TOL && (
            <PowerflowEdge
              rate={rate}
              aRef={{ current: controlRef }}
              bRef={loadRef}
              invertFlow={false}
              componentId={ComponentId.Load}
            />
          )}
        </svg>

        <h3 className={classes.textSubTitle}>Facility Power Distribution</h3>

        {leftFlows.map((flow, i) => {
          return (
            <PowerflowTile
              key={flow.id}
              pos={{ top: gridTopPos(posOrder[i], tileSize), left: 0 }}
              topref={leftRefs.current[i]}
              componentFlow={flow}
            />
          );
        })}

        <div
          style={{
            backgroundImage: `url(${controlIcon})`,
            position: 'absolute',
            top: gridTopPos(posOrder[0], controlTileSize),
            left: `calc(50% - ${controlTileSize / 2}px)`,
          }}
          ref={(newRef) => setControlRef(newRef)}
          className={classes.controllerTile}
        />

        <PowerflowTile
          key={loadComponent.id}
          className={clsx(classes.loadTile)}
          pos={{ top: gridTopPos(posOrder[0], loadTileSize) }}
          topref={loadRef}
          componentFlow={loadComponent}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    height: '62vh',
    maxHeight: 24 * 4 + 80 * 5 + 24,
    minHeight: 450,
    '&$smallSize': {
      maxHeight: 16 * 4 + 60 * 5 + 16,
      minHeight: 360,
    },
  },
  svgEl: {
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
  },
  controllerTile: {
    backgroundColor: '#fff',
    width: 100,
    height: 100,
    border: '1px solid #ccc',
    borderRadius: 4,
    backgroundSize: '80px 80px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '95%',
      height: '90%',
      left: '2.5%',
      bottom: 0,
      borderRadius: 8,
      zIndex: -1,
      opacity: 0.18,
      boxShadow: '0px 2px 4px 4px #003D7B',
    },
  },
  tileImage: {
    width: 120,
    height: 100,
    margin: '-10px -20px',
    float: 'left',
    '$loadTile &': {
      marginBottom: -30,
    },
  },
  tileContent: {
    flexDirection: 'column',
    padding: '12px 0',
  },
  tile: {
    backgroundColor: '#fff',
    display: 'flex',
    width: 200,
    height: 88,
    border: '1px solid #CCD7E2',
    borderRadius: 4,
    marginBottom: 16,
    maxWidth: 'calc(33.333% - 4px)',
    boxSizing: 'border-box',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '80%',
      height: 25,
      bottom: 0,
      left: '10%',
      zIndex: -1,
      opacity: 0.12,
      boxShadow: '0px 10px 10px 0px #003D7B',
    },
  },
  smallSize: {
    '& $tileImage': {
      width: 0.8 * 120,
      height: 0.8 * 100,
    },

    '& $tile': {
      height: 68,
      marginBottom: 8,
    },
    '& $loadTile': {
      height: 115,
      width: 115,
      right: 32,
    },
    '& $loadTile $tileImage': {
      marginTop: -10,
      marginBottom: -10,
    },
    '& $textSubTitle': {
      fontSize: '14px',
    },
    '& $tileContent': {
      padding: '4px 0',
    },
    '& $textComponentLabel': {
      fontSize: '14px',
    },
    '& $textComponentValue': {
      fontSize: '12px',
    },
    '& textComponentValueConsuming': {
      fontSize: '12px',
    },
    '& $textComponentAdditional': {
      fontSize: '12px',
    },
    '& $controllerTile': {
      width: 80,
      height: 80,
      backgroundSize: '60px 60px',
    },
  },
  xSmallSize: {
    '& $textComponentLabel': {
      fontSize: '13px',
    },
    '& $textComponentValue': {
      fontSize: '11px',
    },
    '& textComponentValueConsuming': {
      fontSize: '11px',
    },
    '& $textComponentAdditional': {
      fontSize: '11px',
    },
  },
  loadTile: {
    height: 120,
    width: 120,
    maxWidth: '30%',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 8,
    right: 36,
    '& $smallSize': {
      height: 80,
      width: 80,
    },
    '& $tileContent': {
      marginTop: -4,
      textAlign: 'center',
    },
  },
  loadImage: {
    height: 120,
    width: 120,
    margin: '-10px -10px -30px -10px',
  },
  edge: {
    stroke: '#479AFA',
    fill: 'none',
  },
  textSubTitle: {
    textAlign: 'center',
    position: 'absolute',
    top: -26,
    left: 0,
    right: 0,
    margin: 0,
    color: '#8B8A8A',
    fontSize: '14px',
    fontWeight: 400,
  },
  centerBackground: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '50%',
    width: '30%',
    maxWidth: 'calc(33.333% - 4px)',
    transform: 'translateX(-50%)',
    backgroundColor: '#DDDDDD',
    opacity: '.3',
    borderRadius: 8,
  },
  textComponentLabel: {
    fontSize: '15px',
    color: '#221F1F',
  },
  textComponentValue: {
    fontSize: '13px',
    color: '#727272',
  },
  textComponentValueConsuming: {
    fontSize: '13px',
    color: '#006DC7',
  },
  textComponentAdditional: {
    fontSize: '13px',
    color: '#13A300',
  },
  inactiveTile: {
    border: '1px solid #CCD7E2',
    '& $textComponentLabel': {
      opacity: 0.45,
    },
    '& $textComponentValue': {
      opacity: 0.45,
    },
    '& $tileImage': {
      filter: 'saturate(0)',
      opacity: 0.45,
    },
  },
}));

interface PowerflowTileProps {
  pos: { top?: number; left?: number; right?: number; bottom?: number };
  className?: string;
  componentFlow: ComponentFlow;
  topref: React.RefObject<HTMLDivElement>;
}

const PowerflowTile = (props: PowerflowTileProps) => {
  const classes = useStyles();
  const { pos, componentFlow, className } = props;
  const { flow, name, additionalMetrics, unit } = componentFlow;
  const isInactive = Math.abs(flow) < FLOW_TOL;
  return (
    <div
      data-testid={identifiers.dashboardPage.flowComponent + '-' + componentFlow.id}
      style={{ position: 'absolute', ...pos }}
      ref={props.topref}
      className={clsx(classes.tile, isInactive && classes.inactiveTile, className)}
    >
      <div>
        <img
          alt={`${componentFlow.id} Icon`}
          className={classes.tileImage}
          src={imageLookup[componentFlow.id]}
        />
      </div>
      <div className={classes.tileContent}>
        <Typography className={classes.textComponentLabel}>{name}</Typography>
        <Typography
          className={
            flow < -1 * FLOW_TOL ? classes.textComponentValueConsuming : classes.textComponentValue
          }
        >
          {flow !== undefined ? (
            <>
              {Math.abs(flow).toFixed(1)} {unit || ''}
            </>
          ) : (
            <>No Data</>
          )}
        </Typography>
        {additionalMetrics &&
          additionalMetrics.map((metric) => {
            return (
              <Typography
                data-testid={identifiers.dashboardPage.flowComponentValue}
                key={`${metric.value?.toFixed(1)}-${metric.unit}`}
                className={classes.textComponentAdditional}
              >
                {metric.value?.toFixed(1) || '-'} {metric.unit}
              </Typography>
            );
          })}
      </div>
    </div>
  );
};

interface Position {
  x: number;
  y: number;
}

export const getCenterPos = (ref: React.RefObject<HTMLDivElement>) => {
  if (!ref.current) {
    throw new Error('No ref given.');
  }
  const { offsetTop, offsetLeft, offsetHeight, offsetWidth } = ref.current;

  return {
    x: offsetLeft + offsetWidth / 2,
    y: offsetTop + offsetHeight / 2,
    width: offsetWidth,
    height: offsetHeight,
  };
};

const moveTo = (pos: Position) => {
  return `M ${pos.x} ${pos.y}`;
};

const lineTo = (end: Position) => {
  return `L ${end.x} ${end.y}`;
};

const arcTo = (start: Position, end: Position, radius: number) => {
  return `A ${radius} ${radius} ${0} ${0} ${start.y <= end.y ? 1 : 0} ${end.x} ${end.y}`;
};

interface PowerflowEdgeProps {
  aRef: React.RefObject<HTMLDivElement>;
  bRef: React.RefObject<HTMLDivElement>;
  rate: number;
  invertFlow?: boolean;
  aOffset?: Position;
  bOffset?: Position;
  componentId: ComponentId;
}

const PowerflowEdge = (props: PowerflowEdgeProps) => {
  const classes = useStyles();

  const { aRef, bRef, bOffset, invertFlow, componentId, rate } = props;

  const aPos = getCenterPos(aRef);
  aPos.x = aPos.x + aPos.width / 2;
  const bPos = getCenterPos(bRef);

  if (bOffset) {
    bPos.x = bPos.x + bOffset.x;
    bPos.y = bPos.y + bOffset.y;
  }

  const radius = 20;
  const aCorner = { x: bPos.x - radius, y: aPos.y };
  const bCorner = { x: bPos.x, y: aPos.y > bPos.y ? aPos.y - radius : aPos.y + radius };

  let path = [moveTo(aPos), lineTo(aCorner)];
  if (Math.abs(aPos.y - bPos.y) >= radius) {
    if (aPos.y > bPos.y) {
      bPos.y = bPos.y + bPos.height / 2;
    } else {
      bPos.y = bPos.y - bPos.height / 2;
    }
    path = path.concat([arcTo(aCorner, bCorner, radius), lineTo(bPos)]);
  } else {
    aCorner.x = aCorner.x - bPos.width / 2 + radius - (bOffset?.x || 0);
    path = [moveTo(aPos), lineTo(aCorner)];
  }

  const pathStr = path.join(' ');

  const pathRef = React.useRef<SVGPathElement>(null);

  const [dotPos, setDotPos] = React.useState<Position[]>([]);

  let dots: Position[] = [];

  const animationRate = rate; // px per sec

  const lastTS = useRef<number>(0);
  const offset = useRef<number>(0);

  // Handle the animation of the dots.
  useAnimationFrame(
    (ts) => {
      if (pathRef.current && lastTS.current) {
        const node = d3.select(pathRef.current).node();

        if (node !== null) {
          // Total length of path
          const totalLength = node.getTotalLength();
          // Number of uniformly spaced dots that will fit
          const dotCount = 1;
          const spacing = totalLength / dotCount;
          // Animation percentage bounded to [0, 1]
          offset.current = (offset.current + animationRate * (ts - lastTS.current)) % 1;

          // calculate dot positions
          dots = compact(
            range(dotCount).map((i) => {
              let p = 0;
              if (!invertFlow) {
                p = (offset.current * (totalLength / dotCount) + i * spacing) % totalLength;
              } else {
                p =
                  (totalLength - i * spacing - offset.current * (totalLength / dotCount)) %
                  totalLength;
              }
              return node?.getPointAtLength(p);
            })
          );
          setDotPos(dots);
        }
      }
      lastTS.current = ts;
    },
    // if the flow direction changes we need the animation to know about it
    [invertFlow]
  );

  const testId =
    (invertFlow ? identifiers.dashboardPage.flowEdgeReversed : identifiers.dashboardPage.flowEdge) +
    '-' +
    componentId;

  return (
    <g data-testid={testId}>
      <path ref={pathRef} d={pathStr} className={classes.edge} />;
      {dotPos.map((pos, i) => {
        return <circle key={i} r="4" fill="#479AFA" cx={pos.x} cy={pos.y} />;
      })}
    </g>
  );
};
