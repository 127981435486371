import { RootState } from '../../';
import { getUserProfile } from '../../entities/profile/selectors';

export const getIsConnected = (state: RootState) =>
  state.contexts.pushFeed.pushFeedState === 'connected';

export const getPushFeedStatus = (state: RootState) => state.contexts.pushFeed.pushFeedState;

export const getPushConnectionId = (state: RootState) => state.contexts.pushFeed.connectionId;

export const getProfileContext = (rootState: RootState) => {
  const profile = getUserProfile(rootState);
  if (!profile) {
    throw new Error('Unable to find user profile');
  }
  return profile;
};
