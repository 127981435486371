import {
  RequestStatusStateSlice,
  RequestAction,
  RequestStatus,
  RequestActionType,
  serializeAction,
  Lifecycle,
  SimpleRequestStatus,
  RequestActionCreator,
} from './reducer';
import { ApiError } from '../../api';
import { sortBy } from 'lodash';
import { getType, ActionType } from 'typesafe-actions';

export const isUnrequested = (
  state: RequestStatusStateSlice,
  triggerAction: RequestAction
): boolean => {
  const key = serializeAction(triggerAction);
  const uid = (state.requests.byPayload[triggerAction.type] || {})[key];
  return uid === undefined;
};

const lookupActionRecord = (state: RequestStatusStateSlice, triggerAction: RequestAction) => {
  const key = serializeAction(triggerAction);
  const uid = (state.requests.byPayload[triggerAction.type] || {})[key];
  const record = state.requests.byUid[uid];
  return record;
};

export const isPending = (
  state: RequestStatusStateSlice,
  triggerAction: RequestAction
): boolean => {
  const record = lookupActionRecord(state, triggerAction);
  return Boolean(record && record.status === Lifecycle.PENDING);
};

export const isComplete = (
  state: RequestStatusStateSlice,
  triggerAction: RequestAction
): boolean => {
  const record = lookupActionRecord(state, triggerAction);
  return Boolean(record && record.status === Lifecycle.COMPLETED);
};

export const isError = (state: RequestStatusStateSlice, triggerAction: RequestAction): boolean => {
  const record = lookupActionRecord(state, triggerAction);
  return Boolean(record && typeof record.status === 'object');
};

export const getError = (
  state: RequestStatusStateSlice,
  triggerAction: RequestAction
): ApiError | undefined => {
  const record = lookupActionRecord(state, triggerAction);
  return record && typeof record.status === 'object' ? record.status : undefined;
};

export const getStatus = (
  state: RequestStatusStateSlice,
  triggerAction: RequestAction
): SimpleRequestStatus => {
  const record = lookupActionRecord(state, triggerAction);
  return record ? record.status : undefined;
};

export const getLastDispatched = <T extends RequestAction>(
  state: RequestStatusStateSlice,
  triggerAction: T
): T | undefined => {
  const record = lookupActionRecord(state, triggerAction);
  return record ? (record.trigger as T) : undefined;
};

/**
 * Returns the first un-cleared error to have occurred for a particular action
 * type, and optionally actionInstance. Returns undefined if no errors exist.
 */
export const getFirstError = (
  state: RequestStatusStateSlice,
  triggerActionType: RequestActionType,
  actionInstance?: RequestAction
): ApiError | undefined => {
  let errors = Object.keys(state.requests.byError[triggerActionType] || {}).map(
    (actionId) => state.requests.byUid[actionId]
  );
  errors = sortBy(errors, (error) => error.trigger.meta.triggeredTs);
  if (actionInstance) {
    errors = errors.filter(
      (error) => serializeAction(error.trigger) === serializeAction(actionInstance)
    );
  }
  return errors.length > 0 && typeof errors[0].status === 'object' ? errors[0].status : undefined;
};

/**
 * Returns the first un-cleared request to have occurred for a particular action
 * type, and optionally actionInstance. Returns undefined if no request exists.
 */
export const getFirstRequest = <T extends RequestActionCreator>(
  state: RequestStatusStateSlice,
  triggerActionCreator: T
) => {
  let requests = Object.values(state.requests.byUid).filter(
    (request) =>
      request.trigger.type === getType(triggerActionCreator) &&
      request.status !== Lifecycle.COMPLETED &&
      !request.cleared
  );
  requests = sortBy(requests, (request) => request.trigger.meta.triggeredTs);
  return requests.length > 0 ? requests[0] : undefined;
};

export const getIsTypePending = (
  state: RequestStatusStateSlice,
  triggerActionType: RequestActionType
): boolean => {
  const typeRecord = state.requests[triggerActionType];
  return Object.values(typeRecord).includes(Lifecycle.PENDING);
};

export const getAllPending = <T extends RequestActionCreator>(
  state: RequestStatusStateSlice,
  triggerActionCreator: T
): RequestStatus<ActionType<T>>[] => {
  const actionType = getType(triggerActionCreator);
  const uids = Object.keys(state.requests.byPending[actionType] || {});
  return uids.map((uid) => state.requests.byUid[uid]) as RequestStatus<ActionType<T>>[];
};

export const getAllError = <T extends RequestActionCreator>(
  state: RequestStatusStateSlice,
  triggerActionCreator: T
): RequestStatus<ActionType<T>>[] => {
  const actionType = getType(triggerActionCreator);
  const uids = Object.keys(state.requests.byError[actionType] || {});
  return uids.map((uid) => state.requests.byUid[uid]) as RequestStatus<ActionType<T>>[];
};
