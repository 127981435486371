import { ControlDatasetStateSlice } from './reducer';
import { ControlDataset } from '../../../models';

export const findControlDataset = (
  state: ControlDatasetStateSlice,
  siteId: number,
  date: Date
): ControlDataset | undefined => {
  return state.entities.controlDataset.bySiteId?.[siteId]?.[date.toISOString()];
};
