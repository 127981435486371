import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  IconButton,
  Chip,
  Theme,
  makeStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import FilterIcon from '@material-ui/icons/FilterList';
import { useFormik } from 'formik';
import { SecondaryActionButton } from '../../ui/Buttons';
import { MultipleSelect } from '../../ui/controls/MultipleSelect';
import { getFormikInputProps } from '../../../helpers/form';
import { pick } from 'lodash';
import {
  PortfolioFilterValues,
  PortfolioFilter,
  FilterOption,
  FilterLabels,
} from '../../../state/contexts/portfolioPage';
import { AlarmStatus } from '../../../models';

export interface SiteFilterProps {
  isAdmin?: boolean;
  hideToggle?: boolean;
  hideChips?: boolean;
  currentFilters: PortfolioFilterValues;
  availableFilters: PortfolioFilter;
  handleFilterChange: (filter: PortfolioFilterValues) => void;
}

const SiteFilter = (props: SiteFilterProps) => {
  const classes = useStyles();
  const { isAdmin, currentFilters, handleFilterChange, availableFilters, hideToggle, hideChips } =
    props;
  const [open, setOpen] = React.useState(false);

  const handleOpenDialog = () => setOpen(true);
  const handleCancel = () => {
    formik.resetForm();
    setOpen(false);
  };

  const handleSubmit = (filter: PortfolioFilterValues) => {
    handleFilterChange(filter);
    handleCancel();
  };

  const formik = useFormik<PortfolioFilterValues>({
    enableReinitialize: true,
    initialValues: currentFilters,
    onSubmit: handleSubmit,
  });

  const { isSubmitting, isValid, dirty } = formik;
  const isSubmitDisabled = isSubmitting || !isValid || !dirty;

  const handleRemoveChip = async (key: keyof PortfolioFilterValues) => {
    await formik.setFieldValue(key, []);
    formik.submitForm();
  };

  const getListOfActiveFilterLabels = (key: string) => {
    /* TODO: Typing here is a mess */
    const currentValues = currentFilters[key] as Array<string | number | AlarmStatus>;
    const availableValues =
      (availableFilters[key] as Array<
        FilterOption<string> | FilterOption<number> | FilterOption<AlarmStatus>
      >) || [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return availableValues.filter((f) => currentValues.includes(f.value as any)).map((f) => f.name);
  };

  return (
    <>
      {!hideToggle && (
        <SecondaryActionButton
          classes={{
            root: classes.button,
          }}
          onClick={handleOpenDialog}
          endIcon={<FilterIcon />}
        >
          Filter
        </SecondaryActionButton>
      )}
      {!hideChips &&
        (Object.keys(currentFilters) as (keyof typeof currentFilters)[]).map((key) => {
          if (currentFilters[key].length === 0) {
            return null;
          }
          return (
            <Chip
              key={key}
              classes={{
                root: classes.chip,
              }}
              size="small"
              label={`${FilterLabels[key]}: ${getListOfActiveFilterLabels(key).join(', ')}`}
              onDelete={() => handleRemoveChip(key)}
              onClick={handleOpenDialog}
            />
          );
        })}

      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle>Filters</DialogTitle>
        <DialogContent style={{ minHeight: 250 }}>
          <Grid container direction="column" spacing={2}>
            {Object.keys(FilterLabels).map((key) => {
              if (!isAdmin && key === 'customers') {
                return null;
              }
              return (
                <Grid key={key} item container alignItems="center" spacing={1}>
                  <Grid item style={{ flex: 1 }}>
                    {/*
                    // @ts-ignore todo: fix MultipleSelect props type */}
                    <MultipleSelect
                      fullWidth
                      variant="outlined"
                      classes={{ selectMenu: classes.selectMenu }}
                      options={availableFilters[key]}
                      label={FilterLabels[key]}
                      id={FilterLabels[key].replace(' ', '').toLowerCase()}
                      value={formik.values[key]}
                      {...pick(getFormikInputProps(formik, key, 'select'), 'onChange')}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      data-testid={`clear-${key}-filter`}
                      size="small"
                      style={formik.values[key].length > 0 ? {} : { visibility: 'hidden' }}
                      onClick={() => formik.setFieldValue(key, [])}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={() => formik.submitForm()} disabled={isSubmitDisabled}>
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  chip: {
    margin: theme.spacing(0.1, 0.2),
    maxWidth: 'calc(100% - 8px)',
  },
  button: {
    backgroundColor: '#FFF',
    height: 40,
  },
  selectMenu: {
    whiteSpace: 'normal',
  },
}));

export default SiteFilter;
