import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as installerActions from './actions';
import { apiSagaV2 } from '../../../helpers/saga';
import { InstallerApi } from '../../../api';

const fetchSiteTemplates = apiSagaV2(
  installerActions.fetchSiteTemplates,
  async (connection, action) => {
    return new InstallerApi(connection).fetchSiteTemplates(action.payload.search);
  }
);

const fetchUtilities = apiSagaV2(installerActions.fetchUtilities, async (connection, action) => {
  return new InstallerApi(connection).fetchUtilities(action.payload.postalCode);
});

const provisionSite = apiSagaV2(installerActions.provisionSite, async (connection, action) => {
  return new InstallerApi(connection).provisionSite(action.payload.formData);
});

export function* installerSagas() {
  yield takeEvery(getType(installerActions.fetchSiteTemplates.request), fetchSiteTemplates);
  yield takeEvery(getType(installerActions.fetchUtilities.request), fetchUtilities);
  yield takeEvery(getType(installerActions.provisionSite.request), provisionSite);
}
