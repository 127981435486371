import React from 'react';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import identifiers from '../../../tests/identifiers';
import { isApiError } from '../../../api/util/ApiError';
import { useParamSelector, useRequest } from '../../../helpers/hooks';
import { fetchUtilities } from '../../../state/entities/installer/actions';
import { getUtilities } from '../../../state/entities/installer/selectors';
import { Lifecycle } from '../../../state/request';
import LoadWithSpinner from '../../ui/Loading/LoadWithSpinner';
import { Utility } from '../../../models';

export interface UtilityRateLoaderProps {
  postalCode: string;
  errorDismissalAction: React.ReactNode;
  children: (props: { utilities: Utility[] }) => React.ReactElement | null;
}

export const UtilityRateLoader = ({
  children,
  postalCode,
  errorDismissalAction,
}: UtilityRateLoaderProps) => {
  const utilities = useParamSelector(getUtilities)(postalCode);
  const rateScheduleCount = utilities.reduce(
    (count, currentUtility) => count + currentUtility.rateSchedules.length,
    0
  );

  const [, utilitiesStatus = Lifecycle.PENDING] = useRequest(fetchUtilities.request(postalCode));

  if (utilitiesStatus === Lifecycle.PENDING) {
    return (
      <Box data-testid={identifiers.utilityRate.utilitiesLoading} padding={3}>
        <LoadWithSpinner
          message={
            <>
              Loading utilities and rate schedules for <strong>{postalCode}</strong>
            </>
          }
        />
      </Box>
    );
  }

  if (isApiError(utilitiesStatus)) {
    return (
      <Alert
        action={errorDismissalAction}
        data-testid={identifiers.utilityRate.utilityError}
        severity="error"
      >
        Error loading utility and rate schedule data.
      </Alert>
    );
  }

  if (rateScheduleCount === 0) {
    return (
      <Alert
        action={errorDismissalAction}
        data-testid={identifiers.utilityRate.noRateSchedules}
        severity="error"
      >
        No rate schedules available for this location.
      </Alert>
    );
  }

  return children({ utilities });
};
