import { combineReducers } from 'redux';
import { SiteContextState, siteContextReducer } from './site';
import {
  authContextReducer,
  AuthContextState,
  pushContextReducer,
  PushContextState,
} from './auth/reducer';
import { appContextReducer, AppContextState } from './app';
import { alarmsPageReducer, AlarmsPageState } from './alarmsPage';
import { analysisPageContextReducer, AnalysisPageContext } from './analysisPage';
import { PortfolioPageState, portfolioPageReducer } from './portfolioPage';
import { UtilityRateContext, utilityRateRequestReducer } from './utilityRate';

export interface ContextRootState {
  app: AppContextState;
  alarmsPage: AlarmsPageState;
  analysisPage: AnalysisPageContext;
  portfolioPage: PortfolioPageState;
  auth: AuthContextState;
  pushFeed: PushContextState;
  site: SiteContextState;
  utilityRate: UtilityRateContext;
}

export const contextReducer = combineReducers<ContextRootState>({
  app: appContextReducer,
  alarmsPage: alarmsPageReducer,
  analysisPage: analysisPageContextReducer,
  auth: authContextReducer,
  pushFeed: pushContextReducer,
  portfolioPage: portfolioPageReducer,
  site: siteContextReducer,
  utilityRate: utilityRateRequestReducer,
});
