import { RootState } from '../';
import { getType, createAction, ActionType } from 'typesafe-actions';
import { loadState, saveState, deleteState } from '../../helpers/localStorage';
import { SDate } from '../../helpers/SettableDate';
import { takeEvery, select } from 'redux-saga/effects';

const LOCAL_STORAGE_KEY = 'app-state';

/* ----- actions ----- */

export const closeNav = createAction('context/app/closeNav', (resolve) => () => resolve());

export const toggleNav = createAction('context/app/toggleNav', (resolve) => () => resolve());

export const setTimeTravel = createAction(
  'context/app/setTimeTravel',
  (resolve) => (date: Date | undefined) => resolve({ date })
);

/* ----- selectors ----- */

export const getIsNavOpen = (rootState: RootState) => {
  return rootState.contexts.app.isNavOpen;
};

/* ----- reducer ----- */

export interface AppContextState {
  isNavOpen: boolean;
}

const initialState = {
  isNavOpen: true,
  ...loadState<AppContextState>(LOCAL_STORAGE_KEY),
};

export const ttDate = loadState<{ date: string | undefined }>(LOCAL_STORAGE_KEY + '/date');
SDate.setCurrentDate(ttDate?.date ? new Date(ttDate.date) : new Date());

type AppContextActions = ActionType<typeof closeNav | typeof toggleNav | typeof setTimeTravel>;

export const appContextReducer = (
  state: AppContextState = initialState,
  action: AppContextActions
): AppContextState => {
  switch (action.type) {
    case getType(closeNav):
      return {
        ...state,
        isNavOpen: false,
      };
    case getType(toggleNav):
      return {
        ...state,
        isNavOpen: !state.isNavOpen,
      };
    default:
      return state;
  }
};

/* ----- saga ----- */

function* handleCloseNav(_action: ActionType<typeof closeNav>) {
  const currentState: AppContextState = yield select((state: RootState) => state.contexts.app);
  saveState(LOCAL_STORAGE_KEY, currentState);
}

function* handleToggleNav(_action: ActionType<typeof toggleNav>) {
  const currentState: AppContextState = yield select((state: RootState) => state.contexts.app);
  saveState(LOCAL_STORAGE_KEY, currentState);
}

function handleSetTimeTravel(action: ActionType<typeof setTimeTravel>) {
  const { date } = action.payload;

  if (date === undefined) {
    deleteState(LOCAL_STORAGE_KEY + '/date');
  } else {
    saveState(LOCAL_STORAGE_KEY + '/date', { date: date.toString() });
  }
  window.location.reload();
}

export function* appContextSagas() {
  yield takeEvery(getType(closeNav), handleCloseNav);
  yield takeEvery(getType(toggleNav), handleToggleNav);
  yield takeEvery(getType(setTimeTravel), handleSetTimeTravel);
}
