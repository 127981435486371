import { NotificationApi } from '../NotificationApi';
import { sleep } from '../../helpers/async';
import { Notification } from '../../models';
import { SubscriptionBase } from '../util/SubscriptionBase';
import { ApiConnection } from '../util/ApiConnection';

export class NotificationSubscription extends SubscriptionBase<Notification[]> {
  private apiAccess?: NotificationApi;

  async *getGenerator(siteId: number): AsyncGenerator<Notification[]> {
    if (!this.apiAccess) return;

    // We first request snapshots from start up to now
    let notifications = await this.apiAccess.fetchNotifications(siteId);
    yield notifications;
    while (!this.stopped) {
      await sleep(this.interval);
      notifications = await this.apiAccess.fetchNotifications(siteId);
      yield notifications;
    }
  }

  start(siteId: number) {
    this.apiAccess = new NotificationApi(this.connection);
    this.generator = this.getGenerator(siteId);
  }

  updateConnection(connection: ApiConnection) {
    this.setConnection(connection);
    this.apiAccess = new NotificationApi(this.connection);
  }
}
