import React from 'react';
import { getSiteContext } from '../../../state/contexts/site';
import { useSelector } from 'react-redux';
import { subSnapshots, getNumericDataSet, findTagLookupBySite } from '../../../state/entities';
import DashboardPage from './DashboardPage';
import { useParamSelector, useSubscription } from '../../../helpers/hooks';
import { getPowerflowTags, getMetricTags, buildMetrics, buildFlows } from './config';
import { SDate } from '../../../helpers/SettableDate';

const FOUR_HOURS = 1000 * 60 * 60 * 4;

export const DashboardPageLoader: React.FC<{}> = () => {
  const site = useSelector(getSiteContext);

  const tags = useParamSelector(findTagLookupBySite)(site.id);

  const powerflowTags = getPowerflowTags(site, tags);
  const metricTags = getMetricTags(site, tags);

  const tagIds = [...powerflowTags, ...metricTags].map((t) => t.id);

  const pointDataStatus = useSubscription(true, subSnapshots, site.id, tagIds, FOUR_HOURS);
  const minus4Hours = new Date(SDate.now() - FOUR_HOURS);
  const now = new Date(SDate.now());
  const latestSnapshots = useParamSelector(getNumericDataSet)(tagIds, minus4Hours, now);

  const flows = buildFlows(site, powerflowTags, latestSnapshots);
  const metrics = buildMetrics(site, latestSnapshots);

  return <DashboardPage metrics={metrics} flows={flows} dataStatus={pointDataStatus} />;
};
