import React from 'react';
import { subNotifications } from '../../../state/entities';
import { useAction, useParamSelector } from '../../../helpers/hooks';
import { findNotificationsBySite } from '../../../state/entities';
import { useEffect } from 'react';
import { NotificationWidget } from './NotificationWidget';

export interface NotificationWidgetContainerProps {
  siteId: number;
}

export const NotificationWidgetContainer: React.FC<NotificationWidgetContainerProps> = (props) => {
  const { siteId } = props;

  const startSubscription = useAction(subNotifications.start);
  const stopSubscription = useAction(subNotifications.stop);

  useEffect(() => {
    const startAction = startSubscription(siteId);
    return () => {
      stopSubscription(startAction);
    };
  }, [siteId, startSubscription, stopSubscription]);

  const notifications = useParamSelector(findNotificationsBySite)(siteId);

  return <NotificationWidget notifications={notifications} />;
};
