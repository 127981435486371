import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ismVoult } from '../../config';
import mvoultLogo from '../../assets/mvoult-logo.png';
import mvoultLogoDark from '../../assets/mvoult-logo-dark.png';

interface Props {
  customerId: number;
  isDark: boolean;
  isTitleBar: boolean;
  link?: string;
}
const useStyles = makeStyles((theme) => ({
  logo: {
    width: '100%',
    height: 'auto',
    objectFit: 'scale-down',
    display: 'block',
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  logoTitleBar: {
    display: 'block',
    maxWidth: '240px',
    maxHeight: '64px',
    width: 'auto',
    height: 'auto',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
  },
}));

const SiteLogo = (props: Props) => {
  const { customerId, isDark, isTitleBar } = props;
  const classes = useStyles();
  const mainLogo = ismVoult ? mvoultLogo : `/logos/logo${customerId}.png`;
  const mainLogoDark = ismVoult ? mvoultLogoDark : `/logos/logo${customerId}-dark.png`;
  const logoSrc = isDark ? mainLogoDark : mainLogo;

  const renderImage = () => {
    return (
      <img
        alt="Company Logo"
        className={isTitleBar ? classes.logoTitleBar : classes.logo}
        src={logoSrc}
      />
    );
  };

  return props.link ? <Link to={props.link}>{renderImage()}</Link> : renderImage();
};

SiteLogo.defaultProps = { isDark: false, isTitleBar: false };

export default SiteLogo;
