import { getType, ActionType } from 'typesafe-actions';
import * as notificationActions from './actions';
import { Notification } from '../../../models';

import { produce } from 'immer';

export interface NotificationEntityState {
  bySiteId: {
    [Key: string]: Notification[];
  };
}

export interface NotificationStateSlice {
  entities: {
    notification: NotificationEntityState;
  };
}

const initialState = {
  bySiteId: {},
};

type NotificationActions = ActionType<typeof notificationActions>;

export const notificationEntityReducer = produce(
  (draft: NotificationEntityState, action: NotificationActions) => {
    switch (action.type) {
      case getType(notificationActions.fetchNotifications.success):
      case getType(notificationActions.subNotifications.update): {
        const { triggerAction, result: notifications } = action.payload;
        const { siteId } = triggerAction.payload;
        draft.bySiteId[siteId] = notifications;
        break;
      }
      default:
        break;
    }
  },
  initialState
);
