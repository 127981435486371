import * as d3 from 'd3';
import { ScreenSize, TimeRange } from './types';
import { useRef, useEffect } from 'react';
import { useCurrent } from '../../../helpers/hooks';
import { throttle } from 'lodash';

interface Props {
  size: ScreenSize;
  xScale: d3.ScaleTime<number, number>;
  zoomRange?: TimeRange;
  onZoom?: (zoomRange: TimeRange) => void;
  onZoomEnd?: (zoomRange: TimeRange) => void;
}

const minZoomDuration = 5000;

const enforceMin = (range: TimeRange) => {
  const diff = range.end.getTime() - range.start.getTime();
  if (Math.abs(diff) < minZoomDuration) {
    return {
      start: range.start,
      end: new Date(range.start.getTime() + minZoomDuration),
    };
  } else {
    return range;
  }
};

export const ZoomBar = (props: Props) => {
  const { zoomRange, size } = props;

  const xScale = useCurrent(props.xScale);
  const onZoom = useCurrent(props.onZoom);
  const onZoomEnd = useCurrent(props.onZoomEnd);

  const groupRef = useRef<SVGGElement>(null);
  const brushRef = useRef<SVGGElement>(null);
  const brushInstRef = useRef<d3.BrushBehavior<number>>();
  const isBrushingRef = useRef<boolean>(false);

  if (!brushInstRef.current && groupRef.current) {
    const brush = d3.brushX<number>().extent([
      [0, 0],
      [size.width, size.height],
    ]);
    const g = d3.select(groupRef.current);

    brush.on('brush', function () {
      // If brush is caused by brush.move event will be undefined
      // and we will do nothing.
      if (!d3.event || !d3.event.sourceEvent) {
        return;
      }
      isBrushingRef.current = true;
      const selection = d3.brushSelection(this);

      if (
        selection &&
        typeof selection[0] === 'number' &&
        typeof selection[1] === 'number' &&
        onZoom.current
      ) {
        const throttledZoom = throttle(onZoom.current, 50);
        throttledZoom(
          enforceMin({
            start: xScale.current.invert(selection[0]),
            end: xScale.current.invert(selection[1]),
          })
        );
      }
    });

    brush.on('end', function () {
      const selection = d3.brushSelection(this);
      isBrushingRef.current = false;
      if (
        selection &&
        typeof selection[0] === 'number' &&
        typeof selection[1] === 'number' &&
        onZoomEnd.current
      ) {
        onZoomEnd.current(
          enforceMin({
            start: xScale.current.invert(selection[0]),
            end: xScale.current.invert(selection[1]),
          })
        );
        // @ts-ignore todo: fix type
        d3.select(this).call(brush.move, null);
      }
    });
    brushInstRef.current = brush;
    // @ts-ignore todo: fix type
    g.selectAll('.brush').call(brush);
  }

  // We want to set the posistion of the zoom rect manually if we are not
  // brushing and the component size changes or the zoomRange is updated
  // externaly.
  useEffect(() => {
    if (groupRef.current && brushInstRef.current && brushRef.current && !isBrushingRef.current) {
      const brushEl = brushRef.current;
      const brush = brushInstRef.current;
      brush.extent([
        [0, 0],
        [size.width, size.height],
      ]);
      // @ts-ignore todo: fix type
      d3.select(groupRef.current).selectAll('.brush').call(brush);
      if (zoomRange) {
        const newSelection = [xScale.current(zoomRange.start), xScale.current(zoomRange.end)];
        // @ts-ignore todo: fix type
        d3.select(brushEl).call(brush.move, newSelection);
      } else {
        // @ts-ignore todo: fix type
        d3.select(brushEl).call(brush.move, null);
      }
    }
  }, [zoomRange, size, xScale]);

  return (
    <g ref={groupRef}>
      <g className="brush" ref={brushRef} />
    </g>
  );
};
