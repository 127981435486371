import { combineReducers } from 'redux';
import { UserEntityState, userEntityReducer } from './user/reducer';
import { AlertEntityState, alertEntityReducer } from './alert/reducer';
import { ReportEntityState, reportEntityReducer } from './report/reducer';
import {
  AlarmDetailEntityState,
  AlarmSummaryEntityState,
  alarmDetailsEntityReducer,
  alarmSummaryEntityReducer,
} from './alarm/reducer';
import { controlDatasetEntityReducer, ControlDatasetEntityState } from './controlDataset/reducer';
import { CustomerEntityState, customerEntityReducer } from './customer/reducer';
import { NodeEntityState, nodeEntityReducer } from './node/reducer';
import { NotificationEntityState, notificationEntityReducer } from './notification/reducer';
import { EquipmentEntityState, equipmentEntityReducer } from './equipment/reducer';
import { InstallerEntityState, installerEntityReducer } from './installer/reducer';
import { SiteEntityState, siteEntityReducer } from './site/reducer';
import { SnapshotEntityState, snapshotEntityReducer } from './snapshot/reducer';
import { TagEntityState, tagEntityReducer } from './tag/reducer';
import { UserProfile } from '../../models';
import { profileEntityReducer } from './profile/reducer';

export type EntitiesRootState = {
  alarmDetails: AlarmDetailEntityState;
  alarmSummary: AlarmSummaryEntityState;
  alert: AlertEntityState;
  controlDataset: ControlDatasetEntityState;
  customer: CustomerEntityState;
  equipment: EquipmentEntityState;
  installer: InstallerEntityState;
  node: NodeEntityState;
  notification: NotificationEntityState;
  report: ReportEntityState;
  site: SiteEntityState;
  snapshot: SnapshotEntityState;
  tag: TagEntityState;
  user: UserEntityState;
  userProfile: UserProfile | null;
};

export interface EntitiesStateSlice {
  entities: EntitiesRootState;
}

export const toRootState = (entitiesSlice: EntitiesRootState) => ({
  entities: entitiesSlice,
});

export const entitiesReducer = combineReducers<EntitiesRootState>({
  alarmDetails: alarmDetailsEntityReducer,
  alarmSummary: alarmSummaryEntityReducer,
  alert: alertEntityReducer,
  controlDataset: controlDatasetEntityReducer,
  customer: customerEntityReducer,
  equipment: equipmentEntityReducer,
  installer: installerEntityReducer,
  node: nodeEntityReducer,
  notification: notificationEntityReducer,
  report: reportEntityReducer,
  // @ts-ignore todo: fix type
  site: siteEntityReducer,
  snapshot: snapshotEntityReducer,
  tag: tagEntityReducer,
  user: userEntityReducer,
  userProfile: profileEntityReducer,
});

export * from './alarm/actions';
export * from './alarm/selectors';

export * from './alert/actions';
export * from './alert/selectors';
export type { AlertNorm } from './alert/reducer';

export * from './controlDataset/actions';
export * from './controlDataset/selectors';

export * from './customer/actions';
export * from './customer/selectors';

export * from './equipment/selectors';

export * from './node/selectors';

export * from './notification/actions';
export * from './notification/selectors';

export * from './profile/actions';
export * from './profile/selectors';

export * from './report/actions';
export * from './report/selectors';

export * from './site/actions';
export * from './site/selectors';

export * from './snapshot/actions';
export * from './snapshot/selectors';

export * from './tag/selectors';

export * from './user/actions';
export * from './user/selectors';
