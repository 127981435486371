import { ActionsOf } from '../../../helpers/actions';
import { getType } from 'typesafe-actions';
import { fetchCustomer } from './actions';
import { createUser, deleteUser } from '../user/actions';
import { Customer } from '../../../models';
import { produce } from 'immer';
import { Normalized } from '../../../helpers/norm';
import { without } from 'lodash';

export interface CustomerNorm extends Normalized<Customer, 'users', { userIds: number[] }> {}

export interface CustomerEntityState {
  byId: {
    [Key: string]: CustomerNorm;
  };
}

export interface UserStateSlice {
  entities: {
    customer: CustomerEntityState;
  };
}

const initialState = {
  byId: {},
};

type UserActions = ActionsOf<typeof fetchCustomer | typeof createUser | typeof deleteUser>;

export const customerEntityReducer = produce((draft: CustomerEntityState, action: UserActions) => {
  switch (action.type) {
    case getType(fetchCustomer.success): {
      const { result: customer } = action.payload;
      const { users, ...restOfCustomer } = customer;
      draft.byId[customer.customerId] = {
        ...restOfCustomer,
        userIds: users.map((user) => user.userId),
      };
      break;
    }

    case getType(createUser.success): {
      const { userId, customerId } = action.payload.result;

      // we are not going to update the customer if it doesn't exist
      if (draft.byId[customerId]) {
        draft.byId[customerId].userIds.push(userId);
      }
      break;
    }

    case getType(deleteUser.success): {
      const { userId, customerId } = action.payload.triggerAction.payload.user;

      // we are not going to update the customer if it doesn't exist
      const customer = draft.byId[customerId];
      if (customer) {
        customer.userIds = without(customer.userIds, userId);
      }
      break;
    }

    default:
      break;
  }
}, initialState);
