import { withData } from '../../utility/withData';
import UserPageContainer from './UserPageContainer';
import { fetchCustomer } from '../../../state/entities';
import { isComplete, isError, Lifecycle } from '../../../state/request';
import { getSiteContext } from '../../../state/contexts/site';
import { RequestStatus } from '../../utility/RequestStatus';
import { getProfileContext } from '../../../state/contexts/auth/selectors';

export const UserPageLoader = withData<{}>(
  (rootState) => {
    const { isSystemAdmin } = getProfileContext(rootState);
    const { customerId } = getSiteContext(rootState);
    return [fetchCustomer.request(customerId, isSystemAdmin)];
  },
  {
    isLoaded: (rootState) => {
      const { customerId } = getSiteContext(rootState);
      const { isSystemAdmin } = getProfileContext(rootState);
      return isComplete(rootState, fetchCustomer.request(customerId, isSystemAdmin));
    },
    renderLoader: () => <RequestStatus status={Lifecycle.PENDING} />,
    isError: (rootState) => {
      const { customerId } = getSiteContext(rootState);
      return isError(rootState, fetchCustomer.request(customerId));
    },
    renderError: () => <div>Not Found</div>,
  }
)(UserPageContainer);
