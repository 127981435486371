import { select, put, take, race } from 'redux-saga/effects';
import { RootState } from '../state';
import { ApiActionTriad, ApiActionMeta, SubActionSet } from './actions';
import { PayloadMetaAction } from 'typesafe-actions/src/types';
import logger from '../helpers/logger';
import { pushHub as appPushHub } from './PushHub';
import { ApiConnection, ApiError } from '../api';
import { ApiClient } from '../api/util/ApiClient';
import { AnyAction } from 'redux';
import { getType } from 'typesafe-actions';
import { publishToken } from '../state/contexts/auth/actions';

export const getAccessToken = (state: RootState) => state.contexts.auth.accessToken!;
export const getPushConnectionId = (state: RootState) => state.contexts.pushFeed.connectionId;

export const getApiConnection = (state: RootState) => {
  const accessToken = getAccessToken(state);
  const connectionId = getPushConnectionId(state);
  if (!connectionId) {
    throw new Error('No connection to push hub found');
  }
  const client = new ApiClient(accessToken);
  return new ApiConnection(client, appPushHub, connectionId);
};

/**
 * Helper to create a saga to connect an api call to a request action triad.
 *
 * The request callback should be a function that takes an ApiConnection, and
 * a request action, and returns the result of the api call.
 *
 * If the request callback throws an error the error action will be
 * dispatched, otherwise the success action will be dispatched with the result.
 *
 */
export const apiSagaV2 = <
  Type1 extends string,
  Type2 extends string,
  Type3 extends string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PArgs extends any[],
  Payload,
  Result
>(
  triad: ApiActionTriad<Type1, Type2, Type3, PArgs, Payload, Result>,
  request: (
    connection: ApiConnection,
    action: PayloadMetaAction<Type1, Payload, ApiActionMeta>
  ) => Promise<Result>
) => {
  function* saga(action: PayloadMetaAction<Type1, Payload, ApiActionMeta>) {
    try {
      const connection: ApiConnection = yield select(getApiConnection);
      const start = Date.now();
      const requestCaller = () => request(connection, action);
      const result: Result = yield requestCaller();
      const end = Date.now();
      // TODO: move this into action creator
      const updatedAction = {
        ...action,
        meta: {
          ...action.meta,
          time: end - start,
        },
      };
      yield put(triad.success(updatedAction, result));
    } catch (e) {
      logger.error(e);
      yield put(triad.error(action, e as ApiError));
    }
  }
  return saga;
};

interface Subscription<Result> {
  stop: () => void;
  next: () => Promise<Result>;
  updateConnection: (connection: ApiConnection) => void;
}

export const subSaga = <
  Type1 extends string,
  Type2 extends string,
  Type3 extends string,
  Type4 extends string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PArgs extends any[],
  Payload,
  Result
>(
  actionSet: SubActionSet<Type1, Type2, Type3, Type4, PArgs, Payload, Result>,
  request: (
    connection: ApiConnection,
    action: PayloadMetaAction<Type1, Payload, ApiActionMeta>
  ) => Subscription<Result>
) => {
  function* saga(action: PayloadMetaAction<Type1, Payload, ApiActionMeta>) {
    try {
      const connection: ApiConnection = yield select(getApiConnection);
      const subscription = request(connection, action);
      while (true) {
        const { result, stop, updateConnection } = yield race({
          result: subscription.next(),
          stop: take((stopAction: AnyAction) => {
            return (
              stopAction.type === getType(actionSet.stop) &&
              stopAction.payload.triggerAction.meta.uid === action.meta.uid
            );
          }),
          updateConnection: take(getType(publishToken)),
        });
        if (result) {
          yield put(actionSet.update(action, result));
        } else if (stop) {
          return;
        } else if (updateConnection) {
          const newConnection: ApiConnection = yield select(getApiConnection);
          subscription.updateConnection(newConnection);
        }
      }
    } catch (e) {
      logger.error(e);
      yield put(actionSet.error(action, e as ApiError));
    }
  }
  return saga;
};
