import { api as apiConfig } from '../config';
import axios, { AxiosInstance } from 'axios';

export class AccountApi {
  static baseUrl = '/User';

  private client: AxiosInstance;

  public constructor() {
    const apiVersion = apiConfig.version || '1.0';
    this.client = axios.create({
      baseURL: `${apiConfig.root.toString()}/v${apiVersion}`,
      timeout: apiConfig.httpTimeoutDefault,
    });
  }

  /**
   * Returns url where user should be redirected to in order to activate their
   * account
   */
  public async activateAccount(emailAddress: string, registrationCode: string): Promise<string> {
    const body = { emailAddress, registrationCode };
    return this.client.post<typeof body, string>(`${AccountApi.baseUrl}/activate`, body);
  }
}
