import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, makeStyles, Toolbar, useTheme } from '@material-ui/core';
import { PageTitle } from '../main/PageTitle';
import { HelpWidget } from '../main/HelpWidget';
import { UserMenu } from '../main/UserMenu';
import { UserProfile } from '../../../models';
import SiteLogo from '../../ui/SiteLogo';

export interface PortfolioToolbarProps {
  profile: UserProfile;
}

export const PortfolioToolbar = ({ profile }: PortfolioToolbarProps) => {
  const classes = useStyles();
  const { logout } = useAuth0();

  return (
    <Toolbar className={classes.root}>
      <div className={classes.logoBg}>
        <SiteLogo customerId={profile.customerId} isTitleBar={true} />
      </div>

      <PageTitle siteName="Portfolio" />

      <IconBorder>
        <HelpWidget />
      </IconBorder>

      <IconBorder>
        {/* eslint-disable-next-line no-restricted-globals */}
        <UserMenu logout={() => logout({ returnTo: `${location.origin}` })} />
      </IconBorder>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingRight: 0,
    backgroundColor: '#FFF',
    paddingLeft: 288,
  },
  logoBg: {
    backgroundColor: theme.sidebar.main,
    height: '64px',
    width: '256px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: '0',
    paddingLeft: '12px',
    zIndex: 100,
    '&:before': {
      zIndex: -1,
      transform: 'rotateZ(15deg)',
      position: 'absolute',
      content: '""',
      display: 'block',
      right: -11,
      bottom: 5,
      height: 77,
      width: 48,
      backgroundColor: theme.sidebar.main,
    },
  },
}));

const IconBorder: React.FC = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      borderLeft={`1px solid ${theme.palette.divider}`}
      display="flex"
      paddingLeft={1}
      paddingRight={1}
    >
      <div>{children}</div>
    </Box>
  );
};
