import React from 'react';
import { fetchSiteData } from '../../state/entities';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveSite, switchSite } from '../../state/contexts/site';
import { isComplete, isPending, getError } from '../../state/request';
import LoadingWithSpinner from '../ui/Loading/LoadWithSpinner';
import { ErrorPage } from '../modules/main/ErrorPage';
import { LoadingPage } from '../modules/main/LoadingPage';
import { useParamSelector } from '../../helpers/hooks';

export interface SiteRouteMatch {
  siteId: string;
}

export const SiteContext: React.FC<{}> = (props) => {
  const match = useRouteMatch<SiteRouteMatch>();
  const siteId = parseInt(match.params.siteId, 10);
  const activeSite = useSelector(getActiveSite);
  const dispatch = useDispatch();
  const requestActionType = fetchSiteData.request(siteId);
  const requestIsComplete = useParamSelector(isComplete)(requestActionType);
  const requestIsPending = useParamSelector(isPending)(requestActionType);
  const requestError = useParamSelector(getError)(requestActionType);

  React.useEffect(() => {
    if (siteId !== activeSite) {
      dispatch(switchSite(siteId));
      dispatch(fetchSiteData.request(siteId));
    }
  }, [dispatch, siteId, activeSite]);

  if (requestIsComplete) {
    return <>{props.children}</>;
  } else if (requestIsPending) {
    return (
      <LoadingPage>
        <LoadingWithSpinner message="Loading Site..." />
      </LoadingPage>
    );
  } else if (requestError) {
    return <ErrorPage message={requestError.message} />;
  } else {
    return null;
  }
};
