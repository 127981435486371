import {
  withStyles,
  TableSortLabel as BaseTableSortLabel,
  TableCell as BaseTableCell,
} from '@material-ui/core';

export const TableSortLabel = withStyles({
  root: {
    color: '#004A86',
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 400,
  },
  active: {
    color: '#004A86',
    fontWeight: 500,
  },
})(BaseTableSortLabel);

export const TableCellTight = withStyles({
  root: {
    padding: '8px 8px',
  },
})(BaseTableCell);
