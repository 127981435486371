import React from 'react';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Notification } from '../../../models';
import {
  IconButton,
  Badge,
  Menu,
  ListItem,
  Typography,
  ListSubheader,
  Button,
  makeStyles,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Link } from 'react-router-dom';
import { PathBuilder } from '../../../routing';

export interface NotificationWidgetProps {
  notifications: Notification[];
}

const useBadgeStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

const useNotificationStyles = makeStyles(() => ({
  root: {
    color: '#B2B2B2',
  },
}));

export const NotificationWidget: React.FC<NotificationWidgetProps> = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { notifications } = props;
  const badgeClasses = useBadgeStyles();
  const notificationClasses = useNotificationStyles();
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <>
          <Tooltip title="Notifications">
            <IconButton
              color="inherit"
              {...bindTrigger(popupState)}
              size={isSmallScreen ? 'small' : 'medium'}
            >
              {notifications.length > 0 ? (
                <Badge badgeContent={notifications.length} classes={badgeClasses}>
                  <NotificationsIcon classes={notificationClasses} />
                </Badge>
              ) : (
                <NotificationsIcon classes={notificationClasses} />
              )}
            </IconButton>
          </Tooltip>

          {notifications.length > 0 ? (
            <Menu {...bindMenu(popupState)}>
              <ListSubheader style={{ backgroundColor: '#fff', borderBottom: '1px solid #ddd' }}>
                Notifications
              </ListSubheader>
              {notifications.map((notification) => (
                <ListItem
                  divider={true}
                  key={`${notification.message}:${notification.action.label}`}
                  style={{ display: 'block', maxWidth: 320 }}
                >
                  <div>
                    <Typography style={{ paddingLeft: 0 }}>{notification.message}</Typography>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    {notification.action && notification.action.label && (
                      <Button
                        style={{ marginRight: -8, marginBottom: -2, fontSize: 12 }}
                        variant="text"
                        size="small"
                        color="primary"
                        component={Link}
                        to={PathBuilder.PROTECTED() + notification.action.url}
                        onClick={popupState.close}
                      >
                        {notification.action.label}
                      </Button>
                    )}
                  </div>
                </ListItem>
              ))}
            </Menu>
          ) : (
            <Menu {...bindMenu(popupState)}>
              <ListSubheader>No Notifications</ListSubheader>
            </Menu>
          )}
        </>
      )}
    </PopupState>
  );
};
