import { RootState } from '../';
import { ActionsOf } from '../../helpers/actions';
import { getType, createAction } from 'typesafe-actions';
import URI from 'urijs';
import { without, keyBy } from 'lodash';
import { produce } from 'immer';
import { takeEvery, select } from 'redux-saga/effects';
import { PathBuilder } from '../../routing';
import { getHistory } from '../../state/history';
import { getSiteContext } from './site';
import { Site, getSiteTags, isChartableTag } from '../../models';
import { isValidDate } from '../../helpers/common';

export interface AnalysisPageContext {
  start?: Date;
  end?: Date;
  tagIds: string[];
}

/* ----- actions ----- */

export const toggleTagId = createAction(
  'context/analysisPage/toggleTagId',
  (resolve) => (tagId: string) => resolve({ tagId })
);

export const clearAllTags = createAction(
  'context/analysisPage/clearAllTags',
  (resolve) => () => resolve()
);

export const setStartDate = createAction(
  'context/analysisPage/setStartDate',
  (resolve) => (start: Date) => resolve({ start })
);

export const setEndDate = createAction(
  'context/analysisPage/setEndDeate',
  (resolve) => (end: Date) => resolve({ end })
);

export const setContext = createAction(
  'context/analysisPage/setContext',
  (resolve) => (context: AnalysisPageContext) => resolve({ context })
);

export const analysisPageContextActions = {
  toggleTagId,
  clearAllTags,
  setStartDate,
  setEndDate,
  setContext,
};

export const parseAnalysisRouteContext = (_path: string, search: string): AnalysisPageContext => {
  const params = URI.parseQuery(search);
  const context: AnalysisPageContext = { tagIds: [] };
  if (params.startTS && typeof params.startTS === 'string') {
    context.start = new Date(params.startTS);
  }
  if (params.endTS && typeof params.endTS === 'string') {
    context.end = new Date(params.endTS);
  }
  if (params.tagIds && params.tagIds instanceof Array) {
    context.tagIds = params.tagIds.filter((t) => t !== null) as string[];
  } else if (params.tagIds && typeof params.tagIds === 'string') {
    context.tagIds = [params.tagIds];
  }

  return {
    ...context,
  };
};

const MAX_RANGE = 1000 * 60 * 60 * 24 * 7;
export const validateAnalysisContext = (context: AnalysisPageContext, site: Site) => {
  const { start, end, tagIds } = context;
  if (start && !isValidDate(start)) {
    throw new Error('Invalid start date.');
  }
  if (end && !isValidDate(end)) {
    throw new Error('Invalid end date.');
  }
  if (end && start && start >= end) {
    throw new Error('Invalid date range, start date must be before end date.');
  }
  if (end && start && end.getTime() - start.getTime() > MAX_RANGE) {
    throw new Error('Cannot display more than 7 days of data at once.');
  }

  // Note we allow a little padding on the commissionedOn date so the user can
  // see date from the very beginning without hitting it exactly, if they want
  // to for some reason
  if (
    start &&
    site.commissionedOn &&
    new Date(site.commissionedOn).getTime() > start.getTime() - 1000 * 60 * 60 * 24
  ) {
    throw new Error(
      `Invalid time range, start of available data is ${new Date(
        site.commissionedOn
      ).toLocaleString()}.`
    );
  }
  // Same logic for end time
  if (end && end.getTime() > Date.now() + 1000 * 60 * 60 * 24) {
    throw new Error('Invalid time range, end is past available data.');
  }
  const siteTagLookup = keyBy(getSiteTags(site), (tag) => tag.id);
  tagIds.forEach((tagId) => {
    const foundTag = siteTagLookup[tagId];
    if (!foundTag) {
      throw new Error(`No tag found with id ${tagId} for this site.`);
    }
    if (!isChartableTag(foundTag)) {
      throw new Error(`Tag ${tagId} cannot be visualized.`);
    }
  });
};

/* ----- selectors ----- */

export const getAnalysisPageContext = (rootState: RootState) => {
  return rootState.contexts.analysisPage;
};

type AnalysisPageContextActions = ActionsOf<typeof analysisPageContextActions>;

export const analysisPageContextReducer = produce(
  (draft: AnalysisPageContext, action: AnalysisPageContextActions) => {
    switch (action.type) {
      case getType(toggleTagId):
        const { tagId } = action.payload;
        draft.tagIds = draft.tagIds.includes(tagId)
          ? without(draft.tagIds, tagId)
          : [tagId, ...draft.tagIds];
        break;
      case getType(clearAllTags):
        draft.tagIds = [];
        break;
      case getType(setStartDate):
        const { start } = action.payload;
        draft.start = start;
        break;
      case getType(setEndDate):
        const { end } = action.payload;
        draft.end = end;
        break;
      case getType(setContext):
        const { context } = action.payload;
        return context;
      default:
        break;
    }
    return;
  },
  {
    tagIds: [],
  } as AnalysisPageContext
);

function* handleSetRouteContext() {
  const history = getHistory();
  const site: Site = yield select(getSiteContext);
  const context: AnalysisPageContext = yield select(getAnalysisPageContext);
  if (context.start && context.end && context.tagIds?.length > 0) {
    const url = PathBuilder.ANALYSIS_QUERY(site.id, context.tagIds, context.start, context.end);
    history.push(url);
  }
}

export function* analysisContextSagas() {
  yield takeEvery(getType(setStartDate), handleSetRouteContext);
  yield takeEvery(getType(setEndDate), handleSetRouteContext);
  yield takeEvery(getType(toggleTagId), handleSetRouteContext);
}
