import React from 'react';
import {
  Typography,
  Grid,
  Dialog,
  DialogContent,
  Toolbar,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Alert, BackoffPolicy, User } from '../../../models';
import { AlertUpdate } from '../../../api';
import AlertListItem from '../../modules/alarm-notifications/AlertListItem';
import {
  AlertSettingsFieldGroup,
  AlertFields,
} from '../../modules/alarm-notifications/AlertSettingsFieldGroup';
import { sortBy } from 'lodash';
import { ApiError } from '../../../api/util/ApiError';
import { PrimaryButton, CancelButton, SecondaryActionButton } from '../../ui/Buttons';
import Pagination from '@material-ui/lab/Pagination';
import Cancel from '@material-ui/icons/Cancel';
import ConfirmDialog from '../../../components/ui/ConfirmDialog';

const Modal: React.FC = (props) => {
  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
};

enum ActiveModal {
  NONE,
  ENABLE,
  DISABLE_CONFIRM,
}

export interface Props {
  isProcessing: Record<string, boolean>;
  error?: ApiError;
  alerts: Alert[];
  users: User[];
}

export interface DispatchProps {
  updateAlert: (alertUpdate: AlertUpdate) => void;
  clearError: (uid: string) => void;
}

type AllProps = Props & DispatchProps;

interface AlertsPageState {
  activeModal: ActiveModal;
  activeAlert?: Alert;
  alertChanges?: Alert;
  page: number;
  filterText: string;
}

const PAGE_SIZE = 25;

class AlertsPage extends React.Component<AllProps, AlertsPageState> {
  contentRef = React.createRef<HTMLDivElement>();

  state: AlertsPageState = {
    activeModal: ActiveModal.NONE,
    page: 0,
    filterText: '',
  };

  handleEnable(alertItem: Alert) {
    this.setState({
      activeModal: ActiveModal.ENABLE,
      activeAlert: alertItem,
      // initialize form state
      alertChanges: {
        ...alertItem,
      },
    });
  }

  handleModalChange(alertItem: Alert) {
    this.setState({
      alertChanges: {
        ...alertItem,
      },
    });
  }

  handleClearModal() {
    this.setState({
      activeModal: ActiveModal.NONE,
      activeAlert: undefined,
      alertChanges: undefined,
    });
  }

  handleDisableAlert(alertItem: Alert) {
    this.setState({
      activeModal: ActiveModal.DISABLE_CONFIRM,
      activeAlert: alertItem,
    });
  }

  toAlertUpdate(alertFields: AlertFields): AlertUpdate {
    return {
      tagId: alertFields.alertTagId,
      backoffPolicy: alertFields.backoffPolicy,
      distributionList: [
        ...alertFields.distributionList.map((distributionItem) => ({
          ...distributionItem,
          userId: distributionItem.userId,
        })),
      ],
    };
  }

  render() {
    const { alerts } = this.props;

    /**
     * Active alerts should appear at the top, inactive at the bottom, with
     * secondary sort name ascending
     */
    const filteredAlerts = alerts.filter((alert) => {
      const filterText = this.state.filterText.toLowerCase().trim();
      return (
        alert.label.toLowerCase().includes(filterText) ||
        alert.description.toLowerCase().includes(filterText)
      );
    });
    const sortedAlerts = sortBy(
      sortBy(filteredAlerts, (alert) => alert.label),
      (alert) => !alert.isActive
    );

    const startIndex = this.state.page * PAGE_SIZE;
    let endIndex = this.state.page * PAGE_SIZE + PAGE_SIZE;
    endIndex = sortedAlerts.length < endIndex ? sortedAlerts.length : endIndex;

    const pagedAlerts = sortedAlerts.slice(startIndex, endIndex);
    const getResultCount = () => {
      if (alerts.length === 0) {
        return null;
      } else if (alerts.length > 0 && sortedAlerts.length === 0) {
        return 'No Matches';
      } else if (sortedAlerts.length !== alerts.length) {
        return `${startIndex + 1} - ${endIndex} of ${sortedAlerts.length} out of ${alerts.length}`;
      }
      return `${startIndex + 1} - ${endIndex} of ${alerts.length}`;
    };

    return (
      <>
        <Grid
          data-testid="alerts-page"
          container
          style={{
            backgroundColor: '#fefefe',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
          }}
        >
          <Grid item container style={{ padding: 16, borderBottom: '1px solid #e0e0e0' }}>
            <Grid container alignItems="center">
              <Grid item>
                <TextField
                  style={{ width: '20em' }}
                  placeholder="Filter"
                  value={this.state.filterText || ''}
                  onChange={(e) => this.setState({ filterText: e.target.value, page: 0 })}
                  variant="outlined"
                  size="small"
                  InputProps={
                    this.state.filterText
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => this.setState({ filterText: '', page: 0 })}
                              >
                                <Cancel />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      : undefined
                  }
                />{' '}
              </Grid>
              <Grid item style={{ marginLeft: 'auto' }}>
                {getResultCount()}
              </Grid>
            </Grid>

            {alerts.length > 0 && (
              <Grid container style={{ paddingLeft: 36, paddingRight: 36, marginTop: 16 }}>
                <Grid xs={11} container item spacing={0}>
                  <Grid item xs={12} sm={3}>
                    <Typography style={{ paddingLeft: 0 }} variant="caption">
                      Alarm
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography style={{ paddingLeft: 0 }} variant="caption">
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography style={{ paddingLeft: 8 }} variant="caption">
                      Priority
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography style={{ paddingLeft: 0 }} variant="caption">
                      Recipients
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={1} item />
              </Grid>
            )}
          </Grid>

          <Grid item style={{ width: '100%', overflowY: 'auto', flex: 1 }} ref={this.contentRef}>
            {alerts.length === 0 && (
              <Typography style={{ margin: 24, textAlign: 'center' }}>
                No alarms are configured for notifications.
              </Typography>
            )}

            {alerts.length > 0 && (
              <Grid item>
                {pagedAlerts.map((alertItem) => (
                  <AlertListItem
                    isSubmitting={this.props.isProcessing[alertItem.alertTagId]}
                    key={alertItem.alertTagId}
                    alert={alertItem}
                    handleEnable={() =>
                      this.handleEnable({
                        ...alertItem,
                        backoffPolicy: alertItem.backoffPolicy || BackoffPolicy.CONSTANT_ONE_HOUR,
                      })
                    }
                  >
                    <AlertSettingsFieldGroup
                      isSubmitting={this.props.isProcessing[alertItem.alertTagId]}
                      users={this.props.users}
                      alertFields={alertItem}
                      onChange={(modifiedFields) => {
                        this.props.updateAlert(this.toAlertUpdate(modifiedFields));
                      }}
                      additionalControls={() => (
                        <SecondaryActionButton onClick={() => this.handleDisableAlert(alertItem)}>
                          Disable
                        </SecondaryActionButton>
                      )}
                    />
                  </AlertListItem>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid item style={{ borderTop: '1px solid #e0e0e0' }}>
            <Toolbar>
              <Grid item container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item style={{ textAlign: 'center' }}>
                  <Pagination
                    count={Math.ceil(sortedAlerts.length / PAGE_SIZE)}
                    page={this.state.page + 1}
                    variant="outlined"
                    shape="rounded"
                    onChange={(_e, newPage) => {
                      this.setState({ page: newPage - 1 });
                      if (this.contentRef.current) {
                        this.contentRef.current.scrollTop = 0;
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
        </Grid>

        {this.state.activeModal === ActiveModal.ENABLE && this.state.alertChanges && (
          <Modal>
            <Grid container direction="column" data-testid="alert-enable-modal">
              <Grid item style={{ marginBottom: 24 }}>
                <Typography variant="h5">
                  Enable Alert:&nbsp;
                  {this.state.activeAlert && this.state.activeAlert.label}
                </Typography>
              </Grid>

              <Grid item>
                <form>
                  <AlertSettingsFieldGroup
                    alertFields={this.state.alertChanges}
                    users={this.props.users}
                    onChange={(update) => {
                      if (this.state.alertChanges) {
                        this.handleModalChange({
                          ...this.state.alertChanges,
                          ...update,
                        });
                      }
                    }}
                  />
                </form>
              </Grid>

              <Grid item container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <CancelButton onClick={() => this.handleClearModal()}>Cancel</CancelButton>
                </Grid>
                <Grid item>
                  <PrimaryButton
                    type="submit"
                    disabled={
                      this.state.alertChanges &&
                      this.state.alertChanges.distributionList.length === 0
                    }
                    onClick={() => {
                      if (this.state.alertChanges) {
                        this.props.updateAlert({
                          tagId: this.state.alertChanges.alertTagId,
                          backoffPolicy: this.state.alertChanges.backoffPolicy,
                          distributionList: [
                            ...this.state.alertChanges.distributionList.map((distributionItem) => ({
                              ...distributionItem,
                            })),
                          ],
                        });
                        this.setState({ page: 0 });
                      }
                      this.handleClearModal();
                    }}
                  >
                    Enable
                  </PrimaryButton>
                </Grid>
              </Grid>
            </Grid>
          </Modal>
        )}

        {this.state.activeModal === ActiveModal.DISABLE_CONFIRM && (
          <ConfirmDialog
            onCancel={() => this.handleClearModal()}
            onConfirm={() => {
              if (this.state.activeAlert) {
                this.props.updateAlert({
                  tagId: this.state.activeAlert.alertTagId,
                  backoffPolicy: this.state.activeAlert.backoffPolicy,
                  distributionList: this.state.activeAlert.distributionList.filter(
                    (dist) => !dist.user
                  ),
                });
              }
              this.handleClearModal();
            }}
            title="Disable Alarm Notifications"
            message={`Are you sure you would like to disable all notifications for
            ${this.state.activeAlert && ' ' + this.state.activeAlert.label}?`}
            open={true}
            maxWidth="sm"
            confirmLabel="Disable"
          />
        )}

        {this.props.error && (
          <Modal>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="subtitle1">Error Processing Request</Typography>
                <Typography color="error" style={{ padding: 0 }}>
                  {this.props.error.message}
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" style={{ marginTop: 16 }}>
                <PrimaryButton
                  onClick={() =>
                    this.props.error && this.props.clearError(this.props.error.actionId)
                  }
                >
                  Ok
                </PrimaryButton>
              </Grid>
            </Grid>
          </Modal>
        )}
      </>
    );
  }
}

export default AlertsPage;
