import { PushClient } from '../api/util/PushClient';
import { SiteSummary } from '../models';

export interface PortfolioQuery {
  customerIds?: number[];
  nameAddressFilter?: string;
  utilityIds?: number[];
  zipCodes?: string[];
  alarmStatuses?: string[];
  gridServicesEnrollmentStatuses?: string[];
  sortBy?: string;
  sortAscending?: boolean;
  pageNumber: number;
  pageSize?: number;
}

export interface PortfolioResponse {
  sites: SiteSummary[];
  totalMatchingSites: number;
  pageNumber: number;
}

interface FilterOption {
  id: number;
  name: string;
}

export interface PortfolioOptions {
  utilities: FilterOption[];
  customers: FilterOption[];
  zipCodes: string[];
}

export class PortfolioApi extends PushClient {
  static baseUrl = '/Snapshot/portfolio';

  public async querySiteSummaries(query: PortfolioQuery): Promise<PortfolioResponse> {
    return this.singleMessagePostRequest(PortfolioApi.baseUrl, query);
  }

  public async fetchPortfolioOptions(): Promise<PortfolioOptions> {
    return this.singleMessageGetRequest(`${PortfolioApi.baseUrl}/filterOptions`);
  }
}
