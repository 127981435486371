import { useMemo, useState } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormikProps, FormikValues } from 'formik';
import { get, pick } from 'lodash';
import * as Yup from 'yup';
import { COUNTRIES, STATES_USA } from '../../../helpers/address';
import ConfirmDialog from '../ConfirmDialog';
import { getFormikInputProps } from '../../../helpers/form';
import { Address, Country, getDefaultAddress } from '../../../models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface AddressFormProps<TFormikValues extends FormikValues = any> {
  defaultCountry?: keyof typeof Country;
  disableCountryInput?: boolean;
  formik: FormikProps<TFormikValues>;
  objectKey: string;
  readOnly?: boolean;
}
export const AddressForm = ({
  defaultCountry = 'US',
  disableCountryInput = false,
  formik,
  objectKey,
  readOnly = false,
}: AddressFormProps) => {
  const [newCountry, setNewCountry] = useState<keyof typeof Country | false>(false);

  const country: keyof typeof Country =
    get(formik.values, `${objectKey}.country`) ?? defaultCountry;

  // Change form component based on country
  const CountryAddressForm = useMemo(() => {
    switch (country) {
      case 'CR':
        return CostaRicaAddressForm;
      case 'US':
      default:
        return UnitedStatesAddressForm;
    }
  }, [country]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <TextField
          label="Country"
          disabled={readOnly || disableCountryInput}
          value={country}
          {...getFormikInputProps(formik, `${objectKey}.country`, 'select')}
          onChange={(event) => {
            const value = event.target.value as keyof typeof Country;
            if (value !== formik.values[`${objectKey}.country`] || value !== defaultCountry) {
              const hasValues = Object.keys(formik.values[objectKey])
                .filter((val) => val !== 'country')
                .some((val) => formik.values[objectKey][val]);

              if (hasValues) {
                setNewCountry(value);
              } else {
                formik.setFieldValue(objectKey, getDefaultAddress(value));
              }
            }
          }}
        >
          {COUNTRIES.map(({ key, label }) => (
            <MenuItem value={key} key={key}>
              {label}
            </MenuItem>
          ))}
        </TextField>
        {newCountry && (
          <ConfirmDialog
            confirmLabel="Yes, Change Country"
            message={
              <>
                Are you sure you want to change the country to{' '}
                <strong>{Country[newCountry]}</strong>? Address changes will be discarded.
              </>
            }
            onCancel={() => setNewCountry(false)}
            onConfirm={() => {
              // Reset form (set default address)
              if (newCountry) {
                formik.setFieldValue(objectKey, getDefaultAddress(newCountry));
                setNewCountry(false);
              }
            }}
          />
        )}
      </Grid>
      <CountryAddressForm formik={formik} objectKey={objectKey} readOnly={readOnly} />
    </Grid>
  );
};

export type CountryAddressFormProps = Required<
  Pick<AddressFormProps, 'formik' | 'objectKey' | 'readOnly'>
>;

const handleZipCodeKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
  if (!event.key.match(/^\d+$/)) {
    event.preventDefault();
  }
};

const CostaRicaAddressForm = ({ formik, readOnly, objectKey }: CountryAddressFormProps) => {
  return (
    <>
      <Grid item>
        <TextField
          label="Address"
          disabled={readOnly}
          {...getFormikInputProps(formik, `${objectKey}.address1`)}
        />
      </Grid>
      <Grid item>
        <TextField
          label="Province"
          disabled={readOnly}
          {...getFormikInputProps(formik, `${objectKey}.stateOrProvince`)}
        />
      </Grid>
      <Grid item>
        <TextField
          label="Canton"
          disabled={readOnly}
          {...getFormikInputProps(formik, `${objectKey}.city`)}
        />
      </Grid>
      <Grid item>
        <TextField
          label="District"
          disabled={readOnly}
          {...getFormikInputProps(formik, `${objectKey}.district`)}
        />
      </Grid>
      <Grid item>
        <TextField
          label="Postal Code"
          disabled={readOnly}
          onKeyPress={handleZipCodeKeyPress}
          {...getFormikInputProps(formik, `${objectKey}.postalCode`)}
        />
      </Grid>
    </>
  );
};

export const crAddressValidationSchema = Yup.object<Address>({
  address1: Yup.string().trim().required('Field Required'),
  city: Yup.string().trim().required('Field Required'),
  district: Yup.string().trim().required('Field Required'),
  stateOrProvince: Yup.string().trim().required('Field Required'),
  postalCode: Yup.string().trim().required('Field Required'),
  country: Yup.string().trim().required('Field Required'),
}).required();

const UnitedStatesAddressForm = ({ formik, readOnly, objectKey }: CountryAddressFormProps) => {
  return (
    <>
      <Grid item>
        <TextField
          label="Address 1"
          disabled={readOnly}
          {...getFormikInputProps(formik, `${objectKey}.address1`)}
        />
      </Grid>
      <Grid item>
        <TextField
          label="Address 2"
          disabled={readOnly}
          {...getFormikInputProps(formik, `${objectKey}.address2`)}
        />
      </Grid>
      <Grid item>
        <TextField
          label="City"
          disabled={readOnly}
          {...getFormikInputProps(formik, `${objectKey}.city`)}
        />
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={12} md={4}>
          <Autocomplete
            id={`${objectKey}.stateOrProvince`}
            value={STATES_USA[get(formik.values, `${objectKey}.stateOrProvince`)] || null}
            options={Object.values(STATES_USA)}
            autoHighlight
            blurOnSelect={true}
            onChange={(event, stateValue) => {
              formik.setFieldTouched(`${objectKey}.stateOrProvince`);
              if (!stateValue) {
                return formik.setFieldValue(`${objectKey}.stateOrProvince`, '');
              }
              const stateEntry = Object.entries(STATES_USA).filter((e) => e.includes(stateValue));
              const stateKey = stateEntry[0][0];
              formik.setFieldValue(`${objectKey}.stateOrProvince`, stateKey);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                disabled={readOnly}
                label="State"
                {...pick(getFormikInputProps(formik, `${objectKey}.stateOrProvince`), [
                  'error',
                  'helperText',
                  'variant',
                  'size',
                ])}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label="Zip Code"
            onKeyPress={handleZipCodeKeyPress}
            disabled={readOnly}
            {...getFormikInputProps(formik, `${objectKey}.postalCode`)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const usaAddressValidationSchema = Yup.object<Address>({
  address1: Yup.string().trim().required('Field Required'),
  address2: Yup.string().trim(),
  city: Yup.string().trim().required('Field Required'),
  stateOrProvince: Yup.string().trim().required('Field Required'),
  postalCode: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .length(5, 'Must be exactly 5 digits')
    .required('Field Required'),
  country: Yup.string().trim().required('Field Required'),
}).required();

export function getAddressValidationSchema(country: string) {
  switch (Country[country]) {
    case Country.CR:
      return crAddressValidationSchema;
    case Country.US:
    default:
      return usaAddressValidationSchema;
  }
}
