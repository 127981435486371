import logger from './logger';

export function loadState<T>(key: string): T | undefined {
  try {
    const serializedState = localStorage.getItem(key);
    if (!serializedState) {
      return undefined;
    }

    return JSON.parse(serializedState) as T;
  } catch (err) {
    logger.error(err);
    return;
  }
}

export const saveState = <State>(key: string, state: State) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    logger.error(err);
  }
};

export const deleteState = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    logger.error(err);
  }
};
