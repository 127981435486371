import React from 'react';
import { AnalysisPage } from './AnalysisPage';
import { getSiteContext } from '../../../state/contexts/site';
import {
  getAnalysisPageContext,
  setStartDate,
  setEndDate,
  clearAllTags,
  toggleTagId,
  parseAnalysisRouteContext,
  setContext,
  validateAnalysisContext,
} from '../../../state/contexts/analysisPage';
import { fetchSnapshots, getNumericDataSet } from '../../../state/entities';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state';
import { isPending } from '../../../state/request';
import { ActionType } from 'typesafe-actions';
import { useAction } from '../../../helpers/hooks';
import { useLocation } from 'react-router-dom';
import { getHistory } from '../../../state';
import { PathBuilder } from '../../../routing';

const getDefaultRouteContext = () => ({
  start: new Date(Date.now() - 1000 * 60 * 60 * 48),
  end: new Date(Date.now()),
  tagIds: [],
});

export const AnalysisPageLoader: React.FC<{}> = () => {
  const site = useSelector(getSiteContext);
  const { id: siteId } = site;
  const context = useSelector(getAnalysisPageContext);

  const [routeError, setRouteError] = useState<string | undefined>();

  const doSetStartDate = useAction(setStartDate);
  const doSetEndDate = useAction(setEndDate);
  const doClearAllTags = useAction(clearAllTags);
  const doToggleTagId = useAction(toggleTagId);
  const doSetContext = useAction(setContext);
  const doReset = () => {
    setRouteError(undefined);
    const history = getHistory();
    history.push(PathBuilder.ANALYSIS(siteId));
    doSetContext(getDefaultRouteContext());
  };

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (context.tagIds.length > 0) {
      setRouteError(undefined);
    }
  }, [context]);

  useEffect(() => {
    try {
      const routeContext = parseAnalysisRouteContext(location.pathname, location.search);
      validateAnalysisContext(routeContext, site);
      if (!routeContext.start && !routeContext.end) {
        routeContext.start = new Date(Date.now() - 1000 * 60 * 60 * 48);
        routeContext.end = new Date(Date.now());
      }
      doSetContext(routeContext);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // If any error is encountered parsing the route context
      doSetContext(getDefaultRouteContext());
      setRouteError(e.message);
    }
    // todo: add useEffectOnce hook?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastRequest = useRef<ActionType<typeof fetchSnapshots.request> | undefined>(undefined);

  useEffect(() => {
    const { start, end, tagIds } = context;
    if (start && end && tagIds.length > 0 && !routeError) {
      const action = fetchSnapshots.request(siteId, tagIds, start, end, true);
      lastRequest.current = action;
      dispatch(action);
    }
  }, [context, dispatch, routeError, siteId]);

  // get snapshots
  const snapshots = useSelector((state: RootState) => {
    if (context.start && context.end && !routeError) {
      return getNumericDataSet(state, context.tagIds, context.start, context.end);
    } else {
      return {};
    }
  });

  const loading = useSelector((state: RootState) =>
    Boolean(lastRequest.current && isPending(state, lastRequest.current))
  );

  return (
    <AnalysisPage
      site={site}
      snapshots={snapshots}
      start={context.start}
      end={context.end}
      tagIds={context.tagIds}
      toggleTagId={doToggleTagId}
      setStartDate={doSetStartDate}
      setEndDate={doSetEndDate}
      clearAllTags={doClearAllTags}
      reset={doReset}
      error={routeError}
      loading={loading}
    />
  );
};
