import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { RootState } from '../../state';

export interface WithDataProps<T> {
  ownProps: T;
  rootState: RootState;
  isLoaded: boolean;
  isError: boolean;
}

export interface WithDataDispatchProps<T> {
  loadDataDispatch: (rootState: RootState, ownProps: T) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withData = <T extends any>(
  loadData: (rootState: RootState, ownProps: T) => Action | Action[] | null,
  options?: {
    isLoaded: (rootState: RootState, ownProps: T) => boolean;
    renderLoader: (rootState: RootState, ownProps: T) => React.ReactNode;
    isError?: (rootState: RootState, ownProps: T) => boolean;
    renderError: (rootState: RootState, ownProps: T) => React.ReactNode;
  }
) => {
  const isError = options && options.isError;
  const isLoaded = options && options.isLoaded;
  const renderLoader = options && options.renderLoader;
  const renderError = options && options.renderError;

  const mapStateToProps = (rootState: RootState, ownProps: T) => {
    return {
      ownProps,
      rootState: rootState,
      isError: isError ? isError(rootState, ownProps) : false,
      isLoaded: isLoaded ? isLoaded(rootState, ownProps) : true,
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
      loadDataDispatch: (rootState: RootState, ownProps: T) => {
        const actions = loadData(rootState, ownProps);
        if (actions instanceof Array) {
          actions.map(dispatch);
        } else if (actions) {
          dispatch(actions);
        }
      },
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (WrappedComponent: React.ComponentType<any>) => {
    class Wrapper extends React.Component<WithDataProps<T> & WithDataDispatchProps<T>> {
      componentDidMount() {
        const { rootState, ownProps } = this.props;
        // perform actions to load data for component
        this.props.loadDataDispatch(rootState, ownProps);
      }

      render() {
        const { rootState, ownProps } = this.props;
        if (this.props.isError && renderError) {
          return renderError(rootState, ownProps);
        } else if (this.props.isLoaded) {
          return <WrappedComponent {...ownProps} />;
        } else if (renderLoader) {
          return renderLoader(rootState, ownProps);
        } else {
          return null;
        }
      }
    }

    return connect(
      mapStateToProps,
      mapDispatchToProps
      /**
       * TODO: fix type issue
       * ... Type 'T' is not assignable to type 'T extends T ? T : T'
       */
      // @ts-ignore-next-line
    )(Wrapper);
  };
};
