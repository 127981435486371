import { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  StepProps,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Formik, FormikConfig } from 'formik';
import * as Yup from 'yup';
import { Schema } from 'yup';
import { useHistory } from 'react-router-dom';
import { PathBuilder } from '../../../routing';
import {
  Country,
  EquipmentTemplate,
  getDefaultAddress,
  InstallerProvisionData,
  PartnerSite,
  SiteTemplate,
} from '../../../models';
import identifiers from '../../../tests/identifiers';
import { getAddressValidationSchema } from '../../ui/form/AddressForm';
import { UtilityRateLoader } from 'src/components/ui/form/UtilityRateLoader';
import {
  InstallerFormProps,
  InstallerPageProps,
  InstallerSteps,
  SolarEdgeDetails,
  SolArkDetails,
  SiteDetails,
} from './InstallerPage.types';
import { isApiError } from '../../../api/util/ApiError';
import LoadWithSpinner from '../../ui/Loading/LoadWithSpinner';
import { PrimaryButton } from '../../ui/Buttons';
import SolArkSiteDetailsForm, {
  SolArkSiteDetailsFormValidationSchema,
  solArkSiteDetailsInitialValues,
} from './SolArkSiteDetailsForm';
import SolarEdgeSiteDetailsForm, {
  SolarEdgeSiteDetailsFormValidationSchema,
  solarEdgeSiteDetailsInitialValues,
} from './SolarEdgeSiteDetailsForm';
import SiteLocation, { SiteLocationStepLabel } from './SiteLocation';
import SkuSelect from './SkuSelect';

const InstallerPage = ({ provisionSiteRequest, site, siteTemplates, sku }: InstallerPageProps) => {
  const classes = useStyles();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(sku === '' ? 0 : 1);
  const [country, setCountry] = useState<string>(Country.US);
  const [postalCode, setPostalCode] = useState<string>('');
  const [image, setImage] = useState<string | false>(false);

  // Get site template from SKU
  const siteTemplate = useMemo(() => {
    const template = sku === '' ? null : siteTemplates.find((item) => item.sku === sku) ?? false;
    if (template === false) {
      // SKU template doesn't exist, go back to selection step
      history.push(PathBuilder.INSTALLER(site.id));
      return null;
    }
    return template;
  }, [history, site.id, siteTemplates, sku]);

  // Get equipment that needs configuration from site template
  const equipment = useMemo(
    () =>
      (siteTemplate?.equipment ?? []).filter(
        ({ requiresAdditionalConfiguration = true }: EquipmentTemplate) =>
          requiresAdditionalConfiguration
      ),
    [siteTemplate]
  );

  // Set initial form values based on site template selection
  const initialValues = useMemo<InstallerFormProps>(() => {
    const baseValues = baseSiteDetailsInitialValues(sku);
    if (siteTemplate?.partnerId === PartnerSite.SolarEdge) {
      return {
        ...baseValues,
        ...solarEdgeSiteDetailsInitialValues(equipment),
      } as SolarEdgeDetails;
    }
    if (siteTemplate?.partnerId === PartnerSite.SolArk) {
      return {
        ...baseValues,
        ...solArkSiteDetailsInitialValues,
      } as SolArkDetails;
    }

    return baseValues;
  }, [equipment, siteTemplate?.partnerId]);

  // Handle SKU reset
  useEffect(() => {
    if (sku === '') {
      setActiveStep(InstallerSteps.SKU);
      setImage(false);
    }
  }, [sku]);

  // Change validation schema based on selections
  const validationSchema = useMemo(
    () => getInstallerValidationSchema(activeStep, equipment, country, siteTemplate?.partnerId),
    [activeStep, country, equipment, siteTemplate?.partnerId]
  );

  // Manage provision site request error state
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setIsError(provisionSiteRequest.isError);
  }, [provisionSiteRequest.isError]);

  const formikConfig: FormikConfig<InstallerFormProps> = {
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, helpers) => {
      // Reset submitCount to prevent premature validation
      helpers.resetForm({ submitCount: 0, values });

      // Handle submission for each step
      switch (activeStep) {
        case InstallerSteps.SKU:
          break;
        case InstallerSteps.LOCATION:
          setActiveStep(InstallerSteps.DETAILS);
          break;
        case InstallerSteps.DETAILS:
          if (siteTemplate) {
            // Format input
            const {
              address,
              image: _removed,
              sku: templateSKU,
              customerEmail,
              siteName,
              utilityId,
              rateScheduleId,
              imageFile,
              ...otherValues
            } = values;
            const { address1, address2 = '', district, ...otherAddress } = address;
            let input: InstallerProvisionData = {
              imageFile,
              rateScheduleId,
              customerEmail,
              siteName,
              utilityId,
              ...otherAddress,
              templateSKU,
              installationDate: new Date().toISOString(),
              address: address2 !== '' ? `${address1}, ${address2}` : address1,
              utilityName: document.getElementById('utilityId')?.innerText ?? '',
            };

            if (siteTemplate.partnerId === PartnerSite.SolarEdge) {
              const { serialNumbers, ...otherSolarEdgeValues } = otherValues as Omit<
                SolarEdgeDetails,
                'sku' | 'address' | 'image'
              >;
              const inverterSerialNumbers = serialNumbers
                ? equipment.map((item) => serialNumbers[item.participantId])
                : undefined;

              input = { ...input, inverterSerialNumbers, ...otherSolarEdgeValues };
            }

            if (siteTemplate.partnerId === PartnerSite.SolArk) {
              const otherSolArkValues = otherValues as Omit<
                SolArkDetails,
                'sku' | 'address' | 'image'
              >;
              input = { ...input, ...otherSolArkValues };
            }

            provisionSiteRequest.boundActionCreator(input, siteTemplate);
          }
          break;
        default:
      }
    },
    validationSchema,
  };

  return (
    <div className={classes.root} data-testid={identifiers.installerPage.page}>
      <Formik {...formikConfig}>
        <Stepper
          activeStep={activeStep}
          className={classes.stepper}
          connector={<InstallerStepConnector />}
          orientation="vertical"
        >
          <InstallerStep
            label={
              activeStep > InstallerSteps.SKU ? (
                <>
                  <Typography variant="h6">SKU</Typography>
                  <Typography variant="subtitle1">{siteTemplate?.name ?? sku}</Typography>
                </>
              ) : (
                <Typography variant="h6">Select a SKU</Typography>
              )
            }
            testId={identifiers.installerPage.stepSKUSelection}
          >
            <SkuSelect
              setActiveStep={setActiveStep}
              siteTemplates={siteTemplates}
              sku={sku}
              siteId={site.id}
            />
          </InstallerStep>

          <InstallerStep
            label={<SiteLocationStepLabel activeStep={activeStep} />}
            testId={identifiers.installerPage.stepSiteLocation}
          >
            <SiteLocation
              updatePostalCode={setPostalCode}
              onCountryChange={setCountry}
              setActiveStep={setActiveStep}
              siteId={site.id}
            />
          </InstallerStep>

          <InstallerStep
            label={<Typography variant="h6">Site Details</Typography>}
            testId={identifiers.installerPage.stepSiteDetails}
          >
            {isValidPartner(siteTemplate) ? (
              <UtilityRateLoader
                postalCode={postalCode}
                errorDismissalAction={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setActiveStep(InstallerSteps.LOCATION)}
                  >
                    BACK
                  </Button>
                }
              >
                {({ utilities }) => (
                  <>
                    {siteTemplate?.partnerId === PartnerSite.SolarEdge && (
                      <SolarEdgeSiteDetailsForm
                        utilities={utilities}
                        setActiveStep={setActiveStep}
                        setImage={setImage}
                        image={image}
                        siteTemplate={siteTemplate}
                        equipment={equipment}
                      />
                    )}
                    {siteTemplate?.partnerId === PartnerSite.SolArk && (
                      <SolArkSiteDetailsForm
                        setActiveStep={setActiveStep}
                        image={image}
                        setImage={setImage}
                        utilities={utilities}
                      />
                    )}
                  </>
                )}
              </UtilityRateLoader>
            ) : (
              <>
                <Alert
                  className={classes.alert}
                  data-testid={identifiers.installerPage.invalidPartner}
                  severity="error"
                >
                  <AlertTitle>Invalid Partner</AlertTitle>
                  Select a SKU with a valid partner.
                </Alert>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      history.push(PathBuilder.INSTALLER(site.id));
                    }}
                  >
                    Back
                  </Button>
                </Box>
              </>
            )}
          </InstallerStep>
        </Stepper>
      </Formik>

      <Dialog
        data-testid={identifiers.installerPage.provisionPending}
        maxWidth="sm"
        open={provisionSiteRequest.isPending}
        fullWidth
      >
        <Box padding={6}>
          <LoadWithSpinner message="Provisioning site" />
        </Box>
      </Dialog>

      <Dialog
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
        data-testid={identifiers.installerPage.provisionError}
        maxWidth="sm"
        open={isError}
        fullWidth
      >
        <DialogTitle id="error-dialog-title">Error Creating New Site</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-dialog-description">
            There was an error provisioning your new site.{' '}
            {isApiError(provisionSiteRequest.status) && provisionSiteRequest.status.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={() => setIsError(false)} autoFocus>
            Ok
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const baseSiteDetailsInitialValues = (sku: string) => {
  return {
    sku,
    siteName: '',
    address: { ...getDefaultAddress('US'), stateOrProvince: 'CA' },
    utilityId: null,
    rateScheduleId: null,
    gridServicesInitialStatus: '',
    customerEmail: '',
    image: '',
    imageFile: '' as const,
  } as SiteDetails;
};

function isValidPartner(siteTemplate: SiteTemplate | null) {
  if (!siteTemplate) {
    return false;
  }
  return Object.values(PartnerSite).includes(siteTemplate.partnerId);
}

function getInstallerValidationSchema(
  activeStep: InstallerSteps,
  equipment: EquipmentTemplate[],
  country: string,
  partnerId?: PartnerSite
) {
  const schema: Record<string, Schema<string>> = {};

  const isLastStep = activeStep === InstallerSteps.DETAILS;

  // SKU Step
  if (activeStep === InstallerSteps.SKU || isLastStep) {
    schema.sku = Yup.string().required('Field Required');
  }

  // Location Step
  if (activeStep === InstallerSteps.LOCATION || isLastStep) {
    Object.assign(schema, {
      siteName: Yup.string()
        .required('Field Required')
        .test(
          'length',
          `Must Be Between ${FIELD_SITE_NAME_MIN_LENGTH} And ${FIELD_SITE_NAME_MAX_LENGTH} Characters Long`,
          (val) =>
            !!(
              val &&
              val.length >= FIELD_SITE_NAME_MIN_LENGTH &&
              val.length <= FIELD_SITE_NAME_MAX_LENGTH
            )
        ),
      address: getAddressValidationSchema(country),
    });
  }

  // Details Step
  if (isLastStep) {
    if (!partnerId) {
      return;
    }

    let detailsSchema;
    if (partnerId === PartnerSite.SolarEdge) {
      detailsSchema = SolarEdgeSiteDetailsFormValidationSchema(equipment);
    }
    if (partnerId === PartnerSite.SolArk) {
      detailsSchema = SolArkSiteDetailsFormValidationSchema;
    }

    Object.assign(schema, detailsSchema);
  }

  return Yup.object(schema);
}

const FIELD_SITE_NAME_MAX_LENGTH = 48;
const FIELD_SITE_NAME_MIN_LENGTH = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      background: 'rgba(255, 255, 255, 0.75)',
    },
  },
  alert: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  img: {
    maxHeight: 225,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 150,
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  stepper: {
    background: 'transparent',
    marginTop: theme.spacing(5),
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

interface InstallerStepProps extends Partial<StepProps> {
  label: ReactNode;
  testId: string;
}
const InstallerStep: FunctionComponent<InstallerStepProps> = ({
  children,
  label,
  testId,
  ...stepProps
}) => {
  const classes = useStepStyles();
  return (
    <Step {...stepProps}>
      <StepLabel
        classes={{ label: classes.label }}
        StepIconProps={{ classes: { root: classes.icon } }}
      >
        {label}
      </StepLabel>
      <StepContent classes={{ root: classes.content }}>
        <div data-testid={testId}>{children}</div>
      </StepContent>
    </Step>
  );
};
const useStepStyles = makeStyles((theme) => ({
  content: {
    marginLeft: 14,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    height: '1.2em',
    overflow: 'visible',
    width: '1.2em',
  },
}));

const InstallerStepConnector = withStyles({
  vertical: {
    marginLeft: 14,
  },
})(StepConnector);

export default InstallerPage;
