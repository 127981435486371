import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ismVoult } from '../../config';
import mvoultIcon from '../../assets/mvoult-icon.png';
import mvoultIconDark from '../../assets/mvoult-icon-dark.png';

interface Props {
  customerId: number;
  isDark: boolean;
  link?: string;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '48px',
    height: 'auto',
    padding: `${theme.spacing(0.25)}px`,
    [theme.breakpoints.down('sm')]: {
      width: '36px',
    },
  },
}));

const SiteIcon = (props: Props) => {
  const classes = useStyles();
  const mainIcon = ismVoult ? mvoultIcon : `/logos/icon${props.customerId}.png`;
  const mainIconDark = ismVoult ? mvoultIconDark : `/logos/icon${props.customerId}-dark.png`;
  const iconSrc = props.isDark ? mainIconDark : mainIcon;

  const renderImage = () => {
    return <img alt="Company Icon" className={classes.icon} src={iconSrc} />;
  };

  return (
    <div style={{ display: 'flex' }}>
      {props.link ? <Link to={props.link}>{renderImage()}</Link> : renderImage()}
    </div>
  );
};

SiteIcon.defaultProps = { isDark: false };

export default SiteIcon;
