import { connect } from 'react-redux';
import { RootState } from '../../../state';
import AlertPage, { Props } from './AlertPage';
import { updateAlert, findAlertsBySite, getUsersWithSiteAccess } from '../../../state/entities';
import { getType } from 'typesafe-actions';
import { clearRequest, getFirstError, getAllPending } from '../../../state/request';
import { getSiteContext } from '../../../state/contexts/site';
import { getProfileContext } from '../../../state/contexts/auth/selectors';
import { Dictionary } from 'lodash';

const mapStateToProps = (rootState: RootState): Props => {
  const { id: siteId } = getSiteContext(rootState);
  getProfileContext(rootState);
  const error = getFirstError(rootState, getType(updateAlert.request));
  const users = getUsersWithSiteAccess(rootState, siteId);

  /*
  if (userProfile.customerId !== customerId) {
    throw new Error('Unable to access this page as non-customer user.');
  }
   */

  const alerts = findAlertsBySite(rootState, siteId);
  const pending = getAllPending(rootState, updateAlert.request);
  const isProcessing = pending.reduce<Dictionary<boolean>>((acc, v) => {
    acc[v.trigger.payload.alertUpdate.tagId] = true;
    return acc;
  }, {} as Dictionary<boolean>);

  return {
    isProcessing,
    error,
    /**
     * Users that are assigned to this site
     * Note: This includes users assigned to this site, but linked to
     *       a different customer
     */
    users,
    alerts,
  };
};

const actions = {
  updateAlert: updateAlert.request,
  clearError: clearRequest,
};

export default connect(mapStateToProps, actions)(AlertPage);
