import { Dictionary, zipObject } from 'lodash';
import { TagDefinition, TagAttribute, TagType } from '../../../models';
import { includesAll } from '../../../helpers/common';

const persistant = (attribute: TagAttribute) => ({
  attributes: [attribute, TagAttribute.OperationalSummary],
  tagType: TagType.Persistent,
});

const forecast = (attribute: TagAttribute) => ({
  attributes: [attribute, TagAttribute.OperationalSummary],
  tagType: TagType.Forecast,
});

const isTagMatch = (tag: TagDefinition, tagPartial: ReturnType<typeof persistant>) => {
  return includesAll(tag.attributes, tagPartial.attributes) && tag.tagType === tagPartial.tagType;
};

export const OpSummaryChartSources = {
  UtilityRollback: persistant(TagAttribute.UtilityRollback),
  UtilityConsumption: persistant(TagAttribute.UtilityConsumption),
  BaseGeneration: persistant(TagAttribute.BaseGeneration),
  StorageDischarging: persistant(TagAttribute.StorageDischarging),
  PV: persistant(TagAttribute.PV),
  Load: persistant(TagAttribute.Load),
  StorageCharging: persistant(TagAttribute.StorageCharging),
  StateOfCharge: persistant(TagAttribute.StateOfCharge),
  DemandThreshold: persistant(TagAttribute.Demand),

  // Forecasts
  UtilityRollbackForecast: forecast(TagAttribute.UtilityRollback),
  UtilityConsumptionForecast: forecast(TagAttribute.UtilityConsumption),
  BaseGenerationForecast: forecast(TagAttribute.BaseGeneration),
  StorageDischargingForecast: forecast(TagAttribute.StorageDischarging),
  PVForecast: forecast(TagAttribute.PV),
  LoadForecast: forecast(TagAttribute.Load),
  StorageChargingForecast: forecast(TagAttribute.StorageCharging),
  StateOfChargeForecast: forecast(TagAttribute.StateOfCharge),
  DemandThresholdForecast: forecast(TagAttribute.Demand),

  // Rates
  ConsumptionRate: persistant(TagAttribute.Rate),
} as const;

export type OpSummaryChartSourceKey = keyof typeof OpSummaryChartSources;

/**
 * These are requested for the last 24 hours
 */
const HistoricalSources: OpSummaryChartSourceKey[] = [
  'UtilityRollback',
  'UtilityConsumption',
  'BaseGeneration',
  'StorageDischarging',
  'PV',
  'Load',
  'StorageCharging',
  'StateOfCharge',
  'DemandThreshold',
];

/**
 * These are requested for the next 24 hours
 */
export const ForecastSources: OpSummaryChartSourceKey[] = [
  'UtilityRollbackForecast',
  'UtilityConsumptionForecast',
  'BaseGenerationForecast',
  'StorageDischargingForecast',
  'PVForecast',
  'LoadForecast',
  'StorageChargingForecast',
  'StateOfChargeForecast',
  'DemandThresholdForecast',
];

/**
 * These are requested from minus 24 hours to plus 8 hours
 */
const RateSources: OpSummaryChartSourceKey[] = ['ConsumptionRate'];

export const getHistoricalTagIds = (siteTags: Dictionary<TagDefinition>) => {
  let tags = Object.values(siteTags);
  tags = tags.filter((t) => t.attributes.includes(TagAttribute.OperationalSummary));
  const foundTags = HistoricalSources.map((key) => {
    return tags.find((tag) => isTagMatch(tag, OpSummaryChartSources[key]))?.id;
  });
  return zipObject(HistoricalSources, foundTags);
};

export const getForecastTagIds = (siteTags: Dictionary<TagDefinition>) => {
  let tags = Object.values(siteTags);
  tags = tags.filter((t) => t.attributes.includes(TagAttribute.OperationalSummary));
  const foundTags = ForecastSources.map((key) => {
    return tags.find((tag) => isTagMatch(tag, OpSummaryChartSources[key]))?.id;
  });
  return zipObject(ForecastSources, foundTags);
};

export const getRateTagIds = (siteTags: Dictionary<TagDefinition>) => {
  let tags = Object.values(siteTags);
  tags = tags.filter((t) => t.attributes.includes(TagAttribute.OperationalSummary));
  const foundTags = RateSources.map((key) => {
    return tags.find((tag) => isTagMatch(tag, OpSummaryChartSources[key]))?.id;
  });
  return zipObject(RateSources, foundTags);
};
