import { AlarmPriority } from './alarm';
import { includesAll } from '../helpers/common';
import { mapValues, Dictionary } from 'lodash';
import { DataPoint } from './snapshot';

export interface HasTags {
  tags: TagDefinition[];
}

export enum TagDataType {
  INTEGER = 'Integer',
  DOUBLE = 'Double',
  LONG = 'Long',
  STRING = 'String',
  DATETIME = 'DateTime',
  BOOLEAN = 'Boolean',
}

const ChartableDataTypes = [TagDataType.INTEGER, TagDataType.DOUBLE, TagDataType.LONG];

export enum TagAttribute {
  Lobby = 'lobby',
  OperationalSummary = 'operationalsummary',
  Current = 'current',
  PV = 'pv',
  Storage = 'storage',
  Utility = 'utility',
  Load = 'load',
  StateOfCharge = 'state of charge',
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Actual = 'actual',
  Baseline = 'baseline',
  PercentReduction = 'percent reduction',
  MaxValue = 'max-value',
  HighValue = 'high-value',
  LowValue = 'low-value',
  MinValue = 'min-value',

  CSS = 'css',
  Rate = 'rateschedule',

  PvToLoad = 'pv to load',
  UtilityRollback = 'utility rollback',
  PvToBattery = 'pv to battery',
  UtilityToBattery = 'utility to battery',
  StorageDischarging = 'storage discharging',
  UtilityToLoad = 'utility to load',
  ControlSummary = 'controlsummary',
  UtilityConsumption = 'utility consumption',

  Cost = 'cost',
  Demand = 'demand',
  Projection = 'projection',
  TimeSeries = 'timeseries',
  TOU = 'tou',

  ModeledLoad = 'modeled load',
  BaseGeneration = 'base generation',
  StorageCharging = 'storage charging',

  AvoidedCost = 'avoided cost',
  MTD = 'mtd',
}

export enum TagType {
  Persistent = 'Persistent',
  Forecast = 'Forecast',
  Alarm = 'Alarm',
  Control = 'Control',
}

export interface TagDefinition {
  id: string;
  label: string;
  fullName: string;
  unit: string;
  tagType: TagType;
  dataType: TagDataType;
  attributes: TagAttribute[];
  acknowledgementNoteRequired: boolean;
  alarmPriority?: AlarmPriority;
  alarmRangeMin?: number;
  alarmRangeMax?: number;
  alarmCanBeResolved?: boolean;
}

export const findTagWithAttr = (entity: HasTags, attr: TagAttribute): TagDefinition | undefined => {
  return entity.tags.find((tag) => tag.attributes.includes(attr));
};

export const lookupTag = (
  allTags: TagDefinition[],
  attributes: TagAttribute[]
): TagDefinition | undefined => {
  return allTags.find((tag) => includesAll(tag.attributes, attributes));
};

export const lookupTags = (
  allTags: TagDefinition[],
  attributes: TagAttribute[]
): TagDefinition[] => {
  return allTags.filter((tag) => includesAll(tag.attributes, attributes));
};

/**
 * Given a dictionary of tagIds and a data set, return a dictionary of
 * datapoints.
 */
export const hydrateTagMap = (
  tagMap: Dictionary<string | string[] | undefined>,
  snapshots: Dictionary<DataPoint | undefined>
) => {
  return mapValues(tagMap, (tags) => {
    if (tags instanceof Array) {
      return tags.map((tagId) => snapshots[tagId]?.value);
    } else {
      return tags && snapshots[tags]?.value;
    }
  });
};

export const isDisplayTag = (tag: TagDefinition) =>
  tag.tagType === TagType.Persistent && tag.unit !== 'None';

export const isAlarmTag = (tag: TagDefinition) => tag.tagType === TagType.Alarm;

export const isChartableTag = (tag: TagDefinition) =>
  isDisplayTag(tag) && ChartableDataTypes.includes(tag.dataType);
