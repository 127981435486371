import { ChangeEvent } from 'react';
import { CheckboxProps, TextFieldProps } from '@material-ui/core';
import { FormikProps, FormikValues } from 'formik';
import { get } from 'lodash';

export interface FormikInputPropsOptions {
  onChange?: (event: ChangeEvent<unknown>) => void;
  uncontrolled?: boolean;
}

export type FormikInputPropsType = 'checkbox' | 'currency' | 'select' | 'text';

/**
 * Get formik input props
 *
 * @param formik
 * @param id
 * @param type
 * @param options
 */
export function getFormikInputProps<TValues = FormikValues>(
  formik: FormikProps<TValues>,
  id: string,
  type?: 'currency' | 'select' | 'text',
  options?: FormikInputPropsOptions
): TextFieldProps;

export function getFormikInputProps<TValues = FormikValues>(
  formik: FormikProps<TValues>,
  id: string,
  type: 'checkbox',
  options?: FormikInputPropsOptions
): CheckboxProps;

export function getFormikInputProps<TValues = FormikValues>(
  formik: FormikProps<TValues>,
  id: string,
  type: FormikInputPropsType = 'text',
  options: FormikInputPropsOptions = {}
) {
  const { errors, handleBlur, handleChange, setFieldValue, touched, values } = formik;
  const { onChange = () => {}, uncontrolled = false } = options;

  switch (type) {
    case 'currency':
    case 'select':
    case 'text':
    default:
      return {
        autoComplete: 'off',
        defaultValue: uncontrolled ? get(values, id) : undefined,
        error: get(touched, id) && Boolean(get(errors, id)),
        fullWidth: true,
        helperText: get(touched, id) && get(errors, id),
        id,
        InputLabelProps: { shrink: true },
        onBlur:
          type === 'currency'
            ? (_: unknown, val: number) => setFieldValue(id, val, true)
            : handleBlur(id),
        onChange: (event: ChangeEvent<unknown>, val: boolean) => {
          onChange(event);
          if (type === 'currency') {
            setFieldValue(id, val, true);
          } else {
            handleChange(id)(event);
          }
        },
        select: type === 'select',
        size: 'small',
        value: uncontrolled ? undefined : get(values, id) ?? '',
        variant: 'outlined',
        FormHelperTextProps:
          get(touched, id) && Boolean(get(errors, id))
            ? {
                'data-testid': `${id}-error`,
              }
            : undefined,
      } as TextFieldProps;
    case 'checkbox':
      return {
        id,
        onBlur: handleBlur(id),
        onChange: (event) => {
          onChange(event);
          handleChange(id)(event);
        },
        checked: get(values, id),
      } as CheckboxProps;
  }
}
