import React from 'react';
import { AlarmDetails, AlarmStatus } from '../../../models';
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Divider,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import { Lifecycle, SimpleRequestStatus } from '../../../state/request';
import { Link, useHistory } from 'react-router-dom';
import FieldGroup from '../../ui/FieldGroup';
import { orderBy } from 'lodash';
import { Route } from 'react-router-dom';
import { PathBuilder, PathPattern } from '../../../routing';
import { AlarmAckModal, AlarmNoteModal, AlarmResolveModal } from './AlarmUpdateModals';
import { Normalized } from '../../../helpers/norm';
import { AlarmHistoryLoader } from './AlarmHistoryLoader';
import { useState } from 'react';
import { getAnalysisUrl } from '../../../state/contexts/alarmsPage';
import { RequestStatus } from '../../utility/RequestStatus';
import { acknowledgeAlarm, addNote, resolveAlarm } from '../../../state/entities';
import { WithRequest } from '../../utility/WithRequest';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;
  if (value !== index) {
    return null;
  }

  return <>{children}</>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      minHeight: '75vh',
    },
    dialogTitleRoot: {
      paddingBottom: 0,
    },
  })
);

export interface Props {
  siteId: number;
  requestStatus: SimpleRequestStatus;
  alarmDetails?: AlarmDetails;
}

interface AlarmLogItemProps {
  datetime: Date;
  title: string;
  message?: string;
  user?: string;
}

const AlarmLogItem: React.FC<AlarmLogItemProps> = (props) => {
  const { datetime, title, message, user } = props;
  return (
    <Grid item container>
      <Grid item xs={3}>
        <Typography>{datetime.toLocaleString()}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography style={{ fontWeight: 600 }}>{title + (user ? ' - ' + user : '')}</Typography>
        {message && <Typography>{message}</Typography>}
      </Grid>
    </Grid>
  );
};

type ContentProps = Normalized<Props, 'alarmDetails', { alarmDetails: AlarmDetails }>;

const AlarmDetailsModalContent: React.FC<ContentProps> = (props) => {
  const { alarmDetails, siteId } = props;
  const history = useHistory();

  const handleActionCancel = () => {
    history.push(PathBuilder.ALARM_DETAILS(siteId, alarmDetails.alarmId, alarmDetails.alarmTag.id));
  };

  const logItems: AlarmLogItemProps[] = [
    {
      title: 'Triggered',
      datetime: new Date(alarmDetails.activatedAt),
    },
  ];
  if (alarmDetails.resolvedAt) {
    logItems.push({
      title: 'Resolved',
      datetime: new Date(alarmDetails.resolvedAt),
    });
  }
  if (alarmDetails.acknowledgement) {
    logItems.push({
      title: 'Acknowledged',
      datetime: new Date(alarmDetails.acknowledgement.timeStamp),
      user: alarmDetails.acknowledgement.userEmail, // TODO: get user name here
      message: alarmDetails.acknowledgement.contents,
    });
  }
  alarmDetails.notes.forEach((note) => {
    logItems.push({
      title: 'Note',
      datetime: new Date(note.timeStamp),
      user: note.userEmail,
      message: note.contents,
    });
  });

  const renderedLogItems = orderBy(logItems, (item) => item.datetime, 'desc').map((item) => (
    <AlarmLogItem key={`${item.datetime}-${item.title}`} {...item} />
  ));

  const [activeTab, setActiveTab] = useState<number>(0);
  const classes = useStyles();

  return (
    <>
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <Grid container direction="column">
          <Grid item container spacing={4}>
            <Grid item sm={4}>
              <FieldGroup label="Alarm Event">
                <Typography style={{ wordBreak: 'break-all' }}>
                  {alarmDetails.alarmTag.label}
                </Typography>
              </FieldGroup>
            </Grid>
            <Grid item sm={3}>
              <FieldGroup label="Status">
                <Typography data-testid="alarm-details-status">{alarmDetails.status}</Typography>
              </FieldGroup>
            </Grid>
            <Grid item sm={3}>
              <FieldGroup label="Triggered">
                <Typography>{new Date(alarmDetails.activatedAt).toLocaleString()}</Typography>
              </FieldGroup>
            </Grid>
            {alarmDetails.relatedTags && alarmDetails.relatedTags.length > 0 && (
              <Grid
                item
                sm={2}
                container
                alignItems="flex-start"
                style={{ justifyContent: 'flex-end' }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  to={getAnalysisUrl(siteId, alarmDetails)}
                  component={Link}
                >
                  View Analysis
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Tabs value={activeTab} onChange={(_e, i) => setActiveTab(i)}>
          <Tab label="Activity" />
          <Tab label="History" />
        </Tabs>
      </DialogTitle>
      <Divider />

      <DialogContent style={{ position: 'relative' }}>
        <TabPanel value={activeTab} index={0}>
          {renderedLogItems}
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <Box style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
            <AlarmHistoryLoader alarmDetails={alarmDetails} />
          </Box>
        </TabPanel>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container spacing={1} style={{ marginRight: 'auto' }}>
          <Grid item>
            {alarmDetails.alarmTag.alarmCanBeResolved
              ? alarmDetails.status !== AlarmStatus.RESOLVED && (
                  <Button
                    data-testid="alarm-resolve-action"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      history.push(
                        PathBuilder.ALARM_RESOLVE(
                          siteId,
                          alarmDetails.alarmId,
                          alarmDetails.alarmTag.id
                        )
                      )
                    }
                  >
                    Resolve
                  </Button>
                )
              : alarmDetails.status === AlarmStatus.ACTIVE_UN_ACK && (
                  <Button
                    data-testid="alarm-ack-action"
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      history.push(
                        PathBuilder.ALARM_ACK(
                          siteId,
                          alarmDetails.alarmId,
                          alarmDetails.alarmTag.id
                        )
                      )
                    }
                  >
                    Acknowledge
                  </Button>
                )}
          </Grid>

          <Grid item>
            <Button
              onClick={() =>
                history.push(
                  PathBuilder.ALARM_NOTE(siteId, alarmDetails.alarmId, alarmDetails.alarmTag.id)
                )
              }
            >
              Add Note
            </Button>
          </Grid>
        </Grid>
        <Button onClick={() => history.push(PathBuilder.ALARMS_DEFAULT(siteId))}>Done</Button>
      </DialogActions>

      <Route path={PathPattern.ALARM_ACK}>
        <WithRequest
          actionCreator={acknowledgeAlarm.request}
          onSuccess={() =>
            history.push(
              PathBuilder.ALARM_DETAILS(siteId, alarmDetails.alarmId, alarmDetails.alarmTag.id)
            )
          }
          onSuccessDelay={1000}
          render={({ status, boundActionCreator }) => (
            <AlarmAckModal
              siteId={siteId}
              alarmDetails={alarmDetails}
              handleCancel={handleActionCancel}
              handleSubmit={boundActionCreator}
              requestStatus={status}
            />
          )}
        />
      </Route>

      <Route path={PathPattern.ALARM_NOTE}>
        <WithRequest
          actionCreator={addNote.request}
          onSuccess={() =>
            history.push(
              PathBuilder.ALARM_DETAILS(siteId, alarmDetails.alarmId, alarmDetails.alarmTag.id)
            )
          }
          onSuccessDelay={1000}
          render={({ status, boundActionCreator }) => (
            <AlarmNoteModal
              siteId={siteId}
              alarmDetails={alarmDetails}
              handleCancel={handleActionCancel}
              handleSubmit={boundActionCreator}
              requestStatus={status}
            />
          )}
        />
      </Route>

      <Route path={PathPattern.ALARM_RESOLVE}>
        <WithRequest
          actionCreator={resolveAlarm.request}
          onSuccess={() =>
            history.push(
              PathBuilder.ALARM_DETAILS(siteId, alarmDetails.alarmId, alarmDetails.alarmTag.id)
            )
          }
          onSuccessDelay={1000}
          render={({ status, boundActionCreator }) => (
            <AlarmResolveModal
              siteId={siteId}
              alarmDetails={alarmDetails}
              handleCancel={handleActionCancel}
              handleSubmit={boundActionCreator}
              requestStatus={status}
            />
          )}
        />
      </Route>
    </>
  );
};

export const AlarmDetailsModal: React.FC<Props> = (props) => {
  const { siteId, requestStatus } = props;
  const classes = useStyles();
  const history = useHistory();
  return (
    <Dialog
      data-testid="alarm-details-modal"
      open={true}
      maxWidth="lg"
      fullWidth
      classes={{ paper: classes.dialogPaper }}
    >
      {requestStatus === Lifecycle.COMPLETED ? (
        <AlarmDetailsModalContent {...(props as ContentProps)} />
      ) : (
        <>
          <RequestStatus status={requestStatus} style={{ flex: 1, minHeight: '100%' }} />

          <DialogActions>
            <Button onClick={() => history.push(PathBuilder.ALARMS_DEFAULT(siteId))}>Done</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
