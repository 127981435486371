import React from 'react';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Badge,
  CircularProgress,
  Fade,
  IconButton,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import AlertIcon from '@material-ui/icons/Notifications';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import EquipmentDetails from './EquipmentDetails';
import EquipmentTagList from './EquipmentTagList';
import { AlarmStatus } from '../../../models';
import { EquipmentTabProps } from './EquipmentPage.d';
import { PathBuilder } from '../../../routing';
import identifiers from '../../../tests/identifiers';
import { SubscriptionLifecycle, SubscriptionStatus } from '../../../state/subscription';

const EquipmentTabPanel = (props: EquipmentTabProps) => {
  const { activeTab, equipment, equipmentAlarms, pinnedTagIds, siteId, tagDataStatus } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const [value, setValue] = useState(getTabIndex(activeTab));

  const { equipmentType, id, label } = equipment;

  const relevantAlarms = useMemo(() => {
    const tagIds = equipment.tags.map((tag) => tag.id);
    return equipmentAlarms.filter(
      ({ alarmTag, status }) =>
        (tagIds.includes(alarmTag.id) && status === AlarmStatus.ACTIVE_UN_ACK) ||
        status === AlarmStatus.ACTIVE_ACK
    );
  }, [equipment, equipmentAlarms]);

  return (
    <Fade in>
      <div
        className={classes.root}
        data-testid={identifiers.equipmentPage.tabPanel}
        role="tabpanel"
        id={`equipment-tabpanel-${id}`}
        aria-labelledby={`equipment-tab-${id}`}
      >
        <div className={classes.actions}>
          <Fade in={relevantAlarms.length > 0}>
            <Tooltip
              title={`${relevantAlarms.length} Active Alarm${
                relevantAlarms.length === 1 ? '' : 's'
              }`}
            >
              <Badge
                badgeContent={relevantAlarms.length}
                classes={{ root: classes.alertBadgeRoot, badge: classes.alertBadge }}
                color="secondary"
              >
                <IconButton onClick={() => history.push(PathBuilder.ALARMS_DEFAULT(siteId))}>
                  <AlertIcon />
                </IconButton>
              </Badge>
            </Tooltip>
          </Fade>
          <Tooltip title="Close">
            <IconButton
              data-testid={identifiers.equipmentPage.closeEquipment}
              onClick={() => history.push(PathBuilder.EQUIPMENT(siteId, pinnedTagIds))}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Typography color="textSecondary" variant="subtitle1">
          {equipmentType}
        </Typography>
        <Typography variant={isSmallScreen ? 'h5' : 'h4'} gutterBottom>
          {label}
          <TagDataStatus tagDataStatus={tagDataStatus} />
        </Typography>

        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          value={value}
        >
          {EQUIPMENT_TABS.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.label}
              id={tab.id}
              onClick={() => {
                history.push(PathBuilder.EQUIPMENT(siteId, pinnedTagIds, id, tab.id));
              }}
            />
          ))}
        </Tabs>

        {EQUIPMENT_TABS.map(({ id: tabId, Component }) => {
          if (tabId !== activeTab) {
            return false;
          }
          return <Component key={tabId} {...props} />;
        })}
      </div>
    </Fade>
  );
};

const EQUIPMENT_TABS = [
  { Component: EquipmentTagList, id: 'tags', label: 'Tag Points' },
  { Component: EquipmentDetails, id: 'details', label: 'Details' },
];

function getTabIndex(activeTab: string) {
  return EQUIPMENT_TABS.findIndex(({ id }) => id === activeTab) || 0;
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    overflow: 'scroll',
    padding: theme.spacing(3),
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  actions: {
    float: 'right',
    marginTop: theme.spacing(2),
  },
  alertBadge: {
    right: 4,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: theme.spacing(0, 0.5),
  },
  alertBadgeRoot: {
    marginRight: theme.spacing(1),
  },
  tabs: {
    marginBottom: theme.spacing(3),
  },
}));

const TagDataStatus = ({
  tagDataStatus = SubscriptionLifecycle.STARTING,
}: {
  tagDataStatus: SubscriptionStatus;
}) => {
  const classes = useStatusStyles();
  switch (tagDataStatus) {
    case SubscriptionLifecycle.STARTING:
      return (
        <CircularProgress
          className={classes.loader}
          data-testid={identifiers.equipmentPage.tagDataLoading}
          size={24}
        />
      );
    case SubscriptionLifecycle.ACTIVE:
      return null;
    case SubscriptionLifecycle.STOPPED:
    default:
      return (
        <Tooltip placement="top" title="Error Retrieving Tag Point Data">
          <ErrorIcon
            className={classes.errorIcon}
            data-testid={identifiers.equipmentPage.tagDataError}
          />
        </Tooltip>
      );
  }
};

const useStatusStyles = makeStyles<Theme>((theme) => ({
  errorIcon: {
    color: theme.palette.error.main,
    margin: theme.spacing(0, 0, -0.5, 1),
  },
  loader: {
    margin: theme.spacing(0, 0, -0.5, 1),
  },
}));

export default React.memo(EquipmentTabPanel);
