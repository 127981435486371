import { User, isAssigned } from '../../../models';
import { values } from 'lodash';
import { UserStateSlice } from './reducer';
import { EntitiesStateSlice } from '../';
import { NormalizationError } from '../../../helpers/norm';
import { findCustomerById } from '../customer/selectors';

export const getUserNorm = (rootState: UserStateSlice, userId: number): User | undefined => {
  return rootState.entities.user.byId[userId];
};

export const denormUser = (rootState: EntitiesStateSlice, userId: number): User => {
  const userNorm = getUserNorm(rootState, userId);
  if (!userNorm) {
    throw new NormalizationError(`Unable to denormalize user: ${userId}`, { userId });
  }

  return userNorm;
};

const getRoot = (rootState: UserStateSlice) => {
  return rootState.entities.user;
};

/**
 * Get all users that are visible to the current user. This includes all system
 * admin users, and all users for the current customer.
 */
export const getAll = (rootState: UserStateSlice): User[] => {
  return values(rootState.entities.user.byId);
};

export const getAssignedToSite = (rootState: UserStateSlice, siteId: number): User[] => {
  const users = getAll(rootState);

  return users.filter((user) => isAssigned(user, siteId));
};

/**
 * Returns users which have access to the specified site. If customerId
 * is passed only customer users are returned.
 */
export const getUsersWithSiteAccess = (
  rootState: EntitiesStateSlice,
  siteId: number,
  customerId?: number
): User[] => {
  let users = Object.values(rootState.entities.user.byId);
  if (customerId !== undefined) {
    users = users.filter((user) => user.customerId === customerId);
  }

  return users.filter((user) => isAssigned(user, siteId));
};

export const findUserById = (rootState: UserStateSlice, userId: number): User => {
  return getRoot(rootState).byId[userId];
};

export const findUsersByCustomerId = (
  rootState: EntitiesStateSlice,
  customerId: number
): User[] => {
  const customer = findCustomerById(rootState, customerId);
  if (!customer) {
    return [];
  }

  return customer.users;
};
