import { produce } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as installerActions from './actions';
import { SiteTemplate, Utility } from '../../../models';

export interface InstallerEntityState {
  siteTemplates: SiteTemplate[];
  utilities: { [postalCode: string]: Utility[] };
}

export interface InstallerStateSlice {
  entities: {
    installer: InstallerEntityState;
  };
}

const initialState: InstallerEntityState = {
  siteTemplates: [],
  utilities: {},
};

type InstallerActions = ActionType<typeof installerActions>;

export const installerEntityReducer = produce(
  (draft: InstallerEntityState, action: InstallerActions) => {
    switch (action.type) {
      case getType(installerActions.fetchSiteTemplates.success):
        return {
          ...draft,
          siteTemplates: action.payload.result,
        };
      case getType(installerActions.fetchUtilities.success):
        return {
          ...draft,
          utilities: {
            ...draft.utilities,
            [action.payload.triggerAction.payload.postalCode]: action.payload.result,
          },
        };

      default:
        return;
    }
  },
  initialState
);

export { installerActions };
