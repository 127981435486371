import React from 'react';
import * as d3 from 'd3';
import { ScreenSize, SeriesPoint, LinkedSeriesValue, Margins } from './types';
import { interpolate, comparePoints } from './helpers';
import { useMemo } from 'react';

interface LinkedChartSeries {
  values: LinkedSeriesValue[];
  styleClass?: string;
}

interface Props {
  style?: React.CSSProperties;
  size: ScreenSize;
  margins?: Margins;
  xScale: d3.ScaleTime<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  data: SeriesPoint[][];
}

export type SeriesLayout = [
  SeriesPoint[][],
  { minX: number; minY: number; maxX: number; maxY: number }
];

/**
 * The purpose of this function is to take multiple LinkedChartSeries objects as
 * inputs, if these series have offset timestamps or missing timesteps, this
 * function will interpolate so that the stacking is smooth.
 *
 * Additionally this function returns the horizontal and vertical range of the data
 * after the stacking operation has been carried out.
 *
 * This function is exported because it is possible we will want to process this
 * data only once in a parent, use the range data, and then pass it as a prop to
 * this component.
 */
export const stackAreaSeries = (chartSeries: LinkedChartSeries[]): SeriesLayout => {
  // Create shallow sorted copies of the SeriesValues. This is to make sure
  // values are sorted based on x value.
  // Note: the order of series in chartSeries is the stacking order
  const sortedSeries = chartSeries.map((series) => [...series.values].sort(comparePoints));

  const lookup = {};
  const timestamps = new Set<number>();
  const seriesIds: number[] = [];

  // build lookup table for data values based on timestamp also creates forward
  // and backward references for use in interpolation
  sortedSeries.forEach((series, seriesId) => {
    seriesIds.push(seriesId);
    lookup[seriesId] = lookup[seriesId] || {};

    series.forEach((value, j) => {
      const ts = value.x.getTime();

      timestamps.add(ts);
      lookup[seriesId][ts] = value;

      // create forward and back references
      value.prev = j > 0 ? series[j - 1] : null;
      value.next = j < series.length - 1 ? series[j + 1] : null;
    });
  });

  // transform set of timestamps to sorted array
  const sortedTimestamps = Array.from(timestamps).sort();

  const tsSums = {};

  let minY = Infinity;
  let maxY = -Infinity;
  let minX = Infinity;
  let maxX = -Infinity;

  const interpolatedSeries = seriesIds.map((seriesId) => {
    const currentSeriesLookup = lookup[seriesId];
    let prevValue: null | SeriesPoint = null;

    return sortedTimestamps.map((ts) => {
      // will be SeriesPoint if value exists for this series at ts
      let valueRef = currentSeriesLookup[ts];

      if (valueRef) {
        // only updating prevValue to point to existing data points
        prevValue = valueRef;
      } else {
        // fill in missing timesteps by interpolation or by setting to 0 before first value
        // or after last value
        valueRef = currentSeriesLookup[ts] = {
          x: new Date(ts),
          y: prevValue && prevValue.next ? interpolate(prevValue, prevValue.next, ts) : 0,
        };
      }

      let sumAtTs = tsSums[ts] || 0;

      // create return value
      const stackedValue = {
        x: valueRef.x,
        y: valueRef.y,
        y0: sumAtTs,
        seriesId: seriesId,
      } as SeriesPoint;

      if (chartSeries[seriesId].styleClass) {
        stackedValue.styleClass = chartSeries[seriesId].styleClass;
      }

      // update stacked value lookup
      sumAtTs = tsSums[ts] = sumAtTs + valueRef.y;

      // update x and y range mins and maxes
      maxY = sumAtTs > maxY ? sumAtTs : maxY;
      minY = sumAtTs < minY ? sumAtTs : minY;
      maxX = ts > maxX ? ts : maxX;
      minX = ts < minX ? ts : minX;

      return stackedValue;
    });
  });

  return [
    interpolatedSeries,
    { minY: minY === Infinity ? 0 : minY, maxY: maxY === -Infinity ? 0 : maxY, minX, maxX },
  ];
};

const StackedAreaSeries = React.memo((props: Props) => {
  const { xScale, yScale, data } = props;

  const area = useMemo(() => {
    return d3
      .area<SeriesPoint>()
      .x((d) => xScale(d.x) ?? 0)
      .y0((d) => yScale(d.y0) ?? 0)
      .y1((d) => yScale(d.y0 + d.y) ?? 0);
  }, [xScale, yScale]);

  return (
    <g>
      {data.map((areaData, i) => {
        const areaPath = area(areaData);
        return areaPath ? (
          <path key={i} d={areaPath} className={props.data[i][0].styleClass} />
        ) : null;
      })}
      ;
    </g>
  );
});

export default StackedAreaSeries;
