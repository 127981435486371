import React from 'react';
import { cloneElement, useEffect, useRef, useState } from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';

const WINDOW_EVENTS = ['navtoggle', 'resize'];

/**
 * Show a Tooltip if children is truncated with ellipsis
 */
export const TooltipIfEllipsis: React.FC<TooltipProps> = ({ children, ...tooltipProps }) => {
  const ref = useRef<HTMLElement | null>(null);

  // Figure out whether or not children has been truncated with ellipsis
  const [isEllipsis, setIsEllipsis] = useState(false);
  useEffect(() => {
    function updateIsEllipsis() {
      setIsEllipsis(!!(ref.current && ref.current.offsetWidth < ref.current.scrollWidth));
    }
    updateIsEllipsis();

    WINDOW_EVENTS.forEach((event) => window.addEventListener(event, updateIsEllipsis));
    return () =>
      WINDOW_EVENTS.forEach((event) => window.removeEventListener(event, updateIsEllipsis));
  });

  const content = cloneElement(children, { ref });

  if (isEllipsis) {
    return <Tooltip {...tooltipProps}>{content}</Tooltip>;
  } else {
    return content;
  }
};
