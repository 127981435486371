import React from 'react';
import { DataTable, Column } from '../../ui/DataTable';
import { AlarmSummary, AlarmStatus } from '../../../models';
import { PathBuilder } from '../../../routing';
import { Link } from 'react-router-dom';
import { setSort, getSort, getPage, getAnalysisUrl } from '../../../state/contexts/alarmsPage';
import { useSelector } from 'react-redux';
import { useAction } from '../../../helpers/hooks';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import { IconButton, Tooltip, makeStyles, Theme } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsPausedIcon from '@material-ui/icons/NotificationsPaused';
import SnoozeIcon from '@material-ui/icons/Snooze';
import CheckIcon from '@material-ui/icons/Check';

interface Props {
  siteId: number;
  alarmSummaries: AlarmSummary[];
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    resolvedCell: {
      color: theme.palette.success.main,
      display: 'flex',
      alignItems: 'center',
    },
    activeUnAckCell: {
      color: theme.palette.error.dark,
      display: 'flex',
      alignItems: 'center',
    },
    activeAckCell: {
      color: theme.palette.grey[700],
      display: 'flex',
      alignItems: 'center',
    },
  };
});

const getAlarmsTableConfig = (
  siteId: number,
  classes: ReturnType<typeof useStyles>
): Column<AlarmSummary>[] => [
  {
    id: 'activatedAt',
    label: 'Triggered',
    field: 'activatedAt',
    isSortable: true,
    renderCell: (alarmSummary) => new Date(alarmSummary.activatedAt).toLocaleString(),
  },
  {
    id: 'name',
    label: 'Name',
    field: (alarmSummary) => alarmSummary.alarmTag.label,
    isSortable: true,
    renderCell: (alarmSummary) => (
      <Link to={PathBuilder.ALARM_DETAILS(siteId, alarmSummary.alarmId, alarmSummary.alarmTag.id)}>
        {alarmSummary.alarmTag.label}
      </Link>
    ),
  },
  {
    id: 'priority',
    label: 'Priority',
    field: (alarmSummary) => alarmSummary.alarmTag.alarmPriority,
    isSortable: true,
    renderCell: (alarmSummary) => alarmSummary.alarmTag.alarmPriority,
  },
  {
    id: 'status',
    label: 'Status',
    field: (alarmSummary) => alarmSummary.status,
    renderCell: (alarmSummary) => {
      switch (alarmSummary.status) {
        case AlarmStatus.RESOLVED:
          return (
            <Tooltip title={AlarmStatus.RESOLVED}>
              <div className={classes.resolvedCell}>
                <CheckCircleOutlineIcon />
              </div>
            </Tooltip>
          );
        case AlarmStatus.ACTIVE_UN_ACK:
          return (
            <Tooltip title={AlarmStatus.ACTIVE_UN_ACK}>
              <div className={classes.activeUnAckCell}>
                <NotificationsActiveIcon />
              </div>
            </Tooltip>
          );
        case AlarmStatus.ACTIVE_ACK:
          return (
            <Tooltip title={AlarmStatus.ACTIVE_ACK}>
              <div className={classes.activeAckCell}>
                <NotificationsPausedIcon />
              </div>
            </Tooltip>
          );
        default:
          return null;
      }
    },
    isSortable: true,
  },
  {
    id: 'ack',
    label: 'Acknowledged',
    field: (alarmSummary) =>
      alarmSummary.acknowledgement
        ? new Date(alarmSummary.acknowledgement.timeStamp).toLocaleString()
        : '—',
    isSortable: true,
  },
  {
    id: 'resolved',
    label: 'Resolved',
    field: (alarmSummary) =>
      alarmSummary.resolvedAt ? new Date(alarmSummary.resolvedAt).toLocaleString() : '—',
    isSortable: true,
  },
  {
    id: 'actions',
    isSortable: false,
    field: 'actions',
    renderCell: (alarmSummary) => {
      return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          {alarmSummary.status === AlarmStatus.ACTIVE_UN_ACK &&
            !alarmSummary.alarmTag.alarmCanBeResolved && (
              <Tooltip title="Acknowledge">
                <IconButton
                  size="small"
                  to={PathBuilder.ALARM_ACK(siteId, alarmSummary.alarmId, alarmSummary.alarmTag.id)}
                  component={Link}
                >
                  <SnoozeIcon />
                </IconButton>
              </Tooltip>
            )}

          {alarmSummary.status === AlarmStatus.ACTIVE_UN_ACK &&
            alarmSummary.alarmTag.alarmCanBeResolved && (
              <Tooltip title="Resolve">
                <IconButton
                  size="small"
                  to={PathBuilder.ALARM_RESOLVE(
                    siteId,
                    alarmSummary.alarmId,
                    alarmSummary.alarmTag.id
                  )}
                  component={Link}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            )}

          {alarmSummary.relatedTagIds && alarmSummary.relatedTagIds.length > 0 && (
            <Tooltip title="View in Analysis">
              <IconButton size="small" to={getAnalysisUrl(siteId, alarmSummary)} component={Link}>
                <MultilineChartIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      );
    },
  },
];

export const AlarmsTableContainer: React.FC<Props> = (props) => {
  const doSetSort = useAction(setSort);
  const page = useSelector(getPage);
  const { orderBy, orderDirection } = useSelector(getSort);

  const classes = useStyles();
  const tableConfig = getAlarmsTableConfig(props.siteId, classes);
  return (
    <DataTable<AlarmSummary>
      fullHeight
      stickyHeader={true}
      elementKey={(alarmSummary) => alarmSummary.alarmUid}
      elements={props.alarmSummaries}
      columnMapping={tableConfig}
      handleSort={doSetSort}
      page={page}
      orderBy={orderBy}
      orderDirection={orderDirection}
    />
  );
};
