import { RootState } from '../';
import { ActionsOf } from '../../helpers/actions';
import { getType, createAction } from 'typesafe-actions';
import { getSiteById } from '../entities/site/selectors';

/* ----- actions ----- */

export const switchSite = createAction(
  'context/site/switchSite',
  (resolve) => (siteId: number) => resolve({ siteId })
);

export const siteContextActions = {
  switchSite,
};

/* ----- selectors ----- */

export const getActiveSite = (rootState: RootState) => {
  return rootState.contexts.site.siteId;
};

export const getSiteContext = (rootState: RootState) => {
  const { siteId } = rootState.contexts.site;
  if (!siteId) {
    throw new Error('Not within site context');
  }
  const site = getSiteById(rootState, siteId);

  if (!site) {
    throw new Error(`No context found for site ${siteId}`);
  }
  return site;
};

/* ----- reducer ----- */

export interface SiteContextState {
  siteId?: number;
}

const initialState = {
  siteId: undefined,
};

type SiteContextActions = ActionsOf<typeof siteContextActions>;

export const siteContextReducer = (
  state: SiteContextState = initialState,
  action: SiteContextActions
): SiteContextState => {
  switch (action.type) {
    case getType(siteContextActions.switchSite):
      return {
        ...state,
        siteId: action.payload.siteId,
      };
    default:
      return state;
  }
};
