import { RootState } from '../';
import { takeEvery } from 'redux-saga/effects';
import { createAction, getType } from 'typesafe-actions';
import { ActionsOf, createApiActions } from '../../helpers/actions';
import { apiSagaV2 } from '../../helpers/saga';
import { AdminApi } from '../../api';
import { ActionType } from 'typesafe-actions/src/types';

// Actions
export const utilityRateSwitchSchedule = createApiActions<boolean>()(
  'utilityRate/update',
  'utilityRate/updateSuccess',
  'utilityRate/updateError',
  (siteId: number, rateScheduleId: number) => ({ siteId, rateScheduleId })
);

export const utilityRateReset = createAction('utilityRate/reset', (resolve) => () => resolve());

// Sagas
const switchRateSchedule = apiSagaV2(utilityRateSwitchSchedule, async (connection, action) => {
  const { siteId, rateScheduleId } = action.payload;
  return new AdminApi(connection).switchRateSchedule(siteId, rateScheduleId);
});

export function* watchUtilityRateSagas() {
  yield takeEvery(getType(utilityRateSwitchSchedule.request), switchRateSchedule);
}

// Selector
export const getUtilityRateContext = (rootState: RootState) => {
  return rootState.contexts.utilityRate;
};

// Reducers
export interface UtilityRateContext {
  rateScheduleId?: number;
  submission: {
    error: boolean;
    success?: boolean;
  };
}

type UtilityRateContextActions =
  | ActionsOf<typeof utilityRateSwitchSchedule>
  | ActionType<typeof utilityRateReset>;

const initialState = {
  submission: {
    error: false,
  },
};

export const utilityRateRequestReducer = (
  state: UtilityRateContext = initialState,
  action: UtilityRateContextActions
): UtilityRateContext => {
  switch (action.type) {
    case getType(utilityRateSwitchSchedule.request):
      return {
        ...state,
        rateScheduleId: action.payload.rateScheduleId,
      };
    case getType(utilityRateSwitchSchedule.success):
      return { ...state, submission: { ...state.submission, success: true } };
    case getType(utilityRateSwitchSchedule.error):
      return { ...state, submission: { error: true, success: false } };
    case getType(utilityRateReset):
      return { ...state, submission: { error: false, success: undefined } };
    default:
      return state;
  }
};
