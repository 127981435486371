import { ChangeEvent } from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Zoom,
  useMediaQuery,
  useTheme,
  makeStyles,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import { number, string, object, Schema } from 'yup';
import { NumberField } from '../../ui/controls/NumberField';
import identifiers from '../../../tests/identifiers';
import { getFormikInputProps } from '../../../helpers/form';
import { InstallerSteps, SolarEdgeDetails } from './InstallerPage.types';
import { SiteTemplate, Utility, EquipmentTemplate, EnrollmentStatus } from '../../../models';
import { UtilityRate } from 'src/components/ui/form/UtilityRate';

export interface SolarEdgeSiteDetailsFormProps {
  utilities: Utility[];
  setActiveStep: (step: number) => void;
  setImage: (imageUrl: string | false) => void;
  image: string | false;
  siteTemplate: SiteTemplate | null;
  equipment: EquipmentTemplate[];
}

const SolarEdgeSiteDetailsForm = (props: SolarEdgeSiteDetailsFormProps) => {
  const { utilities, setActiveStep, setImage, image, siteTemplate, equipment } = props;
  const formik = useFormikContext<SolarEdgeDetails>();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const pvModelField = (
    <Grid item xs={12} md={8}>
      <TextField label="PV Model" {...getFormikInputProps(formik, 'pvModel')} />
    </Grid>
  );

  return (
    <Box marginTop={1.5}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <TextField
            fullWidth
            label="Grid Services Eligibility"
            {...getFormikInputProps(formik, 'gridServicesInitialStatus', 'select')}
          >
            {Object.keys(EnrollmentStatus)
              .filter((key) =>
                [
                  EnrollmentStatus.Ineligible,
                  EnrollmentStatus.Eligible,
                  EnrollmentStatus.OptIn,
                ].includes(EnrollmentStatus[key])
              )
              .map((key) => (
                <MenuItem key={key} value={key}>
                  {EnrollmentStatus[key]}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item container spacing={3}>
          <UtilityRate utilities={utilities} rateColumnSize={8} />

          <Grid item xs={12} md={4}>
            <NumberField
              label="Peak Power"
              unit="kWp"
              {...getFormikInputProps(formik, 'peakPower')}
            />
          </Grid>
        </Grid>
        <Grid item>
          <TextField label="Customer E-mail" {...getFormikInputProps(formik, 'customerEmail')} />
        </Grid>
        <Grid item>
          <TextField
            label="Installer Notes"
            InputProps={{
              inputProps: {
                maxLength: FIELD_NOTES_MAX_LENGTH,
              },
            }}
            multiline
            {...getFormikInputProps(formik, 'notes')}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Image"
            InputLabelProps={{ shrink: true }}
            type="file"
            inputProps={{ accept: '.jpg,.jpeg,.png,.gif' }}
            {...getFormikInputProps(formik, 'image', 'text', {
              onChange: (event: ChangeEvent<unknown>) => {
                const imageFile = (event as ChangeEvent<HTMLInputElement>).currentTarget
                  ?.files?.[0];
                if (imageFile) {
                  setImage(URL.createObjectURL(imageFile));
                  formik.setFieldValue('imageFile', imageFile);
                } else {
                  setImage(false);
                  formik.setFieldValue('imageFile', '');
                }
              },
            })}
          />
        </Grid>
        {image && (
          <Grid item>
            <Zoom in>
              <img
                alt="Installer Image"
                data-testid={identifiers.installerPage.image}
                src={image}
              />
            </Zoom>
          </Grid>
        )}
      </Grid>

      <Box marginBottom={2} marginTop={3}>
        <Typography variant="h6">Primary PV Module</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <TextField label="PV Manufacturer" {...getFormikInputProps(formik, 'pvManufacturer')} />
        </Grid>
        {isSmallScreen && pvModelField}
        <Grid item xs={12} md={4}>
          <NumberField
            label="PV Maximum Power"
            max={FIELD_PV_MAXIMUM_POWER_MAX}
            min={0}
            unit="kW"
            {...getFormikInputProps(formik, 'pvMaximumPower')}
          />
        </Grid>
        {!isSmallScreen && pvModelField}
        <Grid item xs={12} md={4}>
          <NumberField
            label="PV Temperature Coefficient"
            max={0}
            unit="%/°C"
            {...getFormikInputProps(formik, 'pvTemperatureCoefficient')}
          />
        </Grid>
      </Grid>

      {siteTemplate && equipment.length > 0 && (
        <>
          <Box marginBottom={2} marginTop={3}>
            <Typography variant="h6">Equipment Serial Numbers</Typography>
          </Box>
          <Grid container direction="column" spacing={3}>
            {(() => {
              const rows: JSX.Element[] = [];
              equipment.forEach((equipmentTemplate) => {
                rows.push(
                  <Grid key={equipmentTemplate.participantId} item>
                    <TextField
                      label={getEquipmentTemplateName(equipmentTemplate, isSmallScreen)}
                      {...getFormikInputProps(
                        formik,
                        `serialNumbers.${equipmentTemplate.participantId}`
                      )}
                    />
                  </Grid>
                );
              });
              return rows;
            })()}
          </Grid>
        </>
      )}

      <Box marginTop={2} textAlign="right">
        <Button
          onClick={() => {
            // Reset image field because the value can't be set programmatically for security reasons
            setImage(false);
            formik.setFieldValue('image', '');
            formik.setFieldValue('imageFile', '');

            setActiveStep(InstallerSteps.LOCATION);
          }}
        >
          Back
        </Button>
        <Button
          className={classes.marginLeft}
          color="primary"
          onClick={() => formik.submitForm()}
          variant="contained"
        >
          Provision
        </Button>
      </Box>
    </Box>
  );
};

const FIELD_NOTES_MAX_LENGTH = 255;
export const FIELD_PV_MAXIMUM_POWER_MAX = 1000;

export const SolarEdgeSiteDetailsFormValidationSchema = (equipment: EquipmentTemplate[]) => ({
  gridServicesInitialStatus: string().required('Field Required'),
  utilityId: number().nullable(),
  rateScheduleId: number().required('Field Required').nullable(),
  peakPower: number().moreThan(0, `Must Be Greater Than 0 kWp`),
  customerEmail: string().email('Invalid E-mail Address').required('Field Required'),
  notes: string().max(FIELD_NOTES_MAX_LENGTH, `Cannot Exceed ${FIELD_NOTES_MAX_LENGTH} Characters`),
  imageFile: string(),
  serialNumbers: object(
    equipment.reduce((acc, e) => {
      acc[e.participantId] = string().required('Field Required');
      return acc;
    }, {} as Record<string, Schema<string>>)
  ),
  pvManufacturer: string().required('Field Required'),
  pvModel: string().required('Field Required'),
  pvMaximumPower: number()
    .max(FIELD_PV_MAXIMUM_POWER_MAX, `Cannot Exceed ${FIELD_PV_MAXIMUM_POWER_MAX} kW`)
    .moreThan(0, `Must Be Greater Than 0 kW`)
    .required('Field Required'),
  pvTemperatureCoefficient: number().lessThan(0, 'Must Be Negative'),
});

export const solarEdgeSiteDetailsInitialValues = (equipment: EquipmentTemplate[]) => {
  return {
    notes: '',
    peakPower: '' as const,
    pvManufacturer: '',
    pvModel: '',
    pvMaximumPower: '' as const,
    pvTemperatureCoefficient: '' as const,
    serialNumbers: equipment.reduce((acc, template) => {
      acc[template.participantId] = '';
      return acc;
    }, {}),
  };
};

const useStyles = makeStyles((theme) => ({
  img: {
    maxHeight: 225,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 150,
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

function getEquipmentTemplateName(
  { make = '', manufacturer = '', model = '', name }: EquipmentTemplate,
  isSmallScreen: boolean
) {
  if (isSmallScreen) {
    return name;
  }

  const extras: string[] = [];
  if (manufacturer !== '') {
    extras.push(manufacturer);
  }
  if (make !== '') {
    extras.push(make);
  }
  if (model !== '') {
    extras.push(model);
  }
  return extras.length > 0 ? `${name} (${extras.join(', ')})` : name;
}

export default SolarEdgeSiteDetailsForm;
